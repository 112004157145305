import { Http, HttpLegacy } from '../index';
import { flushInitiativesCache } from '@/api/risma/data';
import { handleRequestResponse, throwError } from '../httpUtils';
export function getAll() {
  return handleRequestResponse(Http.get('/activities/initiatives'));
}
export function getArchivedAndNotArchivedActions() {
  return handleRequestResponse(Http.get('actions/reports?filters[archived]=0,1&filters[status]=0,1'));
}
export function getAllWithTagsFilter(tag) {
  return handleRequestResponse(Http.get('/activities/initiatives?filterTags[]=' + tag));
}
export function create(data) {
  flushInitiativesCache();
  return handleRequestResponse(Http.post('/actions/action', data));
}
export function deleteAction(slug) {
  flushInitiativesCache();
  return handleRequestResponse(Http.delete('/actions/action/' + slug));
}
export function getMyInitiatives() {
  return handleRequestResponse(Http.get('/actions/my'));
}
export function getInitiatives() {
  return handleRequestResponse(Http.get('/actions/action'));
}
export function getSingleInitiative(slug) {
  return handleRequestResponse(Http.get("/actions/action/".concat(slug)));
}
export function updateSingleInitiative(slug, data) {
  flushInitiativesCache();
  return handleRequestResponse(Http.patch("/actions/action/".concat(slug), data));
}
export function getExecutionChangelog(id) {
  return handleRequestResponse(Http.get("/changelog/changelog/execution/".concat(id)));
}
export function getMyDailyInitiatives() {
  return handleRequestResponse(Http.get('/actions/dailyteam'));
}
export function getActionsTree() {
  return handleRequestResponse(Http.get('/actions/tree'));
}

// REST API Risma1
export function moveInitiatives(serializedData) {
  return HttpLegacy.get(window.location.origin + '/api/execution/move_deadlines?' + serializedData).then(function (response) {
    return response;
  }).catch(function (err) {
    return throwError(err);
  });
}
export function getAllActionMainReport() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var url = '/actions/reports';
  url += params;
  return handleRequestResponse(Http.get(url));
}
export function getActionDashboardsOverview(_ref) {
  var startDate = _ref.startDate,
    endDate = _ref.endDate;
  return handleRequestResponse(Http.get("/actions/dashboards/overview?filters[dateStart]=".concat(startDate, "&filters[dateEnd]=").concat(endDate)));
}
export function getActionBreadcrumb(slug) {
  return handleRequestResponse(Http.get("actions/action/".concat(slug, "/breadcrumb")));
}
export function setNewSequenceNumber(actionId, newIno) {
  return Http.post("actions/action/".concat(actionId, "/update-ino"), {
    newIno: newIno
  });
}