import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "w-full p-12"
};
var _hoisted_2 = {
  class: "w-full h-full"
};
var _hoisted_3 = {
  class: "w-full mt-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_doughnut_chart = _resolveComponent("risma-doughnut-chart");
  var _component_performance_card = _resolveComponent("performance-card");
  var _component_risma_line_chart = _resolveComponent("risma-line-chart");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("div", {
    class: _normalizeClass($options.hasLargeDataset ? '2lg:flex' : 'xl:flex')
  }, [_createVNode(_component_performance_card, {
    class: _normalizeClass(["xl:mr-10 flex-shrink-0 xl:min-w-370px flex compliance-indicator", $options.hasLargeDataset ? '2lg:w-3/5 2xl:w-1/2' : 'xl:w-7/20']),
    "overridden-overlay-classes": "flex border border-gray-350 bg-white",
    "overridden-export-frame-classes": "flex items-center justify-center relative",
    title: $data.translate.complianceIndicator,
    "is-export-on-parent": true,
    "sub-title": $data.translate.anAverageScoreOfAllDashboards,
    "use-default-export": false
  }, {
    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_doughnut_chart, {
        cover: false,
        "chart-max-width": 'calc(100% - 4rem)',
        "chart-min-width": 'calc(100% - 4rem)',
        "is-export-on-parent": true,
        datasets: $options.complianceDatasets,
        options: $options.complianceOptions
      }, null, 8 /* PROPS */, ["datasets", "options"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class", "title", "sub-title"]), _createVNode(_component_performance_card, {
    title: $data.translate.overview,
    "sub-title": $data.translate.theCurrentScoreForAllDashboards,
    "overridden-overlay-classes": "border border-gray-350 bg-white",
    class: "flex-auto"
  }, {
    content: _withCtx(function () {
      return [_createElementVNode("div", {
        class: _normalizeClass("project-charts-grid-".concat($options.hasLargeDataset ? 3 : 4, " grid gap-4 justify-center p-2"))
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.projectStatusDatasets, function (chart, index) {
        return _openBlock(), _createBlock(_component_risma_doughnut_chart, {
          key: index,
          class: "project-chart mx-1",
          datasets: chart.datasets,
          title: chart.title || '',
          description: chart.description || '',
          options: chart.options,
          "chart-max-width": '150px',
          "chart-min-width": '100px',
          "use-default-export": false
        }, null, 8 /* PROPS */, ["datasets", "title", "description", "options"]);
      }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "sub-title"])], 2 /* CLASS */), _createElementVNode("div", _hoisted_3, [_createVNode(_component_performance_card, {
    title: $data.translate.progress,
    "overridden-overlay-classes": "border border-gray-350 bg-white",
    "monthly-daily-value": $props.monthlyDailyValue,
    "monthly-daily-options": $options.monthlyDailyOptions,
    "sub-title": $data.translate.eachDashboardsScoreOverTime,
    "use-default-export": false,
    onProgressTypeChanged: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('progressTypeChanged', $event);
    })
  }, {
    content: _withCtx(function () {
      return [_createElementVNode("div", null, [_createVNode(_component_risma_line_chart, {
        labels: $props.performanceLineData.labels.labels,
        datasets: $props.performanceLineData.data,
        options: $options.modifiedLineChartOptions,
        "local-storage-key": $data.lineChartStorageKey
      }, null, 8 /* PROPS */, ["labels", "datasets", "options", "local-storage-key"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "monthly-daily-value", "monthly-daily-options", "sub-title"])])])]);
}