import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment, withModifiers as _withModifiers, renderList as _renderList } from "vue";
var _hoisted_1 = {
  class: "flex w-full justify-between mb-4"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 2
};
var _hoisted_4 = {
  key: 3
};
var _hoisted_5 = {
  key: 2
};
var _hoisted_6 = {
  key: 4
};
var _hoisted_7 = {
  key: 5
};
var _hoisted_8 = ["innerHTML"];
var _hoisted_9 = {
  class: "px-4 min-w-300px"
};
var _hoisted_10 = {
  class: "review-date"
};
var _hoisted_11 = {
  class: "review-date"
};
var _hoisted_12 = {
  key: 0
};
var _hoisted_13 = {
  class: "flex pb-4 w-full"
};
var _hoisted_14 = {
  class: "w-1/2 mr-4"
};
var _hoisted_15 = ["value"];
var _hoisted_16 = {
  class: "w-1/2 ml-4"
};
var _hoisted_17 = {
  class: "text-lg"
};
var _hoisted_18 = {
  key: 0,
  class: "truncate"
};
var _hoisted_19 = ["href", "title"];
var _hoisted_20 = {
  class: "hidden"
};
var _hoisted_21 = {
  key: 1
};
var _hoisted_22 = ["href", "target"];
var _hoisted_23 = {
  key: 4
};
var _hoisted_24 = {
  key: 5,
  class: "mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_text_box = _resolveComponent("text-box");
  var _component_risma_file_upload = _resolveComponent("risma-file-upload");
  var _component_attachment_item = _resolveComponent("attachment-item");
  var _component_collapsible_panel = _resolveComponent("collapsible-panel");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_main_well = _resolveComponent("main-well");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  return _openBlock(), _createElementBlock("div", null, [$data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: $data.notificationType,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.notificationMessage = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.notificationMessage), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), $options.information || $data.alertInformation ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: "warning"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.information) + _toDisplayString($data.alertInformation), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $options.title
  }, null, 8 /* PROPS */, ["title"]), $data.isLoaded ? (_openBlock(), _createBlock(_component_main_well, {
    key: 2,
    class: "mb-3"
  }, {
    default: _withCtx(function () {
      var _$options$latestRevie, _$options$latestRevie2;
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createVNode(_component_risma_title, {
        title: "".concat($options.toLocalDate($data.control.deadline), " - ").concat($data.control.cpath, " ").concat($data.control.title),
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), $data.control.completedUserid ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString("".concat($data.translate.controlCompletedBy, " ").concat($options.findUserNameById($data.control.completedUserid), " ").concat($data.translate.on, " ").concat($options.toLocalDateTime($data.control.completed))), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("b", null, _toDisplayString($data.translate.comment), 1 /* TEXT */), (_$options$latestRevie = $options.latestReview) !== null && _$options$latestRevie !== void 0 && _$options$latestRevie.comment ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.completedCommentsFormat), {
        key: 1
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.latestReview.comment), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : $data.control.completedComments ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($data.control.completedComments), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($data.translate.notSet), 1 /* TEXT */)), _ctx.getSettingValue('feature.controls_efficiency_enabled') ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 4
      }, [_createElementVNode("b", null, _toDisplayString($data.translate.effectivenessAssessment), 1 /* TEXT */), $options.latestReviewCompletedEfficiencyColor ? (_openBlock(), _createBlock(_component_traffic_light, {
        key: 0,
        color: $options.latestReviewCompletedEfficiencyColor,
        size: "small"
      }, null, 8 /* PROPS */, ["color"])) : $options.controlReviewCompletedEfficiencyColor ? (_openBlock(), _createBlock(_component_traffic_light, {
        key: 1,
        color: $options.controlReviewCompletedEfficiencyColor,
        size: "small"
      }, null, 8 /* PROPS */, ["color"])) : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($data.translate.notSet), 1 /* TEXT */)), _createElementVNode("b", null, _toDisplayString($data.translate.effectivenessAssessmentComment), 1 /* TEXT */), (_$options$latestRevie2 = $options.latestReview) !== null && _$options$latestRevie2 !== void 0 && _$options$latestRevie2.completedEfficiencyComment ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.completedCommentsFormat), {
        key: 3
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.latestReview.completedEfficiencyComment), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : $data.control.completedEfficiencyComment ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString($data.control.completedEfficiencyComment), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString($data.translate.notSet), 1 /* TEXT */))], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), _ctx.getSettingValue('risma.show_expected_evidence') && $data.control.expectedEvidence ? (_openBlock(), _createElementBlock("p", {
        key: 5,
        innerHTML: $data.translate.expectedEvidence + ' ' + $data.control.expectedEvidence
      }, null, 8 /* PROPS */, _hoisted_8)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_9, [_createElementVNode("p", _hoisted_10, _toDisplayString("".concat($data.translate.completed, " ").concat($options.toLocalDateTime($data.control.completed))), 1 /* TEXT */), _createElementVNode("p", _hoisted_11, _toDisplayString("".concat($data.translate.reviewDeadline, " ").concat($options.toLocalDateTime($data.control.reviewDeadline))), 1 /* TEXT */)])]), $data.control.reviewStatus && $data.control.reviewStatus !== 'approved' ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createVNode(_component_text_box, {
        text: $data.comment,
        placeholder: $data.translate.optionallyAddComment,
        label: $data.translate.optionallyAddComment,
        class: "mb-3",
        onUpdated: $options.commentUpdated
      }, null, 8 /* PROPS */, ["text", "placeholder", "label", "onUpdated"]), _createVNode(_component_collapsible_panel, null, {
        header: _withCtx(function () {
          return [_createVNode(_component_risma_title, {
            title: $data.translate.addAttachment,
            type: "medium",
            class: "text-blue-750 inline-block"
          }, null, 8 /* PROPS */, ["title"])];
        }),
        content: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("form", {
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(function () {}, ["prevent"]))
          }, [_createVNode(_component_risma_file_upload, {
            "show-uploaded-files": false,
            "use-form-data": true,
            "use-raw-file-content": true,
            onUploadFiles: $options.uploadFormDataFiles,
            onInput: $options.uploadFiles
          }, null, 8 /* PROPS */, ["onUploadFiles", "onInput"])], 32 /* NEED_HYDRATION */), $data.showProgressBar ? (_openBlock(), _createElementBlock("progress", {
            key: 0,
            max: 1,
            value: $data.uploadingValue,
            class: "w-full"
          }, null, 8 /* PROPS */, _hoisted_15)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_16, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.uploadedFiles, function (file, idx) {
            return _openBlock(), _createBlock(_component_attachment_item, {
              key: "".concat(file.filename, "_").concat(idx),
              file: file,
              onOnDelete: function onOnDelete($event) {
                return $options.handleRemoveAttachment(file.id);
              }
            }, null, 8 /* PROPS */, ["file", "onOnDelete"]);
          }), 128 /* KEYED_FRAGMENT */))])])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_buttons_row, {
        class: "justify-end",
        data: $options.buttonsRowData
      }, {
        "button-save": _withCtx(function () {
          return [_createVNode(_component_feather_icon, {
            icon: "save",
            class: "stroke-3 mr-1"
          })];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data"])])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("p", _hoisted_17, _toDisplayString($data.translate.reviewHistory), 1 /* TEXT */), $options.loadDatatables ? (_openBlock(), _createBlock(_component_risma_datatable, {
    key: 3,
    "dataset-main": _ctx.datasetSlice,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "wrapper-height-sticky": $data.control.customFields.length ? 0 : 800,
    "state-local": $data.stateLocal,
    onOnStateChanged: _ctx.onStateChanged
  }, {
    commentsAndUrl: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_createElementVNode("p", null, _toDisplayString(item.field.textBefore), 1 /* TEXT */), item.field.url ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createElementVNode("a", {
        target: "_blank",
        href: item.field.url,
        title: item.field.title,
        rel: "noopener noreferrer"
      }, [_createVNode(_component_feather_icon, {
        icon: "link",
        width: "15",
        height: "15",
        class: "text-gray-610 stroke-2 inline-block"
      }), _createTextVNode(" " + _toDisplayString(item.field.title), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_19)])) : _createCommentVNode("v-if", true)];
    }),
    effectiveness: _withCtx(function (_ref2) {
      var _item$field;
      var item = _ref2.item;
      return [item !== null && item !== void 0 && (_item$field = item.field) !== null && _item$field !== void 0 && _item$field.color ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createVNode(_component_traffic_light, {
        color: item.field.color
      }, null, 8 /* PROPS */, ["color"]), _createElementVNode("p", _hoisted_20, _toDisplayString(item.field.value) + "-" + _toDisplayString(_ctx.$trans(item.field.label)), 1 /* TEXT */)], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock("span", _hoisted_21))];
    }),
    attachments: _withCtx(function (_ref3) {
      var item = _ref3.item;
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.field, function (file, index) {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: index
        }, [_createVNode(_component_feather_icon, {
          icon: "file",
          width: "15",
          height: "15",
          class: "m-auto text-gray-610 stroke-2 inline-block"
        }), _createElementVNode("a", {
          href: file.url,
          target: file.target
        }, _toDisplayString(file.fileName), 9 /* TEXT, PROPS */, _hoisted_22), _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1 /* HOISTED */))], 64 /* STABLE_FRAGMENT */);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "wrapper-height-sticky", "state-local", "onOnStateChanged"])) : (_openBlock(), _createElementBlock("div", _hoisted_23, [_createElementVNode("p", null, _toDisplayString($data.translate.controlHasNoReviewHistory), 1 /* TEXT */)])), $options.loadDatatables && $data.control.customFieldValues.length && $options.rightActivityType ? (_openBlock(), _createElementBlock("div", _hoisted_24, [_createVNode(_component_risma_title, {
    title: $data.translate.customFields,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_activity_custom_fields, {
    class: "mb-8",
    "activity-type": $options.rightActivityType,
    "custom-field-values": $data.control.customFieldValues,
    readonly: true,
    onChanged: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dataUpdated');
    })
  }, null, 8 /* PROPS */, ["activity-type", "custom-field-values"])])) : _createCommentVNode("v-if", true)]);
}