function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import MainWell from '@/components/Atoms/Containers/MainWell';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
import InputField from '@/components/Atoms/Inputs/InputField';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import LevelOfThreat from '@/components/Molecules/Risk/LevelOfThreat';
import Consequence from '@/components/Molecules/Risk/Consequence';
import * as RiskApi from '@/api/risk/risk';
import { RiskStatus } from '@/constants/risks/RiskStatus';
import { ControlValidationMessages } from '@/constants/ControlValidationMessages';
import { defaultProbabilityLabels, defaultConsequenceLabels, defaultFirstColumn, defaultLevelOfThreatOptions, defaultAxisLabels } from '@/constants/risks/RiskPeriodDefaultData';
var ENTERPRISE_RISK_MANAGEMENT_TYPE = 1;
var PROBABILITY = {
  CHILDREN_KEY: 'likelihoodColumns',
  LABELS_KEY: 'probabilityLabels'
};
var CONSEQUENCE = {
  CHILDREN_KEY: 'impactColumns',
  LABELS_KEY: 'consequenceLabels'
};
export default {
  name: 'RiskAdminPeriodDescription',
  introduction: 'Risk admin period description component',
  description: '',
  token: '<risk-admin-period-description></risk-admin-period-description>',
  components: {
    MainWell: MainWell,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    LevelOfThreat: LevelOfThreat,
    Consequence: Consequence,
    RismaTitle: RismaTitle,
    ButtonsRow: ButtonsRow,
    ToggleMenu: ToggleMenu,
    InputField: InputField,
    Checkboxes: Checkboxes
  },
  emits: ['refreshRiskProjects'],
  data: function data() {
    return {
      defaultLevelOfThreatOptions: defaultLevelOfThreatOptions,
      CONSEQUENCE: CONSEQUENCE,
      PROBABILITY: PROBABILITY,
      isNewMode: false,
      isLoaded: false,
      activity: {
        title: ''
      },
      riskProjects: [],
      currentRiskProject: {},
      projectPeriodLatest: {},
      openPeriod: false,
      resetApprovals: true,
      dataUpdate: {},
      errors: {},
      translate: getTranslate['RiskAdminPeriodDescription']()
    };
  },
  computed: {
    isPageDirty: function isPageDirty() {
      // check for empty dataUpdate object
      return !Object.keys(this.dataUpdate).length;
    },
    isPeriodClosed: function isPeriodClosed() {
      return this.activity.status === RiskStatus.CLOSED;
    },
    isPeriodOpen: function isPeriodOpen() {
      return this.activity.status === RiskStatus.OPEN;
    },
    path: function path() {
      return this.$route.params.path;
    },
    projectId: function projectId() {
      return this.$route.params.projectId;
    },
    openNewPeriodAndSavePrevious: function openNewPeriodAndSavePrevious() {
      return this.openPeriod && !this.activity.isCurrent;
    },
    savePeriodTitle: function savePeriodTitle() {
      return this.openNewPeriodAndSavePrevious ? this.translate.saveAndOpen : this.translate.save;
    },
    isPeriodEditable: function isPeriodEditable() {
      return this.isNewMode || this.isDraft;
    },
    isDraft: function isDraft() {
      return this.activity.status === RiskStatus.DRAFT;
    },
    riskApprovalEnabled: function riskApprovalEnabled() {
      return this.currentRiskProject.riskApproval;
    },
    toggleMenuOptions: function toggleMenuOptions() {
      var _this = this;
      return [{
        id: 1,
        title: "".concat(this.translate.addDefault, " CIA ").concat(this.translate.values),
        icon: 'plus',
        action: function action() {
          _this.changeDataByToggle('CIA');
        }
      }, {
        id: 2,
        title: "".concat(this.translate.addDefault, " FIT ").concat(this.translate.values),
        icon: 'plus',
        action: function action() {
          _this.changeDataByToggle('FIT');
        }
      }];
    },
    buttonsRowData: function buttonsRowData() {
      var list = [{
        text: this.translate.cancel,
        type: 'util',
        clickEvent: this.cancelAction
      }];
      if (this.isNewMode && !this.isPeriodClosed) {
        list.push({
          text: this.savePeriodTitle,
          type: 'save',
          clickEvent: this.createNewActivity
        });
      }
      if (!this.isNewMode && !this.isPeriodClosed) {
        list.push({
          text: this.savePeriodTitle,
          type: 'save',
          disabled: this.isPageDirty && this.activity.isCurrent,
          clickEvent: this.updateData
        });
      }
      return list;
    }
  },
  beforeMount: function beforeMount() {
    var _this2 = this;
    this.getRiskProjects().then(function () {
      _this2.getAllData();
    });
  },
  methods: {
    getDatafromLabels: function getDatafromLabels(labels) {
      return labels.map(function (item, index) {
        return {
          column: index,
          value: index,
          label: item
        };
      });
    },
    changeDataByToggle: function changeDataByToggle(key) {
      var _this3 = this;
      this.activity.consequenceLevels = defaultConsequenceLabels[key].length;
      this.activity.probabilityLevels = defaultProbabilityLabels[key].length;
      this.activity.probabilityRows = [];
      this.activity.consequenceRows = [];
      this.activity.onlyColumns = [];
      var impactColumns = this.getDatafromLabels(defaultConsequenceLabels[key]);
      var likelihoodColumns = this.getDatafromLabels(defaultProbabilityLabels[key]);
      defaultFirstColumn[key].forEach(function (item, index) {
        var data = {
          id: null,
          label: item,
          index: index
        };
        _this3.activity.probabilityRows.push(_objectSpread({}, data));
        _this3.activity.consequenceRows.push(_objectSpread({}, data));
        _this3.activity.onlyColumns.push(data);
        _this3.activity.consequenceRows[index].impactColumns = impactColumns;
        _this3.activity.probabilityRows[index].likelihoodColumns = likelihoodColumns;
      });
      this.setDefaultDataUpdate();
    },
    getRiskProjects: function getRiskProjects() {
      var _this4 = this;
      this.isLoaded = false;
      return RiskApi.getRiskProjects().then(function (_ref) {
        var list = _ref.list;
        _this4.riskProjects = list.filter(function (item) {
          return item.type !== ENTERPRISE_RISK_MANAGEMENT_TYPE;
        });
        _this4.currentRiskProject = _this4.riskProjects.find(function (item) {
          return +item.id === +_this4.projectId;
        });
        _this4.projectPeriodLatest = _this4.currentRiskProject.projectPeriods.find(function (item) {
          return item.isCurrent;
        });
      }).catch(function (e) {
        throw e;
      });
    },
    updateProperty: function updateProperty(propertyName, value) {
      this.activity[propertyName] = value;
      this.dataUpdate[propertyName] = value;
    },
    getAllData: function getAllData() {
      var _this5 = this;
      this.isNewMode = this.checkIsNew();
      var promises = [];
      if (!this.isNewMode) {
        promises.push(this.getPeriodData());
      }
      this.isLoaded = false;
      return Promise.all(promises).then(function () {
        _this5.isLoaded = true;
        if (_this5.isNewMode) {
          _this5.getNewPeriodData();
          _this5.setDefaultDataUpdate();
          _this5.setTitleFocus();
        }
      }).catch(function (e) {
        return _this5.handleGenericError(e);
      });
    },
    handleGenericError: function handleGenericError(e) {
      var message = this.translate.thereWasAnError;
      if (e && e.response && e.response.error) {
        message += ': ' + e.response.error;
      }
      this.$notify({
        title: message,
        type: 'error'
      });
    },
    onLevelChanged: function onLevelChanged(_ref2) {
      var labelName = _ref2.labelName,
        options = _ref2.options;
      this.activity.threatLevelsLabel = labelName;
      this.dataUpdate.threatLevelsLabel = labelName;
      this.dataUpdate.threatLevels = options.map(function (item) {
        return item.title;
      });
      this.activity.threatLevels = options;
    },
    getNewPeriodData: function getNewPeriodData() {
      this.activity = {
        title: '',
        id: null,
        consequenceLevels: this.projectPeriodLatest.consequenceLevels,
        consequenceRows: _toConsumableArray(this.projectPeriodLatest.consequenceRows),
        consequenceLabel: this.translate.consequence,
        onlyColumns: _toConsumableArray(this.projectPeriodLatest.consequenceRows),
        probabilityRows: _toConsumableArray(this.projectPeriodLatest.probabilityRows),
        probabilityLevels: this.projectPeriodLatest.probabilityLevels,
        probabilityLabel: this.translate.probability,
        threatLevels: this.projectPeriodLatest.threatLevels,
        threatLevelsLabel: this.translate.levelOfThreat
      };
      // No need for passing ids
      this.activity.consequenceRows.map(function (item) {
        item.id = null;
      });
      this.activity.probabilityRows.map(function (item) {
        item.id = null;
      });
    },
    setDefaultDataUpdate: function setDefaultDataUpdate() {
      this.dataUpdate = _objectSpread({}, this.activity);
      if (this.isNewMode) {
        this.dataUpdate.threatLevels = this.defaultLevelOfThreatOptions.map(function (item) {
          return item.title;
        });
      } else {
        this.dataUpdate.threatLevels = this.activity.threatLevels.map(function (item) {
          return item.title;
        });
      }
      this.dataUpdate.assessmentRows = this.getDefaultAssessmentRows(this.activity);
    },
    setDefaultPeriodFields: function setDefaultPeriodFields(period) {
      if (!period.consequenceLabel) {
        period.consequenceLabel = defaultAxisLabels.CONSEQUENCE;
      }
      if (!period.probabilityLabel) {
        period.probabilityLabel = defaultAxisLabels.PROBABILITY;
      }
      return period;
    },
    setTitleFocus: function setTitleFocus() {
      var _this6 = this;
      this.$nextTick(function () {
        var titleComponent = _this6.$refs.detailTitle;
        if (titleComponent) titleComponent.$el.focus();
      });
    },
    checkIsNew: function checkIsNew() {
      var result = false;
      if (this.path === 'new') {
        result = true;
      }
      return result;
    },
    getPeriodData: function getPeriodData() {
      var _this7 = this;
      this.isLoaded = false;
      return RiskApi.getPeriod(this.projectId, this.path).then(function (data) {
        _this7.isLoaded = true;
        _this7.activity = data;
        _this7.openPeriod = data.isCurrent;
        _this7.setDefaultPeriodFields(_this7.activity);
      }).catch(function (e) {
        _this7.handleGenericError(e);
        throw e;
      });
    },
    isValidSave: function isValidSave() {
      var result = true;
      this.errors = {};

      // Validation for title
      if (!this.activity.title) {
        this.errors.title = ControlValidationMessages.TITLE_IS_REQUIRED;
      }
      if (this.dataUpdate.assessmentRows && !this.areAssessmentRowsValid(this.dataUpdate.assessmentRows)) {
        this.errors.consequence = ControlValidationMessages.CONSEQUENCE_PARAMETER_ERROR;
      }
      if (Object.keys(this.errors).length) {
        result = false;
        this.$scrollTo(document.body, 100);
      }
      return result;
    },
    areAssessmentRowsValid: function areAssessmentRowsValid(assessmentRows) {
      return assessmentRows.every(function (row) {
        var label = row.label,
          consequenceLabels = row.consequenceLabels,
          probabilityLabels = row.probabilityLabels;
        var isLabelNotEmpty = label.trim() !== '';
        var areConsequencesValid = !consequenceLabels.some(function (label) {
          return !label;
        });
        var areProbabilitiesValid = !probabilityLabels.some(function (label) {
          return !label;
        });
        return isLabelNotEmpty && areConsequencesValid && areProbabilitiesValid;
      });
    },
    createNewActivity: function createNewActivity() {
      var _this8 = this;
      if (!this.isValidSave()) {
        return;
      }
      this.isLoaded = false;
      var payload = this.preparePayload();
      payload.status = this.prepareStatus();
      RiskApi.createPeriod(this.projectId, payload).then(function () {
        _this8.$emit('refreshRiskProjects');
        _this8.isLoaded = true;
        _this8.returnToPrevPage();
      }).catch(function (e) {
        return _this8.handleError(e);
      });
    },
    updateData: function updateData() {
      var _this9 = this;
      if (!this.isValidSave()) {
        return;
      }
      if (this.openNewPeriodAndSavePrevious && this.isConsequenceOrProbabilityDirty()) {
        this.$confirm(this.translate.warning, [this.translate.warningMessage, this.translate.futhermoreThis, this.translate.doYouWish], function (res) {
          return res && _this9.updateActivity();
        });
        return;
      }
      this.updateActivity();
    },
    isConsequenceOrProbabilityDirty: function isConsequenceOrProbabilityDirty() {
      var updatedPeriodData = this.dataUpdate.assessmentRows || this.getDefaultAssessmentRows(this.activity);
      var currentPeriodData = this.getDefaultAssessmentRows(this.projectPeriodLatest);
      return !(
      // if they have equal row length

      updatedPeriodData.length === currentPeriodData.length && updatedPeriodData.every(function (item, index) {
        return (
          // if labels are not changed
          item.label === currentPeriodData[index].label &&
          // they have equal length
          item.consequenceLabels.length === currentPeriodData[index].consequenceLabels.length && item.probabilityLabels.length === currentPeriodData[index].probabilityLabels.length &&
          // if consequence labels are changed
          item.consequenceLabels.every(function (label, labelIndex) {
            return label === currentPeriodData[index].consequenceLabels[labelIndex];
          }) &&
          // if consequence labels are changed
          item.probabilityLabels.every(function (label, labelIndex) {
            return label === currentPeriodData[index].probabilityLabels[labelIndex];
          })
        );
      }));
    },
    handleError: function handleError(e) {
      this.handleGenericError(e);
      this.isLoaded = true;
    },
    updateActivity: function updateActivity() {
      var _this10 = this;
      this.isLoaded = false;
      var payload = this.preparePayload();
      payload.status = this.prepareStatus();
      payload.resetApprovals = this.resetApprovals;
      RiskApi.updatePeriod(this.projectId, this.path, payload).then(function () {
        _this10.isLoaded = true;
        _this10.resetPeriod();
        _this10.$notify({
          title: _this10.translate.riskUpdated,
          type: 'success'
        });
      }).catch(function () {
        return _this10.handleError();
      });
    },
    prepareStatus: function prepareStatus() {
      return this.openPeriod ? RiskStatus.OPEN : RiskStatus.DRAFT;
    },
    resetPeriod: function resetPeriod() {
      this.dataUpdate = {};
      this.activity = {
        title: ''
      };
      this.getPeriodData();
    },
    preparePayload: function preparePayload() {
      return _objectSpread({}, this.dataUpdate);
    },
    cancelAction: function cancelAction() {
      this.returnToPrevPage();
    },
    returnToPrevPage: function returnToPrevPage() {
      this.$router.go(-1);
    },
    getDefaultAssessmentRows: function getDefaultAssessmentRows(activity) {
      var assessmentRows = [];
      activity.consequenceRows.forEach(function (item, index) {
        var data = {
          label: item.label,
          index: item.index,
          consequenceLabels: item.impactColumns.map(function (item) {
            return item.label;
          }),
          probabilityLabels: activity.probabilityRows[index].likelihoodColumns.map(function (item) {
            return item.label;
          })
        };
        if (item.id) {
          data.id = item.id;
        }
        assessmentRows.push(data);
      });
      return assessmentRows;
    },
    onDataChanged: function onDataChanged(e, key) {
      this.activity.onlyColumns = _toConsumableArray(e.items);
      this.refreshDataUpdate(e.items, key);
    },
    refreshDataUpdate: function refreshDataUpdate(rows, key) {
      var _this11 = this;
      var childrenLength = rows[0].children.length || 0;
      if (!this.dataUpdate.assessmentRows) {
        this.dataUpdate.assessmentRows = this.getDefaultAssessmentRows(this.activity);
      }
      if (key === CONSEQUENCE.LABELS_KEY) {
        this.dataUpdate.consequenceLevels = childrenLength;
      } else {
        this.dataUpdate.probabilityLevels = childrenLength;
      }
      rows.forEach(function (item, index) {
        var childrenLabels = item.children.map(function (item) {
          return item.value;
        });
        if (_this11.dataUpdate.assessmentRows[index]) {
          if (item.index > _this11.dataUpdate.assessmentRows[index].index) {
            _this11.dataUpdate.assessmentRows.splice(index, 1);
          } else {
            _this11.dataUpdate.assessmentRows[index].label = item.label;
            _this11.dataUpdate.assessmentRows[index][key] = childrenLabels;
          }
        } else {
          var data = {
            label: item.label,
            index: item.index,
            consequenceLabels: childrenLabels,
            probabilityLabels: childrenLabels
          };
          if (item.id) {
            data.id = item.id;
          }
          _this11.dataUpdate.assessmentRows.push(data);
        }
      });
      if (this.dataUpdate.assessmentRows.length > rows.length) {
        this.dataUpdate.assessmentRows.splice(rows.length, this.dataUpdate.assessmentRows.length - rows.length);
      }
    }
  }
};