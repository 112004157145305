// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vertical-menu-wrapper[data-v-300fc569] {
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-shadow: -16px 0px 0px 40px #ffffff, -16px -48px 0px 40px #ffffff;
}
.vertical-menu-wrapper[data-v-300fc569]::-webkit-scrollbar {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
