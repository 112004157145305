import { ResizeOptions } from '@/constants/ResizeOptions';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { generateKey } from '@/utils/Utils';
export default {
  name: 'TextBox',
  token: '<text-box></text-box>',
  props: {
    label: {
      required: false,
      default: '',
      type: String,
      note: 'Label text'
    },
    minHeight: {
      required: false,
      default: 144,
      type: Number,
      note: 'Minimum height of the textarea'
    },
    maxHeight: {
      required: false,
      default: 400,
      type: Number,
      note: 'Maximum height of the textarea'
    },
    text: {
      required: false,
      default: '',
      type: String,
      note: 'The text needed to be displayed'
    },
    placeholder: {
      required: false,
      default: '',
      type: String,
      note: 'A placeholder text'
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Disable text area, e.g. for showing read-only pages'
    },
    resize: {
      required: false,
      type: String,
      default: ResizeOptions.Y,
      note: 'Resize option'
    },
    error: {
      required: false,
      type: String,
      default: '',
      note: 'Error message'
    },
    maxLength: {
      required: false,
      type: Number,
      default: undefined,
      note: 'Maximum number of characters in the text box'
    },
    localStorageKey: {
      type: String,
      required: false,
      default: '',
      note: 'Name of localStorage key'
    }
  },
  emits: ['updated', 'blur', 'focus'],
  setup: function setup(props) {
    return {
      storedYDimension: getLocalStorageComputed("".concat(props.localStorageKey), {
        height: null
      }, true)
    };
  },
  data: function data() {
    return {
      editedText: '',
      isFocused: false,
      resizeObserver: null,
      isMouseDown: false
    };
  },
  computed: {
    id: function id() {
      return this.label ? generateKey() : null;
    },
    textareaClasses: function textareaClasses() {
      var resize = this.disabled ? ResizeOptions.NONE : this.resize;
      return ['block w-full p-2 border rounded-md outline-none glob-b1 text-rm-text', 'border-rm-grey hover:border-rm-signal-grey-dark focus:border-rm-active-blue transition duration-300', 'placeholder:text-rm-grey', resize, {
        'disabled bg-rm-wisp cursor-not-allowed': this.disabled,
        'border-rm-grey hover:border-rm-grey': this.disabled,
        'error border-rm-signal-red hover:border-rm-signal-red focus:border-rm-signal-red': this.error
      }];
    },
    counter: function counter() {
      var maxLength = this.maxLength ? "/".concat(this.maxLength) : '';
      return "".concat(this.editedText.length).concat(maxLength);
    }
  },
  watch: {
    text: {
      handler: function handler() {
        this.editedText = this.text || '';
      }
    }
  },
  mounted: function mounted() {
    this.editedText = this.text || '';
    this.initiate();
  },
  beforeUnmount: function beforeUnmount() {
    var _this = this,
      _this$resizeObserver;
    this.$refs.textarea.removeEventListener('input', function () {
      return _this.adjustHeight();
    });
    (_this$resizeObserver = this.resizeObserver) === null || _this$resizeObserver === void 0 || _this$resizeObserver.unobserve(this.$refs.textarea);
  },
  methods: {
    initiate: function initiate() {
      var _this2 = this;
      if (this.localStorageKey) {
        this.setInitialDimensions();
        this.addMouseListeners();
        this.setResizeObserver();
      }
      this.adjustHeight();
      this.$refs.textarea.addEventListener('input', function () {
        return _this2.adjustHeight();
      });
    },
    setInitialDimensions: function setInitialDimensions() {
      var _this$storedYDimensio;
      var initialHeight = (_this$storedYDimensio = this.storedYDimension.height) !== null && _this$storedYDimensio !== void 0 ? _this$storedYDimensio : this.minHeight;
      this.$refs.textarea.style.height = initialHeight + 'px';
    },
    addMouseListeners: function addMouseListeners() {
      var _this3 = this;
      this.$refs.textarea.addEventListener('mousedown', function () {
        return _this3.isMouseDown = true;
      });
      document.addEventListener('mouseup', function () {
        return _this3.isMouseDown = false;
      });
    },
    setResizeObserver: function setResizeObserver() {
      var _this4 = this;
      this.resizeObserver = new ResizeObserver(function (entries) {
        if (!_this4.isMouseDown) return;
        _this4.storedYDimension = {
          height: entries[0].borderBoxSize[0].blockSize
        };
      });
      this.resizeObserver.observe(this.$refs.textarea);
    },
    adjustHeight: function adjustHeight() {
      var _this5 = this;
      if (this.storedYDimension.height) return;
      var currentHeight = Math.max(this.minHeight, this.$refs.textarea.offsetHeight);
      this.$refs.textarea.style.height = '0px';
      this.$nextTick(function () {
        var height = _this5.$refs.textarea.scrollHeight < currentHeight ? Math.min(_this5.maxHeight, currentHeight) : _this5.$refs.textarea.scrollHeight > _this5.maxHeight ? _this5.maxHeight : _this5.$refs.textarea.scrollHeight;
        _this5.$refs.textarea.style.height = height + 'px';
      });
    },
    pushEvent: function pushEvent() {
      if (this.disabled) return;
      this.$emit('updated', this.editedText);
    },
    onFocus: function onFocus() {
      this.isFocused = true;
      this.$emit('focus', this.editedText);
    },
    onBlur: function onBlur() {
      this.isFocused = false;
      this.$emit('blur', this.editedText);
    }
  }
};