import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_required_fields_notification = _resolveComponent("required-fields-notification");
  var _component_delegation_well = _resolveComponent("delegation-well");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  var _component_multiple_questionnaire_selector = _resolveComponent("multiple-questionnaire-selector");
  var _component_contract_type_selector = _resolveComponent("contract-type-selector");
  var _component_dep_well = _resolveComponent("dep-well");
  return _openBlock(), _createElementBlock("div", null, [$data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "info",
    closeable: false
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.notificationMessage), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_required_fields_notification, {
    "missing-required-fields": $options.missingRequiredFields
  }, null, 8 /* PROPS */, ["missing-required-fields"]), $options.isUserLocked ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    closeable: false,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.thisActivityIsReadonlyAsYourUserIsConfigu), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_dep_well, {
    "is-loaded": $data.isLoaded,
    item: $options.enhancementContract,
    "dep-header-props": $options.depHeaderProps,
    "read-only": $options.isReadOnly,
    "update-idx": $data.updateIdx,
    "local-storage-key": "relations-contract",
    onUpdatingAttachments: $options.updatingAttachments,
    "onUpdate:immediately": $options.saveChangesImmediately,
    onFileUpdate: $options.onFileUpdate,
    onPropertyChanged: $options.contractPropertyChanged,
    onSaveStatusClicked: $options.saveStatusClicked,
    onValidationError: $options.handleShowNotification,
    onGetActivityData: $options.getActivityData,
    onDeleteContract: $options.deleteContract,
    onChangeTab: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeTab', $event);
    }),
    "onUpdate:isPageWithCustomUpdateDirty": $options.handleUpdateForCustomPageUpdate
  }, {
    delegation: _withCtx(function () {
      return [$data.contract ? (_openBlock(), _createBlock(_component_delegation_well, {
        key: 0,
        "model-value": $options.delegationData,
        "read-only": $options.isReadOnly,
        organisations: _ctx.organisations,
        "current-activity-type": $options.currentActivityType,
        onPropertyChanged: $options.saveChangesImmediately,
        onAddNewItem: _ctx.updateDelegationList
      }, null, 8 /* PROPS */, ["model-value", "read-only", "organisations", "current-activity-type", "onPropertyChanged", "onAddNewItem"])) : _createCommentVNode("v-if", true)];
    }),
    details: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        type: "medium",
        title: $data.translate.activityType
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_activity_type_selector, {
        "activity-types": _ctx.activityTypes,
        "model-value": $data.contract.activityTypeId,
        disabled: $options.isReadOnly,
        "required-text": $data.translate.pleaseFillOutThisField,
        class: "mb-4",
        "can-be-reset": false,
        "onUpdate:modelValue": $options.activityTypeChanged
      }, null, 8 /* PROPS */, ["activity-types", "model-value", "disabled", "required-text", "onUpdate:modelValue"]), $data.contract.id && $data.questionnaires.length ? (_openBlock(), _createBlock(_component_multiple_questionnaire_selector, {
        key: 0,
        class: "mb-4 pb-6 border-b-2 border-gray-250",
        "questionnaire-options": $data.questionnaires,
        disabled: !!$options.isReadOnly,
        "selected-questionnaires": $data.contract.questionnaires,
        onAddQuestionnaire: $options.addQuestionnaire,
        onRemoveQuestionnaire: $options.removeQuestionnaire
      }, null, 8 /* PROPS */, ["questionnaire-options", "disabled", "selected-questionnaires", "onAddQuestionnaire", "onRemoveQuestionnaire"])) : _createCommentVNode("v-if", true), _createVNode(_component_contract_type_selector, {
        "contract-id": +$props.id,
        contracts: $data.contracts,
        "contract-type": $data.contract.contractType,
        "parent-id": $data.contract.parentId,
        "onUpdate:contractType": $options.updateContractType,
        "onUpdate:list": $options.updateParentIdList
      }, null, 8 /* PROPS */, ["contract-id", "contracts", "contract-type", "parent-id", "onUpdate:contractType", "onUpdate:list"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-loaded", "item", "dep-header-props", "read-only", "update-idx", "onUpdatingAttachments", "onUpdate:immediately", "onFileUpdate", "onPropertyChanged", "onSaveStatusClicked", "onValidationError", "onGetActivityData", "onDeleteContract", "onUpdate:isPageWithCustomUpdateDirty"])]);
}