import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = ["href"];
var _hoisted_2 = {
  class: "flex align-top mt-2 cursor-pointer text-base text-gray-750 font-semibold mr-3 flex-grow"
};
var _hoisted_3 = {
  class: "mt-2"
};
var _hoisted_4 = {
  class: "ml-8 glob-l1 text-rm-signal-grey-dark mt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_pdf_document = _resolveComponent("pdf-document");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: "RISMA AI PARTNER",
    "button-ok-text": $data.translate.accept,
    "button-dismiss-text": $data.translate.cancel,
    "ok-button-disabled": $data.okButtonDisabled,
    "show-buttons": false,
    "modal-fixed-width": "700px",
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_pdf_document, {
        "src-pdf": $options.pdfSrc,
        class: "min-h-28"
      }, null, 8 /* PROPS */, ["src-pdf"])];
    }),
    footer: _withCtx(function () {
      return [_createElementVNode("a", {
        href: $options.pdfSrc,
        download: "",
        class: "group cursor-pointer hover:underline mt-4 hover:bg-rm-grey-light block w-fit rounded-sm active:bg-rm-active-blue--10"
      }, [_createVNode(_component_feather_icon, {
        icon: "download",
        width: 20,
        height: 20,
        class: "transition stroke-2 text-rm-grey group-hover:text-rm-text group-active:text-rm-active-blue"
      })], 8 /* PROPS */, _hoisted_1), !$props.onlyDoc ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createElementVNode("label", _hoisted_2, [_createVNode(_component_checkboxes, {
        modelValue: $data.isRead,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.isRead = $event;
        }),
        class: "-ml-2",
        options: $data.options,
        onUpdated: $options.handleChange
      }, null, 8 /* PROPS */, ["modelValue", "options", "onUpdated"]), _createElementVNode("span", _hoisted_3, _toDisplayString($data.translate.iHaveReadAndAccept), 1 /* TEXT */)]), _createElementVNode("p", _hoisted_4, _toDisplayString($data.translate.youMustFirstConfirm), 1 /* TEXT */), _createVNode(_component_buttons_row, {
        class: "flex justify-end mt-5",
        data: $options.buttonsRowData,
        onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(function () {}, ["stop"]), ["enter"]))
      }, null, 8 /* PROPS */, ["data"])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["button-ok-text", "button-dismiss-text", "ok-button-disabled"]);
}