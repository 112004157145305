import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  class: "glob-l1-sb"
};
var _hoisted_2 = ["placeholder"];
var _hoisted_3 = ["tabindex"];
var _hoisted_4 = {
  key: 0,
  class: "border max-h-500px overflow-y-scroll min-w-full glob-l1 z-10 bg-rm-white mt-0.65rem -ml-1px rounded-md absolute border-2"
};
var _hoisted_5 = ["value", "onMousedown"];
var _hoisted_6 = {
  key: 0,
  class: "py-2 px-2 italic"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_focus = _resolveDirective("focus");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "absolute right-0.4rem top-0.4rem min-w-100px",
    onMousedown: _cache[12] || (_cache[12] = _withModifiers(function () {
      return $options.handleExpand && $options.handleExpand.apply($options, arguments);
    }, ["stop", "prevent"]))
  }, [_createElementVNode("div", {
    class: _normalizeClass([{
      'cursor-pointer': !$props.disabled
    }, "bg-rm-white"])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["p-2 h-8 flex items-center rounded border justify-between", [!$props.disabled || $props.predefinedValue ? 'bg-rm-white' : 'pointer-events-none bg-rm-white-chalk hover:border-rm-signal-grey-dark hover:text-rm-text', $options.focusedStyles, $options.textFocusedStyles, {
      'pointer-events-none': $props.predefinedValue
    }]])
  }, [_createElementVNode("span", _hoisted_1, _toDisplayString($options.selectedValue), 1 /* TEXT */), !$props.disabled ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "chevron-down",
    class: _normalizeClass(['right-4 transform duration-500 stroke-2 ml-1', {
      'rotate-180': $data.expanded
    }])
  }, null, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)], 2 /* CLASS */), $props.withSearch && $data.expanded ? _withDirectives((_openBlock(), _createElementBlock("input", {
    key: 0,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.filterString = $event;
    }),
    placeholder: $props.placeholder,
    type: "text",
    class: "rounded-md absolute outline-none inset-px p-2 glob-l1 text-rm-text",
    onKeyup: _cache[1] || (_cache[1] = _withKeys(function () {
      return $options.hide && $options.hide.apply($options, arguments);
    }, ["esc"])),
    onKeydown: [_cache[2] || (_cache[2] = _withKeys(_withModifiers(function () {
      return $options.moveDown && $options.moveDown.apply($options, arguments);
    }, ["prevent"]), ["down"])), _cache[3] || (_cache[3] = _withKeys(_withModifiers(function () {
      return $options.moveUp && $options.moveUp.apply($options, arguments);
    }, ["prevent"]), ["up"]))],
    onKeypress: _cache[4] || (_cache[4] = _withKeys(_withModifiers(function () {
      return $options.addFromPointer && $options.addFromPointer.apply($options, arguments);
    }, ["prevent", "stop", "self"]), ["enter"])),
    onInput: _cache[5] || (_cache[5] = function ($event) {
      return $data.currentPointerIndex = -1;
    })
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2)), [[_vModelText, $data.filterString], [_directive_focus, true]]) : _createCommentVNode("v-if", true), !$props.disabled ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    ref: "options",
    tabindex: $props.tabindex,
    onMousedown: _cache[6] || (_cache[6] = _withModifiers(function () {}, ["stop"])),
    onFocus: _cache[7] || (_cache[7] = function () {
      return $options.show && $options.show.apply($options, arguments);
    }),
    onKeyup: _cache[8] || (_cache[8] = _withKeys(function () {
      return $options.hide && $options.hide.apply($options, arguments);
    }, ["esc"])),
    onKeydown: [_cache[9] || (_cache[9] = _withKeys(_withModifiers(function () {
      return $options.moveDown && $options.moveDown.apply($options, arguments);
    }, ["prevent"]), ["down"])), _cache[10] || (_cache[10] = _withKeys(_withModifiers(function () {
      return $options.moveUp && $options.moveUp.apply($options, arguments);
    }, ["prevent"]), ["up"]))],
    onKeypress: _cache[11] || (_cache[11] = _withKeys(_withModifiers(function () {
      return $options.addFromPointer && $options.addFromPointer.apply($options, arguments);
    }, ["prevent", "stop", "self"]), ["enter"]))
  }, [$data.expanded ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredOptions, function (option, index) {
    return _openBlock(), _createElementBlock("div", {
      key: option.value,
      value: option.value,
      class: _normalizeClass(["pointer py-2 px-2 cursor-pointer hover:bg-rm-active-blue--6 flex justify-between", [{
        'text-rm-active-blue bg-rm-active-blue--10 glob-l1-sb': option.value === $props.modelValue
      }, {
        'bg-rm-active-blue--6': $data.currentPointerIndex === index
      }]]),
      onMousedown: _withModifiers(function ($event) {
        return $options.handleOptionSelect(option);
      }, ["stop", "prevent"])
    }, [_renderSlot(_ctx.$slots, "option", {
      option: option
    }, function () {
      return [_createTextVNode(_toDisplayString(option.label), 1 /* TEXT */)];
    }), option.value === $props.modelValue ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      icon: "check",
      width: "18",
      height: "18",
      class: "text-rm-active-blue stroke-2"
    })) : _createCommentVNode("v-if", true)], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_5);
  }), 128 /* KEYED_FRAGMENT */)), !$options.filteredOptions.length ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($props.withSearch && $data.filterString ? $data.translate.noResultsMatchingSearchCriteria : $data.translate.listIsEmpty), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)], 40 /* PROPS, NEED_HYDRATION */, _hoisted_3)) : _createCommentVNode("v-if", true)], 32 /* NEED_HYDRATION */)), [[_directive_click_outside, $options.hide]]);
}