import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "mb-20 border border-gray-450 rounded"
};
var _hoisted_2 = {
  class: "flex justify-end mt-4 mr-4"
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  key: 1
};
var _hoisted_7 = {
  key: 1
};
var _hoisted_8 = {
  key: 1
};
var _hoisted_9 = {
  key: 0
};
var _hoisted_10 = {
  key: 1
};
var _hoisted_11 = ["innerHTML"];
var _hoisted_12 = {
  key: 2,
  id: "tableContainer",
  class: "-mt-5"
};
var _hoisted_13 = {
  class: "mb-3 mt-2 ml-2",
  role: "group"
};
var _hoisted_14 = {
  class: "flex"
};
var _hoisted_15 = {
  class: "overflow-x-scroll"
};
var _hoisted_16 = {
  id: "table"
};
var _hoisted_17 = {
  key: 0
};
var _hoisted_18 = {
  key: 2
};
var _hoisted_19 = ["innerHTML"];
var _hoisted_20 = ["innerHTML"];
var _hoisted_21 = ["onClick"];
var _hoisted_22 = {
  key: 5
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_FilePreviewModal = _resolveComponent("FilePreviewModal");
  var _component_view_switcher = _resolveComponent("view-switcher");
  var _component_save_report = _resolveComponent("save-report");
  var _component_v_lazy_image = _resolveComponent("v-lazy-image");
  var _component_hierarchical_list = _resolveComponent("hierarchical-list");
  var _component_contact_cards = _resolveComponent("contact-cards");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_modal = _resolveComponent("modal");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_excel = _resolveComponent("risma-excel");
  var _component_risma_print = _resolveComponent("risma-print");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.previewFile ? (_openBlock(), _createBlock(_component_FilePreviewModal, {
    key: 0,
    "file-src": $data.previewFile.fileSrc,
    "file-src-type": $data.previewFile.fileType,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.previewFile = null;
    })
  }, null, 8 /* PROPS */, ["file-src", "file-src-type"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_view_switcher, {
    view: $data.viewType,
    views: $data.ARTICLE_30_TABLE_VIEWS,
    "onUpdate:view": _cache[1] || (_cache[1] = function ($event) {
      return $data.viewType = $event;
    })
  }, null, 8 /* PROPS */, ["view", "views"])]), $data.viewType === $data.VIEW_TYPES.EXPANDED ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_save_report, {
    type: $props.reportType,
    "state-local": $props.stateLocal,
    "compliance-projects-id": $props.projectId,
    "report-options": $options.reportOptionsExtended,
    onLoad: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.loadReportByReplacingQueryParams($event);
    })
  }, null, 8 /* PROPS */, ["type", "state-local", "compliance-projects-id", "report-options"]), _createVNode(_component_risma_datatable, {
    ref: "reportsDataTable",
    "dataset-main": _ctx.datasetSlice,
    "dataset-total": $options.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "wrapper-height-sticky": 500,
    "do-resizing": true,
    "state-local": $props.stateLocal,
    "excel-filename": "RISMAcompliance",
    "columns-meta": $options.columnsMeta,
    class: "mt-5 px-4",
    onExport: _cache[9] || (_cache[9] = function ($event) {
      return $options.emitExport($event.type, $event.count);
    }),
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged,
    onDismissModal: _cache[10] || (_cache[10] = function ($event) {
      return _ctx.$emit('dismissModal');
    })
  }, _createSlots({
    dataFlow: _withCtx(function (_ref) {
      var item = _ref.item;
      return [!item.imageUrl ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.field), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", {
        key: 1,
        onClick: function onClick($event) {
          return $options.setPreviewFile(item.imageUrl);
        }
      }, [_createVNode(_component_v_lazy_image, {
        class: "dataFlowImage",
        src: item.imageUrl
      }, null, 8 /* PROPS */, ["src"])], 8 /* PROPS */, _hoisted_5))];
    }),
    node: _withCtx(function (_ref2) {
      var item = _ref2.item;
      return [item.field.hierarchicalList ? (_openBlock(), _createBlock(_component_hierarchical_list, {
        key: 0,
        "has-preview-mode": true,
        node: item.field.hierarchicalList,
        "show-children": true,
        "toggle-children": false,
        onUpdated: _cache[3] || (_cache[3] = function ($event) {
          return _ctx.$emit('updated');
        }),
        onDismissModal: _cache[4] || (_cache[4] = function ($event) {
          return _ctx.$emit('dismissModal');
        })
      }, null, 8 /* PROPS */, ["node"])) : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(item.field), 1 /* TEXT */))];
    }),
    dataProcessors: _withCtx(function (_ref3) {
      var item = _ref3.item;
      return [item.hasContactData ? (_openBlock(), _createBlock(_component_contact_cards, {
        key: 0,
        "has-preview-mode": true,
        "information-assets": item.data,
        onUpdateFromPreviewMode: _cache[5] || (_cache[5] = function ($event) {
          return _ctx.$emit('updateFromContactCards', $event);
        }),
        onDismissModal: _cache[6] || (_cache[6] = function ($event) {
          return _ctx.$emit('dismissContractCardModal');
        })
      }, null, 8 /* PROPS */, ["information-assets"])) : (_openBlock(), _createElementBlock("div", _hoisted_7))];
    }),
    dataControllers: _withCtx(function (_ref4) {
      var item = _ref4.item;
      return [item.hasContactData ? (_openBlock(), _createBlock(_component_contact_cards, {
        key: 0,
        "has-preview-mode": true,
        "information-assets": item.data
      }, null, 8 /* PROPS */, ["information-assets"])) : (_openBlock(), _createElementBlock("div", _hoisted_8))];
    }),
    _: 2 /* DYNAMIC */
  }, [_renderList($options.uniqueKeys, function (linkCol) {
    return {
      name: linkCol,
      fn: _withCtx(function (_ref5) {
        var item = _ref5.item;
        return [item.isFramework ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(item.field), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", {
          class: "list-reset-styles",
          innerHTML: item.field.value
        }, null, 8 /* PROPS */, _hoisted_11), item.field.data ? (_openBlock(), _createBlock(_component_contact_cards, {
          key: 0,
          "has-preview-mode": true,
          "information-assets": item.field.data,
          onUpdateFromPreviewMode: _cache[7] || (_cache[7] = function ($event) {
            return _ctx.$emit('updateFromContactCards', $event);
          }),
          onDismissModal: _cache[8] || (_cache[8] = function ($event) {
            return _ctx.$emit('dismissContractCardModal');
          })
        }, null, 8 /* PROPS */, ["information-assets"])) : _createCommentVNode("v-if", true)]))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "state-local", "columns-meta", "onPageChange", "onOnStateChanged"])])) : _createCommentVNode("v-if", true), $data.viewType === $data.VIEW_TYPES.COMPACT || $data.viewType === $data.VIEW_TYPES.INVERTED_COMPACT ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [$data.wordModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.translate.excludeNodesWithoutAnswersInReport,
    "button-ok-text": $data.translate.yes,
    "button-dismiss-text": $data.translate.no,
    onAccept: $options.handleExportToWordButton,
    onDismiss: $options.handleExportToWordButton
  }, null, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_14, [_createVNode(_component_risma_excel, {
    columns: $data.viewType !== $data.VIEW_TYPES.INVERTED_COMPACT ? $options.datatableDataByType.columns : {},
    "dataset-export": $data.viewType !== $data.VIEW_TYPES.INVERTED_COMPACT ? $options.datasetExport : $options.invertedDatasetToExport,
    onExport: _cache[11] || (_cache[11] = function ($event) {
      return $options.emitExport($data.ReportLogTypes.EXCEL, $event);
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_button, {
        text: $data.translate.exportToExcel,
        type: "save",
        class: "mr-2"
      }, null, 8 /* PROPS */, ["text"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columns", "dataset-export"]), $data.viewType === $data.VIEW_TYPES.COMPACT && $props.reportUrl && _ctx.getSettingValue('feature.article30_word_export') ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.exportToWord,
    type: "save",
    class: "mr-2",
    onClick: $options.toogleExportToWordModal
  }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_print, {
    columns: $options.columns,
    "dataset-export": $options.datasetExport,
    "head-title": "",
    onExport: _cache[12] || (_cache[12] = function ($event) {
      return $options.emitExport($data.ReportLogTypes.PRINT, $event);
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_button, {
        text: $data.translate.printTheTable,
        type: "save"
      }, null, 8 /* PROPS */, ["text"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columns", "dataset-export"])])]), _createElementVNode("div", _hoisted_15, [_createElementVNode("table", _hoisted_16, [$data.viewType !== $data.VIEW_TYPES.INVERTED_COMPACT ? (_openBlock(), _createElementBlock("thead", _hoisted_17, [_createElementVNode("tr", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.datatableDataByType.columns, function (column, columnKey) {
    return _openBlock(), _createElementBlock("th", {
      key: columnKey,
      class: _normalizeClass({
        'th-long-question': column.length > 75
      })
    }, _toDisplayString(column), 3 /* TEXT, CLASS */);
  }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.datatableDataByType.dataset, function (dataItem, rowIndex) {
    return _openBlock(), _createElementBlock("tr", {
      key: rowIndex
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.datatableDataByType.columns, function (_, columnKey) {
      var _dataItem$columnKey$f, _dataItem$columnKey;
      return _openBlock(), _createElementBlock("td", {
        key: columnKey
      }, [(_dataItem$columnKey$f = dataItem[columnKey].field) !== null && _dataItem$columnKey$f !== void 0 && _dataItem$columnKey$f.hierarchicalList ? (_openBlock(), _createBlock(_component_hierarchical_list, {
        key: 0,
        "show-children": true,
        "toggle-children": false,
        "has-preview-mode": true,
        node: dataItem[columnKey].field.hierarchicalList,
        onUpdated: _cache[13] || (_cache[13] = function ($event) {
          return _ctx.$emit('updated');
        }),
        onDismissModal: _cache[14] || (_cache[14] = function ($event) {
          return _ctx.$emit('dismissModal');
        })
      }, null, 8 /* PROPS */, ["node"])) : dataItem[columnKey].hasContactData ? (_openBlock(), _createBlock(_component_contact_cards, {
        key: 1,
        "has-preview-mode": true,
        "information-assets": dataItem[columnKey].data,
        onUpdateFromPreviewMode: _cache[15] || (_cache[15] = function ($event) {
          return _ctx.$emit('updateFromContactCards', $event);
        }),
        onDismissModal: _cache[16] || (_cache[16] = function ($event) {
          return _ctx.$emit('dismissContractCardModal');
        })
      }, null, 8 /* PROPS */, ["information-assets"])) : (_dataItem$columnKey = dataItem[columnKey]) !== null && _dataItem$columnKey !== void 0 && _dataItem$columnKey.isQuestionAnswer ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createElementVNode("div", {
        class: "list-reset-styles",
        innerHTML: dataItem[columnKey].field.value
      }, null, 8 /* PROPS */, _hoisted_19), dataItem[columnKey].field.data ? (_openBlock(), _createBlock(_component_contact_cards, {
        key: 0,
        "has-preview-mode": true,
        "information-assets": dataItem[columnKey].field.data,
        onUpdateFromPreviewMode: _cache[17] || (_cache[17] = function ($event) {
          return _ctx.$emit('updateFromContactCards', $event);
        }),
        onDismissModal: _cache[18] || (_cache[18] = function ($event) {
          return _ctx.$emit('dismissContractCardModal');
        })
      }, null, 8 /* PROPS */, ["information-assets"])) : _createCommentVNode("v-if", true)])) : dataItem[columnKey].isHtml ? (_openBlock(), _createElementBlock("section", {
        key: 3,
        class: "list-reset-styles",
        innerHTML: $options.replaceHtml(dataItem[columnKey].field)
      }, null, 8 /* PROPS */, _hoisted_20)) : dataItem[columnKey].imageUrl ? (_openBlock(), _createElementBlock("span", {
        key: 4,
        class: "cursor-pointer",
        onClick: function onClick($event) {
          return $options.setPreviewFile(dataItem[columnKey].imageUrl);
        }
      }, [_createVNode(_component_v_lazy_image, {
        src: dataItem[columnKey].imageUrl
      }, null, 8 /* PROPS */, ["src"])], 8 /* PROPS */, _hoisted_21)) : (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(dataItem[columnKey].field), 1 /* TEXT */))]);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))])])])])) : _createCommentVNode("v-if", true)]);
}