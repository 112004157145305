function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getCustomFields, getCustomField, deleteCustomField } from '@/api/admin/admin';
import getUtilSettings from '@/api/risma/settings';
import { AdminCustomFieldsAndActivityTypesMixin } from '@/components/Organisms/Admin/AdminCustomFieldsAndActivityTypesMixin';
import { getCustomFieldTypeText } from '@/constants/CustomFieldTypes';
import { MODULES } from '@/constants/modules';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import AdminCustomFieldsModal from '@/components/Organisms/Admin/AdminCustomFieldsModal';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import { getDisabledModuleFromArrayBySetting } from '@/utils/format/getDisabledModuleFromArrayBySetting';
import { compareStrings } from '@/utils/compare';
export default {
  name: 'AdminCustomFieldsWell',
  introduction: 'Well with custom fields in /business/admin',
  description: 'Well with a list of custom fields. Used together with AdminCustomFieldsModal component.',
  token: '<admin-custom-fields-well></admin-custom-fields-well>',
  components: {
    MainWell: MainWell,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    AdminCustomFieldsModal: AdminCustomFieldsModal,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    RismaSelect: RismaSelect
  },
  mixins: [AdminCustomFieldsAndActivityTypesMixin],
  data: function data() {
    return {
      isLoaded: false,
      idToDelete: null,
      fields: [],
      settings: {},
      selectedModules: [],
      translate: getTranslate['AdminCustomFieldsWell']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    filteredIds: function filteredIds() {
      return [this.getSettingValue('feature.isms_rpo_field_id'), this.getSettingValue('feature.isms_rto_field_id')];
    },
    modulesOptions: function modulesOptions() {
      var _this = this;
      var uniqueModules = _toConsumableArray(new Set(this.fields.map(function (item) {
        return item.module;
      })));
      return uniqueModules.map(function (module) {
        return {
          label: _this.moduleTitles[module],
          id: module
        };
      }).sort(function (a, b) {
        return compareStrings(a.label, b.label);
      });
    },
    sortedFields: function sortedFields() {
      var _this2 = this;
      return _toConsumableArray(this.fields).sort(function (a, b) {
        return compareStrings(a.label, b.label);
      }).sort(function (a, b) {
        return compareStrings(_this2.moduleTitles[a.module], _this2.moduleTitles[b.module]);
      });
    },
    filteredFields: function filteredFields() {
      if (!this.selectedModules.length) return this.sortedFields;
      var moduleIds = this.selectedModules.map(function (item) {
        return item.id;
      });
      return this.sortedFields.filter(function (field) {
        return moduleIds.indexOf(field.module) !== -1;
      });
    }
  }),
  mounted: function mounted() {
    var _this3 = this;
    this.getSettings().then(function () {
      _this3.getData();
    });
  },
  methods: {
    getSettings: function getSettings() {
      var _this4 = this;
      return getUtilSettings().then(function (response) {
        return response.list.forEach(function (setting) {
          return _this4.settings[setting.settingname] = setting.value;
        });
      });
    },
    getData: function getData() {
      var _this5 = this;
      this.isLoaded = false;
      var params = '?data[]=hasValues&data[]=children';
      getCustomFields(params).then(function (_ref) {
        var list = _ref.list;
        _this5.isLoaded = true;
        _this5.fields = _this5.addProps(_this5.filterFields(list));
      }).catch(function () {
        _this5.isLoaded = true;
      });
    },
    filterFields: function filterFields(data) {
      var _this6 = this;
      var fields = data.filter(function (item) {
        return item.module !== MODULES.RISK_PROJECT_PERIOD && !_this6.filteredIds.includes(item.id);
      });
      var disabledModules = getDisabledModuleFromArrayBySetting(fields.map(function (item) {
        return item.module;
      }), this.getSettingValue);
      return fields.filter(function (item) {
        return !disabledModules.includes(item.module);
      });
    },
    addProps: function addProps(data) {
      var _this7 = this;
      data.map(function (item) {
        if (!item.fieldtype_text) {
          item.fieldtype_text = _this7.$trans(getCustomFieldTypeText(item.typeId));
        }
      });
      return data;
    },
    createCustomField: function createCustomField(e) {
      e.preventDefault();
      if (this.selectedModules.length === 1) {
        this.$refs.modal.onCreateOpen(this.selectedModules[0].id);
      } else {
        this.$refs.modal.onCreateOpen();
      }
      this.$refs.modal.toggleModal();
    },
    editCustomField: function editCustomField(id) {
      var _this8 = this;
      getCustomField(id).then(function (data) {
        _this8.$refs.modal.onEditOpen(data);
        _this8.$refs.modal.toggleModal();
      }).catch(function (e) {
        throw e;
      });
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this9 = this;
      deleteCustomField(this.idToDelete).then(function () {
        _this9.removeCustomFieldById(_this9.idToDelete);
        _this9.showSuccessMessage(_this9.translate.fieldDeleted);
      }).catch(function (data) {
        if (data) {
          if (data.response && data.response.data && data.response.data.error) {
            _this9.showErrorMessage(data.response.data.error);
          }
          throw data;
        }
      });
    },
    deleteCustomField: function deleteCustomField(id) {
      var _this10 = this;
      this.idToDelete = id;
      this.$confirm('', this.translate.areYouSureYouWantToDeleteThisCustomField, function (res) {
        return res && _this10.onDeleteConfirm();
      });
    },
    removeCustomFieldById: function removeCustomFieldById(id) {
      var _this11 = this;
      this.fields.map(function (field, index) {
        if (field.id === id) {
          _this11.fields.splice(index, 1);
        }
      });
    },
    onCreated: function onCreated() {
      this.showSuccessMessage(this.translate.fieldWasCreated);
      this.getData();
    },
    onEdit: function onEdit() {
      this.$refs.modal.toggleModal();
      this.showSuccessMessage(this.translate.fieldWasUpdated);
      this.getData();
    },
    showSuccessMessage: function showSuccessMessage(msg) {
      this.$notify({
        title: msg,
        type: 'success'
      });
    },
    showErrorMessage: function showErrorMessage(msg) {
      this.$notify({
        title: msg,
        type: 'error'
      });
    }
  }
};