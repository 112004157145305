function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import buildComplianceMenu from '@/menus/compliance';
import { i18n } from '@/i18n';
import buildControlsMenu from '@/menus/controls';
import buildRisksMenu from '@/menus/risks';
import buildIncidentsMenu from '@/menus/incidents';
import buildActionsMenu from '@/menus/actions';
import buildContractsMenu from '@/menus/contracts';
import buildPoliciesMenu from '@/menus/policies';
import buildAssetsMenu from '@/menus/assets';
import { MODULES } from '@/constants/modules';
export function getModuleFromPath(path, settings) {
  var splitPath = path.split('/');
  if (splitPath[2] === 'processtree' || splitPath[1] === 'awareness') {
    return 'policies';
  }
  if (settings['feature.asset_management_menu'] == 1 && splitPath[2] === 'informationassets') {
    return 'assets';
  }
  return splitPath[1].replace(/[0-9]/g, '');
}
function changeToOneLevelMenu(list) {
  return list.reduce(function (a, b) {
    return b.children ? a.concat(b.children) : a.concat(b);
  }, []);
}
function updateComplianceItems(list) {
  // Move InformationAssets to end of the list
  var firstEl = list.splice(0, 1);
  return [].concat(_toConsumableArray(list), _toConsumableArray(firstEl));
}
export function buildMainNavMenuStructure(settings, currentUser) {
  var _buildActionsMenu;
  var customIA = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var mainNavStructure = {};
  var complianceMenu = buildComplianceMenu(currentUser.projects[MODULES.COMPLIANCE], currentUser, settings, i18n, customIA);
  var controlsMenu = buildControlsMenu(currentUser);
  mainNavStructure['risk'] = buildRisksMenu(settings, currentUser).main;
  mainNavStructure['actions'] = (_buildActionsMenu = buildActionsMenu(settings)) === null || _buildActionsMenu === void 0 ? void 0 : _buildActionsMenu.main;
  mainNavStructure['controls'] = changeToOneLevelMenu(controlsMenu.main);
  mainNavStructure['incident'] = buildIncidentsMenu(settings, currentUser).main;
  mainNavStructure['compliance'] = updateComplianceItems(complianceMenu.main);
  mainNavStructure['contracts'] = buildContractsMenu(settings, currentUser);
  mainNavStructure['policies'] = buildPoliciesMenu(settings);
  mainNavStructure['assets'] = buildAssetsMenu(settings, currentUser, customIA);
  return mainNavStructure;
}