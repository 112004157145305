function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { updateFlow as _updateFlow } from '@/api/compliance/dataflow';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
import DataTypeLegalBasisCreationModal from '@/components/Molecules/Modal/DataTypeLegalBasisCreationModal';
import DataFlowDatatable from '@/components/Organisms/Compliance/DataFlow/DataFlowDatatable';
import { IATitleMixin } from '@/mixins/IATitleMixin';
import { getDataflowThirdPartiesTitle, getDataflowStakeholdersTitle } from '@/utils/TitleFromSetting';
import { prepareGroupedStakeholdersOptions, prepareGroupedSystemsOptions } from '@/utils/dataFlowUtils';
export default {
  name: 'DataFlowRow',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    TextBox: TextBox,
    Modal: Modal,
    InputField: InputField,
    ToggleMenu: ToggleMenu,
    DataTypeLegalBasisCreationModal: DataTypeLegalBasisCreationModal,
    DataFlowDatatable: DataFlowDatatable
  },
  mixins: [IATitleMixin],
  props: {
    projectId: {
      type: [Number, String],
      required: true,
      default: null
    },
    nodeId: {
      type: [Number, String],
      required: true,
      default: null
    },
    flow: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    systems: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    stakeholders: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    dataTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    legalBasis: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    isProjectLocked: {
      type: Boolean,
      required: false,
      default: false
    },
    questions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['deleteStakeholder', 'addDataflowToStakeholder', 'deleteDataFlow', 'updateFlow'],
  data: function data() {
    return {
      RECEIVE_DIRECTION: 'receive',
      DELIVER_DIRECTION: 'deliver',
      showChangeSystemTitleModal: false,
      showDataTypeLegalBasisModal: false,
      timer: {},
      emptySystemTitleError: '',
      newSystemTitle: '',
      warningText: {
        body: '',
        header: '',
        okButton: ''
      },
      dataFlowStakeholdersTitle: getDataflowStakeholdersTitle(),
      subFlows: [],
      modalData: null,
      translate: getTranslate['DataFlowRow']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    hasDataflowWizard: function hasDataflowWizard() {
      return this.getSettingValue('feature.dataflow_wizard');
    },
    dataFlowSystemsTitle: function dataFlowSystemsTitle() {
      return this.flow.systemsTitle || getDataflowThirdPartiesTitle();
    },
    showComments: function showComments() {
      return !!this.getSettingValue('feature.data_flow_comments');
    },
    deleteStakeholderMessage: function deleteStakeholderMessage() {
      return "".concat(this.translate.areYouSureYouWantToDeleteThisDataFlowWit, " ").concat(this.informationAssetsTitle(), "?");
    },
    flowDirection: function flowDirection() {
      return this.flow.direction === this.DELIVER_DIRECTION ? 'right' : 'left';
    },
    stakeholdersOptions: function stakeholdersOptions() {
      return prepareGroupedStakeholdersOptions(this.stakeholders);
    },
    systemsOptions: function systemsOptions() {
      return prepareGroupedSystemsOptions(this.systems);
    },
    popUpActions: function popUpActions() {
      return [{
        class: 'text-blue-750 text-xs hover:bg-gray-450',
        icon: this.flow.direction === this.RECEIVE_DIRECTION ? 'arrow-right' : 'arrow-left',
        title: this.translate.changeDirectionsOfDataflow,
        action: this.onChangeDataflowDirection
      }, {
        class: 'text-blue-750 text-xs hover:bg-gray-450',
        icon: 'plus',
        title: this.translate.createANewDataFlowFromThisStakeholder,
        action: this.onNewDataFlow
      }, {
        class: 'text-red-50 text-xs hover:bg-gray-450',
        icon: 'x',
        title: this.translate.deleteTheEntireDataFlow,
        action: this.onDeleteStakeholder
      }];
    }
  }),
  watch: {
    flow: function flow(newFlow) {
      this.subFlows = newFlow.subFlows || [];
    }
  },
  beforeMount: function beforeMount() {
    this.subFlows = this.flow.subFlows || [];
  },
  methods: {
    showSystemTitleChangeModal: function showSystemTitleChangeModal() {
      this.newSystemTitle = this.flow.systemsTitle;
      this.showChangeSystemTitleModal = true;
    },
    toggleWarningAlert: function toggleWarningAlert() {
      var _this = this;
      this.$confirm(this.warningText.header, this.warningText.body, function (res) {
        return res ? _this.changeCallback() : _this.changeCallback = null;
      }, {
        buttonOkText: this.warningText.okButton,
        buttonDismissText: this.translate.cancel
      });
    },
    onNewDataFlow: function onNewDataFlow() {
      this.$emit('addDataflowToStakeholder', this.flow.id);
    },
    onDeleteStakeholder: function onDeleteStakeholder() {
      var _this2 = this;
      this.warningText = {
        body: this.deleteStakeholderMessage,
        header: this.translate.confirmationNeeded,
        okButton: this.translate.confirm
      };
      this.changeCallback = function () {
        _this2.$emit('deleteStakeholder');
      };
      this.toggleWarningAlert();
    },
    onDeleteDataFlow: function onDeleteDataFlow(idxToDelete) {
      var _this3 = this;
      this.warningText = {
        body: this.translate.areYouSureYouWantToDeleteThisDataFlow,
        header: this.translate.deleteDataFlow,
        okButton: this.translate.delete
      };
      this.changeCallback = function () {
        _this3.$emit('deleteDataFlow', idxToDelete);
      };
      this.toggleWarningAlert();
    },
    onChangeDataflowDirection: function onChangeDataflowDirection() {
      var _this4 = this;
      this.warningText = {
        body: this.translate.changingTheDirectionOfTheDataflowOrAdding,
        header: this.translate.warning,
        okButton: this.translate.ok
      };
      this.changeCallback = function () {
        var direction = _this4.flow.direction === _this4.RECEIVE_DIRECTION ? _this4.DELIVER_DIRECTION : _this4.RECEIVE_DIRECTION;
        _this4.updateAllFlows(direction, 'direction');
      };
      this.toggleWarningAlert();
    },
    onChangeStakeholder: function onChangeStakeholder(stakeholderId) {
      var _this5 = this;
      this.warningText = {
        body: this.translate.changingTheDirectionOfTheDataflowOrAdding,
        header: this.translate.warning,
        okButton: this.translate.ok
      };
      this.changeCallback = function () {
        var stakeholder = _this5.stakeholders.find(function (stk) {
          return stk.uniqueId === stakeholderId;
        });
        _this5.updateAllFlows(stakeholder, 'stakeholder');
      };
      this.toggleWarningAlert();
    },
    onChangeSystemTitle: function onChangeSystemTitle() {
      if (!this.newSystemTitle) {
        this.emptySystemTitleError = this.translate.titleCannotBeEmpty;
        return;
      }
      this.updateAllFlows(this.newSystemTitle, 'systemsTitle');
      this.newSystemTitle = '';
      this.showChangeSystemTitleModal = false;
    },
    updateLegalBasisRelation: function updateLegalBasisRelation(updatedFlows) {
      var _this6 = this;
      updatedFlows.forEach(function (flow) {
        _this6.updateSingleFlow(flow.dataTypes, 'dataTypes', flow.id);
      });
      this.showDataTypeLegalBasisModal = false;
    },
    updateAllFlows: function updateAllFlows(value, property) {
      var _this7 = this;
      this.subFlows.forEach(function (subFlow) {
        subFlow[property] = value;
        _this7.updateFlow(subFlow);
      });
    },
    updateSingleFlow: function updateSingleFlow(value, property, flowId) {
      var _this8 = this;
      var flow = this.subFlows.find(function (subFlow) {
        return +subFlow.id === +flowId;
      });
      flow[property] = value;
      if (property === 'comments') {
        clearTimeout(this.timer[flowId]);
        this.timer[flowId] = setTimeout(function () {
          return _this8.updateFlow(flow);
        }, this.$debounceTimeout);
        return;
      }
      return this.updateFlow(flow);
    },
    updateFlow: function updateFlow(flow) {
      var _this9 = this;
      return _updateFlow(this.projectId, this.nodeId, flow.id, flow).then(function (_ref) {
        var data = _ref.data;
        _this9.$emit('updateFlow', data);
      });
    }
  }
};