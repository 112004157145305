function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getAllFlows as _getAllFlows } from '@/api/compliance/dataflow';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TabList from '@/components/Atoms/TabList';
import DataFlowDatatable from '@/components/Organisms/Compliance/DataFlow/DataFlowDatatable';
import { removeDuplicates } from '@/utils/filterArray';
import { getDataFlowImageUrl } from '@/utils/Compliance/DataFlow';
import { TabTypes } from '@/constants/Tabs';
import { ObjectTypesToIAExport } from '@/constants/ObjectTypes';
export default {
  name: 'InformationAssetsDataFlow',
  components: {
    RismaTitle: RismaTitle,
    TabList: TabList,
    DataFlowDatatable: DataFlowDatatable
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'data object'
    }
  },
  data: function data() {
    return {
      getDataFlowImageUrl: getDataFlowImageUrl,
      linkTabs: [],
      dataFlowData: {},
      currentTab: null,
      dataFlowsByNode: {},
      showImgError: false,
      imgError: this.$trans("You don't have permission to see this content")
    };
  },
  computed: {
    nodeId: function nodeId() {
      return this.dataFlowData[this.currentTab].nodeId;
    },
    projectId: function projectId() {
      return this.dataFlowData[this.currentTab].projectId;
    },
    projectNodeName: function projectNodeName() {
      return "".concat(this.projectId, "-").concat(this.nodeId);
    },
    additionalUrlParameters: function additionalUrlParameters() {
      return "?background=MAIN_WELL_BACKGROUND&".concat(ObjectTypesToIAExport[this.data.objType], "Id=").concat(this.data.id);
    },
    flowIdsByComplianceNodeId: function flowIdsByComplianceNodeId() {
      return this.data.dataFlows.reduce(function (acc, cur) {
        if (!acc[cur.complianceNodesId]) {
          acc[cur.complianceNodesId] = [cur.id];
          return acc;
        }
        acc[cur.complianceNodesId].push(cur.id);
        return acc;
      }, {});
    },
    dataFlows: function dataFlows() {
      return this.dataFlowsByNode[this.projectNodeName] || [];
    },
    filteredDataFlows: function filteredDataFlows() {
      return this.dataFlows.map(function (flow) {
        return _objectSpread(_objectSpread({}, flow), {}, {
          dataTypes: flow.dataTypes.filter(function (_ref) {
            var legalBasisOptionLabel = _ref.legalBasisOptionLabel,
              storagePeriodOptionLabel = _ref.storagePeriodOptionLabel;
            return legalBasisOptionLabel || storagePeriodOptionLabel;
          })
        });
      }).filter(function (_ref2) {
        var dataTypes = _ref2.dataTypes;
        return dataTypes.length;
      });
    },
    dataFlowsByStakeholder: function dataFlowsByStakeholder() {
      return this.filteredDataFlows.reduce(function (acc, cur) {
        var _cur$stakeholder = cur.stakeholder,
          id = _cur$stakeholder.id,
          text = _cur$stakeholder.text;
        if (!acc[id]) {
          acc[id] = {
            name: text,
            dataFlows: []
          };
        }
        acc[id].dataFlows.push(cur);
        return acc;
      }, {});
    }
  },
  watch: {
    currentTab: function currentTab() {
      if (this.dataFlowsByNode[this.projectNodeName]) return;
      this.getAllFlows();
    }
  },
  mounted: function mounted() {
    this.prepareData();
  },
  methods: {
    prepareData: function prepareData() {
      var _this = this;
      this.linkTabs = [];
      this.dataFlowData = {};
      var data = removeDuplicates(this.data.dataFlows, 'complianceNodesId');
      data.forEach(function (item) {
        var slotName = TabTypes.DATAFLOW + '_' + item.complianceNodesId;
        _this.linkTabs.push({
          name: item.complianceNodesTitle,
          slotName: slotName
        });
        _this.dataFlowData[slotName] = {
          nodeId: item.complianceNodesId,
          projectId: item.complianceNodesProjectId,
          name: item.complianceNodesTitle
        };
      });
      this.currentTab = this.linkTabs[0].slotName;
    },
    getAllFlows: function getAllFlows() {
      var _this2 = this;
      return _getAllFlows(this.projectId, this.nodeId).then(function (_ref3) {
        var list = _ref3.list;
        _this2.dataFlowsByNode[_this2.projectNodeName] = list.filter(function (flow) {
          return _this2.flowIdsByComplianceNodeId[_this2.nodeId].includes(flow.id);
        });
      });
    }
  }
};