import i18n from '@/i18n';
export var getTranslate = {
  ActionDashboard: function ActionDashboard() {
    return {
      actionsDashboard: i18n.t('Actions Dashboard'),
      period: i18n.t('Period'),
      actionsCount: i18n.t('Actions count'),
      noResultsFoundYouMayWantToWidenYourSearc: i18n.t('No results found. You may want to widen your search criteria')
    };
  },
  ActionDescription: function ActionDescription() {
    return {
      areYouSureTheActionInclUnderlyingActions: i18n.t('Are you sure? The Action incl. underlying actions will be permanently deleted'),
      areYouSure: i18n.t('Are you sure?'),
      delete: i18n.t('Delete'),
      cancel: i18n.t('Cancel'),
      start: i18n.t('Start'),
      deadline: i18n.t('Deadline'),
      presentStatus: i18n.t('Present status'),
      description: i18n.t('Description'),
      target: i18n.t('Target'),
      numericalUnits: i18n.t('Numerical units'),
      addNumericalUnit: i18n.t('Add Numerical unit'),
      budget: i18n.t('Budget'),
      forbrug: i18n.t('Forbrug'),
      forventetAntalTimer: i18n.t('Forventet antal timer'),
      forbrugAntalTimer: i18n.t('Forbrug antal timer'),
      totalIncludingUnderlying: i18n.t('Total including underlying'),
      underlyingLevelsInheritAboveNumericalUnits: i18n.t('Underlying levels inherit above numerical units'),
      projectStatus: i18n.t('Project status'),
      completionPercentage: i18n.t('Completion percentage'),
      otherComments: i18n.t('Other comments'),
      statusChanges: i18n.t('Status changes'),
      closeActionAnd: i18n.t('Close action and'),
      underlyingAction: i18n.t('underlying action'),
      underlyingActions: i18n.t('underlying actions'),
      reopenActionAnd: i18n.t('Reopen action and'),
      reopenAction: i18n.t('Reopen action'),
      createUnderlyingAction: i18n.t('Create underlying action'),
      createSubsequentActionAtThisLevel: i18n.t('Create subsequent action at this level'),
      shiftTheDatesOfThisAction: i18n.t('Shift the dates of this action'),
      deleteActionAndAllUnderlyingActions: i18n.t('Delete action and all underlying actions'),
      updateSequenceNumber: i18n.t('Update sequence number ...'),
      exceedsTheDeadlineOfTheOverlyingInitiative: i18n.t('Exceeds the deadline of the overlying initiative'),
      warningAndUnderlyingInitiativeIsBehindSchedule: i18n.t('Warning: An underlying initiative is behind schedule.'),
      copyActionUnderlying: i18n.t('Copy action + underlying ...'),
      warningActionAndAllUnderlyingActionsWillBeClosed: i18n.t('Warning: Action and all underlying actions will be closed before archiving. Archived actions cannot be reopened or removed from the archive'),
      archive: i18n.t('Archive'),
      archiveActionAndAllUnderlyingActions: i18n.t('Archive action and all underlying actions')
    };
  },
  ActionEntry: function ActionEntry() {
    return {
      actionIsClosedPleaseReopenBeforeEditing: i18n.t('Action is closed. Please re-open before editing'),
      thisActivityIsReadonlyAsYourUserIsConfigu: i18n.t('This activity is read-only as your user is configured to only have read-only access'),
      activityType: i18n.t('Activity type'),
      startDateCannotBeAfterDeadline: i18n.t('Start date cannot be after deadline'),
      pleaseSelectDeadlineFirst: i18n.t('Please select a deadline first'),
      actionIsClosedAndArchived: i18n.t('Action is closed and archived. Action cannot be reopened.')
    };
  },
  ActionGap: function ActionGap() {
    return {
      analysis: i18n.t('Analysis'),
      riskAssessment: i18n.t('Risk assessment'),
      before: i18n.t('before'),
      after: i18n.t('after')
    };
  },
  ActionMainReport: function ActionMainReport() {
    return {
      rootAction: i18n.t('Root action'),
      mainReport: i18n.t('Main report'),
      showAsGanttRoadmap: i18n.t('Show as gantt roadmap'),
      dependingOn: i18n.t('Depending on'),
      precursorTo: i18n.t('Precursor to'),
      trafficLight: i18n.t('Traffic light'),
      path: i18n.t('Path'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      status: i18n.t('Status'),
      open: i18n.t('Open'),
      closed: i18n.t('Closed'),
      start: i18n.t('Start'),
      deadline: i18n.t('Deadline'),
      organisations: i18n.t('Organisations'),
      responsible: i18n.t('Responsible'),
      notifications: i18n.t('Notifications'),
      tags: i18n.t('Tags'),
      completionPercentage: i18n.t('Completion percentage'),
      target: i18n.t('Target'),
      otherComments: i18n.t('Other comments'),
      frameworks: i18n.t('Framework'),
      linkedGaps: i18n.t('Linked gaps')
    };
  },
  MyActions: function MyActions() {
    return {
      sortBy: i18n.t('Sort by'),
      actionsNumber: i18n.t('Actions number'),
      deadline: i18n.t('Deadline')
    };
  }
};