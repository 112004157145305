function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
export var DelegationTypes = {
  users: 'users',
  organisations: 'organisations',
  tags: 'tags',
  initiativeTypes: 'initiativeTypes',
  initiatives: 'initiatives',
  riskManagementLevels: 'riskManagementLevels',
  solutionId: 'solutionId',
  contract: 'contract'
};
export var DelegationItemTypes = {
  INITIATIVE_TYPE: 'initiativeType',
  RESPONSIBLE_USER: 'responsibleUserIds',
  REVIEWER_USER: 'reviewersUserIds',
  ESCALATION_USER: 'escalationUserIds',
  ACCOUNTABLE_USER: 'accountableUserIds',
  CONSULTED_USER: 'consultedUserIds',
  INFORMED_USER: 'informedUserIds',
  NOTIFICATION_USER: 'notificationsUserIds',
  ORGANISATION: 'organisationIds',
  MANAGEMENT_LEVEL: 'managementLevelId',
  DAILY_TEAM_USER: 'dailyTeamUsersIds',
  DAILY_TEAM_ORGANISATION: 'dailyTeamOrganisationsIds',
  TAG: 'tagIds',
  DEPENDING: 'dependingOn'
};
export var DelegationModalSettings = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, DelegationTypes.users, {
  title: 'Users',
  label: 'Add new users',
  searchPlaceholder: 'Search users',
  searchFields: ['name', 'username', 'initials', 'fullname'],
  subtitle: 'Added users',
  titleField: 'fullname',
  shortTitleField: 'initials',
  labelKey: 'display_name',
  enableAssignToMe: true,
  type: DelegationTypes.users
}), DelegationTypes.organisations, {
  title: 'Organisations',
  label: 'Add new organisations',
  searchPlaceholder: 'Search organisations',
  searchFields: ['name', 'visible_name'],
  subtitle: 'Added organisations',
  titleField: 'visible_name',
  labelKey: 'visible_name',
  type: DelegationTypes.organisations
}), DelegationTypes.tags, {
  title: 'Tags',
  label: 'Add new tags',
  searchPlaceholder: 'Search tags',
  searchFields: ['tag'],
  subtitle: 'Added tags',
  titleField: 'tag',
  labelKey: 'tag',
  type: DelegationTypes.tags
}), DelegationTypes.initiativeTypes, {
  title: 'Activity type',
  label: 'Choose activity type',
  searchPlaceholder: 'Search activity types',
  searchFields: ['label'],
  subtitle: 'Activity type',
  titleField: 'label',
  labelKey: 'label',
  isMultiple: false,
  type: DelegationTypes.initiativeTypes
}), DelegationTypes.initiatives, {
  title: 'Depending on',
  label: 'Add new depending on',
  searchPlaceholder: 'Search actions',
  searchFields: ['label'],
  subtitle: 'Add depending on',
  titleField: 'label',
  labelKey: 'label',
  type: DelegationTypes.initiatives
}), DelegationTypes.riskManagementLevels, {
  title: 'Management level',
  label: 'Choose management level',
  searchPlaceholder: 'Search management levels',
  searchFields: ['name'],
  subtitle: 'Management level',
  titleField: 'name',
  labelKey: 'name',
  isMultiple: false,
  type: DelegationTypes.riskManagementLevels
}), DelegationTypes.solutionId, {
  title: 'Add framework',
  label: 'Add framework',
  searchPlaceholder: 'Search on frameworks',
  searchFields: ['displayName'],
  subtitle: 'Added frameworks',
  titleField: 'displayName',
  labelKey: 'displayName',
  isMultiple: true,
  type: DelegationTypes.solutionId
}), DelegationTypes.contract, {
  title: '',
  label: 'Choose contract',
  searchPlaceholder: 'Click to select contracts',
  searchFields: ['title'],
  labelKey: 'title',
  titleField: 'title',
  subtitle: 'Added contracts'
});