function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUserStore } from '@/Store/userStore';
import { updateWorkflow, updateWorkflowStatus, createWorkflowStatusByModule, deleteWorkflowStatusByModule, getWorkflowById } from '@/api/admin/workflow';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import ColorPicker from '@/components/Molecules/ColorPicker';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import StaticTable from '@/components/Molecules/StaticTable';
import { MODULES } from '@/constants/modules';
import { saveStatus as saveStatuses } from '@/constants/SaveStatuses';
import { UserLevels } from '@/constants/UserLevels';
import { getCustomFieldTypeText } from '@/constants/CustomFieldTypes';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
import { useWorkflowStore } from '@/Store/workflowStore';
var DEFAULT_COLOR = '#4BBF5E';
export default {
  name: 'IncidentsCustomWorkflowEdit',
  token: '<incidents-custom-workflow-edit />',
  components: {
    FeatherIcon: FeatherIcon,
    RismaButton: RismaButton,
    Modal: Modal,
    InputField: InputField,
    TinyMceBox: TinyMceBox,
    SingleSelect: SingleSelect,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    ColorPicker: ColorPicker,
    RismaLabel: RismaLabel,
    RismaSelect: RismaSelect,
    Checkboxes: Checkboxes,
    StaticTable: StaticTable
  },
  props: {
    id: {
      required: false,
      default: null,
      type: String
    }
  },
  data: function data() {
    return {
      StaticTableThemes: StaticTableThemes,
      saveStatus: saveStatuses.SAVED,
      saveStatuses: saveStatuses,
      workflow: {},
      showStatusModal: false,
      showDeleteModal: false,
      isCreateMode: false,
      moveToState: null,
      editedStatusData: {
        id: null,
        title: '',
        instructions: '',
        color: DEFAULT_COLOR,
        targetStatuses: [],
        suggestedResponsible: [],
        fieldOptions: []
      },
      colorValueType: 'hex',
      colorIsInvalid: false,
      deleteStatusData: {},
      isOkButtonPressed: false,
      moveToStatuses: [],
      workflowStatuses: [],
      isStartStatus: null,
      getCustomFieldTypeText: getCustomFieldTypeText,
      radioButtonClasses: 'appearance-none w-5 h-5 border rounded-full border-black checked:border-4 checked:border-blue-350 transition-all duration-200 ease-linear',
      translate: getTranslate['IncidentsCustomWorkflowEdit']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useActivityTypesStore, {
    activityTypes: 'incidentActivityTypes'
  })), mapState(useUsersStore, ['activeUsersWithLevelAccess'])), mapState(useUserStore, ['isAdmin'])), {}, {
    readOnly: function readOnly() {
      return !this.isAdmin;
    },
    isStatusTitleInvalid: function isStatusTitleInvalid() {
      return this.isOkButtonPressed && !this.editedStatusData.title;
    },
    isColorInvalid: function isColorInvalid() {
      return this.colorIsInvalid && this.editedStatusData.color;
    },
    responsibleOptions: function responsibleOptions() {
      return this.activeUsersWithLevelAccess('level_incidents', UserLevels.NORMAL);
    },
    selectedResponsible: function selectedResponsible() {
      var _this = this;
      return this.responsibleOptions.filter(function (_ref) {
        var _this$editedStatusDat;
        var id = _ref.id;
        return (_this$editedStatusDat = _this.editedStatusData.suggestedResponsible) === null || _this$editedStatusDat === void 0 ? void 0 : _this$editedStatusDat.includes(id);
      });
    },
    customFields: function customFields() {
      var _this$activityTypes$f,
        _this2 = this;
      return ((_this$activityTypes$f = this.activityTypes.find(function (_ref2) {
        var _this2$workflow;
        var id = _ref2.id;
        return +id === +((_this2$workflow = _this2.workflow) === null || _this2$workflow === void 0 || (_this2$workflow = _this2$workflow.activityType) === null || _this2$workflow === void 0 ? void 0 : _this2$workflow.id);
      })) === null || _this$activityTypes$f === void 0 ? void 0 : _this$activityTypes$f.fields) || [];
    },
    transitionsOptions: function transitionsOptions() {
      var _this3 = this;
      return this.workflowStatuses.filter(function (_ref3) {
        var id = _ref3.id;
        return +_this3.editedStatusData.id !== +id;
      }).map(function (status) {
        return {
          id: status.id,
          title: status.title
        };
      });
    },
    selectedTransitions: function selectedTransitions() {
      return this.editedStatusData.targetStatuses.map(function (status) {
        return {
          id: status.id,
          title: status.title
        };
      });
    },
    tableDataset: function tableDataset() {
      var _this$workflowStatuse,
        _this4 = this;
      if (!((_this$workflowStatuse = this.workflowStatuses) !== null && _this$workflowStatuse !== void 0 && _this$workflowStatuse.length)) return [];
      return this.workflowStatuses.map(function (ws) {
        return {
          statusTitle: {
            id: ws.id,
            title: ws.title
          },
          transitions: ws.targetStatuses.length ? ws.targetStatuses.map(function (_ref4) {
            var title = _ref4.title;
            return title;
          }).join(', ') : 'N/A',
          activeFields: _this4.getActiveFields(ws.fieldOptions),
          isStartStatus: {
            checked: ws.id === _this4.workflow.startStatusId,
            id: ws.id
          },
          delete: {
            id: ws.id
          }
        };
      });
    },
    tableColumns: function tableColumns() {
      return [this.translate.statusTitle, this.translate.transitions, this.translate.activeFields, this.translate.isStartStatus, !this.readOnly && this.translate.delete].filter(Boolean);
    }
  }),
  mounted: function mounted() {
    this.getWorkflow();
  },
  methods: {
    getWorkflow: function getWorkflow() {
      var _this5 = this;
      return getWorkflowById(this.id).then(function (workflow) {
        _this5.workflow = workflow;
        _this5.workflowStatuses = workflow.workflowStatuses;
      });
    },
    getDefaultFieldOptions: function getDefaultFieldOptions() {
      return this.customFields.map(function (_ref5) {
        var id = _ref5.id;
        return {
          fieldId: id,
          isLocked: false,
          isHidden: false
        };
      });
    },
    addStatus: function addStatus() {
      this.editedStatusData = {
        id: null,
        title: '',
        instructions: '',
        color: DEFAULT_COLOR,
        targetStatuses: [],
        suggestedResponsible: [],
        fieldOptions: this.getDefaultFieldOptions()
      };
      this.isCreateMode = true;
      this.showStatusModal = true;
      this.isOkButtonPressed = false;
    },
    editStatus: function editStatus(statusId) {
      var _status$fieldOptions;
      if (this.readOnly) return;
      var status = this.getStatusById(statusId);
      var id = status.id,
        title = status.title,
        instructions = status.instructions,
        color = status.color,
        targetStatuses = status.targetStatuses,
        suggestedResponsible = status.suggestedResponsible;
      var fieldOptions = this.getFieldOptionsFromStatus(status);
      (_status$fieldOptions = status.fieldOptions) === null || _status$fieldOptions === void 0 || _status$fieldOptions.forEach(function (option) {
        var idx = fieldOptions.findIndex(function (o) {
          return o.fieldId === option.fieldId;
        });
        if (idx !== -1) {
          fieldOptions[idx] = option;
        }
      });
      this.editedStatusData = {
        id: id,
        title: title,
        instructions: instructions,
        color: color,
        fieldOptions: fieldOptions,
        targetStatuses: targetStatuses,
        suggestedResponsible: suggestedResponsible
      };
      this.isCreateMode = false;
      this.showStatusModal = true;
      this.isOkButtonPressed = false;
      this.colorIsInvalid = false;
    },
    getFieldOptionsFromStatus: function getFieldOptionsFromStatus(status) {
      if (!status.fieldOptions) return this.getDefaultFieldOptions();
      var result = status.fieldOptions.slice();
      this.customFields.forEach(function (cf) {
        if (!result.find(function (option) {
          return option.fieldId === cf.id;
        })) {
          result.push({
            fieldId: cf.id,
            isLocked: false,
            isHidden: false
          });
        }
      });
      return result;
    },
    openDeleteModal: function openDeleteModal(statusId) {
      var _this6 = this;
      var status = this.getStatusById(statusId);
      if (!status.inUse) {
        this.$confirm(this.translate.deleteStatus, "".concat(this.translate.areYouSureYouWantToDelete, " ").concat(status.title), function (res) {
          return res && _this6.deleteStatus(status);
        }, {
          buttonOkText: this.translate.delete,
          headerType: 'big'
        });
        return;
      }
      this.showDeleteModal = true;
      this.moveToStatuses = this.workflowStatuses.filter(function (_ref6) {
        var id = _ref6.id;
        return status.id !== id;
      }).map(function (s) {
        return {
          value: s.id,
          label: s.title
        };
      });
      this.deleteStatusData = status;
    },
    onStatusSettingsChange: function onStatusSettingsChange() {
      var _this7 = this;
      if (!this.editedStatusData.title) {
        this.isOkButtonPressed = true;
        return;
      }
      this.saveStatus = saveStatuses.SAVING;
      var promise = this.isCreateMode ? this.createStatus(_objectSpread(_objectSpread({
        workflowId: this.id
      }, this.editedStatusData), {}, {
        id: null
      })) : this.updateStatus(this.editedStatusData);
      return promise.then(function () {
        _this7.showStatusModal = false;
      }).catch(function (err) {
        _this7.colorIsInvalid = true;
        return _this7.showError(err);
      }).finally(function () {
        _this7.editedStatusData.instructions = '';
        _this7.isOkButtonPressed = false;
        _this7.saveStatus = saveStatuses.SAVED;
      });
    },
    getStatusById: function getStatusById(id) {
      if (!id) return null;
      return this.workflowStatuses.find(function (ws) {
        return ws.id === id;
      });
    },
    onSelectResponsible: function onSelectResponsible(event) {
      this.editedStatusData.suggestedResponsible = event.map(function (_ref7) {
        var id = _ref7.id;
        return id;
      });
    },
    isFieldEditable: function isFieldEditable(id) {
      var _this$getFieldById;
      return !((_this$getFieldById = this.getFieldById(id)) !== null && _this$getFieldById !== void 0 && _this$getFieldById.isLocked);
    },
    isFieldHidden: function isFieldHidden(id) {
      var _this$getFieldById2;
      return (_this$getFieldById2 = this.getFieldById(id)) === null || _this$getFieldById2 === void 0 ? void 0 : _this$getFieldById2.isHidden;
    },
    updateIsEditable: function updateIsEditable(id, value) {
      this.getFieldById(id).isLocked = !value;
    },
    updateIsHidden: function updateIsHidden(id, value) {
      this.getFieldById(id).isHidden = value;
    },
    getFieldById: function getFieldById(id) {
      var _this$editedStatusDat2;
      return (_this$editedStatusDat2 = this.editedStatusData.fieldOptions) === null || _this$editedStatusDat2 === void 0 ? void 0 : _this$editedStatusDat2.find(function (_ref8) {
        var fieldId = _ref8.fieldId;
        return +fieldId === +id;
      });
    },
    createStatus: function createStatus(data) {
      var _this8 = this;
      data = _objectSpread(_objectSpread({}, data), {}, {
        targetStatuses: data.targetStatuses.map(function (_ref9) {
          var id = _ref9.id;
          return id;
        })
      });
      return createWorkflowStatusByModule(MODULES.INCIDENTS, data).then(function () {
        _this8.$notify({
          title: "".concat(_this8.translate.workflowStatus, " ").concat(_this8.translate.created)
        });
        return _this8.getWorkflow();
      }).then(useWorkflowStore().load);
    },
    updateStatus: function updateStatus(data) {
      var _data$targetStatuses,
        _this9 = this;
      data = _objectSpread(_objectSpread({
        workflowId: this.id
      }, data), {}, {
        targetStatuses: (_data$targetStatuses = data.targetStatuses) === null || _data$targetStatuses === void 0 ? void 0 : _data$targetStatuses.map(function (_ref10) {
          var id = _ref10.id;
          return id;
        })
      });
      return updateWorkflowStatus(MODULES.INCIDENTS, data).then(this.getWorkflow).then(function () {
        return _this9.$notify({
          title: "".concat(_this9.translate.workflowStatus, " ").concat(_this9.translate.updated)
        });
      }).then(useWorkflowStore().load);
    },
    deleteStatus: function deleteStatus(status) {
      var _this10 = this;
      this.saveStatus = saveStatuses.SAVING;
      this.showDeleteModal = false;
      var data = this.moveToState ? {
        moveToState: this.moveToState
      } : null;
      deleteWorkflowStatusByModule(MODULES.INCIDENTS, status.id, data).then(function () {
        _this10.workflowStatuses = _this10.workflowStatuses.filter(function (_ref11) {
          var id = _ref11.id;
          return +id !== +status.id;
        });
        _this10.deleteStatusData = {};
        _this10.moveToState = null;
        _this10.$notify({
          title: "".concat(_this10.translate.workflowStatus, " ").concat(_this10.translate.deleted)
        });
      }).then(this.getWorkflow).then(useWorkflowStore().load).catch(function (err) {
        return _this10.showError(err);
      }).finally(function () {
        return _this10.saveStatus = saveStatuses.SAVED;
      });
    },
    setColor: function setColor(color) {
      this.colorIsInvalid = false;
      this.editedStatusData.color = color;
    },
    showError: function showError(err) {
      var _this$translate$err$r, _err$response;
      this.$notify({
        title: (_this$translate$err$r = this.translate[err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.error]) !== null && _this$translate$err$r !== void 0 ? _this$translate$err$r : this.translate.anErrorOccurred,
        type: 'error'
      });
    },
    changeIsStartStatus: function changeIsStartStatus(id) {
      var _this11 = this;
      this.saveStatus = saveStatuses.SAVING;
      return updateWorkflow(this.id, {
        startStatusId: id
      }).then(function (workflow) {
        _this11.workflow = workflow;
        _this11.$notify({
          title: "".concat(_this11.translate.workflowStatus, " ").concat(_this11.translate.updated)
        });
      }).then(useWorkflowStore().load).catch(function (err) {
        return _this11.showError(err);
      }).finally(function () {
        return _this11.saveStatus = saveStatuses.SAVED;
      });
    },
    getActiveFields: function getActiveFields(fieldOptions) {
      var _this12 = this;
      if (!(fieldOptions !== null && fieldOptions !== void 0 && fieldOptions.length)) return this.customFields.map(function (_ref12) {
        var label = _ref12.label;
        return label;
      }).join(', ');
      return fieldOptions.filter(function (_ref13) {
        var isLocked = _ref13.isLocked;
        return !isLocked;
      }).map(function (_ref14) {
        var _this12$customFields$;
        var fieldId = _ref14.fieldId;
        return (_this12$customFields$ = _this12.customFields.find(function (_ref15) {
          var id = _ref15.id;
          return +id === +fieldId;
        })) === null || _this12$customFields$ === void 0 ? void 0 : _this12$customFields$.label;
      }).join(', ');
    }
  }
};