import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex justify-between items-center"
};
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_view_switcher = _resolveComponent("view-switcher");
  var _component_preview_list_tile = _resolveComponent("preview-list-tile");
  var _component_compact_card = _resolveComponent("compact-card");
  var _component_preview_list_expanded = _resolveComponent("preview-list-expanded");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_vertical_menu_wrapper = _resolveComponent("vertical-menu-wrapper");
  return _openBlock(), _createElementBlock("div", {
    key: $data.localKey
  }, [_createVNode(_component_vertical_menu_wrapper, null, {
    menu: _withCtx(function () {
      return [$setup.isMy ? (_openBlock(), _createBlock(_component_vertical_menu, {
        key: 0,
        items: $options.menuItems,
        "exact-match": true
      }, null, 8 /* PROPS */, ["items"])) : _createCommentVNode("v-if", true)];
    }),
    content: _withCtx(function () {
      var _$options$currentGrou;
      return [$options.detailLoadedError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.detailLoadedError), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        title: ((_$options$currentGrou = $options.currentGroup) === null || _$options$currentGrou === void 0 ? void 0 : _$options$currentGrou.title) || ''
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
        title: $data.translate.sortBy,
        type: "medium",
        class: "ml-auto mr-2 text-blue-750",
        truncate: false
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
        modelValue: $setup.sortBy,
        "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
          return $setup.sortBy = $event;
        }), _cache[1] || (_cache[1] = function ($event) {
          return $setup.sortBy = $event;
        })],
        options: $options.sortOptionsFiltered,
        "un-sorted": true,
        class: "max-w-250px mr-10"
      }, null, 8 /* PROPS */, ["modelValue", "options"]), _createVNode(_component_view_switcher, {
        view: $setup.viewSelected,
        "onUpdate:view": _cache[2] || (_cache[2] = function ($event) {
          return $setup.viewSelected = $event;
        }),
        views: $data.STANDARD_VIEWS
      }, null, 8 /* PROPS */, ["view", "views"])]), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [!$options.incidentsSorted.length ? (_openBlock(), _createBlock(_component_risma_title, {
        key: 0,
        title: $data.translate.nothingToShow,
        type: "medium",
        class: "text-center"
      }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), $setup.viewSelected === $data.viewTypes.TILES ? (_openBlock(), _createBlock(_component_preview_list_tile, {
        key: $setup.sortBy,
        activities: $options.incidentsSorted,
        "watermark-image": $data.WATERMARK_IMAGE,
        workflows: $options.allWsFlat,
        onUpdateFromPreviewMode: $options.updateFromPreviewMode
      }, null, 8 /* PROPS */, ["activities", "watermark-image", "workflows", "onUpdateFromPreviewMode"])) : _createCommentVNode("v-if", true), $setup.viewSelected === $data.viewTypes.COMPACT ? (_openBlock(), _createBlock(_component_compact_card, {
        key: $setup.sortBy,
        activities: $options.incidentsSorted,
        "activity-types": _ctx.activityTypes,
        module: $data.MODULE_NAME,
        users: _ctx.users,
        organisations: _ctx.organisations,
        class: "mb-8",
        onUpdateFromPreviewMode: $options.updateFromPreviewMode
      }, null, 8 /* PROPS */, ["activities", "activity-types", "module", "users", "organisations", "onUpdateFromPreviewMode"])) : _createCommentVNode("v-if", true), $setup.viewSelected === $data.viewTypes.EXPANDED ? (_openBlock(), _createBlock(_component_preview_list_expanded, {
        key: $setup.sortBy,
        activities: $options.incidentsSorted,
        users: _ctx.users,
        organisations: _ctx.organisations,
        "watermark-image": $data.WATERMARK_IMAGE,
        onUpdateFromPreviewMode: $options.updateFromPreviewMode
      }, null, 8 /* PROPS */, ["activities", "users", "organisations", "watermark-image", "onUpdateFromPreviewMode"])) : _createCommentVNode("v-if", true)])) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 2
      }))];
    }),
    _: 1 /* STABLE */
  })]);
}