function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import VerticalMenu from '@/components/Molecules/VerticalMenu/VerticalMenu';
import VerticalMenuWrapper from '@/components/Molecules/Wrappers/VerticalMenuWrapper';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { KpiChartColors } from '@/constants/performance/dashboard';
import { DateFormats } from '@/constants/DateFormats';
import { createKpiDashboard, getKpiDashboards, getKpiOverview } from '@/api/reports/performance';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { formatTime } from '@/utils/date';
export default {
  name: 'PerformanceReport',
  components: {
    VerticalMenu: VerticalMenu,
    VerticalMenuWrapper: VerticalMenuWrapper,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  setup: function setup() {
    return {
      dailyMonthlyState: getLocalStorageComputed('monthly_daily_performance_flag', 'daily')
    };
  },
  data: function data() {
    return {
      topLinks: [{
        path: '/performance-report/dashboard',
        title: 'GRC Intelligence Center',
        icon: 'pie-chart'
      }],
      dashboards: [],
      performanceData: [],
      performanceLineData: {},
      isLoading: true,
      translate: {
        settings: this.$trans('Settings'),
        daily: this.$trans('Daily'),
        monthly: this.$trans('Monthly')
      }
    };
  },
  computed: {
    settingLink: function settingLink() {
      return [{
        path: '/performance-report/settings',
        title: this.translate.settings,
        icon: 'settings',
        routeName: 'PerformanceReportSettings'
      }];
    },
    dashboardLinks: function dashboardLinks() {
      return this.dashboards.reduce(function (acc, dashboard) {
        if (dashboard.showInMenu) {
          return [].concat(_toConsumableArray(acc), [{
            path: '/performance-report/dashboard/' + dashboard.id,
            title: dashboard.title
          }]);
        }
        return acc;
      }, []);
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.isLoading = true;
    this.getData().then(function () {
      return _this.isLoading = false;
    });
  },
  methods: {
    getAllDashboards: function getAllDashboards() {
      var _this2 = this;
      return getKpiDashboards().then(function (res) {
        return _this2.dashboards = res.list;
      });
    },
    handleDashboardCreation: function handleDashboardCreation(newItem) {
      var _this3 = this;
      return createKpiDashboard(newItem).then(function (newDashboard) {
        _this3.dashboards.push(newDashboard);
      });
    },
    getData: function getData() {
      var promises = [this.getPerformanceDashboard(), this.getAllDashboards()];
      return Promise.all(promises);
    },
    handleProgressTypeChanged: function handleProgressTypeChanged(value) {
      this.dailyMonthlyState = value;
      this.getPerformanceDashboard(true);
    },
    getPerformanceDashboard: function getPerformanceDashboard() {
      var _this4 = this;
      var isOnlyLineDataUpdated = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      return getKpiOverview("?progressType=".concat(this.dailyMonthlyState)).then(function (_ref) {
        var _ref$list = _ref.list,
          list = _ref$list === void 0 ? [] : _ref$list;
        var formattedList = _this4.formatKpiData(list, KpiChartColors);
        var filteredList = formattedList.filter(function (item) {
          return item.showOnDashboard;
        });
        if (!isOnlyLineDataUpdated) {
          _this4.performanceData = _this4.preparePerformanceData(formattedList);
        }
        _this4.performanceLineData = _this4.preparePerformanceLine(filteredList);
      });
    },
    preparePerformanceLine: function preparePerformanceLine(list) {
      var dateList = list.reduce(function (acc, dataItem) {
        return acc.concat(dataItem.kpiProgress.map(function (item) {
          return item.date_recorded;
        }));
      }, []);
      var uniqueDates = _toConsumableArray(new Set(dateList)).sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
      return {
        labels: this.preparePerformanceLineLabels(uniqueDates),
        data: this.preparePerformanceLineData(list, uniqueDates)
      };
    },
    preparePerformanceLineLabels: function preparePerformanceLineLabels(dateList) {
      var labels = {
        labels: [],
        customLabels: []
      };
      dateList.forEach(function (date) {
        labels.labels.push(formatTime(date, DateFormats.DATE_DAY_FORMAT));
        labels.customLabels.push(formatTime(date, DateFormats.DATE_LONG_FORMAT));
      });
      return labels;
    },
    preparePerformanceLineData: function preparePerformanceLineData(dataList, dateList) {
      return dataList.map(function (item) {
        return {
          label: item.title,
          data: dateList.map(function (date) {
            var kpi = item.kpiProgress.find(function (item) {
              return item.date_recorded === date;
            });
            return kpi === null || kpi === void 0 ? void 0 : kpi.kpi_value;
          }),
          borderColor: item._colors[0],
          backgroundColor: item._colors[0]
        };
      });
    },
    formatKpiData: function formatKpiData(array, colorList) {
      return array.map(function (item, index) {
        var itemCopy = _objectSpread({}, item);
        itemCopy._colors = colorList[index % colorList.length] || colorList[0];
        return itemCopy;
      });
    },
    preparePerformanceData: function preparePerformanceData(list) {
      var ignoreVisibilityFlags = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      return list.reduce(function (acc, item) {
        if (!item.showOnDashboard && !ignoreVisibilityFlags) {
          return acc;
        }
        var kpiPercentRound = item.kpiPercent ? Math.round(item.kpiPercent) : 0;
        acc.push({
          id: item.id,
          title: item.title,
          description: item.kpiPercentDescription || '',
          data: [kpiPercentRound, 100 - kpiPercentRound],
          backgroundColor: item._colors
        });
        return acc;
      }, []);
    }
  }
};