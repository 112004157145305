import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "dep-relations"
};
var _hoisted_2 = {
  key: 0,
  class: "w-[320px] bg-white relations-wrapper border-2 border-solid border-gray-250 shadow-main-well h-full"
};
var _hoisted_3 = {
  class: "relations-tabs flex flex-row p-2 pb-0"
};
var _hoisted_4 = {
  class: "slot-data mt-6 mx-2 px-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tab_list = _resolveComponent("tab-list");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_Transition, {
    style: _normalizeStyle($options.transitionVars),
    name: "slide"
  }, {
    default: _withCtx(function () {
      return [$props.isExpanded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_tab_list, {
        class: "relations-tabs flex flex-row p-2 pb-0 w-full",
        tabs: $options.tabsTranslated,
        "local-storage-key": $props.localStorageKey + $data.currentTabKey,
        onUpdate: _cache[0] || (_cache[0] = function ($event) {
          return $data.currentTab = $event;
        })
      }, null, 8 /* PROPS */, ["tabs", "local-storage-key"])]), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, $data.currentTab, {
        class: "m-2"
      }, undefined, true)])])) : _createCommentVNode("v-if", true)];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["style"])]);
}