function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUserStore } from '@/Store/userStore';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { DocumentTypesIds } from '@/constants/contracts/DocumentTypes';
import { CONTRACTS_TABLE_COLUMNS } from '@/constants/contracts/contractsTableColumns';
import { MODULES } from '@/constants/modules';
import { getContractTypes } from '@/constants/contracts/contractTypes';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import * as Utils from '@/utils/Utils';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import { getContracts as _getContracts } from '@/api/contracts/contracts';
import { getWorkflowStatusesByModule } from '@/api/risma/data';
import { getCompanyIdsInArray } from '@/utils/format/companies';
import { formatTimeToDateFormat } from '@/utils/date';
import * as fileAPI from '@/api/risma/file';
export default {
  name: 'ContractsDPAOverview',
  description: 'Component for displaying a list that presents all active contracts and requirements regarding DPA for each contract',
  token: '<contracts-dpa-overview />',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaDatatable: RismaDatatable,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  mixins: [RismaDatatableDefaultMixin],
  data: function data() {
    return {
      stateLocal: 'contracts-data-process-agreement',
      columnsMeta: [{
        targets: ['startDate', 'endDate'],
        visible: false
      }],
      disabledFilterByColumns: ['startDate', 'endDate'],
      decoupleFilterFields: [{
        key: 'responsible'
      }, {
        key: 'companies'
      }],
      files: [],
      companies: [],
      workflowStatuses: [],
      contracts: [],
      dataset: [],
      isLoading: false,
      contractTypes: getContractTypes(),
      translate: getTranslate['ContractsDPAOverview']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useActivityTypesStore, {
    activityTypes: 'contractActivityTypes'
  })), mapState(useUserStore, ['userHasCompaniesAccess'])), {}, {
    columns: function columns() {
      var COLUMNS = CONTRACTS_TABLE_COLUMNS();
      var result = {
        dpa: this.translate.dataProcessorAgreement,
        mainContract: this.translate.mainContract,
        uniqueId: COLUMNS.contractNumber,
        title: COLUMNS.titlePreview,
        contractType: this.translate.contractType,
        companies: COLUMNS.companies,
        activityType: COLUMNS.activityType,
        status: COLUMNS.status,
        startDate: COLUMNS.start,
        endDate: COLUMNS.end
      };
      if (!this.userHasCompaniesAccess) {
        delete result.companies;
      }
      return result;
    },
    enhancedContracts: function enhancedContracts() {
      var newContracts = this.contracts.map(function (contract) {
        var _contract$links;
        return _objectSpread(_objectSpread({}, contract), {}, {
          hasMainContract: false,
          hasDPA: (contract === null || contract === void 0 || (_contract$links = contract.links) === null || _contract$links === void 0 || (_contract$links = _contract$links.dataprocessor_processor) === null || _contract$links === void 0 ? void 0 : _contract$links.length) || false,
          files: []
        });
      });
      this.files.forEach(function (file) {
        var _file$docTypeIds, _file$docTypeIds2;
        var contractIdx = newContracts.findIndex(function (contract) {
          return +contract.id === +file.moduleId;
        });
        if (contractIdx === -1) return;
        newContracts[contractIdx].files.push(file);
        if ((_file$docTypeIds = file.docTypeIds) !== null && _file$docTypeIds !== void 0 && _file$docTypeIds.includes(DocumentTypesIds.MAIN)) {
          newContracts[contractIdx].hasMainContract = true;
        }
        if ((_file$docTypeIds2 = file.docTypeIds) !== null && _file$docTypeIds2 !== void 0 && _file$docTypeIds2.includes(DocumentTypesIds.DPA)) {
          newContracts[contractIdx].hasDPA = true;
        }
      });
      return newContracts;
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      this.isLoading = true;
      return this.getInitialData().then(function () {
        _this.updateDataset();
        _this.isLoading = false;
      });
    },
    getInitialData: function getInitialData() {
      return Promise.all([this.getCompanies(), this.getFiles(), this.getWorkflowStatuses(), this.getContracts()]);
    },
    getContracts: function getContracts() {
      var _this2 = this;
      return _getContracts().then(function (_ref) {
        var list = _ref.list;
        _this2.contracts = list;
      });
    },
    getFiles: function getFiles() {
      var _this3 = this;
      return fileAPI.searchFiles('?module=contracts').then(function (_ref2) {
        var list = _ref2.list;
        _this3.files = list;
      });
    },
    getCompanies: function getCompanies() {
      var _this4 = this;
      return _getCompanies().then(function (_ref3) {
        var list = _ref3.list;
        return _this4.companies = list;
      });
    },
    getWorkflowStatuses: function getWorkflowStatuses() {
      var _this5 = this;
      return getWorkflowStatusesByModule(MODULES.CONTRACTS).then(function (_ref4) {
        var list = _ref4.list;
        return _this5.workflowStatuses = list;
      });
    },
    getCompaniesList: function getCompaniesList(ids) {
      return getCompanyIdsInArray(ids, this.companies);
    },
    getActivityTypeLabel: function getActivityTypeLabel(id) {
      return Utils.findPropById(id, this.activityTypes, 'label');
    },
    getWorkflowStatusLabel: function getWorkflowStatusLabel(id) {
      return Utils.findPropById(id, this.workflowStatuses, 'title');
    },
    prepareDataset: function prepareDataset() {
      var _this6 = this;
      this.dataset = this.enhancedContracts.map(function (item) {
        var _item$links, _Object$values$find;
        var companies = _this6.getCompaniesList((item === null || item === void 0 || (_item$links = item.links) === null || _item$links === void 0 ? void 0 : _item$links.company) || []);
        var result = {
          dpa: {
            field: item.hasDPA ? _this6.translate.yes : _this6.translate.no
          },
          mainContract: {
            field: item.hasMainContract ? _this6.translate.yes : _this6.translate.no
          },
          uniqueId: {
            field: item.uniqueId || ''
          },
          title: {
            activity: item,
            fieldText: item.title,
            hoverTitle: DatatableRenderer.getItemHoverTitle(item)
          },
          contractType: {
            field: ((_Object$values$find = Object.values(_this6.contractTypes).find(function (type) {
              return type.value === item.contractType;
            })) === null || _Object$values$find === void 0 ? void 0 : _Object$values$find.label) || ''
          },
          companies: {
            field: companies,
            fieldText: companies.map(function (item) {
              return item.name;
            }).join(', ')
          },
          activityType: {
            field: _this6.getActivityTypeLabel(item.activityTypeId)
          },
          status: {
            field: _this6.$trans(_this6.getWorkflowStatusLabel(item.workflowStatusId))
          },
          startDate: {
            field: item.startDate ? formatTimeToDateFormat(item.startDate) : ''
          },
          endDate: {
            field: item.endDate ? formatTimeToDateFormat(item.endDate) : ''
          }
        };
        if (!_this6.userHasCompaniesAccess) {
          delete result.companies;
        }
        return result;
      });
    },
    updateDataset: function updateDataset() {
      this.prepareDataset(), this.enhanceDataset(this.dataset, this.columns);
    },
    reloadContracts: function reloadContracts() {
      return Promise.all([this.getFiles(), this.getContracts()]);
    },
    onPreviewDismiss: function onPreviewDismiss() {
      var _this7 = this;
      this.isLoading = true;
      this.reloadContracts().then(function () {
        _this7.updateDataset();
        _this7.isLoading = false;
      });
    }
  }
};