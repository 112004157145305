import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
var CONTRACTS_DEFAULT = '/contracts/contract';
export function getTableContracts() {
  var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return handleRequestResponse(Http.get('/contracts/reports' + (query ? "?".concat(query) : '')));
}
export function getMyTableContracts() {
  var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  query = new URLSearchParams(query);
  query.append('filters[onlyMy]', 'true');
  return getTableContracts(query.toString());
}
export function getExpiredTableContracts() {
  var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  query = new URLSearchParams(query);
  query.append('filters[expired]', 'true');
  return getTableContracts(query.toString());
}
export function getControlSummary() {
  var onlyMy = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var expired = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var query = new URLSearchParams('');
  if (onlyMy) {
    query.append('onlyMy', 'true');
  }
  if (expired) {
    query.append('expired', 'true');
  }
  return handleRequestResponse(Http.get('/contracts/controlsummary?' + query.toString()));
}
export function getContracts() {
  var includeArchived = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var url = CONTRACTS_DEFAULT;
  if (includeArchived) {
    url += '?includeArchived=true';
  }
  return handleRequestResponse(Http.get(url));
}
export function getMyContracts() {
  var url = '/contracts/my';
  return handleRequestResponse(Http.get(url));
}
export function addContract(data) {
  return handleRequestResponse(Http.post(CONTRACTS_DEFAULT, data));
}
export function getContract(id) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return handleRequestResponse(Http.get("".concat(CONTRACTS_DEFAULT, "/").concat(id).concat(params)));
}
export function editContract(data) {
  return handleRequestResponse(Http.patch("".concat(CONTRACTS_DEFAULT, "/").concat(data.id), data));
}
export function getRelated(id) {
  return handleRequestResponse(Http.get("".concat(CONTRACTS_DEFAULT, "/").concat(id, "/related")));
}
export function getContractsReport() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return handleRequestResponse(Http.get("/contracts/reports".concat(params)));
}
export function getMyContractsCompanies() {
  return handleRequestResponse(Http.get('/contracts/my/companies'));
}
export function deleteContract(id) {
  return handleRequestResponse(Http.delete("".concat(CONTRACTS_DEFAULT, "/").concat(id)));
}
export function copyContract(contractId) {
  var withControls = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var query = contractId + (withControls ? '/controls' : '');
  return handleRequestResponse(Http.post('/contracts/copy/' + query));
}
export function getDoraReports(companyId) {
  return handleRequestResponse(Http.get("/contracts/report/dora/".concat(companyId, "/all/validate")));
}
export function getDoraDefinitions() {
  return handleRequestResponse(Http.get("/contracts/dora_definitions"));
}
export function editDoraDefinitions(value) {
  return handleRequestResponse(Http.patch("/contracts/dora_definitions", {
    value: value
  }));
}