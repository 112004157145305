function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import { useUsersStore } from '@/Store/usersStore';
import { setNewSequenceNumber as _setNewSequenceNumber } from '@/api/execution/initiative';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import StaticTable from '@/components/Molecules/StaticTable';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import Notification from '@/components/Molecules/Notification';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { findPropsByIds, checkStringIsInt } from '@/utils/Utils';
export default {
  name: 'ActionUpdateSequenceNumberModal',
  introduction: 'Move initiative and underlying dates modal',
  components: {
    Modal: Modal,
    RismaTitle: RismaTitle,
    InputField: InputField,
    StaticTable: StaticTable,
    TrafficLight: TrafficLight,
    Notification: Notification,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    initiative: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['dismiss'],
  data: function data() {
    return {
      columnsMaxWidth: {
        title: '300px'
      },
      loading: false,
      newNumber: '',
      error: '',
      numberError: '',
      translate: getTranslate['ActionUpdateSequenceNumberModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), {}, {
    columns: function columns() {
      return ['#', this.translate.title, this.translate.responsible, this.translate.deadline, this.translate.trafficLight];
    },
    dataset: function dataset() {
      var _this = this;
      var itemsList = this.getTreeChildren(this.initiative);
      return itemsList.map(function (child) {
        var _child$userIds;
        return {
          sequenceNumber: child.path,
          title: child.title,
          responsible: findPropsByIds(((_child$userIds = child.userIds) === null || _child$userIds === void 0 ? void 0 : _child$userIds.responsible) || [], _this.users, 'display_name'),
          deadline: child.deadline,
          trafficLight: _this.getTrafficLightColorByObjectTypeAndNumber(child.objType, child.trafficLight)
        };
      });
    },
    header: function header() {
      return this.translate.newNumberForAction + ' ' + this.initiative.displayHeadline;
    },
    pathLastNumber: function pathLastNumber() {
      var dotIndex = this.initiative.path.lastIndexOf('.');
      return dotIndex === -1 ? this.initiative.path : this.initiative.path.slice(dotIndex + 1);
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightColorByObjectTypeAndNumber'])), {}, {
    onModalAccept: function onModalAccept() {
      if (!this.validateData()) return;
      return this.setNewSequenceNumber();
    },
    validateData: function validateData() {
      this.numberError = '';
      if (!this.newNumber || !checkStringIsInt(this.newNumber)) {
        this.numberError = this.translate.newNumberMustConsistOnlyOfDigits;
      } else if (this.newNumber <= 0) {
        this.numberError = this.translate.theNumberMustBeGreaterThan0;
      } else if (this.newNumber === this.pathLastNumber) {
        this.numberError = this.translate.theNumberAlreadyExistPleaseChooseAUniqueNumber;
      }
      return !this.numberError;
    },
    setNewSequenceNumber: function setNewSequenceNumber() {
      var _this2 = this;
      return _setNewSequenceNumber(this.initiative.id, +this.newNumber).then(function () {
        var newSlug = _this2.initiative.slug.replace("".concat(_this2.initiative.ipath), _this2.newNumber);
        _this2.$router.push("/actions/i/-/".concat(newSlug, "/").concat(_this2.initiative.id));
      }).catch(function (error) {
        var _error$response;
        _this2.error = _this2.$trans((_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.data) || _this2.translate.somethingWentWrong;
      }).finally(function () {
        _this2.loading = false;
      });
    },
    getTreeChildren: function getTreeChildren(tree) {
      var _this3 = this;
      var result = [_objectSpread(_objectSpread({}, tree), {}, {
        children: null
      })];
      var children = tree.children || [];
      children.forEach(function (child) {
        result.push.apply(result, _toConsumableArray(_this3.getTreeChildren(child)));
      });
      return result;
    }
  })
};