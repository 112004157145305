export function toPrint(id) {
  var html = document.getElementById(id).cloneNode(true);
  html.querySelectorAll('img.dataFlowImage').forEach(function (img) {
    var newImage = document.createElement('img');
    newImage.src = img.src;
    newImage.classList.add('dataFlowImage');
    img.parentNode.replaceChild(newImage, img);
  });
  var newWindow = window.open();
  newWindow.document.write(template(html.innerHTML));
  newWindow.focus();
  newWindow.print();
  newWindow.close();
}
export function printTable(dataset, columns) {
  var headTitle = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var cssProps = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var mediaPrintCSS = cssProps.mediaPrintCSS || defaultMediaPrintCSS();
  var printCSS = cssProps.printCSS || defaultPrintCSS();
  var iframeId = 'printingFrame';
  var _prepareIframe = prepareIframe(iframeId, getStyles(mediaPrintCSS, printCSS)),
    iframe = _prepareIframe.iframe,
    iframeBody = _prepareIframe.iframeBody;
  var tableMaxSize = 1750; // in px;
  var maxAllowedIterations = 100;
  var iterationsCounter = 0;
  var insertedItemsCounter = 0;
  if (headTitle) {
    var header = document.createElement('h1');
    header.textContent = headTitle;
    iframeBody.appendChild(header);
  }
  var _loop = function _loop() {
    iterationsCounter += 1;
    var table = prepareTableWithHeaders(columns);
    var tableRows = table.querySelectorAll('tr');
    iframeBody.appendChild(table);
    var _loop2 = function _loop2() {
      var item = dataset[i];
      Object.keys(columns).forEach(function (headerKey, index) {
        var row = tableRows[index];
        var tableCell = document.createElement('td');
        tableCell.innerHTML = item[headerKey] || '';
        row.appendChild(tableCell);
      });
      insertedItemsCounter += 1;
      if (table.offsetWidth > tableMaxSize) {
        return 1; // break
      }
    };
    for (var i = insertedItemsCounter; i < dataset.length; i++) {
      if (_loop2()) break;
    }
  };
  do {
    _loop();
  } while (insertedItemsCounter < dataset.length && iterationsCounter < maxAllowedIterations);
  setTimeout(function () {
    window.frames[iframeId].focus();
    window.frames[iframeId].print();
    document.body.removeChild(iframe);
  }, 100);
}
function prepareTableWithHeaders(columns) {
  var table = document.createElement('table');
  var tbody = document.createElement('tbody');
  var showHeaders = Object.values(columns).some(Boolean);
  Object.values(columns).forEach(function (headerTitle) {
    var row = document.createElement('tr');
    if (showHeaders) {
      var header = document.createElement('td');
      header.innerText = headerTitle;
      row.appendChild(header);
    }
    tbody.appendChild(row);
  });
  table.appendChild(tbody);
  return table;
}
function prepareIframe(id, styles) {
  var iframe = document.createElement('iframe');
  iframe.id = id;
  iframe.name = id;
  iframe.width = '0';
  iframe.height = '0';
  iframe.style = 'position: absolute;top: 0;left: 0;width: 0;height: 0;';
  document.body.appendChild(iframe);
  var iframeBody = document.createElement('body');
  iframeBody.append(styles);
  iframe.contentWindow.document.open();
  iframe.contentWindow.document.append(iframeBody);
  iframe.contentWindow.document.close();
  return {
    iframe: iframe,
    iframeBody: iframeBody
  };
}
function getStyles(mediaPrintCSS, printCSS) {
  var fragment = new DocumentFragment();
  var style = fragment.appendChild(document.createElement('style'));
  style.textContent = "".concat(mediaPrintCSS, " ").concat(printCSS);
  return fragment;
}
function css() {
  return "\n      * {\n        -webkit-print-color-adjust: exact;\n      }\n      table {\n        font-family: 'Inter', sans-serif;\n      }\n      tr.odd td {\n        background: #ececec;\n      }\n      tr.even td {\n        background: #ffffff;\n      }\n      th, td {\n        vertical-align: top;\n      }\n      th {\n        color: #000;\n        font-size: 14px;\n        padding: 5px 7px;\n        font-weight: 600;\n        min-width: 70px;\n      }\n      td {\n        font-size: 8pt;\n      }\n\n      td img.dataFlowImage {\n        width: 400px;\n        height: auto;\n      }\n\n      tr td:first-child a {\n        font-size:14px;\n        color: black;\n      }\n      th:first-child, td:first-child {\n        padding-left: 15px;\n      }\n\n      th:last-child, td:last-child {\n        padding-right: 15px;\n      }\n\n      .risma-button {\n        display: none;\n      }\n      ";
}
function printCss() {
  return "@media print {\n  @page {size: A4 landscape;}\n  table { page-break-after:auto }\n  tr    { page-break-inside:avoid; page-break-after:auto }\n  td    { page-break-inside:avoid; page-break-after:auto;border-left: 1px solid rgba(0,0,0,0.1);}\n  th div p { display: none}\n  tbody tr:first-child td { border-top: 1px solid rgba(0,0,0,0.1);}\n  td div p {\n  page-break-inside: avoid;\n  }\n  /*thead { display:table-header-group;}*/\n  tfoot { display:table-footer-group }\n\n  ".concat(css(), "\n}");
}
function defaultPrintCSS() {
  return "\n        body {\n            font-family: 'Inter', sans-serif;\n            padding-left: 20px;\n            padding-right: 50px;\n            margin: 0 auto;\n        }\n        h1 {\n            font-family: 'Inter', sans-serif;\n            color: '#347480';\n            font-weight: 300;\n            font-size: 2.5rem;\n            margin: 0;\n        }\n        table {\n            max-width: 1750px;\n            width: 100%;\n            border-collapse: separate;\n            border-spacing: 0;\n        }\n        tr:nth-child(even) {\n          background-color: rgba(200, 200, 200, 0.4);\n        }\n\n        td:nth-child(even) {\n          background-color: rgba(200, 200, 200, 0.4);\n        }\n        td,\n        th {\n            padding: 8px;\n            font-size: 14px;\n            box-sizing: content-box;\n            text-align: left;\n        }\n        th {\n            border-bottom: 1px solid #111;\n        }";
}
function defaultMediaPrintCSS() {
  return "\n        @media print {\n            table { break-after: page }\n            tr    { break-inside:avoid; break-after:auto }\n            td    { break-inside:avoid; break-after:auto; }\n        } ";
}
function template(html) {
  return "<html><head><title>RISMAbusiness</title><link href=\"/assets/css/bootstrap4.min.css\" rel=\"stylesheet\"><link href=\"https://fonts.googleapis.com/css?family=Inter:wght@100..900&display=swap\" rel=\"stylesheet\" type=\"text/css\"><style>".concat(printCss()).concat(css(), "</style></head><body>").concat(html, "</body></html>").replace(/\s{2,}/g, ' ');
}