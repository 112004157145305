import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, Fragment as _Fragment, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "mini-tree h-full w-full flex"
};
var _hoisted_2 = {
  key: 0,
  ref: "filters"
};
var _hoisted_3 = {
  class: "flex justify-between items-center mt-2"
};
var _hoisted_4 = {
  key: 1
};
var _hoisted_5 = {
  class: "flex items-center"
};
var _hoisted_6 = {
  class: "ml-3"
};
var _hoisted_7 = {
  class: "text-center"
};
var _hoisted_8 = {
  class: "ml-10"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_mini_tree_filters = _resolveComponent("mini-tree-filters");
  var _component_mini_tree_level_selector = _resolveComponent("mini-tree-level-selector");
  var _component_mini_tree_branch_draggable = _resolveComponent("mini-tree-branch-draggable");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createBlock(_resolveDynamicComponent($props.hasHorizontalResizeBox ? 'HorizontalResizeBox' : 'HorizontalResizeFallback'), {
    "local-storage-key": "".concat($setup.localStorageKey, "_horizontal"),
    class: _normalizeClass({
      'min-h-content': $props.hasHorizontalResizeBox
    })
  }, {
    leftColumn: _withCtx(function () {
      var _$props$branch;
      return [$props.hasOptionalFunctionality ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$props.title ? (_openBlock(), _createBlock(_component_risma_title, {
        key: 0,
        title: $props.title,
        type: "big"
      }, null, 8 /* PROPS */, ["title"])) : (_openBlock(), _createElementBlock("span", _hoisted_4)), _createElementVNode("div", _hoisted_5, [$props.showTreeFilters ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "cursor-pointer flex -ml-8 m-0.3rem pl-8 mt-3 py-3",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $data.areFiltersOpened = !$data.areFiltersOpened;
        })
      }, [_createVNode(_component_risma_title, {
        type: "small",
        class: "!text-neutral-500",
        title: $data.translate.filters
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
        icon: "chevron-down",
        class: _normalizeClass(['transform duration-500 min-w-2rem text-neutral-500 stroke-2', {
          'rotate-180': $data.areFiltersOpened
        }])
      }, null, 8 /* PROPS */, ["class"])])) : _createCommentVNode("v-if", true), !$props.readOnly && $props.addNodeEnabled ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 1,
        text: $props.addNewNodeLabel || $data.translate.newNode,
        type: "save",
        class: "mr-2",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('addNode');
        })
      }, null, 8 /* PROPS */, ["text"])) : _createCommentVNode("v-if", true)])]), $props.showTreeFilters ? (_openBlock(), _createBlock(_component_mini_tree_filters, {
        key: 0,
        "filter-options-data": $props.filterOptionsData,
        "opened-state": $data.areFiltersOpened,
        "local-storage-unique-key": $props.localStorageUniqueKey,
        onSearch: $options.searchData,
        "onUpdate:opened": _cache[2] || (_cache[2] = function ($event) {
          return $data.areFiltersOpened = $event;
        })
      }, null, 8 /* PROPS */, ["filter-options-data", "opened-state", "local-storage-unique-key", "onSearch"])) : _createCommentVNode("v-if", true), _createVNode(_component_mini_tree_level_selector, {
        class: "my-3",
        "model-value": $data.level,
        "max-level": $data.maxPossibleLevel,
        "onUpdate:modelValue": $options.setLevel
      }, null, 8 /* PROPS */, ["model-value", "max-level", "onUpdate:modelValue"])], 512 /* NEED_PATCH */)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
        class: _normalizeClass(["mt-6", !$data.areFiltersOpened ? 'overflow-y-auto' : '']),
        style: _normalizeStyle({
          maxHeight: "calc(100vh - ".concat($data.leftColumnMaxHeight, "px)")
        })
      }, [_createElementVNode("div", _hoisted_6, [$props.loadState ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [(_$props$branch = $props.branch) !== null && _$props$branch !== void 0 && (_$props$branch = _$props$branch.children) !== null && _$props$branch !== void 0 && _$props$branch.length ? (_openBlock(), _createBlock(_component_mini_tree_branch_draggable, _mergeProps({
        key: 0,
        list: $props.branch.children,
        "opened-array": $data.openedArray,
        "selected-path": $setup.selectedPath,
        "parent-enabled": true,
        "search-enabled": $props.searchEnabled,
        "has-toggle-menu-prop": $props.hasToggleMenuProp,
        "node-emits": $props.nodeEmits
      }, _ctx.$attrs, {
        draggable: $props.draggable,
        "onDrag:end": $options.handleDragEnd,
        "onDrop:showHint": $options.showDropHint,
        "onUpdate:selected": $options.setSelectedPath,
        "onUpdate:opened": $options.setOpened
      }), null, 16 /* FULL_PROPS */, ["list", "opened-array", "selected-path", "search-enabled", "has-toggle-menu-prop", "node-emits", "draggable", "onDrag:end", "onDrop:showHint", "onUpdate:selected", "onUpdate:opened"])) : _renderSlot(_ctx.$slots, "emptyListLabel", {
        key: 1
      }, function () {
        return [_createElementVNode("span", _hoisted_7, _toDisplayString($data.translate.listIsEmpty), 1 /* TEXT */)];
      })], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 1
      }))])], 6 /* CLASS, STYLE */)];
    }),
    rightColumn: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_8, [_renderSlot(_ctx.$slots, "nodeContent")])];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["local-storage-key", "class"])), $props.draggable ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "absolute",
    style: _normalizeStyle({
      left: $data.leftPosition + 'px',
      top: $data.topPosition + 'px'
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "arrow-right",
    class: "stroke-2"
  })], 4 /* STYLE */)) : _createCommentVNode("v-if", true)]);
}