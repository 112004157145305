function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import RismaSelect from '../Atoms/Inputs/RismaSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import { formatNodes as _formatNodes } from '@/utils/format/Compliance';
export default {
  name: 'RismaSelectorBar',
  components: {
    RismaSelect: RismaSelect,
    FeatherIcon: FeatherIcon,
    RismaToggle: RismaToggle,
    FrameworksSelector: FrameworksSelector
  },
  introduction: 'Parent component for <risma-select>',
  description: '',
  token: '<risma-selector-bar :selectors="rismaSelectorBarData" />',
  props: {
    selectors: {
      required: true,
      type: Array,
      note: 'an Object Array determines which lists to show, all item must have a list key, label key and a preSelected key ie. [{list: "organisations", label:"Orgs", preSelected:[]},{list: "users", label:"Responsible", preSelected:[{user}, {user}]}]'
    },
    projectId: {
      required: false,
      type: [String, Number],
      default: 0,
      note: 'projectId is used to filter lists, so only items with connection or access to a project is visible. Required if nodes selector is passed'
    },
    filter: {
      type: Boolean,
      default: false,
      note: 'Should the ListBar return a filter string on change?'
    },
    orgs: {
      type: Array,
      required: true,
      note: 'List of organisations'
    },
    users: {
      type: Array,
      required: true,
      note: 'List of users'
    },
    nodes: {
      type: Array,
      required: true,
      note: 'List of nodes'
    },
    tags: {
      type: Array,
      required: true,
      note: 'List of nodes'
    },
    solutionsPropName: {
      required: false,
      type: String,
      default: 'solutionIds',
      note: 'Property name used in filter string'
    }
  },
  emits: ['listChange', 'reset'],
  data: function data() {
    return {
      formattedNodes: [],
      numbersInitialized: 0,
      selectedFrameworks: [],
      translate: {
        noFiltersApplied: this.$trans('No filters applied'),
        resetFilter: this.$trans('Reset filter'),
        selectAll: this.$trans('Select all')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useTrafficLights, ['gapTrafficLights'])), {}, {
    colNumber: function colNumber() {
      var number = Math.floor(Math.round(12 / this.selectors.length));
      if (number < 3) {
        number = 3;
      }
      if (number === 12) {
        number = 4;
      }
      return number;
    },
    listOptions: function listOptions() {
      var _this = this;
      return {
        gaps: {
          list: this.gapTrafficLights.map(function (gap) {
            return {
              id: gap.number,
              color: gap.color,
              text_color: gap.text_color,
              label: _this.$trans(gap.legend)
            };
          }),
          display: 'label'
        },
        nodes: null,
        controls: null,
        initiatives: null,
        users: {
          list: [],
          display: 'display_name'
        },
        organisations: {
          list: [],
          display: 'visible_name'
        },
        tags: {
          list: [],
          display: 'tag'
        }
      };
    },
    hasFrameworks: function hasFrameworks() {
      return !!this.selectors.find(function (selector) {
        return selector.list === 'frameworks';
      });
    }
  }),
  created: function created() {
    this.listOptions.users.list = this.users;
    this.listOptions.organisations.list = this.orgs;
    this.formattedNodes = this.formatNodes(this.nodes);
    this.listOptions.nodes = {
      display: 'title',
      list: this.formattedNodes
    };
    this.listOptions.tags.list = this.tags;
    if (this.hasFrameworks) {
      var _this$selectors$find;
      var frameworks = ((_this$selectors$find = this.selectors.find(function (selector) {
        return selector.list === 'frameworks';
      })) === null || _this$selectors$find === void 0 ? void 0 : _this$selectors$find.preSelected) || [];
      this.selectedFrameworks = frameworks;
    }
  },
  methods: {
    formatPreSelected: function formatPreSelected(selector) {
      var mapping = {
        organisations: {
          list: this.orgs,
          display_name: 'visible_name'
        },
        users: {
          list: this.users,
          display_name: 'display_name'
        },
        nodes: {
          list: this.formattedNodes,
          display_name: 'title'
        },
        tags: {
          list: this.tags,
          display_name: 'tag'
        }
      };
      if (!mapping[selector.list]) {
        return selector.preSelected;
      }
      selector.preSelected.forEach(function (item) {
        var entry = mapping[selector.list].list.find(function (e) {
          return e.id === item.id;
        });
        if (entry) {
          item[mapping[selector.list].display_name] = entry[mapping[selector.list].display_name];
        }
      });
      return selector.preSelected;
    },
    formatNodes: function formatNodes(nodes) {
      return _formatNodes(nodes, '', [], true).map(function (item) {
        return {
          id: item.id,
          title: item.title
        };
      });
    },
    resetAll: function resetAll() {
      var _this2 = this;
      this.$emit('reset');
      Object.keys(this.$refs).forEach(function (filterName) {
        _this2.$refs[filterName][0].selected = [];
      });
      this.selectedFrameworks = [];
      this.$forceUpdate();
      this.emit();
    },
    initialized: function initialized(val, selector) {
      this.numbersInitialized++;
      this.change(val, selector);
    },
    change: function change(val, selector) {
      var classPrefixes = {
        gaps: {
          selectorPrefix: '.gaps',
          elClassPrefix: 'gap'
        },
        nodes: {
          selectorPrefix: '.nodes',
          elClassPrefix: 'node'
        },
        organisations: {
          selectorPrefix: '.organisations',
          elClassPrefix: 'organisation'
        },
        users: {
          selectorPrefix: '.users',
          elClassPrefix: 'user'
        },
        tags: {
          selectorPrefix: '.tags',
          elClassPrefix: 'tag'
        }
      };
      if (!classPrefixes[selector.list]) {
        return;
      }
      if (this.numbersInitialized === this.selectors.length || this.hasFrameworks && this.numbersInitialized === this.selectors.length - 1) {
        this.emit();
      }
    },
    selectFrameworks: function selectFrameworks(value) {
      this.selectedFrameworks = value;
      this.emit();
    },
    emit: function emit() {
      var _this3 = this;
      var returnObject = {};
      var filterString = this.makeFilterString();
      Object.keys(this.$refs).forEach(function (key) {
        returnObject[key] = _this3.$refs[key][0].selected;
      });
      if (this.hasFrameworks) {
        returnObject.frameworks = this.selectedFrameworks;
      }
      this.$emit('listChange', {
        filterString: filterString.substr(0, filterString.length - 1),
        filter: returnObject
      });
    },
    makeFilterString: function makeFilterString() {
      var _this4 = this;
      var filter = '';
      var map = {
        gaps: 'analysisRAG',
        organisations: 'organisationIds',
        nodes: 'nodeIds',
        users: 'responsibleUserIds'
      };
      //forEach selected attribute in every refs object
      Object.keys(this.$refs).forEach(function (filterName) {
        var refObj = _this4.$refs[filterName][0];
        refObj.selected.forEach(function (item) {
          if (item.label === _this4.translate.selectAll) return;
          if (filterName === 'gaps') {
            filter += 'filter[' + map[filterName] + '][]=' + item.label + '&';
          } else if (filterName === 'tags') {
            filter += 'filter[tagIds][]=' + item.id + '&';
          } else filter += 'filter[' + map[filterName] + '][]=' + item.id + '&';
        });
      });
      if (this.hasFrameworks && this.selectedFrameworks.length) {
        var list = this.selectedFrameworks.map(function (framework) {
          return framework.id;
        });
        filter += "filter[".concat(this.solutionsPropName, "]=") + list.join(',') + '&';
      }
      return filter;
    }
  }
};