import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue";
var _hoisted_1 = ["data-path"];
var _hoisted_2 = ["title"];
var _hoisted_3 = {
  class: "drag-icon"
};
var _hoisted_4 = {
  key: 0,
  class: "mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["py-2 relative mini-tree-branch vertical-arrow-last pl-5 vertical-arrow", [{
      'vertical-arrow-last-selected': $props.parentId === $options.lastIdInSelectedChain || $options.beforeSelected && !$options.isSelected || $props.searchEnabled,
      'vertical-arrow-last-headless': $options.level > 0 && $options.index === 0 && $options.hasChildren
    }]]),
    "data-path": $props.path
  }, [_createElementVNode("div", {
    class: _normalizeClass(["flex items-center cursor-pointer relative min-h-[34px] js-branch group", [{
      'hori-arrow': $options.level > 0 && $options.index === 0,
      'hori-arrow hori-arrow-on-hidden-children': !$options.hasChildren && !($options.level > 0 && $options.index === 0),
      'hori-arrow-selected': $options.isElementHighlighted || $options.beforeSelected && $options.index === 0
    }]]),
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.handleClick && $options.handleClick.apply($options, arguments);
    })
  }, [$options.hasChildren ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(['arrow-icon transform duration-500 stroke-2 mr-2 icon-position', $options.isElementHighlighted || $options.beforeSelected ? 'icon-blue' : 'icon-gray', {
      'rotate-90': $options.isOpened
    }]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
      return _ctx.$emit('update:opened', $props.item);
    }, ["stop"]))
  }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass([[$options.isElementHighlighted ? 'text-rm-text font-semibold' : 'text-neutral-500', {
      'bg-blue-60 rounded-md': $options.lastIdInSelectedChain === $props.item.id,
      'vertical-arrow-first-selected': $options.beforeSelected || $props.parentId === $options.lastIdInSelectedChain || $props.searchEnabled,
      'first-child-vertical-arrow': $options.index === 0 && $options.level > 0,
      'vertical-arrow-first': $options.index > 0 || $options.level > 0,
      'vertical-arrow-first-hidden': $options.index === 0,
      'line-through': $props.item.enabled === false
    }], "w-fit ml-1 flex items-center px-1 vertical-arrow group-hover:bg-blue-60 group-hover:rounded-md min-h-[34px]"]),
    title: $props.item.title
  }, [$props.item.icon ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    class: _normalizeClass(["mr-2 flex-none", $props.item.icon.class]),
    icon: $props.item.icon.name
  }, null, 8 /* PROPS */, ["class", "icon"])) : _createCommentVNode("v-if", true), _createElementVNode("span", _hoisted_3, _toDisplayString($props.item.title || $props.item.name), 1 /* TEXT */), $options.hasToggleMenu ? (_openBlock(), _createBlock(_component_toggle_menu, {
    key: 1,
    class: "ml-4 font-normal",
    position: "fixed",
    "menu-style": "minimal",
    options: $options.nodeActions
  }, null, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true)], 10 /* CLASS, PROPS */, _hoisted_2)], 2 /* CLASS */), $options.hasChildren && $options.isOpened ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_renderSlot(_ctx.$slots, "children")])) : _createCommentVNode("v-if", true)], 10 /* CLASS, PROPS */, _hoisted_1);
}