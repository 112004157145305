import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-rm-grey rounded-sm w-[1.125rem] h-[1.125rem] flex justify-center items-center active:bg-rm-active-blue--10 active:text-rm-active-blue", $data.extraStyles])
  }, _cache[0] || (_cache[0] = [_createElementVNode("svg", {
    width: "8",
    height: "14",
    viewBox: "0 0 8 14",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    class: "fill-current align-middle"
  }, [_createElementVNode("path", {
    d: "M0 12.6C0 11.83 0.626087 11.2 1.3913 11.2C2.15652 11.2 2.78261 11.83 2.78261 12.6C2.78261 13.37 2.15652 14 1.3913 14C0.626087 14 0 13.37 0 12.6ZM0 7C0 6.23 0.626087 5.6 1.3913 5.6C2.15652 5.6 2.78261 6.23 2.78261 7C2.78261 7.77 2.15652 8.4 1.3913 8.4C0.626087 8.4 0 7.77 0 7ZM0 1.4C0 0.63 0.626087 0 1.3913 0C2.15652 0 2.78261 0.63 2.78261 1.4C2.78261 2.17 2.15652 2.8 1.3913 2.8C0.626087 2.8 0 2.17 0 1.4ZM5.21739 12.6C5.21739 11.83 5.84348 11.2 6.6087 11.2C7.37391 11.2 8 11.83 8 12.6C8 13.37 7.37391 14 6.6087 14C5.84348 14 5.21739 13.37 5.21739 12.6ZM5.21739 7C5.21739 6.23 5.84348 5.6 6.6087 5.6C7.37391 5.6 8 6.23 8 7C8 7.77 7.37391 8.4 6.6087 8.4C5.84348 8.4 5.21739 7.77 5.21739 7ZM5.21739 1.4C5.21739 0.63 5.84348 0 6.6087 0C7.37391 0 8 0.63 8 1.4C8 2.17 7.37391 2.8 6.6087 2.8C5.84348 2.8 5.21739 2.17 5.21739 1.4Z"
  })], -1 /* HOISTED */)]), 2 /* CLASS */);
}