import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "flex flex-grow flex-col p-8 justify-center items-center bg-rm-white z-1"
};
var _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_buttons_row = _resolveComponent("buttons-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.isAiTerms ? (_openBlock(), _createElementBlock("p", {
    key: 0,
    class: _normalizeClass($props.paragraphStyle)
  }, [_createElementVNode("span", {
    innerHTML: $options.confirmationText
  }, null, 8 /* PROPS */, _hoisted_2)], 2 /* CLASS */)) : (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList($options.confirmationText, function (text) {
    return _openBlock(), _createElementBlock("p", {
      key: text,
      class: _normalizeClass($props.paragraphStyle)
    }, _toDisplayString(text), 3 /* TEXT, CLASS */);
  }), 128 /* KEYED_FRAGMENT */)), _createVNode(_component_buttons_row, {
    data: $options.buttonsRowData,
    class: "mt-8"
  }, null, 8 /* PROPS */, ["data"])]);
}