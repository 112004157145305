function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { isEmailValid } from '@/utils/validations';
import { getQuizQuestionnaires } from '@/api/compliance/quiz';
var signOffModalModes = {
  DRAFT: 'draft',
  CREATE: 'create',
  REMIND: 'remind'
};
export default {
  name: 'SignOffForm',
  description: 'Sign-off-form is a part of sign-off-modal and uses for preview awareness request',
  token: '<sign-off-form />',
  components: {
    FeatherIcon: FeatherIcon,
    TextBox: TextBox,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    InputField: InputField,
    SingleSelect: SingleSelect
  },
  props: {
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of awareness.users'
    },
    usersRow: {
      type: String,
      default: ''
    },
    isAwarenessSent: {
      type: Boolean,
      required: true,
      default: false
    },
    readonly: {
      type: Boolean,
      required: true,
      default: false
    },
    dashboardTitle: {
      type: String,
      default: ''
    },
    comments: {
      type: String,
      default: ''
    },
    modalType: {
      type: String,
      default: signOffModalModes.CREATE
    },
    activityTitle: {
      type: String,
      required: true
    },
    validationUsers: {
      type: String,
      required: false,
      default: ''
    },
    validationDashboard: {
      type: String,
      required: false,
      default: ''
    },
    validationEmail: {
      type: String,
      required: false,
      default: ''
    },
    quizQuestionnaireId: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  emits: ['remove:user', 'update:dashboardTitle', 'update:comments', 'update:usersRow', 'add:newEmail', 'update:quizQuestionnaireId'],
  data: function data() {
    return {
      newEmail: '',
      newEmailError: '',
      signOffModalModes: signOffModalModes,
      quizQuestionnaires: [],
      translate: getTranslate['SignOffForm']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isQuiz: function isQuiz() {
      return this.getSettingValue('feature.compliance_process_tree.awareness_quiz');
    },
    dashboardLabel: function dashboardLabel() {
      if (this.isQuiz) return this.translate.title;
      return this.translate.dashboardTitle;
    },
    descriptionText: function descriptionText() {
      if (this.isQuiz) return this.translate.whenYouSendThisRequestToTheSelectedUsersWithQuiz;
      var result = this.translate.whenYouSendThisRequestToTheSelectedUsers;
      if (this.isAwarenessSent) {
        result = this.translate.thisMessageWillOnlyBeSendToTheUsersWhoHa;
        result += ' ';
        result += this.translate.noteThatYouCanAddMoreRecipients;
      }
      return result;
    },
    documentationDescription: function documentationDescription() {
      var mainTitle = this.translate.theUsersWillBePresentedWithTheContentOf;
      return "".concat(mainTitle, ": ").concat(this.activityTitle);
    },
    titlePlaceholder: function titlePlaceholder() {
      if (this.isQuiz) return this.translate.typeTheTitleOf;
      return this.translate.stateTheTitleOfTheDashboardThatWillBeCrea;
    }
  }),
  mounted: function mounted() {
    this.getQuizData();
  },
  methods: {
    getQuizData: function getQuizData() {
      var _this = this;
      if (!this.isQuiz) return;
      return getQuizQuestionnaires('format=json').then(function (_ref) {
        var list = _ref.list;
        return _this.quizQuestionnaires = list.map(function (item) {
          return {
            label: item.title,
            value: item.id
          };
        });
      });
    },
    addNewEmail: function addNewEmail() {
      if (!this.newEmail) return;
      this.newEmailError = '';
      if (isEmailValid(this.newEmail)) {
        this.$emit('add:newEmail', this.newEmail);
        this.newEmail = '';
      } else {
        this.newEmailError = this.translate.invalidEmailAddress;
      }
    }
  }
};