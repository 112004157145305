import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = {
  class: "mr-3"
};
var _hoisted_3 = {
  class: "level-of-threat-modal"
};
var _hoisted_4 = {
  class: "mb-3"
};
var _hoisted_5 = {
  class: "validation-error"
};
var _hoisted_6 = {
  key: 0,
  class: "validation-error"
};
var _hoisted_7 = {
  key: 1,
  class: "validation-error"
};
var _hoisted_8 = {
  key: 0,
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_options_list = _resolveComponent("options-list");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $props.activity.threatLevelsLabel,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString($data.translate.dropdownWithValues), 1 /* TEXT */), _createElementVNode("a", {
    href: "#",
    class: "flex",
    onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
      return $options.editLevel && $options.editLevel.apply($options, arguments);
    }, ["prevent"]))
  }, [_createVNode(_component_feather_icon, {
    class: "text-green-50 stroke-2 mr-1",
    icon: $options.featherIcon
  }, null, 8 /* PROPS */, ["icon"]), _createElementVNode("span", null, _toDisplayString($options.threatText), 1 /* TEXT */)])]), _createElementVNode("div", _hoisted_3, [$data.showModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $options.modalTitle,
    "show-buttons": $data.showButtons,
    onDismiss: _ctx.toggleModal
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("fieldset", null, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: _normalizeClass([{
          error: $data.labelNameValidation
        }, "input-field-container"])
      }, [_createVNode(_component_input_field, {
        title: $data.translate.title,
        "model-value": $data.labelName,
        placeholder: $data.translate.enterTitle,
        enabled: $options.isOpen || $options.isDraft,
        type: "text",
        "onUpdate:modelValue": _ctx.onChangeLabel
      }, null, 8 /* PROPS */, ["title", "model-value", "placeholder", "enabled", "onUpdate:modelValue"])], 2 /* CLASS */), _createElementVNode("span", _hoisted_5, _toDisplayString($data.labelNameValidation), 1 /* TEXT */)]), _createElementVNode("div", {
        class: _normalizeClass([{
          disabled: $data.isEditMode
        }, "mb-3"])
      }, [_createElementVNode("div", null, [_createVNode(_component_risma_title, {
        title: $data.translate.fieldType,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", null, [_createElementVNode("div", {
        class: _normalizeClass([{
          error: $data.selectOptionsValidation && !$data.selectOptions.length || $data.fieldTypeValidation
        }, "input-field-container"])
      }, [_createVNode(_component_single_select, {
        options: $data.fieldTypes,
        "model-value": $data.fieldType,
        disabled: $data.isEditMode,
        placeholder: $data.translate.chooseAFieldType
      }, null, 8 /* PROPS */, ["options", "model-value", "disabled", "placeholder"])], 2 /* CLASS */), !$data.selectOptions.length ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($data.selectOptionsValidation), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $data.fieldTypeValidation ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($data.fieldTypeValidation), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])], 2 /* CLASS */), $data.fieldType === $data.customFieldTypes.SELECT ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_risma_title, {
        title: $data.translate.dropdownOptions,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_options_list, {
        "predefined-options": $data.selectOptions,
        placeholder: $data.translate.enterDropdownOption,
        draggable: false,
        "show-empty-fields-error": true,
        "confirm-on-delete": $options.confirmOnDelete,
        "label-property": "title",
        class: "mb-3",
        onOnOptionsChange: $options.updateDropdownOption
      }, null, 8 /* PROPS */, ["predefined-options", "placeholder", "confirm-on-delete", "onOnOptionsChange"])])) : _createCommentVNode("v-if", true)]), _createVNode(_component_buttons_row, {
        class: "actions",
        data: $options.buttonsRowData
      }, null, 8 /* PROPS */, ["data"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "show-buttons", "onDismiss"])) : _createCommentVNode("v-if", true)])]);
}