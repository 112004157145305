function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useRiskProjectsStore } from '@/Store/riskProjectsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { MODULES } from '@/constants/modules';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
export default {
  name: 'PreviewDataImport',
  components: {
    TrafficLight: TrafficLight,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    RismaFileUpload: RismaFileUpload,
    SingleSelect: SingleSelect
  },
  props: {
    previewFields: {
      type: Object,
      required: true
    },
    previewData: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    routeModule: {
      type: String,
      required: true
    },
    fileTypesAllowed: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['loadData', 'error'],
  data: function data() {
    return {
      translate: getTranslate['PreviewDataImport'](),
      currentModule: null,
      modulesWithoutPreview: [MODULES.COMPLIANCE, MODULES.COMPLIANCE_GAP],
      files: null,
      additionalFieldValues: {},
      fieldTypes: {
        SINGLE_SELECT: 'singleSelect'
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useRiskProjectsStore, {
    allRiskProjects: 'projects'
  })), {}, {
    normalWhitespaceList: function normalWhitespaceList() {
      var initial = ['message', 'description', 'responsible', 'accountable', 'consulted', 'informed', 'organisations'];
      return [].concat(initial, _toConsumableArray(Object.keys(this.currentPreviewFields).filter(function (field) {
        return field.startsWith('c-');
      })));
    },
    showUploadForm: function showUploadForm() {
      var _this = this;
      return !this.currentModuleFields || this.currentModuleFields.every(function (field) {
        var _this$additionalField;
        return (_this$additionalField = _this.additionalFieldValues[_this.currentModule]) === null || _this$additionalField === void 0 ? void 0 : _this$additionalField[field.key];
      });
    },
    currentModuleFields: function currentModuleFields() {
      var _this$additionalField2;
      return ((_this$additionalField2 = this.additionalFieldsByModules) === null || _this$additionalField2 === void 0 ? void 0 : _this$additionalField2[this.currentModule]) || null;
    },
    additionalFieldsByModules: function additionalFieldsByModules() {
      return _defineProperty({}, MODULES.RISK, [{
        key: 'projectId',
        type: this.fieldTypes.SINGLE_SELECT,
        title: this.translate.selectProject,
        options: this.allRiskProjects.map(function (project) {
          return {
            value: project.id,
            label: project.title
          };
        })
      }]);
    },
    currentPreviewFields: function currentPreviewFields() {
      return this.previewFields[this.currentModule] || [];
    },
    currentPreviewData: function currentPreviewData() {
      return this.previewData[this.currentModule] || [];
    },
    importIsPossible: function importIsPossible() {
      return this.currentPreviewData.every(function (item) {
        return item.success;
      });
    },
    withoutPreview: function withoutPreview() {
      return this.modulesWithoutPreview.includes(this.currentModule);
    },
    modules: function modules() {
      return MODULES;
    },
    moduleOptions: function moduleOptions() {
      return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, MODULES.COMPLIANCE, {
        title: this.translate.complianceNodeImport,
        fileSource: '/risma2/examples/NodeImportExample.xlsx'
      }), MODULES.COMPLIANCE_GAP, {
        title: this.translate.gapAnalysisImport,
        fileSource: '',
        fileType: this.fileTypesAllowed.xls
      }), MODULES.EXECUTION, {
        title: this.translate.actionsImport,
        fileSource: '/risma2/examples/ActionsImportExample.xlsx'
      }), MODULES.CONTROLS, {
        title: this.translate.controlsImport,
        fileSource: '/risma2/examples/ControlsImportExample.xlsx'
      }), MODULES.CONTRACTS, {
        title: this.translate.contractImport,
        fileSource: '/risma2/examples/ContractImportExample.xlsx'
      }), MODULES.COMPLIANCE_INFORMATION_ASSETS, {
        title: this.translate.informationAssetsImport,
        fileSource: '/risma2/examples/AssetsImportExample.xlsx'
      }), MODULES.RISK, {
        title: this.translate.riskImport,
        fileSource: '/risma2/examples/RisksImportExample.xlsx'
      }), MODULES.FRAMEWORK, {
        title: this.translate.frameworkImport,
        fileSource: '/risma2/examples/FrameworkImportExample.xlsx'
      });
    }
  }),
  watch: {
    routeModule: function routeModule() {
      this.checkModuleExistence();
      this.$emit('loadData', null);
    }
  },
  created: function created() {
    this.checkModuleExistence();
  },
  methods: {
    onImportButtonClick: function onImportButtonClick() {
      if (!this.files) return;
      this.upload(this.files, true);
    },
    onFilesLoad: function onFilesLoad(files) {
      if (!files) return;
      this.files = files;
      this.upload(this.files);
    },
    upload: function upload(files) {
      var createData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var params = {
        createData: this.withoutPreview || createData
      };
      if (this.additionalFieldValues[this.currentModule]) {
        params = _objectSpread(_objectSpread({}, params), this.additionalFieldValues[this.currentModule]);
      }
      if (createData && !this.importIsPossible) {
        this.$emit('error', this.translate.pleaseCorrectTheErrorsInTheSubmittedImport);
        return;
      }
      this.$emit('loadData', {
        module: this.currentModule,
        files: files,
        params: params
      });
    },
    dropExistingFile: function dropExistingFile(e) {
      if (e.target.value) {
        e.target.value = null;
      }
    },
    checkModuleExistence: function checkModuleExistence() {
      if (this.routeModule in this.moduleOptions) {
        this.currentModule = this.routeModule;
      } else {
        this.$router.replace({
          name: 'PreviewDataImport',
          params: {
            module: MODULES.COMPLIANCE
          }
        });
      }
    },
    onAdditionalFieldChange: function onAdditionalFieldChange(fieldKey, value) {
      if (!this.additionalFieldValues[this.currentModule]) {
        this.additionalFieldValues[this.currentModule] = {};
      }
      this.additionalFieldValues[this.currentModule][fieldKey] = value;
    }
  }
};