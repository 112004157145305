import OptionsList from '@/components/Molecules/OptionsList/OptionsList';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
export default {
  name: '',
  components: {
    OptionsList: OptionsList,
    RismaButton: RismaButton
  },
  data: function data() {
    return {
      saveButtonClickCounter: 0,
      predefinedOptions: [{
        label: 'option 1',
        value: 1
      }, {
        label: 'option 2',
        value: 2
      }, {
        label: '',
        value: 3
      }, {
        label: 'option 2',
        value: 4
      }]
    };
  }
};