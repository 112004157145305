import { defineStore } from 'pinia';
import { getRiskProjects } from '@/api/risma/data';
export var useRiskProjectsStore = defineStore('riskProjectsStore', {
  state: function state() {
    return {
      projects: []
    };
  },
  actions: {
    load: function load() {
      var _this = this;
      return getRiskProjects().then(function (_ref) {
        var list = _ref.list;
        _this.projects = list;
      });
    },
    getProjectById: function getProjectById(projectId) {
      return this.projects.find(function (project) {
        return +project.id === +projectId;
      });
    },
    isErmByProjectId: function isErmByProjectId(projectId) {
      var _this$getProjectById;
      return ((_this$getProjectById = this.getProjectById(projectId)) === null || _this$getProjectById === void 0 ? void 0 : _this$getProjectById.isErm) || false;
    }
  }
});