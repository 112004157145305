import i18n from '@/i18n';
import { getTitlePlaceholder } from '@/utils/repetativeTranslations';
export var getTranslate = {
  AttachmentModal: function AttachmentModal() {
    return {
      addLink: i18n.t('Add link'),
      addAttachment: i18n.t('Add attachment'),
      cancel: i18n.t('Cancel'),
      apply: i18n.t('Apply'),
      pleaseFillLinkToFieldsCorrectly: i18n.t('Please fill Link to fields correctly'),
      title: i18n.t('Title'),
      titles: i18n.t('Titles'),
      type: i18n.t('Type'),
      chooseType: i18n.t('Choose type'),
      linkTo: i18n.t('Link to'),
      insertLink: i18n.t('Insert link'),
      insertLinkTitle: i18n.t('Insert link title'),
      whenYouPinADocumentItWillBeAddedToTheAt: i18n.t('When you pin a document it will be added to the attachment menu for quick access'),
      invalidFileTypePleaseUploadAnyOfTheseFile: i18n.t('Invalid file type. Please upload any of these file types'),
      uploadIsLimitedToOneFileAtATime: i18n.t('Upload is limited to one file at a time.'),
      linkHasntBeenAdded: i18n.t('A link has not been added'),
      fileHasntBeenUploaded: i18n.t('A file has not been uploaded')
    };
  },
  AttachmentVersionsModal: function AttachmentVersionsModal() {
    return {
      addNewVersion: i18n.t('Add new version'),
      fileVersionsFor: i18n.t('File versions for'),
      cancel: i18n.t('Cancel'),
      apply: i18n.t('Apply'),
      title: i18n.t('Title'),
      type: i18n.t('Type'),
      chooseType: i18n.t('Choose type'),
      fileName: i18n.t('File name'),
      uploadDate: i18n.t('Upload date'),
      uploaderName: i18n.t('Uploader name'),
      pleaseSelectAFile: i18n.t('Please select a file'),
      uploadIsLimitedToOneFileAtATime: i18n.t('Upload is limited to one file at a time.'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      invalidFileTypePleaseUploadAnyOfTheseFile: i18n.t('Invalid file type. Please upload any of these file types'),
      enterTitle: getTitlePlaceholder()
    };
  },
  CreateInitiativeModal: function CreateInitiativeModal() {
    return {
      createAction: i18n.t('Create action'),
      create: i18n.t('Create'),
      dismiss: i18n.t('Dismiss'),
      title: i18n.t('Title'),
      enterTitle: getTitlePlaceholder(),
      users: i18n.t('Users'),
      description: i18n.t('Description'),
      target: i18n.t('Target'),
      responsible: i18n.t('Responsible'),
      parent: i18n.t('Parent'),
      youNeedToFillTitleToContinue: i18n.t('You need to fill title to continue'),
      youNeedToFillResponsibleToContinue: i18n.t('You need to fill responsible to continue'),
      pleaseCreateAParentInitiativeInActionsAndT: i18n.t('Please create a parent initiative in actions and try again'),
      activityType: i18n.t('Activity type')
    };
  },
  DataflowModal: function DataflowModal() {
    return {
      addDataFlow: i18n.t('Add data flow'),
      add: i18n.t('Add'),
      cancel: i18n.t('Cancel'),
      pleaseSelectAStakeholder: i18n.t('Please select a stakeholder'),
      selectOne: i18n.t('Select one'),
      direction: i18n.t('Direction'),
      dataTypes: i18n.t('Data types'),
      select: i18n.t('Select'),
      comments: i18n.t('Comments'),
      areYouSure: i18n.t('Are you sure?'),
      ifYouDeleteThisDraftTheEnteredDataWillNo: i18n.t('If you delete this draft, the entered data will not be saved.'),
      receives: i18n.t('Receives'),
      delivers: i18n.t('Delivers')
    };
  },
  DpmAuditQuestionnaire: function DpmAuditQuestionnaire() {
    return {
      yourAnswersAreAutomaticallySavedAfterAFewS: i18n.t('Your answers are automatically saved after a few seconds. You can always return to the questionnaire and your data will be saved.'),
      youHaveBeenAskedToAnswerAQuestionnaireFor: i18n.t('You have been asked to answer a questionnaire for the following: '),
      thisLinkExpiresOnThe: i18n.t('This link expires on the '),
      whenYouAreFinishedFillingOutTheQuestionnair: i18n.t('When you are finished filling out the questionnaire, please click "Submit" in the top right corner in order to return it to sender.'),
      noTitleYet: i18n.t('No title yet'),
      submitQuestionnaire: i18n.t('Submit questionnaire'),
      submit: i18n.t('Submit'),
      questionnaireSubmitted: i18n.t('Questionnaire submitted'),
      areYouSureYouWatnToSubmitTheQuestionnaire: i18n.t('Are you sure you want to submit the questionnaire? Please note that you will no longer be able to answer the questionnaire after the questionnaire has been submitted.')
    };
  },
  LinksTab: function LinksTab() {
    return {
      areYouSureYouWantToRemoveThisLinked: i18n.t('Are you sure you want to remove this linked'),
      areYouSure: i18n.t('Are you sure?'),
      remove: i18n.t('Remove'),
      cancel: i18n.t('Cancel'),
      addLink: i18n.t('Add link'),
      youDontHaveAnyLinksToOrFromThisActivity: i18n.t("You don't have any links to or from this activity yet"),
      compliance: i18n.t('Compliance'),
      questionnaire: i18n.t('Questionnaire'),
      gapAnalysis: i18n.t('Gap analysis'),
      youDontHaveRightsToSee: i18n.t('You don’t have rights to see'),
      dataProcessor: i18n.t('Data processor'),
      notSet: i18n.t('Not set'),
      availableActivities: i18n.t('available activities'),
      hiddenActivities: i18n.t('hidden activities'),
      hiddenActivity: i18n.t('hidden activity')
    };
  },
  SendToJiraModal: function SendToJiraModal() {
    return {
      sendToJira: i18n.t('Send to Jira'),
      selectProject: i18n.t('Select project'),
      selectIssueType: i18n.t('Select issue type'),
      issueCreatedInJiraWithKey: i18n.t('Issue created in Jira with key: ')
    };
  },
  SignOffForm: function SignOffForm() {
    return {
      documentation: i18n.t('Documentation'),
      users: i18n.t('Users'),
      enterTheEmailAddressesOfThePersonsWhoHave: i18n.t('Enter the email addresses of the persons who have to sign-off. One email address per line.'),
      enterEmailAddress: i18n.t('Enter email address'),
      add: i18n.t('Add'),
      comments: i18n.t('Comments'),
      reminderComment: i18n.t('Reminder comment'),
      anyCommentsWillBeIncludedInTheEmailToThe: i18n.t('Any comments will be included in the email to the selected users'),
      dashboardTitle: i18n.t('Dashboard title'),
      title: i18n.t('Title'),
      maxLengthIs30Characters: i18n.t('Max length is 30 characters'),
      stateTheTitleOfTheDashboardThatWillBeCrea: i18n.t('State the title of the dashboard that will be created upon this action'),
      whenYouSendThisRequestToTheSelectedUsers: i18n.t('When you send this request to the selected users, the users will receive an e-mail with a link and your comment. The content of the node, from where this request has been created, will be presented as a read-only form, when they open the link in the e-mail. From there the users will be able to read all content of the node and declare that the content has been read.'),
      thisMessageWillOnlyBeSendToTheUsersWhoHa: i18n.t("This message will only be send to the users who haven't signed off on your previous request."),
      noteThatYouCanAddMoreRecipients: i18n.t('Note that you can add more recipients.'),
      theUsersWillBePresentedWithTheContentOf: i18n.t('The users will be presented with the content of'),
      invalidEmailAddress: i18n.t('Invalid e-mail address'),
      quizQuestionnaire: i18n.t('Quiz questionnaire'),
      selectQuizQuestionnaire: i18n.t('Select quiz questionnaire'),
      typeTheTitleOf: i18n.t('Type the title of the campaign'),
      whenYouSendThisRequestToTheSelectedUsersWithQuiz: i18n.t('When you send this request to the selected users, the users will receive an e-mail with a link and your comment. The content of the node, the description, attached and/or linked files. If you select a quiz, then this too wil be presented alongside all other content. Everything will be presented as a read-only form.')
    };
  },
  AwarenessReminderModal: function AwarenessReminderModal() {
    return {
      cancel: i18n.t('Cancel'),
      send: i18n.t('Send'),
      areYouSure: i18n.t('Are you sure?'),
      areYouSureYouWantToCloseItTheEnteredInf: i18n.t('Are you sure you want to close it? The entered information will not be saved'),
      sendReminder: i18n.t('Send reminder'),
      duplicateEmailAddress: i18n.t('Duplicate email address'),
      atLeastUserIsRequired: i18n.t('At least 1 user is required')
    };
  },
  UserRightsExpanded: function UserRightsExpanded() {
    return {
      back: i18n.t('Back'),
      mfaStatus: i18n.t('MFA status'),
      email: i18n.t('Email'),
      username: i18n.t('Username'),
      fullName: i18n.t('Full name'),
      initials: i18n.t('Initials'),
      rismaactions: i18n.t('RISMAactions'),
      rismarisk: i18n.t('RISMArisk'),
      rismacontrols: i18n.t('RISMAcontrols'),
      rismaincidents: i18n.t('RISMAincidents'),
      locked: i18n.t('Locked'),
      processLibrary: i18n.t('Process Library'),
      previousLogin: i18n.t('Previous login'),
      organisations: i18n.t('Organisations'),
      readonly: i18n.t(' (read-only)'),
      activity: i18n.t('Activity'),
      enabled: i18n.t('Enabled'),
      disabled: i18n.t('Disabled'),
      none: i18n.t('None'),
      active: i18n.t('Active'),
      inactive: i18n.t('Inactive'),
      contracts: i18n.t('Contracts'),
      companies: i18n.t('Companies')
    };
  },
  UserRightsTable: function UserRightsTable() {
    return {
      areYouSureYouWantToDeactivate: i18n.t('Are you sure you want to deactivate'),
      areYouSureYouWantToActivate: i18n.t('Are you sure you want to activate'),
      deactivateUser: i18n.t('Deactivate user'),
      activateUser: i18n.t('Activate user'),
      confirm: i18n.t('Confirm'),
      cancel: i18n.t('Cancel'),
      createUser: i18n.t('Create user'),
      sendEmailToAllUsers: i18n.t('Send E-mail to all users'),
      expandedView: i18n.t('Expanded view'),
      search: i18n.t('Search'),
      noRecordsMatching: i18n.t('No records matching'),
      username: i18n.t('Username'),
      fullName: i18n.t('Full name'),
      initials: i18n.t('Initials'),
      email: i18n.t('Email'),
      showDeactivatedUsers: i18n.t('Show deactivated users'),
      copiedEmailsToClipboard: i18n.t('Copied emails to clipboard'),
      warningYouCannotPerformThisOperation: i18n.t('Warning: You cannot perform this operation!'),
      user: i18n.t('User'),
      created: i18n.t('Created'),
      userAdministration: i18n.t('User administration'),
      users: i18n.t('Users'),
      close: i18n.t('Close')
    };
  },
  WizardFlowWrapper: function WizardFlowWrapper() {
    return {
      welcome: i18n.t('Welcome'),
      choose: i18n.t('Choose'),
      dataTypes: i18n.t('Data types'),
      chooseAllDataTypesThat: i18n.t('Choose all the data types that you will be storing'),
      chooseLegalBasisBehindDataTypes: i18n.t('Choose legal basis behind data types'),
      legalBasis: i18n.t('Legal basis'),
      chooseLegalBasis: i18n.t('Choose legal basis'),
      question: i18n.t('Question'),
      areYouSure: i18n.t('Are you sure?'),
      ifYouDeleteThisDraftTheEnteredDataWillNo: i18n.t('If you delete this draft, the entered data will not be saved.'),
      createDataFlow: i18n.t('Create data flow'),
      dataflowFor: i18n.t('Data flow for'),
      hasBeenCreated: i18n.t('has been created'),
      dataRetention: i18n.t('Storage period')
    };
  },
  HeaderNavBar: function HeaderNavBar() {
    return {
      my: i18n.t('My'),
      administration: i18n.t('Administration')
    };
  },
  QuickCreateIncidentModal: function QuickCreateIncidentModal() {
    return {
      createIncident: i18n.t('Create incident'),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      theFormHasMandatoryFields: i18n.t('The form has mandatory fields. These fields must be filled in before the incident can be created:'),
      title: i18n.t('Title'),
      enterTitle: i18n.t('Enter title'),
      activityType: i18n.t('Activity type'),
      choose: i18n.t('Choose'),
      description: i18n.t('Description'),
      incidentOccurred: i18n.t('Incident occurred'),
      severity: i18n.t('Severity'),
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      errorCreatingIncident: i18n.t('Error creating incident'),
      byCreatingThisIncident: function byCreatingThisIncident(users) {
        return i18n.t('By creating this incident, you and %s will automatically be assigned as responsible and a notification will be sent to both them and yourself.', [users]);
      }
    };
  },
  StatusTransition: function StatusTransition() {
    return {
      transitionTo: i18n.t('Transition to'),
      comment: i18n.t('Comment'),
      selectingNewResponsiblesForThisIncident: i18n.t('Selecting new responsible(s) for this incident will automatically remove yourself as responsible, when transitioning the incident to a new status. The added comment will be included in the e-mail to the new responsible(s)'),
      transition: i18n.t('Transition'),
      responsible: i18n.t('Responsible'),
      assignIncidentToANewResponsible: i18n.t('Assign incident to a new responsible'),
      transitionIncidentTo: i18n.t('Transition incident to'),
      viewWorkflow: i18n.t('View workflow'),
      cancel: i18n.t('Cancel')
    };
  }
};