function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useTrafficLights } from '@/Store/trafficLights';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import TrafficLightSelector from '@/components/Organisms/Selectors/TrafficLightSelector';
import RepeatableFields from '@/components/Molecules/RepeatableFields';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import StaticTable from '@/components/Molecules/StaticTable';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import HelpToolTip from '@/components/Molecules/HelpToolTip';
import InputFieldSelect from '@/components/Atoms/Inputs/InputFieldSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import CustomFieldTextBox from '@/components/Molecules/CustomFieldTextBox';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { TrendOptions } from '@/constants/TrendOptions';
import { CustomFieldsBaseMixin } from '@/components/Molecules/CustomFieldsBaseMixin';
import { DateFormats } from '@/constants/DateFormats';
import { TRAFFIC_LIGHT_MODULES } from '@/constants/modules';
import * as Utils from '@/utils/Utils';
import { userHasAccessByModule } from '@/utils/access';
import { setupSelectedValues as _setupSelectedValues } from '@/utils/CustomFields';
export default {
  name: 'ActivityCustomFields',
  introduction: 'Activity Custom Fields',
  description: 'Custom fields of an active activity type',
  token: '<activity-custom-fields/>',
  components: {
    SingleSelect: SingleSelect,
    InputField: InputField,
    TrafficLightSelector: TrafficLightSelector,
    TrafficLight: TrafficLight,
    RepeatableFields: RepeatableFields,
    StaticTable: StaticTable,
    DateTimePicker: DateTimePicker,
    HelpToolTip: HelpToolTip,
    InputFieldSelect: InputFieldSelect,
    RismaSelect: RismaSelect,
    CustomFieldTextBox: CustomFieldTextBox
  },
  mixins: [CustomFieldsBaseMixin],
  props: {
    activityType: {
      type: Object,
      required: true,
      note: 'Activity type'
    },
    customFieldValues: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Saved custom field values'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Are custom fields disabled?'
    },
    readonly: {
      type: Boolean,
      default: false,
      note: 'Are custom fields readonly (In Complete modal)?'
    },
    growingFields: {
      type: Boolean,
      default: false,
      note: 'Define if custom fields should be expandable when there is empty space'
    },
    dateOptions: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Options for date fields'
    },
    projectId: {
      type: [Number, String],
      required: false,
      default: null,
      note: 'project id for the current project'
    },
    ignoreVisibilityLevel: {
      type: Boolean,
      required: false,
      default: null
    },
    listOfDisabledFields: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of ids of disabled custom fields'
    },
    listOfHiddenFields: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of ids of hidden custom fields'
    }
  },
  data: function data() {
    return {
      CustomFieldTypes: CustomFieldTypes,
      filteredCustomFieldValues: [],
      allFields: [],
      currentTextarea: null,
      trendOptions: TrendOptions(),
      dateSaveFormat: DateFormats.DATE_TIME_FORMAT_FULL,
      translate: {
        pleaseFillOutThisField: this.$trans('Please fill out this field.'),
        searchForAnOption: this.$trans('Search for an option'),
        pleaseSelectTrafficLight: this.$trans('Please select a traffic light state.')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    filteredFields: function filteredFields() {
      var _this = this;
      if (!this.allFields) return [];
      return this.allFields.filter(function (field) {
        if (_this.listOfHiddenFields.includes(field.id)) return false;
        if (_this.ignoreVisibilityLevel || !field.visibilityUserLevel) return true;
        var isHasSavedValue = _this.readonly ? _this.hasSavedValue(field) : true;
        var isUserHasAccess = userHasAccessByModule(_this.currentUser, field.module, field.visibilityUserLevel, _this.projectId);
        return isHasSavedValue && isUserHasAccess;
      });
    }
  }),
  watch: {
    activityType: function activityType() {
      this.setupSelectedValues(this.activityType);
      this.filteredCustomFieldValues = this.getCustomFieldsFiltered(this.customFieldValues);
    },
    customFieldValues: {
      deep: true,
      handler: function handler() {
        this.filteredCustomFieldValues = this.getCustomFieldsFiltered(this.customFieldValues);
      }
    }
  },
  beforeMount: function beforeMount() {
    this.setupSelectedValues(this.activityType);
    this.filteredCustomFieldValues = this.getCustomFieldsFiltered(this.customFieldValues);
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightColorForModule'])), {}, {
    isFieldDisabled: function isFieldDisabled(fieldId) {
      return this.disabled || this.listOfDisabledFields.includes(fieldId);
    },
    setupSelectedValues: function setupSelectedValues(activityType) {
      if (activityType.fields === undefined) {
        return;
      }
      this.setupDefaultSelect(activityType.fields);
      this.allFields = _setupSelectedValues(this.allFields, this.customFieldValues);
    },
    setupDefaultSelect: function setupDefaultSelect(fields) {
      var result = [];
      fields.map(function (f) {
        var _defaultValueByType$f;
        var defaultValueByType = _defineProperty(_defineProperty({}, CustomFieldTypes.TRAFFIC_LIGHT, 0), CustomFieldTypes.SELECT_MULTI, []);
        result.push(_objectSpread(_objectSpread({}, f), {}, {
          selectedValue: (_defaultValueByType$f = defaultValueByType[f.typeId]) !== null && _defaultValueByType$f !== void 0 ? _defaultValueByType$f : ''
        }));
      });
      this.allFields = result;
    },
    getCustomFieldsFiltered: function getCustomFieldsFiltered(cfValues) {
      var result = [];
      if (!cfValues || !cfValues.length || this.activityType === undefined) {
        return result;
      }
      this.activityType.fields.map(function (cf) {
        var existingValue = cfValues.find(function (cfValue) {
          return cf.id === cfValue.fieldId;
        });
        if (existingValue) {
          result.push(existingValue);
        }
      });
      return result;
    },
    isSimpleComponent: function isSimpleComponent(field) {
      return field.typeId === CustomFieldTypes.TEXT_LINE || field.typeId === CustomFieldTypes.TEXT_FIELD || field.typeId === CustomFieldTypes.SELECT || field.typeId === CustomFieldTypes.TREND;
    },
    findTrafficColor: function findTrafficColor() {
      var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      return this.getTrafficLightColorForModule(TRAFFIC_LIGHT_MODULES.default, value, true);
    },
    prepareColumns: function prepareColumns(field) {
      var result = [];
      if (field.children) {
        field.children.map(function (child) {
          return result.push(child.label);
        });
      }
      return result;
    },
    prepareDataset: function prepareDataset(field) {
      var _this2 = this;
      var result = [];
      var record = {};
      this.filteredCustomFieldValues.map(function (customFieldValue) {
        if (customFieldValue.fieldId === field.id && customFieldValue.rows) {
          for (var rowNumber in customFieldValue.rows) {
            record = _this2.prepareRecord(field.children, customFieldValue.rows[rowNumber]);
            result.push(record);
          }
        }
      });
      return result;
    },
    prepareRecord: function prepareRecord(data, rowData) {
      var _this3 = this;
      var result = {};

      // Init labels
      data.map(function (field) {
        return result[field.label] = '';
      });
      // Populating exist values
      data.map(function (dataItem) {
        rowData.map(function (rowItem) {
          if (dataItem.id !== rowItem.fieldId) return;
          if (dataItem.typeId === CustomFieldTypes.SELECT_MULTI) {
            var options = Utils.getProperSelectedOptions(dataItem.options);
            var value = options.find(function (option) {
              return option.id === rowItem.value;
            }).value;
            if (result[dataItem.label]) {
              result[dataItem.label] += ', ' + value;
            } else {
              result[dataItem.label] = value;
            }
          } else if (dataItem.typeId === CustomFieldTypes.SELECT) {
            var _options = Utils.getProperSelectedOptions(dataItem.options);
            result[dataItem.label] = _options.find(function (option) {
              return option.id === rowItem.value;
            }).value;
          } else if (dataItem.typeId === CustomFieldTypes.TRAFFIC_LIGHT) {
            result[dataItem.label] = _this3.prepareTrafficComponent(rowItem.value);
          } else {
            result[dataItem.label] = rowItem.value;
          }
        });
      });
      return result;
    },
    prepareTrafficComponent: function prepareTrafficComponent(data) {
      var bgColor = this.findTrafficColor(data) || '';
      return "<span class=\"traffic-light\" style=\"background: ".concat(bgColor, "\"></span>");
    },
    hasSavedValue: function hasSavedValue(field) {
      var result = false;
      if (field.typeId === CustomFieldTypes.ROW_GROUP) {
        this.filteredCustomFieldValues.map(function (customFieldValue) {
          if (customFieldValue.fieldId === field.id && Object.keys(customFieldValue.rows).length) {
            result = true;
          }
        });
      } else {
        if (field.selectedValue) {
          result = true;
        }
      }
      return result;
    }
  })
};