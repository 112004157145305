import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex mt-2 mb-4"
};
var _hoisted_2 = {
  border: "1",
  class: "table"
};
var _hoisted_3 = {
  class: "border w-1/2"
};
var _hoisted_4 = {
  class: "border w-1/4"
};
var _hoisted_5 = {
  class: "border"
};
var _hoisted_6 = {
  class: "border"
};
var _hoisted_7 = {
  class: "border"
};
var _hoisted_8 = {
  class: "border"
};
var _hoisted_9 = {
  class: "border"
};
var _hoisted_10 = ["placeholder"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.emailSetup
      }, null, 8 /* PROPS */, ["title"]), $data.error ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.error), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        type: "medium",
        class: "mr-4",
        title: $data.translate.emailSendingEnabled
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_toggle, {
        "model-value": !$options.smtp_disable_delivery,
        "onUpdate:modelValue": $options.updateSettingsValue
      }, null, 8 /* PROPS */, ["model-value", "onUpdate:modelValue"])]), _cache[11] || (_cache[11] = _createElementVNode("b", null, "E-mail setting", -1 /* HOISTED */)), _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createElementVNode("table", _hoisted_2, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_3, _toDisplayString($data.translate.name), 1 /* TEXT */), _createElementVNode("th", _hoisted_4, _toDisplayString($data.translate.value), 1 /* TEXT */)])]), _createElementVNode("tbody", null, [_createElementVNode("tr", _hoisted_5, [_cache[5] || (_cache[5] = _createElementVNode("td", null, "smtp host", -1 /* HOISTED */)), _createElementVNode("td", null, _toDisplayString($options.smtp_host), 1 /* TEXT */)]), _createElementVNode("tr", _hoisted_6, [_cache[6] || (_cache[6] = _createElementVNode("td", null, "smtp port", -1 /* HOISTED */)), _createElementVNode("td", null, _toDisplayString($options.smtp_port), 1 /* TEXT */)]), _createElementVNode("tr", _hoisted_7, [_cache[7] || (_cache[7] = _createElementVNode("td", null, "smtp user", -1 /* HOISTED */)), _createElementVNode("td", null, _toDisplayString($options.smtp_user), 1 /* TEXT */)]), _cache[10] || (_cache[10] = _createElementVNode("tr", {
        class: "border"
      }, [_createElementVNode("td", null, "smtp pass"), _createElementVNode("td", null, "**** NOT SHOWN ****")], -1 /* HOISTED */)), _createElementVNode("tr", _hoisted_8, [_cache[8] || (_cache[8] = _createElementVNode("td", null, "smtp encryption (valid values: tls or ssl)", -1 /* HOISTED */)), _createElementVNode("td", null, _toDisplayString($options.smtp_encryption), 1 /* TEXT */)]), _createElementVNode("tr", _hoisted_9, [_cache[9] || (_cache[9] = _createElementVNode("td", null, "Override all emails to", -1 /* HOISTED */)), _createElementVNode("td", null, [_withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.recipientEmail = $event;
        }),
        type: "text",
        class: "w-full border focus:outline-none",
        placeholder: $data.translate.enterEmailAddress,
        onInput: _cache[1] || (_cache[1] = function () {
          return $options.updateEmail && $options.updateEmail.apply($options, arguments);
        }),
        onKeyup: _cache[2] || (_cache[2] = _withKeys(function ($event) {
          return $options.updateEmail($event, 0);
        }, ["enter"])),
        onBlur: _cache[3] || (_cache[3] = function ($event) {
          return $options.updateEmail($event, 0);
        })
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_10), [[_vModelText, $data.recipientEmail, void 0, {
        trim: true
      }]])])])])]), _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[15] || (_cache[15] = _createElementVNode("b", null, "Test E-mail setup", -1 /* HOISTED */)), _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $data.email = $event;
        }),
        name: "email",
        size: "75"
      }, null, 512 /* NEED_PATCH */), [[_vModelText, $data.email, void 0, {
        trim: true
      }]]), _createVNode(_component_risma_button, {
        text: $data.translate.sendTestEmail,
        type: "save",
        class: "mt-4",
        onClick: $options.sendTestEmail
      }, null, 8 /* PROPS */, ["text", "onClick"])];
    }),
    _: 1 /* STABLE */
  })]);
}