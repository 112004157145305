function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_mini_tree_branch_draggable = _resolveComponent("mini-tree-branch-draggable", true);
  var _component_mini_tree_branch = _resolveComponent("mini-tree-branch");
  var _component_draggable = _resolveComponent("draggable");
  return _openBlock(), _createBlock(_component_draggable, _mergeProps({
    class: "dragArea",
    tag: "ul",
    "item-key": "name",
    list: $props.list,
    handle: ".drag-icon",
    group: {
      name: 'treeBranch'
    }
  }, $data.dragOptions, {
    "force-fallback": true,
    move: $options.moveCallback,
    disabled: !$props.draggable,
    onEnd: $options.handleDragEnd
  }), {
    item: _withCtx(function (_ref) {
      var element = _ref.element,
        index = _ref.index;
      return [_createVNode(_component_mini_tree_branch, _mergeProps({
        item: element,
        path: [].concat(_toConsumableArray($props.path), [index]),
        "opened-array": $props.openedArray,
        "selected-path": $props.selectedPath,
        "search-enabled": $props.searchEnabled,
        "node-emits": $props.nodeEmits,
        "has-toggle-menu-prop": $props.hasToggleMenuProp,
        "parent-enabled": $props.parentEnabled
      }, _ctx.$attrs, {
        "onEdit:title": _cache[9] || (_cache[9] = function ($event) {
          return _ctx.$emit('edit:title', $event);
        }),
        "onEdit:enabled": _cache[10] || (_cache[10] = function ($event) {
          return _ctx.$emit('edit:enabled', $event);
        }),
        "onUpdate:selected": $options.emitItemSelection,
        "onUpdate:opened": _cache[11] || (_cache[11] = function ($event) {
          return _ctx.$emit('update:opened', $event);
        }),
        onAddSubNode: _cache[12] || (_cache[12] = function ($event) {
          return _ctx.$emit('addSubNode', $event);
        }),
        "onEdit:payingParty": _cache[13] || (_cache[13] = function ($event) {
          return _ctx.$emit('edit:payingParty', $event);
        }),
        "onEdit:signingParty": _cache[14] || (_cache[14] = function ($event) {
          return _ctx.$emit('edit:signingParty', $event);
        }),
        onDeleteNode: _cache[15] || (_cache[15] = function ($event) {
          return _ctx.$emit('deleteNode', $event);
        })
      }), {
        children: _withCtx(function () {
          return [_createVNode(_component_mini_tree_branch_draggable, _mergeProps({
            list: element.children,
            "opened-array": $props.openedArray,
            "selected-path": $props.selectedPath,
            "search-enabled": $props.searchEnabled,
            "has-toggle-menu-prop": $props.hasToggleMenuProp,
            "parent-enabled": element.enabled,
            "node-emits": $props.nodeEmits,
            path: [].concat(_toConsumableArray($props.path), [index]),
            draggable: $props.draggable
          }, _ctx.$attrs, {
            "onDrag:end": _cache[0] || (_cache[0] = function ($event) {
              return _ctx.$emit('drag:end', $event);
            }),
            "onDrop:showHint": _cache[1] || (_cache[1] = function ($event) {
              return _ctx.$emit('drop:showHint', $event);
            }),
            "onEdit:title": _cache[2] || (_cache[2] = function ($event) {
              return _ctx.$emit('edit:title', $event);
            }),
            "onEdit:enabled": _cache[3] || (_cache[3] = function ($event) {
              return _ctx.$emit('edit:enabled', $event);
            }),
            "onUpdate:selected": function onUpdateSelected($event) {
              return $options.onItemSelect($event, element, index);
            },
            "onUpdate:opened": _cache[4] || (_cache[4] = function ($event) {
              return _ctx.$emit('update:opened', $event);
            }),
            onAddSubNode: _cache[5] || (_cache[5] = function ($event) {
              return _ctx.$emit('addSubNode', $event);
            }),
            "onEdit:payingParty": _cache[6] || (_cache[6] = function ($event) {
              return _ctx.$emit('edit:payingParty', $event);
            }),
            "onEdit:signingParty": _cache[7] || (_cache[7] = function ($event) {
              return _ctx.$emit('edit:signingParty', $event);
            }),
            onDeleteNode: _cache[8] || (_cache[8] = function ($event) {
              return _ctx.$emit('deleteNode', $event);
            })
          }), null, 16 /* FULL_PROPS */, ["list", "opened-array", "selected-path", "search-enabled", "has-toggle-menu-prop", "parent-enabled", "node-emits", "path", "draggable", "onUpdate:selected"])];
        }),
        _: 2 /* DYNAMIC */
      }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["item", "path", "opened-array", "selected-path", "search-enabled", "node-emits", "has-toggle-menu-prop", "parent-enabled", "onUpdate:selected"])];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["list", "move", "disabled", "onEnd"]);
}