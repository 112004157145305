import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "sign-off-content"
};
var _hoisted_2 = {
  key: 0,
  class: "sign-off-box description"
};
var _hoisted_3 = {
  key: 1,
  class: "sign-off-box documentation"
};
var _hoisted_4 = {
  key: 0,
  class: "sign-off-box users-wrap"
};
var _hoisted_5 = {
  class: "users-block input-field-container"
};
var _hoisted_6 = {
  key: 1,
  class: "sign-off-box users-wrap"
};
var _hoisted_7 = {
  class: "overflow-y-auto w-fit max-h-140px mb-4"
};
var _hoisted_8 = {
  class: "w-fit mr-4"
};
var _hoisted_9 = {
  class: "mr-4"
};
var _hoisted_10 = {
  key: 0,
  class: "flex items-center w-96"
};
var _hoisted_11 = {
  class: "sign-off-box comments-wrap"
};
var _hoisted_12 = {
  class: "comments-block"
};
var _hoisted_13 = {
  class: "sign-off-box dashboard-wrap"
};
var _hoisted_14 = {
  class: "dashboard-title-wrap"
};
var _hoisted_15 = {
  key: 0,
  class: "additional-title"
};
var _hoisted_16 = {
  class: "dashboard-block input-field-container"
};
var _hoisted_17 = {
  class: "sign-off-box dashboard-wrap"
};
var _hoisted_18 = {
  class: "dashboard-title-wrap"
};
var _hoisted_19 = {
  key: 0,
  class: "additional-title"
};
var _hoisted_20 = {
  class: "dashboard-block input-field-container"
};
var _hoisted_21 = {
  class: "sign-off-box"
};
var _hoisted_22 = {
  key: 0,
  class: "sign-off-box users-wrap"
};
var _hoisted_23 = {
  class: "users-block input-field-container"
};
var _hoisted_24 = {
  key: 1,
  class: "sign-off-box users-wrap"
};
var _hoisted_25 = {
  class: "overflow-y-auto w-fit max-h-140px mb-4"
};
var _hoisted_26 = {
  class: "w-fit mr-4"
};
var _hoisted_27 = {
  class: "mr-4"
};
var _hoisted_28 = {
  class: "sign-off-box comments-wrap"
};
var _hoisted_29 = {
  class: "comments-block"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_text_box = _resolveComponent("text-box");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$props.readonly ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($options.descriptionText), 1 /* TEXT */)) : _createCommentVNode("v-if", true), !$options.isQuiz ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.documentation,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createTextVNode(" " + _toDisplayString($options.documentationDescription), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _createCommentVNode(" Looks like a template for quiz has totally different order, but they are much like the same (except singleselect for quiz and several titles ) "), !$options.isQuiz ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 2
  }, [$props.modalType === $data.signOffModalModes.CREATE ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_text_box, {
    text: $props.usersRow,
    disabled: $props.readonly,
    placeholder: $data.translate.enterTheEmailAddressesOfThePersonsWhoHave,
    label: $data.translate.users,
    error: $props.validationUsers,
    class: "w-full",
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('update:usersRow', $event);
    })
  }, null, 8 /* PROPS */, ["text", "disabled", "placeholder", "label", "error"])])])) : _createCommentVNode("v-if", true), $props.modalType === $data.signOffModalModes.DRAFT || $props.modalType === $data.signOffModalModes.REMIND ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_risma_title, {
    title: $data.translate.users,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.users, function (user, index) {
    return _openBlock(), _createElementBlock("div", {
      key: "sign-off-modal-sent-user-".concat(index),
      class: "flex items-center w-full justify-between"
    }, [_createElementVNode("span", _hoisted_9, _toDisplayString(user.email) + " " + _toDisplayString(user.fullname ? "(".concat(user.fullname, ")") : ''), 1 /* TEXT */), !$props.readonly && user.signoff === null ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      class: "cursor-pointer",
      icon: "x",
      onClick: function onClick($event) {
        return _ctx.$emit('remove:user', user);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))])]), !$props.readonly && $props.modalType !== $data.signOffModalModes.CREATE ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_input_field, {
    modelValue: $data.newEmail,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.newEmail = $event;
    }),
    placeholder: $data.translate.enterEmailAddress,
    invalid: !!($props.validationEmail || $data.newEmailError),
    "error-help-text": $props.validationEmail || $data.newEmailError || '',
    class: "w-full",
    type: "text",
    onOnEnter: $options.addNewEmail
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "invalid", "error-help-text", "onOnEnter"]), _createVNode(_component_risma_button, {
    text: $data.translate.add,
    type: "secondary",
    disabled: !$data.newEmail.length,
    onClick: $options.addNewEmail
  }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createVNode(_component_text_box, {
    text: $props.comments,
    enabled: !$props.readonly,
    placeholder: $data.translate.anyCommentsWillBeIncludedInTheEmailToThe,
    label: $props.modalType === $data.signOffModalModes.REMIND ? $data.translate.reminderComment : $data.translate.comments,
    class: "w-full",
    onUpdated: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('update:comments', $event);
    })
  }, null, 8 /* PROPS */, ["text", "enabled", "placeholder", "label"])])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createVNode(_component_risma_title, {
    title: $options.dashboardLabel,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), !$props.readonly ? (_openBlock(), _createElementBlock("span", _hoisted_15, " (" + _toDisplayString($data.translate.maxLengthIs30Characters) + ") ", 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_16, [_createVNode(_component_input_field, {
    "model-value": $props.dashboardTitle,
    placeholder: $options.titlePlaceholder,
    maxlength: 30,
    invalid: !!$props.validationDashboard,
    "error-help-text": $props.validationDashboard,
    enabled: !$props.isAwarenessSent && !$props.readonly,
    type: "text",
    onUpdated: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('update:dashboardTitle', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "placeholder", "invalid", "error-help-text", "enabled"])])])], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 3
  }, [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createVNode(_component_risma_title, {
    title: $options.dashboardLabel,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), !$props.readonly ? (_openBlock(), _createElementBlock("span", _hoisted_19, " (" + _toDisplayString($data.translate.maxLengthIs30Characters) + ") ", 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_20, [_createVNode(_component_input_field, {
    "model-value": $props.dashboardTitle,
    placeholder: $options.titlePlaceholder,
    maxlength: 30,
    invalid: !!$props.validationDashboard,
    "error-help-text": $props.validationDashboard,
    enabled: !$props.isAwarenessSent && !$props.readonly,
    type: "text",
    onUpdated: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('update:dashboardTitle', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "placeholder", "invalid", "error-help-text", "enabled"])])]), _createElementVNode("div", _hoisted_21, [_createVNode(_component_single_select, {
    "model-value": $props.quizQuestionnaireId,
    title: $data.translate.quizQuestionnaire,
    options: $data.quizQuestionnaires,
    placeholder: $data.translate.selectQuizQuestionnaire,
    disabled: $props.modalType !== $data.signOffModalModes.CREATE,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('update:quizQuestionnaireId', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "title", "options", "placeholder", "disabled"])]), $props.modalType === $data.signOffModalModes.CREATE ? (_openBlock(), _createElementBlock("div", _hoisted_22, [_createElementVNode("div", _hoisted_23, [_createVNode(_component_text_box, {
    text: $props.usersRow,
    disabled: $props.readonly,
    placeholder: $data.translate.enterTheEmailAddressesOfThePersonsWhoHave,
    label: $data.translate.users,
    error: $props.validationUsers,
    class: "w-full",
    onUpdated: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('update:usersRow', $event);
    })
  }, null, 8 /* PROPS */, ["text", "disabled", "placeholder", "label", "error"])])])) : _createCommentVNode("v-if", true), $props.modalType === $data.signOffModalModes.DRAFT || $props.modalType === $data.signOffModalModes.REMIND ? (_openBlock(), _createElementBlock("div", _hoisted_24, [_createVNode(_component_risma_title, {
    title: $data.translate.users,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_25, [_createElementVNode("div", _hoisted_26, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.users, function (user, index) {
    return _openBlock(), _createElementBlock("div", {
      key: "sign-off-modal-sent-user-".concat(index),
      class: "flex items-center w-full justify-between"
    }, [_createElementVNode("span", _hoisted_27, _toDisplayString(user.email) + " " + _toDisplayString(user.fullname ? "(".concat(user.fullname, ")") : ''), 1 /* TEXT */), !$props.readonly && user.signoff === null ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      class: "cursor-pointer",
      icon: "x",
      onClick: function onClick($event) {
        return _ctx.$emit('remove:user', user);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_28, [_createElementVNode("div", _hoisted_29, [_createVNode(_component_text_box, {
    text: $props.comments,
    disabled: $props.readonly,
    placeholder: $data.translate.anyCommentsWillBeIncludedInTheEmailToThe,
    label: $props.modalType === $data.signOffModalModes.REMIND ? $data.translate.reminderComment : $data.translate.comments,
    class: "w-full",
    onUpdated: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.$emit('update:comments', $event);
    })
  }, null, 8 /* PROPS */, ["text", "disabled", "placeholder", "label"])])])], 64 /* STABLE_FRAGMENT */))]);
}