import i18n from '@/i18n';
export var getTranslate = {
  AccessTable: function AccessTable() {
    return {
      access: i18n.t('Access'),
      none: i18n.t('None'),
      normal: i18n.t('Normal'),
      privil: i18n.t('Privil.'),
      super: i18n.t('Super'),
      locked: i18n.t('Locked'),
      clickHereToEditAccessToComplianceProjects: i18n.t('Click here to edit access to compliance projects'),
      admin: i18n.t('Admin')
    };
  },
  Changelog: function Changelog() {
    return {
      noChangeHistory: i18n.t('No change history.'),
      type: i18n.t('Type'),
      title: i18n.t('Title'),
      from: i18n.t('From'),
      to: i18n.t('To'),
      user: i18n.t('User'),
      date: i18n.t('Date'),
      created: i18n.t('Created'),
      closed: i18n.t('Closed'),
      open: i18n.t('Open'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      approved: i18n.t('Approved'),
      unapproved: i18n.t('Unapproved'),
      likelihood: i18n.t('Likelihood'),
      impact: i18n.t('Impact'),
      enabled: i18n.t('Enabled'),
      disabled: i18n.t('Disabled'),
      pinned: i18n.t('Pinned'),
      notPinned: i18n.t('Not pinned'),
      informationAssets: i18n.t('Information Assets'),
      custom: i18n.t('Custom'),
      deletedQuestion: i18n.t('Deleted question'),
      notSpecified: i18n.t('Not specified'),
      threatAssessments: i18n.t('Threat Assessments'),
      threat: i18n.t('Threat'),
      motherControlId: i18n.t('Mother Control ID'),
      trend: {
        1: i18n.t('Falling trend'),
        2: i18n.t('Neutral trend'),
        3: i18n.t('Rising trend')
      },
      include: i18n.t('Include'),
      exclude: i18n.t('Exclude')
    };
  },
  CustomFieldTypes: function CustomFieldTypes() {
    return {
      valueShouldBeUnique: i18n.t('Value should be unique'),
      fieldType: i18n.t('Field type'),
      chooseAFieldType: i18n.t('Choose a field type'),
      dropdownOptions: i18n.t('Dropdown options'),
      enterDropdownOption: i18n.t('Enter dropdown option'),
      unit: i18n.t('Unit'),
      chooseCustomFields: i18n.t('Choose custom fields'),
      chooseOrder: i18n.t('Choose order'),
      enterAnOption: i18n.t('Enter an option'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      deleteAnswerOption: i18n.t('Delete') + ' ' + i18n.t('Answer option').toLowerCase(),
      areYouSureYouWantToDeleteThisAnswerOption: i18n.t('Are you sure you want to delete this') + ' ' + i18n.t('Answer option').toLowerCase() + '?'
    };
  },
  DelegationModal: function DelegationModal() {
    return {
      apply: i18n.t('Apply'),
      cancel: i18n.t('Cancel'),
      createNew: i18n.t('Create new'),
      assignToMe: i18n.t('Assign to me')
    };
  },
  UserEditModal: function UserEditModal() {
    return {
      disableMfaForUser: i18n.t('Disable MFA for user'),
      dismiss: i18n.t('Dismiss'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      isAnInvalidEmailAddress: i18n.t(' is an invalid email address.'),
      username: i18n.t('Username'),
      initials: i18n.t('Initials'),
      password: i18n.t('Password'),
      email: i18n.t('Email'),
      organisation: i18n.t('Organisation'),
      sendWelcomeEmailToUser: i18n.t('Send welcome e-mail to user'),
      fullName: i18n.t('Full name'),
      updateUser: i18n.t('Update user'),
      update: i18n.t('Update'),
      createUser: i18n.t('Create user'),
      create: i18n.t('Create'),
      apiUser: i18n.t('API user'),
      toProtectAPIaccount: i18n.t('To protect API account, please change your password to something that is at least _amount_ characters long')
    };
  },
  UserRightsTableNew: function UserRightsTableNew() {
    return {
      noAccess: i18n.t('No access'),
      setAll: i18n.t('Set all'),
      normal: i18n.t('Normal'),
      privileged: i18n.t('Privileged'),
      super: i18n.t('Super'),
      admin: i18n.t('Admin'),
      locked: i18n.t('Locked'),
      supervisor: i18n.t('Supervisor'),
      unassignAsSupervisor: i18n.t('Unassign as Supervisor'),
      warningYouCannotPerformThisOperation: i18n.t('Warning: You cannot perform this operation!'),
      thisIsAnADUser: i18n.t('This is an Active Directory user. You can only set supervisor access on this user. All other rights must be set via AD security groups.')
    };
  },
  RepeatableFields: function RepeatableFields() {
    return {
      addRow: i18n.t('Add row'),
      areYouSure: i18n.t('Are you sure'),
      delete: i18n.t('Delete'),
      deleteRepeatableField: i18n.t('Delete') + ' ' + i18n.t('Repeatable field').toLowerCase(),
      areYouSureYouWantToDeleteThisRepeatableField: "".concat(i18n.t('Are you sure you want to delete this'), " ").concat(i18n.t('Repeatable field').toLowerCase(), "?")
    };
  },
  TinyMceBox: function TinyMceBox() {
    return {
      edit: i18n.t('Edit'),
      cancel: i18n.t('Cancel'),
      save: i18n.t('Save'),
      clickToShowAll: i18n.t('Click to show all'),
      clickToShowLess: i18n.t('Click to show less'),
      addComment: i18n.t('Add comment')
    };
  },
  WizardFlow: function WizardFlow() {
    return {
      create: i18n.t('Create'),
      previous: i18n.t('Previous'),
      next: i18n.t('Next'),
      createDataFlow: i18n.t('Create data flow'),
      cancel: i18n.t('Cancel'),
      dontShowThisMessageAgain: i18n.t('Don’t show this message again'),
      areYouSureYouWant: i18n.t('Are you sure you want to proceed with creating this data flow?')
    };
  },
  RequiredFieldsNotification: function RequiredFieldsNotification() {
    return {
      formHasRequiredFields: i18n.t('Form has required fields.'),
      followingFieldsAreMissingValues: i18n.t('Following fields are missing values:')
    };
  }
};