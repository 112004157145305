var _excluded = ["query", "filterType"];
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useRoute } from 'vue-router';
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
import * as API from '@/api/compliance/reports/nodes';
import { getInformationAssetsSystems, getAllTrees as _getAllTrees, getNodesByProcessTreeId as _getNodesByProcessTreeId, getRisks as _getRisks, getControls as _getControls, getInitiatives as _getInitiatives, getInformationAssetsProcessors, getInformationAssetsControllers, getCustomInformationAssets as _getCustomInformationAssets, getContracts as _getContracts } from '@/api/risma/data';
import { getDataflowReport } from '@/api/compliance/reports/dataflows';
import { getAll as _getIncidents } from '@/api/incident/incident';
import { getRiskProjects } from '@/api/risk/risk';
import { logReport as _logReport } from '@/api/reports/reports';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import HierarchicalList from '@/components/Molecules/HierarchicalList';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import ViewSwitcher from '@/components/Molecules/ViewSwitcher';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import { ReportSearchForm, NoSearchResults } from '../index';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { filterAsset } from '@/utils/format/objectsFromIds';
import { mapToCommaListWithLinks, mapToCommaList } from '@/utils/format/mapTo';
import { getHierarchicalList, buildHierarchicalStructure } from '@/utils/format/hierarchicalList';
import { MODULES } from '@/constants/modules';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { LiaTiaType } from '@/constants/compliance/LiaTiaGeneric';
import * as Utils from '@/utils/Utils';
import { ReportTypes } from '@/constants/reports';
import { DateFormats } from '@/constants/DateFormats';
import { viewTypes } from '@/constants/ListViewType';
import { NODES_REPORT_VIEWS } from '@/constants/ViewTypes';
import * as formatDate from '@/utils/date';
import { getProjectDescriptionLabel, getProjectPurposeLabel, getProjectStatusLabel } from '@/utils/Compliance/complianceProject';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'ReportNodes',
  introduction: 'A wrapper for the compliance node report',
  description: 'A wrapper for the compliance report to handle search in Nodes. This will display a input-field along with a report-search-form-component',
  token: '<report-nodes />',
  components: {
    ReportSearchForm: ReportSearchForm,
    NoSearchResults: NoSearchResults,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    InputField: InputField,
    RismaDatatable: RismaDatatable,
    PreviewModalLink: PreviewModalLink,
    HierarchicalList: HierarchicalList,
    TrafficLight: TrafficLight,
    SaveReport: SaveReport,
    RismaTitleWithIcon: RismaTitleWithIcon,
    ViewSwitcher: ViewSwitcher,
    RismaTitle: RismaTitle,
    RismaTable: RismaTable
  },
  mixins: [RaciTitleMixin, RismaDatatableDefaultMixin, SaveReportMixin],
  props: {
    projectId: {
      type: [Number, String],
      required: true,
      note: 'The Project ID which is passed by VueRouter'
    },
    savedReportOptions: {
      type: Object,
      default: null
    }
  },
  setup: function setup(props) {
    var _route$query, _JSON$parse;
    var localStorageKey = "node_report_filters_".concat(props.projectId);
    var route = useRoute();
    var query = (_route$query = route.query) === null || _route$query === void 0 ? void 0 : _route$query.query;
    var type = query ? (_JSON$parse = JSON.parse(query)) === null || _JSON$parse === void 0 || (_JSON$parse = _JSON$parse.query) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.type : null;
    var typeToView = _defineProperty(_defineProperty({}, ReportTypes.COMPLIANCE_NODE, viewTypes.COMPACT), ReportTypes.COMPLIANCE_NODE_THIN, viewTypes.RISMA_TABLE);
    var viewSelected = getLocalStorageComputed('compliance_nodes_report_view', viewTypes.RISMA_TABLE);
    if (type) viewSelected.value = typeToView[type];
    return {
      searchArgs: getLocalStorageComputed(localStorageKey, {}, true),
      viewSelected: viewSelected
    };
  },
  data: function data() {
    return {
      reportQuery: '',
      loading: false,
      controllers: [],
      dataset: [],
      noResults: false,
      updatedNode: null,
      queryInput: '',
      systems: [],
      actions: [],
      contracts: [],
      controls: [],
      informationAssets: [],
      processors: [],
      incidents: [],
      processNodes: [],
      risks: [],
      dataflowReport: [],
      decoupleFilterFields: [{
        key: 'responsible'
      }, {
        key: 'accountable'
      }, {
        key: 'consulted'
      }, {
        key: 'informed'
      }, {
        key: 'controllers'
      }, {
        key: 'organisations'
      }, {
        key: 'systems'
      }, {
        key: 'frameworks'
      }],
      disabledFilterByColumns: ['process', 'attachedFiles', 'questionnaireAnsweredPercentage', 'description', 'purpose', 'processStatus', 'governanceReview', 'governanceReviewLastCompleted', 'nextGovernanceReviewDate'],
      customFields: [],
      columnSorting: [{
        key: 'questionnaireAnsweredPercentage',
        sortingFunc: this.sortNumerically
      }],
      liaTiaNodes: [],
      allNodes: [],
      ProjectTypes: ProjectTypes,
      stateLocal: "compliance_nodes_search_report_".concat(this.projectId),
      processTreeId: 0,
      scoringIncludedInDatatable: false,
      cfColumns: {},
      customFieldsById: {},
      serverError: null,
      reportType: ReportTypes.COMPLIANCE_NODE,
      initialFilters: {
        scoringIncluded: true
      },
      viewTypes: viewTypes,
      NODES_REPORT_VIEWS: NODES_REPORT_VIEWS,
      stateLocalThin: 'nodes-report-risma-table-key',
      reportTypeThin: ReportTypes.COMPLIANCE_NODE_THIN,
      translate: getTranslate['ReportNodes']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useActivityTypesStore, {
    activityTypes: 'complianceActivityTypes',
    customIA: 'complianceIACustomActivityTypes'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useTagsStore, ['tags'])), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), {}, {
    currentLocation: function currentLocation() {
      return [this.currentProject.title, 'Reports', 'Nodes'];
    },
    rpoFieldId: function rpoFieldId() {
      return this.getSettingValue('feature.isms_rpo_field_id');
    },
    rtoFieldId: function rtoFieldId() {
      return this.getSettingValue('feature.isms_rto_field_id');
    },
    columns: function columns() {
      var columns = this.getSettingValue('feature.show_node_id') ? {
        id: this.translate.id
      } : {};
      columns = _objectSpread(_objectSpread({}, columns), {}, {
        process: this.translate.process,
        description: getProjectDescriptionLabel(this.currentProject),
        purpose: getProjectPurposeLabel(this.currentProject),
        processStatus: getProjectStatusLabel(this.currentProject),
        questionnaire: this.translate.questionnaire,
        questionnaireAnsweredPercentage: this.translate.completionDegreeIn,
        gap_schema: this.translate.gapSchemaName,
        activityType: this.translate.activityType,
        responsible: this.responsibleTitle(true),
        accountable: this.accountableTitle(true),
        consulted: this.consultedTitle(true),
        informed: this.informedTitle(true),
        controllers: this.translate.dataControllers,
        organisations: this.translate.organisations,
        systems: this.translate.systems,
        actions: this.translate.actions,
        contracts: this.translate.contracts,
        controls: this.translate.controls,
        processors: this.translate.processors,
        incidents: this.translate.incidents,
        processNodes: this.translate.processLibrary,
        risks: this.translate.risks,
        dataflow: this.translate.dataFlow,
        attachedFiles: this.translate.attachedFiles,
        tags: this.translate.tags,
        frameworks: this.translate.framework,
        governanceReview: this.translate.governanceReview,
        governanceReviewLastCompleted: this.translate.governanceReviewLastCompleted,
        nextGovernanceReviewDate: this.translate.nextGovernanceReviewDate
      });
      if (this.scoringIncludedInDatatable) {
        columns.score = this.translate.score;
        columns.scoreHelp = this.translate.assessmentScoring;
      }
      if (this.getSettingValue('feature.risk_assessment_on_systems')) {
        columns.rto = this.translate.rto;
        columns.rpo = this.translate.rpo;
      }
      columns = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, columns), this.customInformationAssetsColumns), this.risksColumns), this.cfColumns);
      return columns;
    },
    customInformationAssetsColumns: function customInformationAssetsColumns() {
      var informationAssetsColumns = {};
      this.informationAssets.forEach(function (ia) {
        informationAssetsColumns["".concat(MODULES.COMPLIANCE_INFORMATION_ASSETS, "-").concat(ia.id)] = ia.label;
      });
      return informationAssetsColumns;
    },
    risksColumns: function risksColumns() {
      var columns = {};
      this.risks.forEach(function (project) {
        columns["".concat(MODULES.RISK, "-").concat(project.id)] = project.label;
      });
      return columns;
    },
    showActivityType: function showActivityType() {
      return this.getSettingValue('feature.compliance_activity_types');
    },
    linkedColumnsForPreview: function linkedColumnsForPreview() {
      return ['systems', 'controllers', 'actions', 'contracts', 'controls', 'processors', 'incidents', 'processNodes', 'risks', 'governanceReview'].concat(_toConsumableArray(Object.keys(this.customInformationAssetsColumns)), _toConsumableArray(Object.keys(this.risksColumns)));
    },
    columnsMeta: function columnsMeta() {
      return [{
        targets: ['process', 'organisations', 'attachments'],
        width: '300px'
      }, {
        targets: ['questionnaire', 'gap_schema'],
        width: '250px'
      }, {
        targets: ['questionnaireAnsweredPercentage', 'responsible', 'accountable', 'consulted', 'informed'],
        width: '180px'
      }, {
        targets: ['description'],
        width: '340px'
      }, {
        targets: ['actions', 'contracts', 'controls', 'informationAssets', 'processors', 'incidents', 'processNodes', 'systems', 'risks', 'governanceReview'].concat(_toConsumableArray(Object.keys(this.customInformationAssetsColumns)), _toConsumableArray(Object.keys(this.risksColumns))),
        width: '340px',
        visible: false
      }];
    },
    isCompactViewActive: function isCompactViewActive() {
      return this.dataset.length > 0 && !this.loading && this.viewSelected === this.viewTypes.COMPACT;
    },
    currentProject: function currentProject() {
      return this.complianceProjectById(this.projectId);
    }
  }),
  watch: {
    savedReportOptions: function savedReportOptions() {
      if (this.savedReportOptions) {
        this.setAndLoadSavedReport(this.savedReportOptions);
      }
    },
    viewSelected: function viewSelected() {
      this.$router.replace({
        query: null
      });
    }
  },
  mounted: function mounted() {
    if (!this.showActivityType) {
      delete this.columns.activityType;
    }
    if (this.viewSelected === this.viewTypes.COMPACT) {
      this.setInitialState();
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useComplianceProjectsStore, {
    complianceProjectById: 'getProjectById'
  })), {}, {
    getData: function getData() {
      return getDataflowReport(this.projectId).then(this.setDataFlowReport);
    },
    getNodesThinReport: function getNodesThinReport(params) {
      return API.searchReports(this.projectId, "?".concat(params));
    },
    getProcessNodes: function getProcessNodes() {
      return this.getAllTrees().then(this.getNodesByProcessTreeId);
    },
    getAllTrees: function getAllTrees() {
      var _this = this;
      return _getAllTrees().then(function (response) {
        if (response[0].id !== null) {
          _this.processTreeId = response[0].id;
        }
      });
    },
    getNodesByProcessTreeId: function getNodesByProcessTreeId() {
      var _this2 = this;
      return _getNodesByProcessTreeId(this.processTreeId).then(function (rootNode) {
        var processLibraryNodes = [];
        Utils.pushChildrenToParent({
          children: rootNode.children
        }, processLibraryNodes);
        _this2.processNodes = processLibraryNodes;
      });
    },
    getCustomInformationAssets: function getCustomInformationAssets() {
      var _this3 = this;
      return _getCustomInformationAssets().then(function (_ref) {
        var customIA = _ref.list;
        _this3.informationAssets = _this3.splitActivitiesByType(_this3.customIA, customIA, 'label', 'activityTypeId');
      });
    },
    getRisks: function getRisks() {
      var _this4 = this;
      return Promise.all([getRiskProjects(), _getRisks()]).then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
          riskProjects = _ref3[0].list,
          risks = _ref3[1].list;
        _this4.risks = _this4.splitActivitiesByType(riskProjects, risks, 'title', 'projectId');
      });
    },
    getControls: function getControls() {
      var _this5 = this;
      return _getControls().then(function (_ref4) {
        var list = _ref4.list;
        return _this5.controls = list || [];
      });
    },
    getContracts: function getContracts() {
      var _this6 = this;
      return _getContracts().then(function (_ref5) {
        var list = _ref5.list;
        return _this6.contracts = list || [];
      });
    },
    getIncidents: function getIncidents() {
      var _this7 = this;
      return _getIncidents().then(function (list) {
        return _this7.incidents = list || [];
      });
    },
    getInitiatives: function getInitiatives() {
      var _this8 = this;
      return _getInitiatives().then(function (_ref6) {
        var list = _ref6.list;
        return _this8.actions = list || [];
      });
    },
    getProcessors: function getProcessors() {
      var _this9 = this;
      return getInformationAssetsProcessors().then(function (_ref7) {
        var list = _ref7.list;
        return _this9.processors = list || [];
      });
    },
    getControllers: function getControllers() {
      var _this10 = this;
      return getInformationAssetsControllers().then(function (_ref8) {
        var list = _ref8.list;
        return _this10.controllers = list || [];
      });
    },
    getSystems: function getSystems() {
      var _this11 = this;
      return getInformationAssetsSystems().then(function (_ref9) {
        var list = _ref9.list;
        return _this11.systems = list || [];
      });
    },
    splitActivitiesByType: function splitActivitiesByType(types, activities, labelName, typeIdName) {
      var result = [];
      types.forEach(function (type) {
        var filteredActivities = activities.filter(function (a) {
          return +type.id === +a[typeIdName];
        });
        result.push({
          id: type.id,
          label: type[labelName],
          activities: filteredActivities
        });
      });
      return result;
    },
    setInitialState: function setInitialState() {
      if (this.savedReportOptions) {
        this.setAndLoadSavedReport(this.savedReportOptions);
      } else {
        this.initializeFiltersAndQueryInput(this.searchArgs);
      }
    },
    handleDoSearch: function handleDoSearch(_ref10) {
      var _this12 = this;
      var _ref10$organisations = _ref10.organisations,
        organisations = _ref10$organisations === void 0 ? [] : _ref10$organisations,
        _ref10$users = _ref10.users,
        users = _ref10$users === void 0 ? [] : _ref10$users,
        _ref10$scoringInclude = _ref10.scoringIncluded,
        scoringIncluded = _ref10$scoringInclude === void 0 ? true : _ref10$scoringInclude,
        _ref10$filterType = _ref10.filterType,
        filterType = _ref10$filterType === void 0 ? 0 : _ref10$filterType,
        _ref10$tags = _ref10.tags,
        tags = _ref10$tags === void 0 ? [] : _ref10$tags;
      var resetQuery = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.loading = true;
      this.noResults = false;
      this.serverError = undefined;
      this.scoringIncludedInDatatable = scoringIncluded;
      this.searchArgs = {
        organisations: organisations,
        users: users,
        tags: tags,
        query: this.queryInput,
        filterType: filterType,
        scoringIncluded: scoringIncluded
      };
      this.reportQuery = this.buildQuery(this.searchArgs);
      resetQuery && this.resetSavedReportQuery();
      var promises = [];
      promises.push(API.searchNodes(this.projectId, this.reportQuery, scoringIncluded).then(function (response) {
        _this12.allNodes = response.list.filter(function (node) {
          return !(node.rootNode && node.rootNode.id === node.id);
        });
      }).then(this.loadAdditionalData));
      if (filterType > 0 && +this.currentProject.type === ProjectTypes.GDPR) {
        promises.push(API.searchReports(this.projectId, "?filters[".concat(LiaTiaType[filterType].key, "Nodes]=1")).then(function (_ref11) {
          var list = _ref11.list;
          return _this12.liaTiaNodes = list;
        }));
      }
      promises.push(this.getData());
      return Promise.all(promises).then(function () {
        return _this12.generateDataset(_this12.allNodes, filterType);
      }).then(function () {
        _this12.noResults = _this12.dataset.length === 0;
      }).catch(function (error) {
        var _error$response;
        _this12.dataset = [];
        _this12.noResults = true;
        _this12.serverError = error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error;
        throw error;
      }).finally(function () {
        _this12.loading = false;
        _this12.dataset.length && _this12.viewSelected === viewTypes.COMPACT && _this12.loadSavedReport();
      });
    },
    logReport: function logReport(count) {
      var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var searchArgs = this.reportQuery.length || this.scoringIncludedInDatatable ? this.searchArgs : {};
      return _logReport(MODULES.COMPLIANCE, count, this.currentLocation, searchArgs, format);
    },
    loadAdditionalData: function loadAdditionalData() {
      var _this13 = this;
      if (!this.allNodes.length) return Promise.resolve();
      var promises = {};
      this.allNodes.forEach(function (node) {
        var _node$links$MODULES$I, _node$links$MODULES$R, _node$links$MODULES$C, _node$links$MODULES$R2, _node$links$MODULES$C2, _node$links$MODULES$E, _node$links$MODULES$C3, _node$links$MODULES$C4, _node$links$MODULES$C5, _node$links$dataproce, _node$links$MODULES$C6;
        if (node.links && (_node$links$MODULES$I = node.links[MODULES.INCIDENTS]) !== null && _node$links$MODULES$I !== void 0 && _node$links$MODULES$I.length && !promises[MODULES.INCIDENTS] && !_this13.incidents.length) {
          promises[MODULES.INCIDENTS] = _this13.getIncidents();
        }
        if (node.links && (_node$links$MODULES$R = node.links[MODULES.RISK]) !== null && _node$links$MODULES$R !== void 0 && _node$links$MODULES$R.length && !promises[MODULES.RISK] && !_this13.risks.length) {
          promises[MODULES.RISK] = _this13.getRisks();
        }
        if (node.links && ((_node$links$MODULES$C = node.links[MODULES.CONTROLS]) !== null && _node$links$MODULES$C !== void 0 && _node$links$MODULES$C.length || (_node$links$MODULES$R2 = node.links[MODULES.REVIEW]) !== null && _node$links$MODULES$R2 !== void 0 && _node$links$MODULES$R2.length) && !promises[MODULES.CONTROLS] && !_this13.controls.length) {
          promises[MODULES.CONTROLS] = _this13.getControls();
        }
        if (node.links && (_node$links$MODULES$C2 = node.links[MODULES.CONTRACTS]) !== null && _node$links$MODULES$C2 !== void 0 && _node$links$MODULES$C2.length && !promises[MODULES.CONTRACTS] && !_this13.contracts.length) {
          promises[MODULES.CONTRACTS] = _this13.getContracts();
        }
        if (node.links && (_node$links$MODULES$E = node.links[MODULES.EXECUTION]) !== null && _node$links$MODULES$E !== void 0 && _node$links$MODULES$E.length && !promises[MODULES.EXECUTION] && !_this13.actions.length) {
          promises[MODULES.EXECUTION] = _this13.getInitiatives();
        }
        if (node.links && (_node$links$MODULES$C3 = node.links[MODULES.COMPLIANCE_INFORMATION_ASSETS]) !== null && _node$links$MODULES$C3 !== void 0 && _node$links$MODULES$C3.length && !promises[MODULES.COMPLIANCE_INFORMATION_ASSETS] && !_this13.informationAssets.length) {
          promises[MODULES.COMPLIANCE_INFORMATION_ASSETS] = _this13.getCustomInformationAssets();
        }
        if (node.links && (_node$links$MODULES$C4 = node.links[MODULES.COMPLIANCE_IA_PROCESSOR]) !== null && _node$links$MODULES$C4 !== void 0 && _node$links$MODULES$C4.length && !promises[MODULES.COMPLIANCE_IA_PROCESSOR] && !_this13.processors.length) {
          promises[MODULES.COMPLIANCE_IA_PROCESSOR] = _this13.getProcessors();
        }
        if (node.links && (_node$links$MODULES$C5 = node.links[MODULES.COMPLIANCE_IA_CONTROLLER]) !== null && _node$links$MODULES$C5 !== void 0 && _node$links$MODULES$C5.length && !promises[MODULES.COMPLIANCE_IA_CONTROLLER] && !_this13.controllers.length) {
          promises[MODULES.COMPLIANCE_IA_CONTROLLER] = _this13.getControllers();
        }
        if (node.links && (_node$links$dataproce = node.links.dataprocessor_system) !== null && _node$links$dataproce !== void 0 && _node$links$dataproce.length && !promises.dataprocessor_system && !_this13.systems.length) {
          promises.dataprocessor_system = _this13.getSystems();
        }
        if (node.links && (_node$links$MODULES$C6 = node.links[MODULES.COMPLIANCE_PROCESS_TREE_NODE]) !== null && _node$links$MODULES$C6 !== void 0 && _node$links$MODULES$C6.length && !promises[MODULES.COMPLIANCE_PROCESS_TREE_NODE] && !_this13.processNodes.length) {
          promises[MODULES.COMPLIANCE_PROCESS_TREE_NODE] = _this13.getProcessNodes();
        }
      });
      return Promise.all(Object.values(promises));
    },
    handleMapResults: function handleMapResults(nodes) {
      var _this14 = this;
      var rows = [];
      nodes.forEach(function (node) {
        var _node$links, _node$questionnaire, _node$gap_schema, _governanceReview, _governanceReview2, _governanceReview3, _governanceReview4;
        var organisations = [],
          responsibleUsers = [],
          accountableUsers = [],
          consultedUsers = [],
          informedUsers = [],
          controllers = [],
          systems = [],
          actions = [],
          contracts = [],
          controls = [],
          informationAssets = {},
          processors = [],
          incidents = [],
          processNodes = [],
          risks = [],
          tags = filterAsset(_this14.tags, node.tagIds),
          governanceReviewControls = [],
          governanceReview;
        if (node.organisationIds) {
          organisations = filterAsset(_this14.organisations, node.organisationIds);
        }
        if (node.responsibleUserIds) {
          responsibleUsers = filterAsset(_this14.users, node.responsibleUserIds);
        }
        if (node.accountableUserIds) {
          accountableUsers = filterAsset(_this14.users, node.accountableUserIds);
        }
        if (node.consultedUserIds) {
          consultedUsers = filterAsset(_this14.users, node.consultedUserIds);
        }
        if (node.informedUserIds) {
          informedUsers = filterAsset(_this14.users, node.informedUserIds);
        }
        if (node.links && node.links[MODULES.COMPLIANCE_IA_CONTROLLER]) {
          controllers = filterAsset(_this14.controllers, node.links[MODULES.COMPLIANCE_IA_CONTROLLER]);
        }
        if (node.links && node.links.dataprocessor_system) {
          systems = filterAsset(_this14.systems, node.links.dataprocessor_system);
        }
        if (node.links && node.links[MODULES.EXECUTION]) {
          actions = filterAsset(_this14.actions, node.links[MODULES.EXECUTION]);
        }
        if (node.links && node.links[MODULES.CONTRACTS]) {
          contracts = filterAsset(_this14.contracts, node.links[MODULES.CONTRACTS]);
        }
        if (node.links && node.links[MODULES.CONTROLS]) {
          controls = filterAsset(_this14.controls, node.links[MODULES.CONTROLS]);
        }
        informationAssets = _this14.getFormattedData(_this14.informationAssets, node.links || {}, node.id, MODULES.COMPLIANCE_INFORMATION_ASSETS);
        if (node.links && node.links[MODULES.COMPLIANCE_IA_PROCESSOR]) {
          processors = filterAsset(_this14.processors, node.links[MODULES.COMPLIANCE_IA_PROCESSOR]);
        }
        if (node.links && node.links[MODULES.INCIDENTS]) {
          incidents = filterAsset(_this14.incidents, node.links[MODULES.INCIDENTS]);
        }
        if (node.links && node.links[MODULES.COMPLIANCE_PROCESS_TREE_NODE]) {
          processNodes = filterAsset(_this14.processNodes, node.links[MODULES.COMPLIANCE_PROCESS_TREE_NODE]);
        }
        risks = _this14.getFormattedData(_this14.risks, node.links || {}, node.id, MODULES.RISK);
        if ((_node$links = node.links) !== null && _node$links !== void 0 && _node$links[MODULES.REVIEW]) {
          governanceReviewControls = filterAsset(_this14.controls, node.links[MODULES.REVIEW]);
          governanceReview = governanceReviewControls[0];
        }
        var obj = _this14.getSettingValue('feature.show_node_id') ? {
          id: {
            field: node.id
          }
        } : {};
        var activityType = _this14.activityTypes.find(function (item) {
          return node.activityTypeId === item.id;
        });
        var purposeEnabled = activityType ? activityType.optionalFields.includes('process_purpose') : true;
        var processStatusEnabled = activityType ? activityType.optionalFields.includes('process_status') : true;
        obj = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, obj), {}, {
          process: {
            node: buildHierarchicalStructure(node),
            fieldText: getHierarchicalList(node)
          },
          description: {
            field: node.description || ''
          },
          purpose: {
            field: node.purpose && purposeEnabled ? node.purpose : ''
          },
          processStatus: {
            field: node.status && processStatusEnabled ? node.status : ''
          },
          questionnaire: {
            field: (_node$questionnaire = node.questionnaire) === null || _node$questionnaire === void 0 ? void 0 : _node$questionnaire.title
          },
          questionnaireAnsweredPercentage: {
            field: node.questionnaireAnsweredPercentage || ''
          },
          gap_schema: {
            field: (_node$gap_schema = node.gap_schema) === null || _node$gap_schema === void 0 ? void 0 : _node$gap_schema.title
          },
          activityType: {
            field: activityType ? activityType.label : ''
          },
          responsible: {
            field: mapToCommaList(responsibleUsers, 'display_name')
          },
          accountable: {
            field: mapToCommaList(accountableUsers, 'display_name')
          },
          consulted: {
            field: mapToCommaList(consultedUsers, 'display_name')
          },
          informed: {
            field: mapToCommaList(informedUsers, 'display_name')
          },
          controllers: _this14.getActivitiesForPreview(controllers, obj.id, 'name'),
          organisations: {
            field: mapToCommaList(organisations, 'visible_name')
          },
          systems: _this14.getActivitiesForPreview(systems, obj.id, 'name'),
          actions: _this14.getActivitiesForPreview(actions, obj.id),
          contracts: _this14.getActivitiesForPreview(contracts, obj.id),
          controls: _this14.getActivitiesForPreview(controls, obj.id),
          governanceReview: _this14.getActivitiesForPreview(governanceReviewControls, obj.id),
          governanceReviewLastCompleted: _this14.getFormattedDateField((_governanceReview = governanceReview) === null || _governanceReview === void 0 ? void 0 : _governanceReview.latestCompletion, (_governanceReview2 = governanceReview) === null || _governanceReview2 === void 0 ? void 0 : _governanceReview2.deadlineTimezone),
          nextGovernanceReviewDate: _this14.getFormattedDateField((_governanceReview3 = governanceReview) === null || _governanceReview3 === void 0 ? void 0 : _governanceReview3.deadline, (_governanceReview4 = governanceReview) === null || _governanceReview4 === void 0 ? void 0 : _governanceReview4.deadlineTimezone)
        }, informationAssets), {}, {
          processors: _this14.getActivitiesForPreview(processors, obj.id),
          incidents: _this14.getActivitiesForPreview(incidents, obj.id),
          processNodes: _this14.getActivitiesForPreview(processNodes, obj.id, 'title', {
            processTreeId: _this14.processTreeId
          })
        }, risks), _this14.customFieldsById[node.id]);
        obj.dataflow = {
          field: _this14.hasDataFlow(node.id, _this14.dataflowReport) ? _this14.translate.yes : _this14.translate.no
        };
        obj.attachedFiles = {
          field: mapToCommaListWithLinks(node.attachments, 'url', ['title', 'filename'], 'id', false),
          fieldText: mapToCommaList(node.attachments, ['title', 'filename'])
        };
        obj.tags = {
          field: mapToCommaList(tags, 'tag')
        };
        obj.frameworks = {
          field: Utils.findPropsByIdsSorted(node.solutionIds, _this14.frameworks, 'displayName')
        };
        if (!_this14.showActivityType) {
          delete obj.activityType;
        }
        if (_this14.scoringIncludedInDatatable) {
          if (node.questionnaireScore) {
            obj.score = {
              field: node.questionnaireScore.totalScore
            };
            obj.scoreHelp = {
              field: "<span style=\"color: ".concat(node.questionnaireScore.helpText.color, "\">").concat(node.questionnaireScore.helpText.assessmentText, "</span>"),
              fieldText: node.questionnaireScore.helpText.assessmentText
            };
          } else {
            obj.score = {
              field: ''
            };
            obj.scoreHelp = {
              field: ''
            };
          }
        }
        if (_this14.getSettingValue('feature.risk_assessment_on_systems')) {
          obj.rto = {
            field: _this14.getRtoValue(node)
          };
          obj.rpo = {
            field: _this14.getRpoValue(node)
          };
        }
        rows.push(obj);
      });
      return rows;
    },
    getFormattedDateField: function getFormattedDateField(date, timezone) {
      if (!date || !timezone) return {
        field: ''
      };
      return {
        field: formatDate.prepareDateTimezone(date, timezone, DateFormats.DATE_TIME_FORMAT)
      };
    },
    getCustomFieldValueId: function getCustomFieldValueId(data, fieldId) {
      if (data.customFieldValues) {
        var valueObject = data.customFieldValues.find(function (f) {
          return +f.fieldId === +fieldId;
        });
        if (valueObject) return valueObject.value;
      }
      return null;
    },
    getCustomFieldValue: function getCustomFieldValue(data, fieldId) {
      var valueId = this.getCustomFieldValueId(data, fieldId);
      if (valueId === null) {
        return '';
      }
      var _ref12 = data.customFields.find(function (item) {
          return +item.id === +fieldId;
        }) || {},
        _ref12$options = _ref12.options,
        options = _ref12$options === void 0 ? {} : _ref12$options;
      var option = Object.values(options).find(function (option) {
        return +option.id === +valueId;
      });
      return (option === null || option === void 0 ? void 0 : option.value) || '';
    },
    getRtoValue: function getRtoValue(data) {
      return this.getCustomFieldValue(data, this.rtoFieldId);
    },
    getRpoValue: function getRpoValue(data) {
      return this.getCustomFieldValue(data, this.rpoFieldId);
    },
    getFormattedData: function getFormattedData(items, links, objId, module) {
      var _this15 = this;
      var result = {};
      items.forEach(function (item) {
        var activities = filterAsset(item.activities, links[module]);
        var column = "".concat(module, "-").concat(item.id);
        result[column] = _this15.getActivitiesForPreview(activities, objId);
      });
      return result;
    },
    getActivitiesForPreview: function getActivitiesForPreview(activities, objId) {
      var field = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'title';
      var addToActivity = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var fieldRow = {
        activities: activities.map(function (activity) {
          return {
            activity: _objectSpread(_objectSpread({}, activity), addToActivity),
            fieldText: activity[field]
          };
        }),
        fieldText: mapToCommaList(activities, field),
        id: objId
      };
      return _objectSpread({
        field: fieldRow
      }, fieldRow);
    },
    hasDataFlow: function hasDataFlow(id, report) {
      if (report.length === 0) {
        return false;
      }
      var filtered = report.filter(function (item) {
        return item.id == id;
      });
      if (filtered.length && filtered[0].dataFlows.length) {
        return true;
      }
      return false;
    },
    handleClearQuery: function handleClearQuery() {
      this.queryInput = '';
      this.noResults = false;
      this.searchArgs = {};
      this.resetSavedReportQuery();
    },
    buildQuery: function buildQuery(args) {
      var query = '';
      if (args.query.length > 0) {
        query = query + 'title=' + args.query + ':';
      }
      if (args.organisations.length > 0) {
        query = query + 'organisation=' + this.convertArrayToString(args.organisations) + ':';
      }
      if (args.users.length > 0) {
        query = query + 'owner=' + this.convertArrayToString(args.users) + ':';
      }
      if (args.tags.length) {
        query += "tag_id=".concat(args.tags.join(','), ":");
      }
      query = query.replace(/'/g, "\\'");
      return query.substr(0, query.length - 1);
    },
    convertArrayToString: function convertArrayToString(array) {
      return array.join();
    },
    setDataFlowReport: function setDataFlowReport(response) {
      this.dataflowReport = response.list;
    },
    sortNumerically: function sortNumerically(data, key) {
      return data.sort(function (a, b) {
        return a[key].field - b[key].field;
      });
    },
    onDismissModal: function onDismissModal() {
      if (this.updatedNode) {
        this.refreshTable();
        this.updatedNode = null;
      }
    },
    refreshTable: function refreshTable() {
      this.handleDoSearch(this.searchArgs);
    },
    prepareCustomFieldOptions: function prepareCustomFieldOptions(nodes) {
      var _this$getPropertiesWi = this.getPropertiesWithCustomFields(nodes, this.activityTypes),
        cfByEntities = _this$getPropertiesWi.cfByEntities,
        cfColumns = _this$getPropertiesWi.cfColumns,
        cfDisabledColumns = _this$getPropertiesWi.cfDisabledColumns;
      this.cfColumns = cfColumns;
      this.customFieldsById = cfByEntities;
      this.disabledFilterByColumns = this.disabledFilterByColumns.concat(cfDisabledColumns);
    },
    generateDataset: function generateDataset(allNodes, filterType) {
      var _this16 = this;
      var nodes = _toConsumableArray(allNodes);
      if (filterType > 0 && +this.projectId === ProjectTypes.GDPR) {
        nodes = nodes.filter(function (item) {
          return _this16.liaTiaNodes.find(function (node) {
            return node.id === item.id;
          });
        });
      }
      this.prepareCustomFieldOptions(nodes);
      this.dataset = this.handleMapResults(nodes);
      this.enhanceDataset(this.dataset, this.columns);
      this.linkedColumnsForPreview.forEach(function (previewColumnKey) {
        _this16.decoupleFilterFields.push({
          key: previewColumnKey
        });
      });
    },
    setAndLoadSavedReport: function setAndLoadSavedReport(savedReportOptions) {
      var _this17 = this;
      this.initializeFiltersAndQueryInput(savedReportOptions);
      return this.handleDoSearch(savedReportOptions, false).then(function () {
        _this17.dataset.length && _this17.viewSelected === viewTypes.COMPACT && _this17.loadSavedReport();
      });
    },
    initializeFiltersAndQueryInput: function initializeFiltersAndQueryInput(_ref13) {
      var _ref13$query = _ref13.query,
        query = _ref13$query === void 0 ? '' : _ref13$query,
        filterType = _ref13.filterType,
        filters = _objectWithoutProperties(_ref13, _excluded);
      this.initialFilters = _objectSpread(_objectSpread(_objectSpread({}, this.initialFilters), filters), {}, {
        selectedRadio: filterType
      });
      this.queryInput = query;
    }
  })
};