import { buildNavBar } from '@/menus/navbar';
import { sortAlphabetic } from '@/utils/sort';
export function projectsByUser(user, getSettings) {
  if (!user) return [];
  var data = {};
  if (getSettings('app.module_risks2_enabled') === 1) {
    var _user$projects;
    data.risk = (user === null || user === void 0 || (_user$projects = user.projects) === null || _user$projects === void 0 ? void 0 : _user$projects.risk) || [];
  }
  if (getSettings('app.module_compliance_enabled') === 1) {
    var _user$projects2;
    data.compliance = (user === null || user === void 0 || (_user$projects2 = user.projects) === null || _user$projects2 === void 0 ? void 0 : _user$projects2.compliance) || [];
  }
  return data;
}
export function getAllModules(settings) {
  var structure = buildNavBar(settings, null);
  var list = [structure.actions, structure.companies, structure.contracts, structure.controls, structure.policies, structure.incident, structure.assets, structure.risk].filter(function (module) {
    return module.rights;
  });
  return sortAlphabetic(list, 'title');
}