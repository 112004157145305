export function capitalize(value) {
  if (!value) {
    return '';
  }
  return value.toString().charAt(0).toUpperCase() + value.toString().slice(1);
}
export function capitalizeWords(str) {
  return str.split(' ').map(capitalize).join(' ');
}
export function deCapitalize(value) {
  if (!value) return '';
  return value.toString().charAt(0).toLowerCase() + value.toString().slice(1);
}