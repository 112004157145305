import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'RismaVueMultiselectTags',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    tags: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Set to true to disable switch'
    },
    tabindex: {
      type: [Number, String],
      required: false,
      default: 0,
      note: 'index for tabbing'
    },
    labelKey: {
      type: String,
      required: true,
      note: 'The key to create the labels by'
    },
    truncateTagText: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Indicates that truncation applies to the text within the tags. It has higher priority than truncateLength'
    },
    truncateLength: {
      type: Number,
      required: false,
      default: 200
    },
    lockedOptions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    trackBy: {
      required: false,
      type: String,
      default: 'id',
      note: 'Key to compare options by'
    }
  },
  emits: ['remove'],
  data: function data() {
    return {
      hoveredElementIndex: null
    };
  },
  methods: {
    getTagsClasses: function getTagsClasses(value) {
      if (this.disabled) return 'pr-2 border-rm-grey text-rm-text bg-rm-grey--20';
      var additionalClasses = this.lockedOptions.includes(value) ? 'pr-2' : 'pr-0 hover:bg-rm-white hover:outline-rm-active-blue hover:outline hover:outline-1';
      return "bg-rm-active-blue--10 text-rm-active-blue border-rm-active-blue ".concat(additionalClasses);
    }
  }
};