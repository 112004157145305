function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapActions, mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
import { useRiskProjectsStore } from '@/Store/riskProjectsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RowOfRights from '@/components/Molecules/Admin/RowOfRights';
import UserActionModal from '@/components/Molecules/Modal/UserActionModal';
import DelegationModal from '@/components/Molecules/DelegationModal';
import { getModuleTitles, MODULES } from '@/constants/modules';
import { DelegationModalSettings, DelegationTypes } from '@/constants/relations/DelegationModal';
import { compareStrings } from '@/utils/compare';
import { UserAccessFieldByModule, UserLevelsByValue } from '@/constants/UserLevels';
import { findIdsInArray } from '@/utils/Utils';
import { getUserActionConfiguration, updateUserActionConfiguration, resetUserActionConfiguration } from '@/api/admin/admin';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { getInformationAssetsTitle } from '@/utils/InformationAssets';
export default {
  name: 'UserActionConfiguration',
  components: {
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    FeatherIcon: FeatherIcon,
    RowOfRights: RowOfRights,
    DelegationModal: DelegationModal,
    UserActionModal: UserActionModal
  },
  setup: function setup() {
    var localStorageKey = 'user_action_configuration_list';
    return {
      userListIds: getLocalStorageComputed(localStorageKey, [], true)
    };
  },
  data: function data() {
    return {
      isLoaded: false,
      data: {},
      defaultData: {},
      isUserModalOpened: false,
      ModuleTitles: getModuleTitles(),
      modalKey: '',
      translate: getTranslate['UserActionConfiguration']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useActivityTypesStore, ['getActivityTypeById'])), mapState(useUsersStore, ['activeUsers'])), mapState(useUserStore, {
    getComplianceProjectById: 'complianceProjectById',
    getRiskProjectById: 'riskProjectById'
  })), {}, {
    modalSettings: function modalSettings() {
      return DelegationModalSettings[DelegationTypes.users];
    },
    accessList: function accessList() {
      return ['Normal', this.translate.privileged, this.translate.super, this.translate.administrator];
    },
    columns: function columns() {
      var users = this.usersList;
      return ['', ''].concat(_toConsumableArray(this.accessList), _toConsumableArray(users), ['userIcon']);
    },
    headRows: function headRows() {
      var users = this.usersList;
      return ['icon', 'title', '', '', '', ''].concat(_toConsumableArray(users), ['']);
    },
    usersList: function usersList() {
      if (!this.userListIds) return [];
      return findIdsInArray(this.userListIds, this.activeUsers);
    }
  }),
  mounted: function mounted() {
    this.loadData();
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useComplianceProjectsStore, {
    getComplianceProjectDataById: 'getProjectById'
  })), mapActions(useRiskProjectsStore, {
    getRiskProjectDataById: 'getProjectById'
  })), {}, {
    removeUser: function removeUser(user) {
      this.userListIds = this.userListIds.filter(function (item) {
        return item !== user.id;
      });
    },
    handleUpdateUsers: function handleUpdateUsers(list) {
      this.userListIds = list;
      this.isUserModalOpened = false;
    },
    handleModalOpen: function handleModalOpen(data) {
      this.modalKey = data;
    },
    loadData: function loadData() {
      var _this = this;
      this.isLoaded = false;
      var promises = [getUserActionConfiguration(true).then(function (_ref) {
        var list = _ref.list;
        return _this.defaultData = list;
      }), getUserActionConfiguration().then(function (_ref2) {
        var list = _ref2.list;
        return _this.setData(list);
      })];
      return Promise.all(promises).then(function () {
        _this.isLoaded = true;
      });
    },
    setData: function setData(list) {
      var _this2 = this;
      this.data = {};
      var unsortedData = {};
      list.filter(function (item) {
        if (item.activityTypeId && !_this2.getActivityTypeById(item.activityTypeId).label) {
          return false;
        }
        if (item.complianceProjectId && !_this2.getComplianceProjectById(item.complianceProjectId).id) {
          return false;
        }
        if (item.riskProjectId && !_this2.getRiskProjectById(item.riskProjectId).id) {
          return false;
        }
        return true;
      }).forEach(function (item) {
        var field = _this2.getField(item);
        if (unsortedData[field]) {
          unsortedData[field].unshift(_objectSpread(_objectSpread({}, item), {}, {
            title: item.label
          }));
        } else {
          unsortedData[field] = [_objectSpread(_objectSpread({}, item), {}, {
            title: item.label
          })];
        }
      });
      var keys = Object.keys(unsortedData).sort();
      keys.forEach(function (key) {
        _this2.data[key] = unsortedData[key].sort(function (a, b) {
          return compareStrings(a.action, b.action);
        });
      });
    },
    getField: function getField(item) {
      var title = this.ModuleTitles[item.module];
      var BuiltInIAModules = [MODULES.COMPLIANCE_IA_CONTROLLER, MODULES.COMPLIANCE_IA_PROCESSOR, MODULES.COMPLIANCE_IA_SYSTEM];
      if (BuiltInIAModules.includes(item.module)) {
        title = getInformationAssetsTitle() + ' - ' + title;
      }
      if (item.activityTypeId) {
        var _this$getActivityType;
        title += ' - ' + ((_this$getActivityType = this.getActivityTypeById(item.activityTypeId)) === null || _this$getActivityType === void 0 ? void 0 : _this$getActivityType.label);
      }
      if (item.complianceProjectId) {
        var _this$getCompliancePr;
        title += ' - ' + ((_this$getCompliancePr = this.getComplianceProjectDataById(item.complianceProjectId)) === null || _this$getCompliancePr === void 0 ? void 0 : _this$getCompliancePr.title);
      }
      if (item.riskProjectId) {
        var _this$getRiskProjectD;
        title += ' - ' + ((_this$getRiskProjectD = this.getRiskProjectDataById(item.riskProjectId)) === null || _this$getRiskProjectD === void 0 ? void 0 : _this$getRiskProjectD.title);
      }
      return title;
    },
    handleUpdateAccess: function handleUpdateAccess(data) {
      var _this3 = this;
      if (!data.length) {
        this.modalKey = '';
        return;
      }
      this.isLoaded = false;
      this.updateListWithNewData(data);
      this.modalKey = '';
      var promises = data.map(function (item) {
        if (item.overridden) {
          return updateUserActionConfiguration({
            module: item.module,
            action: item.action,
            accessLevel: item.accessLevel,
            activityTypeId: item.activityTypeId,
            complianceProjectId: item.complianceProjectId,
            riskProjectId: item.riskProjectId
          });
        }
        return resetUserActionConfiguration(item.identifier);
      });
      Promise.all(promises).then(function () {
        return _this3.$notify({
          title: _this3.translate.userActionsConfigurationUpdated
        });
      }).catch(function (error) {
        return _this3.$notify({
          title: error.response.error,
          type: 'error'
        });
      }).finally(function () {
        return _this3.isLoaded = true;
      });
    },
    updateListWithNewData: function updateListWithNewData(data) {
      var _this4 = this;
      data.forEach(function (item) {
        var index = _this4.data[_this4.modalKey].findIndex(function (itemToUpdate) {
          return itemToUpdate.identifier === item.identifier;
        });
        if (item.accessLevel === -1) {
          var defaultValue = _this4.defaultData.find(function (defaultItem) {
            return defaultItem.identifier === item.identifier;
          }).accessLevel;
          item.accessLevel = defaultValue;
        }
        _this4.data[_this4.modalKey][index] = item;
      });
    },
    getUserAccessLevelLabel: function getUserAccessLevelLabel(user, items) {
      var module = items[0].module;
      var rightsName = this.getUserRightsByModule(user, module, items);
      return UserLevelsByValue[rightsName];
    },
    getUserRightsByModule: function getUserRightsByModule(user, module, items) {
      if (module === MODULES.COMPLIANCE || module === MODULES.RISK) {
        var _field = module === MODULES.COMPLIANCE ? 'complianceProjectId' : 'riskProjectId';
        var project = user.projects[module].find(function (item) {
          return item.id === items[0][_field];
        });
        if (!project) return '';
        return project.access_level;
      }
      var field = UserAccessFieldByModule[module];
      return user[field];
    }
  })
};