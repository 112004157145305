import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "user-selector"
};
var _hoisted_2 = ["title"];
var _hoisted_3 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_select = _resolveComponent("risma-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.availableOptions !== null ? (_openBlock(), _createBlock(_component_risma_select, {
    key: 0,
    title: $props.title,
    options: $props.availableOptions,
    "selected-options": $props.selectedOptions,
    placeholder: $data.translate.users,
    "enable-select-all": $props.enableSelectAll,
    "max-visible-tags": $options.maxVisibleTags,
    "confirm-select-all": true,
    "truncate-length": 0,
    "error-message": $props.errorMessage,
    "label-key": "display_name",
    onSelected: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('selected', $event);
    }),
    onOpen: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('open');
    }),
    onClose: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('close');
    })
  }, {
    tagLabelCustom: _withCtx(function (props) {
      return [_createElementVNode("span", {
        title: props.option.fullname
      }, _toDisplayString(props.option.display_name), 9 /* TEXT, PROPS */, _hoisted_2)];
    }),
    labelCustom: _withCtx(function (props) {
      return [_createElementVNode("span", {
        title: props.option.fullname
      }, _toDisplayString(props.option.display_name), 9 /* TEXT, PROPS */, _hoisted_3)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "enable-select-all", "max-visible-tags", "error-message"])) : _createCommentVNode("v-if", true)]);
}