import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = {
  class: "mb-5"
};
var _hoisted_3 = {
  class: "flex items-center mb-1"
};
var _hoisted_4 = {
  class: "text-rm-signal-red glob-l2-sb"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_static_table = _resolveComponent("static-table");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_modal, {
    header: $options.header,
    "button-ok-text": $data.translate.apply,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.onModalAccept,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [$data.error ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        closeable: false
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.error), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
        title: $data.translate.newNumber,
        class: "mr-2",
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_4, _toDisplayString($data.translate.pleaseOnlyTypeNewSequenceNumber), 1 /* TEXT */)]), _createVNode(_component_input_field, {
        modelValue: $data.newNumber,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.newNumber = $event;
        }),
        "focus-on-mount": true,
        placeholder: $data.translate.insertNewNumber,
        invalid: !!$data.numberError,
        "error-help-text": $data.numberError,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "placeholder", "invalid", "error-help-text"])]), _createElementVNode("div", null, [_createVNode(_component_risma_title, {
        title: $data.translate.theFollowingActionsWillBeAffected,
        truncate: false,
        class: "mb-1",
        type: "small"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_static_table, {
        "dataset-table": $options.dataset,
        columns: $options.columns,
        "columns-max-width": $data.columnsMaxWidth
      }, {
        trafficLight: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createElementVNode("div", null, [_createVNode(_component_traffic_light, {
            color: slotData
          }, null, 8 /* PROPS */, ["color"])])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset-table", "columns", "columns-max-width"])])]))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"])]);
}