// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-wrapper[data-v-1c81583f] {
  display: flex;
  margin-bottom: 1rem;
}
.select-wrapper .choose-result[data-v-1c81583f] {
  width: 200px;
}
.select-wrapper .select-item[data-v-1c81583f] {
  width: 287px;
  margin: 1rem;
}
.select-wrapper .select-mode[data-v-1c81583f] {
  margin: 1rem 60px 1rem auto;
}
.button-wrap[data-v-1c81583f] {
  text-align: center;
}
.add-button-wrap[data-v-1c81583f] {
  align-self: flex-end;
  margin-bottom: 1rem;
  margin-left: 10px;
}
.add-button-wrap[data-v-1c81583f]  .risma-button-btn {
  padding: 5px 10px !important;
  border-radius: 5px;
}
.parsed-rules[data-v-1c81583f] {
  margin-bottom: 20px;
}
.parsed-rules .parsed-rule[data-v-1c81583f] {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 3px 10px;
  border-radius: 5px;
  color: #FFFFFF;
  background: #347480;
}
.parsed-rules .parsed-rule .parsed-rule-content[data-v-1c81583f] {
  margin-right: 20px;
}
.fade-enter-active[data-v-1c81583f],
.fade-leave-active[data-v-1c81583f] {
  transition: opacity 0.3s;
}
.fade-enter-from[data-v-1c81583f],
.fade-leave-to[data-v-1c81583f] {
  opacity: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
