function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { defineStore } from 'pinia';
import getUtilSettings, { patchUtilSettings } from '@/api/risma/settings';
import cloneDeep from 'lodash/cloneDeep';
import i18n from '@/i18n';
export var useSettingsStore = defineStore('settingsStore', {
  state: function state() {
    return {
      settings: {}
    };
  },
  getters: {
    isComplianceAvailable: function isComplianceAvailable() {
      return this.settings['app.module_compliance_enabled'];
    },
    getValue: function getValue() {
      var _this = this;
      return function (settingName) {
        return _this.settings[settingName];
      };
    },
    showControlType: function showControlType() {
      return this.settings['risma.show_key_control'] || this.settings['risma.show_automatic_manual'] || this.settings['risma.show_preventive_detective'] || this.settings['risma.show_high_complexity_low_complexity'];
    },
    attachmentSizeLimit: function attachmentSizeLimit() {
      return this.settings['max_attachment_size'];
    },
    attachmentSizeLimitReadable: function attachmentSizeLimitReadable() {
      return this.settings['max_attachment_size_readable'];
    },
    getAllSettings: function getAllSettings() {
      return this.settings;
    },
    isSystemsEnabled: function isSystemsEnabled() {
      return this.settings['feature.enable_systems'] === undefined || this.settings['feature.enable_systems'] === 1;
    },
    isDataControllersEnabled: function isDataControllersEnabled() {
      return this.settings['feature.enable_data_controllers'] === undefined || this.settings['feature.enable_data_controllers'] === 1;
    },
    isDataProcessorsEnabled: function isDataProcessorsEnabled() {
      return this.settings['feature.enable_data_processors'] === undefined || this.settings['feature.enable_data_processors'] === 1;
    },
    isIACustomEnabled: function isIACustomEnabled() {
      return this.settings['feature.enable_ia_custom'] === undefined || this.settings['feature.enable_ia_custom'] === 1;
    },
    isInformationAssetsDisabled: function isInformationAssetsDisabled() {
      return this.settings['feature.disable_information_assets'] === 1;
    },
    processLibraryName: function processLibraryName() {
      var processLibraryName = this.settings['feature.process_library_name'] || 'Process Library';
      return processLibraryName === 'Process Library' ? i18n.t(processLibraryName) : processLibraryName;
    },
    informationAssetsName: function informationAssetsName() {
      var assetsName = this.settings['feature.information_assets_name'] || 'Information Assets';
      return assetsName === 'Information Assets' ? i18n.t(assetsName) : assetsName;
    },
    aiAssistantEnabled: function aiAssistantEnabled() {
      return this.settings['feature.overall.ai_assistant_enabled'];
    },
    aiTermsAccepted: function aiTermsAccepted() {
      return this.settings['app.overall.ai_terms_accepted'];
    },
    aiDefaultRegion: function aiDefaultRegion() {
      return this.settings['app.overall.ai_default_region'] || 'DK';
    }
  },
  actions: {
    set: function set(settings) {
      this.settings = cloneDeep(settings);
    },
    load: function load() {
      var _this2 = this;
      return getUtilSettings().then(function (_ref) {
        var list = _ref.list;
        var settings = {};
        list.forEach(function (setting) {
          return settings[setting.settingname] = setting.value;
        });
        _this2.settings = settings;
        if (settings['feature.disable_information_assets']) {
          _this2.disableIA();
        }
        return settings;
      });
    },
    updateSettings: function updateSettings(settings) {
      var _this3 = this;
      return patchUtilSettings(settings).then(function () {
        Object.entries(settings).forEach(function (_ref2) {
          var _ref3 = _slicedToArray(_ref2, 2),
            settingname = _ref3[0],
            value = _ref3[1];
          return _this3.settings[settingname] = value;
        });
      });
    },
    disableIA: function disableIA() {
      this.settings['feature.enable_data_controllers'] = 0;
      this.settings['feature.enable_data_processors'] = 0;
      this.settings['feature.enable_systems'] = 0;
      this.settings['feature.enable_ia_custom'] = 0;
      this.settings['feature.dataflow'] = 0;
    }
  }
});