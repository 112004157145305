import { sortArrayOfObjectsByArrayOfValues } from '@/utils/sort';
import { calculateFilterDelta } from '@/constants/datatable/filters';
export var SaveReportMixin = {
  data: function data() {
    return {
      isReportSaved: false
    };
  },
  methods: {
    loadReportByReplacingQueryParams: function loadReportByReplacingQueryParams(report) {
      var _report$query;
      if (report !== null && report !== void 0 && (_report$query = report.query) !== null && _report$query !== void 0 && (_report$query = _report$query.reportOptions) !== null && _report$query !== void 0 && _report$query.isShortUrl) {
        this.$router.replace({
          query: {
            reportId: report.id
          }
        });
        return;
      }
      this.$router.replace({
        query: {
          query: JSON.stringify({
            query: report.query
          })
        }
      });
    },
    applySettingsFromLoadedReport: function applySettingsFromLoadedReport(data) {
      var _data$query, _data$query2;
      var applyDateRange = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var state = this.getTableSettings();
      var filters = data.query.filters;
      var columns = data.query.display ? data.query.display.columns : [];
      var sorting = data.query.display ? data.query.display.sorting : {};
      var additionalFilters = data.query.extraFilters || {};
      if (!state || !state.columns) return;
      state === null || state === void 0 || state.columns.forEach(function (column) {
        var key = column.key;
        column.visible = !columns.length || columns.includes(key);
        if (filters[key]) {
          column.search = filters[key];
        } else {
          column.search = '';
          column.css = '';
        }
        if (additionalFilters[key] !== undefined) {
          column.additionalFieldValue = additionalFilters[key];
          column.delta = calculateFilterDelta(key, additionalFilters[key]);
        }
        if (sorting[key]) {
          column.sort = {
            dir: sorting[key],
            index: key
          };
        } else {
          column.sort = null;
        }
      });
      if (state !== null && state !== void 0 && state.columns) {
        state.columns = sortArrayOfObjectsByArrayOfValues(state.columns, columns);
      }
      state.search = ((_data$query = data.query) === null || _data$query === void 0 || (_data$query = _data$query.display) === null || _data$query === void 0 ? void 0 : _data$query.search) || {};
      this.updateRismaDatatableState(state);
      if (!applyDateRange) return;
      this.applyDateRangeFromReport((_data$query2 = data.query) === null || _data$query2 === void 0 || (_data$query2 = _data$query2.reportOptions) === null || _data$query2 === void 0 ? void 0 : _data$query2.dateRange);
    },
    applyDateRangeFromReport: function applyDateRangeFromReport(dateRange) {
      var rismaDateRangePicker = this.$refs.rismaDateRangePicker;
      if (!dateRange || dateRange.some(function (date) {
        return !date;
      }) || !rismaDateRangePicker) return;
      rismaDateRangePicker.handleChange(dateRange);
      this.$nextTick(function () {
        return rismaDateRangePicker.handleApply();
      });
    },
    updateRismaDatatableState: function updateRismaDatatableState(state) {
      var datatable = this.$refs.reportsDataTable;
      datatable.resetState();
      datatable.handleStateSafely(state);
      datatable.showAppliedFilters();
    },
    afterReportSave: function afterReportSave() {
      var _this = this;
      this.isReportSaved = true;
      setTimeout(function () {
        _this.isReportSaved = false;
      }, 3000);
    },
    getTableSettings: function getTableSettings() {
      try {
        return JSON.parse(localStorage.getItem(this.stateLocal));
      } catch (e) {
        return null;
      }
    },
    loadSavedReport: function loadSavedReport() {
      var _this$$route$query,
        _this2 = this;
      var applyDateRange = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      if (!((_this$$route$query = this.$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.query)) return;
      this.$nextTick(function () {
        return _this2.applySettingsFromLoadedReport(JSON.parse(_this2.$route.query.query), applyDateRange);
      });
    },
    resetSavedReportQuery: function resetSavedReportQuery() {
      if (this.savedReportOptions) {
        this.$router.replace({
          query: {}
        });
      }
    }
  }
};