import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "validation-error"
};
var _hoisted_2 = {
  key: 1,
  class: "validation-error"
};
var _hoisted_3 = {
  key: 0,
  class: "items-group group-field-values"
};
var _hoisted_4 = {
  class: "custom-field-group-title"
};
var _hoisted_5 = {
  class: "custom-field-group-content"
};
var _hoisted_6 = {
  key: 1,
  class: "mb-3"
};
var _hoisted_7 = {
  key: 0,
  class: "validation-error"
};
var _hoisted_8 = {
  key: 2,
  class: "mb-3"
};
var _hoisted_9 = {
  class: "table-cell"
};
var _hoisted_10 = {
  class: "table-cell"
};
var _hoisted_11 = {
  key: 0,
  class: "table-cell"
};
var _hoisted_12 = {
  class: "table-cell"
};
var _hoisted_13 = ["onUpdate:modelValue", "value", "onChange"];
var _hoisted_14 = {
  class: "pl-6"
};
var _hoisted_15 = {
  class: "table-cell"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_options_list = _resolveComponent("options-list");
  var _component_input_field = _resolveComponent("input-field");
  return _openBlock(), _createElementBlock("fieldset", null, [_createElementVNode("div", {
    class: _normalizeClass([{
      disabled: $props.disabled
    }, "mb-3"])
  }, [_createElementVNode("div", {
    class: _normalizeClass([{
      error: $options.errors.options && !($props.field.options && $props.field.options.length) || $options.errors.typeId || $options.errors.emptyField
    }, "input-field-container"])
  }, [_createVNode(_component_single_select, {
    options: $options.fieldTypesAll,
    "model-value": $options.fieldType,
    disabled: $props.disabled,
    title: $data.translate.fieldType,
    placeholder: $data.translate.chooseAFieldType,
    "onUpdate:modelValue": $options.onChangeFieldType
  }, null, 8 /* PROPS */, ["options", "model-value", "disabled", "title", "placeholder", "onUpdate:modelValue"])], 2 /* CLASS */), $options.errors.typeId ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($data.translate.chooseAFieldType), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $options.errors.emptyField ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($data.translate.chooseCustomFields), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */), $options.fieldType === $data.customFieldTypes.SELECT || $options.fieldType === $data.customFieldTypes.SELECT_MULTI ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.dropdownOptions,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_options_list, {
    "predefined-options": $props.field.options,
    placeholder: $data.translate.enterDropdownOption,
    draggable: true,
    "read-only": $props.readOnly,
    "confirm-on-delete": $options.confirmOnDelete,
    "show-empty-fields-error": $options.errors.options,
    "error-message": !($props.field.options && $props.field.options.length) && $options.errors.options ? $data.translate.enterAnOption : '',
    "save-button-click-counter": $props.saveButtonClickCounter,
    "label-property": "value",
    class: "mb-3",
    onOnOptionsChange: $options.updateDropdownOption
  }, null, 8 /* PROPS */, ["predefined-options", "placeholder", "read-only", "confirm-on-delete", "show-empty-fields-error", "error-message", "save-button-click-counter", "onOnOptionsChange"])])])) : _createCommentVNode("v-if", true), $options.fieldType === $data.customFieldTypes.NUMERICAL ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.unit,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", {
    class: _normalizeClass([{
      error: $options.errors.emptyUnitField
    }, "input-field-container"])
  }, [_createVNode(_component_input_field, {
    modelValue: $props.field.unit,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $props.field.unit = $event;
    }), $options.updateUnit],
    type: "text",
    placeholder: $data.translate.unit,
    maxlength: 20
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "onUpdate:modelValue"])], 2 /* CLASS */), $options.errors.emptyUnitField ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($data.translate.pleaseFillOutThisField), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.fieldType === $data.customFieldTypes.ROW_GROUP && $props.moduleType === $data.MODULES.CONTROLS ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("table", null, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $data.translate.chooseCustomFields,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_10, [_createVNode(_component_risma_title, {
    title: $data.translate.fieldType,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), $options.hasActiveField ? (_openBlock(), _createElementBlock("th", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $data.translate.chooseOrder,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.customFieldsFiltered, function (customField, key) {
    return _openBlock(), _createElementBlock("tr", {
      key: key
    }, [_createElementVNode("td", _hoisted_12, [_createElementVNode("label", null, [_withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return customField.isActive = $event;
      },
      value: customField.isActive,
      type: "checkbox",
      class: "float-left",
      onChange: function onChange($event) {
        return $options.setChosenCustomFieldActive(customField, $event);
      }
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_13), [[_vModelCheckbox, customField.isActive]]), _createElementVNode("div", _hoisted_14, _toDisplayString(customField.label), 1 /* TEXT */)])]), _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.$trans(customField.fieldtype_text)), 1 /* TEXT */), _createElementVNode("td", null, [customField.isActive ? (_openBlock(), _createBlock(_component_single_select, {
      key: 0,
      options: _ctx.orders,
      "un-sorted": $data.unSorted,
      "model-value": customField.order,
      class: "p-0",
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.onChangeChosenCustomFieldOrder(customField, $event);
      }
    }, null, 8 /* PROPS */, ["options", "un-sorted", "model-value", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true)])]);
  }), 128 /* KEYED_FRAGMENT */))])])])) : _createCommentVNode("v-if", true)]);
}