import i18n from '@/i18n';
export var getTranslate = {
  ContractDescription: function ContractDescription() {
    return {
      thereAreStillSomeRelatedContractsAttachedTo: i18n.t('There are still some related contracts attached to this contract'),
      warning: i18n.t('Warning'),
      areYouSureYouWishToPermanentlyDeleteThisC: i18n.t('Are you sure you wish to permanently delete this contract?'),
      deleteContract: i18n.t('Delete contract'),
      delete: i18n.t('Delete'),
      cancel: i18n.t('Cancel'),
      confidentialContract: i18n.t('Confidential contract'),
      description: i18n.t('Description'),
      startDate: i18n.t('Start date'),
      expirationDate: i18n.t('Expiration date'),
      notificationUponExpirationDate: i18n.t('Notification upon expiration date'),
      renewalDeadline: i18n.t('Renewal deadline'),
      notificationUponRenewalDeadline: i18n.t('Notification upon renewal deadline'),
      renewalAmount: i18n.t('Renewal amount'),
      commitmentDeadline: i18n.t('Commitment deadline'),
      notificationUponCommitmentDeadline: i18n.t('Notification upon commitment deadline'),
      autoRenewal: i18n.t('Auto renewal'),
      choose: i18n.t('Choose'),
      amount: i18n.t('Amount'),
      paymentInterval: i18n.t('Payment interval'),
      contractVersion: i18n.t('Contract version'),
      statusChangeNote: i18n.t('Status change note'),
      contractExpiresAfterExpirationDate: i18n.t('Contract expires after expiration date'),
      contractContinuesAfterExpirationDate: i18n.t('Contract continues after expiration date'),
      thisContractIsArchivedAndCantBeChanged: i18n.t('This contract is archived and can’t be changed'),
      archiveContract: i18n.t('Archive contract'),
      restoreContract: i18n.t('Restore contract'),
      areYouSureYouWantToArchiveContract: i18n.t('Are you sure you want to archive contract?'),
      areYouSureYouWantToRestoreContract: i18n.t('Are you sure you want to restore contract?'),
      rememberToEditContractDatesAndorContractSt: i18n.t('Remember to edit contract dates and/or contract status.'),
      renewContract: i18n.t('Renew contract'),
      addOutsourcing: i18n.t('Add outsourcing'),
      copyOnlyContract: i18n.t('Copy only contract'),
      copyContractAndControls: i18n.t('Copy contract and controls'),
      anErrorOccurred: i18n.t('An error occurred'),
      paymentStartMonth: i18n.t('Payment start month'),
      latestAudit: i18n.t('Latest audit'),
      doraIctContract: i18n.t('DORA ICT Contract'),
      thisIsAnIctContract: i18n.t('This is an ICT contract and should be included in the DORA Registry'),
      terminationReason: i18n.t('Termination reason'),
      noticePeriodForFinancialEntity: i18n.t('Notice period for the financial entity (days)'),
      noticePeriodForICTProvider: i18n.t('Notice period for the ICT provider (days)'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      jurisdictionCountry: i18n.t('Jurisdiction country'),
      jurisdictionName: i18n.t('Jurisdiction name'),
      egDistrictCourt: i18n.t('e.g. District Court, Orlando, FL'),
      annualExpense: i18n.t('Annual expense'),
      annualExpenseCurrency: i18n.t('Annual expense currency')
    };
  },
  ContractDocuments: function ContractDocuments() {
    return {
      documents: i18n.t('Documents'),
      noDocumentsHaveBeenUploadedYet: i18n.t('No documents have been uploaded yet.'),
      newVersion: i18n.t('New version'),
      edit: i18n.t('Edit'),
      delete: i18n.t('Delete'),
      title: i18n.t('Title'),
      uploaderName: i18n.t('Uploader name'),
      uploadDate: i18n.t('Upload date'),
      type: i18n.t('Type'),
      versions: i18n.t('Versions'),
      notSpecified: i18n.t('Not specified'),
      showVersions: i18n.t('Show versions')
    };
  },
  ContractEntry: function ContractEntry() {
    return {
      thisActivityIsReadonlyAsYourUserIsConfigu: i18n.t('This activity is read-only as your user is configured to only have read-only access'),
      activityType: i18n.t('Activity type'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      terminationReason: i18n.t('Termination reason'),
      isRequired: i18n.t('is required')
    };
  },
  ContractParty: function ContractParty() {
    return {
      delete: i18n.t('Delete'),
      name: i18n.t('Name'),
      address: i18n.t('Address'),
      city: i18n.t('City'),
      zipcode: i18n.t('Zipcode'),
      phone: i18n.t('Phone'),
      vatNumber: i18n.t('VAT number'),
      title: i18n.t('Title'),
      email: i18n.t('E-mail'),
      nothingToShow: i18n.t('Nothing to show'),
      youNeedToAddContractPartyBeforeAddingConta: i18n.t('You need to add contract party before adding contact persons.'),
      selectedContractPartyDoesntHaveAnyAvailable: i18n.t('Selected contract party doesn`t have any available contact persons.'),
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      areYouSureYouWantToRemoveThisActivity: i18n.t('Are you sure you want to remove this') + ' ' + i18n.t('Activity').toLowerCase() + '?'
    };
  },
  ContractRelatedContracts: function ContractRelatedContracts() {
    return {
      otherRelatedContracts: i18n.t('Other related contracts'),
      nothingToShow: i18n.t('Nothing to show'),
      relatedContractsFor: i18n.t('Related contracts for'),
      relatedContracts: i18n.t('Related contracts'),
      areYouSureYouWantToRemoveThisActivity: i18n.t('Are you sure you want to remove this') + ' ' + i18n.t('Activity').toLowerCase() + '?'
    };
  },
  ContractsDashboard: function ContractsDashboard() {
    return {
      expiring: i18n.t('Expiring'),
      renewal: i18n.t('Renewal'),
      commitment: i18n.t('Commitment'),
      payment: i18n.t('Payment'),
      statusOverview: i18n.t('Status overview'),
      contracts: i18n.t('Contracts'),
      annualCost: i18n.t('Annual cost'),
      expirationOfContractsWithin30Days: i18n.t('Expiration of contracts within 30 days'),
      nothingToShow: i18n.t('Nothing to show'),
      paymentIn: i18n.t('Payment in')
    };
  },
  ContractsDPAOverview: function ContractsDPAOverview() {
    return {
      dataProcessorAgreements: i18n.t('Data processor agreements'),
      dataProcessorAgreement: i18n.t('Data processor agreement'),
      mainContract: i18n.t('Main contract'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      contractType: i18n.t('Contract type')
    };
  },
  ContractsFileDocuments: function ContractsFileDocuments() {
    return {
      search: i18n.t('Search'),
      contractStatus: i18n.t('Contract status'),
      select: i18n.t('Select'),
      documentTypes: i18n.t('Document types'),
      resetFilter: i18n.t('Reset filter'),
      documents: i18n.t('Documents'),
      nothingToShow: i18n.t('Nothing to show'),
      theSelectedSearchCriteriaHaveNoResultsToDi: i18n.t('The selected search criteria have no results to display'),
      title: i18n.t('Title'),
      contract: i18n.t('Contract'),
      uploadDate: i18n.t('Upload date'),
      type: i18n.t('Type'),
      versions: i18n.t('Versions'),
      contractType: i18n.t('Contract type')
    };
  },
  ContractsOverview: function ContractsOverview() {
    return {
      controls: i18n.t('Controls'),
      awaiting: i18n.t('Awaiting'),
      exceeded: i18n.t('Exceeded'),
      contracts: i18n.t('Contracts'),
      search: i18n.t('Search'),
      display: i18n.t('Display'),
      contractNo: i18n.t('Contract no.'),
      title: i18n.t('Title'),
      contractParty: i18n.t('Contract party'),
      contactPerson: i18n.t('Contact person'),
      activityType: i18n.t('Activity type'),
      amount: i18n.t('Amount'),
      currency: i18n.t('Currency'),
      paymentInterval: i18n.t('Payment interval'),
      paymentStartMonth: i18n.t('Payment start month'),
      renewalAmount: i18n.t('Renewal amount'),
      renewalAmountCurrency: i18n.t('Renewal amount currency'),
      startDate: i18n.t('Start date'),
      expirationDate: i18n.t('Expiration date'),
      commitmentDeadline: i18n.t('Commitment deadline'),
      renewalDeadline: i18n.t('Renewal deadline'),
      status: i18n.t('Status'),
      description: i18n.t('Description'),
      responsible: i18n.t('Responsible'),
      accountable: i18n.t('Accountable'),
      consulted: i18n.t('Consulted'),
      informed: i18n.t('Informed'),
      organisation: i18n.t('Organisation'),
      tags: i18n.t('Tags'),
      starredContracts: i18n.t('Starred contracts'),
      myContracts: i18n.t('My contracts'),
      expiredContracts: i18n.t('Expired contracts'),
      overview: i18n.t('Overview'),
      contractHasBeenCreated: i18n.t('Contract has been created'),
      youDoNotHavePermissionToEditThisContract: i18n.t('You do not have permission to edit this contract'),
      noRecordsMatching: i18n.t('No records matching'),
      contractType: i18n.t('Contract type'),
      internalParty: i18n.t('Internal party'),
      externalParty: i18n.t('External party')
    };
  },
  ContractsOverviewWrapper: function ContractsOverviewWrapper() {
    return {
      myContracts: i18n.t('My contracts'),
      myCompanies: i18n.t('My companies'),
      overview: i18n.t('Overview'),
      expiredContracts: i18n.t('Expired contracts'),
      dataProcessorAgreements: i18n.t('Data processor agreements'),
      searchDocuments: i18n.t('Search documents'),
      searchContent: i18n.t('Search content')
    };
  },
  ContractsReport: function ContractsReport() {
    return {
      report: i18n.t('Report'),
      expirationDate: i18n.t('Expiration date'),
      deadlineInterval: i18n.t('Deadline interval'),
      contractNo: i18n.t('Contract no.'),
      title: i18n.t('Title'),
      contractParty: i18n.t('Contract party'),
      contactPerson: i18n.t('Contact person'),
      activityType: i18n.t('Activity type'),
      amount: i18n.t('Amount'),
      currency: i18n.t('Currency'),
      renewalAmount: i18n.t('Renewal amount'),
      renewalAmountCurrency: i18n.t('Renewal amount currency'),
      startDate: i18n.t('Start date'),
      notificationUponExpirationDate: i18n.t('Notification upon expiration date'),
      commitmentDeadline: i18n.t('Commitment deadline'),
      notificationUponCommitmentDeadline: i18n.t('Notification upon commitment deadline'),
      renewalDeadline: i18n.t('Renewal deadline'),
      notificationUponRenewalDeadline: i18n.t('Notification upon renewal deadline'),
      paymentInterval: i18n.t('Payment interval'),
      confidential: i18n.t('Confidential'),
      status: i18n.t('Status'),
      archived: i18n.t('Archived'),
      description: i18n.t('Description'),
      responsible: i18n.t('Responsible'),
      accountable: i18n.t('Accountable'),
      consulted: i18n.t('Consulted'),
      informed: i18n.t('Informed'),
      organisation: i18n.t('Organisation'),
      tags: i18n.t('Tags'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      contracts: i18n.t('Contracts'),
      paymentStartMonth: i18n.t('Payment start month'),
      startAndExpirationDate: i18n.t('Start- and expiration date'),
      frameworks: i18n.t('Framework'),
      autoRenewal: i18n.t('Auto renewal'),
      notSet: i18n.t('Not set'),
      contractExpiresAfterExpirationDate: i18n.t('Contract expires after expiration date'),
      contractContinuesAfterExpirationDate: i18n.t('Contract continues after expiration date'),
      contractType: i18n.t('Contract type'),
      questionnaire: i18n.t('Questionnaire'),
      completionDegreeIn: i18n.t('Completion degree in %'),
      score: i18n.t('Score'),
      assessmentScoring: i18n.t('Assessment scoring'),
      internalParty: i18n.t('Internal party'),
      externalParty: i18n.t('External party')
    };
  },
  ContractFinance: function ContractFinance() {
    return {
      paymentOverview: i18n.t('Payment overview'),
      amount: i18n.t('Amount'),
      paymentInterval: i18n.t('Payment interval'),
      paymentStartMonth: i18n.t('Payment start month'),
      overallSum: i18n.t('Overall sum'),
      choose: i18n.t('Choose'),
      lastPayment: i18n.t('Last payment'),
      inOrderToCalculateTheTotalPayment: i18n.t('In order to calculate the total payment, you must first set the currency.')
    };
  },
  DoraReport: function DoraReport() {
    return {
      registerOfInformation: i18n.t('Register of Information'),
      chooseTheParentCompanyYourRegister: i18n.t('Choose the parent company your register will be based on'),
      parentCompany: i18n.t('Parent company'),
      selectParentCompany: i18n.t('Select parent company'),
      entityMaintain: i18n.t('Entity maintaining the register of the information'),
      listOfEntities: i18n.t('List of entities within the scope of the register of information'),
      listOfBranches: i18n.t('List of branches'),
      previouslySelectedParentCompany: i18n.t('Previously selected parent company is no longer available'),
      id: i18n.t('ID'),
      name: i18n.t('Name'),
      status: i18n.t('Status'),
      csv: i18n.t('CSV'),
      ready: i18n.t('Ready'),
      missing: i18n.t('missing'),
      excel: i18n.t('Excel')
    };
  },
  ContractPartyExtended: function ContractPartyExtended() {
    return {
      contractParty: i18n.t('Contract party'),
      external: i18n.t('External'),
      internal: i18n.t('Internal'),
      add: i18n.t('Add'),
      setCompanyAsNewPayingPart: function setCompanyAsNewPayingPart(name) {
        return i18n.t('Set %s as the new paying part', [name]);
      },
      removeCurrentPayingPart: function removeCurrentPayingPart(name) {
        return i18n.t('This action will remove the current paying part: %s. Are you sure you want to proceed?', ["<b>".concat(name, "</b>")]);
      },
      start: i18n.t('Start'),
      byAddingYourFirstExternal: i18n.t('by adding your first external contract party'),
      byAddingYourFirstInternal: i18n.t('by adding your first internal contract party')
    };
  }
};