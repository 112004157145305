function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { MODULES, getModuleTitles } from '@/constants/modules';
import { RiskAssessmentActivityLinksTab } from '@/constants/links/LinksModalSettings';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'SelectorTags',
  components: {
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    FeatherIcon: FeatherIcon,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  props: {
    module: {
      required: true,
      type: String,
      default: MODULES.INCIDENTS,
      note: 'selector module'
    },
    tags: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'selector tags'
    },
    hasTrafficLights: {
      required: false,
      type: [Boolean, Function],
      default: false,
      note: 'selector tags'
    },
    title: {
      required: false,
      type: String,
      default: '',
      note: 'tags title'
    },
    hasDeleteOptions: {
      required: false,
      type: Boolean,
      default: true,
      note: 'enable/disable delete option'
    },
    selectedTag: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      },
      note: 'Selected tag object'
    },
    backgroundClass: {
      type: String,
      require: false,
      default: 'bg-zinc-50',
      note: 'background color as tailwind color to hide border through the module name'
    }
  },
  emits: ['click', 'delete'],
  data: function data() {
    return {
      moduleTitles: getModuleTitles(),
      MODULES: MODULES
    };
  },
  computed: {
    icon: function icon() {
      switch (this.module) {
        case MODULES.EXECUTION:
          return {
            icon: 'check',
            class: 'ml-2'
          };
        case MODULES.INCIDENTS:
        case MODULES.CONTROLS:
        case MODULES.CONTRACTS:
          return 'confidential';
        default:
          return '';
      }
    },
    dataset: function dataset() {
      var _this = this;
      return this.tags.reduce(function (acc, tag) {
        if (!tag) return acc;
        var trafficLight = _this.getTrafficLight(tag);
        return [].concat(_toConsumableArray(acc), [_objectSpread(_objectSpread({}, tag), {}, {
          fullTitle: _this.getTitle(tag, _this.module),
          withLineThrough: _this.getLineThrough(tag),
          trafficLightColor: (trafficLight === null || trafficLight === void 0 ? void 0 : trafficLight.color) || '',
          trafficLightIcon: (trafficLight === null || trafficLight === void 0 ? void 0 : trafficLight.icon) || '',
          displayIcon: _this.getDisplayIcon(tag)
        })]);
      }, []);
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['findLightForModuleByNumber', 'getGapLight'])), {}, {
    getTitle: function getTitle(item, module) {
      if (!item) {
        return '';
      }
      if (module.substr(0, 4) === MODULES.RISK && module !== RiskAssessmentActivityLinksTab.value) {
        return "".concat(item.rno + '.' || '', " ").concat(item.title).trim();
      }
      switch (module) {
        case MODULES.CONTROLS:
          return "".concat(item.cpath, ". ").concat(item.title);
        case MODULES.EXECUTION:
          return "".concat(item.ipath, " ").concat(item.title);
        case RiskAssessmentActivityLinksTab.value:
          return this.getTitle(item, item.objType || module);
        default:
          return item.title || item.name;
      }
    },
    getTrafficLight: function getTrafficLight(item) {
      var _item$gapAnalysis;
      if ((_item$gapAnalysis = item.gapAnalysis) !== null && _item$gapAnalysis !== void 0 && _item$gapAnalysis.analysis_rag) {
        return this.getGapLight(item.gapAnalysis.analysis_rag);
      }
      if (item.trafficLightColor) {
        return {
          color: item.trafficLightColor,
          icon: ''
        };
      }
      if (item.objType === ObjectTypes.INCIDENTS_INCIDENT || item.objType === ObjectTypes.INCIDENTS_INCIDENT_LIMITED) {
        return this.findLightForModuleByNumber(this.module, item.severity);
      }
      if (item.objType.startsWith(ObjectTypes.COMPANY)) {
        return this.findLightForModuleByNumber(this.module, item.vatStatus);
      }
      var trafficLight = item.trafficLight || item.traffic_light || 0;
      return this.findLightForModuleByNumber(this.module, trafficLight);
    },
    getLineThrough: function getLineThrough(item) {
      return this.module === MODULES.CONTROLS && !item.active || this.module === MODULES.RISK && item.closed;
    },
    getDisplayIcon: function getDisplayIcon(tag) {
      switch (this.module) {
        case MODULES.EXECUTION:
          return tag.closed;
        case MODULES.INCIDENTS:
        case MODULES.CONTROLS:
        case MODULES.CONTRACTS:
          return tag.confidential;
        default:
          return false;
      }
    },
    checkSelectedTag: function checkSelectedTag(_ref) {
      var id = _ref.id,
        objType = _ref.objType,
        nodeId = _ref.nodeId,
        fullTitle = _ref.fullTitle;
      return (this.selectedTag.id !== undefined && this.selectedTag.id === id || this.selectedTag.nodeId !== undefined && this.selectedTag.nodeId === nodeId) && this.selectedTag.objType === objType && this.selectedTag.fullTitle === fullTitle;
    },
    selectTag: function selectTag(event, tag) {
      if (!this.checkSelectedTag(tag)) {
        this.$emit('click', tag, event);
      }
    }
  })
};