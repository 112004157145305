import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-500px mb-3"
};
var _hoisted_2 = {
  class: "w-500px mb-3"
};
var _hoisted_3 = {
  class: "w-500px mb-3"
};
var _hoisted_4 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_options_list = _resolveComponent("options-list");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [_cache[1] || (_cache[1] = _createElementVNode("h3", {
    class: "mb-1"
  }, "Regular options list", -1 /* HOISTED */)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_options_list, {
    "predefined-options": $data.predefinedOptions
  }, null, 8 /* PROPS */, ["predefined-options"])]), _cache[2] || (_cache[2] = _createElementVNode("h3", {
    class: "mb-1"
  }, "Options list draggable", -1 /* HOISTED */)), _createElementVNode("div", _hoisted_2, [_createVNode(_component_options_list, {
    "predefined-options": $data.predefinedOptions,
    "save-button-click-counter": $data.saveButtonClickCounter,
    draggable: true
  }, null, 8 /* PROPS */, ["predefined-options", "save-button-click-counter"])]), _cache[3] || (_cache[3] = _createElementVNode("h3", {
    class: "mb-1"
  }, "Options list with enabled unique/empty fields errors", -1 /* HOISTED */)), _createElementVNode("div", _hoisted_3, [_createVNode(_component_options_list, {
    "predefined-options": $data.predefinedOptions,
    "save-button-click-counter": $data.saveButtonClickCounter,
    "show-unique-fields-error": true,
    "show-empty-fields-error": true
  }, null, 8 /* PROPS */, ["predefined-options", "save-button-click-counter"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_button, {
    text: 'Click me to show errors on newly created fields',
    type: "save",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.saveButtonClickCounter++;
    })
  })])]);
}