import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "net-gross"
};
var _hoisted_2 = {
  key: 0,
  class: "flex frame"
};
var _hoisted_3 = {
  key: 0,
  class: "ml-2"
};
var _hoisted_4 = {
  class: "w-230px mr-6 ml-auto"
};
var _hoisted_5 = {
  class: "w-230px"
};
var _hoisted_6 = {
  key: 1,
  class: "flex frame"
};
var _hoisted_7 = {
  key: 0,
  class: "ml-2"
};
var _hoisted_8 = {
  class: "w-230px mr-6 ml-auto"
};
var _hoisted_9 = {
  class: "w-230px"
};
var _hoisted_10 = {
  class: "frame"
};
var _hoisted_11 = {
  class: "tolerance-gap-table"
};
var _hoisted_12 = {
  key: 0
};
var _hoisted_13 = {
  class: "text-center"
};
var _hoisted_14 = {
  class: "text-center"
};
var _hoisted_15 = {
  class: "text-center"
};
var _hoisted_16 = {
  class: "text-center"
};
var _hoisted_17 = {
  class: "text-center"
};
var _hoisted_18 = {
  class: "text-center"
};
var _hoisted_19 = {
  key: 1
};
var _hoisted_20 = {
  class: "text-right"
};
var _hoisted_21 = {
  class: "text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.riskProduct,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), $options.riskCalculationsEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.grossValues,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), $data.data.grossUpdated ? (_openBlock(), _createElementBlock("span", _hoisted_3, "(" + _toDisplayString($options.grossUpdatedUTCToLocal) + ")", 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
    modelValue: $data.localData.grossLikelihood,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $data.localData.grossLikelihood = $event;
    }), $options.update],
    title: $options.likelihoodLabel,
    options: $options.likelihoodOptions,
    disabled: $props.readOnly,
    "un-sorted": true,
    placeholder: $data.translate.choose
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "disabled", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_single_select, {
    modelValue: $data.localData.grossImpact,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
      return $data.localData.grossImpact = $event;
    }), $options.update],
    title: $options.impactLabel,
    options: $options.impactOptions,
    disabled: $props.readOnly,
    "un-sorted": true,
    placeholder: $data.translate.choose
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "disabled", "placeholder", "onUpdate:modelValue"])])])) : _createCommentVNode("v-if", true), $options.riskCalculationsEnabled && !$options.hideTolerance ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_risma_title, {
    title: $data.translate.toleranceValues,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), $data.data.toleranceUpdated ? (_openBlock(), _createElementBlock("span", _hoisted_7, " (" + _toDisplayString($options.toleranceUpdatedUTCToLocal) + ") ", 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_8, [_createVNode(_component_single_select, {
    modelValue: $data.localData.toleranceLikelihood,
    "onUpdate:modelValue": [_cache[2] || (_cache[2] = function ($event) {
      return $data.localData.toleranceLikelihood = $event;
    }), $options.update],
    title: $options.likelihoodLabel,
    options: $options.likelihoodOptions,
    disabled: $props.readOnly,
    "un-sorted": true,
    placeholder: $data.translate.choose
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "disabled", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_single_select, {
    modelValue: $data.localData.toleranceImpact,
    "onUpdate:modelValue": [_cache[3] || (_cache[3] = function ($event) {
      return $data.localData.toleranceImpact = $event;
    }), $options.update],
    title: $options.impactLabel,
    options: $options.impactOptions,
    disabled: $props.readOnly,
    "un-sorted": true,
    placeholder: $data.translate.choose
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "disabled", "placeholder", "onUpdate:modelValue"])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_10, [_createVNode(_component_risma_title, {
    title: $options.hideTolerance ? _ctx.riskDegreeTitle : $data.translate.toleranceGap,
    type: "medium",
    class: "absolute"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("table", _hoisted_11, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_cache[4] || (_cache[4] = _createElementVNode("th", null, null, -1 /* HOISTED */)), _createElementVNode("th", null, [_createVNode(_component_risma_title, {
    title: $options.likelihoodLabel,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", null, [_createVNode(_component_risma_title, {
    title: $options.impactLabel,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", null, [_createVNode(_component_risma_title, {
    title: _ctx.riskDegreeTitle,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])])])]), _createElementVNode("tbody", null, [$options.riskCalculationsEnabled && !$options.hideTolerance ? (_openBlock(), _createElementBlock("tr", _hoisted_12, [_createElementVNode("td", null, [_createVNode(_component_risma_title, {
    title: $data.translate.tolerance + $options.formulaExplanationTolerance,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("td", _hoisted_13, _toDisplayString($data.data.toleranceLikelihood || 0), 1 /* TEXT */), _createElementVNode("td", _hoisted_14, _toDisplayString($data.data.toleranceImpact || 0), 1 /* TEXT */), _createElementVNode("td", _hoisted_15, _toDisplayString($data.data.toleranceCalculation), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _createElementVNode("tr", null, [_createElementVNode("td", null, [_createVNode(_component_risma_title, {
    title: $data.translate.riskExposure + $options.formulaExplanationExposure,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("td", _hoisted_16, _toDisplayString($data.data.likelihood_score || 0), 1 /* TEXT */), _createElementVNode("td", _hoisted_17, _toDisplayString($data.data.impact_score || 0), 1 /* TEXT */), _createElementVNode("td", _hoisted_18, _toDisplayString($data.data.rpi), 1 /* TEXT */)]), $options.riskCalculationsEnabled && !$options.hideTolerance ? (_openBlock(), _createElementBlock("tr", _hoisted_19, [_cache[5] || (_cache[5] = _createElementVNode("td", null, null, -1 /* HOISTED */)), _cache[6] || (_cache[6] = _createElementVNode("td", null, null, -1 /* HOISTED */)), _createElementVNode("td", _hoisted_20, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.riskGap, ":"),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("td", _hoisted_21, _toDisplayString($data.data.riskGap), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)])]), $options.riskCalculationsEnabled ? (_openBlock(), _createBlock(_component_tiny_mce_box, {
    key: 0,
    type: "comment",
    height: "120px",
    "initial-content": $data.localData.calculationsComment || '',
    "read-only": $props.readOnly,
    "always-open": $options.keepCommentFieldsExpanded,
    onChanged: $options.onCommentChanged
  }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open", "onChanged"])) : _createCommentVNode("v-if", true)])]);
}