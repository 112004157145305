function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import { useUserStore } from '@/Store/userStore';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
import { logReport as _logReport } from '@/api/reports/reports';
import { getDataflowReport } from '@/api/compliance/reports/dataflows';
import * as API from '@/api/risma/data';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import HierarchicalList from '@/components/Molecules/HierarchicalList';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { IATitleMixin } from '@/mixins/IATitleMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { getHierarchicalList, buildHierarchicalStructure } from '@/utils/format/hierarchicalList';
import { filterAsset } from '@/utils/format/objectsFromIds';
import { mapToCommaList } from '@/utils/format/mapTo';
import { getDataflowStakeholdersTitle } from '@/utils/TitleFromSetting';
import { MODULES } from '@/constants/modules';
import { SortDirection } from '@/constants/SortDirection';
import { UserLevels } from '@/constants/UserLevels';
import { ReportTypes } from '@/constants/reports';
export default {
  name: 'DataflowReport',
  description: 'Compliance report to handle search in Dataflow.',
  token: '<dataflow-report />',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaDatatable: RismaDatatable,
    HierarchicalList: HierarchicalList,
    SaveReport: SaveReport
  },
  mixins: [RismaDatatableDefaultMixin, IATitleMixin, SaveReportMixin],
  props: {
    projectId: {
      required: true,
      type: [String, Number],
      note: 'ID of current project'
    }
  },
  data: function data() {
    return {
      order: [{
        index: 'process',
        dir: SortDirection.ASC
      }],
      organisations: [],
      nodes: null,
      isLoaded: false,
      dataset: [],
      decoupleFilterFields: [{
        key: 'informationAssets'
      }, {
        key: 'organisation'
      }],
      reportType: ReportTypes.COMPLIANCE_DATAFLOW,
      stateLocal: "report_dataflow_state_".concat(this.projectId),
      translate: getTranslate['ReportDataflow']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useTagsStore, ['tags'])), mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserAccessLevelByProjectId: 'complianceAccessLevelByProjectId'
  })), {}, {
    currentLocation: function currentLocation() {
      var currentProject = this.complianceProjectById(this.projectId);
      return [currentProject.title, 'Reports', 'Data flow'];
    },
    stakeholdersTitle: function stakeholdersTitle() {
      return getDataflowStakeholdersTitle();
    },
    columns: function columns() {
      return {
        stakeholder: this.stakeholdersTitle,
        direction: this.translate.direction,
        dataType: this.translate.dataType,
        legalBasis: this.translate.legalBasis,
        storagePeriod: this.translate.storagePeriod,
        informationAssets: this.informationAssetsTitle(),
        systemsTitle: "".concat(this.informationAssetsTitle(), " ").concat(this.translate.title),
        comments: this.translate.comments,
        process: this.translate.process,
        organisation: this.translate.organisation,
        tags: this.translate.tags
      };
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useComplianceProjectsStore, {
    complianceProjectById: 'getProjectById'
  })), {}, {
    getData: function getData() {
      Promise.all([this.getReportData(), this.fetchOrganisations()]).then(this.createTable);
    },
    logReport: function logReport(count) {
      var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      return _logReport(MODULES.COMPLIANCE, count, this.currentLocation, {}, format);
    },
    createTable: function createTable() {
      this.dataset = this.preSortDataset(this.prepareDataset(this.nodes));
      this.enhanceDataset(this.dataset, this.columns);
      this.isLoaded = true;
      this.loadSavedReport();
    },
    prepareDataset: function prepareDataset(data) {
      var _this = this;
      var tmpArr = [];
      data.forEach(function (node) {
        node.dataFlows.forEach(function (dataFlow) {
          dataFlow.dataTypes.forEach(function (dataType) {
            tmpArr.push(_this.extractData(dataType, dataFlow, node));
          });
        });
      });
      return tmpArr;
    },
    preSortDataset: function preSortDataset(dataset) {
      return _toConsumableArray(dataset).sort(function (a, b) {
        var _a$stakeholder, _b$stakeholder, _a$stakeholder2, _b$stakeholder2, _a$dataType, _b$dataType, _a$dataType2, _b$dataType2;
        if (((_a$stakeholder = a.stakeholder) === null || _a$stakeholder === void 0 ? void 0 : _a$stakeholder.field) < ((_b$stakeholder = b.stakeholder) === null || _b$stakeholder === void 0 ? void 0 : _b$stakeholder.field)) return -1;
        if (((_a$stakeholder2 = a.stakeholder) === null || _a$stakeholder2 === void 0 ? void 0 : _a$stakeholder2.field) > ((_b$stakeholder2 = b.stakeholder) === null || _b$stakeholder2 === void 0 ? void 0 : _b$stakeholder2.field)) return 1;
        if (((_a$dataType = a.dataType) === null || _a$dataType === void 0 ? void 0 : _a$dataType.field) < ((_b$dataType = b.dataType) === null || _b$dataType === void 0 ? void 0 : _b$dataType.field)) return -1;
        if (((_a$dataType2 = a.dataType) === null || _a$dataType2 === void 0 ? void 0 : _a$dataType2.field) > ((_b$dataType2 = b.dataType) === null || _b$dataType2 === void 0 ? void 0 : _b$dataType2.field)) return 1;
        return 0;
      });
    },
    getReportData: function getReportData() {
      var _this2 = this;
      return getDataflowReport(this.projectId).then(function (_ref) {
        var list = _ref.list;
        _this2.nodes = list;
      });
    },
    fetchOrganisations: function fetchOrganisations() {
      var _this3 = this;
      var filterByUserAccess = this.currentUserAccessLevelByProjectId(this.projectId) && this.currentUserAccessLevelByProjectId(this.projectId) < UserLevels.SUPER;
      return API.getOrganisationsAndFilterByUserAccess(filterByUserAccess).then(function (response) {
        return _this3.organisations = response.list;
      });
    },
    lookupOrganisation: function lookupOrganisation(id) {
      var org = this.organisations.find(function (item) {
        return item.id === parseInt(id);
      });
      return org ? org.visible_name : '';
    },
    extractData: function extractData(dataType, dataFlow, node) {
      var _dataType$legalBasisO,
        _dataType$storagePeri,
        _this4 = this;
      return {
        stakeholder: {
          field: dataFlow.stakeholder.text
        },
        direction: {
          field: dataFlow.direction === 'deliver' ? this.translate.deliversData : this.translate.receivesData
        },
        dataType: {
          field: dataType.text
        },
        legalBasis: {
          field: (_dataType$legalBasisO = dataType.legalBasisOptionLabel) !== null && _dataType$legalBasisO !== void 0 ? _dataType$legalBasisO : ''
        },
        storagePeriod: {
          field: (_dataType$storagePeri = dataType.storagePeriodOptionLabel) !== null && _dataType$storagePeri !== void 0 ? _dataType$storagePeri : ''
        },
        informationAssets: {
          field: dataFlow.systems.map(function (item) {
            return item.text;
          }).join(', ')
        },
        systemsTitle: {
          field: dataFlow.systemsTitle !== null && dataFlow.systemsTitle !== '' ? dataFlow.systemsTitle : this.translate.systems
        },
        comments: {
          field: dataFlow.comments
        },
        process: {
          field: {
            node: buildHierarchicalStructure(node)
          },
          fieldText: getHierarchicalList(node)
        },
        organisation: {
          field: node.organisationIds.map(function (item) {
            return _this4.lookupOrganisation(item);
          }).join(', ')
        },
        tags: {
          field: this.getTagsNames(this.tags, node.tagIds)
        }
      };
    },
    getTagsNames: function getTagsNames(tags, ids) {
      return mapToCommaList(filterAsset(tags, ids), 'tag');
    }
  })
};