function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { getFunctions as _getFunctions, addFunction as _addFunction, editFunction as _editFunction, deleteFunction as _deleteFunction, getSelectBoxOptions } from '@/api/admin/companies';
import CompanyFunctionModal from '@/components/Organisms/Companies/CompanyFunctionModal';
import StaticTable from '@/components/Molecules/StaticTable';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { deCapitalize } from '@/utils/capitalize';
import { formatTimeToDateFormat } from '@/utils/date';
import { getFunctionIdentifier } from '@/utils/format/companies';
var SELECTBOX_OPTIONS_NAMES = ['CriticalOrImportant', 'ImpactOfDiscontinuation', 'LicensedActivities'];
export default {
  name: 'CompanyFunctions',
  components: {
    CompanyFunctionModal: CompanyFunctionModal,
    StaticTable: StaticTable,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: function _default() {
        return {};
      }
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['validationError'],
  data: function data() {
    return {
      isLoaded: false,
      functions: [],
      showFunctionModal: false,
      functionToEdit: {},
      selectBoxOptions: {
        criticalOrImportant: [],
        licensedActivities: [],
        impactOfDiscontinuation: []
      },
      columnsMaxWidth: {
        name: '400px',
        delete: '30px'
      },
      translate: getTranslate['CompanyFunctions']()
    };
  },
  computed: {
    columns: function columns() {
      var list = [this.translate.identifier, this.translate.name, this.translate.licensedActivity, this.translate.criticalOrImportant, this.translate.reasonForCriticality, this.translate.lastAssessmentDate, this.translate.rto, this.translate.rpo, this.translate.impactOfDiscontinuing];
      if (!this.readOnly) {
        list.push('');
      }
      return list;
    },
    hoverTitles: function hoverTitles() {
      return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, this.translate.identifier, this.translate.identifier + ' (06.01.0010)'), this.translate.name, this.translate.name + ' (06.01.0030)'), this.translate.licensedActivity, this.translate.licensedActivity + ' (06.01.0020)'), this.translate.criticalOrImportant, this.translate.criticalOrImportant + ' (06.01.0050)'), this.translate.reasonForCriticality, this.translate.reasonForCriticality + ' (06.01.0060)'), this.translate.lastAssessmentDate, this.translate.lastAssessmentDate + ' (06.01.0070)'), this.translate.rto, this.translate.rto + ' (06.01.0080)'), this.translate.rpo, this.translate.rpo + ' (06.01.0090)'), this.translate.impactOfDiscontinuing, this.translate.impactOfDiscontinuing + ' (06.01.0100)');
    },
    dataset: function dataset() {
      var _this = this;
      return this.functions.map(function (item) {
        var licensedActivity = item.licensedActivities[0];
        var licensedActivityText = _this.selectBoxOptionsById.licensedActivities[licensedActivity === null || licensedActivity === void 0 ? void 0 : licensedActivity.licensedActivity];
        var functionIdentifier = licensedActivity === null || licensedActivity === void 0 ? void 0 : licensedActivity.functionIdentifier;
        var data = {
          identifier: getFunctionIdentifier(functionIdentifier),
          name: {
            name: item.name,
            id: item.id
          },
          licensedActivity: licensedActivityText,
          criticalOrImportant: _this.selectBoxOptionsById.criticalOrImportant[item.criticalOrImportant],
          reasonForCriticality: item.reasonForCriticality,
          dateOfAssessment: item.dateOfAssessment ? formatTimeToDateFormat(item.dateOfAssessment) : '',
          rto: item.rto,
          rpo: item.rpo,
          impactOfDiscontinuation: _this.selectBoxOptionsById.impactOfDiscontinuation[item.impactOfDiscontinuation]
        };
        if (!_this.readOnly) {
          data.delete = {
            id: item.id
          };
        }
        return data;
      });
    },
    selectBoxOptionsById: function selectBoxOptionsById() {
      return Object.entries(this.selectBoxOptions).reduce(function (acc, _ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
          key = _ref3[0],
          value = _ref3[1];
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, key, Object.fromEntries(value.map(function (_ref4) {
          var value = _ref4.value,
            label = _ref4.label;
          return [value, label];
        }))));
      }, {});
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      return Promise.all([this.getFunctions()].concat(_toConsumableArray(this.getSelectboxOptions()))).catch(function (error) {
        return _this2.handleError(error);
      }).finally(function () {
        return _this2.isLoaded = true;
      });
    },
    getFunctions: function getFunctions() {
      var _this3 = this;
      return _getFunctions(this.data.id).then(function (_ref5) {
        var list = _ref5.list;
        return _this3.functions = list;
      });
    },
    getSelectboxOptions: function getSelectboxOptions() {
      var _this4 = this;
      return SELECTBOX_OPTIONS_NAMES.map(function (name) {
        return getSelectBoxOptions(name).then(function (_ref6) {
          var list = _ref6.list;
          _this4.selectBoxOptions[deCapitalize(name)] = list;
        });
      });
    },
    addFunction: function addFunction(data) {
      var _this5 = this;
      return _addFunction(this.data.id, data).then(function (response) {
        _this5.functions.push(response);
        _this5.toggleShowFunctionModal();
      }).catch(function (error) {
        return _this5.handleError(error);
      });
    },
    editFunction: function editFunction(data) {
      var _this6 = this;
      return _editFunction(this.data.id, data).then(function (f) {
        var index = _this6.functions.findIndex(function (_ref7) {
          var id = _ref7.id;
          return +id === +f.id;
        });
        if (index !== -1) {
          _this6.functions[index] = f;
        }
        _this6.toggleShowFunctionModal();
      }).catch(function (error) {
        return _this6.handleError(error);
      });
    },
    deleteFunction: function deleteFunction(functionId) {
      var _this7 = this;
      return _deleteFunction(this.data.id, functionId).then(function () {
        _this7.functions = _this7.functions.filter(function (_ref8) {
          var id = _ref8.id;
          return +id !== +functionId;
        });
      }).catch(function (error) {
        return _this7.handleError(error);
      });
    },
    handleAddFunction: function handleAddFunction() {
      this.functionToEdit = {};
      this.toggleShowFunctionModal();
    },
    handleEditFunction: function handleEditFunction(id) {
      var _func$licensedActivit;
      if (this.readOnly) return;
      var func = this.functions.find(function (f) {
        return +f.id === +id;
      });
      this.functionToEdit = _objectSpread(_objectSpread({}, func), {}, {
        licensedActivity: +((_func$licensedActivit = func.licensedActivities[0]) === null || _func$licensedActivit === void 0 ? void 0 : _func$licensedActivit.licensedActivity) || null
      });
      this.showFunctionModal = true;
    },
    handleDeleteFunction: function handleDeleteFunction(id) {
      var _this8 = this;
      var functionToDelete = this.functions.find(function (f) {
        return +f.id === +id;
      });
      this.$confirm(this.translate.areYouSure, "".concat(this.translate.deleteFunction, " ").concat(functionToDelete.name, "?"), function (res) {
        return res && _this8.deleteFunction(id);
      }, {
        buttonOkText: this.translate.delete
      });
    },
    handleSaveFunction: function handleSaveFunction(data) {
      var _data$criticalOrImpor, _data$impactOfDiscont, _data$dateOfAssessmen;
      var functionData = _objectSpread(_objectSpread({}, data), {}, {
        criticalOrImportant: (_data$criticalOrImpor = data.criticalOrImportant) !== null && _data$criticalOrImpor !== void 0 ? _data$criticalOrImpor : 0,
        impactOfDiscontinuation: (_data$impactOfDiscont = data.impactOfDiscontinuation) !== null && _data$impactOfDiscont !== void 0 ? _data$impactOfDiscont : 0,
        dateOfAssessment: (_data$dateOfAssessmen = data.dateOfAssessment) !== null && _data$dateOfAssessmen !== void 0 ? _data$dateOfAssessmen : '',
        licensedActivities: data.licensedActivity !== null && data.licensedActivity !== undefined ? [data.licensedActivity] : []
      });
      if (!functionData.id) {
        this.addFunction(functionData);
        return;
      }
      this.editFunction(functionData);
    },
    toggleShowFunctionModal: function toggleShowFunctionModal() {
      this.showFunctionModal = !this.showFunctionModal;
    },
    handleError: function handleError(error) {
      this.$emit('validationError', 'error', error.fullError.message);
    }
  }
};