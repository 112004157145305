var toggleStates = {
  FILE: 'file',
  LINK: 'link'
};
export default {
  name: 'PanelToggle',
  props: {
    modelValue: {
      required: true,
      type: String,
      default: toggleStates.FILE,
      validator: function validator(value) {
        return !value || Object.values(toggleStates).includes(value);
      }
    }
  },
  emits: ['update:modelValue'],
  data: function data() {
    return {
      toggleStates: toggleStates,
      selectedClasses: 'bg-rm-active-blue--10 text-rm-active-blue border-rm-active-blue',
      nonSelectedClasses: 'border-rm-grey text-rm-grey bg-rm-white hover:text-rm-text hover:border-rm-signal-grey-dark',
      defaultClasses: 'border py-1 px-2 cursor-pointer glob-h3',
      translate: {
        file: this.$trans('File'),
        link: this.$trans('Link')
      }
    };
  },
  mounted: function mounted() {
    if (!this.modelValue) this.$emit('update:modelValue', toggleStates.FILE);
  },
  methods: {
    select: function select(value) {
      if (this.modelValue === value) return;
      this.$emit('update:modelValue', value);
    }
  }
};