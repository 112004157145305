// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.performance-container[data-v-0b97ffa6] {
  margin-top: -105px;
  margin-bottom: -25px;
  margin-right: -24px;
  min-height: calc(100vh - 5rem);
}
[data-v-0b97ffa6]  .vertical-menu-wrapper {
  height: calc(100% - 55px);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
