function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import emitter from 'tiny-emitter/instance';
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useWorkflowStore } from '@/Store/workflowStore';
import { create } from '@/api/incident/incident';
import { getTranslate } from '@/components/Organisms/translate';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import TrafficLightSelector from '@/components/Organisms/Selectors/TrafficLightSelector';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import Notification from '@/components/Molecules/Notification';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import { MODULES } from '@/constants/modules';
import { getMissingRequiredFieldsLabels } from '@/utils/CustomFields';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import * as Utils from '@/utils/Utils';
export default {
  name: 'QuickCreateIncidentModal',
  token: '<quick-create-incident-modal/>',
  components: {
    Modal: Modal,
    InputField: InputField,
    SingleSelect: SingleSelect,
    TinyMceBox: TinyMceBox,
    DateTimePicker: DateTimePicker,
    TrafficLightSelector: TrafficLightSelector,
    ActivityCustomFields: ActivityCustomFields,
    Notification: Notification,
    FrameworksSelector: FrameworksSelector
  },
  emits: ['dismiss'],
  data: function data() {
    return {
      newIncident: {
        title: '',
        activityTypeId: null,
        description: '',
        incidentHappenedAt: null,
        severity: 0,
        frameworks: [],
        responsibleUserIds: [],
        customFieldValuesParsed: null
      },
      isCreateAttempted: false,
      MODULES: MODULES,
      translate: getTranslate['QuickCreateIncidentModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useActivityTypesStore, ['incidentActivityTypes'])), mapState(useFrameworksStore, ['enabledFrameworks'])), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useUserStore, ['currentUser'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useWorkflowStore, ['getWorkflow'])), {}, {
    currentWorkflow: function currentWorkflow() {
      return this.getWorkflow(MODULES.INCIDENTS, this.newIncident.activityTypeId);
    },
    currentStatus: function currentStatus() {
      var _this$currentWorkflow;
      var statuses = (_this$currentWorkflow = this.currentWorkflow) === null || _this$currentWorkflow === void 0 ? void 0 : _this$currentWorkflow.workflowStatuses;
      if (!statuses) return;
      return statuses.find(function (_ref) {
        var isStartStatus = _ref.isStartStatus;
        return isStartStatus;
      }) || statuses[0];
    },
    listOfHiddenFields: function listOfHiddenFields() {
      var _this$currentStatus;
      return (_this$currentStatus = this.currentStatus) === null || _this$currentStatus === void 0 || (_this$currentStatus = _this$currentStatus.fieldOptions) === null || _this$currentStatus === void 0 ? void 0 : _this$currentStatus.filter(function (_ref2) {
        var isHidden = _ref2.isHidden;
        return isHidden;
      }).map(function (_ref3) {
        var fieldId = _ref3.fieldId;
        return fieldId;
      });
    },
    listOfDisabledFields: function listOfDisabledFields() {
      var _this$currentStatus2;
      return (_this$currentStatus2 = this.currentStatus) === null || _this$currentStatus2 === void 0 || (_this$currentStatus2 = _this$currentStatus2.fieldOptions) === null || _this$currentStatus2 === void 0 ? void 0 : _this$currentStatus2.filter(function (_ref4) {
        var isLocked = _ref4.isLocked;
        return isLocked;
      }).map(function (_ref5) {
        var fieldId = _ref5.fieldId;
        return fieldId;
      });
    },
    showDescription: function showDescription() {
      return !this.selectedIncidentActivityType || this.selectedIncidentActivityType.optionalFields.includes('description');
    },
    showSeverity: function showSeverity() {
      return !this.selectedIncidentActivityType || this.selectedIncidentActivityType.optionalFields.includes('severity');
    },
    incidentActivityTypesOptions: function incidentActivityTypesOptions() {
      return this.incidentActivityTypes.map(function (activityType) {
        return _objectSpread(_objectSpread({}, activityType), {}, {
          value: activityType.id
        });
      });
    },
    selectedIncidentActivityType: function selectedIncidentActivityType() {
      var _this = this;
      return this.incidentActivityTypes.find(function (_ref6) {
        var id = _ref6.id;
        return id === _this.newIncident.activityTypeId;
      });
    },
    missingCustomFields: function missingCustomFields() {
      return getMissingRequiredFieldsLabels(this.selectedIncidentActivityType, this.newIncident.customFieldValuesParsed);
    },
    missingFields: function missingFields() {
      return {
        title: !this.newIncident.title,
        activityType: !this.newIncident.activityTypeId
      };
    },
    missingRequiredFieldsNames: function missingRequiredFieldsNames() {
      var _this2 = this;
      var missingFields = Object.entries(this.missingFields).filter(function (_ref7) {
        var _ref8 = _slicedToArray(_ref7, 2),
          value = _ref8[1];
        return value;
      }).map(function (_ref9) {
        var _ref10 = _slicedToArray(_ref9, 1),
          key = _ref10[0];
        return _this2.translate[key];
      });
      return [].concat(_toConsumableArray(missingFields), _toConsumableArray(this.missingCustomFields));
    },
    responsibleNotification: function responsibleNotification() {
      var _this$selectedInciden;
      var names = Utils.findPropsByIds((_this$selectedInciden = this.selectedIncidentActivityType) === null || _this$selectedInciden === void 0 ? void 0 : _this$selectedInciden.responsibleUsers, this.users, 'display_name');
      return this.translate.byCreatingThisIncident(names);
    }
  }),
  methods: {
    onActivityTypeSelect: function onActivityTypeSelect() {
      var _this3 = this;
      var framework = this.enabledFrameworks.find(function (_ref11) {
        var id = _ref11.id;
        return id === _this3.selectedIncidentActivityType.solutionId;
      });
      this.newIncident.solutionIds = framework ? [framework] : [];
      if (!this.showDescription) {
        this.newIncident.description = '';
      }
      if (!this.showSeverity) {
        this.newIncident.severity = 0;
      }
    },
    createIncident: function createIncident() {
      var _this$selectedInciden2,
        _this$selectedInciden3,
        _this4 = this;
      if (this.missingRequiredFieldsNames.length) {
        this.isCreateAttempted = true;
        return;
      }
      var data = _objectSpread(_objectSpread({}, this.newIncident), {}, {
        solutionIds: this.newIncident.solutionIds.map(function (_ref12) {
          var id = _ref12.id;
          return id;
        }),
        responsibleUserIds: (_this$selectedInciden2 = this.selectedIncidentActivityType) !== null && _this$selectedInciden2 !== void 0 && (_this$selectedInciden2 = _this$selectedInciden2.responsibleUsers) !== null && _this$selectedInciden2 !== void 0 && _this$selectedInciden2.length ? (_this$selectedInciden3 = this.selectedIncidentActivityType) === null || _this$selectedInciden3 === void 0 ? void 0 : _this$selectedInciden3.responsibleUsers : this.getSettingValue('feature.incidents.no_responsible') ? [] : [this.currentUser.id]
      });
      if (this.newIncident.customFieldValuesParsed) {
        data.customFieldValues = this.newIncident.customFieldValuesParsed;
      }
      this.dismissCreateModal();
      return create(data).then(function (newIncident) {
        emitter.emit('getIncidents');
        _this4.$notify({
          title: _this4.translate.yourActivityHasBeenCreated,
          activity: getFieldsFromObjectForPreview(newIncident),
          useCreateTimeout: true
        });
      }).catch(function (_ref13) {
        var response = _ref13.response;
        var errorMessage = _this4.translate.errorCreatingIncident;
        if (response.error) {
          errorMessage += ': ' + _this4.$trans(response.error);
        }
        _this4.$notify({
          title: errorMessage,
          type: 'error'
        });
      });
    },
    dismissCreateModal: function dismissCreateModal() {
      this.$emit('dismiss');
    }
  }
};