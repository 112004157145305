import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "erm-cf"
};
var _hoisted_2 = {
  class: "grid grid-cols-3 gap-4"
};
var _hoisted_3 = {
  class: "col-span-1"
};
var _hoisted_4 = {
  key: 0,
  class: "validation-error"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_custom_field_types = _resolveComponent("custom-field-types");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_input_field, {
    modelValue: $props.customField.label,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $props.customField.label = $event;
    }), _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('edit', {
        id: $props.customField.id,
        event: ['label', $event]
      });
    })],
    title: $data.translate.fieldLabel,
    enabled: !$props.readOnly
  }, null, 8 /* PROPS */, ["modelValue", "title", "enabled"]), $props.customField.errors && $props.customField.errors.label ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($data.translate.pleaseFillOutThisField), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createVNode(_component_custom_field_types, {
    class: "col-span-2 grid grid-cols-2 gap-4",
    "module-type": "risk",
    field: $props.customField,
    disabled: $props.readOnly,
    "read-only": $props.readOnly,
    "save-button-click-counter": $props.saveButtonClickCounter,
    onEdit: $options.handleUpdateCF
  }, null, 8 /* PROPS */, ["field", "disabled", "read-only", "save-button-click-counter", "onEdit"])]), _createVNode(_component_risma_button, {
    text: $data.translate.remove,
    class: "ml-auto inline-block",
    disabled: $props.readOnly,
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('delete');
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "x",
        class: "mr-2 text-orange-50"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "disabled"])]);
}