import i18n from '@/i18n';
export var getTranslate = {
  AuditFollowup: function AuditFollowup() {
    return {
      unansweredAudits: i18n.t('Unanswered audits'),
      thisIsTheListOfAllOpenUnanswered: i18n.t('This is the list of all open (i.e. unanswered) audits that are not yet expired.'),
      created: i18n.t('Created'),
      createdBy: i18n.t('Created by'),
      recipient: i18n.t('Recipient'),
      assets: i18n.t('Assets'),
      firstClick: i18n.t('First click'),
      deadline: i18n.t('Deadline')
    };
  },
  IAAnsweredProcessorAudits: function IAAnsweredProcessorAudits() {
    return {
      answeredAudits: i18n.t('Answered audits'),
      selectOneQuestionnaireAndThenOptionalFilte: i18n.t('Select one questionnaire and then (optional) filter the Processors with this questionnaire on an audit.'),
      selectAnAuditType: i18n.t('Select an audit type'),
      selectAQuestionnaire: i18n.t('Select a questionnaire'),
      selectProcessors: i18n.t('Select processors'),
      createReport: i18n.t('Create report'),
      nothingToShow: i18n.t('Nothing to show'),
      systems: i18n.t('Systems'),
      dataProcessors: i18n.t('Data Processors'),
      dataControllers: i18n.t('Data Controllers'),
      customAssets: i18n.t('Custom assets'),
      contracts: i18n.t('Contracts'),
      dataProcessor: i18n.t('Data Processor'),
      dataController: i18n.t('Data Controller'),
      system: i18n.t('System'),
      informationAsset: i18n.t('Information asset'),
      contract: i18n.t('Contract')
    };
  },
  QuestionnaireReport: function QuestionnaireReport() {
    return {
      incident: i18n.t('Incident'),
      questionnaireReport: i18n.t('Questionnaire report'),
      createReport: i18n.t('Create report'),
      nothingToShow: i18n.t('Nothing to show'),
      theSelectedSearchCriteriaHaveNoResultsToDi: i18n.t('The selected search criteria have no results to display'),
      dataProcessor: i18n.t('Data Processor'),
      dataController: i18n.t('Data Controller'),
      system: i18n.t('System'),
      informationAsset: i18n.t('Information asset'),
      startBySelectingIA: i18n.t("Start by selecting the information asset and the relevant questionnaire you want a report over. As an optional addition you can choose to create a questionnaire report for specific assets."),
      startBySelecting: i18n.t("First choose questionnaire and then questions from the list to show report"),
      contract: i18n.t('Contract'),
      risk: i18n.t('Risks')
    };
  },
  QuestionnaireSelection: function QuestionnaireSelection() {
    return {
      responsible: i18n.t('Responsible'),
      organisation: i18n.t('Organisation'),
      type: i18n.t('type'),
      chooseTypes: i18n.t('Choose types'),
      questionnaire: i18n.t('Questionnaire'),
      selectAQuestionnaire: i18n.t('Select a questionnaire'),
      selectSpecificAssets: i18n.t('Select specific assets'),
      selectResponsible: i18n.t('Select responsible'),
      selectOrganisations: i18n.t('Select organisations'),
      systems: i18n.t('Systems'),
      dataProcessors: i18n.t('Data processors'),
      dataControllers: i18n.t('Data controllers'),
      customAssets: i18n.t('Custom assets')
    };
  }
};