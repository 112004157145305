import i18n from '@/i18n';
export var getTranslate = {
  AdSettings: function AdSettings() {
    return {
      enterAValidEndpoint: i18n.t('Enter a valid endpoint'),
      enterAValidClientId: i18n.t('Enter a valid client id'),
      enterAValidEmailMapping: i18n.t('Enter a valid e-mail mapping'),
      settingsAreSaved: i18n.t('Settings are saved'),
      activateAdLogin: i18n.t('Activate AD login'),
      useSecurityGroups: i18n.t('Use security groups'),
      domainName: i18n.t('Domain name'),
      enterADomainName: i18n.t('Enter a domain name'),
      endpoint: i18n.t('Endpoint'),
      enterAnEndpoint: i18n.t('Enter an endpoint'),
      clientId: i18n.t('Client id'),
      enterAClientId: i18n.t('Enter a client id'),
      advancedEmailMapping: i18n.t('Advanced: e-mail mapping'),
      enterAnEmailMappingSetToUpnForDefault: i18n.t('Enter an e-mail mapping. Set to upn for default'),
      update: i18n.t('Update'),
      clientUrlForAzureAdShouldBeFormat: i18n.t('Client url for Azure AD should be format https://sts.windows.net/UUID/'),
      theEnteredSecretHasTheFormatOfAnObjectId: i18n.t('The entered secret has the format of an Object ID. Please re-check the value'),
      somethingWentWrongWhileUploading: i18n.t('Something went wrong while uploading')
    };
  },
  ProjectQuestionnaireGapSelector: function ProjectQuestionnaireGapSelector() {
    return {
      chooseQuestionnaireType: i18n.t('Choose questionnaire type'),
      chooseQuestionnaire: i18n.t('Choose questionnaire'),
      chooseGapSchema: i18n.t('Choose gap schema'),
      mode: i18n.t('Mode'),
      text: i18n.t('Text'),
      form: i18n.t('Form')
    };
  },
  UserSynchronisation: function UserSynchronisation() {
    return {
      doNothing: i18n.t('Do nothing'),
      deactivateKeepUserData: i18n.t('Deactivate and keep user data'),
      deactivateAnonymizeAllUserDataExceptUserInitials: i18n.t('Deactivate and anonymize all user data except user initials'),
      deactivateAnonymizeAllUserData: i18n.t('Deactivate and anonymize all user data'),
      saveSettings: i18n.t('Save settings'),
      settingsAreSaved: i18n.t('Settings are saved'),
      somethingWentWrongWhileUploading: i18n.t('Something went wrong while uploading'),
      synchroniseViaMicrosoftGraph: i18n.t('Synchronise via Microsoft Graph'),
      whenTheUserHasBeenDisabledRemotely: i18n.t('When the user has been disabled remotely…'),
      userCreation: i18n.t('User Creation'),
      createAllUsers: i18n.t('Create all users'),
      createUsersAssignedToSecurityGroupsConfiguredInRisma: i18n.t('Create users assigned to security groups configured in Risma'),
      dontCreateUsers: i18n.t("Don't create users"),
      connectionOk: i18n.t('Connection ok'),
      activateUserSynchronisation: i18n.t('Activate automatic synchronisation'),
      synchronisationRequires: i18n.t('The user synchronisation requires Tenant ID, Client ID and Client Secret to be configured'),
      testConnection: i18n.t('Test connection'),
      created: i18n.t('Created'),
      updated: i18n.t('Updated'),
      deactivated: i18n.t('Deactivated'),
      users: i18n.t('Users'),
      user: i18n.t('User'),
      warnings: i18n.t('Warnings'),
      errors: i18n.t('Errors'),
      existing: i18n.t('Existing'),
      theSynchronisationWillTakePlace: i18n.t('The synchronisation will take place once per day. When this happens the listed users below will respectively be created, updated or deactivated'),
      connectionSuccessFulButNoChange: i18n.t('The connection was successful. No users were found. Please review your settings')
    };
  },
  SecurityGroupsCreationModal: function SecurityGroupsCreationModal() {
    return {
      create: i18n.t('Create'),
      objectId: i18n.t('Object Id'),
      name: i18n.t('Name'),
      errorMessageRequired: i18n.t('Please fill both fields correctly'),
      admin: i18n.t('Admin'),
      controls: i18n.t('Controls'),
      actions: i18n.t('Actions'),
      incidents: i18n.t('Incidents'),
      risks: i18n.t('Risks'),
      contracts: i18n.t('Contracts'),
      companies: i18n.t('Companies'),
      lockedFields: i18n.t('Locked fields'),
      createSecurityGroup: i18n.t('Create') + ' ' + i18n.t('Security group').toLowerCase(),
      pleaseFillOutThisField: i18n.t('Please fill out this field.')
    };
  }
};