import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap pt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_text_box = _resolveComponent("text-box");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_text_box, {
    text: "Here are some lines of text",
    class: "mb-10",
    placeholder: "Placeholder text"
  }), _createVNode(_component_text_box, {
    text: "Here are some lines of text",
    class: "w-900px mb-10",
    label: "TextBox label. Width 900px. Counter limit 500. Resize vertical",
    "max-length": 500,
    placeholder: "Placeholder text"
  }), _createVNode(_component_text_box, {
    text: $data.text,
    class: "w-500px mb-10",
    label: "Width 500px. Counter limit 1000. Resize horizontal. Error",
    error: $data.text.length ? '' : 'Enter a value.',
    "max-length": 1000,
    resize: $data.ResizeOptions.X,
    placeholder: "Placeholder text",
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return $data.text = $event;
    })
  }, null, 8 /* PROPS */, ["text", "error", "resize"]), _createVNode(_component_text_box, {
    text: "Here are some lines of text",
    class: "w-400px mb-10",
    label: "Width 400px. Resize horizontal & vertical",
    resize: $data.ResizeOptions.RESIZE,
    placeholder: "Placeholder text"
  }, null, 8 /* PROPS */, ["resize"]), _createVNode(_component_text_box, {
    disabled: true,
    class: "w-500px mb-10",
    text: "This text-box is read-only\n        but still has text"
  })]);
}