function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import * as riskAPI from '@/api/risk/risk';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import RiskGrid from '@/components/Organisms/RiskGrid';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { ReportTypes } from '@/constants/reports';
import { RiskReportsMixin } from '@/mixins/RiskReportsMixin';
import { RiskGridMixin } from '@/mixins/RiskGridMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
export default {
  name: 'RiskReportsThreats',
  description: 'Threats tab for risk2 projects',
  token: '<risk-reports-threats />',
  components: {
    RismaDatatable: RismaDatatable,
    SingleSelect: SingleSelect,
    RiskGrid: RiskGrid,
    PreviewModalLink: PreviewModalLink,
    SaveReport: SaveReport
  },
  mixins: [RiskReportsMixin, RismaDatatableDefaultMixin, SaveReportMixin, RiskGridMixin],
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Id of the Risk Project'
    }
  },
  data: function data() {
    return {
      datasetBig: [],
      period: {},
      periodSelected: {},
      threats: [],
      consequenceId: '',
      gridBoxSize: 80,
      arrowWidth: 220,
      threatColor: 'black',
      translate: getTranslate['RiskReportsThreats'](),
      reportType: ReportTypes.RISK_THREATS,
      stateLocal: "".concat(ReportTypes.RISK_THREATS, "-").concat(this.projectId),
      activitySlotNames: ['risks', 'initiatives', 'controls', 'systems'],
      currentRiskProject: null
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    columnsBig: function columnsBig() {
      return _objectSpread(_objectSpread({
        threat: this.translate.threat,
        risks: this.translate.risks
      }, this.systemColumn), {}, {
        initiatives: this.translate.actions,
        controls: this.translate.controls
      });
    },
    consequenceOptions: function consequenceOptions() {
      return this.period.consequenceRows && this.period.consequenceRows.map(function (item) {
        return {
          label: item.label,
          value: item.id
        };
      });
    },
    consequenceSelected: function consequenceSelected() {
      var _this = this;
      return this.period.consequenceRows && this.period.consequenceRows.find(function (item) {
        return item.id === _this.consequenceId;
      });
    },
    matrix: function matrix() {
      var data = this.getMatrix(this.dots);
      data.colorCodes = _defineProperty({}, this.threatColor, 'background-color: #000');
      return {
        title: this.translate.threats,
        data: data
      };
    },
    isErm: function isErm() {
      return false;
    },
    dots: function dots() {
      var _this2 = this;
      if (!this.consequenceSelected) {
        return [];
      }
      var dots = [];
      this.threats.forEach(function (threat) {
        if (threat.assessments) {
          var assessment = threat.assessments.find(function (assessment) {
            return assessment.label === _this2.consequenceSelected.label;
          });
          if (assessment) {
            dots.push(_this2.getThreatDotValue(threat, assessment, _this2.threatColor));
          }
        }
      });
      return dots;
    },
    customHeatmapOptions: function customHeatmapOptions() {
      var _this$currentRiskProj;
      return (_this$currentRiskProj = this.currentRiskProject) !== null && _this$currentRiskProj !== void 0 && _this$currentRiskProj.customHeatmapEnabled ? this.currentRiskProject.heatmapOptions : [];
    }
  }),
  mounted: function mounted() {
    var _this3 = this;
    Promise.all([this.handleGetThreat(this.projectId).then(function (response) {
      _this3.threats = response.list;
      _this3.risks = response.risks || [];
      return _this3.getLinkedAssetsAndActivities();
    }).then(function (response) {
      _this3.handleGetLinkedAssetsAndActivities(response);
      _this3.datasetBig = _this3.formatDatasetBig(_this3.threats, _this3.risks, _this3.controls, _this3.initiatives, _this3.systems);
      _this3.enhanceDataset(_this3.datasetBig, _this3.columnsBig);
    }).catch(function (error) {
      throw new Error(error);
    }), this.getProjects()]).then(function () {
      if (_this3.consequenceOptions && _this3.consequenceOptions.length) {
        _this3.consequenceId = _this3.consequenceOptions[0].value;
      }
      _this3.isLoaded = true;
      _this3.loadSavedReport();
    }).finally(function () {
      return _this3.isLoaded = true;
    });
  },
  methods: {
    handleGetThreat: function handleGetThreat(projectId) {
      return new Promise(function (resolve, reject) {
        riskAPI.getReportsThreat(projectId).then(function (response) {
          return resolve(response);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    createEmptyGridFrom: function createEmptyGridFrom(n) {
      return Array(n).fill([]);
    },
    dotHoverFunction: function dotHoverFunction(dot) {
      return dot.title;
    },
    getProjects: function getProjects() {
      var _this4 = this;
      return riskAPI.getSingleRiskProject(this.projectId).then(function (project) {
        _this4.period = _this4.periodSelected = _this4.getActivePeriod(project);
        _this4.currentRiskProject = project;
      });
    },
    getActivePeriod: function getActivePeriod(project) {
      if (project) {
        return project.projectPeriods.find(function (period) {
          return period.id === project.activePeriodId;
        });
      }
      return {};
    },
    getThreatDotValue: function getThreatDotValue(threat, assessment, color) {
      return {
        id: threat.id,
        color: color,
        title: threat.displayHeadline,
        x: this.currentRiskProject.matrixInverted ? assessment.impact : assessment.likelihood,
        y: this.currentRiskProject.matrixInverted ? assessment.likelihood : assessment.impact,
        xBefore: 0,
        yBefore: 0
      };
    },
    formatDatasetBig: function formatDatasetBig(threats, risksAll, controlsAll, initiativesAll, systemsAll) {
      var _this5 = this;
      return threats.map(function (threat) {
        var risks = risksAll.filter(function (risk) {
          return risk.threatIds.includes(threat.id);
        });
        var risksFormatted = _this5.getFormattedRisks(risks);
        var controlsFormatted = _this5.getFormattedControls(risks, controlsAll);
        var initiativesFormatted = _this5.getFormattedInitiatives(risks, initiativesAll);
        return _objectSpread(_objectSpread({
          threat: {
            field: threat.displayHeadline
          },
          risks: risksFormatted
        }, _this5.getSystemsCell(risks, systemsAll)), {}, {
          initiatives: initiativesFormatted,
          controls: controlsFormatted
        });
      });
    }
  }
};