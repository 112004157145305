import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex justify-between mb-3"
};
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  var _component_company_function_modal = _resolveComponent("company-function-modal");
  return _openBlock(), _createElementBlock("div", null, [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.functions
  }, null, 8 /* PROPS */, ["title"]), !$props.readOnly ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "plus",
    height: "35",
    width: "35",
    class: "stroke-3 text-blue-350 cursor-pointer",
    onClick: $options.handleAddFunction
  }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]), _createVNode(_component_static_table, {
    columns: $options.columns,
    "column-header-hover-titles": $options.hoverTitles,
    "dataset-table": $options.dataset,
    "columns-max-width": $data.columnsMaxWidth
  }, {
    name: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createElementVNode("span", {
        class: _normalizeClass(["font-semibold", {
          'hover:underline cursor-pointer': !$props.readOnly
        }]),
        onClick: function onClick($event) {
          return $options.handleEditFunction(slotData.id);
        }
      }, _toDisplayString(slotData.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_2)];
    }),
    delete: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [_createElementVNode("div", null, [!$props.readOnly ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "x",
        class: "stroke-2 text-red-50 cursor-pointer",
        onClick: function onClick($event) {
          return $options.handleDeleteFunction(slotData.id);
        }
      }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columns", "column-header-hover-titles", "dataset-table", "columns-max-width"])], 64 /* STABLE_FRAGMENT */)), $data.showFunctionModal ? (_openBlock(), _createBlock(_component_company_function_modal, {
    key: 2,
    "function-data": $data.functionToEdit,
    "select-box-options": $data.selectBoxOptions,
    onDismiss: $options.toggleShowFunctionModal,
    onSave: $options.handleSaveFunction
  }, null, 8 /* PROPS */, ["function-data", "select-box-options", "onDismiss", "onSave"])) : _createCommentVNode("v-if", true)]);
}