import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "flex justify-between mb-4"
};
var _hoisted_2 = {
  class: "w-12/25"
};
var _hoisted_3 = {
  class: "flex justify-between mb-4"
};
var _hoisted_4 = {
  class: "w-12/25"
};
var _hoisted_5 = {
  class: "flex justify-between mb-4"
};
var _hoisted_6 = {
  class: "flex justify-between mb-4"
};
var _hoisted_7 = {
  class: "w-12/25"
};
var _hoisted_8 = {
  class: "flex justify-end"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $props.functionData.id ? $data.translate.editFunction : $data.translate.addFunction,
    "show-buttons": false,
    "dismiss-button-disabled": true,
    "dismiss-on-click-outside": true,
    onDismiss: _cache[11] || (_cache[11] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        modelValue: $props.functionData.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $props.functionData.name = $event;
        }),
        title: $data.translate.name + ' (06.01.0030)',
        placeholder: $data.translate.name,
        invalid: !!$data.errorMessages.name,
        "error-help-text": $data.errorMessages.name,
        type: "text",
        class: "w-12/25"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid", "error-help-text"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
        modelValue: $props.functionData.licensedActivity,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $props.functionData.licensedActivity = $event;
        }),
        title: $data.translate.licensedActivity + ' (06.01.0020)',
        placeholder: $data.translate.licensedActivity,
        options: $props.selectBoxOptions.licensedActivities
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options"])])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
        modelValue: $props.functionData.criticalOrImportant,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $props.functionData.criticalOrImportant = $event;
        }),
        title: $data.translate.criticalOrImportant + ' (06.01.0050)',
        placeholder: $data.translate.criticalOrImportant,
        options: $props.selectBoxOptions.criticalOrImportant
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options"])]), _createVNode(_component_input_field, {
        modelValue: $props.functionData.reasonForCriticality,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $props.functionData.reasonForCriticality = $event;
        }),
        title: $data.translate.reasonForCriticality + ' (06.01.0060)',
        placeholder: $data.translate.reasonForCriticality,
        type: "text",
        class: "w-12/25"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_date_time_picker, {
        modelValue: $props.functionData.dateOfAssessment,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $props.functionData.dateOfAssessment = $event;
        }),
        title: $data.translate.lastAssessmentDate + ' (06.01.0070)',
        "plugin-options": $data.datePickerOptions,
        "delete-enabled": true,
        class: "w-12/25"
      }, null, 8 /* PROPS */, ["modelValue", "title", "plugin-options"]), _createVNode(_component_input_field, {
        modelValue: $props.functionData.rto,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
          return $props.functionData.rto = $event;
        }),
        title: $data.translate.rtoInHours + ' (06.01.0080)',
        placeholder: $data.translate.rto,
        type: "number",
        "allowed-symbols-regex": "[0-9]",
        min: "0",
        class: "w-12/25",
        onBlur: _cache[6] || (_cache[6] = function ($event) {
          return $props.functionData.rto = $options.adjustMaxValue($event);
        })
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_input_field, {
        modelValue: $props.functionData.rpo,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
          return $props.functionData.rpo = $event;
        }),
        title: $data.translate.rpoInHours + ' (06.01.0090)',
        placeholder: $data.translate.rpo,
        type: "number",
        "allowed-symbols-regex": "[0-9]",
        min: "0",
        class: "w-12/25",
        onBlur: _cache[8] || (_cache[8] = function ($event) {
          return $props.functionData.rpo = $options.adjustMaxValue($event);
        })
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_single_select, {
        modelValue: $props.functionData.impactOfDiscontinuation,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
          return $props.functionData.impactOfDiscontinuation = $event;
        }),
        title: $data.translate.impactOfDiscontinuing + ' (06.01.0100)',
        placeholder: $data.translate.impactOfDiscontinuing,
        options: $props.selectBoxOptions.impactOfDiscontinuation
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options"])])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_button, {
        text: $data.translate.cancel,
        type: "util",
        class: "mr-3",
        onClick: _cache[10] || (_cache[10] = function ($event) {
          return _ctx.$emit('dismiss');
        })
      }, null, 8 /* PROPS */, ["text"]), _createVNode(_component_risma_button, {
        text: $data.translate.save,
        type: "save",
        onClick: $options.handleSaveFunction
      }, null, 8 /* PROPS */, ["text", "onClick"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"]);
}