function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { getTranslate } from './translate';
import { getInformationAssets } from '@/api/risma/data';
import { getAudit, deleteAudit as _deleteAudit } from '@/api/compliance/audit';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import DpmSendQuestionnaireModal from '@/components/Molecules/Modal/DpmSendQuestionnaireModal';
import RismaStepProgressBar from '@/components/Molecules/RismaStepProgressBar';
import DpmActionModal from '@/components/Pages/Tabs/DpmActionModal';
import questionnaire from '@/components/Organisms/Compliance/questionnaire';
import Notification from '@/components/Molecules/Notification';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { AuditGenericData } from '@/constants/AuditGenericData';
export default {
  name: 'AuditOverview',
  components: {
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    DpmSendQuestionnaireModal: DpmSendQuestionnaireModal,
    RismaStepProgressBar: RismaStepProgressBar,
    DpmActionModal: DpmActionModal,
    questionnaire: questionnaire,
    Notification: Notification,
    FeatherIcon: FeatherIcon
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    id: {
      type: [String, Number],
      required: true,
      note: 'id'
    },
    module: {
      type: String,
      required: true,
      note: 'module name'
    },
    idItem: {
      type: [String, Number],
      required: false,
      default: '',
      note: 'Data Processor Management id'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      isLoaded: false,
      allAudits: [],
      auditIdToDelete: null,
      progressData: {
        firstItem: null,
        secondItem: null,
        thirdItem: null
      },
      menuItems: [],
      currentAudit: {},
      notificationError: null,
      questions: null,
      informationAssets: null,
      showQuestionnaireModal: false,
      dpmActionType: 'extendDeadline',
      isModalOpened: false,
      translate: getTranslate['AuditOverview']()
    };
  },
  computed: {
    dataQuestionnaire: function dataQuestionnaire() {
      if (typeof this.data.questionnaire === 'undefined') {
        return [];
      }
      return this.data.questionnaire;
    },
    existingAudits: function existingAudits() {
      var _this = this;
      var result = [];
      this.allAudits.forEach(function (item) {
        var statusText = _this.translate.sent;
        if (item.firstClick) {
          statusText = _this.translate.questionnaireOpened;
        }
        if (item.answered) {
          statusText = _this.translate.questionnaireAnswered;
        }
        if (item.requestExpired) {
          statusText = _this.translate.deadlineExceeded;
        }
        var selectItem = {
          value: item.id,
          label: '<span class="glob-h3">' + item.questionnaire.title + '</span><br/>' + _this.translate.recipient + ': ' + item.fullname + '<br/>' + _this.translate.status + ': ' + statusText,
          requestExpired: item.requestExpired
        };
        result.push(selectItem);
      });
      return result;
    },
    genericData: function genericData() {
      return AuditGenericData[this.data.objType];
    },
    showActionPanel: function showActionPanel() {
      var _this$progressData$se, _this$progressData$th;
      return !this.data.archived && !this.readOnly && (((_this$progressData$se = this.progressData.secondItem) === null || _this$progressData$se === void 0 ? void 0 : _this$progressData$se.state) === 'declined' || ((_this$progressData$th = this.progressData.thirdItem) === null || _this$progressData$th === void 0 ? void 0 : _this$progressData$th.state) === 'approved');
    },
    dpmActionGroup: function dpmActionGroup() {
      return {
        extendDeadline: {
          header: this.translate.extendDeadlineForAudit,
          mainText: this.translate.hereYouCanExtendTheDeadlineForTheAuditAnd,
          accept: this.extendDeadline
        },
        askForMoreInfo: {
          header: this.translate.resubmitAuditForMoreInfo,
          mainText: this.translate.hereYouCanAskForMoreInfoRegardingTheAudit,
          accept: this.askForMoreInfo
        }
      };
    },
    modalQuestionnaires: function modalQuestionnaires() {
      if (this.data.auditQuestionnaires) {
        return this.data.auditQuestionnaires.map(function (q) {
          return {
            value: q.id,
            label: q.title
          };
        });
      }
      return [];
    },
    auditTitle: function auditTitle() {
      var result = this.data.name || this.data.title;
      if (this.genericData && this.genericData.titleSuffix) {
        result += ': ' + this.$trans(this.genericData.titleSuffix);
      }
      return result;
    }
  },
  watch: {
    idItem: {
      handler: function handler() {
        this.progressData = {
          firstItem: null,
          secondItem: null,
          thirdItem: null
        };
        this.goToFirstIfIdNotDefined();
        this.idItem && this.loadAudit(+this.idItem);
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    var promises = [this.getAllRequests(), this.getAllInformationAssets()];
    Promise.all(promises).then(function () {
      _this2.goToFirstIfIdNotDefined();
      _this2.prepareMenuItems(_this2.existingAudits);
      _this2.isLoaded = true;
    });
  },
  methods: {
    goToFirstIfIdNotDefined: function goToFirstIfIdNotDefined() {
      if (this.idItem === '' && this.existingAudits.length && !this.isPreview) {
        var questionnaireLastId = this.existingAudits[this.existingAudits.length - 1].value;
        this.$router.replace("".concat(this.$router.currentRoute.value.path, "/").concat(questionnaireLastId));
        return true;
      }
      return false;
    },
    getUrlWithoutDPMId: function getUrlWithoutDPMId(path) {
      var dpmTitle = 'dpm';
      var dpmIndex = path.indexOf(dpmTitle);
      return path.substring(0, dpmIndex + dpmTitle.length);
    },
    prepareMenuItems: function prepareMenuItems(existingAudits) {
      var menu = [];
      var urlWithoutDPMId = !this.isPreview ? this.getUrlWithoutDPMId(this.$router.currentRoute.value.path) : '';
      existingAudits.map(function (audit) {
        menu.push({
          id: audit.value,
          title: audit.label,
          path: "".concat(urlWithoutDPMId, "/").concat(audit.value),
          color: audit.requestExpired === true ? 'red' : 'green'
        });
      });
      menu.reverse();
      this.menuItems = menu;
    },
    goToAudit: function goToAudit(item) {
      if (this.isPreview) {
        this.loadAudit(item.id);
        return;
      }
      var rout = item.routeName ? {
        name: item.routeName
      } : item.path;
      this.$router.push(rout);
    },
    loadAudit: function loadAudit(e) {
      var _this3 = this;
      return getAudit(this.module, this.id, e).then(function (response) {
        _this3.currentAudit = response;
        _this3.createProgressData(_this3.currentAudit);
      });
    },
    createProgressData: function createProgressData(data) {
      var _this4 = this;
      this.progressData.firstItem = {
        state: '',
        titles: []
      };
      this.progressData.secondItem = {
        state: 'wait',
        titles: [this.translate.awaitingQuestionnaireToBeOpened]
      };
      this.progressData.thirdItem = {
        state: 'wait',
        titles: [this.translate.awaitingQuestionnaireAnswer]
      };
      this.updateFirstItem('approved');
      if (data.firstClick) {
        this.progressData.secondItem = {
          state: 'approved',
          titles: [this.translate.questionnaireOpened + ': ' + data.firstClick.substring(0, 10)]
        };
      }
      this.questions = null;
      if (data.answered) {
        this.progressData.thirdItem = {
          state: 'approved',
          titles: [this.translate.questionnaireAnswered + ':' + data.answered.substring(0, 10)]
        };
        this.$nextTick(function () {
          _this4.questions = data.questionnaire.questionAnswers;
        });
      } else if (data.requestExpired) {
        this.progressData.secondItem = {
          state: 'declined',
          titles: [this.translate.deadlineExceeded]
        };
        this.progressData.thirdItem = {
          state: 'declined',
          titles: [this.translate.deadlineExceeded, this.translate.questionnaireAnswerNotReceived]
        };
      }
    },
    getAllInformationAssets: function getAllInformationAssets() {
      var _this5 = this;
      return getInformationAssets().then(function (_ref) {
        var list = _ref.list;
        return _this5.informationAssets = list;
      });
    },
    getAllRequests: function getAllRequests() {
      var _this6 = this;
      return this.genericData.getAllRequestsAPI(this.module, this.id).then(function (response) {
        _this6.allAudits = response.list;
        if (_this6.isPreview && _this6.existingAudits.length > 0) {
          return _this6.loadAudit(_this6.existingAudits[_this6.existingAudits.length - 1].value);
        }
      });
    },
    createNewDpmRequest: function createNewDpmRequest(formData) {
      var _this7 = this;
      formData.title = this.data.name || this.data.title;
      this.genericData.createNewRequestAPI(this.module, this.id, formData).then(function (response) {
        return Promise.all([response, _this7.getAllRequests()]);
      }).then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1),
          response = _ref3[0];
        if (!_this7.isPreview) {
          var urlWithoutDPMId = _this7.getUrlWithoutDPMId(_this7.$router.currentRoute.value.path);
          _this7.$router.replace("".concat(urlWithoutDPMId, "/").concat(response.data.id));
        }
      }).then(function () {
        _this7.prepareMenuItems(_this7.existingAudits);
      });
    },
    showActionModal: function showActionModal(type) {
      this.isModalOpened = true;
      this.dpmActionType = type;
    },
    askForMoreInfo: function askForMoreInfo(data) {
      var _this8 = this;
      this.genericData.askForMoreInfoAPI(this.currentAudit.id, data.expires, data.message).then(function (response) {
        _this8.updateFirstItem('approved');
        _this8.updateAllAuditsLocaly(response.data);
        _this8.loadAudit(_this8.currentAudit.id);
      }).catch(function (_ref4) {
        var response = _ref4.response;
        _this8.notificationError = response.error || _this8.translate.anErrorOccurred;
      }).finally(function () {
        _this8.isModalOpened = false;
      });
    },
    extendDeadline: function extendDeadline(data) {
      var _this9 = this;
      this.genericData.extendDeadlineAPI(this.module, this.id, this.currentAudit.id, data.expires, data.message).then(function (_ref5) {
        var data = _ref5.data;
        _this9.updateFirstItem('approved');
        if (!data.requestExpired) {
          _this9.progressData.secondItem.state = 'wait';
          _this9.progressData.thirdItem.state = 'wait';
        }
        _this9.updateAllAuditsLocaly(data);
        _this9.prepareMenuItems(_this9.existingAudits);
        _this9.loadAudit(_this9.currentAudit.id);
      }).catch(function (_ref6) {
        var response = _ref6.response;
        _this9.notificationError = response.error || _this9.translate.anErrorOccurred;
      }).finally(function () {
        _this9.isModalOpened = false;
      });
    },
    updateFirstItem: function updateFirstItem(state) {
      this.progressData.firstItem.state = state;
      this.progressData.firstItem.titles = [this.translate.sent + ': ' + this.currentAudit.created.substring(0, 10), this.translate.to + ': ' + this.currentAudit.fullname + ' (' + this.currentAudit.email + ')', this.translate.companyName + ': ' + this.currentAudit.company, this.translate.deadline + ': ' + this.currentAudit.expires.substring(0, 10)];
    },
    updateAllAuditsLocaly: function updateAllAuditsLocaly(audit) {
      var auditIndex = this.allAudits.findIndex(function (item) {
        return item.id === audit.id;
      });
      this.allAudits[auditIndex] = _objectSpread(_objectSpread({}, this.allAudits[auditIndex]), audit);
    },
    dismissQuestionnaireModal: function dismissQuestionnaireModal() {
      this.showQuestionnaireModal = false;
    },
    deleteAudit: function deleteAudit() {
      var _this10 = this;
      return _deleteAudit(this.module, this.id, this.auditIdToDelete).then(function () {
        _this10.allAudits = _this10.allAudits.filter(function (item) {
          return item.id !== _this10.auditIdToDelete;
        });
        _this10.menuItems = _this10.menuItems.filter(function (item) {
          return item.id !== _this10.auditIdToDelete;
        });
        if (!_this10.isPreview && +_this10.auditIdToDelete === +_this10.idItem) {
          _this10.currentAudit = {};
          _this10.questions = null;
          _this10.redirectToLastAudit();
        }
        if (_this10.isPreview && +_this10.auditIdToDelete === +_this10.currentAudit.id) {
          _this10.currentAudit = {};
          _this10.questions = null;
          var auditToShow = _this10.existingAudits[_this10.existingAudits.length - 1];
          auditToShow && _this10.loadAudit(+auditToShow.value);
        }
        _this10.auditIdToDelete = null;
      });
    },
    redirectToLastAudit: function redirectToLastAudit() {
      var _this$existingAudits;
      var urlWithoutDPMId = this.getUrlWithoutDPMId(this.$router.currentRoute.value.path);
      var questionnaireLastId = (_this$existingAudits = this.existingAudits[this.existingAudits.length - 1]) === null || _this$existingAudits === void 0 ? void 0 : _this$existingAudits.value;
      if (questionnaireLastId) {
        return this.$router.replace("".concat(urlWithoutDPMId, "/").concat(questionnaireLastId));
      }
      return this.$router.replace("".concat(urlWithoutDPMId));
    },
    showDeleteAlert: function showDeleteAlert(id) {
      var _this11 = this;
      this.auditIdToDelete = id;
      this.$confirm(this.translate.areYouSureYouWantToDeleteThisAudit, this.translate.theRecipientOfTheAuditWillReceiveAnEmail, function (res) {
        return res ? _this11.deleteAudit() : _this11.auditIdToDelete = null;
      }, {
        buttonOkText: this.translate.approve
      });
    }
  }
};