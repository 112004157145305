function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import { setFrequencies } from '@/utils/controls/utils';
import { focus } from '@/utils/directives/focus';
import DeadlinePreview from '@/components/Molecules/DeadlinePreview';
import Notification from '@/components/Molecules/Notification';
import { DateFormats } from '@/constants/DateFormats';
import { Timezones } from '@/constants/Timezones';
import { UserLevels } from '@/constants/UserLevels';
import HelpToolTip from '@/components/Molecules/HelpToolTip';
import { formatCurrentDateTime, calculateDifferenceAsDays, getTZGuess, formatDateToUTCTimezone, convertDateToUtcFormat, formatDateInTimeZoneWithOffset, addTimeAndFormatDate, getEndOfDayInUtc } from '@/utils/date';
export default {
  name: 'GovernanceReviewModal',
  directives: {
    focus: focus
  },
  components: {
    HelpToolTip: HelpToolTip,
    Notification: Notification,
    DeadlinePreview: DeadlinePreview,
    RismaSelect: RismaSelect,
    DateTimePicker: DateTimePicker,
    SingleSelect: SingleSelect,
    TinyMceBox: TinyMceBox,
    RismaTitle: RismaTitle,
    Modal: Modal,
    InputField: InputField
  },
  token: '<governance-review-modal></governance-review-modal>',
  props: {
    showModal: {
      type: Boolean,
      default: false,
      note: 'Should the modal be shown?'
    },
    review: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          frequency: 3,
          deadline: formatCurrentDateTime(DateFormats.DATE_TIME_FORMAT_LAST),
          userIds: {
            responsible: [],
            escalation: [],
            review: []
          },
          reviewDeadline: '',
          tags: [],
          organisationIds: []
        };
      },
      note: 'An object which most hold values for the following keys: title, description, frequency, date, responsible, escalating, reviewer, reviewDate, organisations. Empty Object will also be passed as default'
    },
    projectName: {
      type: String,
      required: true,
      note: 'Name of current project. Needed to make locked tag'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma users'
    },
    organisations: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma organisations'
    }
  },
  emits: ['accept', 'dismiss'],
  data: function data() {
    return {
      isRismaSelectOpen: false,
      currentReview: null,
      errors: {},
      notificationMessage: '',
      newReview: true,
      translate: getTranslate['GovernanceReviewModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useTagsStore, {
    tags: 'validTags'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    frequencies: function frequencies() {
      return setFrequencies(this.getSettingValue);
    },
    usersWithAccessToControls: function usersWithAccessToControls() {
      return this.users.filter(function (user) {
        return user.is_admin || user.level_controls > UserLevels.NONE;
      });
    },
    defaultReview: function defaultReview() {
      var _this = this;
      var tag = this.tagsWithLockedTag.find(function (tag) {
        return tag.tag.includes(_this.lockedTagName);
      }) || this.lockedTag;
      return {
        title: '',
        description: '',
        frequency: 3,
        deadline: getEndOfDayInUtc(this.timezone),
        userIds: {
          responsible: [],
          escalation: [],
          review: []
        },
        reviewDeadline: '',
        tags: [tag],
        organisationIds: []
      };
    },
    lockedTagName: function lockedTagName() {
      return "".concat(this.projectName, " - governance");
    },
    lockedTag: function lockedTag() {
      return {
        tag: this.lockedTagName,
        id: this.lockedTagName
      };
    },
    tagsWithLockedTag: function tagsWithLockedTag() {
      var _this2 = this;
      var tags = _toConsumableArray(this.tags);
      if (!tags.find(function (tag) {
        return tag.tag.includes(_this2.lockedTagName);
      })) {
        tags.unshift(this.lockedTag);
      }
      return tags;
    },
    idsOfLockedTags: function idsOfLockedTags() {
      var _this3 = this;
      var lockedTag = this.tagsWithLockedTag.find(function (tag) {
        return tag.tag.includes(_this3.lockedTagName);
      });
      return lockedTag && lockedTag.id ? [lockedTag.id] : [this.lockedTag.id];
    },
    timezone: function timezone() {
      var _this$currentReview;
      var guessedTimezone = getTZGuess();
      var timezone = Timezones.find(function (timezone) {
        return timezone.value === guessedTimezone;
      });
      return ((_this$currentReview = this.currentReview) === null || _this$currentReview === void 0 ? void 0 : _this$currentReview.deadlineTimezone) || (timezone ? guessedTimezone : 'UTC');
    },
    deadline: {
      get: function get() {
        return formatDateToUTCTimezone(this.currentReview.deadline, this.timezone);
      },
      set: function set(value) {
        this.currentReview.deadline = convertDateToUtcFormat(value, this.timezone);
      }
    },
    revDeadline: {
      get: function get() {
        if (this.currentReview.userIds.review.length > 0 && this.currentReview.reviewDeadline === '') {
          return formatDateInTimeZoneWithOffset(this.currentReview.deadline, 7, this.timezone);
        } else {
          return this.currentReview.userIds.review.length > 0 ? formatDateToUTCTimezone(this.currentReview.reviewDeadline, this.timezone) : '';
        }
      },
      set: function set(value) {
        if (value !== '') {
          this.currentReview.reviewDeadline = convertDateToUtcFormat(value, this.timezone);
        } else {
          if (this.currentReview.reviewDeadline !== '') {
            this.currentReview.reviewDeadline = addTimeAndFormatDate(this.currentReview.deadline, 7, 'day');
          } else {
            this.currentReview.reviewDeadline = value;
          }
        }
      }
    },
    datePickerOption: function datePickerOption() {
      return {
        widgetPositioning: {
          vertical: 'top'
        }
      };
    }
  }),
  watch: {
    review: {
      handler: function handler() {
        if (Object.keys(this.review).length === 0) {
          this.currentReview = this.prepareCurrentReview(this.defaultReview);
          this.newReview = true;
        } else {
          this.currentReview = this.prepareCurrentReview(this.review);
          this.newReview = false;
        }
      },
      deep: true
    },
    showModal: function showModal() {
      if (Object.keys(this.review).length === 0) {
        this.currentReview = this.prepareCurrentReview(this.defaultReview);
        this.newReview = true;
      } else {
        this.currentReview = this.prepareCurrentReview(this.review);
        this.newReview = false;
      }
    }
  },
  beforeMount: function beforeMount() {
    this.getData();
  },
  methods: {
    onTagSelected: function onTagSelected($event) {
      this.currentReview.tags = this.restoreLockedTags($event === null ? [] : $event);
    },
    restoreLockedTags: function restoreLockedTags(tags) {
      var _this4 = this;
      if (!tags.find(function (tag) {
        return tag.tag.includes(_this4.lockedTagName);
      })) {
        var lockedTag = this.tagsWithLockedTag.find(function (tag) {
          return tag.tag.includes(_this4.lockedTagName);
        });
        tags.push(lockedTag);
      }
      return tags;
    },
    prepareCurrentReview: function prepareCurrentReview(review) {
      var preparedReview = this.setTags(review);
      preparedReview.tags = this.restoreLockedTags(preparedReview.tags);
      return preparedReview;
    },
    organisationFromIds: function organisationFromIds(idList) {
      return this.organisations.filter(function (org) {
        return idList.includes(org.id);
      });
    },
    usersFromIds: function usersFromIds(idList) {
      return this.users.filter(function (user) {
        return idList.includes(user.id);
      });
    },
    handleAccept: function handleAccept() {
      var _this5 = this;
      this.errors = {};
      if (!this.validate()) {
        return;
      }
      if (this.currentReview.reviewDeadline === '' && this.currentReview.userIds.review.length > 0) {
        this.currentReview.reviewDeadline = addTimeAndFormatDate(this.currentReview.deadline, 7, 'day');
      }
      this.currentReview.tags = this.currentReview.tags.filter(function (tag) {
        return tag.id !== _this5.lockedTag.id;
      });
      this.$emit('accept', this.currentReview);
    },
    setTags: function setTags(review) {
      var _review$tagIds;
      var reviewCopy = JSON.parse(JSON.stringify(review));
      if ((_review$tagIds = review.tagIds) !== null && _review$tagIds !== void 0 && _review$tagIds.length) {
        reviewCopy.tags = this.tagsWithLockedTag.filter(function (tag) {
          return review.tagIds.includes(tag.id);
        });
      } else {
        reviewCopy.tags = [];
      }
      return reviewCopy;
    },
    handleDismiss: function handleDismiss() {
      var _this6 = this;
      if (this.hasChanged()) {
        this.$confirm(this.translate.areYouSure, this.translate.ifYouDeleteThisDraftTheEnteredDataWillNo, function (res) {
          return res && _this6.acceptAlert();
        });
      } else {
        this.errors = [];
        this.$emit('dismiss');
      }
    },
    acceptAlert: function acceptAlert() {
      this.errors = [];
      this.$emit('dismiss');
    },
    getData: function getData() {
      if (Object.keys(this.review).length === 0) {
        this.currentReview = JSON.parse(JSON.stringify(this.defaultReview));
      } else {
        this.currentReview = this.setTags(this.review);
      }
    },
    hasChanged: function hasChanged() {
      return !this.newReview && JSON.stringify(this.review).trim() !== JSON.stringify(this.currentReview).trim() || this.newReview && JSON.stringify(this.defaultReview) !== JSON.stringify(this.currentReview);
    },
    validate: function validate() {
      this.notificationMessage = '';
      var foundErrors = false;
      var keysToValidate = ['title', 'responsible', 'organisationIds'];
      for (var i = 0; i < keysToValidate.length; i++) {
        if (typeof this.currentReview[keysToValidate[i]] === 'undefined') {
          if (!this.currentReview.userIds[keysToValidate[i]]) {
            continue;
          }
          if (this.currentReview.userIds[keysToValidate[i]].length === 0) {
            var field = keysToValidate[i];
            this.errors[field] = this.$trans("You need to fill ".concat(field, " to continue"));
            foundErrors = true;
            continue;
          }
        } else {
          if (this.currentReview[keysToValidate[i]] === '' || this.currentReview[keysToValidate[i]].length === 0) {
            var _field = keysToValidate[i].substr(keysToValidate[i].length - 3) === 'Ids' ? keysToValidate[i].substr(0, keysToValidate[i].length - 3) : keysToValidate[i];
            this.errors[_field] = this.$trans("You need to fill ".concat(_field, " to continue"));
            foundErrors = true;
          }
        }
      }
      if (this.currentReview.userIds.review.length > 0 && calculateDifferenceAsDays(this.currentReview.reviewDeadline, this.currentReview.deadline) < 0) {
        this.notificationMessage = this.translate.reviewDeadlineCantBeBeforeTheControlDeadl;
        foundErrors = true;
      }
      return !foundErrors;
    }
  }
};