import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "border border-gray-450 p-6 pt-1 mb-3"
};
var _hoisted_2 = {
  class: "p-2 font-bold text-lg"
};
var _hoisted_3 = {
  class: "grid grid-cols-3 gap-x-6 gap-y-6"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  class: "flex"
};
var _hoisted_6 = {
  class: "ml-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_multiple_date_range = _resolveComponent("multiple-date-range");
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: "flex items-center ml-auto mb-2 w-fit cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $setup.showFilters = !$setup.showFilters;
    })
  }, [_createVNode(_component_risma_title, {
    title: $options.showSwitchTitle,
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    class: "stroke-2",
    icon: $setup.showFilters ? 'chevron-down' : 'chevron-up'
  }, null, 8 /* PROPS */, ["icon"])]), $setup.showFilters ? (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [_createElementVNode("legend", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.filters,
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"])]), $data.isLoaded && $props.useMultipleDateRanges ? (_openBlock(), _createBlock(_component_multiple_date_range, {
    key: 0,
    class: "mb-3",
    "initial-ranges": $data.multipleDateRanges,
    onRangesChanged: _cache[1] || (_cache[1] = function ($event) {
      return $data.multipleDateRanges = $event;
    })
  }, null, 8 /* PROPS */, ["initial-ranges"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [!$props.useMultipleDateRanges ? (_openBlock(), _createElementBlock("div", _hoisted_4, [$data.isLoaded ? (_openBlock(), _createBlock(_component_risma_date_range_picker, {
    key: 0,
    title: $data.translate.choosePeriod,
    "start-date": $data.dateRange.dateStart,
    "end-date": $data.dateRange.dateEnd,
    "date-range-classes": "!max-w-full",
    opens: "right",
    class: "w-full !max-w-full",
    onChanged: $options.changeDatePicker,
    onDiscard: $options.onDateDiscard
  }, null, 8 /* PROPS */, ["title", "start-date", "end-date", "onChanged", "onDiscard"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [_createVNode(_component_single_select, {
    modelValue: $data.dateFilterSelected,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.dateFilterSelected = $event;
    }),
    title: $data.translate.dateFilter,
    options: $options.dateFilterOptions,
    placeholder: $data.translate.select
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])]), _createElementVNode("div", null, [_createVNode(_component_risma_select, {
    title: $data.translate.responsible,
    options: $options.usersWithControlAccess,
    "selected-options": $data.searchData.responsible,
    placeholder: $data.translate.select,
    "label-key": "display_name",
    onSelected: _cache[3] || (_cache[3] = function ($event) {
      return $data.searchData.responsible = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])]), _createElementVNode("div", null, [_createVNode(_component_risma_select, {
    title: $data.translate.reviewer,
    options: $options.usersWithControlAccess,
    "selected-options": $data.searchData.reviewer,
    placeholder: $data.translate.select,
    "label-key": "display_name",
    onSelected: _cache[4] || (_cache[4] = function ($event) {
      return $data.searchData.reviewer = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])]), _createElementVNode("div", null, [_createVNode(_component_risma_select, {
    title: $data.translate.organisations,
    options: $data.organisations,
    "selected-options": $data.searchData.organisationIds,
    placeholder: $data.translate.select,
    "label-key": "visible_name",
    onSelected: _cache[5] || (_cache[5] = function ($event) {
      return $data.searchData.organisationIds = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])]), _createElementVNode("div", null, [_createVNode(_component_risma_select, {
    title: $data.translate.activityType,
    options: _ctx.activityTypes,
    "selected-options": $data.searchData.activityTypeIds,
    placeholder: $data.translate.select,
    "un-sorted": true,
    "label-key": "label",
    onSelected: _cache[6] || (_cache[6] = function ($event) {
      return $data.searchData.activityTypeIds = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])]), _createElementVNode("div", null, [_createVNode(_component_risma_select, {
    title: $options.processesName,
    options: $data.processes,
    "selected-options": $data.searchData.processIds,
    placeholder: $data.translate.select,
    "un-sorted": true,
    "label-key": "title",
    onSelected: _cache[7] || (_cache[7] = function ($event) {
      return $data.searchData.processIds = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])]), _createElementVNode("div", null, [_createVNode(_component_risma_select, {
    title: $options.accountTitle,
    options: $options.accountsWithChildren,
    "selected-options": $data.searchData.accountIds,
    placeholder: $data.translate.select,
    "label-key": "title",
    onSelected: _cache[8] || (_cache[8] = function ($event) {
      return $data.searchData.accountIds = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])]), _createElementVNode("div", null, [_createVNode(_component_risma_select, {
    title: $options.risksTitle,
    options: $data.risks,
    "selected-options": $data.searchData.riskIds,
    placeholder: $data.translate.select,
    "label-key": "title",
    onSelected: _cache[9] || (_cache[9] = function ($event) {
      return $data.searchData.riskIds = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])]), _createElementVNode("div", null, [_createVNode(_component_single_select, {
    title: $data.translate.motherControls,
    placeholder: $data.translate.select,
    options: $options.motherControlOptions,
    "model-value": $data.searchData.motherControlId,
    "un-sorted": true,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = function ($event) {
      return $data.searchData.motherControlId = $event;
    })
  }, null, 8 /* PROPS */, ["title", "placeholder", "options", "model-value"])]), _createElementVNode("div", null, [_createVNode(_component_risma_select, {
    title: $data.translate.tags,
    options: _ctx.tags,
    "selected-options": $data.searchData.tagIds,
    placeholder: $data.translate.select,
    "label-key": "tag",
    onSelected: _cache[11] || (_cache[11] = function ($event) {
      return $data.searchData.tagIds = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])]), _createElementVNode("div", null, [_createVNode(_component_risma_select, {
    title: $data.translate.frameworks,
    options: _ctx.frameworks,
    "selected-options": $data.searchData.solutionId,
    placeholder: $data.translate.select,
    "label-key": "displayName",
    onSelected: _cache[13] || (_cache[13] = function ($event) {
      return $data.searchData.solutionId = $event;
    })
  }, {
    newOption: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_toggle, {
        modelValue: $data.includeUnderlyingFrameworks,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = function ($event) {
          return $data.includeUnderlyingFrameworks = $event;
        })
      }, null, 8 /* PROPS */, ["modelValue"]), _createElementVNode("span", _hoisted_6, _toDisplayString($data.translate.includeUnderlying), 1 /* TEXT */)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])]), _cache[15] || (_cache[15] = _createElementVNode("div", null, null, -1 /* HOISTED */))]), _createVNode(_component_risma_button, {
    type: "save",
    text: $data.translate.apply,
    class: "mt-6 ml-auto",
    onClick: $options.applyFilters
  }, null, 8 /* PROPS */, ["text", "onClick"])])) : _createCommentVNode("v-if", true), $setup.showFilters ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "flex mb-6 cursor-pointer w-min",
    onClick: _cache[14] || (_cache[14] = function () {
      return $options.resetFilter && $options.resetFilter.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "rotate-ccw",
    class: "text-gray-610 stroke-2 mr-1"
  }), _createVNode(_component_risma_title, {
    title: $data.translate.resetFilter,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)]);
}