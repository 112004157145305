import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'TrafficLight',
  components: {
    FeatherIcon: FeatherIcon
  },
  description: '',
  token: '<traffic-light color="#57aa74" stroke="#57aa74"/>',
  props: {
    color: {
      type: String,
      required: true,
      note: 'the color'
    },
    title: {
      type: String,
      note: 'a title which will be shown on hover',
      default: ''
    },
    size: {
      type: String,
      default: 'default',
      note: 'the width and the height of the traffic light'
    },
    readOnly: {
      type: Boolean,
      default: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['click'],
  computed: {
    iconClasses: function iconClasses() {
      if (!this.icon) return 'border-trans-200';
      if (this.icon === 'ban') {
        var result = 'is-ban border-rRed-600';
        if (!this.readOnly) {
          result += ' ban-active';
        }
        return result;
      }
      return '';
    },
    dimension: function dimension() {
      switch (this.size) {
        case 'small':
          return 12;
        case 'big':
          return 20;
        default:
          return 16;
      }
    }
  }
};