import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.otherRelatedContracts,
    "button-ok-text": $data.translate.apply,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.onAccept,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('closeModal');
    })
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_risma_select, {
        "selected-options": $data.selectedContracts,
        options: $props.contracts,
        title: $data.translate.chooseContract,
        placeholder: $data.translate.clickToSelectContracts,
        "error-message": $data.isContractValid ? '' : $data.translate.pleaseSelectAContract,
        "label-key": "title",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.selectedContracts = $event;
        })
      }, {
        labelCustom: _withCtx(function (_ref) {
          var option = _ref.option;
          return [_createElementVNode("span", {
            class: _normalizeClass(option.archived ? 'line-through' : '')
          }, _toDisplayString(option.title), 3 /* TEXT, CLASS */)];
        }),
        tagLabelCustom: _withCtx(function (_ref2) {
          var option = _ref2.option;
          return [_createElementVNode("span", {
            class: _normalizeClass(option.archived ? 'line-through' : '')
          }, _toDisplayString(option.title), 3 /* TEXT, CLASS */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["selected-options", "options", "title", "placeholder", "error-message"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}