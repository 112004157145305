import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.saveCurrentReport,
    "button-ok-text": $data.translate.saveReport,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.doSaveReport,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      var _$data$errors, _$data$errors2;
      return [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        title: $data.translate.title,
        invalid: !!$data.errors.title,
        "model-value": $data.saveReportTitle,
        "error-help-text": ((_$data$errors = $data.errors) === null || _$data$errors === void 0 ? void 0 : _$data$errors.title) || '',
        placeholder: $data.translate.enterYourOwnTitleForThisReportHere,
        "focus-on-mount": "",
        type: "text",
        "onUpdate:modelValue": $options.updateSaveReportTitle
      }, null, 8 /* PROPS */, ["title", "invalid", "model-value", "error-help-text", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", null, [_createVNode(_component_input_field, {
        title: $data.translate.comments,
        invalid: !!$data.errors.description,
        "error-help-text": ((_$data$errors2 = $data.errors) === null || _$data$errors2 === void 0 ? void 0 : _$data$errors2.description) || '',
        "model-value": $data.saveReportDescription,
        placeholder: $data.translate.enterYourCommentsHere,
        type: "text",
        "onUpdate:modelValue": $options.updateSaveReportDescription
      }, null, 8 /* PROPS */, ["title", "invalid", "error-help-text", "model-value", "placeholder", "onUpdate:modelValue"])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}