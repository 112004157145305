import i18n from '@/i18n';
import { calculateStartOfDateAsDate, calculateStartDateBeforeTodayAsDate, calculateEndDateAsDate, calculatePastEndDateAsDate, calculateFutureEndDateByMonthsAsDate } from '@/utils/date';
export var getBaseRanges = function getBaseRanges() {
  return [{
    text: i18n.t('Today'),
    range: [calculateStartOfDateAsDate('day'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last week'),
    range: [calculateStartDateBeforeTodayAsDate(6, 'days', 'day'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('This month'),
    range: [calculateStartOfDateAsDate('month'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last month'),
    range: [calculateStartDateBeforeTodayAsDate(1, 'month', 'month'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last 3 months'),
    range: [calculateStartDateBeforeTodayAsDate(3, 'month', 'month'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last 6 months'),
    range: [calculateStartDateBeforeTodayAsDate(6, 'month', 'month'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('This year'),
    range: [calculateStartOfDateAsDate('year'), calculateEndDateAsDate('year')]
  }, {
    text: i18n.t('Year-to-date'),
    range: [calculateStartOfDateAsDate('year'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last year'),
    range: [calculateStartDateBeforeTodayAsDate(1, 'year', 'year'), calculatePastEndDateAsDate(1, 'year', 'year')]
  }];
};
export var getRangesWithNextThreeMonths = function getRangesWithNextThreeMonths() {
  var baseRange = getBaseRanges();
  var nextThreeMonthOption = {
    text: i18n.t('Next 3 months'),
    range: [calculateStartOfDateAsDate('day'), calculateFutureEndDateByMonthsAsDate(3)]
  };
  baseRange.splice(5, 0, nextThreeMonthOption);
  return baseRange;
};
export var DATES_LIMIT_RANGE = {
  START: new Date(0),
  END: new Date('2038-01-19 03:14:07Z')
};