export var DataFlowOptionTypes = {
  QUESTION_OPTION: 'question_option',
  DATAPROCESSOR_PROCESSOR: 'dataprocessor_processor',
  DATAPROCESSOR_CONTROLLER: 'dataprocessor_controller',
  DATAPROCESSOR_SYSTEM: 'dataprocessor_system'
};
export var DataFlowFields = {
  CURRENT_BASIS_SELECTED: 'currentBasisSelected',
  CURRENT_BASIS_SELECTED_ALL_DATA: 'currentBasisSelectedAllData',
  CURRENT_TYPES_SELECTED: 'currentTypesSelected',
  CURRENT_DATA_SUBJECT: 'currentDataSubject',
  CURRENT_DATA_SUBJECT_DIRECTION: 'direction',
  CURRENT_STORAGE_PERIOD_SELECTED: 'currentStoragePeriodSelected',
  SYSTEMS_SELECTED: 'systemsSelected',
  BASIS_OPTIONS: 'basisOptions',
  DATA_TYPE_OPTIONS: 'dataTypeOptions',
  STAKEHOLDER_OPTIONS: 'stakeholderOptions',
  SYSTEMS: 'systems',
  SYSTEMS_COMMENT: 'systemsComment',
  DATA_RETENTION_SELECTED: 'dataRetentionSelected',
  DATA_RETENTION_SELECTED_ALL_DATA: 'dataRetentionSelectedAllData'
};
export var CompanyWizardFields = {
  COMPANY_OBJECT: 'companyObject',
  IS_VAT_CREATION_MODE: 'isVatCreationMode',
  SELECTED_COMPANY_IDX: 'selectedCompanyIdx',
  SEARCH_FIELD_VALUE: 'searchFieldValue',
  FOUND_COMPANIES: 'foundCompanies',
  FOUND_LEI: 'foundLei',
  LEI: 'lei'
};
export var AddAssetActions = {
  NONE: null,
  CHOOSE_EXISTING: 1,
  CREATE_NEW: 2
};
export var errorFields = {
  DEFAULT: 'default',
  TITLE: 'title',
  VATNO: 'vatNo',
  COUNTRY: 'country',
  EMAIL: 'email',
  STAKEHOLDER: 'stakeholder',
  DATA_TYPE: 'datatype',
  DATA_PROCESSOR: 'dataProcessor',
  DATA_CONTROLLER: 'dataController',
  CUSTOM_IA: 'customIA',
  SYSTEMS: 'systems',
  CUSTOM_IA_EXISTING_TYPE: 'customIAExistingType',
  CUSTOM_IA_EXISTING: 'customIAExisting'
};