import { getTranslate } from './translate';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Modal from '@/components/Molecules/Modal/Modal';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import { DateFormats } from '@/constants/DateFormats';
import { INT_MAX } from '@/constants/Numbers';
export default {
  name: 'CompanyFunctionModal',
  components: {
    DateTimePicker: DateTimePicker,
    InputField: InputField,
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    Modal: Modal
  },
  props: {
    functionData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    selectBoxOptions: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['save', 'dismiss'],
  data: function data() {
    return {
      datePickerOptions: {
        format: DateFormats.DATE_FORMAT
      },
      errorMessages: {},
      translate: getTranslate['CompanyFunctionModal']()
    };
  },
  methods: {
    handleValidation: function handleValidation() {
      this.errorMessages = {};
      if (!this.functionData.name) {
        this.errorMessages.name = this.translate.titleCannotBeEmpty;
      }
      return !Object.keys(this.errorMessages).length;
    },
    handleSaveFunction: function handleSaveFunction() {
      if (!this.handleValidation()) return;
      this.$emit('save', this.functionData);
    },
    adjustMaxValue: function adjustMaxValue(value) {
      if (value > INT_MAX) return INT_MAX;
      return value;
    }
  }
};