import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "delegation-well"
};
var _hoisted_2 = {
  key: 2,
  class: "text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_delegation_modal = _resolveComponent("delegation-modal");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_delegation_list = _resolveComponent("delegation-list");
  var _component_framework_id_relation_details = _resolveComponent("framework-id-relation-details");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showCreateModal ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.creationComponent), {
    key: 0,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showCreateModal = false;
    }),
    onCreate: $options.onCreateItem
  }, null, 40 /* PROPS, NEED_HYDRATION */, ["onCreate"])) : _createCommentVNode("v-if", true), $data.showDelegationModal ? (_openBlock(), _createBlock(_component_delegation_modal, {
    key: 1,
    "options-list": $options.delegationModalOptionsList,
    "preselected-items": _ctx.delegationModalSelectedIds,
    "modal-settings": $options.delegationModalSettings,
    "is-multiple": $data.isDelegationModalSelectedMultiple,
    "with-reset": $data.isDelegationModalWithReset,
    "show-add-item": $options.canBeCreated,
    "delegation-list-label": $options.delegationLabel,
    "access-rights": $options.accessRights,
    onCloseDelegationModal: _cache[1] || (_cache[1] = function ($event) {
      return $data.showDelegationModal = false;
    }),
    onUpdateDelegationItem: $options.onApplyChanges,
    onCreate: _cache[2] || (_cache[2] = function ($event) {
      return $data.showCreateModal = true;
    }),
    "onUpdate:optionsList": $options.updateSelectedUserList,
    "onUpdate:preselectedItems": _cache[3] || (_cache[3] = function ($event) {
      return _ctx.delegationModalSelectedIds = $event;
    })
  }, null, 8 /* PROPS */, ["options-list", "preselected-items", "modal-settings", "is-multiple", "with-reset", "show-add-item", "delegation-list-label", "access-rights", "onUpdateDelegationItem", "onUpdate:optionsList"])) : _createCommentVNode("v-if", true), !$data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_loading_indicator_small)])) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 3
  }, [$props.modelValue.responsibleUserIds ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 0,
    title: $options.responsibleListTitle,
    "label-key": "display_name",
    list: $options.responsible,
    "read-only": $props.readOnly,
    "is-user": true,
    "max-visible-tags": $options.maxVisibleTags,
    onEdit: _cache[4] || (_cache[4] = function ($event) {
      return $options.editDelegationItem($options.responsible, $data.DelegationItemTypes.RESPONSIBLE_USER, $data.DelegationTypes.users, !$options.isERM);
    })
  }, null, 8 /* PROPS */, ["title", "list", "read-only", "max-visible-tags"])) : _createCommentVNode("v-if", true), $props.modelValue.reviewersUserIds ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 1,
    title: $options.reviewerListTitle,
    "label-key": "display_name",
    list: $options.reviewers,
    "read-only": $props.readOnly,
    "is-user": true,
    "max-visible-tags": $options.maxVisibleTags,
    onEdit: _cache[5] || (_cache[5] = function ($event) {
      return $options.editDelegationItem($options.reviewers, $data.DelegationItemTypes.REVIEWER_USER);
    })
  }, null, 8 /* PROPS */, ["title", "list", "read-only", "max-visible-tags"])) : _createCommentVNode("v-if", true), $props.modelValue.escalationUserIds ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 2,
    title: $options.escalationListTitle,
    "label-key": "display_name",
    list: $options.escalation,
    "read-only": $props.readOnly,
    "is-user": true,
    "max-visible-tags": $options.maxVisibleTags,
    onEdit: _cache[6] || (_cache[6] = function ($event) {
      return $options.editDelegationItem($options.escalation, $data.DelegationItemTypes.ESCALATION_USER);
    })
  }, null, 8 /* PROPS */, ["title", "list", "read-only", "max-visible-tags"])) : _createCommentVNode("v-if", true), $props.modelValue.accountableUserIds ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 3,
    title: $options.accountableListTitle,
    "label-key": "display_name",
    list: $options.accountable,
    "read-only": $props.readOnly,
    "is-user": true,
    "max-visible-tags": $options.maxVisibleTags,
    onEdit: _cache[7] || (_cache[7] = function ($event) {
      return $options.editDelegationItem($options.accountable, $data.DelegationItemTypes.ACCOUNTABLE_USER, $data.DelegationTypes.users, !$options.isERM);
    })
  }, null, 8 /* PROPS */, ["title", "list", "read-only", "max-visible-tags"])) : _createCommentVNode("v-if", true), $props.modelValue.consultedUserIds ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 4,
    title: $options.consultedListTitle,
    "label-key": "display_name",
    list: $options.consulted,
    "read-only": $props.readOnly,
    "is-user": true,
    "max-visible-tags": $options.maxVisibleTags,
    onEdit: _cache[8] || (_cache[8] = function ($event) {
      return $options.editDelegationItem($options.consulted, $data.DelegationItemTypes.CONSULTED_USER);
    })
  }, null, 8 /* PROPS */, ["title", "list", "read-only", "max-visible-tags"])) : _createCommentVNode("v-if", true), !$options.hideInformed && $props.modelValue.informedUserIds ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 5,
    title: $options.informedListTitle,
    "label-key": "display_name",
    list: $options.informed,
    "read-only": $props.readOnly,
    "is-user": true,
    "max-visible-tags": $options.maxVisibleTags,
    onEdit: _cache[9] || (_cache[9] = function ($event) {
      return $options.editDelegationItem($options.informed, $data.DelegationItemTypes.INFORMED_USER);
    })
  }, null, 8 /* PROPS */, ["title", "list", "read-only", "max-visible-tags"])) : _createCommentVNode("v-if", true), $props.modelValue.organisationIds ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 6,
    title: $options.organisationsListTitle,
    "label-key": "visible_name",
    list: $options.orgs,
    "read-only": $props.readOnly,
    "max-visible-tags": $options.maxVisibleTags,
    onEdit: _cache[10] || (_cache[10] = function ($event) {
      return $options.editDelegationItem($options.orgs, $data.DelegationItemTypes.ORGANISATION, $data.DelegationTypes.organisations);
    })
  }, null, 8 /* PROPS */, ["title", "list", "read-only", "max-visible-tags"])) : _createCommentVNode("v-if", true), $props.modelValue.notificationsUserIds && (!$options.isAction || $options.hasNotificationsFromActivityType) ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 7,
    title: $data.translate.notifications,
    "label-key": "display_name",
    list: $options.notifications,
    "read-only": $props.readOnly,
    "is-user": true,
    "max-visible-tags": $options.maxVisibleTags,
    onEdit: _cache[11] || (_cache[11] = function ($event) {
      return $options.editDelegationItem($options.notifications, $data.DelegationItemTypes.NOTIFICATION_USER);
    })
  }, null, 8 /* PROPS */, ["title", "list", "read-only", "max-visible-tags"])) : _createCommentVNode("v-if", true), $options.isERM ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 8,
    title: $options.managementLevelTitle,
    "label-key": "name",
    list: $options.managementLevels,
    "read-only": $props.readOnly,
    onEdit: _cache[12] || (_cache[12] = function ($event) {
      return $options.editDelegationItem($options.managementLevels, $data.DelegationItemTypes.MANAGEMENT_LEVEL, $data.DelegationTypes.riskManagementLevels, false, false);
    })
  }, null, 8 /* PROPS */, ["title", "list", "read-only"])) : _createCommentVNode("v-if", true), $props.modelValue.dailyTeamUsersIds && (!$options.isAction || $options.hasDailyUsersFromActivityType) ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 9,
    title: $options.dailyTeamUserTitle,
    "label-key": "display_name",
    list: $options.dailyTeamUsers,
    "read-only": $props.readOnly,
    "is-user": true,
    "max-visible-tags": $options.maxVisibleTags,
    onEdit: _cache[13] || (_cache[13] = function ($event) {
      return $options.editDelegationItem($options.dailyTeamUsers, $data.DelegationItemTypes.DAILY_TEAM_USER);
    })
  }, null, 8 /* PROPS */, ["title", "list", "read-only", "max-visible-tags"])) : _createCommentVNode("v-if", true), $props.modelValue.dailyTeamOrganisationsIds && (!$options.isAction || $options.hasDailyOrganisationsFromActivityType) ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 10,
    title: $options.dailyTeamOrganisationTitle,
    "label-key": "visible_name",
    list: $options.dailyTeamOrganisations,
    "read-only": $props.readOnly,
    "max-visible-tags": $options.maxVisibleTags,
    onEdit: _cache[14] || (_cache[14] = function ($event) {
      return $options.editDelegationItem($options.dailyTeamOrganisations, $data.DelegationItemTypes.DAILY_TEAM_ORGANISATION, $data.DelegationTypes.organisations);
    })
  }, null, 8 /* PROPS */, ["title", "list", "read-only", "max-visible-tags"])) : _createCommentVNode("v-if", true), $props.modelValue.tagIds ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 11,
    title: $options.tagListTitle,
    list: $options.tagsAssigned,
    "label-key": "tag",
    "read-only": $props.readOnly,
    onEdit: _cache[15] || (_cache[15] = function ($event) {
      return $options.editDelegationItem($options.tagsAssigned, $data.DelegationItemTypes.TAG, 'tags');
    })
  }, null, 8 /* PROPS */, ["title", "list", "read-only"])) : _createCommentVNode("v-if", true), $options.isAction && $options.hasDependingOnFromActivityType ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 12,
    title: $options.dependingOnTitle,
    list: $options.dependingOnAssigned,
    "label-key": "label",
    "read-only": $props.readOnly,
    onEdit: _cache[16] || (_cache[16] = function ($event) {
      return $options.editDelegationItem($options.dependingOnAssigned, $data.DelegationItemTypes.DEPENDING, $data.DelegationTypes.initiatives);
    })
  }, null, 8 /* PROPS */, ["title", "list", "read-only"])) : _createCommentVNode("v-if", true), $options.isAction ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 13,
    title: $options.precursorToTitle,
    list: $options.precursorAssigned,
    "label-key": "label",
    "read-only": true
  }, null, 8 /* PROPS */, ["title", "list"])) : _createCommentVNode("v-if", true), _createVNode(_component_framework_id_relation_details, {
    list: $props.modelValue.solutionIds || [],
    "read-only": $props.readOnly,
    onPropertyChanged: _cache[17] || (_cache[17] = function ($event) {
      return _ctx.$emit('propertyChanged', $event);
    })
  }, null, 8 /* PROPS */, ["list", "read-only"])], 64 /* STABLE_FRAGMENT */))]);
}