function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_custom_risk_field = _resolveComponent("custom-risk-field");
  var _component_main_well = _resolveComponent("main-well");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_field_groups_modal = _resolveComponent("field-groups-modal");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.customRiskFields,
        type: "big"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.theseAreYourOwnCustomRiskFields), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.fieldGroups, function (group) {
        return _openBlock(), _createBlock(_component_main_well, {
          key: group.id,
          class: "mb-6 z-auto"
        }, {
          default: _withCtx(function () {
            return [_createVNode(_component_risma_title, {
              title: group.name || $data.translate.notGrouped,
              type: "big"
            }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.customFieldsByGroups[group.id], function (customField) {
              return _openBlock(), _createBlock(_component_custom_risk_field, {
                key: customField.id,
                class: "erm-cf",
                "custom-field": customField,
                "read-only": $props.readOnly,
                "save-button-click-counter": $props.saveButtonClickCounter,
                onEdit: $options.updateCustomField,
                onDelete: function onDelete($event) {
                  return $options.handleDeleteCF(customField.id);
                }
              }, null, 8 /* PROPS */, ["custom-field", "read-only", "save-button-click-counter", "onEdit", "onDelete"]);
            }), 128 /* KEYED_FRAGMENT */))];
          }),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */);
      }), 128 /* KEYED_FRAGMENT */)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.customFieldsNew, function (customField, index) {
        return _openBlock(), _createBlock(_component_custom_risk_field, {
          key: "new-cf-".concat(index),
          "custom-field": customField,
          "read-only": $props.readOnly,
          "save-button-click-counter": $props.saveButtonClickCounter,
          onEdit: function onEdit($event) {
            return $options.updateCustomField(_objectSpread({
              index: index
            }, $event));
          },
          onDelete: function onDelete($event) {
            return $options.handleDeleteUngroupedCF(index);
          }
        }, null, 8 /* PROPS */, ["custom-field", "read-only", "save-button-click-counter", "onEdit", "onDelete"]);
      }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_buttons_row, {
        data: $options.buttonsRowData
      }, null, 8 /* PROPS */, ["data"]), $data.showFieldGroupsModal ? (_openBlock(), _createBlock(_component_field_groups_modal, {
        key: 0,
        "field-groups": $props.fieldGroups,
        "scoring-id": $props.scoringId,
        "group-items": $options.customFieldsByGroups,
        onDismiss: $options.toggleReorderCFModal,
        onCreate: $options.onCreateGroup,
        "onMove:groups": $options.moveGroups,
        "onMove:fields": $options.moveFields
      }, null, 8 /* PROPS */, ["field-groups", "scoring-id", "group-items", "onDismiss", "onCreate", "onMove:groups", "onMove:fields"])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  });
}