// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-chart[data-v-4a50fb12] {
  flex-basis: 200px;
}
.project-charts-grid-4[data-v-4a50fb12] {
  grid-template-columns: repeat(4, minmax(50px, 1fr));
}
.project-charts-grid-3[data-v-4a50fb12] {
  grid-template-columns: repeat(3, minmax(50px, 1fr));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
