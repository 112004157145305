import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "flex flex-col justify-between"
};
var _hoisted_2 = {
  class: "mb-3"
};
var _hoisted_3 = {
  class: "text-red-50 font-semibold"
};
var _hoisted_4 = {
  class: "mb-3"
};
var _hoisted_5 = {
  class: "text-red-50 font-semibold"
};
var _hoisted_6 = {
  class: "mb-3"
};
var _hoisted_7 = {
  class: "text-red-50 font-semibold"
};
var _hoisted_8 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_input_field = _resolveComponent("input-field");
  var _component_text_box = _resolveComponent("text-box");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.addNew,
    "show-buttons": false,
    "dismiss-button-disabled": true,
    "dismiss-on-click-outside": true,
    onDismiss: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
        modelValue: $data.data.articleId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.data.articleId = $event;
        }),
        title: $data.translate.article,
        options: $options.articlesOptions,
        placeholder: $data.translate.selectArticle
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"]), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$trans($data.errors.articleId)), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
        modelValue: $data.data.module,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.data.module = $event;
        }),
        title: $data.translate.chooseActivityType,
        options: $options.activityTypeOptions,
        placeholder: $data.translate.choose
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"]), _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$trans($data.errors.module)), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_input_field, {
        modelValue: $data.data.header,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.data.header = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.enterTitle,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$trans($data.errors.header)), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_text_box, {
        text: $data.data.description,
        placeholder: $data.translate.description,
        label: $data.translate.description,
        error: $data.errors.description,
        class: "w-full",
        onUpdated: _cache[3] || (_cache[3] = function ($event) {
          return $data.data.description = $event;
        })
      }, null, 8 /* PROPS */, ["text", "placeholder", "label", "error"])])]), _createVNode(_component_risma_button, {
        text: $data.translate.save,
        type: "save",
        class: "ml-auto save-button",
        onClick: $options.onSave
      }, null, 8 /* PROPS */, ["text", "onClick"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"]);
}