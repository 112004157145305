function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import CustomRiskField from '@/components/Organisms/Admin/CustomRiskField';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import FieldGroupsModal from '@/components/Molecules/Modal/FieldGroupsModal';
import { CustomFieldTypes as CF_TYPES } from '@/constants/CustomFieldTypes';
import * as Utils from '@/utils/Utils';
import { MODULES } from '@/constants/modules';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'CustomRiskFieldsWell',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    ButtonsRow: ButtonsRow,
    FieldGroupsModal: FieldGroupsModal,
    CustomRiskField: CustomRiskField
  },
  props: {
    scoringId: {
      type: Number,
      required: true
    },
    fields: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    },
    fieldGroups: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    saveButtonClickCounter: {
      required: false,
      type: Number,
      default: 0,
      note: 'required to not display errors below newly created items before user make another save button click'
    }
  },
  emits: ['edit', 'delete', 'move:groups', 'move:fields'],
  data: function data() {
    return {
      CustomFieldTypes: CF_TYPES,
      fieldDeafultValue: {
        typeId: null,
        options: [],
        unit: '',
        children: [],
        errors: {}
      },
      showFieldGroupsModal: false,
      idToDelete: null,
      ungroupedCFIndexToDelete: null,
      translate: getTranslate['CustomRiskFieldsWell']()
    };
  },
  computed: {
    customFieldsNew: function customFieldsNew() {
      return this.fields.filter(function (item) {
        return item.id === undefined;
      });
    },
    groupedCFLength: function groupedCFLength() {
      return this.fields.length - this.customFieldsNew.length;
    },
    filteredFilds: function filteredFilds() {
      var _this = this;
      return this.fields.filter(function (item) {
        return item.typeId !== _this.CustomFieldTypes.COMBINED;
      }).sort(function (fieldA, fieldB) {
        return (fieldA === null || fieldA === void 0 ? void 0 : fieldA.sorting) - (fieldB === null || fieldB === void 0 ? void 0 : fieldB.sorting);
      });
    },
    combinedFields: function combinedFields() {
      var _this2 = this;
      return this.fields.filter(function (item) {
        return item.fieldtype === _this2.CustomFieldTypes.COMBINED;
      }) || [];
    },
    customFieldsByGroups: function customFieldsByGroups() {
      var _this3 = this;
      var map = {};
      this.filteredFilds.forEach(function (field) {
        if (field.id === undefined) return;
        if (!map[field.fieldGroup]) {
          map[field.fieldGroup] = [];
        }
        field.options = field.options ? Utils.getProperSelectedOptions(field.options) : [];
        map[field.fieldGroup].push(_objectSpread(_objectSpread({}, _this3.fieldDeafultValue), field));
      });
      return map;
    },
    reorderButtonTitle: function reorderButtonTitle() {
      return this.customFieldsNew.length ? this.translate.savePeriodToChangeCustomFieldOrder : this.translate.reorderCustomFields;
    },
    notGroupedId: function notGroupedId() {
      var group = this.fieldGroups.find(function (group) {
        return group.name === '';
      });
      return (group === null || group === void 0 ? void 0 : group.id) || 0;
    },
    buttonsRowData: function buttonsRowData() {
      var list = [{
        text: this.translate.addCustomField,
        type: 'save',
        disabled: this.readOnly,
        clickEvent: this.addUngroupedCF
      }];
      if (!this.readOnly) {
        list.push({
          text: this.reorderButtonTitle,
          type: 'save',
          disabled: !!this.customFieldsNew.length,
          clickEvent: this.toggleReorderCFModal
        });
      }
      return list;
    }
  },
  methods: {
    emitChangeFields: function emitChangeFields(fields) {
      this.$emit('edit', fields);
    },
    addUngroupedCF: function addUngroupedCF() {
      var baseFields = _objectSpread({
        calculationType: 1,
        fieldWeight: -1,
        fieldGroup: this.notGroupedId,
        module: MODULES.RISK,
        objType: ObjectTypes.CUSTOM_FIELDS_FIELD,
        scoringId: this.scoringId,
        label: ''
      }, this.fieldDeafultValue);
      var fields = [].concat(_toConsumableArray(this.fields), [baseFields]);
      this.emitChangeFields(fields);
    },
    deleteCF: function deleteCF() {
      if (this.ungroupedCFIndexToDelete !== null) {
        this.fields.splice(this.groupedCFLength + this.ungroupedCFIndexToDelete, 1);
        this.emitChangeFields(this.fields);
      }
      if (this.idToDelete !== null) {
        this.$emit('delete', this.idToDelete);
      }
      this.closeAlert();
    },
    confirm: function confirm() {
      var _this4 = this;
      this.$confirm(this.translate.areYouSure, this.closeMessage, function (res) {
        return res ? _this4.deleteCF() : _this4.closeAlert();
      }, {
        buttonOkText: this.translate.delete,
        buttonDismissText: this.translate.cancel
      });
    },
    handleDeleteCF: function handleDeleteCF(id) {
      this.idToDelete = id;
      this.confirm();
    },
    handleDeleteUngroupedCF: function handleDeleteUngroupedCF(index) {
      this.ungroupedCFIndexToDelete = index;
      this.confirm();
    },
    closeAlert: function closeAlert() {
      this.ungroupedCFIndexToDelete = this.idToDelete = null;
    },
    updateCustomField: function updateCustomField(_ref) {
      var id = _ref.id,
        index = _ref.index,
        event = _ref.event;
      if (!event) return;
      var fieldIndex = null;
      var fields = _toConsumableArray(this.fields);
      var _event = _slicedToArray(event, 2),
        key = _event[0],
        value = _event[1];
      if (id !== undefined) {
        fieldIndex = fields.findIndex(function (field) {
          return field.id === id;
        });
      } else {
        fieldIndex = this.groupedCFLength + index;
      }
      fields[fieldIndex][key] = value;
      this.emitChangeFields(fields);
    },
    toggleReorderCFModal: function toggleReorderCFModal() {
      this.showFieldGroupsModal = !this.showFieldGroupsModal;
    },
    onCreateGroup: function onCreateGroup(newField) {
      this.fieldGroups.push(newField);
    },
    moveGroups: function moveGroups(fieldGroups) {
      this.$emit('move:groups', fieldGroups);
    },
    moveFields: function moveFields(data) {
      var _this5 = this;
      var fieldsList = this.fieldGroups.reduce(function (accumulator, current) {
        if (data[current.id]) {
          accumulator.push.apply(accumulator, _toConsumableArray(data[+current.id]));
        } else {
          var fields = _this5.customFieldsByGroups[current.id] || [];
          accumulator.push.apply(accumulator, _toConsumableArray(fields));
        }
        return accumulator;
      }, []);
      this.$emit('move:fields', [].concat(_toConsumableArray(fieldsList), _toConsumableArray(this.combinedFields)));
    }
  }
};