import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_delegation_modal = _resolveComponent("delegation-modal");
  var _component_single_select = _resolveComponent("single-select");
  var _component_delegation_list = _resolveComponent("delegation-list");
  return _openBlock(), _createElementBlock("div", null, [$data.showDelegationModal ? (_openBlock(), _createBlock(_component_delegation_modal, {
    key: 0,
    "options-list": $options.delegationModalOptions,
    "preselected-items": $options.delegationModalSelectedIds,
    "modal-settings": $options.delegationSettings,
    "is-multiple": $options.delegationSettings.isMultiple,
    "with-reset": true,
    "show-add-item": false,
    "delegation-list-label": $options.delegationListTitle,
    onCloseDelegationModal: _cache[0] || (_cache[0] = function ($event) {
      return $data.showDelegationModal = false;
    }),
    onUpdateDelegationItem: $options.onApplyChanges
  }, null, 8 /* PROPS */, ["options-list", "preselected-items", "modal-settings", "is-multiple", "delegation-list-label", "onUpdateDelegationItem"])) : _createCommentVNode("v-if", true), _createVNode(_component_single_select, {
    title: $data.translate.contractType,
    options: $options.contractTypeOptions,
    "model-value": $props.contractType,
    class: "mb-4",
    "un-sorted": true,
    disabled: $props.readOnly,
    "with-reset": $props.contractType !== $data.contractTypes.NOT_SET.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('update:contractType', $event);
    })
  }, null, 8 /* PROPS */, ["title", "options", "model-value", "disabled", "with-reset"]), $options.hasDelegationList ? (_openBlock(), _createBlock(_component_delegation_list, {
    key: 1,
    title: $options.delegationListTitle,
    "label-key": "title",
    list: $options.delegationListAvailableOptions,
    "read-only": $props.readOnly,
    onEdit: $options.editHandle
  }, null, 8 /* PROPS */, ["title", "list", "read-only", "onEdit"])) : _createCommentVNode("v-if", true)]);
}