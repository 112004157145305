function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import WizardFlow from '@/components/Molecules/WizardFlow';
import { createFlow, getAllOptions } from '@/api/compliance/dataflow';
import { updateQuestionForNode, getAllQuestionAnswersForNode } from '@/api/compliance/questionnaire';
import { removeDuplicates } from '@/utils/filterArray';
import { sortAlphabetic } from '@/utils/sort';
import { getDataflowThirdPartiesTitle, getDataflowStakeholdersTitle } from '@/utils/TitleFromSetting';
import { DataFlowOptionTypes, DataFlowFields, errorFields } from '@/constants/compliance/WizardFlow';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
import DataFlowWizardStep1 from '@/components/Organisms/Compliance/DataFlow/DataFlowWizardStep1';
import DataFlowWizardStepDataSubject from '@/components/Organisms/Compliance/DataFlow/DataFlowWizardStepDataSubject';
import DataFlowWizardStepDataType from '@/components/Organisms/Compliance/DataFlow/DataFlowWizardStepDataType';
import DataFlowWizardStepLegalBasis from '@/components/Organisms/Compliance/DataFlow/DataFlowWizardStepLegalBasis';
import DataFlowWizardStepRetention from '@/components/Organisms/Compliance/DataFlow/DataFlowWizardStepRetention';
import DataFlowWizardStepSystems from '@/components/Organisms/Compliance/DataFlow/DataFlowWizardStepSystems';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { prepareGroupedStakeholdersOptions, prepareGroupedSystemsOptions } from '@/utils/dataFlowUtils';
var QUESTIONS = 'questions';
var QuestionObjFlags = {
  DATAFLOW_STAKEHOLDER: 'dataFlowStakeholder',
  DATAFLOW_DATA_TYPE: 'dataFlowDataType',
  DATAFLOW_SYSTEM: 'dataFlowSystem'
};
export default {
  name: 'WizardFlowWrapper',
  description: 'Wrapper component for wizard flow that contains api requests, data converter, WizardFlow and button',
  components: {
    FeatherIcon: FeatherIcon,
    WizardFlow: WizardFlow
  },
  props: {
    nodeId: {
      required: false,
      type: [Number, String],
      default: 0
    },
    projectId: {
      type: Number,
      required: true,
      note: 'project id for the current project'
    },
    questionnaires: {
      type: Array,
      required: true,
      note: 'tempopary solution for the Basis options - would be removed with API update'
    }
  },
  setup: function setup() {
    return {
      welcomePageState: getLocalStorageComputed('wizard_flow_welcome_disabled', 'false')
    };
  },
  data: function data() {
    return {
      data: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, DataFlowFields.STAKEHOLDER_OPTIONS, []), DataFlowFields.DATA_TYPE_OPTIONS, []), DataFlowFields.SYSTEMS, []), QUESTIONS, []),
      metaOptions: _defineProperty(_defineProperty(_defineProperty({}, DataFlowFields.STAKEHOLDER_OPTIONS, []), DataFlowFields.DATA_TYPE_OPTIONS, []), DataFlowFields.SYSTEMS, []),
      selectedOptions: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, DataFlowFields.CURRENT_DATA_SUBJECT, null), DataFlowFields.CURRENT_TYPES_SELECTED, []), DataFlowFields.CURRENT_BASIS_SELECTED, {}), DataFlowFields.CURRENT_BASIS_SELECTED_ALL_DATA, {}), DataFlowFields.SYSTEMS_SELECTED, []), DataFlowFields.SYSTEMS_COMMENT, ''), DataFlowFields.CURRENT_DATA_SUBJECT_DIRECTION, 'deliver'), DataFlowFields.DATA_RETENTION_SELECTED, {}), DataFlowFields.DATA_RETENTION_SELECTED_ALL_DATA, {}),
      allStakeholders: [],
      allDataTypes: [],
      questions: [],
      DataFlowFields: DataFlowFields,
      isOpened: false,
      showAlert: false,
      loadState: false,
      errorMessage: '',
      questionTypes: QuestionTypes(),
      translate: getTranslate['WizardFlowWrapper']()
    };
  },
  computed: {
    canBeOpened: function canBeOpened() {
      var _this = this;
      return Object.keys(this.data).every(function (item) {
        var _this$data$item;
        return (_this$data$item = _this.data[item]) === null || _this$data$item === void 0 ? void 0 : _this$data$item.length;
      });
    },
    items: function items() {
      var stakeholderName = getDataflowStakeholdersTitle();
      var systemName = getDataflowThirdPartiesTitle();
      return [{
        headline: this.translate.welcome,
        icon: 'info',
        component: DataFlowWizardStep1,
        hasShowMessageAgain: true
      }, {
        headline: stakeholderName,
        headline2: stakeholderName,
        icon: 'user',
        component: DataFlowWizardStepDataSubject,
        isLockedNextStep: !this.selectedOptions[DataFlowFields.CURRENT_DATA_SUBJECT],
        lockMessage: {
          field: errorFields.STAKEHOLDER,
          text: "".concat(this.translate.choose, " ").concat(stakeholderName)
        },
        questionsString: this.metaOptions[DataFlowFields.STAKEHOLDER_OPTIONS].questionTitles
      }, {
        headline: this.translate.dataTypes,
        headline2: this.translate.chooseAllDataTypesThat,
        icon: 'filter',
        questionsString: this.metaOptions[DataFlowFields.DATA_TYPE_OPTIONS].questionTitles,
        isLockedNextStep: !Object.keys(this.selectedOptions[DataFlowFields.CURRENT_TYPES_SELECTED]).length,
        lockMessage: {
          field: errorFields.DATA_TYPE,
          text: this.translate.chooseAllDataTypesThat
        },
        component: DataFlowWizardStepDataType
      }, {
        headline: this.translate.legalBasis,
        icon: 'clipboard',
        component: DataFlowWizardStepLegalBasis
      }, {
        headline: this.translate.dataRetention,
        icon: 'database',
        component: DataFlowWizardStepRetention
      }, {
        headline: systemName,
        headline2: systemName,
        icon: 'server',
        questionsString: this.metaOptions[DataFlowFields.SYSTEMS].questionTitles,
        component: DataFlowWizardStepSystems
      }];
    },
    activeQuestionIdsList: function activeQuestionIdsList() {
      return this.questions.reduce(function (result, question) {
        result[question.id] = true;
        return result;
      }, {});
    }
  },
  mounted: function mounted() {
    this.getAllData();
  },
  methods: {
    getAllData: function getAllData() {
      return this.getQuestionnaire(this.questionnaires[0].questionnaireId).then(this.getDataFlow);
    },
    getDataFlow: function getDataFlow() {
      var _this2 = this;
      return getAllOptions(this.projectId, this.nodeId, '?all=true').then(function (_ref) {
        var stakeholders = _ref.stakeholders,
          dataTypes = _ref.dataTypes,
          systems = _ref.systems;
        var dataStakeholders = _this2.filterOptionsByActiveQuestions(stakeholders, DataFlowFields.STAKEHOLDER_OPTIONS);
        var dataDataTypes = _this2.filterOptionsByActiveQuestions(dataTypes, DataFlowFields.DATA_TYPE_OPTIONS);
        var systemTypes = _this2.filterOptionsByActiveQuestions(systems, DataFlowFields.SYSTEMS);
        _this2.allStakeholders = sortAlphabetic(dataStakeholders, 'text');
        _this2.allDataTypes = sortAlphabetic(dataDataTypes, 'text');
        _this2.data[DataFlowFields.STAKEHOLDER_OPTIONS] = prepareGroupedStakeholdersOptions(dataStakeholders);
        _this2.data[DataFlowFields.DATA_TYPE_OPTIONS] = dataDataTypes;
        _this2.data[DataFlowFields.SYSTEMS] = prepareGroupedSystemsOptions(systemTypes);
      });
    },
    filterOptionsByActiveQuestions: function filterOptionsByActiveQuestions(options, dataFlowField) {
      var _this3 = this;
      return removeDuplicates(options || [], 'uniqueId').filter(function (option) {
        var _this3$metaOptions$da, _this3$metaOptions$da2, _this3$metaOptions$da3;
        switch (option.type) {
          case DataFlowOptionTypes.QUESTION_OPTION:
            return option.questionId && _this3.activeQuestionIdsList[option.questionId];
          case DataFlowOptionTypes.DATAPROCESSOR_PROCESSOR:
            return (_this3$metaOptions$da = _this3.metaOptions[dataFlowField]) === null || _this3$metaOptions$da === void 0 ? void 0 : _this3$metaOptions$da.isProcessorTypePresent;
          case DataFlowOptionTypes.DATAPROCESSOR_CONTROLLER:
            return (_this3$metaOptions$da2 = _this3.metaOptions[dataFlowField]) === null || _this3$metaOptions$da2 === void 0 ? void 0 : _this3$metaOptions$da2.isControllerTypePresent;
          case DataFlowOptionTypes.DATAPROCESSOR_SYSTEM:
            return (_this3$metaOptions$da3 = _this3.metaOptions[dataFlowField]) === null || _this3$metaOptions$da3 === void 0 ? void 0 : _this3$metaOptions$da3.isSystemTypePresent;
          default:
            return false;
        }
      });
    },
    getQuestionnaire: function getQuestionnaire(id) {
      var _this4 = this;
      this.questions = [];
      return getAllQuestionAnswersForNode(this.projectId, this.nodeId, id).then(function (_ref2) {
        var list = _ref2.list;
        _this4.questions = list;
        _this4.data[QUESTIONS] = list;
        _this4.metaOptions = _defineProperty(_defineProperty(_defineProperty({}, DataFlowFields.STAKEHOLDER_OPTIONS, _this4.prepareMetaData(list, QuestionObjFlags.DATAFLOW_STAKEHOLDER)), DataFlowFields.DATA_TYPE_OPTIONS, _this4.prepareMetaData(list, QuestionObjFlags.DATAFLOW_DATA_TYPE)), DataFlowFields.SYSTEMS, _this4.prepareMetaData(list, QuestionObjFlags.DATAFLOW_SYSTEM));
      });
    },
    updateSelected: function updateSelected(_ref3) {
      var _this5 = this;
      var value = _ref3.value,
        field = _ref3.field,
        additionalField = _ref3.additionalField;
      switch (field) {
        case DataFlowFields.CURRENT_TYPES_SELECTED:
          this.selectedOptions[DataFlowFields.CURRENT_TYPES_SELECTED] = value;
          this.selectedOptions[DataFlowFields.CURRENT_BASIS_SELECTED] = {};
          this.selectedOptions[DataFlowFields.CURRENT_BASIS_SELECTED_ALL_DATA] = {};
          this.selectedOptions[DataFlowFields.DATA_RETENTION_SELECTED] = {};
          this.selectedOptions[DataFlowFields.DATA_RETENTION_SELECTED_ALL_DATA] = {};
          this.selectedOptions[DataFlowFields.CURRENT_TYPES_SELECTED].forEach(function (value) {
            _this5.selectedOptions[DataFlowFields.CURRENT_BASIS_SELECTED][value.uniqueId] = null;
            _this5.selectedOptions[DataFlowFields.CURRENT_BASIS_SELECTED_ALL_DATA][value.uniqueId] = null;
            _this5.selectedOptions[DataFlowFields.DATA_RETENTION_SELECTED][value.uniqueId] = null;
            _this5.selectedOptions[DataFlowFields.DATA_RETENTION_SELECTED_ALL_DATA][value.uniqueId] = null;
          });
          break;
        case DataFlowFields.CURRENT_BASIS_SELECTED:
          this.selectedOptions[DataFlowFields.CURRENT_BASIS_SELECTED][additionalField] = value ? value.id : null;
          this.selectedOptions[DataFlowFields.CURRENT_BASIS_SELECTED_ALL_DATA][additionalField] = value || null;
          break;
        case DataFlowFields.DATA_RETENTION_SELECTED:
          this.selectedOptions[DataFlowFields.DATA_RETENTION_SELECTED][additionalField] = value ? value.id : null;
          this.selectedOptions[DataFlowFields.DATA_RETENTION_SELECTED_ALL_DATA][additionalField] = value || null;
          break;
        default:
          this.selectedOptions[field] = value;
          break;
      }
    },
    handleAccept: function handleAccept(_ref4) {
      var messageState = _ref4.messageState;
      this.loadState = true;
      this.handleUpdateStorage(messageState);
      var flowData = this.getPreparedDataFlowData();
      var updateQuestions = this.getAnsweredQuestionPromises(this.questions, flowData);
      this.handleApiQueue(flowData, updateQuestions);
    },
    handleApiQueue: function handleApiQueue(flowData, updateQuestions) {
      var _this6 = this;
      if (!updateQuestions.length) {
        return createFlow(this.projectId, this.nodeId, flowData).then(function () {
          var stakeholder = _this6.allStakeholders.find(function (stakeholder) {
            return stakeholder.uniqueId === _this6.selectedOptions[DataFlowFields.CURRENT_DATA_SUBJECT];
          });
          _this6.$notify({
            title: "".concat(_this6.translate.dataflowFor, " ").concat(stakeholder.text, " ").concat(_this6.translate.hasBeenCreated)
          });
          _this6.closeAll();
          _this6.getQuestionnaire(_this6.questionnaires[0].questionnaireId);
        }).catch(function (error) {
          var _error$response;
          return _this6.errorMessage = error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error;
        }).finally(function () {
          return _this6.loadState = false;
        });
      }
      var preparedQuestion = updateQuestions.shift();
      return updateQuestionForNode(this.projectId, this.nodeId, preparedQuestion).then(function () {
        return _this6.handleApiQueue(flowData, updateQuestions);
      }).catch(function (error) {
        var _error$response2;
        return _this6.errorMessage = error === null || error === void 0 || (_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.error;
      }).finally(function () {
        return _this6.loadState = false;
      });
    },
    handleStakeholderQuestionnaireUpdate: function handleStakeholderQuestionnaireUpdate(updateQuestions, questions, flowData) {
      var _this7 = this;
      if (flowData.stakeholder.type === DataFlowOptionTypes.QUESTION_OPTION) {
        var stakeholderQuestion = questions.find(function (item) {
          return item.id === flowData.stakeholder.questionId;
        });
        this.setAllUpdatedQuestions(updateQuestions, stakeholderQuestion, flowData.stakeholder);
      } else {
        questions.forEach(function (question) {
          if (question.questionobject.dataFlowStakeholder && _this7.canIABeInjected(question, flowData.stakeholder)) {
            _this7.setAllUpdatedQuestions(updateQuestions, question, flowData.stakeholder);
          }
        });
      }
    },
    handleDataTypeOptionQuestionnaireUpdate: function handleDataTypeOptionQuestionnaireUpdate(updateQuestions, questions, flowData) {
      var _this8 = this;
      flowData.dataTypes.forEach(function (dataType) {
        var dataTypeQuestion = questions.find(function (item) {
          return item.id === dataType.questionId;
        });
        _this8.setAllUpdatedQuestions(updateQuestions, dataTypeQuestion, dataType);
      });
    },
    handleBehindTypeOptionQuestionnaireUpdate: function handleBehindTypeOptionQuestionnaireUpdate(updateQuestions, questions, selectedData) {
      var _this9 = this;
      var values = Object.values(selectedData);
      values.forEach(function (behindType) {
        if (behindType) {
          var question = questions.find(function (question) {
            return question.id === behindType.questionId;
          });
          _this9.setAllUpdatedQuestions(updateQuestions, question, _objectSpread(_objectSpread({}, behindType), {}, {
            type: DataFlowOptionTypes.QUESTION_OPTION
          }));
        }
      });
    },
    handleSystemOptionQuestionnaireUpdate: function handleSystemOptionQuestionnaireUpdate(updateQuestions, questions, flowData) {
      var _this10 = this;
      questions.forEach(function (question) {
        if (question.questionobject.dataFlowSystem) {
          flowData.systems.forEach(function (system) {
            var multipleChoiceQuestion = question.questiontype === _this10.questionTypes.multipleChoice.id && system.type === DataFlowOptionTypes.QUESTION_OPTION;
            if (_this10.canIABeInjected(question, system) || multipleChoiceQuestion) {
              _this10.setAllUpdatedQuestions(updateQuestions, question, system);
            }
          });
        }
      });
    },
    getAnsweredQuestionPromises: function getAnsweredQuestionPromises(questions, flowData) {
      var updateQuestions = [];
      this.handleStakeholderQuestionnaireUpdate(updateQuestions, questions, flowData);
      this.handleDataTypeOptionQuestionnaireUpdate(updateQuestions, questions, flowData);
      this.handleBehindTypeOptionQuestionnaireUpdate(updateQuestions, questions, this.selectedOptions[DataFlowFields.CURRENT_BASIS_SELECTED_ALL_DATA]);
      this.handleBehindTypeOptionQuestionnaireUpdate(updateQuestions, questions, this.selectedOptions[DataFlowFields.DATA_RETENTION_SELECTED_ALL_DATA]);
      this.handleSystemOptionQuestionnaireUpdate(updateQuestions, questions, flowData);
      return updateQuestions;
    },
    canIABeInjected: function canIABeInjected(question, ia) {
      var informationAssetQuestionTypes = [DataFlowOptionTypes.DATAPROCESSOR_SYSTEM, DataFlowOptionTypes.DATAPROCESSOR_PROCESSOR];
      return question.questiontype === this.questionTypes.informationAsset.id && informationAssetQuestionTypes.includes(ia.type) || ia.type === DataFlowOptionTypes.DATAPROCESSOR_SYSTEM && question.questiontype === this.questionTypes.informationAssetSystems.id || ia.type === DataFlowOptionTypes.DATAPROCESSOR_PROCESSOR && question.questiontype === this.questionTypes.informationAssetProcessors.id || ia.type === DataFlowOptionTypes.DATAPROCESSOR_CONTROLLER && question.questiontype === this.questionTypes.informationAssetControllers.id;
    },
    setAllUpdatedQuestions: function setAllUpdatedQuestions(updateQuestions, question, element) {
      var _question$information, _question$information2, _question$information3;
      var data = {};
      var duplicateIndex = updateQuestions.findIndex(function (item) {
        return item.id === question.id;
      });
      switch (element.type) {
        case DataFlowOptionTypes.QUESTION_OPTION:
          if (question.answers_options && Object.keys(question.answers_options).find(function (item) {
            return +item === +element.id;
          })) return;
          if (duplicateIndex !== -1) {
            updateQuestions[duplicateIndex].answers_options[element.id] = element.text;
            return;
          }
          data = _objectSpread(_objectSpread({}, this.prepareDefaultQuestionProps(question)), {}, {
            answers_options: _objectSpread(_objectSpread({}, question.answers_options), {}, _defineProperty({}, element.id, element.text))
          });
          updateQuestions.push(data);
          break;
        case DataFlowOptionTypes.DATAPROCESSOR_PROCESSOR:
          if (question !== null && question !== void 0 && (_question$information = question.informationAssets) !== null && _question$information !== void 0 && _question$information.processors.find(function (item) {
            return item.id === element.id;
          })) return;
          if (duplicateIndex !== -1) {
            updateQuestions[duplicateIndex].informationAssets.processors.push(element.id);
            return;
          }
          data = _objectSpread(_objectSpread({}, this.prepareDefaultQuestionProps(question)), {}, {
            informationAssets: this.getIAByField(question.informationAssets, 'processors', element.id)
          });
          updateQuestions.push(data);
          break;
        case DataFlowOptionTypes.DATAPROCESSOR_CONTROLLER:
          if (question !== null && question !== void 0 && (_question$information2 = question.informationAssets) !== null && _question$information2 !== void 0 && _question$information2.controllers.find(function (item) {
            return item.id === element.id;
          })) return;
          if (duplicateIndex !== -1) {
            updateQuestions[duplicateIndex].informationAssets.controllers.push(element.id);
            return;
          }
          data = _objectSpread(_objectSpread({}, this.prepareDefaultQuestionProps(question)), {}, {
            informationAssets: this.getIAByField(question.informationAssets, 'controllers', element.id)
          });
          updateQuestions.push(data);
          break;
        case DataFlowOptionTypes.DATAPROCESSOR_SYSTEM:
          if (question !== null && question !== void 0 && (_question$information3 = question.informationAssets) !== null && _question$information3 !== void 0 && _question$information3.systems.find(function (item) {
            return item.id === element.id;
          })) return;
          if (duplicateIndex !== -1) {
            updateQuestions[duplicateIndex].informationAssets.systems.push(element.id);
            return;
          }
          data = _objectSpread(_objectSpread({}, this.prepareDefaultQuestionProps(question)), {}, {
            informationAssets: this.getIAByField(question.informationAssets, 'systems', element.id)
          });
          updateQuestions.push(data);
          break;
      }
    },
    getIAByField: function getIAByField(object, field, id) {
      var data = {};
      Object.keys(object).forEach(function (key) {
        if (key === field) {
          data[key] = [].concat(_toConsumableArray(object[key].map(function (item) {
            return item.id;
          })), [id]);
        } else {
          data[key] = object[key].map(function (item) {
            return item.id;
          });
        }
      });
      return data;
    },
    prepareDefaultQuestionProps: function prepareDefaultQuestionProps(_ref5) {
      var answers_options = _ref5.answers_options,
        files = _ref5.files,
        id = _ref5.id,
        informationAssets = _ref5.informationAssets,
        questionnumber = _ref5.questionnumber;
      return {
        answers_options: answers_options,
        files: files,
        id: id,
        informationAssets: informationAssets,
        questionnumber: questionnumber
      };
    },
    expandDataWithDataTypes: function expandDataWithDataTypes(data, selectedOptions, field, optionId, optionLabel) {
      Object.keys(selectedOptions[field]).forEach(function (basisKey) {
        if (selectedOptions[field][basisKey]) {
          var index = data.findIndex(function (type) {
            return type.uniqueId === basisKey;
          });
          if (index !== -1) {
            data[index][optionId] = selectedOptions[field][basisKey].id;
            data[index][optionLabel] = selectedOptions[field][basisKey].text;
          }
        }
      });
    },
    getPreparedDataFlowData: function getPreparedDataFlowData() {
      var _this11 = this;
      var dataTypes = this.selectedOptions[DataFlowFields.CURRENT_TYPES_SELECTED];
      this.expandDataWithDataTypes(dataTypes, this.selectedOptions, DataFlowFields.CURRENT_BASIS_SELECTED_ALL_DATA, 'legalBasisOptionId', 'legalBasisOptionLabel');
      this.expandDataWithDataTypes(dataTypes, this.selectedOptions, DataFlowFields.DATA_RETENTION_SELECTED_ALL_DATA, 'storagePeriodOptionId', 'storagePeriodOptionLabel');
      return {
        comments: this.selectedOptions[DataFlowFields.SYSTEMS_COMMENT],
        dataTypes: dataTypes,
        direction: this.selectedOptions[DataFlowFields.CURRENT_DATA_SUBJECT_DIRECTION],
        stakeholder: this.allStakeholders.find(function (item) {
          return item.uniqueId === _this11.selectedOptions[DataFlowFields.CURRENT_DATA_SUBJECT];
        }),
        systems: this.selectedOptions[DataFlowFields.SYSTEMS_SELECTED]
      };
    },
    prepareMetaData: function prepareMetaData() {
      var _this12 = this;
      var questions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var questionObjProp = arguments.length > 1 ? arguments[1] : undefined;
      var questionsList = [];
      questions.forEach(function (question) {
        if (!question.questionobject[questionObjProp]) return;
        questionsList.push({
          title: "".concat(_this12.translate.question, " ").concat(question.questionnumber),
          type: question.questiontype
        });
      });
      var questionTitles = questionsList.map(function (q) {
        return q.title;
      }).join(', ');
      var isControllerTypePresent = questionsList.some(function (question) {
        return question.type === _this12.questionTypes.informationAssetControllers.id;
      });
      var isProcessorTypePresent = questionsList.some(function (question) {
        return question.type === _this12.questionTypes.informationAssetProcessors.id || question.type === _this12.questionTypes.informationAsset.id;
      });
      var isSystemTypePresent = questionObjProp !== QuestionObjFlags.DATAFLOW_STAKEHOLDER && questionsList.some(function (question) {
        return question.type === _this12.questionTypes.informationAssetSystems.id || question.type === _this12.questionTypes.informationAsset.id;
      });
      return {
        questionsList: questionsList,
        questionTitles: questionTitles,
        questionObjProp: questionObjProp,
        isControllerTypePresent: isControllerTypePresent,
        isProcessorTypePresent: isProcessorTypePresent,
        isSystemTypePresent: isSystemTypePresent
      };
    },
    handleUpdateStorage: function handleUpdateStorage(value) {
      this.welcomePageState = value + '';
    },
    clearData: function clearData() {
      this.selectedOptions = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, DataFlowFields.CURRENT_DATA_SUBJECT, null), DataFlowFields.CURRENT_TYPES_SELECTED, []), DataFlowFields.CURRENT_BASIS_SELECTED, {}), DataFlowFields.CURRENT_BASIS_SELECTED_ALL_DATA, {}), DataFlowFields.DATA_RETENTION_SELECTED, {}), DataFlowFields.DATA_RETENTION_SELECTED_ALL_DATA, {}), DataFlowFields.SYSTEMS_SELECTED, []), DataFlowFields.SYSTEMS_COMMENT, ''), DataFlowFields.CURRENT_DATA_SUBJECT_DIRECTION, 'deliver');
      this.data[DataFlowFields.DATA_TYPE_OPTIONS] = this.allDataTypes;
    },
    handleDismiss: function handleDismiss() {
      var _this13 = this;
      var isDirty = this.selectedOptions[DataFlowFields.CURRENT_DATA_SUBJECT];
      if (isDirty) {
        this.$confirm(this.translate.areYouSure, this.translate.ifYouDeleteThisDraftTheEnteredDataWillNo, function (res) {
          return res && _this13.closeAll();
        });
        return;
      }
      this.closeAll();
    },
    closeAll: function closeAll() {
      this.clearData();
      this.isOpened = false;
    }
  }
};