import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, mergeProps as _mergeProps } from "vue";
var _hoisted_1 = ["title"];
var _hoisted_2 = {
  key: 0,
  class: "mt-1 glob-l1 text-rm-signal-red"
};
var _hoisted_3 = {
  key: 1,
  class: "mt-1 glob-l1 text-rm-signal-red"
};
var _hoisted_4 = {
  key: 0,
  class: "mt-1 glob-l1 text-rm-signal-red"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_drag_and_drop_icon = _resolveComponent("drag-and-drop-icon");
  var _component_input_field = _resolveComponent("input-field");
  var _component_overlay_delete_button = _resolveComponent("overlay-delete-button");
  var _component_draggable = _resolveComponent("draggable");
  var _component_add_option_button = _resolveComponent("add-option-button");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_draggable, _mergeProps({
    list: $data.options,
    disabled: $props.readOnly || !$props.draggable,
    tag: "div",
    "item-key": "index",
    "component-data": {
      type: 'transition'
    }
  }, $data.dragOptions, {
    handle: ".drag-icon",
    onStart: _cache[0] || (_cache[0] = function ($event) {
      return $data.itemToDragIdx = $event.oldIndex;
    }),
    onEnd: _cache[1] || (_cache[1] = function ($event) {
      return $data.itemToDragIdx = null;
    }),
    onChange: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('onOptionsChange', $data.options);
    })
  }), {
    item: _withCtx(function (_ref) {
      var element = _ref.element,
        index = _ref.index;
      return [_createVNode(_component_overlay_delete_button, {
        "icon-visible": !$props.readOnly,
        class: "mb-3",
        onOnDeleteIconClick: function onOnDeleteIconClick($event) {
          return $options.handleDeleteOption(index);
        }
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", {
            class: "relative",
            title: element[$props.labelProperty]
          }, [!$props.readOnly && $props.draggable ? (_openBlock(), _createBlock(_component_drag_and_drop_icon, {
            key: 0,
            class: "drag-icon absolute z-2 left-2 top-1/2 transform -translate-y-1/2"
          })) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
            "model-value": element[$props.labelProperty],
            placeholder: $props.placeholder,
            enabled: !$props.readOnly,
            "focus-on-mount": $data.hasFocusOnMount,
            class: "w-full input-field",
            "external-classes": 'truncate' + $options.getInputCalculatedClasses(element, index) + (!$props.readOnly && $props.draggable ? ' pl-7' : ''),
            "external-styles": ($data.itemToDragIdx === index ? 'border-color: rgb(33, 130, 253);' : '') + (!$props.readOnly ? '' : ''),
            type: "text",
            "onUpdate:modelValue": function onUpdateModelValue($event) {
              return $options.onOptionChange($event, index);
            }
          }, null, 8 /* PROPS */, ["model-value", "placeholder", "enabled", "focus-on-mount", "external-classes", "external-styles", "onUpdate:modelValue"])], 8 /* PROPS */, _hoisted_1), $props.showUniqueFieldsError && !element.hideError && $options.uniqueFieldsIdxError[index] ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($data.translate.valueShouldBeUnique), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $props.showEmptyFieldsError && !element.hideError && !element[$props.labelProperty] ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($data.translate.pleaseFillOutThisField), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["icon-visible", "onOnDeleteIconClick"])];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["list", "disabled"]), $props.errorMessage ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.errorMessage), 1 /* TEXT */)) : _createCommentVNode("v-if", true), !$props.readOnly ? (_openBlock(), _createBlock(_component_add_option_button, {
    key: 1,
    class: "mt-2",
    onClick: $options.handleAddOption
  }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]);
}