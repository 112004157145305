import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "relative"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default"), _withDirectives(_createElementVNode("div", {
    class: _normalizeClass(["p-1 flex justify-center absolute cursor-pointer", {
      '-right-3.5 -top-3.5 items-center': $props.inset === $data.insetTypes.DEFAULT,
      '-right-1 -top-1 items-start': $props.inset === $data.insetTypes.ZERO
    }]),
    onMouseenter: _cache[1] || (_cache[1] = function ($event) {
      return $data.deleteIconHover = true;
    }),
    onMouseleave: _cache[2] || (_cache[2] = function ($event) {
      return $data.deleteIconHover = false;
    }),
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('onDeleteIconClick');
    })
  }, [_createElementVNode("button", {
    class: _normalizeClass(["group rounded-full outline-none border border-rm-grey bg-white flex items-center justify-center shadow-rm-gray--20 focus:border-rm-signal-red", {
      'border-rm-signal-red': $data.deleteIconHover
    }]),
    tabindex: "0",
    style: _normalizeStyle({
      width: $props.circleSize + 'px',
      height: $props.circleSize + 'px'
    }),
    onEnter: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('onDeleteIconClick');
    })
  }, [_createVNode(_component_feather_icon, {
    class: _normalizeClass(["stroke-2 group-focus:text-rm-signal-red", [{
      'text-rm-signal-red': $data.deleteIconHover
    }, $props.iconColorClass]]),
    width: $props.iconSize,
    height: $props.iconSize,
    icon: $props.icon
  }, null, 8 /* PROPS */, ["class", "width", "height", "icon"])], 38 /* CLASS, STYLE, NEED_HYDRATION */)], 34 /* CLASS, NEED_HYDRATION */), [[_vShow, $props.iconVisible]])]);
}