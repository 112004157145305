import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap pt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_custom_field_text_box = _resolveComponent("custom-field-text-box");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_custom_field_text_box, {
    field: $data.fieldTwo,
    class: "w-400px mb-10",
    "local-storage-key": "test-resize-storage"
  }, null, 8 /* PROPS */, ["field"]), _createVNode(_component_custom_field_text_box, {
    field: $data.fieldOne,
    disabled: true,
    class: "w-400px"
  }, null, 8 /* PROPS */, ["field"])]);
}