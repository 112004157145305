import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ai_assistant_icon = _resolveComponent("ai-assistant-icon");
  var _component_dynamic_tooltip = _resolveComponent("dynamic-tooltip");
  return !_ctx.aiTermsAccepted ? (_openBlock(), _createBlock(_component_dynamic_tooltip, {
    key: 0,
    title: "RISMA AI Partner",
    "initial-mode": $props.mode,
    position: $props.position,
    "tooltip-styles": $props.tooltipStyles,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('close:tooltip');
    })
  }, {
    tooltipBody: _withCtx(function () {
      return [_createElementVNode("div", {
        class: "w-250px",
        onClick: _cache[0] || (_cache[0] = function () {
          return $options.handleTooltipClick && $options.handleTooltipClick.apply($options, arguments);
        }),
        innerHTML: $options.tooltipText
      }, null, 8 /* PROPS */, _hoisted_1)];
    }),
    tooltipTrigger: _withCtx(function () {
      return [_createElementVNode("button", {
        class: _normalizeClass($options.btnClasses),
        disabled: "true"
      }, [_createVNode(_component_ai_assistant_icon, _mergeProps($options.iconStyles, {
        class: "m-auto"
      }), null, 16 /* FULL_PROPS */)], 2 /* CLASS */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["initial-mode", "position", "tooltip-styles"])) : (_openBlock(), _createElementBlock("button", {
    key: 1,
    class: _normalizeClass($options.btnClasses),
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('click:icon');
    })
  }, [_createVNode(_component_ai_assistant_icon, _mergeProps($options.iconStyles, {
    class: "m-auto",
    "title-attr-enabled": true
  }), null, 16 /* FULL_PROPS */)], 2 /* CLASS */));
}