function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import GapAttributesList from '@/components/Molecules/Admin/GapAttributesList';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { getAttributes, setAttributes } from '@/api/admin/gapschema';
import { generateKey } from '@/utils/Utils';
import { getTranslate } from './translate';
export default {
  name: 'GapSchemasAdminAttributes',
  components: {
    RismaButton: RismaButton,
    FeatherIcon: FeatherIcon,
    GapAttributesList: GapAttributesList,
    Notification: Notification,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    gapSchemaId: {
      type: [String, Number],
      required: true
    }
  },
  emits: ['notify'],
  data: function data() {
    return {
      initialAttributes: [],
      attributesArr: [],
      errors: {},
      notificationMessage: '',
      loading: false,
      saveButtonClickCounter: 0,
      translate: getTranslate['GapSchemasAdminAttributes']()
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.loading = true;
    getAttributes(this.gapSchemaId).then(function (_ref) {
      var attributes = _ref.attributes;
      _this.attributesArr = attributes.map(function (attribute) {
        attribute.key = generateKey();
        return attribute;
      });
      _this.initialAttributes = cloneDeep(_this.attributesArr);
    }).finally(function () {
      return _this.loading = false;
    });
  },
  methods: {
    onAttributeCreate: function onAttributeCreate() {
      this.attributesArr.push({
        category: '',
        labels: [''],
        key: generateKey()
      });
    },
    onUpdateCategory: function onUpdateCategory(newCategory, idx) {
      if (!this.attributesArr[idx]) return;
      this.attributesArr[idx] = _objectSpread(_objectSpread({}, this.attributesArr[idx]), {}, {
        category: newCategory
      });
    },
    onUpdateLabels: function onUpdateLabels(newLabels, idx) {
      if (!this.attributesArr[idx]) return;
      if (!newLabels || !newLabels.length) {
        this.attributesArr.splice(idx, 1);
        return;
      }
      this.attributesArr[idx].labels = newLabels;
    },
    handleValidation: function handleValidation() {
      this.errors = _objectSpread({}, this.validateAttributes());
      return !Object.values(this.errors.labels).some(Boolean) && !this.errors.category;
    },
    validateAttributes: function validateAttributes() {
      var _this2 = this;
      var errors = {
        labels: []
      };
      var categoryMap = {};
      var categoryErrors = {};
      this.attributesArr.forEach(function (_ref2, categoryIdx) {
        var category = _ref2.category,
          labels = _ref2.labels;
        category = category ? category.toLowerCase() : '';
        var attributesMap = {};
        var attributeErrors = false;
        attributeErrors = labels.some(function (attr, idx) {
          attr = attr.label ? attr.label.toLowerCase() : '';
          if (!attr || attributesMap[attr] !== undefined) {
            return true;
          }
          attributesMap[attr] = idx;
        });
        errors.labels[categoryIdx] = attributeErrors;
        if (!category) {
          categoryErrors[categoryIdx] = _this2.translate.pleaseFillOutThisField;
          return;
        }
        if (categoryMap[category] !== undefined) {
          var matchIdx = categoryMap[category];
          categoryErrors[categoryIdx] = _this2.translate.valueShouldBeUnique;
          categoryErrors[matchIdx] = _this2.translate.valueShouldBeUnique;
        } else {
          categoryMap[category] = categoryIdx;
        }
      });
      if (Object.values(categoryErrors).length) {
        errors.category = categoryErrors;
      }
      return errors;
    },
    onSave: function onSave() {
      var _this3 = this;
      this.notificationMessage = '';
      this.saveButtonClickCounter++;
      if (isEqual(this.initialAttributes, this.attributesArr)) {
        this.notificationMessage = this.translate.noChangesToSave;
        return;
      }
      if (!this.handleValidation()) {
        this.$nextTick(function () {
          var el = _this3.$el.querySelector('.option-list-error');
          if (el) {
            _this3.$scrollTo(el, 100, {
              offset: -150
            });
          }
        });
      } else {
        this.loading = true;
        setAttributes(this.gapSchemaId, this.attributesArr).then(function () {
          _this3.$emit('notify', {
            message: _this3.translate.attributesAreSaved
          });
          _this3.initialAttributes = cloneDeep(_this3.attributesArr);
        }).catch(function () {
          _this3.$emit('notify', {
            message: _this3.translate.unexpectedError,
            type: 'error'
          });
        }).finally(function () {
          _this3.loading = false;
        });
      }
    }
  }
};