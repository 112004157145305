import cloneDeep from 'lodash/cloneDeep';
export var DROP_TYPE = {
  before: 'before',
  inside: 'inside',
  after: 'after'
};
export var determineDropPosition = function determineDropPosition(node, mouseEvent) {
  var rect = node.getBoundingClientRect();
  var nodeTop = rect.top;
  var nodeHeight = rect.height;
  var mouseY = mouseEvent.clientY;
  var rel = Math.max(0, mouseY - nodeTop);
  var left, top;
  var type;
  if (rel < nodeHeight / 3) {
    type = DROP_TYPE.before;
    left = rect.left;
    top = nodeTop;
  } else if (rel > nodeHeight - nodeHeight / 3) {
    type = DROP_TYPE.after;
    left = rect.left;
    top = rect.bottom;
  } else {
    type = DROP_TYPE.inside;
    left = rect.left - 2;
    top = nodeTop + nodeHeight / 2;
  }
  return {
    left: left,
    top: top + window.scrollY,
    type: type
  };
};
export var removeItemAtPath = function removeItemAtPath(tree, path) {
  var node = tree;
  for (var i = 0; i < path.length - 1; i++) {
    if (!node || !node[path[i]]) {
      return null;
    }
    node = node[path[i]].children;
  }
  return node.splice(path[path.length - 1], 1)[0];
};
export var insertItemAtPath = function insertItemAtPath(tree, path, dropType, item) {
  var node = tree;
  for (var i = 0; i < path.length - 1; i++) {
    node = node[path[i]].children;
  }
  var index = path[path.length - 1];
  if (dropType === DROP_TYPE.inside) {
    node[index].children.push(item);
  } else {
    var toPosition = +index + (dropType === DROP_TYPE.after ? 1 : 0);
    node.splice(toPosition, 0, item);
  }
};
export var adjustTargetPath = function adjustTargetPath(fromPath, toPath) {
  if (fromPath.length > toPath.length) return;
  var commonDepth = Math.min(fromPath.length, toPath.length);
  if (fromPath.slice(0, commonDepth - 1).join(',') !== toPath.slice(0, commonDepth - 1).join(',')) return;
  if (+fromPath[commonDepth - 1] < +toPath[commonDepth - 1]) {
    toPath[commonDepth - 1]--;
    return;
  }
  return;
};
export var moveItemInList = function moveItemInList(listBase, fromPath, toPath, dropType) {
  var list = cloneDeep(listBase);
  adjustTargetPath(fromPath, toPath);
  var removedItem = removeItemAtPath(list, fromPath);
  insertItemAtPath(list, toPath, dropType, removedItem);
  return list;
};
export var arePathsMatchingExceptLast = function arePathsMatchingExceptLast(fromPath, toPath) {
  if (fromPath.length !== toPath.length) return false;
  return fromPath.slice(0, -1).join(',') === toPath.slice(0, -1).join(',');
};
export var isDropInsideUnchanged = function isDropInsideUnchanged(fromPath, toPath, dropType, childrenLength) {
  var isParentSame = fromPath.slice(0, -1).join(',') === toPath.join(',');
  var isLastElementValid = +fromPath[fromPath.length - 1] === childrenLength - 1;
  return fromPath.length - 1 === toPath.length && isParentSame && dropType === DROP_TYPE.inside && isLastElementValid;
};
var isDropTypeValid = function isDropTypeValid(fromLast, toLast, dropType) {
  return fromLast + 1 === toLast && dropType === DROP_TYPE.before || fromLast - 1 === toLast && dropType === DROP_TYPE.after;
};
export var isDropPositionSame = function isDropPositionSame(fromPath, toPath, dropType, relatedChildrenLength) {
  if (isDropInsideUnchanged(fromPath, toPath, dropType, relatedChildrenLength)) return true;
  if (!arePathsMatchingExceptLast(fromPath, toPath)) return false;
  var fromLast = +fromPath[fromPath.length - 1];
  var toLast = +toPath[toPath.length - 1];
  return isDropTypeValid(fromLast, toLast, dropType);
};