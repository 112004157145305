function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useWorkflowStore } from '@/Store/workflowStore';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TabList from '@/components/Atoms/TabList';
import SelectorTags from '@/components/Molecules/SelectorTags';
import LinksModal from '@/components/Organisms/Links/LinksModal';
import LinksDepPreview from '@/components/Organisms/Links/LinksDepPreview';
import QuestionPreviewList from '@/components/Organisms/Links/QuestionPreviewList';
import ActionGap from '@/components/Pages/Actions/ActionGap';
import { LinkTabTitles, TabTypes } from '@/constants/Tabs';
import { getModuleFromObjType, ObjectTypes } from '@/constants/ObjectTypes';
import { itemTitleByModule, MODULES } from '@/constants/modules';
import { getProjectsOptionsLinksTab, GovernanceReviewOptionsLinksTab, RiskAssessmentActivityLinksTab, getProjectRiskAssessmentLinksTab } from '@/constants/links/LinksModalSettings';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import { WrongModules } from '@/constants/WrongModules';
import * as LinksTabUtils from '@/utils/linksTabUtils';
import { compareStrings } from '@/utils/compare';
import { isLockedActionUser, isLockedControlUser, isLockedIncidentUser, isLockedRiskUser, userHasProjectAccess, isLockedProcessLibrary } from '@/utils/access';
import { LinksTabGetDataMixin } from '@/components/Organisms/LinksTabGetDataMixin';
import { LinksTabHandlePreviewMixin } from '@/components/Organisms/LinksTabHandlePreviewMixin';
import { InformationasstesOverviewMixin } from '@/mixins/InformationAssetsOverview';
var LinkTypes = {
  MITIGATING_ACTIVITIES: 'mitigatingActivities',
  GAP_ANALYSIS: 'gapAnalysis'
};
export default {
  name: 'LinksTab',
  introduction: 'Universal tab with links',
  description: 'A component used in all DEPs to show all the linked items',
  token: '<links-tab></links-tab>',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    LinksModal: LinksModal,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    SelectorTags: SelectorTags,
    LinksDepPreview: LinksDepPreview,
    QuestionPreviewList: QuestionPreviewList,
    TabList: TabList,
    ActionGap: ActionGap
  },
  mixins: [InformationasstesOverviewMixin, LinksTabGetDataMixin, LinksTabHandlePreviewMixin],
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'data object'
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    return {
      isLoaded: false,
      showLinksModal: false,
      linksOptions: [],
      workflows: {},
      // Dataset keys must be initialized with keys for reactivity to work
      dataset: Object.keys(MODULES).reduce(function (acc, module) {
        acc[module] = null;
        return acc;
      }, {}),
      loadedModulesMap: [],
      preselectedLinks: [],
      activityList: [],
      projects: [],
      MODULES: MODULES,
      itemTitleByModule: itemTitleByModule(this.$trans),
      scoring: [],
      projectsOptions: [],
      projectOptionsWithDefault: [],
      currentTab: TabTypes.LINKS,
      anotherLinks: _defineProperty(_defineProperty({}, TabTypes.GAPLINKS, {}), TabTypes.QUESTIONNAIRE, {}),
      tabTypes: TabTypes,
      selectedTag: {},
      controls: [],
      GovernanceReviewOptionsLinksTab: GovernanceReviewOptionsLinksTab,
      itemDeletionData: null,
      unfilteredModules: [MODULES.COMPLIANCE_PROCESS_TREE_NODE, MODULES.CONTROLS, MODULES.RISK, MODULES.COMPLIANCE, MODULES.COMPLIANCE_INFORMATION_ASSETS],
      LinkTypes: LinkTypes,
      translate: getTranslate['LinksTab']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    allSettings: 'getAllSettings'
  })), mapState(useSettingsStore, ['isSystemsEnabled', 'isDataControllersEnabled', 'isDataProcessorsEnabled', 'isIACustomEnabled'])), mapState(useUserStore, {
    currentUserRiskProjectById: 'riskProjectById',
    currentUserComplianceProjectById: 'complianceProjectById',
    showGapByProjectId: 'showGapByProjectId'
  })), mapState(useUserStore, ['isAdmin', 'currentUser'])), mapState(useOrganisationsStore, ['organisations'])), mapState(useUsersStore, {
    users: 'activeUsers',
    unfilteredUsers: 'users'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'complianceIACustomActivityTypes'
  })), mapState(useWorkflowStore, ['getAllStatuses'])), {}, {
    ProjectsOptionsLinksTab: function ProjectsOptionsLinksTab() {
      return getProjectsOptionsLinksTab(this.allSettings, this.currentUser);
    },
    disabledOptions: function disabledOptions() {
      return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, MODULES.CONTRACTS, !this.getSettingValue('app.module_contracts_enabled')), MODULES.COMPANY, !this.getSettingValue('app.module_companies_enabled')), MODULES.COMPLIANCE_IA_SYSTEM, !this.isSystemsEnabled), MODULES.COMPLIANCE_IA_CONTROLLER, !this.isDataControllersEnabled), MODULES.COMPLIANCE_IA_PROCESSOR, !this.isDataProcessorsEnabled), MODULES.COMPLIANCE_INFORMATION_ASSETS, !this.isIACustomEnabled);
    },
    allProjectOptions: function allProjectOptions() {
      return [].concat(_toConsumableArray(this.projectOptionsWithDefault), [this.GovernanceReviewOptionsLinksTab, getProjectRiskAssessmentLinksTab(), RiskAssessmentActivityLinksTab]);
    },
    currentProject: function currentProject() {
      if (this.module === MODULES.RISK) {
        return this.currentUserRiskProjectById(this.data.projectId);
      } else if (this.module === MODULES.COMPLIANCE) {
        return this.currentUserComplianceProjectById(this.data.compliance_projects_id);
      } else {
        return {};
      }
    },
    isReadOnlyAccess: function isReadOnlyAccess() {
      if (this.readOnly) {
        return this.readOnly;
      }
      switch (this.module) {
        case MODULES.INCIDENTS:
          return isLockedIncidentUser(this.currentUser);
        case MODULES.RISK:
          return isLockedRiskUser(this.currentUser);
        case MODULES.EXECUTION:
          return !!(this.data.closed || isLockedActionUser(this.currentUser));
        case MODULES.CONTROLS:
          return isLockedControlUser(this.currentUser) || !this.data.active;
        case MODULES.COMPLIANCE_PROCESS_TREE_NODE:
          return isLockedProcessLibrary(this.currentUser);
        default:
          return !!this.currentProject.locked;
      }
    },
    hasTabs: function hasTabs() {
      return !!(Object.keys(this.anotherLinks[TabTypes.QUESTIONNAIRE]).length || Object.keys(this.anotherLinks[TabTypes.GAPLINKS]).length);
    },
    listTitle: function listTitle() {
      switch (this.currentTab) {
        case TabTypes.GAPLINKS:
          return this.translate.compliance;
        default:
          return this.data && (this.data.title || this.data.name) || '';
      }
    },
    linkTabs: function linkTabs() {
      var list = [{
        name: this.$trans(LinkTabTitles[this.data.objType]),
        slotName: TabTypes.LINKS
      }];
      if (Object.keys(this.anotherLinks[TabTypes.QUESTIONNAIRE]).length) {
        list.push({
          name: this.translate.questionnaire,
          slotName: TabTypes.QUESTIONNAIRE
        });
      }
      if (Object.keys(this.anotherLinks[TabTypes.GAPLINKS]).length) {
        list.push({
          name: this.translate.gapAnalysis,
          slotName: TabTypes.GAPLINKS
        });
      }
      return list;
    },
    itemsByTab: function itemsByTab() {
      if (this.currentTab === TabTypes.LINKS) {
        return this.linkItems;
      } else {
        return this.anotherLinks[this.currentTab];
      }
    },
    filteredItems: function filteredItems() {
      var _this = this;
      var keys = Object.keys(this.itemsByTab);
      var result = {};
      keys.forEach(function (key) {
        if (_this.itemsByTab[key].items && _this.itemsByTab[key].items.length) {
          result[key] = _this.itemsByTab[key];
        }
      });
      return result;
    },
    sortedItems: function sortedItems() {
      return new Map(Object.entries(this.filteredItems).sort(function (a, b) {
        return compareStrings(a[1].moduleTitle, b[1].moduleTitle);
      }));
    },
    module: function module() {
      return getModuleFromObjType(this.data.objType);
    },
    links: {
      get: function get() {
        var links = _objectSpread({}, this.data.links);
        WrongModules.forEach(function (wrongModule) {
          delete links[wrongModule];
        });
        return links;
      },
      set: function set(links) {
        this.$emit('propertyChanged', {
          property: 'links',
          value: links
        });
      }
    },
    linkItems: function linkItems() {
      var _this2 = this;
      var linkItems = {};
      Object.keys(this.links).forEach(function (module) {
        var _linkItems$module, _linkItems$module2;
        if (!_this2.projectOptionExistsByModule(module) && module !== MODULES.RISK_ASSESSMENT) {
          return;
        }
        if (_this2.links[module].length && module === MODULES.CONTROLS) {
          linkItems[module] = {
            items: _this2.getLinkItems(_this2.links[module], _this2.controls)
          };
        } else if (_this2.links[module].length && _this2.dataset[module]) {
          linkItems[module] = {
            items: _this2.getLinkItems(_this2.links[module], _this2.dataset[module])
          };
        }
        if ((module === MODULES.COMPLIANCE || module === MODULES.RISK) && (_linkItems$module = linkItems[module]) !== null && _linkItems$module !== void 0 && _linkItems$module.items) {
          var additionalContent = _this2.prepareDatasetByAdditionalContent(linkItems[module].items, module, 'compliance_projects_id', 'projectId');
          Object.keys(additionalContent).forEach(function (key) {
            linkItems[key] = {
              items: additionalContent[key]
            };
          });

          //transform compliance[3] => compliance-1[2], compliance-2[1]
          delete linkItems[module];
        }
        if (module === MODULES.COMPLIANCE_INFORMATION_ASSETS && (_linkItems$module2 = linkItems[module]) !== null && _linkItems$module2 !== void 0 && _linkItems$module2.items) {
          var _additionalContent = _this2.prepareDatasetByAdditionalContent(linkItems[module].items, module, 'activity_id');
          Object.keys(_additionalContent).forEach(function (key) {
            linkItems[key] = {
              items: _additionalContent[key]
            };
          });
          delete linkItems[module];
        }
        if (module === MODULES.COMPANY) {
          var _linkItems$module3;
          if ((_linkItems$module3 = linkItems[module]) !== null && _linkItems$module3 !== void 0 && _linkItems$module3.items) {
            linkItems[module].items = linkItems[module].items.map(function (item) {
              return _objectSpread(_objectSpread({}, item), {}, {
                trafficLight: item.trafficLight ? item.trafficLight : {}
              });
            });
          }
        }
      });
      if (this.data.riskAssessmentActivity) {
        linkItems.riskAssessmentActivity = {
          items: [this.data.riskAssessmentActivity]
        };
      }
      if (this.governanceReviewLinkId) {
        linkItems.governanceReviewLinks = {
          items: this.getGovernanceReviewLinkNode()
        };
      }
      Object.keys(linkItems).forEach(function (linkKey) {
        linkItems[linkKey] = _objectSpread(_objectSpread({}, linkItems[linkKey]), {}, {
          module: linkKey,
          moduleTitle: LinksTabUtils.getProjectOptionByModule(linkKey, _this2.allProjectOptions).label,
          hasTrafficLight: LinksTabUtils.isModuleHasTrafficLight(LinksTabUtils.getPurifiedModuleName(linkKey)),
          hasDeleteOption: _this2.hasDeleteOptions(linkKey) && !_this2.isReadOnlyAccess
        });
      });
      return linkItems;
    },
    governanceReviewLinkModule: function governanceReviewLinkModule() {
      if (!this.data.governanceReviewLinks) return;
      return Object.keys(this.data.governanceReviewLinks)[0];
    },
    governanceReviewLinkId: function governanceReviewLinkId() {
      if (!this.governanceReviewLinkModule) return;
      return this.data.governanceReviewLinks[this.governanceReviewLinkModule][0];
    },
    governanceReviewLinks: function governanceReviewLinks() {
      if (!this.governanceReviewLinkModule) return [];
      return this.data.governanceReviewLinks[this.governanceReviewLinkModule];
    },
    isNotFiltered: function isNotFiltered() {
      return this.unfilteredModules.includes(this.module);
    },
    availableActivitiesDiff: function availableActivitiesDiff() {
      var allActivities = Object.values(this.links).reduce(function (acc, el) {
        return acc + el.length;
      }, 0);
      var filteredActivities = Object.values(this.filteredItems).reduce(function (acc, el) {
        return acc + el.items.length;
      }, 0);
      var additionalActivities = this.data.riskAssessmentActivity ? this.governanceReviewLinks.length + 1 : this.governanceReviewLinks.length;
      return additionalActivities + allActivities - filteredActivities;
    }
  }),
  watch: {
    listTitle: function listTitle(newValue) {
      var _this$dataset$MODULES;
      if (this.loadedModulesMap.includes(MODULES.RISK_ASSESSMENT) && (_this$dataset$MODULES = this.dataset[MODULES.RISK_ASSESSMENT]) !== null && _this$dataset$MODULES !== void 0 && (_this$dataset$MODULES = _this$dataset$MODULES[0]) !== null && _this$dataset$MODULES !== void 0 && _this$dataset$MODULES.title) {
        this.dataset[MODULES.RISK_ASSESSMENT][0].title = newValue;
      }
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    this.getProjects().then(this.setExpandedProjectsOptionsList).then(this.expandDataset).then(this.getWorkflowStatuses(MODULES.CONTRACTS)).then(this.preloadSelectedItems).then(this.getScoringForERM).then(this.loadAdditionalData).finally(function () {
      return _this3.isLoaded = true;
    });
  },
  methods: {
    setCurrentTab: function setCurrentTab(tab) {
      this.currentTab = tab;
      this.previewData.data = null;
      this.previewData.list = null;
      this.selectedTag = {};
    },
    getDefaultProjectsOptions: function getDefaultProjectsOptions() {
      var _this4 = this;
      var availableProjectOptions = this.ProjectsOptionsLinksTab.filter(function (_ref2) {
        var value = _ref2.value;
        return !_this4.disabledOptions[value];
      });
      var filteredProjects = this.filterSelfModules(availableProjectOptions);
      return this.translateDefaultProjectsOptions(filteredProjects);
    },
    filterSelfModules: function filterSelfModules(projects) {
      var _this5 = this;
      return this.isNotFiltered ? projects : projects.filter(function (project) {
        return project.value !== _this5.module;
      });
    },
    translateDefaultProjectsOptions: function translateDefaultProjectsOptions(options) {
      var _this6 = this;
      return options.map(function (_ref3) {
        var value = _ref3.value,
          label = _ref3.label,
          rights = _ref3.rights,
          _ref3$showAddItemButt = _ref3.showAddItemButton,
          showAddItemButton = _ref3$showAddItemButt === void 0 ? false : _ref3$showAddItemButt;
        return {
          value: value,
          label: _this6.$trans(label),
          rights: rights,
          showAddItemButton: showAddItemButton
        };
      });
    },
    getLinkItems: function getLinkItems() {
      var items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      return items.map(function (item) {
        return list.find(function (l) {
          return l.id === item;
        });
      }).filter(Boolean);
    },
    formatListToDataset: function formatListToDataset(list) {
      return list.map(function (item) {
        if (item.traffic_light !== undefined) item.trafficLight = item.traffic_light;
        return item;
      });
    },
    expandDataset: function expandDataset() {
      var _this7 = this;
      this.projects.forEach(function (item) {
        var module = LinksTabUtils.getPurifiedModuleName(item.objType);
        _this7.dataset["".concat(module, "-").concat(item.id)] = null;
      });
      this.activityList.forEach(function (item) {
        var module = item.objType;
        _this7.dataset["".concat(module, "-").concat(item.id)] = null;
      });
    },
    prepareDatasetByAdditionalContent: function prepareDatasetByAdditionalContent(data, module) {
      for (var _len = arguments.length, props = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        props[_key - 2] = arguments[_key];
      }
      var result = {};
      data.forEach(function (item) {
        var property = props.find(function (prop) {
          return !!item[prop];
        });
        var key = "".concat(module, "-").concat(item[property]);
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(item);
      });
      result[module] = data;
      return result;
    },
    setLinksOption: function setLinksOption(datasetKey) {
      var _this8 = this;
      var module = LinksTabUtils.getPurifiedModuleName(datasetKey);
      this.linksOptions = [];
      if (WrongModules.indexOf(module) !== -1) {
        return;
      }
      var promise = this.loadedModulesMap.includes(module) ? Promise.resolve() : this.getPromise(module);
      promise.then(function () {
        var linksOptions = _this8.dataset[datasetKey] || [];
        if (module === MODULES.COMPANY) {
          linksOptions = linksOptions.map(function (item) {
            return _objectSpread(_objectSpread({}, item), {}, {
              trafficLight: item.trafficLight ? item.trafficLight : {}
            });
          });
        }
        if (_this8.module === module) {
          linksOptions = linksOptions.filter(function (item) {
            return item.id !== _this8.data.id;
          });
        }
        _this8.linksOptions = linksOptions;
      });
      this.setPreselectedLinks(datasetKey);
    },
    setPreselectedLinks: function setPreselectedLinks(module) {
      if (!this.linkItems[module]) {
        this.preselectedLinks = [];
        return;
      }
      this.preselectedLinks = _toConsumableArray(this.linkItems[module].items);
    },
    toggleLinksModal: function toggleLinksModal() {
      this.preselectedLinks = [];
      this.showLinksModal = !this.showLinksModal;
    },
    onUpdateLinks: function onUpdateLinks(_ref4) {
      var _this9 = this;
      var module = _ref4.module,
        links = _ref4.links;
      var moduleName = LinksTabUtils.getPurifiedModuleName(module);
      this.toggleLinksModal();
      var temporaryLinks = [];
      if (moduleName !== module && this.links[moduleName]) {
        //code for compl and risk projects and their update handle
        this.links[moduleName].forEach(function (id) {
          if (_this9.dataset[module].find(function (item) {
            return id === item.id;
          })) {
            var index = links.indexOf(id);
            if (index !== -1) {
              temporaryLinks.push(id);
              links.splice(index, 1);
            }
          } else {
            temporaryLinks.push(id);
          }
        });
      }
      temporaryLinks = temporaryLinks.concat(links);
      this.links = _objectSpread(_objectSpread({}, this.links), {}, _defineProperty({}, moduleName, temporaryLinks));
    },
    setExpandedProjectsOptionsList: function setExpandedProjectsOptionsList() {
      this.projectsOptions = this.getExpandedProjectsOptionsList();
      this.projectOptionsWithDefault = this.getExpandedProjectsOptionsList(true);
    },
    getExpandedProjectsOptionsList: function getExpandedProjectsOptionsList() {
      var _this10 = this;
      var withDefaultProjects = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var defaultProjects = this.getDefaultProjectsOptions();
      var expandedProjects = [];
      defaultProjects.forEach(function (project) {
        if (project.value === MODULES.COMPLIANCE || project.value === MODULES.RISK) {
          var additionalProjects = _this10.projects.filter(function (item) {
            return item.objType.indexOf(project.value) !== -1;
          }).map(function (item) {
            return {
              label: "".concat(project.label, " - ").concat(item.title),
              value: "".concat(project.value, "-").concat(item.id),
              rights: project.rights && (item.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type && item.enabled && item.objType === ObjectTypes.RISK_PROJECT || userHasProjectAccess(_this10.currentUser, item.id, item.objType === ObjectTypes.RISK_PROJECT ? ObjectTypes.RISK_PROJECT : ObjectTypes.COMPLIANCE_PROJECT))
            };
          });
          if (withDefaultProjects) {
            expandedProjects.push(project);
          }
          expandedProjects.push.apply(expandedProjects, _toConsumableArray(additionalProjects));
        } else if (project.value === MODULES.COMPLIANCE_INFORMATION_ASSETS) {
          var _additionalProjects = _this10.activityList.filter(function (item) {
            return item.list.length;
          }).map(function (item) {
            return {
              label: item.label,
              value: "".concat(project.value, "-").concat(item.id),
              rights: project.rights
            };
          });
          if (withDefaultProjects) {
            expandedProjects.push(project);
          }
          expandedProjects.push.apply(expandedProjects, _toConsumableArray(_additionalProjects));
        } else {
          expandedProjects.push(project);
        }
      });
      return expandedProjects;
    },
    deleteLink: function deleteLink(module, linkId) {
      var _this11 = this;
      var purifiedModuleName = LinksTabUtils.getPurifiedModuleName(module);
      var itemTypeTitle = this.itemTitleByModule[purifiedModuleName].toLowerCase();
      this.itemDeletionData = {
        module: module,
        linkId: linkId,
        header: "".concat(this.translate.remove, " ").concat(itemTypeTitle),
        body: "".concat(this.translate.areYouSureYouWantToRemoveThisLinked, " ").concat(itemTypeTitle, "?")
      };
      this.$confirm(this.itemDeletionData.header, this.itemDeletionData.body, function (res) {
        return res ? _this11.handleDeleteLink(_this11.itemDeletionData.module, _this11.itemDeletionData.linkId) : _this11.itemDeletionData = null;
      }, {
        buttonOkText: this.translate.remove,
        buttonDismissText: this.translate.cancel
      });
    },
    handleDeleteLink: function handleDeleteLink(module, linkId) {
      var moduleName = LinksTabUtils.getPurifiedModuleName(module);
      var updatedLinks = this.links[moduleName].filter(function (id) {
        return id !== linkId;
      });
      this.links = _objectSpread(_objectSpread({}, this.links), {}, _defineProperty({}, moduleName, updatedLinks));
      this.itemDeletionData = null;
    },
    projectOptionExistsByModule: function projectOptionExistsByModule(linkModule) {
      // split is needed for risk and compl -> their linkModule names like risk-1, risk-2, etc.
      return this.projectsOptions.some(function (item) {
        return LinksTabUtils.getPurifiedModuleName(item.value) === linkModule;
      });
    },
    addNewItem: function addNewItem(item) {
      item.id = +item.id;
      switch (item.objType) {
        case ObjectTypes.CONTROLS_CONTROL:
          this.dataset[MODULES.CONTROLS].push(item);
          this.controls.push(item);
          break;
        case ObjectTypes.EXECUTION_INITIATIVE:
          this.dataset[MODULES.EXECUTION].push(item);
          break;
      }
    },
    getGovernanceReviewLinkNode: function getGovernanceReviewLinkNode() {
      var _this12 = this;
      var nodes = [];
      if (this.governanceReviewLinkModule === MODULES.COMPLIANCE) {
        nodes = this.complianceNodes;
      } else if (this.governanceReviewLinkModule === MODULES.COMPLIANCE_PROCESS_TREE_NODE) {
        nodes = this.dataset[MODULES.COMPLIANCE_PROCESS_TREE_NODE];
      } else if (this.governanceReviewLinkModule === MODULES.COMPLIANCE_INFORMATION_ASSETS) {
        nodes = this.dataset[MODULES.COMPLIANCE_INFORMATION_ASSETS];
      }
      var node = nodes.find(function (node) {
        return node.id == _this12.governanceReviewLinkId;
      });
      return node && [node];
    },
    hasDeleteOptions: function hasDeleteOptions(module) {
      return this.currentTab === this.tabTypes.LINKS && module !== this.GovernanceReviewOptionsLinksTab.value && module !== RiskAssessmentActivityLinksTab.value;
    }
  }
};