function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useTagsStore } from '@/Store/tagsStore';
import * as API from '@/api/risma/data';
import { getTreeByProjectId } from '@/api/compliance/tree';
import { getAttributesForProject } from '@/api/admin/gapschema';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
import { formatNodes as _formatNodes } from '@/utils/format/Compliance';
import { LiaTiaType, LIA_TIA_TYPES } from '@/constants/compliance/LiaTiaGeneric';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { Colors } from '@/Colors';
import { useOrganisationsStore } from '@/Store/organisationsStore';
export default {
  name: 'ReportSearchForm',
  introduction: 'This component will display a search-form for the compliance reports.',
  description: 'This will display two multi-select dropdowns. One with organisations and one with users. The users will be filtered bases on the organisation selected. If no organisation is selected all users will be displayed.\n\n' + 'This component allows the use of a slot named `inputSlot`. This slot will be place as the first element in the row with multiselect-elements. This slot can be a input-field a multiselect or whatever you would like it to be\n\n' + 'When clicking the `Clear query`-button a `@clearQuery`-event will be fired.\n' + 'When the form submits a `@doSearch`-event will be fired.',
  token: '<report-search-form title="" @doSearch="" @clearQuery=""><template v-slot:inputSlot><input v-model="modelName"/></div></report-search-form>',
  components: {
    RismaToggle: RismaToggle,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    RismaButton: RismaButton,
    RismaSelect: RismaSelect,
    RadioButtons: RadioButtons
  },
  mixins: [RaciTitleMixin],
  props: {
    title: {
      type: String,
      required: true,
      note: 'The title in the search form'
    },
    helperText: {
      type: String,
      default: '',
      note: 'The helper text of the search form. This will only be displayed if the prop is pressent'
    },
    searchBtnText: {
      type: String,
      default: 'Search',
      note: 'The text of the "search" button'
    },
    clearBtnText: {
      type: String,
      default: 'Clear search query',
      note: 'The text of the "clear" button'
    },
    nodesFilterEnabled: {
      type: Boolean,
      default: false,
      note: 'Show filter by node?'
    },
    scoringToggleEnabled: {
      type: Boolean,
      default: false,
      note: 'Show scoring toggler?'
    },
    localStorageKey: {
      type: String,
      required: false,
      default: null,
      note: 'Name of localStorage key'
    },
    selectedGaps: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Selected gaps for saving in localStorage'
    },
    hasLiaTiaFilter: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Lia and Tia filters for GDPR'
    },
    projectId: {
      type: [Number, String],
      required: true,
      note: 'Id of current project'
    },
    initialFilters: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['setQueryInput', 'doSearch', 'clearQuery'],
  data: function data() {
    return {
      nodes: [],
      selectedNodes: [],
      selectedUsers: [],
      selectedTags: [],
      selectedOrganisations: [],
      attributes: [],
      scoringIncluded: true,
      includeChildNodes: true,
      Colors: Colors,
      selectedRadio: 0,
      selectedAttributes: [],
      liaTiaSettings: [],
      LiaTiaType: LiaTiaType,
      translate: getTranslate['ReportSearchForm']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useUserStore, {
    currentUserAccessLevelByProjectId: 'complianceAccessLevelByProjectId'
  })), mapState(useTagsStore, ['tags'])), mapState(useOrganisationsStore, {
    organisations: 'organisationsSortedByVisibleName'
  })), {}, {
    filteredUsers: function filteredUsers() {
      var _this = this;
      return this.users.filter(function (user) {
        var _user$projects;
        var currentProject = user === null || user === void 0 || (_user$projects = user.projects) === null || _user$projects === void 0 ? void 0 : _user$projects.compliance.find(function (project) {
          return project.id === parseInt(_this.projectId);
        });
        return currentProject && currentProject.access_level;
      });
    },
    radioButtonsOptions: function radioButtonsOptions() {
      var _this2 = this;
      var options = [{
        value: 0,
        label: this.translate.allNodes
      }];
      var typesInUse = [];
      this.liaTiaSettings.forEach(function (setting) {
        if (!typesInUse.includes(setting.type)) {
          options.push({
            value: setting.type,
            label: _this2.$trans(_this2.LiaTiaType[setting.type].title)
          });
          typesInUse.push(setting.type);
        }
      });
      return options;
    },
    attributesOptions: function attributesOptions() {
      var list = this.attributes.reduce(function (acc, attribute) {
        var enhancedLabels = attribute.labels.map(function (label) {
          return _objectSpread(_objectSpread({}, label), {}, {
            title: "".concat(attribute.category, ": ").concat(label.label)
          });
        });
        return [].concat(_toConsumableArray(acc), _toConsumableArray(enhancedLabels));
      }, []);
      return this.removeDuplicatesByStringPropCaseInsensitive(list, 'title');
    }
  }),
  watch: {
    initialFilters: function initialFilters(value) {
      value && this.applyInitialFilters();
    }
  },
  mounted: function mounted() {
    var promises = [this.getTiaLiaSettings(), this.getProjectGapAttributes()];
    if (this.nodesFilterEnabled) {
      promises.push(this.fetchNodes());
    }
    Promise.all(promises).then(this.applyInitialFilters);
  },
  methods: {
    removeDuplicatesByStringPropCaseInsensitive: function removeDuplicatesByStringPropCaseInsensitive(list, strProp) {
      var mapObj = new Map();
      list.forEach(function (item) {
        var prevValue = mapObj.get(item[strProp].toLowerCase());
        if (!prevValue) mapObj.set(item[strProp].toLowerCase(), item);
      });
      return _toConsumableArray(mapObj.values());
    },
    fetchNodes: function fetchNodes() {
      var _this3 = this;
      return getTreeByProjectId(this.projectId).then(function (response) {
        return _this3.formatNodes(response.children);
      });
    },
    getTiaLiaSettings: function getTiaLiaSettings() {
      var _this4 = this;
      return API.getTiaLiaSettings().then(function (_ref) {
        var list = _ref.list;
        _this4.liaTiaSettings = list.filter(function (item) {
          return +item.type !== LIA_TIA_TYPES.TIA_PROC;
        });
      });
    },
    getProjectGapAttributes: function getProjectGapAttributes() {
      var _this5 = this;
      return getAttributesForProject(this.projectId).then(function (_ref2) {
        var list = _ref2.list;
        list.forEach(function (entry) {
          var _this5$attributes;
          (_this5$attributes = _this5.attributes).push.apply(_this5$attributes, _toConsumableArray(entry.attributes));
        });
      });
    },
    applyInitialFilters: function applyInitialFilters() {
      var filters = this.initialFilters;
      if (filters.organisations) {
        this.selectedOrganisations = this.organisations.filter(function (organisation) {
          return filters.organisations.find(function (id) {
            return id == organisation.id;
          });
        });
      }
      if (filters.users) {
        this.selectedUsers = this.users.filter(function (user) {
          return filters.users.find(function (id) {
            return id == user.id;
          });
        });
      }
      if (filters.attributes) {
        this.selectedAttributes = this.attributesOptions.filter(function (attribute) {
          return filters.attributes.find(function (id) {
            return +id === +attribute.id;
          });
        });
      }
      if (filters.tags) this.selectedTags = this.tags.filter(function (tag) {
        return filters.tags.find(function (item) {
          return +item === tag.id;
        });
      });
      if (this.nodesFilterEnabled) {
        this.selectedNodes = this.nodes.filter(function (node) {
          return filters.nodes.find(function (id) {
            return id == node.id;
          });
        });
        this.includeChildNodes = filters.includeChildNodes;
      } else {
        this.scoringIncluded = filters.scoringIncluded;
      }
      var radio = filters.selectedRadio;
      if (radio) {
        var idx = this.radioButtonsOptions.findIndex(function (option) {
          return +option.value === +radio;
        });
        this.selectedRadio = idx !== -1 ? idx : 0;
      }
    },
    nodesLabel: function nodesLabel(node) {
      return node.formattedTitle;
    },
    organisationsLabel: function organisationsLabel(org) {
      return org.visible_name;
    },
    usersLabel: function usersLabel(user) {
      return user.display_name;
    },
    formatNodes: function formatNodes(nodes) {
      this.nodes = _formatNodes(nodes);
    },
    resetFilters: function resetFilters() {
      this.selectedUsers = [];
      this.selectedOrganisations = [];
      this.selectedNodes = [];
      this.selectedTags = [];
      this.selectedAttributes = [];
      this.scoringIncluded = true;
      this.includeChildNodes = false;
      this.$emit('clearQuery');
    },
    handleSearch: function handleSearch() {
      var selectedNodeIds = this.selectedNodes.map(function (node) {
        return node.id;
      });
      this.$emit('doSearch', {
        organisations: this.selectedOrganisations.map(function (org) {
          return org.id;
        }),
        users: this.selectedUsers.map(function (user) {
          return user.id;
        }),
        tags: this.selectedTags.map(function (tag) {
          return tag.id;
        }),
        nodes: selectedNodeIds,
        attributes: this.selectedAttributes.map(function (a) {
          return a.title;
        }),
        scoringIncluded: this.scoringIncluded,
        filterType: this.selectedRadio,
        includeChildNodes: this.includeChildNodes
      });
    },
    handleResetFilters: function handleResetFilters() {
      this.resetFilters();
      if (this.localStorageKey !== null) localStorage.removeItem(this.localStorageKey);
    }
  }
};