function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { cacheItem } from '@/routes/storeCache';
import { getRiskProjects as _getRiskProjects } from '@/api/risk/risk';
import { getAll as getAllComplianceProjects, updateProject } from '@/api/compliance/projects';
import { patchUtilSettings } from '@/api/risma/settings';
import { getCustomField, createCustomField, editCustomField } from '@/api/admin/admin';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import OptionsList from '@/components/Molecules/OptionsList/OptionsList';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { MODULES } from '@/constants/modules';
import { RiskProjects } from '@/constants/risks/RiskProjects';
export default {
  name: 'ComplianceRiskAssessment',
  description: 'Page with compliance assets settings',
  token: '<compliance-risk-assessment />',
  components: {
    RismaTitle: RismaTitle,
    RismaToggle: RismaToggle,
    SingleSelect: SingleSelect,
    RismaButton: RismaButton,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    OptionsList: OptionsList
  },
  data: function data() {
    return {
      data: {
        'feature.risk_assessment_on_systems': 0,
        'feature.systems_r_projects_id': null,
        'feature.risk_assessment_on_controllers': 0,
        'feature.controllers_r_projects_id': null,
        'feature.risk_assessment_on_processors': 0,
        'feature.processors_r_projects_id': null,
        'feature.isms_rto_field_id': null,
        'feature.isms_rpo_field_id': null
      },
      complianceProjectsDataToSave: {},
      RTO: {
        NAME: 'feature.isms_rto_field_id',
        VALUES_NAME: 'rto',
        LABEL: 'Recovery Time Objective'
      },
      RPO: {
        NAME: 'feature.isms_rpo_field_id',
        VALUES_NAME: 'rpo',
        LABEL: 'Recovery Point Objective'
      },
      rtoRpoGroup: {
        rto: {
          title: 'RTO (Recovery Time Objective)',
          values: [{
            value: ''
          }]
        },
        rpo: {
          title: 'RPO (Recovery Point Objective)',
          values: [{
            value: ''
          }]
        }
      },
      complianceProjects: [],
      riskProjects: [],
      isLoading: false,
      translate: getTranslate['ComplianceRiskAssessment']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useSettingsStore, ['isInformationAssetsDisabled'])), mapState(useActivityTypesStore, {
    customInformationAssetsModules: 'complianceIACustomActivityTypes'
  })), {}, {
    riskProjectsOptions: function riskProjectsOptions() {
      var values = this.riskProjects.map(function (project) {
        return {
          value: project.id,
          label: project.title,
          type: project.type
        };
      });
      return [{
        value: null,
        label: this.translate.noRiskProject
      }].concat(_toConsumableArray(values));
    },
    riskProjectsOptionsWithoutErm: function riskProjectsOptionsWithoutErm() {
      return this.riskProjectsOptions.filter(function (project) {
        return project.type !== RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
      });
    },
    settingsData: function settingsData() {
      return [{
        name: 'System',
        switchSettingName: 'feature.risk_assessment_on_systems',
        riskIdSettingName: 'feature.systems_r_projects_id'
      }, {
        name: 'Controllers',
        switchSettingName: 'feature.risk_assessment_on_controllers',
        riskIdSettingName: 'feature.controllers_r_projects_id'
      }, {
        name: 'Processors',
        switchSettingName: 'feature.risk_assessment_on_processors',
        riskIdSettingName: 'feature.processors_r_projects_id'
      }];
    },
    isRtoRpoValid: function isRtoRpoValid() {
      return this.rtoRpoGroup.rto.values.every(function (_ref) {
        var value = _ref.value;
        return value;
      }) && this.rtoRpoGroup.rpo.values.every(function (_ref2) {
        var value = _ref2.value;
        return value;
      });
    }
  }),
  mounted: function mounted() {
    this.loadData();
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    loadSettings: 'load'
  })), mapActions(useActivityTypesStore, {
    editActivityType: 'update'
  })), {}, {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      var promises = [this.getComplianceProjects(), this.getRiskProjects(), this.getValuesFromSettings(), this.getCustomFieldValues(this.RTO.NAME, this.RTO.VALUES_NAME), this.getCustomFieldValues(this.RPO.NAME, this.RPO.VALUES_NAME)];
      return Promise.all(promises).then(function () {
        return _this.isLoading = false;
      });
    },
    getComplianceProjects: function getComplianceProjects() {
      var _this2 = this;
      return getAllComplianceProjects().then(function (_ref3) {
        var list = _ref3.list;
        return _this2.complianceProjects = list;
      });
    },
    getRiskProjects: function getRiskProjects() {
      var _this3 = this;
      return _getRiskProjects().then(function (_ref4) {
        var list = _ref4.list;
        return _this3.riskProjects = list;
      });
    },
    getCustomFieldValues: function getCustomFieldValues(name, valuesName) {
      var _this4 = this;
      var id = this.data[name];
      if (id) {
        return getCustomField(id).then(function (response) {
          _this4.rtoRpoGroup[valuesName].values = Object.values(response.options).map(function (option) {
            return {
              value: option.value,
              id: option.id
            };
          });
        });
      }
    },
    getValuesFromSettings: function getValuesFromSettings() {
      var _this5 = this;
      Object.keys(this.data).forEach(function (key) {
        return _this5.addSettingToData(_this5.getSettingValue(key), key);
      });
    },
    onProjectAssessmentChange: function onProjectAssessmentChange(projectId, riskProjectId) {
      this.complianceProjectsDataToSave[projectId] = riskProjectId;
    },
    addSettingToData: function addSettingToData(value, name) {
      if (value === undefined) return;
      this.data[name] = value;
    },
    saveSettings: function saveSettings() {
      var _this6 = this;
      if (!this.isRtoRpoValid) return;
      return Promise.all([this.saveComplianceProjectsChange(), this.saveSettingsForCustomIA(), this.saveCustomField(this.RTO.LABEL, this.RTO.VALUES_NAME, this.RTO.NAME), this.saveCustomField(this.RPO.LABEL, this.RPO.VALUES_NAME, this.RPO.NAME)]).then(function () {
        return _this6.patchSettings();
      }).then(function () {
        _this6.$notify({
          title: _this6.translate.settingsAreSaved,
          type: 'success'
        });
      }).catch(function (error) {
        _this6.$notify({
          title: _this6.$trans(error.response.error),
          type: 'error'
        });
      });
    },
    saveComplianceProjectsChange: function saveComplianceProjectsChange() {
      var promises = [];
      Object.entries(this.complianceProjectsDataToSave).forEach(function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 2),
          projectId = _ref6[0],
          riskProjectId = _ref6[1];
        promises.push(updateProject(projectId, {
          riskProjectId: riskProjectId
        }));
      });
      return Promise.all(promises);
    },
    updateDropdownOption: function updateDropdownOption(options, key) {
      this.rtoRpoGroup[key].values = options;
    },
    saveSettingsForCustomIA: function saveSettingsForCustomIA() {
      var _this7 = this;
      var promises = [];
      this.customInformationAssetsModules.forEach(function (module) {
        var data = {
          riskEnabled: module.riskEnabled,
          riskProjectId: module.riskProjectId
        };
        promises.push(_this7.editActivityType(module.id, data));
      });
      return Promise.all(promises);
    },
    saveCustomField: function saveCustomField(label, valuesName, name) {
      var _this8 = this;
      var data = {
        label: label,
        typeId: CustomFieldTypes.SELECT,
        module: MODULES.COMPLIANCE_IA_SYSTEM,
        optionObjects: this.rtoRpoGroup[valuesName].values.map(function (item) {
          return {
            value: item.value,
            id: item.id
          };
        })
      };
      var id = this.data[name];
      if (id) {
        return editCustomField(id, data);
      } else {
        return createCustomField(data).then(function (_ref7) {
          var id = _ref7.id;
          return _this8.addSettingToData(id, name);
        });
      }
    },
    patchSettings: function patchSettings() {
      var _this9 = this;
      return patchUtilSettings(this.data).then(function () {
        return cacheItem('settings', function () {
          return _this9.loadSettings();
        }, 180);
      });
    }
  })
};