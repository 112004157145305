import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaSwitch from '@/components/Atoms/Inputs/RismaSwitch';
import { DataFlowFields } from '@/constants/compliance/WizardFlow';
import { getDataflowStakeholdersTitle } from '@/utils/TitleFromSetting';
export default {
  name: 'DataFlowWizardStepDataSubject',
  components: {
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    RismaSwitch: RismaSwitch
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    selectedOptions: {
      type: Object,
      required: true,
      note: 'selected options for all steps'
    },
    errorMessage: {
      type: Object,
      default: function _default() {
        return {
          field: null,
          text: ''
        };
      }
    }
  },
  emits: ['update:selected'],
  data: function data() {
    return {
      dataDirectionOptions: [{
        name: this.$trans('Receives'),
        value: 'receive'
      }, {
        name: this.$trans('Delivers'),
        value: 'deliver'
      }],
      selectedDirection: 'deliver',
      DataFlowFields: DataFlowFields,
      stakeholderName: getDataflowStakeholdersTitle(),
      translate: {
        dataDirection: this.$trans('Data direction'),
        choose: this.$trans('Choose')
      }
    };
  },
  methods: {
    onOptionChange: function onOptionChange(data) {
      this.$emit('update:selected', {
        value: data,
        field: DataFlowFields.CURRENT_DATA_SUBJECT
      });
    }
  }
};