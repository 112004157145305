import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "single-choice"
};
var _hoisted_2 = {
  key: 0,
  class: "single-choice-radiobuttons"
};
var _hoisted_3 = {
  key: 1,
  class: "single-choice-dropdown"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_radio_buttons = _resolveComponent("radio-buttons");
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.viewType === $options.viewTypes.RADIO_BUTTONS ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_radio_buttons, {
    modelValue: $data.value,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $data.value = $event;
    }), $options.handleUpdate],
    options: $options.optionsArray,
    disabled: $props.readOnly,
    "radio-button-focus": $props.radioButtonFocus
  }, null, 8 /* PROPS */, ["modelValue", "options", "disabled", "radio-button-focus", "onUpdate:modelValue"])])) : _createCommentVNode("v-if", true), $options.viewType === $options.viewTypes.DROPDOWN ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_single_select, {
    modelValue: $data.value,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
      return $data.value = $event;
    }), $options.handleUpdate],
    options: $options.optionsArray,
    "un-sorted": true,
    "with-reset": true,
    disabled: $props.readOnly
  }, null, 8 /* PROPS */, ["modelValue", "options", "disabled", "onUpdate:modelValue"])])) : _createCommentVNode("v-if", true)]);
}