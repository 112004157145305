import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_router_view = _resolveComponent("router-view");
  var _component_vertical_menu_wrapper = _resolveComponent("vertical-menu-wrapper");
  return !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [$options.hasQuiz ? (_openBlock(), _createBlock(_component_vertical_menu_wrapper, {
    key: 0
  }, {
    menu: _withCtx(function () {
      return [_createVNode(_component_vertical_menu, {
        items: $options.menuItems,
        "bottom-items": $options.bottomItems
      }, null, 8 /* PROPS */, ["items", "bottom-items"])];
    }),
    content: _withCtx(function () {
      return [_createVNode(_component_router_view, {
        "data-list": $data.requests
      }, null, 8 /* PROPS */, ["data-list"])];
    }),
    _: 1 /* STABLE */
  })) : (_openBlock(), _createBlock(_component_router_view, {
    key: 1,
    "data-list": $data.requests
  }, null, 8 /* PROPS */, ["data-list"]))]));
}