import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, Teleport as _Teleport } from "vue";
var _hoisted_1 = {
  class: "modal-container-mask"
};
var _hoisted_2 = {
  class: "modal-header flex mb-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_trap_focus_boundary = _resolveComponent("trap-focus-boundary");
  return _openBlock(), _createBlock(_Teleport, {
    to: "body"
  }, [_createVNode(_component_trap_focus_boundary, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", {
        class: "modal-mask",
        tabindex: 1,
        onKeyup: _cache[3] || (_cache[3] = _withKeys(function () {
          return $options.onEnterClick && $options.onEnterClick.apply($options, arguments);
        }, ["enter"]))
      }, [_createElementVNode("div", {
        class: "modal-wrapper",
        onMousedown: _cache[2] || (_cache[2] = _withModifiers(function () {
          return $options.dismissWhenClickedOutside && $options.dismissWhenClickedOutside.apply($options, arguments);
        }, ["self"]))
      }, [_createElementVNode("div", {
        style: _normalizeStyle([$props.modalFixedWidth && "width: ".concat($props.modalFixedWidth), $props.modalFixedHeight && "height: ".concat($props.modalFixedHeight), $props.modalMaxWidth && "max-width: ".concat($props.modalMaxWidth)]),
        class: _normalizeClass(['modal-container flex flex-col', $props.type, {
          'overflow-visible': !$props.bodyScrollY
        }])
      }, [_withDirectives(_createElementVNode("div", _hoisted_1, null, 512 /* NEED_PATCH */), [[_vShow, $props.showModalContainerMask]]), _renderSlot(_ctx.$slots, "header", {}, function () {
        return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
          title: $props.header,
          type: $props.headerType,
          truncate: $props.headerTruncate,
          class: "flex-1 max-w-full-25"
        }, null, 8 /* PROPS */, ["title", "type", "truncate"]), _createElementVNode("button", {
          class: "focus:outline-none",
          onClick: _cache[0] || (_cache[0] = function ($event) {
            return _ctx.$emit('dismiss', $data.modalDismissTypes.ICON, $event);
          })
        }, [_createVNode(_component_feather_icon, {
          icon: "x",
          width: "25",
          height: "25",
          class: "stroke-3 text-gray-750 hover:text-gray-550",
          title: $props.dismissButtonDisabled ? $data.translate.fileUploadInProgress : ''
        }, null, 8 /* PROPS */, ["title"])])])];
      }, true), _createElementVNode("div", {
        ref: "scrollContainer",
        class: _normalizeClass(["modal-body", {
          'flex-grow': $props.bodyFullHeight,
          'overflow-y-auto overflow-x-hidden': $props.bodyScrollY
        }])
      }, [_renderSlot(_ctx.$slots, "body", {}, undefined, true)], 2 /* CLASS */), _renderSlot(_ctx.$slots, "footer", {}, function () {
        return [$props.showButtons ? (_openBlock(), _createBlock(_component_buttons_row, {
          key: 0,
          class: "modal-footer mt-5",
          data: $options.buttonsRowData,
          onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(function () {}, ["stop"]), ["enter"]))
        }, null, 8 /* PROPS */, ["data"])) : _createCommentVNode("v-if", true)];
      }, true)], 6 /* CLASS, STYLE */)], 32 /* NEED_HYDRATION */)], 32 /* NEED_HYDRATION */)];
    }),
    _: 3 /* FORWARDED */
  })]);
}