function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import Modal from '@/components/Molecules/Modal/Modal';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import { DateFormats } from '@/constants/DateFormats';
import { isEmailValid } from '@/utils/validations';
import { escapeHtml } from '@/utils';
import { getUserLanguage } from '@/utils/Utils';
import { addTimeAndFormatDate, formatTime, getLocaleDateFormatString } from '@/utils/date';
export default {
  name: 'DpmSendQuestionnaireModal',
  introduction: 'Component for displaying a Modal dialog',
  description: 'This modal ensures that the user understands the different aspects of the process. This means that they are informed about what the data they are presenting, are gonna do and how the flow will be from when they send the audit. ',
  token: "<dpm-send-questionnaire-modal\n    :audit-questionnaires=\"newProcesses\"/>",
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    InputField: InputField,
    TextBox: TextBox,
    Modal: Modal,
    DateTimePicker: DateTimePicker
  },
  props: {
    auditQuestionnaires: {
      required: true,
      type: [Array, Object],
      note: 'Questionnaire names for data processor management',
      default: function _default() {}
    },
    prefillCompany: {
      required: false,
      type: String,
      note: 'Prefill for modal form',
      default: ''
    },
    prefillEmail: {
      required: false,
      type: String,
      note: 'Prefill for modal form',
      default: ''
    },
    prefillName: {
      required: false,
      type: String,
      note: 'Prefill for modal form',
      default: ''
    }
  },
  emits: ['dismiss', 'update'],
  data: function data() {
    return {
      selectedOptionProcessor: null,
      showModal: false,
      commentsWritten: '',
      nameWritten: this.prefillName,
      companyWritten: this.prefillCompany || '',
      emailWritten: this.prefillEmail || '',
      questionnaireError: false,
      nameError: false,
      companyError: false,
      emailError: '',
      deadlineError: false,
      today: new Date(),
      deadlineValue: this.getCurrentDatePlusOneMonth(),
      datePickerOptions: {
        format: DateFormats.DATE_FORMAT,
        showTodayButton: false
      },
      translate: getTranslate['DpmSendQuestionnaireModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    descriptionOfTheError: function descriptionOfTheError() {
      return this.translate.pleaseFillOutThisField;
    },
    dateFormat: function dateFormat() {
      return getLocaleDateFormatString(getUserLanguage(this.currentUser));
    },
    deadlineHint: function deadlineHint() {
      var todayFormatted = formatTime(this.today, this.dateFormat);
      var yearFromNowFormatted = formatTime(this.fiveYearsFromNow, this.dateFormat);
      return this.translate.youMustSelectADateBetween.replace('/today/', todayFormatted).replace('/past/', yearFromNowFormatted);
    },
    fiveYearsFromNow: function fiveYearsFromNow() {
      return new Date().setFullYear(this.today.getFullYear() + 5);
    },
    showQuestionnaireError: function showQuestionnaireError() {
      return this.questionnaireError && !this.selectedOptionProcessor;
    },
    showNameError: function showNameError() {
      return this.nameError && !this.nameWritten;
    },
    showCompanyError: function showCompanyError() {
      return this.companyError && !this.companyWritten;
    },
    showDeadlineError: function showDeadlineError() {
      return this.deadlineError && !this.deadlineValue;
    }
  }),
  methods: {
    onSelectDataProcessor: function onSelectDataProcessor(changed) {
      this.selectedOptionProcessor = changed;
    },
    toggleModal: function toggleModal() {
      this.$emit('dismiss');
    },
    sendInformation: function sendInformation() {
      this.setEmptyComponents();
      if (this.isValid()) {
        this.toggleModal();
        this.sendData();
      }
    },
    isValid: function isValid() {
      return this.isNotEmpty(this.selectedOptionProcessor) && this.isNotEmpty(this.nameWritten) && this.isNotEmpty(this.emailWritten) && this.isNotEmpty(this.deadlineValue) && isEmailValid(this.emailWritten);
    },
    getEmailErrorMessage: function getEmailErrorMessage() {
      if (!this.isNotEmpty(this.emailWritten)) {
        return this.descriptionOfTheError;
      } else if (!isEmailValid(this.emailWritten)) {
        return this.emailWritten + this.translate.isAnInvalidEmailAddress;
      } else {
        return '';
      }
    },
    isNotEmpty: function isNotEmpty(field) {
      return typeof field === 'number' || typeof field === 'string' && field.length > 0;
    },
    sendData: function sendData() {
      var data = {
        questionnaireId: this.selectedOptionProcessor,
        name: escapeHtml(this.nameWritten),
        company: escapeHtml(this.companyWritten),
        email: this.emailWritten,
        deadline: this.deadlineValue,
        message: escapeHtml(this.commentsWritten)
      };
      this.$emit('update', data);
    },
    setEmptyComponents: function setEmptyComponents() {
      this.questionnaireError = !this.isNotEmpty(this.selectedOptionProcessor);
      this.nameError = !this.isNotEmpty(this.nameWritten);
      this.companyError = !this.isNotEmpty(this.companyWritten);
      this.emailError = this.getEmailErrorMessage();
      this.deadlineError = !this.isNotEmpty(this.deadlineValue);
    },
    disabledDate: function disabledDate(date) {
      return date < this.today || date > this.fiveYearsFromNow;
    },
    getCurrentDatePlusOneMonth: function getCurrentDatePlusOneMonth() {
      return addTimeAndFormatDate(undefined, 1, 'month', DateFormats.DATE_FORMAT);
    }
  }
};