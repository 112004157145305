import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys } from "vue";
var _hoisted_1 = {
  class: "user-edit-modal"
};
var _hoisted_2 = {
  name: "usereditform"
};
var _hoisted_3 = {
  class: "mb-3"
};
var _hoisted_4 = {
  class: "mb-3"
};
var _hoisted_5 = {
  class: "mb-3"
};
var _hoisted_6 = {
  class: "mb-3"
};
var _hoisted_7 = {
  class: "mb-3"
};
var _hoisted_8 = {
  class: "mb-3"
};
var _hoisted_9 = {
  class: "mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_org_selector = _resolveComponent("risma-org-selector");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_modal, {
    header: $data.headerTitle,
    "dismiss-on-click-outside": false,
    "accept-on-enter": !$data.isRismaSelectOpen,
    onAccept: $options.createUser,
    onDismiss: $options.dismissForm
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("form", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "info",
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return $data.notificationMessage = null;
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.notificationMessage), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), $data.hasErrorFromBackend ? (_openBlock(), _createBlock(_component_notification, {
        key: 1,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errorsFromBackend, function (error, idx) {
            return _openBlock(), _createElementBlock("p", {
              key: idx
            }, _toDisplayString(error), 1 /* TEXT */);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.data.username,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.data.username = $event;
        }),
        title: $data.translate.username,
        invalid: $data.usernameError,
        "error-help-text": $options.descriptionOfTheError,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "error-help-text"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_input_field, {
        modelValue: $data.data.initials,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.data.initials = $event;
        }),
        title: $data.translate.initials,
        invalid: $data.initialsError,
        "error-help-text": $options.descriptionOfTheError,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "error-help-text"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_input_field, {
        modelValue: $data.password,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.password = $event;
        }),
        title: $data.translate.password,
        invalid: $data.passwordError,
        "error-help-text": $options.descriptionOfThePasswordError,
        type: "password"
      }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "error-help-text"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_input_field, {
        modelValue: $data.data.fullname,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $data.data.fullname = $event;
        }),
        title: $data.translate.fullName,
        invalid: $data.fullNameError,
        "error-help-text": $options.descriptionOfTheError,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "error-help-text"])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_input_field, {
        modelValue: $data.data.email,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
          return $data.data.email = $event;
        }),
        title: $data.translate.email,
        type: "text",
        invalid: $data.emailError,
        "error-help-text": $options.descriptionOfTheEmailError
      }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "error-help-text"])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_org_selector, {
        title: $data.translate.organisation,
        "available-options": $props.allOrganisations,
        "selected-options": $data.selectedOrganisations,
        "open-direction": "top",
        onSelected: $options.updateOrganisations,
        onOpen: _cache[6] || (_cache[6] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[7] || (_cache[7] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["title", "available-options", "selected-options", "onSelected"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_checkboxes, {
        "label-position": "left",
        "checkbox-size": 20,
        class: "max-w-350px",
        options: $options.checkboxOptions,
        onUpdated: $options.handleCheckBoxOptionChange
      }, null, 8 /* PROPS */, ["options", "onUpdated"])])])];
    }),
    footer: _withCtx(function () {
      return [_createElementVNode("div", {
        class: "mt-5 flex justify-between items-center",
        onKeyup: _cache[8] || (_cache[8] = _withKeys(_withModifiers(function () {}, ["stop"]), ["enter"]))
      }, [_createVNode(_component_buttons_row, {
        class: "ml-auto",
        data: $options.buttonsRowData
      }, null, 8 /* PROPS */, ["data"])], 32 /* NEED_HYDRATION */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "accept-on-enter", "onAccept", "onDismiss"])]);
}