import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex cursor-pointer"
};
var _hoisted_2 = {
  class: "glob-l1-sb group-hover:underline"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("button", {
    class: "flex items-center group text-rm-grey hover:text-rm-signal-grey-dark active:text-rm-active-blue",
    tabindex: "0",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('click');
    }),
    onEnter: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('click');
    })
  }, [_createVNode(_component_feather_icon, {
    class: "stroke-2 mr-2",
    width: "18",
    height: "18",
    icon: "plus-circle"
  }), _createElementVNode("p", _hoisted_2, _toDisplayString($data.translate.addOption), 1 /* TEXT */)], 32 /* NEED_HYDRATION */)]);
}