import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "single-asset-choice relative"
};
var _hoisted_2 = {
  key: 0,
  class: "absolute top-0 bottom-0 right-0 left-0 z-10 cursor-not-allowed"
};
var _hoisted_3 = {
  key: 1,
  class: "w-3/4 mt-3"
};
var _hoisted_4 = {
  class: "font-semibold mr-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_new_asset = _resolveComponent("new-asset");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.readOnly && !$props.partial ? (_openBlock(), _createElementBlock("div", _hoisted_2)) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $options.capitalizedTypeLabel,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
    options: $options.assetsOptions,
    "selected-options": $options.assetsSelected,
    disabled: $props.readOnly,
    class: "w-3/4",
    "label-key": "name",
    onSelected: $options.emitChanges,
    onClose: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "disabled", "onSelected"]), $options.onlyMyInformationAssets && $options.selectedByOthers.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString($options.selectedByOthersDisplay), 1 /* TEXT */), _createElementVNode("span", null, _toDisplayString($data.translate.isAreAlreadyAddedByADifferentUser) + ".", 1 /* TEXT */), _createElementVNode("p", null, _toDisplayString($data.translate.youCantSeeOrEditTheseAssetBecauseYourUserRightInhibitsYouToInteractWithIt) + ". ", 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $options.userHasIaAccess && $options.showAddNewAsset && !$props.readOnly && !$options.onlyMyInformationAssets ? (_openBlock(), _createBlock(_component_new_asset, {
    key: 2,
    type: $props.type,
    "type-id": $props.typeId,
    "type-label": $options.typeLabel,
    "assets-options": $props.assets,
    onNewAsset: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('newAsset', {
        data: $event,
        type: $options.getRightType
      });
    })
  }, null, 8 /* PROPS */, ["type", "type-id", "type-label", "assets-options"])) : _createCommentVNode("v-if", true)]);
}