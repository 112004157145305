import i18n from '@/i18n';
import { getTitlePlaceholder } from '@/utils/repetativeTranslations';
export var getTranslate = {
  CreateComplianceProject: function CreateComplianceProject() {
    return {
      createComplianceProject: i18n.t('Create compliance project'),
      projectName: i18n.t('Project name'),
      projectType: i18n.t('Project type'),
      selectProjectType: i18n.t('Select project type'),
      questionnaireType: i18n.t('Questionnaire type'),
      selectQuestionnaireType: i18n.t('Select questionnaire type'),
      defaultQuestionnaire: i18n.t('Default questionnaire'),
      selectDefaultQuestionnaire: i18n.t('Select default questionnaire'),
      existingProjects: i18n.t('Existing projects'),
      name: i18n.t('Name'),
      enabledisable: i18n.t('Enable/disable'),
      pleaseSelectADefaultQuestionnaire: i18n.t('Please select a default questionnaire!'),
      projectCreated: i18n.t('Project created'),
      treeView: i18n.t('Tree view'),
      default: i18n.t('Default'),
      detailed: i18n.t('Detailed'),
      showOverviewPage: i18n.t('Show overview page'),
      yes: i18n.t('Yes'),
      no: i18n.t('No')
    };
  },
  CreateRiskProject: function CreateRiskProject() {
    return {
      riskProjects: i18n.t('Risk projects'),
      projectName: i18n.t('Project name'),
      projectType: i18n.t('Project type'),
      selectProjectType: i18n.t('Select project type'),
      save: i18n.t('Save'),
      existingProjects: i18n.t('Existing projects'),
      name: i18n.t('Name'),
      numberOfPeriods: i18n.t('Number of periods'),
      currentRisks: i18n.t('Current risks'),
      enabledisable: i18n.t('Enable/disable'),
      noPeriods: i18n.t('No periods'),
      period: i18n.t('Period'),
      status: i18n.t('Status'),
      riskCount: i18n.t('Risk count'),
      delete: i18n.t('Delete'),
      createProject: i18n.t('Create project'),
      enable: i18n.t('Enable'),
      disable: i18n.t('Disable'),
      cancel: i18n.t('Cancel'),
      yes: i18n.t('Yes'),
      open: i18n.t('Open'),
      closed: i18n.t('Closed'),
      draft: i18n.t('Draft'),
      areYouSureYouWantToDeleteThisPeriod: i18n.t('Are you sure you want to delete this period?'),
      riskProjectCreated: i18n.t('Risk project created'),
      riskProjectEnabledDisabled: i18n.t('Risk project enabled/disabled'),
      riskPeriodDeleted: i18n.t('Risk period deleted'),
      youCannotDeleteThisPeriodAsItContainsDataThatIsStillInUse: i18n.t('You cannot delete this period as it contains data that is still in use')
    };
  },
  DangerZone: function DangerZone() {
    return {
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      permanently: i18n.t('permanently'),
      areYouSure: i18n.t('Are you sure?'),
      delete: i18n.t('Delete'),
      cancel: i18n.t('Cancel'),
      dangerZone: i18n.t('Danger zone'),
      copyAllEnterpriseRisksToANewRiskProject: i18n.t('Copy all enterprise risks to a new risk project'),
      setAllControlsRecent: i18n.t('Set all controls recent'),
      hardDeleteUser: i18n.t('Hard delete user'),
      userToDelete: i18n.t('User to delete'),
      moveChangelogEntriesTo: i18n.t('Move changelog entries to'),
      selectAll: i18n.t('Select all')
    };
  },
  EmailSetup: function EmailSetup() {
    return {
      emailSetup: i18n.t('E-mail setup'),
      name: i18n.t('Name'),
      value: i18n.t('Value'),
      sendTestEmail: i18n.t('Send test e-mail'),
      emailSendingEnabled: i18n.t('E-mail sending enabled'),
      enterEmailAddress: i18n.t('Enter email address'),
      invalidEmailAddress: i18n.t('Invalid e-mail address')
    };
  },
  Frameworks: function Frameworks() {
    return {
      confirm: i18n.t('Confirm'),
      createNewFramework: i18n.t('Create new framework'),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      frameworkId: i18n.t('Framework'),
      framework: i18n.t('Framework'),
      active: i18n.t('Active'),
      enterFrameworkLabel: i18n.t('Enter framework label'),
      runAutodetect: i18n.t('Run autodetect'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      pleaseConfirm: i18n.t('Please confirm'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want delete this?'),
      createUnderlyingFramework: i18n.t('Create underlying framework'),
      titleExists: i18n.t('Title exists'),
      updateTitle: i18n.t('Update title'),
      frameworkWasSuccessfullyUpdated: i18n.t('Framework') + ' ' + i18n.t('was successfully updated'),
      frameworkWasSuccessfullyDeleted: i18n.t('Framework') + ' ' + i18n.t('was successfully deleted'),
      frameworkWasSuccessfullyCreated: i18n.t('Framework') + ' ' + i18n.t('was successfully created'),
      update: i18n.t('Update'),
      enterTitle: getTitlePlaceholder()
    };
  },
  UploadGap: function UploadGap() {
    return {
      uploadGap: i18n.t('Upload Gap'),
      gapTitle: i18n.t('Gap title'),
      selectOrigin: i18n.t('Select origin'),
      gapSchemaType: i18n.t('Gap schema type'),
      chooseGapSchemaType: i18n.t('Choose gap schema type'),
      complianceProject: i18n.t('Compliance') + ' ' + i18n.t('Project').toLowerCase(),
      selectComplianceProject: i18n.t('Select') + ' ' + i18n.t('Compliance').toLowerCase() + ' ' + i18n.t('Project').toLowerCase(),
      selectGapExcelOrCsvFile: i18n.t('Select Gap Excel or .csv file'),
      resetAllieRemoveExistingGapSchemasAnd: i18n.t('RESET ALL - i.e. REMOVE EXISTING GAP SCHEMA(S) AND USE FILE IN STEAD! '),
      save: i18n.t('Save'),
      preview: i18n.t('Preview'),
      ordering: i18n.t('Ordering'),
      article: i18n.t('Article'),
      articleTitle: i18n.t('Article Title'),
      domain: i18n.t('Domain'),
      requirement: i18n.t('Requirement'),
      flags: i18n.t('Flags'),
      suggestedActions: i18n.t('Suggested actions'),
      suggestedControls: i18n.t('Suggested controls'),
      attributes: i18n.t('Attributes'),
      frameworks: i18n.t('Frameworks'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.')
    };
  },
  UploadQuestionnaire: function UploadQuestionnaire() {
    return {
      uploadQuestionnaire: i18n.t('Upload questionnaire'),
      title: i18n.t('Title'),
      tabLabel: i18n.t('Tab label'),
      questionnaireType: i18n.t('Questionnaire type'),
      selectQuestionnaireType: i18n.t('Select questionnaire type'),
      origin: i18n.t('Origin'),
      selectOrigin: i18n.t('Select origin'),
      ordering: i18n.t('Ordering'),
      questionNumber: i18n.t('Question number'),
      headline: i18n.t('Headline'),
      question: i18n.t('Question'),
      questionDependency: i18n.t('Question dependency'),
      questionType: i18n.t('Question type'),
      questionTypeId: i18n.t('Question type ID'),
      questionnaireReport: i18n.t('Questionnaire report'),
      flags: i18n.t('Flags'),
      status: i18n.t('Status'),
      questionnaireUploaded: i18n.t('Questionnaire uploaded'),
      pleaseStateTheQuestionnaireTitle: i18n.t('Please state the questionnaire title'),
      pleaseSelectQuestionnaireTabLabel: i18n.t('Please select questionnaire tab label'),
      pleaseSelectQuestionnaireType: i18n.t('Please select questionnaire type'),
      pleaseSelectQuestionnaireOrigin: i18n.t('Please select questionnaire origin'),
      fileIsEmptyPleaseUploadSomethingMoreSubsta: i18n.t('File is empty. Please try again'),
      theQuestionnaireContainsErrors: i18n.t('The questionnaire contains errors'),
      frameworks: i18n.t('Frameworks'),
      youCantUseNextQuestionTypes: function youCantUseNextQuestionTypes(types) {
        return i18n.t('You can`t use next question types: %s in audit questionnaires', [types]);
      },
      fileContainsErrorsPleaseCheckTheContentAnd: i18n.t('File contains errors. Please check the content and try again.')
    };
  },
  SyncAdmin: function SyncAdmin() {
    return {
      templateDb: i18n.t('Template DB'),
      syncedQuestionnaires: i18n.t('Synced questionnaires'),
      questionnairesThatCanBeSynced: i18n.t('Questionnaires that can be synced'),
      questionnaires: i18n.t('Questionnaires'),
      syncedGapSchemas: i18n.t('Gap schemas'),
      syncedProjects: i18n.t('Compliance projects'),
      syncedRules: i18n.t('Risma rules'),
      syncedNodes: i18n.t('Nodes'),
      connectionStatus: i18n.t('Connection status'),
      update: i18n.t('Update')
    };
  },
  Inbox: function Inbox() {
    return {
      imap: i18n.t('IMAP/POP3 e-mail account information'),
      serverAddress: i18n.t('Server address'),
      serverPort: i18n.t('Server port'),
      username: i18n.t('Username'),
      password: i18n.t('Password'),
      foldername: i18n.t('Folder name (IMAP only)'),
      testConnection: i18n.t('Test connection'),
      informationIsSuccessfullyUpdated: i18n.t('Account information is successfully updated'),
      save: i18n.t('Save'),
      listIsEmpty: i18n.t('List is empty'),
      email: i18n.t('Email'),
      service: i18n.t('Service type'),
      edit: i18n.t('Edit'),
      delete: i18n.t('Delete'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      testConnectionSuccessfull: i18n.t('Test connection successful'),
      areYouSure: i18n.t('Are you sure?'),
      emailRemoved: i18n.t('Email has been removed'),
      emailHasBeenUpdated: i18n.t('Email has been updated'),
      emailHasBeenCreated: i18n.t('Email has been created')
    };
  },
  DeletedNodes: function DeletedNodes() {
    return {
      id: i18n.t('ID'),
      treeLeft: i18n.t('Tree left'),
      treeRight: i18n.t('Tree right'),
      title: i18n.t('Title'),
      project: i18n.t('Project'),
      user: i18n.t('User'),
      date: i18n.t('Date'),
      noNodesToShow: i18n.t('No nodes to show'),
      somethingWentWrong: i18n.t('Something went wrong')
    };
  }
};