import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "mb-8"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_assessment_indicators = _resolveComponent("assessment-indicators");
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.questionnaires, function (scoringQuestionnaire) {
    var _scoringQuestionnaire;
    return _openBlock(), _createElementBlock("div", {
      key: scoringQuestionnaire.id
    }, [scoringQuestionnaire.questionsCount !== undefined && scoringQuestionnaire.answerCount !== undefined ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
      title: $data.translate.questionnaireStats + ': ' + scoringQuestionnaire.tabLabel,
      type: "medium"
    }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", null, _toDisplayString($data.translate.questionCount) + ": " + _toDisplayString(scoringQuestionnaire.questionsCount) + ". " + _toDisplayString($data.translate.questionsAnswered) + ": " + _toDisplayString(scoringQuestionnaire.answerCount) + ". (" + _toDisplayString(scoringQuestionnaire.questionnaireAnsweredPercentage) + " %) ", 1 /* TEXT */), (_scoringQuestionnaire = scoringQuestionnaire.scoringCategories) !== null && _scoringQuestionnaire !== void 0 && _scoringQuestionnaire.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", null, _toDisplayString($data.translate.questionnaireScore) + ": " + _toDisplayString(scoringQuestionnaire.questionnaireScore.totalScore), 1 /* TEXT */), scoringQuestionnaire.questionnaireScore.helpText ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createTextVNode(_toDisplayString($data.translate.assessmentScoring) + ": ", 1 /* TEXT */), _createElementVNode("span", {
      style: _normalizeStyle('color: ' + scoringQuestionnaire.questionnaireScore.helpText.color)
    }, _toDisplayString(scoringQuestionnaire.questionnaireScore.helpText.assessmentText), 5 /* TEXT, STYLE */)])) : _createCommentVNode("v-if", true), scoringQuestionnaire.questionnaireScore.totalScore ? (_openBlock(), _createBlock(_component_assessment_indicators, {
      key: 1,
      "model-value": $options.prepareIndicatorData(scoringQuestionnaire.scoringCategories, scoringQuestionnaire.questionnaireScore.totalScore),
      "total-score": scoringQuestionnaire.questionnaireScore.totalScore
    }, null, 8 /* PROPS */, ["model-value", "total-score"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))]);
}