import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "checkboxes"
};
var _hoisted_2 = ["disabled", "checked", "onChange"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.checkboxOptions, function (option, idx) {
    return _openBlock(), _createElementBlock("div", {
      key: "".concat(option.value, "_").concat(idx),
      class: _normalizeClass(["flex items-center m-2 w-fit", {
        'mx-auto': $props.centerCheckbox
      }])
    }, [_createElementVNode("label", {
      class: _normalizeClass(["flex items-center w-full", [$options.checkboxClasses, option.disabled || $props.disabled ? 'cursor-not-allowed' : 'cursor-pointer']])
    }, [option.label ? (_openBlock(), _createBlock(_component_risma_title, {
      key: 0,
      class: _normalizeClass(["mr-3 flex-grow", {
        'break-words': !$props.truncateLabels
      }]),
      type: $props.labelType,
      truncate: $props.truncateLabels,
      title: option.label
    }, null, 8 /* PROPS */, ["type", "class", "truncate", "title"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
      class: _normalizeClass(["checkbox-wrapper flex items-center justify-center relative", {
        'mr-3': option.label && $props.labelPosition === $data.LabelPositions.RIGHT
      }])
    }, [_createElementVNode("input", {
      style: _normalizeStyle($options.checkboxStyles),
      disabled: option.disabled || $props.disabled,
      type: "checkbox",
      checked: $props.modelValue && $props.modelValue.indexOf(option.value) !== -1 || option.checked,
      onChange: function onChange($event) {
        return $options.handleChange(option.value, $event.target.checked);
      }
    }, null, 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_2), _createVNode(_component_feather_icon, {
      icon: $props.checkIcon,
      width: $props.checkboxSize,
      height: $props.checkboxSize,
      class: "check-icon absolute top-0 left-0 text-white opacity-0 stroke-2"
    }, null, 8 /* PROPS */, ["icon", "width", "height"])], 2 /* CLASS */)], 2 /* CLASS */)], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))]);
}