import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  key: 1,
  class: "relative min-h-66px"
};
var _hoisted_2 = {
  class: "mb-1 glob-h3 text-rm-text"
};
var _hoisted_3 = {
  class: ""
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_text_box = _resolveComponent("text-box");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_click_outside = _resolveDirective("click-outside");
  return !$props.disabled ? (_openBlock(), _createBlock(_component_text_box, {
    key: 0,
    text: $props.field.selectedValue,
    placeholder: $props.placeholder,
    disabled: $props.disabled,
    error: $props.field.required && !$props.field.selectedValue ? $data.translate.pleaseFillOutThisField : '',
    label: $props.field.label,
    "min-height": 44,
    "max-height": 136,
    resize: $props.resize,
    "local-storage-key": $props.localStorageKey,
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updated', $event);
    })
  }, null, 8 /* PROPS */, ["text", "placeholder", "disabled", "error", "label", "resize", "local-storage-key"])) : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, _toDisplayString($props.field.label), 1 /* TEXT */), _createElementVNode("div", {
    ref: "disabledTextBox",
    class: _normalizeClass(["grid grid-cols-[auto_auto] p-2 border rounded-md border-rm-grey bg-rm-wisp", {
      'h-11 overflow-hidden': !$data.isTextBoxExpanded,
      'absolute h-144px overflow-auto drop-shadow z-10': $data.isTextBoxExpanded
    }])
  }, [_createElementVNode("p", _hoisted_3, _toDisplayString($props.field.selectedValue), 1 /* TEXT */), $data.showChevron ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "chevron-down",
    class: _normalizeClass(["mt-3px shrink-0 stroke-2 text-rm-signal-grey-dark transform duration-500 cursor-pointer", {
      'rotate-180': $data.isTextBoxExpanded
    }]),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $data.isTextBoxExpanded = !$data.isTextBoxExpanded;
    })
  }, null, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)])), [[_directive_click_outside, $options.closeExpand]]);
}