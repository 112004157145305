function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import IncidentList from '@/components/Pages/Incident/IncidentList';
import IncidentEntry from '@/components/Pages/Incident/IncidentEntry';
import IncidentDescription from '@/components/Pages/Incident/IncidentDescription';
import LinksTab from '@/components/Organisms/LinksTab';
import Changelog from '@/components/Molecules/Changelog';
import IncidentTypeExpanded from '@/components/Pages/Incident/IncidentTypeExpanded';
import MyIncidents from '@/components/Pages/Incident/MyIncidents';
import IncidentDashboard from '@/components/Pages/Incident/IncidentDashboard';
import IncidentAnonymousCreationPage from '@/components/Pages/Incident/IncidentAnonymousCreationPage';
import QuestionnairePage from '@/components/Pages/Compliance/NodeEdit/QuestionnairePage';
import IncidentsReportsWrapper from '@/components/Pages/Incident/IncidentsReportsWrapper';
import QuestionnaireReport from '@/components/Pages/InformationAssets/Reports/QuestionnaireReport';
import { MODULES } from '@/constants/modules';
import { UserLevels } from '@/constants/UserLevels';
import { userHasLevelIncident } from './auth';
import { INCIDENT } from '@/constants/routes/incident';
import { getReportOptionsFromQuery } from '@/utils/report';
import { useSettingsStore } from '@/Store/settingsStore';
export default (function () {
  var routes = [{
    path: '/incident/list',
    name: INCIDENT.incidentList,
    component: IncidentList,
    meta: {
      userRightsFunction: userHasLevelIncident(UserLevels.NORMAL)
    }
  }, {
    path: '/incident/my/:group?',
    name: INCIDENT.myIncidentsList,
    component: MyIncidents,
    meta: {
      userRightsFunction: userHasLevelIncident(UserLevels.NORMAL),
      keyFunction: function keyFunction() {
        return '/incident/my';
      }
    },
    props: function props(route) {
      return {
        currentRouteGroup: route.params.group || 'my'
      };
    }
  }, {
    path: '/incident/archived',
    name: 'IncidentsArchived',
    component: MyIncidents,
    meta: {
      userRightsFunction: userHasLevelIncident(UserLevels.SUPER)
    },
    props: {
      currentRouteGroup: 'archived'
    }
  }, {
    path: '/incident/report',
    component: IncidentsReportsWrapper,
    redirect: '/incident/report/main',
    meta: {
      userRightsFunction: userHasLevelIncident(UserLevels.NORMAL)
    },
    children: [{
      path: 'main',
      name: 'IncidentReport',
      component: function component() {
        if (useSettingsStore().getValue('feature.thin_incidents_report_enable')) {
          return import('@/components/Pages/Incident/IncidentReportThin');
        }
        return import('@/components/Pages/Incident/IncidentReport');
      }
    }, {
      path: 'questionnaire',
      component: QuestionnaireReport,
      name: 'IncidentsQuestionnaireReport',
      props: function props(route) {
        var savedReportOptions = getReportOptionsFromQuery(route.query);
        return _objectSpread(_objectSpread({}, route.params), {}, {
          savedReportOptions: savedReportOptions,
          module: MODULES.INCIDENTS
        });
      }
    }]
  }, {
    path: '/incident/dashboard',
    name: 'IncidentDashboard',
    component: IncidentDashboard,
    meta: {
      userRightsFunction: userHasLevelIncident(UserLevels.NORMAL)
    }
  }, {
    path: '/incident/:id',
    props: true,
    component: IncidentEntry,
    meta: {
      userRightsFunction: userHasLevelIncident(UserLevels.NORMAL)
    },
    children: [{
      path: 'description',
      name: 'IncidentDescription',
      props: true,
      component: IncidentDescription
    }, {
      path: 'changelog',
      name: 'IncidentChangelog',
      component: Changelog
    }, {
      path: 'links',
      component: LinksTab,
      props: {
        module: MODULES.INCIDENTS
      }
    }, {
      path: 'questionnaire/:questionnaireId',
      component: QuestionnairePage,
      props: function props(route) {
        return {
          id: route.params.id,
          pageType: 'incident',
          questionnaireId: route.params.questionnaireId
        };
      },
      meta: {
        keyFunction: function keyFunction($route) {
          return 'questionnaire/' + $route.params.questionnaireId;
        }
      }
    }, {
      path: '',
      name: 'IncidentEntry',
      redirect: {
        name: 'IncidentDescription'
      }
    }]
  }, {
    path: '/incident/type/:typeId/expanded',
    name: 'IncidentTypeExpanded',
    component: IncidentTypeExpanded,
    props: true,
    meta: {
      userRightsFunction: userHasLevelIncident(UserLevels.NORMAL)
    }
  }, {
    path: '/incident/anonymous',
    component: IncidentAnonymousCreationPage,
    props: true,
    meta: {
      skipLoginCheck: true
    }
  }];
  return routes;
});