import { getTranslate } from './translate';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Modal from '@/components/Molecules/Modal/Modal';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import { MODULES, getModuleTitles } from '@/constants/modules';
export default {
  name: 'SuggestedActivitiesModal',
  components: {
    InputField: InputField,
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    Modal: Modal,
    TextBox: TextBox
  },
  props: {
    articles: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['accept', 'dismiss'],
  data: function data() {
    return {
      data: {
        articleId: '',
        header: '',
        description: '',
        module: ''
      },
      errors: {},
      translate: getTranslate['SuggestedActivitiesModal']()
    };
  },
  computed: {
    articlesOptions: function articlesOptions() {
      return this.articles.map(function (article) {
        return {
          label: "".concat(article.article, " ").concat(article.articleTitle),
          value: article.id
        };
      });
    },
    activityTypeOptions: function activityTypeOptions() {
      var _this = this;
      var titlesConfig = getModuleTitles();
      return [MODULES.CONTROLS, MODULES.EXECUTION].map(function (item) {
        return {
          label: _this.$trans(titlesConfig[item]),
          value: item
        };
      });
    }
  },
  methods: {
    onSave: function onSave() {
      if (this.handleValidation(this.data)) {
        return this.$emit('accept', this.data);
      }
    },
    handleValidation: function handleValidation(data) {
      var _this2 = this;
      this.errors = {};
      Object.keys(this.data).forEach(function (item) {
        if (!data[item]) {
          _this2.errors[item] = 'Please fill out this field';
        }
      });
      return !Object.keys(this.errors).length;
    }
  }
};