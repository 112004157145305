import TextBox from '@/components/Atoms/Inputs/TextBox';
import { ResizeOptions } from '@/constants/ResizeOptions';
export default {
  name: '',
  components: {
    TextBox: TextBox
  },
  data: function data() {
    return {
      ResizeOptions: ResizeOptions,
      text: ''
    };
  }
};