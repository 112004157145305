import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  key: 0,
  class: "mt-1 glob-l1 text-rm-signal-red"
};
var _hoisted_3 = {
  key: 0,
  class: "mt-1 glob-l1 text-rm-signal-red"
};
var _hoisted_4 = {
  key: 0,
  class: "mt-1 glob-l1 text-rm-signal-red"
};
var _hoisted_5 = {
  key: 0,
  class: "mt-1 glob-l1 text-rm-signal-red"
};
var _hoisted_6 = {
  class: "mb"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_input_field = _resolveComponent("input-field");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_text_box = _resolveComponent("text-box");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.sendQuestionnaire,
    "button-ok-text": $data.translate.send,
    "button-dismiss-text": $data.translate.cancel,
    "accept-on-enter": false,
    onAccept: $options.sendInformation,
    onDismiss: $options.toggleModal
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.whenYouSendThisRequestToTheSelectedUser,
        truncate: false,
        class: "mb-3",
        type: "small"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_single_select, {
        title: $data.translate.questionnaire,
        options: $props.auditQuestionnaires,
        placeholder: $data.translate.selectQuestionnaire,
        "required-text": $data.questionnaireError ? $options.descriptionOfTheError : '',
        "model-value": $data.selectedOptionProcessor,
        "onUpdate:modelValue": $options.onSelectDataProcessor
      }, null, 8 /* PROPS */, ["title", "options", "placeholder", "required-text", "model-value", "onUpdate:modelValue"])]), _createElementVNode("div", {
        class: _normalizeClass([{
          'notification-up': $data.nameError
        }, "mb-3"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.nameWritten,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.nameWritten = $event;
        }),
        title: $data.translate.recipientName,
        placeholder: $data.translate.enterTheNameOftheRecipient,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), $data.nameError ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($options.descriptionOfTheError), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
        class: _normalizeClass([{
          'notification-up': $data.companyError
        }, "mb-3"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.companyWritten,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.companyWritten = $event;
        }),
        title: $data.translate.recipientCompanyName,
        placeholder: $data.translate.enterTheNameOfTheCompany,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), $data.companyError ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($options.descriptionOfTheError), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
        class: _normalizeClass([{
          'notification-up': $data.emailError
        }, "mb-3"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.emailWritten,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.emailWritten = $event;
        }),
        title: $data.translate.recipientEmail,
        placeholder: $data.translate.enterTheEmailAddressOfTheRecipient,
        type: "text",
        onUpdated: _cache[3] || (_cache[3] = function ($event) {
          return $data.emailError = '';
        })
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), $data.emailError ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($data.emailError), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
        class: _normalizeClass([{
          'notification-up': $data.deadlineError
        }, "mb-3"])
      }, [_createVNode(_component_date_time_picker, {
        modelValue: $data.deadlineValue,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $data.deadlineValue = $event;
        }),
        "plugin-options": $data.datePickerOptions,
        "use-locale-format": true,
        title: $data.translate.deadline,
        "help-tool-tip": $options.deadlineHint,
        "disabled-date": $options.disabledDate
      }, null, 8 /* PROPS */, ["modelValue", "plugin-options", "title", "help-tool-tip", "disabled-date"]), $data.deadlineError ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($options.descriptionOfTheError), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", _hoisted_6, [_createVNode(_component_text_box, {
        text: $data.commentsWritten,
        placeholder: $data.translate.anyCommentsWillBeIncludedInTheEmail,
        label: $data.translate.comments,
        rows: 5,
        "max-length": 1000,
        class: "w-full",
        onUpdated: _cache[5] || (_cache[5] = function ($event) {
          return $data.commentsWritten = $event;
        })
      }, null, 8 /* PROPS */, ["text", "placeholder", "label"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"]);
}