import { TitleTypes } from '@/constants/TitleTypes';
import { ModalDismissTypes } from '@/constants/ModalDismissTypes';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import { disableScrolling, enableScrolling, getScrollPosition } from '@/utils/setScrolling';
import TrapFocusBoundary from '@/components/Atoms/Containers/TrapFocusBoundary';
export default {
  name: 'Modal',
  introduction: 'Component for displaying a Modal dialog',
  description: 'Uses in parent <attachment-modal> component.',
  token: "<modal\n    :header=\"translate.attachFiles\"\n    @accept=\"toggleModal\"\n    @dismiss=\"toggleModal\"\n    :buttonOkText=\"translate.okButton\">\n</modal>",
  components: {
    TrapFocusBoundary: TrapFocusBoundary,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    ButtonsRow: ButtonsRow
  },
  props: {
    header: {
      required: true,
      type: String
    },
    headerType: {
      type: String,
      required: false,
      default: TitleTypes.BIG
    },
    headerTruncate: {
      type: Boolean,
      required: false,
      default: true
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: true
    },
    buttonOkText: {
      type: String,
      required: false,
      default: 'OK'
    },
    buttonDismissText: {
      type: String,
      required: false,
      default: 'Dismiss'
    },
    dismissOnEscKey: {
      required: false,
      type: Boolean,
      default: true
    },
    dismissOnClickOutside: {
      required: false,
      type: Boolean,
      default: true
    },
    showModalContainerMask: {
      required: false,
      type: Boolean,
      default: false
    },
    showDismissButton: {
      type: Boolean,
      required: false,
      default: true
    },
    showOkButton: {
      type: Boolean,
      required: false,
      default: true
    },
    type: {
      type: String,
      required: false,
      default: 'large',
      validator: function validator(val) {
        return ['small', 'large', 'big', 'wide'].includes(val);
      }
    },
    dismissButtonDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    okButtonDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    disableScrolling: {
      type: Boolean,
      required: false,
      default: true,
      note: 'to disable scrolling'
    },
    modalMaxWidth: {
      type: String,
      required: false,
      default: '',
      note: 'Maximum width of the modal'
    },
    modalFixedWidth: {
      type: String,
      required: false,
      default: '',
      note: 'If we need the popup modal to be particular width'
    },
    modalFixedHeight: {
      type: String,
      required: false,
      default: '',
      note: 'If we need the popup modal to be particular height'
    },
    acceptOnEnter: {
      type: Boolean,
      required: false,
      default: true,
      note: 'accept modal on enter hit'
    },
    bodyFullHeight: {
      type: Boolean,
      required: false,
      default: null,
      note: 'Determine if body section should take all empty space'
    },
    bodyScrollY: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Enables vertical scrolling on the modal-body. Should be used when overflowVisible is set to false'
    },
    detectScrollToBottom: {
      type: Boolean,
      required: false,
      default: false,
      note: 'A flag that indicates whether the component should monitor the scroll event and detect when the modal body reaches the bottom'
    }
  },
  emits: ['dismiss', 'accept', 'scroll:bottomReached'],
  data: function data() {
    return {
      modalDismissTypes: ModalDismissTypes,
      scrollPosition: 0,
      translate: {
        fileUploadInProgress: this.$trans('File upload in progress'),
        attachFiles: this.$trans('Attach files'),
        okButton: this.$trans('Ok'),
        dismiss: this.$trans('Dismiss')
      },
      scrollReachedBottomOnce: false
    };
  },
  computed: {
    dismissText: function dismissText() {
      if (!this.buttonDismissText || this.buttonDismissText === 'Dismiss') return this.translate.dismiss;
      return this.buttonDismissText;
    },
    buttonsRowData: function buttonsRowData() {
      var _this = this;
      var list = [];
      var buttonClasses = 'capitalize';
      if (this.showDismissButton) {
        list.push({
          text: this.dismissText,
          type: 'util',
          buttonClasses: buttonClasses,
          disabled: this.dismissButtonDisabled,
          clickEvent: function clickEvent($event) {
            return _this.$emit('dismiss', _this.modalDismissTypes.BUTTON, $event);
          }
        });
      }
      if (this.showOkButton) {
        list.push({
          text: this.buttonOkText,
          type: 'save',
          buttonClasses: buttonClasses,
          disabled: this.okButtonDisabled,
          clickEvent: function clickEvent($event) {
            return _this.$emit('accept', $event);
          }
        });
      }
      return list;
    }
  },
  mounted: function mounted() {
    if (this.dismissOnEscKey) {
      document.body.addEventListener('keyup', this.onEscClick);
    }
    if (this.disableScrolling) {
      this.scrollPosition = getScrollPosition();
      disableScrolling(this.scrollPosition);
    }
    if (this.detectScrollToBottom) {
      this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
      this.checkScroll();
      window.addEventListener('resize', this.checkScroll);
    }
  },
  beforeUnmount: function beforeUnmount() {
    if (this.detectScrollToBottom) {
      this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('resize', this.checkScroll);
    }
  },
  unmounted: function unmounted() {
    if (this.dismissOnEscKey) {
      document.body.removeEventListener('keyup', this.onEscClick);
    }
    if (this.disableScrolling) {
      enableScrolling(this.scrollPosition);
    }
  },
  methods: {
    emitDismiss: function emitDismiss() {
      var dismissType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'icon';
      // Ensure that the focus is removed from any active element to fix scrolling issues when closing the modal while an input is focused.
      document.activeElement.blur();
      this.$emit('dismiss', dismissType);
    },
    dismissWhenClickedOutside: function dismissWhenClickedOutside() {
      if (this.dismissOnClickOutside && !this.dismissButtonDisabled) {
        this.emitDismiss();
      }
    },
    onEnterClick: function onEnterClick() {
      if (this.acceptOnEnter) {
        this.$emit('accept');
      }
    },
    onEscClick: function onEscClick(event) {
      if (event.code === 'Escape' && !this.dismissButtonDisabled) {
        this.emitDismiss();
      }
    },
    emitBottomReachedEvent: function emitBottomReachedEvent() {
      this.$emit('scroll:bottomReached');
      this.scrollReachedBottomOnce = true;
    },
    handleScroll: function handleScroll(el) {
      var tolerance = 1;
      if (el.target.scrollHeight - el.target.scrollTop <= el.target.clientHeight + tolerance) {
        this.emitBottomReachedEvent();
      }
    },
    checkScroll: function checkScroll() {
      if (this.scrollReachedBottomOnce) return;
      var el = this.$refs.scrollContainer;
      if (el.scrollHeight <= el.clientHeight) {
        this.emitBottomReachedEvent();
      }
    }
  }
};