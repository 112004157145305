export default {
  name: 'DragAndDropIcon',
  introduction: 'Icon for drag and drop',
  token: '<drag-and-drop-icon />',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      extraStyles: [this.disabled ? 'cursor-auto opacity-50' : 'move-icon hover:text-rm-signal-grey-dark hover:bg-rm-grey-light']
    };
  }
};