function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import i18n from '@/i18n';
import { getMonthsNames } from '@/utils/date';
import { capitalizeWords } from '@/utils/capitalize';
export var CONTRACT_STATUSES = {
  expiring: 'expiring',
  renewal: 'renewal',
  DRAFT: 'Draft',
  APPROVED: 'Approved',
  EXPIRED: 'Expired',
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
  IN_REVIEW: 'In Review',
  IN_RENEWAL: 'In Renewal'
};
export var COMMITMENT_STATUS = 'commitment';
export var getIntervalOptions = function getIntervalOptions() {
  return [{
    label: i18n.t('Monthly'),
    value: 1
  }, {
    label: i18n.t('Quarterly'),
    value: 3
  }, {
    label: i18n.t('Biannually'),
    value: 6
  }, {
    label: i18n.t('Annually'),
    value: 12
  }];
};
export var getMonthOptions = function getMonthOptions() {
  var monthNames = getMonthsNames();
  return monthNames.map(function (monthName, idx) {
    return {
      label: capitalizeWords(monthName),
      value: idx + 1
    };
  });
};
export var getIntervalTitleByValue = function getIntervalTitleByValue(value) {
  var intervals = getIntervalOptions();
  var interval = intervals.find(function (curInterval) {
    return +curInterval.value === +value;
  });
  return interval ? interval.label : '';
};
export var CONTRACT_PARTY_TYPES = {
  EXTERNAL: 1,
  INTERNAL: 2
};
export var MODAL_TYPES = _objectSpread(_objectSpread({}, CONTRACT_PARTY_TYPES), {}, {
  COPY: 3
});