function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { Colors } from '@/Colors';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { MODULES, getModuleTitles } from '@/constants/modules';
import { getAssessmentProjectsOptions } from '@/constants/links/LinksModalSettings';
import { questionHasSomeDependencyChosen } from '@/utils/questionnaires';
import { sortNumericByPropComplex, sortByIndex } from '@/utils/sort';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { generateComputed } from '@/utils/base/generateComputed';
import { getInitiatives as _getInitiatives } from '@/api/risma/data';
import { getAll as _getControls } from '@/api/controls/controls';
import { getAllQuestionAnswersForNode, getQuestionnaireAssessments } from '@/api/compliance/questionnaire';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import SelectorTags from '@/components/Molecules/SelectorTags';
import AssessmentIndicators from '@/components/Molecules/Compliance/AssessmentIndicators';
import LinksModal from '@/components/Organisms/Links/LinksModal';
export default {
  name: 'QuestionnaireAssessmentPage',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    LinksModal: LinksModal,
    SelectorTags: SelectorTags,
    FeatherIcon: FeatherIcon,
    AssessmentIndicators: AssessmentIndicators,
    TinyMceBox: TinyMceBox
  },
  introduction: '',
  description: '',
  token: '<questionnaire-assessment-page></questionnaire-assessment-page>',
  props: {
    data: {
      required: true,
      type: Object,
      note: 'Current node object'
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    return {
      colors: Colors,
      loaded: false,
      questions: null,
      currentAssessments: [],
      assessmentIndicatorData: null,
      controls: [],
      initiatives: [],
      showLinksModal: false,
      preselectedLinks: [],
      addedControls: [],
      addedInitiatives: [],
      selectedModule: null,
      ModuleTitles: getModuleTitles(),
      MODULES: MODULES,
      isSortedByScore: false,
      translate: getTranslate['QuestionnaireAssessmentPage']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    allSettings: 'settings'
  })), mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserComplianceProjectById: 'complianceProjectById'
  })), generateComputed(['assessment'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    projectsOptions: function projectsOptions() {
      return getAssessmentProjectsOptions(this.allSettings, this.currentUser);
    },
    currentProject: function currentProject() {
      return this.data.compliance_projects_id ? this.currentUserComplianceProjectById(this.data.compliance_projects_id) : {};
    },
    answeredQuestions: function answeredQuestions() {
      var _this = this;
      return this.questions.filter(function (item) {
        return Object.keys(item.answers_options).length && questionHasSomeDependencyChosen(item, _this.questions);
      });
    },
    linksDataSet: function linksDataSet() {
      return _defineProperty(_defineProperty({}, MODULES.EXECUTION, sortByIndex([].concat(_toConsumableArray(this.initiatives), _toConsumableArray(this.addedInitiatives)), 'path')), MODULES.CONTROLS, [].concat(_toConsumableArray(this.controls), _toConsumableArray(this.addedControls)));
    },
    links: function links() {
      var _this2 = this;
      var links = {};
      Object.keys(this.linksDataSet).forEach(function (module) {
        links[module] = _this2.linksDataSet[module].filter(function (item) {
          return _this2.data.links[module] && _this2.data.links[module].find(function (id) {
            return item.id == id;
          });
        });
      });
      return links;
    },
    filteredLinkModules: function filteredLinkModules() {
      var _this3 = this;
      return Object.keys(this.links).filter(function (module) {
        return _this3.links[module].length;
      });
    },
    linksOptions: function linksOptions() {
      return this.linksDataSet[this.selectedModule] || [];
    },
    filteredAnsweredQuestions: function filteredAnsweredQuestions() {
      var list = _toConsumableArray(this.answeredQuestions);
      this.isSortedByScore && list.sort(function (a, b) {
        return b._totalScore - a._totalScore;
      });
      return list;
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this4 = this;
      this.getAll().then(function () {
        return _this4.getCurrentAssessments();
      }).then(function () {
        if (_this4.currentAssessments.length) {
          _this4.sortByValues(_this4.currentAssessments);
          _this4.assessmentIndicatorData = _this4.prepareIndicatorData();
        }
        _this4.loaded = true;
      }).catch(function (e) {
        throw e;
      });
    },
    getAll: function getAll() {
      var _this5 = this;
      return Promise.all([getAllQuestionAnswersForNode(this.data.compliance_projects_id, this.data.id, this.data.questionnaire.id).then(function (response) {
        return _this5.questions = _this5.addAdditionalFields(response.list);
      }), this.getInitiatives(), this.getControls()]);
    },
    getCurrentAssessments: function getCurrentAssessments() {
      var _this6 = this;
      return getQuestionnaireAssessments(this.data.questionnaire.id).then(function (_ref2) {
        var list = _ref2.list;
        _this6.currentAssessments = list.map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            score: item.score + ''
          });
        });
      });
    },
    getInitiatives: function getInitiatives() {
      var _this7 = this;
      return _getInitiatives().then(function (_ref3) {
        var list = _ref3.list;
        return _this7.initiatives = sortByIndex(RismaControlsUtils.cloneEntities(list), 'path');
      });
    },
    getControls: function getControls() {
      var _this8 = this;
      return _getControls().then(function (_ref4) {
        var list = _ref4.list;
        _this8.controls = sortByIndex(RismaControlsUtils.cloneEntities(list), 'path');
      });
    },
    prepareIndicatorData: function prepareIndicatorData() {
      var _this9 = this;
      var result = [];
      this.currentAssessments.forEach(function (item, index) {
        if (index === 0) return;
        var nextIndex = index + 1;
        var next = _this9.currentAssessments[nextIndex];
        var oneStepInPercents = 100 / (_this9.currentAssessments.length - 1);
        result.push({
          title: next ? item.score : ">= ".concat(item.score),
          score: +item.score,
          minScore: _this9.currentAssessments[index - 1].score,
          color: item.color,
          isFilled: +item.score <= _this9.data.questionnaireScore.totalScore,
          width: oneStepInPercents
        });
      });
      return result;
    },
    addAdditionalFields: function addAdditionalFields(data) {
      var _this10 = this;
      return data.map(function (item) {
        var answersOptions = Object.keys(item.answers_options).reduce(function (prev, curr) {
          prev += _this10.getAnswereScore(item.questionobject, curr);
          return prev;
        }, 0);
        return _objectSpread(_objectSpread({}, item), {}, {
          _totalScore: answersOptions,
          _expanded: true
        });
      });
    },
    toggleVisibility: function toggleVisibility(item) {
      item._expanded = !item._expanded;
    },
    sortByValues: function sortByValues(data) {
      if (data && data.length) {
        sortNumericByPropComplex(data, 'score');
      }
      return data;
    },
    updateAssessment: function updateAssessment(e) {
      this.assessment = e;
    },
    getAnswereScore: function getAnswereScore(_ref5, answerId) {
      var optionDetails = _ref5.optionDetails;
      var option = optionDetails.find(function (answer) {
        return answer.id === +answerId;
      });
      return option.score;
    },
    toggleLinksModal: function toggleLinksModal() {
      this.showLinksModal = !this.showLinksModal;
    },
    setLinksOption: function setLinksOption(module) {
      this.selectedModule = module;
      this.preselectedLinks = _toConsumableArray(this.links[module]);
    },
    changeLinks: function changeLinks(_ref6) {
      var module = _ref6.module,
        links = _ref6.links;
      var value = _objectSpread(_objectSpread({}, this.data.links), {}, _defineProperty({}, module, links));
      this.$emit('propertyChanged', {
        property: 'links',
        value: value
      });
      this.toggleLinksModal();
    },
    deleteLink: function deleteLink(module, linkId) {
      var value = _objectSpread(_objectSpread({}, this.data.links), {}, _defineProperty({}, module, this.data.links[module].filter(function (id) {
        return id != linkId;
      })));
      this.$emit('propertyChanged', {
        property: 'links',
        value: value
      });
    },
    addNewItem: function addNewItem(item) {
      switch (item.objType) {
        case ObjectTypes.CONTROLS_CONTROL:
          this.addedControls.push(item);
          break;
        case ObjectTypes.EXECUTION_INITIATIVE:
          this.addedInitiatives.push(item);
          break;
      }
    }
  }
};