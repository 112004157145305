function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { ChoiceViewTypes } from '@/constants/ChoiceViewTypes';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
export default {
  name: 'SingleChoice',
  introduction: 'Single choice is list with checkboxes or dropdown view',
  description: 'Single choice is list with checkboxes or dropdown view based on feature flag - risma.single_choice_as_dropdown.',
  token: "\n<single-choice options=\"options\" answers=\"answers\" @answered=\"fn\"/>\n// Properties looks like\noptions: { 1: 'Yes', 2: 'No' }\nanswers: { 1: 'Yes' }\n",
  components: {
    SingleSelect: SingleSelect,
    RadioButtons: RadioButtons
  },
  props: {
    options: {
      type: Array,
      required: true,
      note: 'A list of items'
    },
    answers: {
      type: [Array, Object],
      required: true,
      note: 'Predefined answers'
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Questionn is be read-only'
    },
    radioButtonFocus: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Determine whether selected or first button will be focused after mounting'
    }
  },
  emits: ['answered'],
  data: function data() {
    return {
      value: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    viewTypes: function viewTypes() {
      return ChoiceViewTypes;
    },
    viewType: function viewType() {
      return this.getSettingValue('risma.single_choice_as_dropdown') ? this.viewTypes.DROPDOWN : this.viewTypes.RADIO_BUTTONS;
    },
    optionsArray: function optionsArray() {
      return this.options.map(function (option) {
        return {
          value: option.id,
          label: option.label
        };
      });
    }
  }),
  watch: {
    answers: {
      deep: true,
      handler: function handler(answerUpdated) {
        this.value = +Object.keys(answerUpdated)[0] || false;
      }
    }
  },
  created: function created() {
    this.value = +Object.keys(this.answers)[0] || false;
  },
  methods: {
    handleUpdate: function handleUpdate(valueAnswered) {
      var answer = {};
      var answerOption = this.optionsArray.find(function (option) {
        return option.value === valueAnswered;
      });
      if (answerOption) {
        answer[answerOption.value] = answerOption.label;
      }
      this.$emit('answered', answer);
    }
  }
};