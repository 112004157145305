function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { Colors } from '@/Colors';
import { getAllByType } from '@/api/compliance/informationAssets';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import Modal from '@/components/Molecules/Modal/Modal';
import { MODULES, getModuleTitles } from '@/constants/modules';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export default {
  name: 'CompanyLinkModal',
  token: '<company-link-modal :linked-information-assets="{information_assets: []}" />',
  components: {
    SingleSelect: SingleSelect,
    Modal: Modal,
    TrafficLight: TrafficLight,
    FeatherIcon: FeatherIcon,
    RismaSelect: RismaSelect
  },
  props: {
    linkedInformationAssets: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['dismissModal', 'updateLinks', 'showAssetCreationModal'],
  data: function data() {
    return {
      AssetsTypes: AssetsTypes,
      Colors: Colors,
      moduleTitles: getModuleTitles(),
      selectedLinkOption: null,
      itemsByModules: {},
      selectedItems: [],
      translate: getTranslate['CompanyLinkModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, ['complianceIACustomActivityTypes'])), {}, {
    informationAssetsName: function informationAssetsName() {
      return this.getSettingValue('feature.information_assets_name') || this.$trans('Assets');
    },
    selectIALabel: function selectIALabel() {
      return "".concat(this.translate.select, " ").concat(this.informationAssetsName, " ").concat(this.translate.type.toLowerCase());
    },
    linkOptions: function linkOptions() {
      var result = [{
        value: MODULES.COMPLIANCE_IA_PROCESSOR,
        label: this.$trans(this.moduleTitles[MODULES.COMPLIANCE_IA_PROCESSOR])
      }, {
        value: MODULES.COMPLIANCE_IA_CONTROLLER,
        label: this.$trans(this.moduleTitles[MODULES.COMPLIANCE_IA_CONTROLLER])
      }, {
        value: MODULES.COMPLIANCE_IA_SYSTEM,
        label: this.$trans(this.moduleTitles[MODULES.COMPLIANCE_IA_SYSTEM])
      }];
      this.complianceIACustomActivityTypes.forEach(function (activityType) {
        if (!activityType.inUse) return;
        result.push({
          value: "".concat(activityType.module, "-").concat(activityType.id),
          label: activityType.label,
          id: activityType.id
        });
      });
      return result;
    },
    selectLinkLabel: function selectLinkLabel() {
      if (!this.selectedLinkModule) return '';
      return this.translate.select + ' ' + this.getModuleTitle(this.selectedLinkModule);
    },
    selectLinkPlaceholder: function selectLinkPlaceholder() {
      if (!this.selectedLinkModule) return '';
      return this.translate.clickToSelect + ' ' + this.getModuleTitle(this.selectedLinkModule);
    },
    assetTypeBySelectedModule: function assetTypeBySelectedModule() {
      if (!this.selectedLinkModule) return null;
      if (this.selectedLinkModule === MODULES.COMPLIANCE_IA_PROCESSOR) {
        return AssetsTypes.PROCESSORS;
      }
      if (this.selectedLinkModule === MODULES.COMPLIANCE_IA_CONTROLLER) {
        return AssetsTypes.CONTROLLERS;
      }
      if (this.selectedLinkModule === MODULES.COMPLIANCE_IA_SYSTEM) {
        return AssetsTypes.SYSTEMS;
      }
      if (this.selectedLinkModule === MODULES.COMPLIANCE_INFORMATION_ASSETS) {
        return AssetsTypes.INFORMATION_ASSETS_API;
      }
      return null;
    },
    selectedActivityTypeId: function selectedActivityTypeId() {
      var _this = this;
      if (this.selectedLinkOption && this.selectedLinkOption.startsWith(MODULES.COMPLIANCE_INFORMATION_ASSETS)) {
        var selectedActivityType = this.linkOptions.find(function (option) {
          return option.value === _this.selectedLinkOption;
        });
        return selectedActivityType.id;
      }
      return null;
    },
    selectableItems: function selectableItems() {
      var _this2 = this;
      var result = this.itemsByModules[this.assetTypeBySelectedModule] || [];
      if (this.selectedActivityTypeId) {
        result = result.filter(function (asset) {
          return asset.activityTypeId === _this2.selectedActivityTypeId;
        });
      }
      return result;
    },
    selectedItemsIds: function selectedItemsIds() {
      return this.selectedItems.map(function (item) {
        return item.id;
      });
    },
    selectedLinkModule: function selectedLinkModule() {
      if (!this.selectedLinkOption) return null;
      if (this.selectedLinkOption.startsWith(MODULES.COMPLIANCE_INFORMATION_ASSETS)) {
        return MODULES.COMPLIANCE_INFORMATION_ASSETS;
      }
      return this.selectedLinkOption;
    },
    showCreateItemButton: function showCreateItemButton() {
      return this.selectedLinkModule === MODULES.COMPLIANCE_IA_PROCESSOR;
    }
  }),
  watch: {
    assetTypeBySelectedModule: function assetTypeBySelectedModule(newType) {
      if (!newType) return;
      if (!this.itemsByModules[newType]) {
        this.getItemsBySelectedModule();
      }
    },
    selectedLinkOption: function selectedLinkOption() {
      this.setSelectedItems(this.linkedInformationAssets, this.selectedLinkOption, this.selectedActivityTypeId);
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightColor'])), {}, {
    getItemsBySelectedModule: function getItemsBySelectedModule() {
      var _this3 = this;
      return getAllByType(this.assetTypeBySelectedModule).then(function (_ref) {
        var list = _ref.list;
        var flatList = _this3.getItemsFlat(list);
        _this3.itemsByModules[_this3.assetTypeBySelectedModule] = flatList;
      });
    },
    updateLinks: function updateLinks() {
      if (!this.selectedLinkModule) {
        this.$emit('dismissModal');
      } else {
        this.$emit('updateLinks', {
          assetType: this.assetTypeBySelectedModule,
          assetsIds: this.selectedItemsIds,
          module: this.selectedLinkModule,
          activityTypeId: this.selectedActivityTypeId
        });
      }
    },
    setSelectedItems: function setSelectedItems(data, option, id) {
      if (id) {
        var allItems = data[MODULES.COMPLIANCE_INFORMATION_ASSETS].slice(0);
        this.selectedItems = allItems.filter(function (asset) {
          return asset.activityTypeId === id;
        });
      } else {
        var _data$option;
        this.selectedItems = ((_data$option = data[option]) === null || _data$option === void 0 ? void 0 : _data$option.slice(0)) || [];
      }
    },
    onLinkSelect: function onLinkSelect(items) {
      this.selectedItems = _toConsumableArray(items);
    },
    onLinkDeselect: function onLinkDeselect(item) {
      this.selectedItems = this.selectedItems.filter(function (selectedItem) {
        return selectedItem.id !== item.id;
      });
    },
    getModuleTitle: function getModuleTitle(module) {
      if (!this.moduleTitles[module]) return '';
      if (this.moduleTitles[module] === this.informationAssetsName) return this.informationAssetsName;
      return this.$trans(this.moduleTitles[module]).toLowerCase();
    },
    getItemsFlat: function getItemsFlat(items) {
      return items.reduce(function (list, item) {
        var _item$childrenObjects;
        list.push(item);
        if ((_item$childrenObjects = item.childrenObjects) !== null && _item$childrenObjects !== void 0 && _item$childrenObjects.length) {
          list.push.apply(list, _toConsumableArray(item.childrenObjects));
        }
        return list;
      }, []);
    }
  })
};