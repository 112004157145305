function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import draggable from 'vuedraggable';
import cloneDeep from 'lodash/cloneDeep';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Pagination from '@/components/Atoms/Pagination';
import RismaColumnSwitcher from '@/components/Atoms/RismaColumnSwitcher/RismaColumnSwitcher';
import RismaPagesize from '@/components/Atoms/RismaDatatable/RismaPagesize';
import DataTableColumnFilter from '@/components/Atoms/Datatable/DataTableColumnFilter';
import DataTableFilterTags from '@/components/Atoms/Datatable/DataTableFilterTags';
import RismaPrint from '@/components/Atoms/Print/RismaPrint';
import RismaExcel from '@/components/Atoms/Excel/RismaExcel';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import InputField from '@/components/Atoms/Inputs/InputField';
import * as Utils from '@/utils/Utils';
import debounce from 'lodash/debounce';
import { Colors } from '@/Colors';
import isEqual from 'lodash/isEqual';
import { prepareFilterRegex, escapeRegex as _escapeRegex, removeFilterRegex } from '@/utils/regex';
import { prepareDatasetToExport } from '@/utils/export/prepareToExport';
import { sortArrayOfObjectsByArrayOfValues } from '@/utils/sort';
import { SortDirection } from '@/constants/SortDirection';
import { ReportLogTypes } from '@/constants/reports';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { CellTypes } from '@/utils/export/excel';
export default {
  name: 'RismaDatatable',
  introduction: 'RismaDatatable pure vue only version',
  description: 'Vue datatable grid component',
  token: '<risma-datatable :datasetMain="dataset" />',
  components: {
    draggable: draggable,
    FeatherIcon: FeatherIcon,
    Pagination: Pagination,
    RismaColumnSwitcher: RismaColumnSwitcher,
    RismaPagesize: RismaPagesize,
    DataTableColumnFilter: DataTableColumnFilter,
    DataTableFilterTags: DataTableFilterTags,
    RismaPrint: RismaPrint,
    RismaExcel: RismaExcel,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    InputField: InputField
  },
  props: {
    datasetMain: {
      required: true,
      type: Array,
      note: 'Main dataset array (without additional columns prop, everything is here)'
    },
    datasetTotal: {
      required: true,
      type: Array,
      note: 'dataset total (not sliced not filtered)'
    },
    datasetTotalSliced: {
      required: true,
      type: Array,
      note: 'dataset total (all pages filtered)'
    },
    columnsMeta: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Predefined columns visibility/width can be passed here'
    },
    alwaysVisibleExportColumns: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Should some columns be always visible in Export / Print?'
    },
    stateLocal: {
      type: String,
      default: '',
      note: 'The name of a key in localStorage'
    },
    pageLength: {
      type: Number,
      required: false,
      default: 50,
      note: 'Number of show items per page'
    },
    paging: {
      type: Boolean,
      required: false,
      default: true,
      note: 'This uses the paging option to disable paging for the table.'
    },
    pagingOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [10, 25, 50, 100];
      },
      note: 'Paging options'
    },
    order: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Default order inside the columns. asc/desc'
    },
    filterByColumn: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Filter by column'
    },
    filterByRenderFunc: {
      type: Function,
      default: null,
      note: 'Function for rendering filter by options in the Filter Modal'
    },
    decoupleFilterFields: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of columns for which decoupling by separator should be applied (in Column Filter)'
    },
    disabledFilterByColumns: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'For which column filter by should be disabled'
    },
    disabledSortByColumns: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'For which column sorting by should be disabled'
    },
    columnFiltersSorting: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'User defined sorting of filter options in the Filter Modal'
    },
    columnsByDefault: {
      type: Object,
      required: false,
      default: null,
      note: 'Default columns that will be displayed if there is no dataset'
    },
    columnSorting: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'User defined sorting of specific columns'
    },
    columnsOrder: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Order of the columns. Columns in this array go before the rest(unordered) columns'
    },
    excelFilename: {
      required: false,
      type: String,
      default: 'RISMAbusiness',
      note: 'Excel filename'
    },
    fullHeight: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is datatable shows with full height'
    },
    printTitle: {
      required: false,
      type: String,
      default: '',
      note: 'Title of table in print mode'
    },
    wrapperHeightSticky: {
      type: Number,
      required: false,
      default: 0,
      note: 'Max height of scrolling part of the table in pixels'
    },
    isGap: {
      type: Boolean,
      required: false,
      default: false,
      note: 'if it is Gap datatable then true'
    },
    doResizing: {
      type: Boolean,
      required: false,
      default: false,
      note: 'if there is a component inside table and it doesnt fit in - this property will do resizing of the tables cells width'
    },
    headerCellStyle: {
      required: false,
      type: String,
      default: '',
      note: 'Classes for styling th elements'
    },
    showPagination: {
      required: false,
      type: Boolean,
      default: true,
      note: 'If to show pagination at the bottom'
    },
    confirmExportMessage: {
      required: false,
      type: String,
      default: '',
      note: 'Confirmation popup message to display before exporting to excel'
    },
    confirmPrintMessage: {
      required: false,
      type: String,
      default: '',
      note: 'Confirmation popup message to display before printing'
    },
    customExport: {
      required: false,
      type: Object,
      default: null,
      note: 'Object with functions for custom export of columns and dataset to Excel and Print'
    },
    firstInit: {
      required: false,
      type: Boolean,
      default: false,
      note: 'needed for some components that have backend sort and init default data'
    },
    withGlobalSearch: {
      required: false,
      type: Boolean,
      default: true,
      note: 'define if global search will be displayed'
    },
    mediaPrintCss: {
      required: false,
      default: '',
      type: String,
      note: 'Media print css styles'
    },
    hasTopNavigation: {
      required: false,
      default: true,
      type: Boolean
    },
    dataCellStyles: {
      required: false,
      type: String,
      default: '',
      note: 'Classes for styling td elements'
    },
    isDraggable: {
      required: false,
      default: true,
      type: Boolean
    },
    defaultSorting: {
      type: Object,
      required: false,
      default: function _default() {
        return {
          keyIndex: 0,
          dir: SortDirection.ASC
        };
      },
      validator: function validator(val) {
        return val === null || typeof val.keyIndex === 'number' && !!val.dir;
      },
      note: 'Which sorting rules should be applied after state resetting'
    },
    loadingIndicatorState: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Loading indicator from parent that shows over rows'
    },
    additionalFilterOptions: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['export', 'pageChange', 'backendSort', 'onStateChanged', 'afterRemovingFilter', 'update:visibility', 'update:scrollState'],
  data: function data() {
    return {
      ReportLogTypes: ReportLogTypes,
      colors: Colors,
      globalSearchTimeout: 500,
      columns: [],
      defaultColumns: [],
      // Next: save state in localStorage
      tableState: {
        columns: [],
        search: {
          search: ''
        },
        order: [],
        pageLength: null,
        locale: Utils.getLanguage()
      },
      pageLengthDefault: null,
      filteredCSS: 'is-filtered',
      tbodyOffsetHorisontal: 100,
      lastKnownScrollLeft: 0,
      displayColumnFilter: false,
      currentColumnKey: null,
      filterOptionsSelected: {},
      bodyHasScroll: false,
      filterData: {
        index: null,
        target: null,
        values: null,
        disabledFilterBy: null,
        disabledSorting: null
      },
      translate: {
        search: this.$trans('Search'),
        noRecordsMatching: this.$trans('No records matching'),
        resetFormatting: this.$trans('Reset formatting')
      },
      additionalFilterOption: null
    };
  },
  computed: {
    datasetMaxLength: function datasetMaxLength() {
      return this.datasetTotal.length;
    },
    visibleColumns: function visibleColumns() {
      return this.columns.filter(function (column) {
        return column.visible;
      });
    },
    resetButtonLabel: function resetButtonLabel() {
      return this.translate.resetFormatting;
    },
    filterOptions: function filterOptions() {
      var _this$filterOptionsSe;
      return ((_this$filterOptionsSe = this.filterOptionsSelected[this.currentColumnKey]) === null || _this$filterOptionsSe === void 0 ? void 0 : _this$filterOptionsSe.selected) || [];
    },
    columnsExport: function columnsExport() {
      var _this = this;
      var result = {};
      var columns = this.customExport && this.customExport.columns ? this.customExport.columns(this.visibleColumns) : this.visibleColumns;
      columns.map(function (item) {
        result[item.key] = item.label;
      });
      this.alwaysVisibleExportColumns.map(function (key) {
        if (result[key]) {
          return;
        }
        var foundColumn = _this.columns.find(function (column) {
          return column.key === key;
        });
        if (foundColumn) {
          result[foundColumn.key] = foundColumn.label;
        }
      });
      return result;
    },
    datasetExport: function datasetExport() {
      var dataset = this.customExport && this.customExport.dataset ? this.customExport.dataset(_toConsumableArray(this.datasetTotalSliced), this.columnsExport) : this.datasetTotalSliced;
      return prepareDatasetToExport(dataset, this.columnsExport, this.isGap);
    },
    stateSession: function stateSession() {
      return this.stateLocal ? this.stateLocal + '-session' : '';
    },
    tbodyHeight: function tbodyHeight() {
      var offset = this.fullHeight ? this.tbodyOffsetHorisontal : this.wrapperHeightSticky;
      return "calc(100vh - ".concat(offset, "px)");
    },
    columnsTypes: function columnsTypes() {
      var firstRow = this.datasetMain[0] || {};
      return Object.keys(firstRow).reduce(function (acc, cur) {
        var _firstRow$cur;
        if (((_firstRow$cur = firstRow[cur]) === null || _firstRow$cur === void 0 ? void 0 : _firstRow$cur.fieldType) === CustomFieldTypes.NUMERICAL) {
          acc[cur] = CellTypes.NUMBER;
        }
        return acc;
      }, {});
    }
  },
  watch: {
    datasetMain: {
      deep: true,
      handler: function handler() {
        var _this2 = this;
        this.initColumnsOnce();
        if (this.doResizing) {
          this.$nextTick(function () {
            _this2.resizeHeadTable();
          });
        }
        this.$nextTick(function () {
          _this2.handleScrollLogic();
        });
      }
    },
    columnsMeta: function columnsMeta() {
      this.applyColumnsWidth();
    },
    datasetTotalSliced: {
      deep: true,
      handler: function handler(after, before) {
        if (!isEqual(after, before)) {
          // some components using v-slot:pageSize like gap.vue that has own logic in parent, so this way it will be updated properly
          this.tableState.pageLength = this.pageLength;
          this.emitState();
        }
      }
    },
    tableState: {
      handler: function handler() {
        this.emitState();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.onGlobalSearch = debounce(this.onGlobalSearchInput, this.globalSearchTimeout);
    this.setSearchFromSession();
    this.initColumnsOnce();
    this.fullHeight && window.addEventListener('resize', this.setTbodyOffset);
    window.addEventListener('resize', this.handleScrollLogic);
    this.pageLengthDefault = this.pageLength;
    if (!this.tableState.pageLength) this.tableState.pageLength = this.pageLength;
  },
  beforeUnmount: function beforeUnmount() {
    this.removeScroll();
    this.fullHeight && window.removeEventListener('resize', this.setTbodyOffset);
    window.removeEventListener('resize', this.handleScrollLogic);
  },
  methods: {
    handleScrollLogic: function handleScrollLogic() {
      if (!this.$refs.body) return;
      this.bodyHasScroll = this.$refs.body.scrollHeight > this.$refs.body.clientHeight;
      this.$emit('update:scrollState', this.bodyHasScroll);
    },
    setSearchFromSession: function setSearchFromSession() {
      var savedSession = JSON.parse(sessionStorage.getItem(this.stateSession));
      if (savedSession !== null && savedSession !== void 0 && savedSession.search) {
        this.tableState.search.search = savedSession.search;
      }
    },
    onColumnDrag: function onColumnDrag() {
      this.tableState.columns = this.columns;
    },
    resizeHeadTable: function resizeHeadTable() {
      var _this3 = this;
      this.visibleColumns.forEach(function (element) {
        var width = _this3.detectDefaultWidth(element.key);
        var datatableCol = _this3.$el.querySelector(".datatable-col-".concat(element.key));
        if ((datatableCol === null || datatableCol === void 0 ? void 0 : datatableCol.style.width) != width) {
          var col = _this3.$el.querySelector(".col-".concat(element.key));
          if (col) {
            datatableCol.style.width = col.offsetWidth - 20 + 'px';
            datatableCol.style.minWidth = col.offsetWidth - 20 + 'px';
            datatableCol.style.maxWidth = col.offsetWidth - 20 + 'px';
          }
        }
      });
    },
    setDefaultColumns: function setDefaultColumns(columns) {
      var _this4 = this;
      for (var key in columns) {
        this.columns.push({
          key: key,
          label: columns[key],
          visible: true,
          css: '',
          search: '',
          width: null
        });
      }
      this.defaultColumns = cloneDeep(this.columns);
      var savedState = this.loadState();
      this.tableState.pageLength = this.pageLength;
      this.tableState.columns = _toConsumableArray(this.columns);
      if (savedState && savedState.locale === Utils.getLanguage()) {
        this.handleStateSafely(savedState);
        this.showAppliedFilters();
      }
      this.$nextTick(function () {
        _this4.addScroll();
        _this4.fullHeight && _this4.setTbodyOffset();
      });
    },
    initColumnsOnce: function initColumnsOnce() {
      var _this5 = this;
      if (this.columns.length) return;
      if (this.columnsByDefault) {
        this.setDefaultColumns(this.columnsByDefault);
      }
      if (!this.datasetMain.length) return;
      var savedState = this.loadState();
      this.columns = this.createColumns(this.datasetMain[0]);
      this.defaultColumns = cloneDeep(this.columns);
      this.tableState.columns = _toConsumableArray(this.columns);
      if (this.order.length) {
        this.tableState.order = _toConsumableArray(this.order);
      }
      if (this.tableState.order.length) {
        this.addDefaultSort();
      }
      if (savedState && savedState.locale === Utils.getLanguage()) {
        this.applyLabels(savedState);
        this.handleStateSafely(savedState);
        this.showAppliedFilters();
        this.applyColumnsWidth();
      }
      this.$nextTick(function () {
        _this5.addScroll();
        _this5.fullHeight && _this5.setTbodyOffset();
      });
      var selectedSortCol = this.firstInit && this.columns.find(function (item) {
        return item.sort;
      });
      if (selectedSortCol) {
        this.$emit('backendSort', selectedSortCol.sort);
      }
    },
    updateSearch: function updateSearch(value) {
      var _this$tableState;
      if (((_this$tableState = this.tableState) === null || _this$tableState === void 0 || (_this$tableState = _this$tableState.search) === null || _this$tableState === void 0 ? void 0 : _this$tableState.search) !== undefined) {
        this.tableState.search.search = value;
      }
    },
    onGlobalSearchInput: function onGlobalSearchInput() {
      if (!this.stateSession) return;
      var savedSession = JSON.parse(sessionStorage.getItem(this.stateSession)) || {};
      sessionStorage.setItem(this.stateSession, JSON.stringify(_objectSpread(_objectSpread({}, savedSession), {}, {
        search: this.tableState.search.search
      })));
    },
    resetPagination: function resetPagination() {
      if (this.$refs && this.$refs.pagination && typeof this.$refs.pagination.resetPage === 'function') {
        this.$refs.pagination.resetPage();
      }
    },
    emitPageChange: function emitPageChange(indexes) {
      this.$emit('pageChange', indexes);
    },
    createColumns: function createColumns(row) {
      var _this6 = this;
      var result = [];
      if (row) {
        var notOrderedColumns = Object.keys(row).filter(function (key) {
          return _this6.columnsOrder.indexOf(key) === -1;
        });
        var columnsInOrder = [].concat(_toConsumableArray(this.columnsOrder), _toConsumableArray(notOrderedColumns));
        columnsInOrder.forEach(function (key) {
          if (row[key]) {
            result.push({
              key: key,
              label: row[key].label,
              visible: _this6.detectDefaultVisibility(key),
              width: _this6.detectDefaultWidth(key),
              search: '',
              css: ''
            });
          }
        });
      }
      return result;
    },
    detectDefaultVisibility: function detectDefaultVisibility(key) {
      var result = true;
      this.columnsMeta.map(function (item) {
        if ('visible' in item) {
          if (item.targets.includes(key)) {
            result = item.visible;
          }
        }
      });
      return result;
    },
    detectDefaultWidth: function detectDefaultWidth(key) {
      var result = null;
      this.columnsMeta.map(function (item) {
        if ('width' in item) {
          if (item.targets.includes(key)) {
            result = item.width;
          }
        }
      });
      return result;
    },
    onChangeVisibility: function onChangeVisibility(column) {
      column.visible = !column.visible;
      this.$emit('update:visibility', column);
    },
    onTheadCellClick: function onTheadCellClick(column, event) {
      if (this.isDisabledColumnMenu(column.key)) return;
      var currentFilterAlreadyOpened = this.currentColumnKey === column.key && this.displayColumnFilter;
      if (this.filterByColumn && currentFilterAlreadyOpened) {
        this.displayColumnFilter = false;
        return;
      }
      if (this.filterByColumn && !currentFilterAlreadyOpened) {
        this.currentColumnKey = column.key;
        this.setAdditionalFilterOption(column.key);
        this.prepareFilterData(column.key, event);
        this.displayColumnFilter = true;
        return;
      }
      if (!this.checkIsDisabledFilterBy(column.key)) {
        var data = {
          dir: this.getSortDirection(column.key),
          index: column.key
        };
        this.sortByColumn(data);
      }
    },
    prepareFilterData: function prepareFilterData(columnKey, event) {
      var disabledFilterBy = this.checkIsDisabledFilterBy(columnKey);
      var disabledSorting = this.checkIsDisabledSorting(columnKey);
      var filterOptions = disabledFilterBy ? [] : this.prepareFilterOptions(columnKey, this.datasetTotal);
      this.filterData = {
        index: columnKey,
        target: event.target,
        values: filterOptions,
        disabledFilterBy: disabledFilterBy,
        disabledSorting: disabledSorting
      };
      if (!filterOptions) return;
      if (!this.filterOptionsSelected[columnKey]) {
        this.filterOptionsSelected[columnKey] = {
          filterText: '',
          selected: filterOptions
        };
      } else {
        this.filterOptionsSelected[columnKey].selected = this.filterSelected(columnKey, filterOptions);
      }
    },
    filterSelected: function filterSelected(columnKey, data) {
      var result = data,
        selectedOptions = this.filterOptionsSelected[columnKey],
        selected = selectedOptions ? selectedOptions.selected : [];
      selected.map(function (selectedItem) {
        if (selectedItem.isActive) {
          var foundDataItems = result.filter(function (item) {
            return item.text === selectedItem.text;
          });
          if (foundDataItems.length) {
            result.map(function (item, index) {
              if (item.text === selectedItem.text) {
                result.splice(index, 1, selectedItem); // Swap
              }
            });
          } else {
            result.unshift(selectedItem);
          }
        }
      });
      return result;
    },
    onFilterOptionSelect: function onFilterOptionSelect(_ref) {
      var key = _ref.key,
        value = _ref.value;
      var idx = this.filterOptionsSelected[key].selected.findIndex(function (item) {
        return item.value === value;
      });
      if (idx === -1) return;
      var selectedOption = this.filterOptionsSelected[key].selected[idx];
      this.filterOptionsSelected[key].selected[idx] = _objectSpread(_objectSpread({}, selectedOption), {}, {
        isActive: !selectedOption.isActive
      });
      var onlyActive = this.filterOptionsSelected[key].selected.filter(function (item) {
        return item.isActive;
      });
      this.doFilteringByColumn({
        index: key,
        data: onlyActive,
        refresh: onlyActive.length === 0
      });
    },
    onFilterOptionSearch: function onFilterOptionSearch(_ref2) {
      var value = _ref2.value,
        key = _ref2.key;
      this.filterOptionsSelected[key].filterText = value;
      var newOptions = this.filterOptionsSelected[key].selected.map(function (item) {
        item.isHidden = false;
        return item;
      });
      if (value) {
        newOptions.map(function (item) {
          var itemText = item.text + '';
          if (itemText.toLowerCase().indexOf(value.toLowerCase()) === -1) {
            item.isHidden = true;
          }
        });
      }
      this.filterOptionsSelected[key].selected = newOptions;
    },
    getSortDirection: function getSortDirection(columnKey) {
      var result = SortDirection.DESC;
      this.columns.forEach(function (column) {
        if (columnKey === column.key) {
          if (column.sort) {
            if (column.sort.dir === SortDirection.DESC) {
              result = SortDirection.ASC;
            }
          }
        }
      });
      return result;
    },
    checkIsDisabledFilterBy: function checkIsDisabledFilterBy(key) {
      return this.disabledFilterByColumns.indexOf(key) !== -1;
    },
    checkIsDisabledSorting: function checkIsDisabledSorting(key) {
      var _this$datasetTotal;
      var result = this.disabledSortByColumns.includes(key);
      if (key.startsWith('cf-') && (_this$datasetTotal = this.datasetTotal) !== null && _this$datasetTotal !== void 0 && _this$datasetTotal.length) {
        var _this$datasetTotal$;
        var fieldType = (_this$datasetTotal$ = this.datasetTotal[0]) === null || _this$datasetTotal$ === void 0 || (_this$datasetTotal$ = _this$datasetTotal$[key]) === null || _this$datasetTotal$ === void 0 ? void 0 : _this$datasetTotal$.fieldType;
        result = result || fieldType === CustomFieldTypes.SELECT_MULTI;
      }
      return result;
    },
    isDisabledColumnMenu: function isDisabledColumnMenu(key) {
      return this.disabledSortByColumns.includes(key) && this.checkIsDisabledFilterBy(key);
    },
    prepareFilterOptions: function prepareFilterOptions(columnKey, data) {
      var _this7 = this;
      var result = this.getFilterOptionsFromState(columnKey);
      data.forEach(function (item) {
        if (item[columnKey] && item[columnKey].field) {
          _this7.addFilterOption(result, item, columnKey);
        }
      });
      this.addSorting(result, columnKey);
      return this.populateFilterOptions(result, columnKey);
    },
    getFilterOptionsFromState: function getFilterOptionsFromState(columnKey) {
      var result = [];
      var state = this.loadState();
      if (state && state.columns) {
        var column = state.columns.find(function (item) {
          return item.key === columnKey;
        });
        if (column) {
          result = column === null || column === void 0 ? void 0 : column.search.split('|');
          result = removeFilterRegex(result);
          result = removeFilterRegex(result, column.delta);
        }
      }
      return Utils.getOnlyUniqueAndExist(result);
    },
    addFilterOption: function addFilterOption(data, item, columnKey) {
      var rowData = Utils.getCorrectRowField(item[columnKey]);
      var isDecoupledFound = columnKey.startsWith('cf-') && item[columnKey].fieldType === CustomFieldTypes.SELECT_MULTI;
      var decoupleItem = this.decoupleFilterFields.find(function (decoupleItem) {
        return decoupleItem.key === columnKey;
      }) || null;
      if (isDecoupledFound || decoupleItem) {
        this.handleDecoupleFields(rowData, data, decoupleItem);
        return;
      }
      rowData += '';
      if (data.indexOf(rowData) === -1) {
        data.push(rowData);
      }
    },
    handleDecoupleFields: function handleDecoupleFields(rowData, data) {
      var decoupleItem = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var DEFAULT_SEPARATOR = ',';
      var result = rowData.split((decoupleItem === null || decoupleItem === void 0 ? void 0 : decoupleItem.separator) || DEFAULT_SEPARATOR);
      result.map(function (rowDataItem) {
        rowDataItem += '';
        rowDataItem = rowDataItem.trim(); // Whitespace after splitting

        if (data.indexOf(rowDataItem) === -1) {
          data.push(rowDataItem);
        }
      });
    },
    addSorting: function addSorting(data, columnKey) {
      var sortingItem = this.columnFiltersSorting.find(function (item) {
        return item.key === columnKey;
      });
      if (sortingItem && typeof sortingItem.sortingFunc === 'function') {
        sortingItem.sortingFunc(data);
      } else {
        // Add default sorting
        this.sortFilterOptions(data);
      }
    },
    populateFilterOptions: function populateFilterOptions(data, columnKey) {
      var _this8 = this;
      var result = data.filter(function (el) {
        return el;
      });
      return result.map(function (item) {
        return {
          text: typeof _this8.filterByRenderFunc === 'function' ? _this8.filterByRenderFunc(item, columnKey) : item,
          value: item,
          isActive: _this8.isActiveFilter(item, columnKey)
        };
      });
    },
    isActiveFilter: function isActiveFilter(item, columnKey) {
      var state = this.loadState();
      var result = false;
      if (state && state.columns) {
        var column = state.columns.find(function (item) {
          return item.key === columnKey;
        });
        if (column) {
          result = column.search.split('|');
          result = removeFilterRegex(result, column.delta);
          result = Utils.getOnlyUniqueAndExist(result);
          result = result.includes(item + '');
        }
      }
      return result;
    },
    sortFilterOptions: function sortFilterOptions(data) {
      return data.sort(function (a, b) {
        var itemA = a + '';
        var itemB = b + '';
        return itemA.toLowerCase().localeCompare(itemB.toLowerCase());
      });
    },
    sortByColumn: function sortByColumn(e) {
      var _this9 = this;
      this.columns.forEach(function (column) {
        column.sort = null;
        if (e.index === column.key) {
          column.sort = e;
          var sortingItem = _this9.columnSorting.find(function (item) {
            return item.key === column.key;
          });
          if (sortingItem && typeof sortingItem.sortingFunc === 'function') {
            column.sort.sortingFunc = sortingItem.sortingFunc;
          }
        }
      });
      this.emitState();
      this.$emit('backendSort', e);
    },
    addDefaultSort: function addDefaultSort() {
      var _this10 = this;
      this.tableState.order.forEach(function (item) {
        var column = _this10.columns.find(function (column) {
          return column.key === item.index;
        });
        if (column) {
          column.sort = item;
        }
      });
    },
    emitState: function emitState() {
      if (this.tableState.columns.length) {
        this.saveState();
      }
      this.$emit('onStateChanged', {
        state: this.tableState
      });
      this.resetPagination();
    },
    doFilteringByColumn: function doFilteringByColumn(e) {
      var _this11 = this;
      var initialString = e.data.map(function (item) {
        return item.value;
      });
      var filterString = '';
      if (initialString) {
        initialString.forEach(function (item) {
          item = _this11.escapeRegex(item);
          if (_this11.additionalFilterOption) {
            filterString += _this11.prepareExtraFilterString(item);
          } else {
            filterString += _this11.prepareFilterString(item);
          }
        });
        filterString = filterString.slice(0, -1); // Remove the last symbol '|'
      }
      this.additionalFilterOption && this.setAdditionalFieldValue(e.index);
      this.searchByColumn(filterString, e.index);
      this.addFilterClass(filterString, e.index);
      if (this.filterByColumn && e.refresh) {
        this.refreshFilterModal(e.index);
      }
    },
    prepareExtraFilterString: function prepareExtraFilterString(item) {
      return this.additionalFilterOption.value ? this.additionalFilterOption.actions.check.event(item) : this.additionalFilterOption.actions.uncheck.event(item);
    },
    refreshFilterModal: function refreshFilterModal(columnKey) {
      this.filterData = {
        index: columnKey,
        values: this.prepareFilterOptions(columnKey, this.datasetTotal),
        disabledFilterBy: this.checkIsDisabledFilterBy(columnKey)
      };
    },
    searchByColumn: function searchByColumn(searchValue, columnKey) {
      this.columns.forEach(function (item) {
        if (item.key === columnKey) {
          item.search = searchValue;
        }
      });
    },
    escapeRegex: function escapeRegex(val) {
      return _escapeRegex(val);
    },
    prepareFilterString: function prepareFilterString(item) {
      return prepareFilterRegex(item);
    },
    addFilterClass: function addFilterClass(filterString, columnKey) {
      var currentColumn = this.columns.find(function (item) {
        return item.key === columnKey;
      });
      var appliedFilterItem = {
        index: columnKey,
        name: currentColumn.label
      };
      if (filterString) {
        currentColumn.css = this.filteredCSS;
        if (this.$refs.dataTableFilterTagsRef) {
          this.$refs.dataTableFilterTagsRef.addFilterTag(appliedFilterItem);
        }
      } else {
        currentColumn.css = '';
        if (this.$refs.dataTableFilterTagsRef) {
          this.$refs.dataTableFilterTagsRef.removeFilterTag(appliedFilterItem);
        }
      }
    },
    showAppliedFilters: function showAppliedFilters() {
      var _this12 = this;
      this.$nextTick(function () {
        _this12.columns.map(function (column) {
          if (column.search) {
            _this12.addFilterClass(column.search, column.key);
          }
        });
      });
    },
    removeAppliedFilter: function removeAppliedFilter(item) {
      var _this$filterOptionsSe2;
      var options = (_this$filterOptionsSe2 = this.filterOptionsSelected[item.index]) === null || _this$filterOptionsSe2 === void 0 ? void 0 : _this$filterOptionsSe2.selected;
      if (options) {
        options.map(function (item) {
          item.isActive = false;
        });
        this.filterOptionsSelected[item.index].selected = options;
      }
      this.doFilteringByColumn({
        index: item.index,
        data: [],
        refresh: false
      });
      this.afterRemovingFilter(item);
    },
    afterRemovingFilter: function afterRemovingFilter(item) {
      this.$emit('afterRemovingFilter', item);
    },
    getSortClass: function getSortClass(column) {
      var result = 'sorting';
      if (column.sort) {
        result += "_".concat(column.sort.dir);
      }
      return result;
    },
    applyColumnsWidth: function applyColumnsWidth() {
      var _this13 = this;
      this.columns.forEach(function (column) {
        column.width = _this13.detectDefaultWidth(column.key);
      });
    },
    applyLabels: function applyLabels(state) {
      var _this14 = this;
      var stateColumns = state.columns;
      stateColumns.forEach(function (stateColumn) {
        var column = _this14.columns.find(function (column) {
          return column.key === stateColumn.key;
        });
        if (!column) return;
        stateColumn.label = column.label || '';
      });
    },
    resetColumns: function resetColumns() {
      this.columns = this.defaultColumns.length ? cloneDeep(this.defaultColumns) : this.createColumns(this.datasetMain[0]);
    },
    resetFilterTags: function resetFilterTags() {
      if (this.$refs.dataTableFilterTagsRef) {
        this.$refs.dataTableFilterTagsRef.items = [];
      }
    },
    resetFilterOptions: function resetFilterOptions() {
      for (var key in this.filterOptionsSelected) {
        this.filterOptionsSelected[key].filterText = '';
        this.filterOptionsSelected[key].selected = [];
      }
    },
    resetGlobalSearch: function resetGlobalSearch() {
      this.globalSearch = '';
    },
    getDefaultSorting: function getDefaultSorting() {
      var _this$columns$this$de;
      return {
        index: ((_this$columns$this$de = this.columns[this.defaultSorting.keyIndex]) === null || _this$columns$this$de === void 0 ? void 0 : _this$columns$this$de.key) || '',
        dir: this.defaultSorting.dir
      };
    },
    resetState: function resetState() {
      this.resetColumns();
      this.resetFilterTags();
      this.resetFilterOptions();
      this.resetGlobalSearch();
      this.tableState = {
        columns: _toConsumableArray(this.columns),
        search: {
          search: ''
        },
        locale: Utils.getLanguage(),
        order: [],
        pageLength: this.pageLengthDefault || this.pageLength
      };
      if (this.defaultSorting) {
        var defaultSort = this.getDefaultSorting();
        this.tableState.order = this.order.length ? _toConsumableArray(this.order) : [defaultSort];
      }
      this.addDefaultSort();
    },
    saveState: function saveState() {
      var data = JSON.stringify(this.tableState);
      if (this.stateLocal !== '') {
        localStorage.setItem(this.stateLocal, data);
      }
    },
    loadState: function loadState() {
      return JSON.parse(localStorage.getItem(this.stateLocal));
    },
    handleStateSafely: function handleStateSafely(state) {
      var _this15 = this;
      this.columns.forEach(function (column, index) {
        state.columns.forEach(function (stateColumn) {
          if (column.key === stateColumn.key) {
            _this15.columns.splice(index, 1, stateColumn);
          }
        });
      });
      this.columns = this.sortColumnsByState(this.columns, state);
      state.columns = this.columns;
      state.pageLength = this.paging ? state.pageLength : this.pageLength;
      this.tableState = _objectSpread(_objectSpread({}, this.tableState), state);
    },
    sortColumnsByState: function sortColumnsByState(columns, state) {
      return sortArrayOfObjectsByArrayOfValues(columns, state.columns.map(function (column) {
        return column.key;
      }));
    },
    setTbodyOffset: function setTbodyOffset() {
      var _this$$el$querySelect = this.$el.querySelector('.risma-datatable-scrollBody').getBoundingClientRect(),
        tableBodyOffsetTop = _this$$el$querySelect.y;
      var tableBodyOffsetBottom = 0;
      if (this.showPagination) {
        var pagination = this.$el.querySelector('.pagination');
        var _ref3 = (pagination === null || pagination === void 0 ? void 0 : pagination.getBoundingClientRect()) || {
            top: 0
          },
          top = _ref3.top;
        var paginationStyle = getComputedStyle(pagination);
        var paginationMarginTop = parseInt(paginationStyle.marginTop);
        tableBodyOffsetBottom = document.body.scrollHeight - top + paginationMarginTop;
      }
      this.tbodyOffsetHorisontal = tableBodyOffsetTop + tableBodyOffsetBottom;
    },
    addScroll: function addScroll() {
      var _this16 = this;
      var scrollHead = this.$el.querySelector('.table-wrapper-head');
      var scrollBody = this.$el.querySelector('.risma-datatable-scrollBody');
      if (scrollBody && !scrollBody.onscroll) {
        scrollBody.onscroll = function (e) {
          scrollHead.scrollLeft = e.target.scrollLeft;
          if (_this16.lastKnownScrollLeft !== e.target.scrollLeft) {
            _this16.displayColumnFilter = false;
          }
          _this16.lastKnownScrollLeft = e.target.scrollLeft;
        };
      }
    },
    removeScroll: function removeScroll() {
      var scrollBody = this.$el.querySelector('.risma-datatable-scrollBody');
      if (scrollBody) {
        scrollBody.onscroll = null;
      }
    },
    setAdditionalFieldValue: function setAdditionalFieldValue(columnKey) {
      var column = this.columns.find(function (item) {
        return item.key === columnKey;
      });
      column.additionalFieldValue = this.additionalFilterOption.value;
      column.delta = this.additionalFilterOption.value ? this.additionalFilterOption.actions.check.delta : this.additionalFilterOption.actions.uncheck.delta;
    },
    setAdditionalFilterOption: function setAdditionalFilterOption(columnKey) {
      if (!this.additionalFilterOptions[columnKey]) {
        return this.additionalFilterOption = null;
      }
      this.additionalFilterOption = this.additionalFilterOptions[columnKey];
      var column = this.columns.find(function (item) {
        return item.key === columnKey;
      });
      this.additionalFilterOption.value = column.additionalFieldValue;
    },
    onAdditionalFilterChanged: function onAdditionalFilterChanged(_ref4) {
      var value = _ref4.value,
        key = _ref4.key;
      this.additionalFilterOption.value = value;
      var onlyActive = this.filterOptionsSelected[key].selected.filter(function (item) {
        return item.isActive;
      });
      this.doFilteringByColumn({
        index: key,
        data: onlyActive,
        refresh: onlyActive.length === 0
      });
    }
  }
};