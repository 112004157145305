function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useTagsStore } from '@/Store/tagsStore';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
import { getReport2, getReport2Url } from '@/api/compliance/reports/predefinedReport';
import { getQuestionnairesByQuestionnaireTypeId as _getQuestionnairesByQuestionnaireTypeId } from '@/api/compliance/questionnaire';
import { getSingle } from '@/api/compliance/projects';
import { getAllControllers, getAllProcessors } from '@/api/compliance/informationAssets';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import { logReport as _logReport, getReport } from '@/api/reports/reports';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import SoaTable from '@/components/Pages/Compliance/Reports/PredefinedReports/soa/Table';
import Article30Table from '@/components/Pages/Compliance/Reports/PredefinedReports/article30/Table';
import QuestionnaireSelection from '@/components/Pages/Compliance/Reports/PredefinedReports/QuestionnaireSelection';
import { mapQuestionAnswers } from '@/components/Pages/Compliance/Reports/PredefinedReports/utils';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { getDataFlowImageUrl } from '@/utils/Compliance/DataFlow';
import { getAnsweredQuestionsByActivity } from '@/utils/reports/questionnaire';
import { filterAsset } from '@/utils/format/objectsFromIds';
import { mapToCommaList } from '@/utils/format/mapTo';
import { MODULES } from '@/constants/modules';
import { getCompanyWithUrl } from '@/utils/format/companies';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { ReportTypes } from '@/constants/reports';
import { VIEW_TYPES } from '@/constants/ViewTypes';
export default {
  name: 'QuestionnaireReport',
  components: {
    Article30Table: Article30Table,
    SoaTable: SoaTable,
    QuestionnaireSelection: QuestionnaireSelection,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle
  },
  props: {
    projectId: {
      type: [Number, String],
      required: true,
      note: 'The ID of the project'
    },
    reportType: {
      type: String,
      required: true,
      note: 'The type of report to display'
    },
    savedReportOptions: {
      type: Object,
      default: null
    },
    reportId: {
      type: Number,
      default: null
    }
  },
  setup: function setup(props) {
    return {
      selectedTableView: getLocalStorageComputed('questionnaire_report_' + props.projectId, null)
    };
  },
  data: function data() {
    return {
      lastUsedParams: {},
      loading: false,
      showReport: false,
      rows: [],
      questions: [],
      answers: [],
      entities: [],
      questionnaires: [],
      selectedQuestionnaire: null,
      error: '',
      report: null,
      reportTable: null,
      mode: null,
      informationAssets: null,
      reportUrl: '',
      article30: 'article30',
      processTreeNodes: [],
      allDataController: [],
      allDataProcessors: [],
      allCompanies: [],
      companiesMap: new Map(),
      updatedContactCard: null,
      includeDataFlow: false,
      isAnsweredOnly: false,
      includeRaciEmails: false,
      includeHelpText: false,
      reportArgs: {},
      translate: {
        nothingToShow: this.$trans('Nothing to show'),
        enterYourSearchCriteriaAboveToViewSearchRe: this.$trans('Enter your search criteria above to view search results')
      },
      initialFilters: null
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useOrganisationsStore, ['organisations'])), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useTagsStore, ['tags'])), {}, {
    showNothingToShowMessage: function showNothingToShowMessage() {
      return this.mode === this.article30 && !this.entities.length && this.showReport;
    },
    currentLocation: function currentLocation() {
      var currentProject = this.complianceProjectById(this.projectId);
      return [currentProject.title, 'Reports', 'Article30/Questionnaire'];
    },
    localStateName: function localStateName() {
      return 'questionnaire_report_results_' + this.projectId;
    },
    savedReportType: function savedReportType() {
      if (this.reportType === 'article-30') {
        return ReportTypes.COMPLIANCE_ARTICLE30;
      }
      return ReportTypes.COMPLIANCE_QUESTIONNAIRE;
    }
  }),
  watch: {
    reportType: function reportType() {
      this.init();
    },
    savedReportOptions: function savedReportOptions() {
      if (this.savedReportOptions) {
        this.selectedTableView = VIEW_TYPES.EXPANDED;
        this.initialFilters = this.savedReportOptions;
      }
    },
    reportId: function reportId() {
      if (this.reportId) this.getUtilReports();
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useComplianceProjectsStore, {
    complianceProjectById: 'getProjectById'
  })), {}, {
    init: function init() {
      var _this = this;
      this.handleClearTable();
      if (this.savedReportOptions) {
        this.selectedTableView = VIEW_TYPES.EXPANDED;
        this.initialFilters = this.savedReportOptions;
      }
      return Promise.all([getAllControllers().then(function (_ref) {
        var list = _ref.list;
        return _this.allDataController = list;
      }), this.getProcessors(), this.getCompanies(), Promise.all([this.getSingleProject(), this.getUtilReports()]).then(this.getQuestionnairesByQuestionnaireTypeId)]);
    },
    getSingleProject: function getSingleProject() {
      var _this2 = this;
      return getSingle(this.projectId).then(function (project) {
        _this2.report = project.reports[_this2.reportType];
        if (_this2.report === undefined) {
          // Unknown report type. Slice is off and go back to the first report available
          var newRoute = _this2.$route.path.split('/').slice(0, -1).join('/');
          _this2.$router.replace(newRoute);
          return;
        }
        _this2.reportTable = _this2.report.table + '-table';
        _this2.mode = _this2.report.table;
      });
    },
    getUtilReports: function getUtilReports() {
      var _this3 = this;
      if (!this.reportId) return Promise.resolve();
      this.selectedTableView = VIEW_TYPES.EXPANDED;
      return getReport(this.reportId).then(function (report) {
        var _report$query;
        _this3.initialFilters = report === null || report === void 0 || (_report$query = report.query) === null || _report$query === void 0 ? void 0 : _report$query.reportOptions;
      });
    },
    getQuestionnairesByQuestionnaireTypeId: function getQuestionnairesByQuestionnaireTypeId() {
      var _this4 = this;
      return _getQuestionnairesByQuestionnaireTypeId(this.report.questionnaireTypeId).then(function (response) {
        return _this4.questionnaires = response.list;
      });
    },
    getCompanies: function getCompanies() {
      var _this5 = this;
      return _getCompanies().then(function (_ref2) {
        var list = _ref2.list;
        return _this5.allCompanies = list;
      });
    },
    getProcessors: function getProcessors() {
      var _this6 = this;
      return getAllProcessors().then(function (_ref3) {
        var list = _ref3.list;
        return _this6.allDataProcessors = list;
      });
    },
    handleCreateReport: function handleCreateReport(_ref4) {
      var _this7 = this;
      var questionnaireIds = _ref4.questionnaireIds,
        questionNumbers = _ref4.questionNumbers,
        nodeIds = _ref4.nodeIds,
        showControllersAndProcessors = _ref4.showControllersAndProcessors,
        includeDataFlow = _ref4.includeDataFlow,
        includeRaciEmails = _ref4.includeRaciEmails,
        isAnsweredOnly = _ref4.isAnsweredOnly,
        includeHelpText = _ref4.includeHelpText,
        organisationIds = _ref4.organisationIds,
        _ref4$resetQuery = _ref4.resetQuery,
        resetQuery = _ref4$resetQuery === void 0 ? true : _ref4$resetQuery;
      this.showReport = false;
      this.loading = true;
      this.questions = [];
      this.error = '';
      this.includeDataFlow = includeDataFlow;
      this.includeRaciEmails = includeRaciEmails;
      this.includeHelpText = includeHelpText;
      this.isAnsweredOnly = isAnsweredOnly;
      this.showControllersAndProcessors = showControllersAndProcessors;
      this.reportArgs = {
        questionnaireIds: questionnaireIds,
        questionNumbers: questionNumbers,
        nodeIds: nodeIds,
        showControllersAndProcessors: showControllersAndProcessors,
        includeDataFlow: includeDataFlow,
        includeRaciEmails: includeRaciEmails,
        organisationIds: organisationIds,
        isAnsweredOnly: isAnsweredOnly
      };
      if ((this.savedReportOptions || this.reportId) && resetQuery) {
        this.$router.replace({
          query: {}
        });
      }
      this.reportUrl = getReport2Url(this.projectId, questionnaireIds, questionNumbers, nodeIds);
      this.lastUsedParams = {
        questionnaireIds: questionnaireIds,
        nodeIds: nodeIds,
        questionIds: questionNumbers
      };
      getReport2(this.projectId, questionnaireIds, questionNumbers, nodeIds, this.report.predefinedSearch).then(function (resp) {
        _this7.tree = resp.tree;
        _this7.questions = isAnsweredOnly ? getAnsweredQuestionsByActivity(resp.questions, nodeIds, 'compliance_nodes_id') : resp.questions;
        _this7.answers = resp.answers;
        _this7.entities = Object.values(resp.entities);
        _this7.informationAssets = resp.informationAssets;
        _this7.files = resp.files;
        _this7.rows = [];
        _this7.error = '';
        _this7.processTreeNodes = resp.processTreeNodes;
        var nodeIdLocal = _toConsumableArray(nodeIds);
        _this7.handleMapNodes(_this7.tree, null, questionnaireIds, nodeIdLocal);
        _this7.showReport = true;
        _this7.loading = false;
      }).catch(function (resp) {
        _this7.showReport = false;
        _this7.loading = false;
        if (resp.response) {
          if (resp.response.error !== undefined) {
            _this7.error = _this7.$trans(resp.response.error);
          }
        } else {
          _this7.error = 'Unhandled error: ' + resp.message;
          throw resp;
        }
      });
    },
    logReport: function logReport(count) {
      var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      return _logReport(MODULES.COMPLIANCE, count, this.currentLocation, this.lastUsedParams, format);
    },
    handleMapNodes: function handleMapNodes(node, parent, questionnaireIds, nodeIds) {
      var _node$questionnaires,
        _this8 = this;
      // Root node will have empty parent and we don't want to show the root node
      var isNotRootNode = parent != null;
      var nodeQuestionnaireIds = (_node$questionnaires = node.questionnaires) === null || _node$questionnaires === void 0 ? void 0 : _node$questionnaires.map(function (q) {
        return q.questionnaireId;
      });
      var hasQuestionnaireId = questionnaireIds.some(function (qId) {
        return nodeQuestionnaireIds.includes(qId);
      });
      var isThisNodeFiltered = nodeIds.length > 0 && nodeIds.indexOf(node.id) === -1;
      var tags = mapToCommaList(filterAsset(this.tags, node.tagIds), 'tag');
      if (isNotRootNode && hasQuestionnaireId && !isThisNodeFiltered) {
        nodeQuestionnaireIds.forEach(function (qId) {
          var _this8$questionnaires;
          var row = {
            title: node.title,
            parent: node.parent,
            nodeId: node.id,
            objType: node.objType,
            url: node.url,
            compliance_projects_id: node.compliance_projects_id,
            status: node.status,
            traffic_light: node.traffic_light,
            values: node.values,
            purpose: node.purpose,
            description: node.description,
            questionnaireId: node.questionnaireId,
            questionnaire: (_this8$questionnaires = _this8.questionnaires.find(function (q) {
              return +q.id === +qId;
            })) === null || _this8$questionnaires === void 0 ? void 0 : _this8$questionnaires.title,
            cells: _this8.prepareCells(node.id, qId),
            dataFlowUrl: node.hasDataFlow ? getDataFlowImageUrl(_this8.projectId, node.id) : null
          };
          if (_this8.report.table === _this8.article30) {
            _this8.addSelectedUsersAndOrganisations(row, node);
            row.tags = tags;
            row.dpoName = node.dpoName || '';
          }
          var checkEntityPresence = _this8.entities.find(function (item) {
            return item.id == node.id;
          });
          if (checkEntityPresence) {
            _this8.rows.push(row);
          }
        });
      }
      if (node.children) {
        node.children.forEach(function (childNode) {
          if (isNotRootNode) {
            childNode.parent = node;
          }
          if (!isThisNodeFiltered) {
            nodeIds.push(childNode.id);
          }
          _this8.handleMapNodes(childNode, node, questionnaireIds, nodeIds);
        });
      }
    },
    addSelectedUsersAndOrganisations: function addSelectedUsersAndOrganisations(to, value) {
      to.responsibleUsers = this.getResponsible(value);
      to.accountableUsers = this.getAccountable(value);
      to.informedUsers = this.getInformed(value);
      to.consultedUsers = this.getConsulted(value);
      to.organisations = this.getOrgs(value);
      if (this.showControllersAndProcessors) {
        to.dataControllers = this.getDataControllers(value);
        to.dataProcessors = this.getDataProcessorsAndLinkedCompanies(value);
      }
      if (this.includeRaciEmails) {
        to.responsibleEmails = this.getResponsible(value, 'email');
        to.accountableEmails = this.getAccountable(value, 'email');
        to.informedEmails = this.getInformed(value, 'email');
        to.consultedEmails = this.getConsulted(value, 'email');
      }
    },
    getResponsible: function getResponsible(value) {
      var propertyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'display_name';
      if (!value.responsibleUserIds) return [];
      var filtered = this.users.filter(function (el) {
        return value.responsibleUserIds.includes(el.id);
      });
      return filtered.map(function (el) {
        return el[propertyName];
      });
    },
    getAccountable: function getAccountable(value) {
      var propertyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'display_name';
      if (!value.accountableUserIds) return [];
      var filtered = this.users.filter(function (el) {
        return value.accountableUserIds.includes(el.id);
      });
      return filtered.map(function (el) {
        return el[propertyName];
      });
    },
    getConsulted: function getConsulted(value) {
      var propertyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'display_name';
      if (!value.consultedUserIds) return [];
      var filtered = this.users.filter(function (el) {
        return value.consultedUserIds.includes(el.id);
      });
      return filtered.map(function (el) {
        return el[propertyName];
      });
    },
    getInformed: function getInformed(value) {
      var propertyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'display_name';
      if (!value.informedUserIds) return [];
      var filtered = this.users.filter(function (el) {
        return value.informedUserIds.includes(el.id);
      });
      return filtered.map(function (el) {
        return el[propertyName];
      });
    },
    getOrgs: function getOrgs(value) {
      if (!value.organisationIds) return [];
      var filtered = this.organisations.filter(function (el) {
        return value.organisationIds.includes(el.id);
      });
      return filtered.map(function (el) {
        return el.visible_name;
      });
    },
    prepareCells: function prepareCells(nodeId, qId) {
      return mapQuestionAnswers(this.questions, this.informationAssets, this.answers[nodeId], this.files, this.processTreeNodes, qId, this.getCompanyLinkedToDataProcessor);
    },
    getDataControllers: function getDataControllers(value) {
      if (!value.links || !value.links[MODULES.COMPLIANCE_IA_CONTROLLER]) return [];
      return this.allDataController.filter(function (controller) {
        return value.links[MODULES.COMPLIANCE_IA_CONTROLLER].includes(controller.id);
      });
    },
    getDataProcessors: function getDataProcessors(value) {
      if (!value.links || !value.links[MODULES.COMPLIANCE_IA_PROCESSOR]) return [];
      return this.allDataProcessors.filter(function (processor) {
        return value.links[MODULES.COMPLIANCE_IA_PROCESSOR].includes(processor.id);
      });
    },
    getDataProcessorsAndLinkedCompanies: function getDataProcessorsAndLinkedCompanies(value) {
      var _this9 = this;
      if (!value.links || !value.links[MODULES.COMPLIANCE_IA_PROCESSOR]) return [[], []];
      var processors = [];
      value.links[MODULES.COMPLIANCE_IA_PROCESSOR].forEach(function (processorId) {
        var processor = _this9.allDataProcessors.find(function (pr) {
          return pr.id === processorId;
        });
        if (!processor) return;
        if (processor.companyId) {
          processor.companyInfo = _this9.getCompanyLinkedToDataProcessor(processor);
        }
        processors.push(processor);
      });
      return processors;
    },
    getCompanyLinkedToDataProcessor: function getCompanyLinkedToDataProcessor(processor) {
      var companyId = processor.companyId;
      if (!this.companiesMap.has(companyId)) {
        var company = this.allCompanies.find(function (company) {
          return company.id === companyId;
        });
        if (company) {
          var preparedCompany = this.prepareCompanyData(company, processor);
          this.companiesMap.set(companyId, preparedCompany);
        }
      }
      return this.companiesMap.get(companyId);
    },
    prepareCompanyData: function prepareCompanyData(company, processor) {
      company = getCompanyWithUrl(company);
      return {
        name: company.name,
        description: company.description,
        id: company.id,
        objType: company.objType,
        address: company.address,
        city: company.city,
        countryCode: company.countryCode,
        phone: company.phone,
        url: company.url,
        vatStatus: company.vatStatus,
        contactName: processor.contactName,
        email: processor.email
      };
    },
    handleClearTable: function handleClearTable() {
      this.showReport = false;
      this.questions = [];
      this.error = '';
    },
    refreshTable: function refreshTable() {
      this.handleCreateReport(this.reportArgs);
    },
    updateFromContactCards: function updateFromContactCards(value) {
      this.updatedContactCard = value;
    },
    dismissContractCardModal: function dismissContractCardModal() {
      var _this10 = this;
      var promise = Promise.resolve();
      if (this.updatedContactCard) {
        var _this$updatedContactC, _this$updatedContactC2;
        if (((_this$updatedContactC = this.updatedContactCard) === null || _this$updatedContactC === void 0 ? void 0 : _this$updatedContactC.objType) === ObjectTypes.COMPANY) {
          this.updateCompany(this.updatedContactCard);
        }
        if (((_this$updatedContactC2 = this.updatedContactCard) === null || _this$updatedContactC2 === void 0 ? void 0 : _this$updatedContactC2.objType) === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR) {
          promise = this.getProcessors();
        }
        promise.then(function () {
          _this10.refreshTable();
          _this10.updatedContactCard = null;
        });
      }
    },
    updateCompany: function updateCompany(company) {
      var companyOld = this.companiesMap.get(company.id);
      var processor = {
        contactName: companyOld.contactName,
        email: companyOld.email
      };
      var companyUpdated = this.prepareCompanyData(company, processor);
      this.companiesMap.set(company.id, companyUpdated);
    }
  })
};