import { getTranslate } from './translate';
import cloneDeep from 'lodash/cloneDeep';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import InputField from '@/components/Atoms/Inputs/InputField';
import MainWell from '@/components/Atoms/Containers/MainWell';
import MiniTree from '@/components/Molecules/MiniTree/MiniTree';
import Modal from '@/components/Molecules/Modal/Modal';
import { getComplianceMiniTreeEmits } from '@/utils/Compliance/TreeNodeEmits';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { MiniTreeMixin } from '@/mixins/MiniTreeMixin';
import { getFrameworks as _getFrameworks, updateFramework, deleteFramework as _deleteFramework, createFramework } from '@/api/admin/frameworks';
export default {
  name: 'Frameworks',
  components: {
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    InputField: InputField,
    MainWell: MainWell,
    Modal: Modal,
    MiniTree: MiniTree
  },
  mixins: [MiniTreeMixin],
  data: function data() {
    return {
      description: "Frameworks are essential for identifying which activities belongs to specific solutions. These ID's are essential for making sure that the KPI's are measured correct, and thereby give you and your organisation the precise performance indication for each solution as well as other reporting for cross-solution purposes.",
      isLoading: false,
      treeData: {},
      createErrorMessage: '',
      showModal: false,
      selectedFramework: null,
      selectedElementData: {},
      newFrameworkData: {},
      hasExpandedView: false,
      modalType: 'create',
      frameworkEmits: getComplianceMiniTreeEmits(ObjectTypes.FRAMEWORK),
      translate: getTranslate['Frameworks']()
    };
  },
  computed: {
    modalHeader: function modalHeader() {
      if (this.modalType === 'update') return this.translate.updateTitle;
      return this.newFrameworkData.parentId ? this.translate.createUnderlyingFramework : this.translate.createNewFramework;
    },
    modalOkText: function modalOkText() {
      return this.modalType === 'create' ? this.translate.create : this.translate.update;
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      this.isLoading = true;
      return this.getFrameworks().then(function () {
        _this.isLoading = false;
      });
    },
    getFrameworks: function getFrameworks() {
      var _this2 = this;
      return _getFrameworks().then(function (_ref) {
        var list = _ref.list;
        _this2.treeData = {
          children: cloneDeep(list)
        };
        _this2.filterFrameworkListDuplicates(list);
      });
    },
    filterFrameworkListDuplicates: function filterFrameworkListDuplicates(list) {
      var _this3 = this;
      list.forEach(function (item) {
        var _item$children;
        if (!(item !== null && item !== void 0 && (_item$children = item.children) !== null && _item$children !== void 0 && _item$children.length)) return;
        _this3.filterChildren(item.children);
      });
    },
    filterChildren: function filterChildren(list) {
      var _this4 = this;
      list.forEach(function (item) {
        _this4.treeData.children = _this4.treeData.children.filter(function (child) {
          return child.id !== item.id;
        });
        if (item.children.length) _this4.filterChildren(item.children);
      });
    },
    handleSuccessfullUpdate: function handleSuccessfullUpdate() {
      this.$notify({
        title: this.translate.frameworkWasSuccessfullyUpdated
      });
    },
    handleError: function handleError(err) {
      var _err$response;
      this.$notify({
        title: this.$trans(err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.error) || err,
        type: 'error'
      });
    },
    handleEnableUpdate: function handleEnableUpdate(framework) {
      var _this5 = this;
      this.isLoading = true;
      return this.updateFrameworkApi({
        id: framework.id,
        enabled: framework.enabled ? 0 : 1
      }).then(function () {
        return _this5.handleSuccessfullUpdate();
      }).catch(function (err) {
        return _this5.handleError(err);
      }).finally(function () {
        return _this5.isLoading = false;
      });
    },
    onFrameworkChange: function onFrameworkChange(value, framework) {
      var _this6 = this;
      if (framework.title !== value) {
        var updatedFramework = {
          id: framework.id,
          title: value
        };
        this.handleValidation(updatedFramework);
        if (this.createErrorMessage) return;
        this.isLoading = true;
        this.toggleShowModal();
        return this.updateFrameworkApi(updatedFramework).then(function () {
          return _this6.handleSuccessfullUpdate();
        }).catch(function (err) {
          return _this6.handleError(err);
        }).finally(function () {
          return _this6.isLoading = false;
        });
      }
    },
    updateFrameworkApi: function updateFrameworkApi(updatedFramework) {
      var _this7 = this;
      return updateFramework(updatedFramework).then(function (newFramework) {
        return _this7.updateFrameworkListByUpdatedElement(newFramework);
      });
    },
    updateFrameworkListByUpdatedElement: function updateFrameworkListByUpdatedElement(updatedFramework) {
      var parent = this.getBranchByParentId(updatedFramework.parentId);
      var elementIndex = parent.children.findIndex(function (item) {
        return item.id === updatedFramework.id;
      });
      parent.children[elementIndex] = updatedFramework;
    },
    updateFrameworkListByCreatedFramework: function updateFrameworkListByCreatedFramework(framework) {
      var parent = this.getBranchByParentId(framework.parentId);
      parent.children.push(framework);
    },
    toggleShowModal: function toggleShowModal() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'create';
      this.newFrameworkData = {
        parentId: (data === null || data === void 0 ? void 0 : data.id) || null,
        enabled: data.enabled || 0,
        title: type === 'update' ? data.title : ''
      };
      this.selectedElementData = data;
      this.modalType = type;
      this.createErrorMessage = '';
      this.showModal = !this.showModal;
    },
    handleDeleteNode: function handleDeleteNode(framework) {
      var _this8 = this;
      this.selectedFramework = framework;
      this.$confirm(this.translate.pleaseConfirm, this.translate.areYouSureYouWantToDelete, function (res) {
        return res && _this8.deleteFramework();
      }, {
        buttonOkText: this.translate.confirm,
        buttonDismissText: this.translate.cancel
      });
    },
    onAcceptModalClick: function onAcceptModalClick() {
      if (this.modalType === 'create') return this.onCreateNewFrameworkClick();
      this.onFrameworkChange(this.newFrameworkData.title, this.selectedElementData);
    },
    handleValidation: function handleValidation(newFramework) {
      this.createErrorMessage = '';
      if (!newFramework.title) {
        this.createErrorMessage = this.translate.titleCannotBeEmpty;
        return;
      }
      var parent = this.getBranchByParentId(newFramework.parentId);
      if (parent.children.find(function (item) {
        return item.title.toLowerCase() === newFramework.title.toLowerCase();
      })) {
        this.createErrorMessage = this.translate.titleExists;
        return;
      }
    },
    onCreateNewFrameworkClick: function onCreateNewFrameworkClick() {
      var _this9 = this;
      var newFramework = this.newFrameworkData;
      this.handleValidation(newFramework);
      if (this.createErrorMessage) return;
      this.toggleShowModal();
      return createFramework(newFramework).then(function (framework) {
        _this9.updateFrameworkListByCreatedFramework(framework);
        _this9.$notify({
          title: _this9.translate.frameworkWasSuccessfullyCreated
        });
      });
    },
    deleteFramework: function deleteFramework() {
      var _this10 = this;
      return _deleteFramework(this.selectedFramework.id).then(function () {
        _this10.updateFrameworkListByDeletedFramework(_this10.selectedFramework);
        _this10.selectedFramework = null;
        _this10.$notify({
          title: _this10.translate.frameworkWasSuccessfullyDeleted
        });
      }).catch(function (err) {
        return _this10.handleError(err);
      });
    },
    updateFrameworkListByDeletedFramework: function updateFrameworkListByDeletedFramework(framework) {
      var parent = this.getBranchByParentId(framework.parentId);
      parent.children = parent.children.filter(function (item) {
        return item.id !== framework.id;
      });
    }
  }
};