function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from '@/components/Organisms/Compliance/Admin/translate';
import { createSecurityGroup as createSecurityGroupAPI } from '@/api/compliance/admin';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
import { adminLevels, accessLevels, accessLevelsBase, accessLevelsCompanies } from '@/constants/compliance/SecurityGroups';
export default {
  name: 'SecurityGroupsCreationModal',
  components: {
    SingleSelect: SingleSelect,
    Notification: Notification,
    InputField: InputField,
    Checkboxes: Checkboxes,
    Modal: Modal
  },
  emits: ['create', 'dismiss'],
  data: function data() {
    return {
      adminLevels: adminLevels(),
      accessLevels: accessLevels,
      accessLevelsBase: accessLevelsBase,
      accessLevelsCompanies: accessLevelsCompanies,
      errors: {
        objectId: '',
        name: ''
      },
      group: {
        name: '',
        objectId: '',
        levelAdmin: 0,
        levelControls: 0,
        levelExecution: 0,
        levelIncidents: 0,
        levelRisk: 0,
        levelContracts: 0,
        levelCompany: 0,
        levelInformationAssets: 0,
        levelProcessLibrary: 0,
        lockedFields: false
      },
      errorMessage: '',
      translate: getTranslate['SecurityGroupsCreationModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    informationAssetsName: 'informationAssetsName',
    processLibraryName: 'processLibraryName'
  })), {}, {
    groupedFieldOptions: function groupedFieldOptions() {
      return [{
        checked: !!this.group.lockedFields,
        label: this.translate.lockedFields
      }];
    }
  }),
  methods: {
    resetNewGroup: function resetNewGroup() {
      this.group = {
        name: '',
        objectId: '',
        levelAdmin: 0,
        levelControls: 0,
        levelExecution: 0,
        levelIncidents: 0,
        levelRisk: 0,
        levelContracts: 0,
        levelCompany: 0,
        levelInformationAssets: 0,
        levelProcessLibrary: 0,
        lockedFields: false
      };
    },
    handleCreate: function handleCreate() {
      var _this = this;
      var newGroup = _objectSpread(_objectSpread({}, this.group), {}, {
        lockedFields: this.group.lockedFields ? 1 : 0
      });
      return createSecurityGroupAPI(newGroup).then(function () {
        return _this.$emit('create');
      }).catch(function (error) {
        return _this.errorMessage = error;
      });
    },
    validate: function validate() {
      this.errors = {
        name: this.group.name ? '' : this.translate.pleaseFillOutThisField,
        objectId: this.group.objectId ? '' : this.translate.pleaseFillOutThisField
      };
    },
    create: function create() {
      this.errorMessage = '';
      this.validate();
      if (Object.values(this.errors).every(function (error) {
        return !error;
      })) {
        this.handleCreate();
      }
    }
  }
};