import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  class: "min-h-180px relative"
};
var _hoisted_2 = {
  key: 1,
  class: "text-center absolute top-1/2 inset-x-0 -translate-y-1/2"
};
var _hoisted_3 = {
  class: "flex flex-col mb-6"
};
var _hoisted_4 = {
  class: "flex items-center"
};
var _hoisted_5 = {
  key: 2,
  class: "text-center"
};
var _hoisted_6 = {
  class: "flex justify-center"
};
var _hoisted_7 = {
  class: "underline cursor-pointer mr-1"
};
var _hoisted_8 = {
  key: 0,
  class: "text-center absolute top-1/2 inset-x-0 -translate-y-1/2"
};
var _hoisted_9 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_contract_party_generic_table = _resolveComponent("contract-party-generic-table");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_no_search_results = _resolveComponent("no-search-results");
  var _component_company_wizard_wrapper = _resolveComponent("company-wizard-wrapper");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $options.modalHeader,
    "show-dismiss-button": false,
    "show-buttons": !!Object.values($data.dataset).some(function (list) {
      return list.length;
    }),
    "button-ok-text": $data.translate.add,
    type: "big",
    onDismiss: _cache[10] || (_cache[10] = function ($event) {
      return _ctx.$emit('dismiss');
    }),
    onAccept: $options.prepareDataForAccept
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [$data.errorText ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $data.errorText = '';
        })
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", null, _toDisplayString($data.errorText), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), $options.isCopyMode ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [$data.dataset.all.length ? (_openBlock(), _createBlock(_component_contract_party_generic_table, {
        key: 0,
        "dataset-table": $data.dataset.all,
        columns: $options.columns,
        class: "mt-4",
        "onUpdate:checkbox": _cache[1] || (_cache[1] = function ($event) {
          return $options.updateCheckbox('all', $event);
        })
      }, null, 8 /* PROPS */, ["dataset-table", "columns"])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("p", null, _toDisplayString($options.noSubcompaniesMessage), 1 /* TEXT */)]))], 64 /* STABLE_FRAGMENT */)) : !$options.isBranchSubLogic ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 2
      }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_input_field, {
        modelValue: $data.searchValue,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.searchValue = $event;
        }),
        modelModifiers: {
          trim: true
        },
        placeholder: $data.translate.companyName,
        "focus-on-mount": true,
        type: "text",
        class: "flex-1 mr-1",
        onOnEnter: $options.handleSearch
      }, null, 8 /* PROPS */, ["modelValue", "placeholder", "onOnEnter"]), _createVNode(_component_risma_button, {
        text: $data.translate.search,
        type: "save",
        onClick: $options.handleSearch
      }, null, 8 /* PROPS */, ["text", "onClick"])])]), $data.dataset.all.length ? (_openBlock(), _createBlock(_component_contract_party_generic_table, {
        key: 0,
        "dataset-table": $data.dataset.all,
        columns: $options.columns,
        class: "mt-4",
        "onUpdate:checkbox": _cache[3] || (_cache[3] = function ($event) {
          return $options.updateCheckbox('all', $event);
        }),
        "onUpdate:radioBtn": _cache[4] || (_cache[4] = function ($event) {
          return $options.updatePayingParty('all', $event);
        })
      }, null, 8 /* PROPS */, ["dataset-table", "columns"])) : !$data.isSearchProceeded ? (_openBlock(), _createBlock(_component_no_search_results, {
        key: 1
      })) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", null, _toDisplayString($data.translate.noResultsFound), 1 /* TEXT */), _createElementVNode("div", _hoisted_6, [_createVNode(_component_company_wizard_wrapper, {
        onRefresh: _cache[5] || (_cache[5] = function ($event) {
          return _ctx.$emit('refresh:companies');
        })
      }, {
        button: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_7, _toDisplayString($data.translate.clickHere), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createTextVNode(" " + _toDisplayString($data.translate.ifYouWish), 1 /* TEXT */)])]))], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 3
      }, [$options.noResultsMessage ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("p", null, _toDisplayString($options.noResultsMessage), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("div", _hoisted_9, [$data.dataset.branch.length ? (_openBlock(), _createBlock(_component_contract_party_generic_table, {
        key: 0,
        class: "mb-8",
        title: "".concat($props.parentCompany.name, " ").concat($data.translate.branches),
        "dataset-table": $data.dataset.branch,
        columns: $options.columns,
        "onUpdate:checkbox": _cache[6] || (_cache[6] = function ($event) {
          return $options.updateCheckbox('branch', $event);
        }),
        "onUpdate:radioBtn": _cache[7] || (_cache[7] = function ($event) {
          return $options.updatePayingParty('branch', $event);
        })
      }, null, 8 /* PROPS */, ["title", "dataset-table", "columns"])) : _createCommentVNode("v-if", true), $data.dataset.sub.length ? (_openBlock(), _createBlock(_component_contract_party_generic_table, {
        key: 1,
        title: "".concat($props.parentCompany.name, " ").concat($data.translate.subsidiaries),
        "dataset-table": $data.dataset.sub,
        columns: $options.columns,
        "onUpdate:checkbox": _cache[8] || (_cache[8] = function ($event) {
          return $options.updateCheckbox('sub', $event);
        }),
        "onUpdate:radioBtn": _cache[9] || (_cache[9] = function ($event) {
          return $options.updatePayingParty('sub', $event);
        })
      }, null, 8 /* PROPS */, ["title", "dataset-table", "columns"])) : _createCommentVNode("v-if", true)]))], 64 /* STABLE_FRAGMENT */))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "show-buttons", "button-ok-text", "onAccept"]);
}