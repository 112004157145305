function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUserStore } from '@/Store/userStore';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
import { UserLevels } from '@/constants/UserLevels';
export default {
  name: 'CreateInitiativeModal',
  components: {
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    InputField: InputField,
    Modal: Modal,
    TextBox: TextBox,
    FrameworksSelector: FrameworksSelector,
    ActivityTypeSelector: ActivityTypeSelector
  },
  introduction: 'Modal for creating initiatives',
  description: "This modal helps to speed up the process of creating an initiative. when the form is filled out and the create button is pressed\n                      the modal will emit and create event, with the data needed to create an initiative. when closed it also emits a dismiss event, for prop handling",
  token: "<create-initiative-modal :initiatives=\"[]\" :preset=\"{title: 'title', description: 'description'}\"/>",
  props: {
    initiatives: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of initiatives'
    },
    preset: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      },
      note: 'Preset fields from activity'
    }
  },
  emits: ['accept', 'dismiss'],
  data: function data() {
    return {
      errors: {},
      initiativeItem: {
        title: '',
        parent: '',
        activityTypeId: null,
        responsible: [],
        description: '',
        target: '',
        selectedFrameworks: []
      },
      isRismaSelectOpen: false,
      translate: getTranslate['CreateInitiativeModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'usersWithActionsAccess'
  })), mapState(useFrameworksStore, ['enabledFrameworks'])), mapState(useActivityTypesStore, {
    activityTypes: 'executionActivityTypes'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    formattedInitiative: function formattedInitiative() {
      var ownerProperty = this.currentUser.level_execution <= UserLevels.PRIVILEGED ? {
        owner: [this.currentUser.id]
      } : {};
      return _objectSpread({
        title: this.initiativeItem.title || this.preset.title,
        userIds: {
          responsible: this.initiativeItem.responsible.map(function (user) {
            return user.id;
          })
        },
        parent: this.initiativeItem.parent || -1,
        description: this.initiativeItem.description,
        target: this.initiativeItem.target,
        activityType: this.initiativeItem.activityTypeId,
        solutionIds: this.initiativeItem.selectedFrameworks.map(function (item) {
          return item.id;
        })
      }, ownerProperty);
    },
    initiativesOptions: function initiativesOptions() {
      return this.initiatives.filter(function (initiative) {
        return initiative.currentUserRights.canEdit;
      }).map(function (initiative) {
        return {
          label: "".concat(initiative.ipath, " ").concat(initiative.title),
          value: initiative.id
        };
      });
    }
  }),
  mounted: function mounted() {
    var _this$initiatives$,
      _this$preset$solution,
      _this = this;
    this.initiativeItem.title = this.preset.title || '';
    this.initiativeItem.parent = this.preset.parentId || ((_this$initiatives$ = this.initiatives[0]) === null || _this$initiatives$ === void 0 ? void 0 : _this$initiatives$.id) || '';
    this.initiativeItem.activityTypeId = this.getSettingValue('feature.actions_default_activity_type_id');
    this.initiativeItem.description = this.preset.description || '';
    this.initiativeItem.selectedFrameworks = (_this$preset$solution = this.preset.solutionIds) !== null && _this$preset$solution !== void 0 && _this$preset$solution.length ? this.enabledFrameworks.filter(function (fk) {
      return _this.preset.solutionIds.includes(fk.id);
    }) : [];
  },
  methods: {
    handleSelection: function handleSelection() {
      this.errors = {};
      if (!this.initiativeItem.title && !this.preset.title) {
        this.errors.title = this.translate.youNeedToFillTitleToContinue;
      }
      if (this.initiativeItem.responsible.length === 0) {
        this.errors.responsible = this.translate.youNeedToFillResponsibleToContinue;
      }
      if (!this.initiativeItem.parent) {
        this.errors.parent = this.translate.pleaseCreateAParentInitiativeInActionsAndT;
      }
      if (Object.keys(this.errors).length > 0) {
        return;
      }
      this.$emit('accept', this.formattedInitiative);
    }
  }
};