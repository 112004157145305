function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import i18n from '@/i18n';
export var QUICK_QUESTIONS = {
  default: [{
    label: 'Simplify',
    actualMessage: "Simplify this text so it's easy to understand:",
    translatedMessages: ["Simplify this text so it's easy to understand:", 'Gør denne tekst nemmere at forstå:', 'Förenkla denna text så att den blir lätt att förstå:', 'Forenkle denne teksten så den blir lett å forstå:']
  }, {
    label: 'Explain',
    actualMessage: 'Explain this text in its compliance context in a simple and concise way:',
    translatedMessages: ['Explain this text in its compliance context in a simple and concise way:', 'Forklar denne tekst i compliance-sammenhæng på en enkel og kortfattet måde:', 'Förklara denna text i dess efterlevnadssammanhang på ett enkelt och koncist sätt:', 'Forklar denne teksten i sin samsvarskontekst på en enkel og kortfattet måte:']
  }],
  additional: [{
    label: 'How do I decide if a topic is material?'
  }, {
    label: 'When should I do a DPIA?'
  }, {
    label: 'How do I analyse the vulnerabilities in my organisation?'
  }]
};
export var SENDER_ROLES = {
  user: 'user',
  system: 'system',
  custom: 'custom'
};
export var getGreetingMessage = function getGreetingMessage() {
  var quickQuestions = QUICK_QUESTIONS.additional.map(function (item) {
    return {
      label: i18n.t(item.label),
      selected: false
    };
  });
  return {
    sender: SENDER_ROLES.custom,
    message: "".concat(i18n.t('Hi there!'), "\n        ").concat(i18n.t("I specialize in compliance topics such as CSRD, GDPR, DORA, and ISMS, including ISO27001, CIS18 and NIS2. I also know how to use RISMA, so feel free to ask — though I'm still learning that part!")),
    quickQuestions: quickQuestions
  };
};
export var getMessageWithCustomContent = function getMessageWithCustomContent(_ref) {
  var context = _ref.context,
    source = _ref.source;
  var withTranslatedMessages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var quickQuestions = QUICK_QUESTIONS.default.map(function (item) {
    return _objectSpread(_objectSpread({
      label: i18n.t(item.label),
      actualMessage: i18n.t(item.actualMessage)
    }, withTranslatedMessages && {
      translatedMessages: item.translatedMessages
    }), {}, {
      selected: false
    });
  });
  return {
    sender: SENDER_ROLES.custom,
    message: i18n.t('You have several options for working with this text, such as simplifying it, requesting an explanation, or asking your own question. How would you prefer to proceed?'),
    quickQuestions: quickQuestions,
    context: context,
    source: source
  };
};
export var CONFIRMATION_TYPES = {
  AI_TERMS: 'aiTerms',
  RESET: 'reset',
  CLEAR: 'clear',
  REGION: 'region'
};
export var addCustomMessagesToHistory = function addCustomMessagesToHistory(chatHistory) {
  var customMessages = [];
  var _loop = function _loop(_i) {
      var _currentCustomMessage, _quickQuestions;
      var message = chatHistory[_i];
      if (!message.source || message.sender !== SENDER_ROLES.user) {
        i = _i;
        return 0;
      } // continue
      var currentCustomMessage = customMessages[customMessages.length - 1] || {};
      if (((_currentCustomMessage = currentCustomMessage) === null || _currentCustomMessage === void 0 ? void 0 : _currentCustomMessage.source) !== message.source) {
        customMessages.push(getMessageWithCustomContent(message, true));
        currentCustomMessage = customMessages[customMessages.length - 1];
      }
      var _ref2 = currentCustomMessage || [],
        quickQuestions = _ref2.quickQuestions;
      var quickQuestionIndex = (_quickQuestions = quickQuestions) === null || _quickQuestions === void 0 ? void 0 : _quickQuestions.findIndex(function (question) {
        return question.translatedMessages.includes(message.message);
      });
      if (quickQuestionIndex === -1) {
        i = _i;
        return 0;
      } // continue
      var quickQuestion = quickQuestions[quickQuestionIndex];
      if (quickQuestion.selected) {
        var _currentCustomMessage2;
        customMessages.push(getMessageWithCustomContent(message, true));
        currentCustomMessage = customMessages[customMessages.length - 1];
        quickQuestions = (_currentCustomMessage2 = currentCustomMessage) === null || _currentCustomMessage2 === void 0 ? void 0 : _currentCustomMessage2.quickQuestions;
        quickQuestion = quickQuestions[quickQuestionIndex];
      }
      quickQuestion.selected = true;
      message.message = quickQuestion.label;
      var selectedQuestionsLength = quickQuestions.filter(function (q) {
        return q.selected;
      }).length;
      if (selectedQuestionsLength === 1) {
        chatHistory.splice(_i, 0, currentCustomMessage);
        _i++;
      }
      i = _i;
    },
    _ret;
  for (var i = 0; i < chatHistory.length; i++) {
    _ret = _loop(i);
    if (_ret === 0) continue;
  }
  return chatHistory;
};

// REGIONS
export var AI_REGIONS = [{
  name: 'Denmark',
  languageName: 'Danish',
  value: 'DK'
}, {
  name: 'Norway',
  languageName: 'Norwegian',
  value: 'NO'
}, {
  name: 'Sweden',
  languageName: 'Swedish',
  value: 'SE'
}];
export var getRegionsOptions = function getRegionsOptions() {
  return AI_REGIONS.map(function (_ref3) {
    var name = _ref3.name,
      value = _ref3.value;
    return {
      name: i18n.t(name),
      value: value
    };
  });
};
export var getChangeRegionMessage = function getChangeRegionMessage(regionValue) {
  var activeRegion = AI_REGIONS.find(function (item) {
    return item.value === regionValue;
  });
  return i18n.t("Your region has been set to ".concat(activeRegion.name, ", and all directives will refer to ").concat(activeRegion.languageName, " law. If you wish to change these settings, you can do so in the menu of the chat box. Enjoy using RISMA AI Partner!"));
};