import { i18n } from '@/i18n';
export var getContractTypes = function getContractTypes() {
  return {
    NOT_SET: {
      value: 1,
      label: i18n.t('Not set')
    },
    STANDALONE: {
      value: 2,
      label: i18n.t('Standalone')
    },
    OVERARCHING: {
      value: 3,
      label: i18n.t('Overarching')
    },
    SUBSEQUENT_OR_ASSOCIATED: {
      value: 4,
      label: i18n.t('Subsequent or associated')
    }
  };
};
export var getContractTypesByValue = function getContractTypesByValue() {
  return Object.fromEntries(Object.values(getContractTypes()).map(function (_ref) {
    var value = _ref.value,
      label = _ref.label;
    return [value, label];
  }));
};