function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import emitter from 'tiny-emitter/instance';
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useWorkflowStore } from '@/Store/workflowStore';
import * as Utils from '@/utils/Utils';
import { removeDuplicates } from '@/utils/filterArray';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import Notification from '@/components/Molecules/Notification';
import PaginatedTable from '@/components/Organisms/PaginatedTable';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import StaticTable from '@/components/Molecules/StaticTable';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import ViewSwitcher from '@/components/Molecules/ViewSwitcher';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { getAll as _getIncidents, remove as removeIncident } from '@/api/incident/incident';
import { Colors } from '@/Colors';
import { INCIDENTS_VIEWS } from '@/constants/ViewTypes';
import Modal from '../../Molecules/Modal/Modal';
import IncidentDescription from './IncidentDescription';
import { create } from '../../../api/incident/incident';
import { getMissingRequiredFieldsLabels } from '@/utils/CustomFields';
import { toLocalDateTime, isDateAfter } from '@/utils/date';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { isFieldInOptionalFields } from '@/utils/Utils';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { MODULES } from '@/constants/modules';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
var VIEWS = {
  TILES: 'tiles',
  COMPACT: 'compact'
};
export default {
  name: 'IncidentList',
  components: {
    PreviewModalLink: PreviewModalLink,
    IncidentDescription: IncidentDescription,
    Modal: Modal,
    FeatherIcon: FeatherIcon,
    InputField: InputField,
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    Notification: Notification,
    PaginatedTable: PaginatedTable,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    StaticTable: StaticTable,
    RismaSelect: RismaSelect,
    ViewSwitcher: ViewSwitcher,
    TrafficLight: TrafficLight,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  description: 'Incidents overview page',
  token: '<incident-list />',
  setup: function setup() {
    return {
      selectedStatus: getLocalStorageComputed('incidents_filtered_status', [], true),
      incidentsVisibleTypes: getLocalStorageComputed('incidents_visible_types_ids', [], true),
      currentView: getLocalStorageComputed('incidents_display_view', INCIDENTS_VIEWS[0].name)
    };
  },
  data: function data() {
    return {
      isRismaSelectOpen: false,
      pageLoaded: false,
      isCreateAttempted: false,
      incidents: null,
      updatedIncident: null,
      activityTypes: null,
      filterString: '',
      errors: [],
      createErrors: [],
      titleError: '',
      systemColors: Colors.system,
      INCIDENTS_VIEWS: INCIDENTS_VIEWS,
      VIEWS: VIEWS,
      deletedIncidentId: null,
      newIncident: null,
      hoverTitles: {
        name: function name(value) {
          return value.title;
        }
      },
      translate: getTranslate['IncidentList'](),
      creatingIncident: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, ['incidentActivityTypes', 'getActivityTypeById'])), mapState(useWorkflowStore, ['getWorkflow', 'getAllStatuses', 'getActivityStatusTitle'])), {}, {
    allWsFlat: function allWsFlat() {
      return this.getAllStatuses(MODULES.INCIDENTS);
    },
    wsOptions: function wsOptions() {
      return removeDuplicates(this.allWsFlat, 'label');
    },
    wsMapByTitle: function wsMapByTitle() {
      var result = {};
      this.allWsFlat.forEach(function (_ref) {
        var label = _ref.label,
          id = _ref.id;
        if (result[label]) {
          result[label].push(id);
          return;
        }
        result[label] = [id];
      });
      return result;
    },
    selectedWorkflowIdsToShow: function selectedWorkflowIdsToShow() {
      var _this$selectedStatus,
        _this = this;
      if (!((_this$selectedStatus = this.selectedStatus) !== null && _this$selectedStatus !== void 0 && _this$selectedStatus.length)) return null;
      var result = [];
      this.selectedStatus.forEach(function (_ref2) {
        var title = _ref2.title;
        if (!_this.wsMapByTitle[title]) return;
        result.push(_this.wsMapByTitle[title]);
      });
      return result.flat();
    },
    hasActivityTypes: function hasActivityTypes() {
      return this.activityTypes && this.activityTypes.length > 0;
    },
    columnsMaxWidth: function columnsMaxWidth() {
      if (this.currentView === VIEWS.TILES) {
        return {
          name: '200px'
        };
      }
      return {
        name: '450px',
        severity: '100px',
        id: '100px'
      };
    },
    columnsHashMap: function columnsHashMap() {
      var _this2 = this;
      var hashMap = new Map();
      this.activityTypes.forEach(function (activityType) {
        hashMap.set(activityType.id, _this2.prepareColumnsForActivityType(activityType));
      });
      return hashMap;
    },
    incidentsWithoutResponsible: function incidentsWithoutResponsible() {
      var _this3 = this;
      var activities = this.incidents.filter(function (_ref3) {
        var id = _ref3.id,
          responsibleUserIds = _ref3.responsibleUserIds;
        return !_this3.hiddenIncidentIds[id] && !(responsibleUserIds !== null && responsibleUserIds !== void 0 && responsibleUserIds.length);
      }).map(function (incident) {
        var _this3$getActivityTyp;
        return {
          severity: incident.severity !== null && incident.severity !== undefined ? DatatableRenderer.prepareTrafficLightField(incident.severity, MODULES.INCIDENTS) : null,
          name: incident,
          status: _this3.getActivityStatusTitle(incident, MODULES.INCIDENTS),
          created: toLocalDateTime(incident.createdAt),
          activityType: (_this3$getActivityTyp = _this3.getActivityTypeById(incident.activityTypeId)) === null || _this3$getActivityTyp === void 0 ? void 0 : _this3$getActivityTyp.label,
          actions: {
            id: incident.id
          }
        };
      });
      return {
        columns: [this.translate.severity, this.translate.title, this.translate.status, this.translate.created, this.translate.activityType, ''],
        activityType: {
          label: this.translate.incidentsWithoutAResponsible,
          severityIncluded: true
        },
        activities: activities
      };
    },
    groupedIncidents: function groupedIncidents() {
      var _this4 = this;
      var groups = {};
      if (this.getSettingValue('feature.incidents.no_responsible')) {
        groups[0] = this.incidentsWithoutResponsible;
      }
      this.activityTypes.forEach(function (activityType) {
        var activities = _this4.incidentsByActivityType[activityType.id] || [];
        activities = activities.filter(function (_ref4) {
          var id = _ref4.id;
          return !_this4.hiddenIncidentIds[id];
        }).map(_this4.prepareTableRow);
        groups[activityType.id] = {
          columns: _this4.columnsHashMap.get(activityType.id),
          activityType: activityType,
          activities: activities
        };
      });
      return groups;
    },
    incidentsByActivityType: function incidentsByActivityType() {
      var _this5 = this;
      var groups = {};
      this.incidents.forEach(function (incident) {
        var _incident$responsible;
        if (_this5.getSettingValue('feature.incidents.no_responsible') && !((_incident$responsible = incident.responsibleUserIds) !== null && _incident$responsible !== void 0 && _incident$responsible.length)) {
          return;
        }
        var activityType = _this5.getActivityTypeById(incident.activityTypeId);
        if (!activityType) return;
        if (!groups[activityType.id]) {
          groups[activityType.id] = [];
        }
        groups[activityType.id].push(incident);
      });
      return groups;
    },
    hiddenIncidentIds: function hiddenIncidentIds() {
      var _this6 = this;
      var hidedIds = {};
      this.incidents.forEach(function (incident) {
        var _this6$selectedWorkfl;
        var isSearchedAndFound = _this6.searchFound(incident, _this6.filterString);
        var byStatus = !_this6.selectedStatus.length || ((_this6$selectedWorkfl = _this6.selectedWorkflowIdsToShow) === null || _this6$selectedWorkfl === void 0 ? void 0 : _this6$selectedWorkfl.includes(incident.workflowStatusId));
        if (!isSearchedAndFound || !byStatus) {
          hidedIds[incident.id] = true;
        }
      });
      return hidedIds;
    },
    isUserLocked: function isUserLocked() {
      return this.currentUser.level_incidents_locked === 1;
    },
    currentTable: function currentTable() {
      return this.currentView === VIEWS.TILES ? 'paginated-table' : 'static-table';
    },
    showIdFeatureEnabled: function showIdFeatureEnabled() {
      return this.getSettingValue('feature.show_incident_id');
    },
    missingRequiredFields: function missingRequiredFields() {
      var _this$newIncident, _this$newIncident2;
      var currentActivityType = this.getActivityTypeById((_this$newIncident = this.newIncident) === null || _this$newIncident === void 0 ? void 0 : _this$newIncident.activityTypeId);
      return getMissingRequiredFieldsLabels(currentActivityType, (_this$newIncident2 = this.newIncident) === null || _this$newIncident2 === void 0 ? void 0 : _this$newIncident2.customFieldValuesParsed);
    },
    newIncidentResponsibleUsers: function newIncidentResponsibleUsers() {
      var _this$activityTypes,
        _this7 = this;
      var activityTypeResponsibleUsers = (_this$activityTypes = this.activityTypes) === null || _this$activityTypes === void 0 || (_this$activityTypes = _this$activityTypes.find(function (_ref5) {
        var _this7$newIncident;
        var id = _ref5.id;
        return +id === +((_this7$newIncident = _this7.newIncident) === null || _this7$newIncident === void 0 ? void 0 : _this7$newIncident.activityTypeId);
      })) === null || _this$activityTypes === void 0 ? void 0 : _this$activityTypes.responsibleUsers;
      return activityTypeResponsibleUsers;
    },
    responsibleNotification: function responsibleNotification() {
      var names = Utils.findPropsByIds(this.newIncidentResponsibleUsers, this.users, 'display_name');
      return this.translate.byCreatingThisIncident(names);
    }
  }),
  created: function created() {
    var _this8 = this;
    this.activityTypes = this.enhanceActivityTypes(this.incidentActivityTypes);
    this.getIncidents().then(function () {
      return _this8.pageLoaded = true;
    });
    emitter.on('getIncidents', this.getIncidents);
  },
  unmounted: function unmounted() {
    emitter.off('getIncidents', this.getIncidents);
  },
  methods: {
    getIncidents: function getIncidents() {
      var _this9 = this;
      return _getIncidents().then(function (response) {
        _this9.incidents = response.sort(function (a, b) {
          return isDateAfter(a.createdAt, b.createdAt) ? -1 : 1;
        }).map(function (incident) {
          incident.hoverTitle = DatatableRenderer.getItemHoverTitle(incident);
          return incident;
        });
      });
    },
    dismissPreviewMode: function dismissPreviewMode() {
      var _this$updatedIncident,
        _this10 = this;
      if ((_this$updatedIncident = this.updatedIncident) !== null && _this$updatedIncident !== void 0 && _this$updatedIncident.archived) {
        this.incidents = this.incidents.filter(function (incident) {
          return incident.id !== _this10.updatedIncident.id;
        });
      }
      this.updatedIncident = null;
    },
    prepareTableRow: function prepareTableRow(incident) {
      var data = {};
      if (incident.severity !== null) {
        data.severity = DatatableRenderer.prepareTrafficLightField(incident.severity, MODULES.INCIDENTS);
      }
      if (this.showIdFeatureEnabled) {
        data.id = incident.id;
      }
      data = _objectSpread(_objectSpread({}, data), {}, {
        name: incident,
        status: this.getActivityStatusTitle(incident, MODULES.INCIDENTS),
        created: toLocalDateTime(incident.createdAt)
      });
      if (this.currentView !== VIEWS.TILES) {
        data.responsible = Utils.findPropsByIds(incident.responsibleUserIds, this.users, 'display_name');
      }
      if (incident.currentUserRights.canDelete) {
        data.actions = {
          id: incident.id
        };
      }
      return data;
    },
    prepareColumnsForActivityType: function prepareColumnsForActivityType(activityType) {
      var columns = [];
      if (activityType.severityIncluded) {
        columns.push(this.translate.severity);
      }
      if (this.showIdFeatureEnabled) {
        columns.push(this.translate.id);
      }
      columns = columns.concat([this.translate.title, this.translate.status, this.translate.created]);
      if (this.currentView === VIEWS.COMPACT) {
        columns.push(this.translate.responsible);
      }
      return [].concat(_toConsumableArray(columns), ['']);
    },
    enhanceActivityTypes: function enhanceActivityTypes(list) {
      return list.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          severityIncluded: isFieldInOptionalFields(item, 'severity')
        });
      });
    },
    toggleVisibility: function toggleVisibility(activityTypeID) {
      if (this.incidentsVisibleTypes.includes(+activityTypeID)) {
        this.incidentsVisibleTypes = this.incidentsVisibleTypes.filter(function (id) {
          return +id !== +activityTypeID;
        });
        return;
      }
      this.incidentsVisibleTypes = [].concat(_toConsumableArray(this.incidentsVisibleTypes), [+activityTypeID]);
    },
    showCurrentTable: function showCurrentTable(id) {
      if (this.currentView === VIEWS.TILES) return true;
      return this.incidentsVisibleTypes.includes(+id);
    },
    setStatusFilter: function setStatusFilter(e) {
      this.selectedStatus = e;
    },
    deleteIncident: function deleteIncident() {
      var _this11 = this;
      removeIncident(this.deletedIncidentId).then(function () {
        _this11.incidents = _this11.incidents.filter(function (incident) {
          return incident.id !== _this11.deletedIncidentId;
        });
        _this11.deletedIncidentId = null;
      });
    },
    onDeleteIncident: function onDeleteIncident(incidentId) {
      var _this12 = this;
      this.deletedIncidentId = incidentId;
      this.$confirm(this.translate.deleteIncident, this.translate.areYouSureYouWantToDelete, function (res) {
        return res && _this12.deleteIncident();
      });
    },
    showCreateNewIncidentModal: function showCreateNewIncidentModal(activityTypeId) {
      var _this$getWorkflow;
      this.createErrors = [];
      var statuses = (_this$getWorkflow = this.getWorkflow(MODULES.INCIDENTS, activityTypeId)) === null || _this$getWorkflow === void 0 ? void 0 : _this$getWorkflow.workflowStatuses;
      var startWorkflowStatus = statuses.find(function (workflowStatus) {
        return workflowStatus.isStartStatus;
      }) || statuses[0];
      this.newIncident = {
        activityTypeId: activityTypeId,
        title: '',
        description: '',
        customFieldValues: [],
        severity: 0,
        workflowStatusId: startWorkflowStatus === null || startWorkflowStatus === void 0 ? void 0 : startWorkflowStatus.id
      };
    },
    handleValidation: function handleValidation(data) {
      this.createErrors = [];
      this.titleError = '';
      if (!data.title || !data.title.trim()) {
        this.titleError = this.translate.titleCannotBeEmpty;
      }
      return !!this.titleError;
    },
    dismissNewIncident: function dismissNewIncident() {
      this.isCreateAttempted = false;
      this.newIncident = null;
    },
    createIncident: function createIncident() {
      var _this$newIncidentResp,
        _this13 = this;
      this.isCreateAttempted = true;
      if (this.missingRequiredFields.length) return;
      if (this.creatingIncident) return;
      this.creatingIncident = true;
      var responsibleUserIds = (_this$newIncidentResp = this.newIncidentResponsibleUsers) !== null && _this$newIncidentResp !== void 0 && _this$newIncidentResp.length ? this.newIncidentResponsibleUsers : this.getSettingValue('feature.incidents.no_responsible') ? [] : [this.currentUser.id];
      var data = _objectSpread(_objectSpread({}, this.newIncident), {}, {
        customFieldValues: this.newIncident.customFieldValuesParsed,
        responsibleUserIds: responsibleUserIds
      });
      if (!data.customFieldValues) {
        delete data.customFieldValues;
      }
      if (this.newIncident.solutions) {
        data.solutionIds = this.newIncident.solutions.map(function (item) {
          return item.id;
        });
        delete data.solutions;
      }
      if (this.handleValidation(data)) {
        this.creatingIncident = false;
        return;
      }
      return create(data).then(function (newIncident) {
        _this13.$notify({
          title: _this13.translate.yourActivityHasBeenCreated,
          activity: getFieldsFromObjectForPreview(newIncident),
          useCreateTimeout: true
        });
        _this13.newIncident = null;
        _this13.creatingIncident = false;
        return _this13.getIncidents();
      }).catch(function (_ref6) {
        var response = _ref6.response;
        var errorMessage = _this13.translate.errorCreatingIncident;
        if (response.error) {
          errorMessage += ': ' + _this13.$trans(response.error);
        }
        _this13.createErrors.push(errorMessage);
      }).finally(function () {
        return _this13.creatingIncident = false;
      });
    },
    searchFound: function searchFound(incident, searchString) {
      var searchFields = ['title'];
      var searchStringLowerCase = searchString.toLowerCase();
      if (this.showIdFeatureEnabled) {
        searchFields.push('id');
      }
      return searchFields.some(function (field) {
        var fieldValue = incident[field] + '';
        return fieldValue.toLowerCase().includes(searchStringLowerCase);
      });
    },
    canUserCreateIncident: function canUserCreateIncident(activityTypeId) {
      var _this$currentUser$Acc;
      return (_this$currentUser$Acc = this.currentUser.AccessLevels[MODULES.INCIDENTS][activityTypeId]) === null || _this$currentUser$Acc === void 0 ? void 0 : _this$currentUser$Acc.canCreate;
    }
  }
};