function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getTranslate } from './translate';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import * as riskAPI from '@/api/risk/risk';
import * as dataAPI from '@/api/risma/data';
import { dataURItoBlob, loadBlobAsURI } from '@/utils/blobUtils';
import { ErmRiskStatus } from '@/constants/risks/RiskStatus';
import { ImagesFormats } from '@/constants/FileFormats';
export default {
  name: 'PdfReportEditModal',
  components: {
    RismaFileUpload: RismaFileUpload,
    RismaToggle: RismaToggle,
    RismaSelect: RismaSelect,
    Modal: Modal,
    RismaLabel: RismaLabel,
    InputField: InputField,
    FeatherIcon: FeatherIcon,
    SingleSelect: SingleSelect
  },
  props: {
    currentReportId: {
      type: Number,
      required: false,
      default: null
    },
    hasBackgroundImage: {
      type: Boolean,
      required: false,
      default: null
    }
  },
  emits: ['dismiss', 'updateReport', 'createReport'],
  data: function data() {
    return {
      draft: {
        title: '',
        subTitle: '',
        baselineScoringId: null,
        managementLevelIds: [],
        fieldIds: [],
        organisationIds: [],
        page2: 0,
        includeUnapprovedRisks: 0
      },
      fileBlobData: null,
      initVersion: null,
      managementLevels: [],
      openScorings: [],
      errorMessages: {},
      imgSrc: null,
      isOkButtonDisabled: false,
      showAlert: false,
      ImagesFormats: ImagesFormats,
      translate: getTranslate['PdfReportEditModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useOrganisationsStore, ['organisations'])), {}, {
    fieldTitlesToValidation: function fieldTitlesToValidation() {
      return {
        title: this.translate.title,
        subTitle: this.translate.subtitle,
        baselineScoringId: this.translate.baseline,
        managementLevelIds: this.translate.managementLevels,
        organisationIds: this.translate.organisations
      };
    },
    validationRules: function validationRules() {
      return {
        notFalse: {
          validationPattern: function validationPattern(value) {
            return !!value;
          },
          validationNotification: this.translate.pleaseFillOutThis,
          validationFields: ['title']
        },
        notNullish: {
          validationPattern: function validationPattern(value) {
            var result = value !== null && value !== void 0 ? value : false;
            return value === result;
          },
          validationNotification: this.translate.pleaseSelectOne,
          validationFields: ['baselineScoringId']
        },
        notEmpty: {
          validationPattern: function validationPattern(value) {
            return value.length;
          },
          validationNotification: this.translate.pleaseSelectOne,
          validationFields: ['managementLevelIds', 'organisationIds']
        }
      };
    },
    modalHeader: function modalHeader() {
      return this.currentReportId === null ? this.translate.createPDFReport : this.translate.editPDFReport;
    },
    allExtraColumns: function allExtraColumns() {
      var _this = this;
      var currentScoring = this.openScorings.find(function (item) {
        return item.id === _this.draft.baselineScoringId && item.module !== 'scoring-zaxis';
      });
      return (currentScoring === null || currentScoring === void 0 ? void 0 : currentScoring.fields) || [];
    },
    preSelectedOptions: function preSelectedOptions() {
      var _this2 = this;
      return {
        currentScoring: this.getSelectOptions(this.openScorings, 'version').filter(function (scoring) {
          return scoring.value === _this2.draft.baselineScoringId;
        }),
        managementLevelIds: this.getSelectOptions(this.managementLevels, 'name').filter(function (ml) {
          return _this2.draft.managementLevelIds.includes(ml.value);
        }),
        extraColumns: this.getSelectOptions(this.allExtraColumns, 'label').filter(function (ec) {
          return _this2.draft.fieldIds.includes(ec.value);
        }),
        organisations: this.getSelectOptions(this.organisations, 'visible_name').filter(function (org) {
          return _this2.draft.organisationIds.includes(org.value);
        })
      };
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    handleDismiss: function handleDismiss() {
      var _this3 = this;
      if (this.isDirty()) {
        this.$confirm(this.translate.areYouSure, this.translate.allDataWillBeLost, function (res) {
          return res && _this3.$emit('dismiss');
        });
        return;
      }
      this.$emit('dismiss');
    },
    isDirty: function isDirty() {
      return !isEqual(this.initVersion, this.draft) || this.fileBlobData;
    },
    getData: function getData() {
      var _this4 = this;
      var promises = [this.getManagementLevels(), this.getScorings(), this.getReportPreview()];
      if (this.currentReportId !== null) {
        promises.push(this.getCurrentReport());
      }
      Promise.allSettled(promises).then(function () {
        return _this4.initVersion = cloneDeep(_this4.draft);
      });
    },
    getSelectOptions: function getSelectOptions(data, nameField) {
      return data.map(function (item) {
        return {
          value: item.id,
          label: item[nameField]
        };
      });
    },
    getManagementLevels: function getManagementLevels() {
      var _this5 = this;
      return riskAPI.getRisksManagementLevels().then(function (_ref) {
        var list = _ref.list;
        return _this5.managementLevels = list;
      });
    },
    getScorings: function getScorings() {
      var _this6 = this;
      return dataAPI.getAllScorings().then(function (_ref2) {
        var list = _ref2.list;
        _this6.openScorings = list.filter(function (scoring) {
          return scoring.status !== ErmRiskStatus.EDITING;
        });
      });
    },
    getReportPreview: function getReportPreview() {
      var _this7 = this;
      if (this.currentReportId && this.hasBackgroundImage) {
        return riskAPI.getManagementReportPreview(this.currentReportId).then(function (blob) {
          return loadBlobAsURI(blob, function (uri) {
            _this7.imgSrc = uri;
          });
        });
      }
    },
    updateDraft: function updateDraft(property, value) {
      if (Array.isArray(value)) {
        value = value.map(function (item) {
          return item.value;
        });
      }
      this.draft = _objectSpread(_objectSpread({}, this.draft), {}, _defineProperty({}, property, value));
    },
    validateDraft: function validateDraft() {
      var _this8 = this;
      var errorMessages = {};
      Object.values(this.validationRules).forEach(function (rule) {
        rule.validationFields.forEach(function (field) {
          if (!rule.validationPattern(_this8.draft[field])) {
            rule.validationFields.forEach(function (validationField) {
              errorMessages[validationField] = "".concat(rule.validationNotification, ": ").concat(_this8.fieldTitlesToValidation[field]);
            });
          }
        });
      });
      this.errorMessages = errorMessages;
      return !Object.keys(errorMessages).length;
    },
    getCurrentReport: function getCurrentReport() {
      var _this9 = this;
      return riskAPI.getManagementReportById(this.currentReportId).then(function (item) {
        _this9.draft = {
          //looks like a test data has title with an empty space in some titles
          title: item.title.trim(),
          subTitle: item.subTitle,
          baselineScoringId: item.baselineScoringId,
          managementLevelIds: item.managementLevelIds,
          fieldIds: item.fieldIds,
          organisationIds: item.organisationIds,
          page2: item.page2,
          includeUnapprovedRisks: item.includeUnapprovedRisks
        };
      });
    },
    initReportSaving: function initReportSaving() {
      if (this.validateDraft()) {
        if (this.isDirty()) {
          this.isOkButtonDisabled = true;
          if (this.currentReportId !== null) {
            this.$emit('updateReport', {
              draft: this.draft,
              file: this.fileBlobData
            });
          } else {
            this.$emit('createReport', {
              draft: this.draft,
              file: this.fileBlobData
            });
          }
          return;
        }
        this.$emit('dismiss');
      }
    },
    handleLoadFile: function handleLoadFile(fileList) {
      var file = fileList.slice(fileList.length - 1);
      this.imgSrc = "data:".concat(file[0].mimeType, ";base64,").concat(file[0].content);
      this.fileBlobData = dataURItoBlob(this.imgSrc);
    },
    initFileHandling: function initFileHandling() {
      this.$refs.fileUploadItem.clickInput();
    }
  }
};