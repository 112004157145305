var selectStyles = {
  DEFAULT: 'default',
  TINY: 'tiny'
};
import { clickOutside } from '@/utils/directives/clickOutside';
import { sortAlphabetic } from '@/utils/sort';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
export default {
  name: 'SingleSelect',
  introduction: 'for when the user is typing',
  description: 'This is a simple input field that emits an "updated"-event when the value changes',
  token: "\n<single-select :options=\"options\" :modelValue=\"selectedOption\" :disabled=\"false\" :placeholder=\"translate.choose\"/>\nconst options = [\n    {value: 1, label:'Hello'},\n    {value: 2, label:'To'},\n    {value: 3, label:'You'}\n];\nlet selectedOption = 2;",
  directives: {
    clickOutside: clickOutside
  },
  components: {
    FeatherIcon: FeatherIcon,
    LoadingIndicatorSmall: LoadingIndicatorSmall
  },
  props: {
    options: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'Available options. Each option is an object {value: Boolean|Number|String, label: String}'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Is disabled'
    },
    placeholder: {
      type: String,
      default: '',
      note: 'Placeholder'
    },
    modelValue: {
      required: false,
      default: null,
      type: [String, Boolean, Number],
      note: 'v-model. Selected option'
    },
    withSearch: {
      required: false,
      default: false,
      type: Boolean,
      note: 'Add possibility to filter options'
    },
    withReset: {
      required: false,
      default: false,
      type: Boolean,
      note: 'Add possibility to reset chosen option'
    },
    unSorted: {
      required: false,
      default: false,
      type: Boolean,
      note: 'Do not sort items by label - keep in the order that was given'
    },
    tabindex: {
      type: [Number, String],
      required: false,
      default: 0,
      note: 'index for tabbing'
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    hasResetButton: {
      type: Boolean,
      required: false,
      default: false
    },
    requiredText: {
      type: String,
      required: false,
      default: '',
      note: 'Label that will be displayed if value is not selected. Also it doesn`t allow to reset value'
    },
    helpText: {
      type: String,
      required: false,
      default: ''
    },
    selectStyle: {
      type: String,
      required: false,
      default: selectStyles.DEFAULT
    },
    allDataShown: {
      type: Boolean,
      default: true
    },
    isLazyLoadingShown: {
      type: Boolean,
      default: false
    },
    emitOnKeydown: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue', 'reset', 'expanded', 'load', 'update:searchValue'],
  data: function data() {
    return {
      expanded: false,
      filterString: '',
      chevronEnabled: true,
      selectStyles: selectStyles,
      focusedElIdx: null,
      tabWasPressed: false,
      isFocusTriggered: false,
      translate: {
        choose: this.$trans('Choose'),
        noResultsMatchingSearchCriteria: this.$trans('No results matching the search criteria'),
        listIsEmpty: this.$trans('List is empty'),
        resetToDefault: this.$trans('Reset to default'),
        scrollDownForMoreResults: this.$trans('Scroll down for more results...')
      }
    };
  },
  computed: {
    values: function values() {
      var _this = this;
      var options = this.options.slice(0);
      if (this.filterString) {
        options = options.filter(function (o) {
          return o.label.toLowerCase().includes(_this.filterString.toLowerCase());
        });
      }
      return this.unSorted ? options : sortAlphabetic(options, 'label');
    },
    isSelectableValueExist: function isSelectableValueExist() {
      return this.values.some(function (value) {
        return !value.$isGroup;
      });
    },
    showFieldRequired: function showFieldRequired() {
      return this.requiredText && !this.selectedValue;
    },
    borderCalculatedClasses: function borderCalculatedClasses() {
      var result = ['rounded-md outline outline-transparent', {
        border: !this.disabled || !this.expanded,
        'absolute z-2 shadow-rm-grey--20': this.disabled && this.expanded,
        'bg-rm-wisp': this.disabled
      }];
      if (this.selectStyle === selectStyles.TINY) return result;
      if (this.showFieldRequired) {
        result.push('border-rm-signal-red focus-within:outline-rm-signal-red--20');
        return result;
      }
      result.push({
        'focus-within:outline-rm-active-blue--20 border-rm-active-blue': this.expanded && !this.disabled,
        'border-rm-grey': !this.expanded || this.disabled,
        'hover:border-rm-signal-grey-dark hover:outline-rm-grey-light': this.disabled && this.chevronEnabled || !this.disabled && !this.expanded
      });
      return result;
    },
    iconCalculatedClasses: function iconCalculatedClasses() {
      if (this.selectStyle === selectStyles.DEFAULT) {
        return 'stroke-2 group-active/icon:text-rm-active-blue text-rm-grey';
      }
      return 'text-white stroke-3';
    },
    innerSelectCalculatedClasses: function innerSelectCalculatedClasses() {
      var result = this.expanded ? 'custom-select-inner h-44px' : 'custom-select-inner h-44px expanded';
      result += this.disabled ? ' disabled cursor-not-allowed' : ' cursor-pointer bg-white';
      result += this.selectedValue ? '' : ' value-not-selected';
      result += this.showFieldRequired ? ' focus:outline-rm-signal-red--20 outline-offset-0 outline-2' : ' focus:outline-rm-active-blue--20 focus:border-rm-active-blue outline-offset-0 outline-2';
      return result;
    },
    selectedValue: function selectedValue() {
      var _this2 = this;
      return this.values.find(function (o) {
        return o.value === _this2.modelValue;
      });
    },
    selectedIndex: function selectedIndex() {
      var _this3 = this;
      return this.values.findIndex(function (o) {
        return o.value === _this3.modelValue;
      });
    },
    lockedForReset: function lockedForReset() {
      return !!this.requiredText || !this.withReset || !this.modelValue || this.disabled;
    },
    isPlaceholderInOptions: function isPlaceholderInOptions() {
      var _this4 = this;
      return this.values.some(function (option) {
        return option.label === _this4.placeholder;
      });
    }
  },
  watch: {
    disabled: function disabled() {
      this.setChevronVisibility();
    },
    expanded: function expanded() {
      this.filterString = '';
      this.tabWasPressed = false;
    }
  },
  mounted: function mounted() {
    this.setChevronVisibility();
    this.$refs.selectItems.addEventListener('scroll', this.onItemsListScroll);
  },
  methods: {
    reset: function reset() {
      this.$emit('update:modelValue', null);
    },
    focusSelected: function focusSelected(value) {
      this.emitOnKeydown && this.$emit('update:modelValue', value);
      var selectedEl = null;
      if (value === null) {
        //selectAll and 'not selected' value can be null
        selectedEl = this.$refs.selectItems.children[0];
      } else {
        selectedEl = this.$refs.selectItems.querySelector("[value=\"".concat(value, "\"]"));
      }
      this.$refs.selectItems.scrollTop = selectedEl.offsetTop - 100;
    },
    onOptionSelect: function onOptionSelect(option) {
      if (this.disabled) {
        return;
      }
      this.$emit('update:modelValue', option.value);
      this.hide('option select');
    },
    onEnterClick: function onEnterClick() {
      if (this.disabled) {
        return;
      }
      if (this.focusedElIdx !== null) {
        this.$emit('update:modelValue', this.values[this.focusedElIdx].value);
      }
      this.hide('enter click');
    },
    nextOption: function nextOption() {
      if (!this.isSelectableValueExist) return;
      if (this.focusedElIdx === null) {
        this.setPointerOnFirstElement();
      } else {
        this.focusedElIdx = this.focusedElIdx === this.values.length - 1 ? 0 : this.focusedElIdx + 1;
      }
      if (this.focusedElIdx > this.values.length - 1) {
        this.focusedElIdx = 0;
      }
      var element = this.values[this.focusedElIdx];
      if (element.$isGroup) {
        this.nextOption();
        return;
      }
      this.focusSelected(element.value);
    },
    prevOption: function prevOption() {
      if (!this.isSelectableValueExist) return;
      if (this.focusedElIdx === null) {
        this.focusedElIdx = this.values.length - 1;
      } else {
        this.focusedElIdx = this.focusedElIdx <= 0 ? this.values.length - 1 : this.focusedElIdx - 1;
      }
      if (this.focusedElIdx > this.values.length - 1) {
        this.focusedElIdx = 0;
      }
      var element = this.values[this.focusedElIdx];
      if (element.$isGroup) {
        this.prevOption();
        return;
      }
      this.focusSelected(element.value);
    },
    onKeyDown: function onKeyDown(evt) {
      if (!this.expanded) return;
      var charCode = evt.keyCode || evt.key.charCodeAt(0);
      this.tabWasPressed = charCode === 9;
    },
    onBlur: function onBlur() {
      if (this.tabWasPressed) {
        this.hide();
        this.isFocusTriggered = false;
      }
    },
    hide: function hide() {
      if (!this.expanded) return;
      this.expanded = false;
      this.emitExpanded();
    },
    emitExpanded: function emitExpanded() {
      this.$emit('expanded', this.expanded);
    },
    show: function show() {
      var _this5 = this;
      var showDisabled = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (this.disabled && !(showDisabled && this.chevronEnabled)) {
        return;
      }
      this.expanded = true;
      this.emitExpanded();
      if (!this.disabled) {
        this.$nextTick(function () {
          _this5.$refs.filterString && _this5.$refs.filterString.focus();
        });
        this.setPointerOnFirstElement();
      }
    },
    setChevronVisibility: function setChevronVisibility() {
      if (this.disabled && !this.$refs.selectedValue) {
        this.chevronEnabled = false;
        return;
      }
      if (!this.disabled || !this.$refs.selectedValue) {
        this.chevronEnabled = true;
        return;
      }
      var _this$$refs$selectedV = this.$refs.selectedValue,
        offsetWidth = _this$$refs$selectedV.offsetWidth,
        scrollWidth = _this$$refs$selectedV.scrollWidth;
      this.chevronEnabled = offsetWidth < scrollWidth;
    },
    onChevronClick: function onChevronClick() {
      this.toggle(true);
    },
    toggle: function toggle() {
      var showDisabled = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.expanded ? this.hide() : this.show(showDisabled);
    },
    onItemsListScroll: function onItemsListScroll(event) {
      if (this.allDataShown) return;
      var target = event.target;
      var bottomOfWindow = target.scrollHeight - target.scrollTop - target.clientHeight <= 5;
      if (bottomOfWindow) {
        this.$emit('load');
      }
    },
    setPointerOnFirstElement: function setPointerOnFirstElement() {
      this.focusedElIdx = 0;
    },
    handleClick: function handleClick() {
      if (this.isFocusTriggered) {
        this.isFocusTriggered = false;
        return;
      }
      this.toggle();
    },
    handleFocus: function handleFocus() {
      this.isFocusTriggered = true;
      this.show();
    }
  }
};