import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "mb-4"
};
var _hoisted_2 = {
  class: "flex mb-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_table = _resolveComponent("risma-table");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
        modelValue: $data.selectedUser,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.selectedUser = $event;
        }),
        class: "!w-1/2 mr-10 z-100",
        title: $data.translate.filterByUsername,
        "with-reset": true,
        placeholder: $data.translate.chooseUser,
        options: $options.optionUsers,
        "with-search": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options"]), _createVNode(_component_risma_date_range_picker, {
        class: "w-1/2",
        title: $data.translate.filterByDateRange,
        "start-date": $data.dateRange.startDate,
        "end-date": $data.dateRange.endDate,
        "date-format": $data.DateFormats.DATE_TIME_FORMAT_FULL,
        type: "datetime",
        onChanged: $options.changedDates
      }, null, 8 /* PROPS */, ["title", "start-date", "end-date", "date-format", "onChanged"])]), _createVNode(_component_risma_button, {
        text: $data.translate.createReport,
        type: "save",
        class: "ml-auto",
        onClick: $options.createReport
      }, null, 8 /* PROPS */, ["text", "onClick"])]), $data.showReport ? (_openBlock(), _createBlock(_component_risma_table, {
        ref: "tableData",
        key: $data.tableGeneratedKey,
        class: "p-4",
        "state-key": "access_changelog_key",
        api: $options.getAccessChangelogAPI
      }, null, 8 /* PROPS */, ["api"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  });
}