function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { getCompany as _getCompany, editCompany } from '@/api/admin/companies';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import Notification from '@/components/Molecules/Notification';
import { createTabsList } from '@/utils/base/tabs';
import { getLinksLabel } from '@/utils/links';
import { catch403 } from '@/utils/handleAPIErrors';
import { saveStatus } from '@/constants/SaveStatuses';
import { TabTypes } from '@/constants/Tabs';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { MODULES } from '@/constants/modules';
import { VAT_STATUSES, vatStatusLabelByValue } from '@/constants/companies/vatStatuses';
import { isEmailValid, isValidVatNo } from '@/utils/validations';
import { calcCompanyRights } from '@/menus/quicknav';
import { UserLevels } from '@/constants/UserLevels';
export default {
  name: 'CompanyEntry',
  components: {
    DepWell: DepWell,
    Notification: Notification
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    id: {
      type: [String, Number],
      require: true,
      default: null,
      note: 'company id'
    }
  },
  emits: ['changeTab', 'updated', 'editContact', 'accessError'],
  data: function data() {
    return {
      isLoaded: false,
      company: {},
      saveStatus: saveStatus.SAVED,
      updateTimer: 0,
      associationError: '',
      translate: {
        beAwareThatThisCompanyHasStatus: this.$trans('Be aware that this company has status'),
        bothFieldsAreRequired: this.$trans('Both fields are required: Please specify the relation to the parent company (e.g., branch or subsidiary) before the data can be saved.')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser', 'isAdmin', 'userHasAccessLevel'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    getAllSettings: 'getAllSettings'
  })), {}, {
    tabsNames: function tabsNames() {
      var tabs = [TabTypes.DESCRIPTION, TabTypes.CONTACTS, TabTypes.LINKS, TabTypes.CHANGELOG];
      if (this.getSettingValue('feature.company.dora_enable') && this.company.doraEnabled) {
        tabs.splice(2, 0, TabTypes.FUNCTIONS);
      }
      return tabs;
    },
    tabs: function tabs() {
      var baseUrl = "/company/".concat(this.id);
      return createTabsList(this.tabsNames, baseUrl, getLinksLabel(this.company));
    },
    isUserAbleToEdit: function isUserAbleToEdit() {
      return this.userHasAccessLevel('level_company', UserLevels.SUPER);
    },
    depHeaderProps: function depHeaderProps() {
      return {
        id: this.company.id,
        title: this.company.name,
        editableTitle: this.isUserAbleToEdit,
        tabs: this.tabs,
        module: MODULES.COMPANY,
        isAttachmentEnabled: false,
        saveStatus: this.saveStatus,
        titleField: 'name',
        trafficLightSettings: this.trafficLightSettings
      };
    },
    trafficLightSettings: function trafficLightSettings() {
      return {
        value: this.company.vatStatus,
        lightType: ObjectTypes.COMPANY,
        field: 'vatStatus',
        lightWithAttribute: true,
        readOnly: !this.isUserAbleToEdit
      };
    },
    routeBackName: function routeBackName() {
      var config = {
        contracts: 'MyCompanies',
        settings: 'Companies'
      };
      return config[this.routeBackKey];
    },
    statusNotificationMessage: function statusNotificationMessage() {
      return this.company.vatStatus > VAT_STATUSES.NORMAL.value ? ": ".concat(this.$trans(vatStatusLabelByValue[this.company.vatStatus])) : '';
    }
  }),
  mounted: function mounted() {
    var _this = this;
    if (calcCompanyRights(this.currentUser, this.getAllSettings)) {
      this.getCompany().finally(function () {
        return _this.isLoaded = true;
      });
    } else {
      this.$emit('accessError');
    }
  },
  unmounted: function unmounted() {
    clearTimeout(this.updateTimer);
  },
  methods: {
    setUpdateTimer: function setUpdateTimer() {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.saveUpdates, this.$debounceTimeout);
    },
    getCompany: function getCompany() {
      var _this2 = this;
      var query = '?data[]=connected'; // get connected IA

      return _getCompany(this.id, query).then(function (response) {
        _this2.company = response;
      }).catch(function () {
        catch403(_this2.$router, 'Companies');
      });
    },
    companyPropertyChanged: function companyPropertyChanged(_ref) {
      var property = _ref.property,
        value = _ref.value;
      this.company[property] = value;
      this.setUpdateTimer();
    },
    saveUpdates: function saveUpdates() {
      var _this3 = this;
      this.associationError = '';
      if (this.company.parentId && !this.company.companyType) {
        this.associationError = this.translate.bothFieldsAreRequired;
        return;
      }
      this.saveStatus = saveStatus.SAVING;
      if (!this.isValid()) {
        this.saveStatus = saveStatus.NOT_SAVED;
        return;
      }
      return editCompany(this.company).then(function (response) {
        _this3.company = _objectSpread(_objectSpread({}, _this3.company), response);
        _this3.saveStatus = saveStatus.SAVED;
        _this3.$emit('updated', _this3.company);
      }).catch(function (error) {
        var _error$response;
        var errorMessage = (error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error) || _this3.$trans('An error occurred');
        _this3.$notify({
          title: errorMessage,
          type: 'error'
        });
        _this3.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    handleShowNotification: function handleShowNotification(type, message) {
      this.$notify({
        title: message,
        type: type
      });
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        clearTimeout(this.updateTimer);
        this.saveUpdates();
      }
    },
    isValid: function isValid() {
      return (!this.company.email || isEmailValid(this.company.email)) && (!this.company.lei || this.company.lei.length === 20) && isValidVatNo(this.company.vatNo, this.company.countryCode);
    }
  }
};