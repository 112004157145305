import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mb-4"
};
var _hoisted_2 = {
  key: 1,
  class: "mb-3"
};
var _hoisted_3 = {
  key: 2,
  class: "mb-4"
};
var _hoisted_4 = {
  key: 3,
  class: "mb-4"
};
var _hoisted_5 = {
  key: 4,
  class: "mb-4"
};
var _hoisted_6 = {
  key: 5,
  class: "mb-6"
};
var _hoisted_7 = {
  key: 0
};
var _hoisted_8 = {
  key: 1,
  class: "text-rm-text"
};
var _hoisted_9 = {
  class: "-mt-5"
};
var _hoisted_10 = {
  class: "mb-6"
};
var _hoisted_11 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_text_box = _resolveComponent("text-box");
  var _component_single_select = _resolveComponent("single-select");
  var _component_user_selector = _resolveComponent("user-selector");
  var _component_organisation_selector = _resolveComponent("organisation-selector");
  var _component_frameworks_selector = _resolveComponent("frameworks-selector");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_attributes_list = _resolveComponent("attributes-list");
  var _component_control_details_associations = _resolveComponent("control-details-associations");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $options.titleModal,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    "dismiss-on-click-outside": false,
    "accept-on-enter": !$data.isRismaSelectOpen,
    "ok-button-disabled": $data.isCreating,
    "dismiss-button-disabled": $data.isCreating,
    onAccept: $options.submitModal,
    onDismiss: _cache[15] || (_cache[15] = function ($event) {
      return _ctx.$emit('close');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.backendErrorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.backendErrorMessage), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        modelValue: $data.data.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.data.title = $event;
        }),
        title: $data.translate.title,
        "focus-on-mount": true,
        invalid: !!$data.errorMessages[$data.errorFields.TITLE],
        "error-help-text": $data.errorMessages[$data.errorFields.TITLE],
        "pre-text": $props.preset.title,
        enabled: !$props.preset.title,
        placeholder: $data.translate.enterTitle,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "error-help-text", "pre-text", "enabled", "placeholder"])]), $props.showAllFields ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_text_box, {
        text: $data.data.description,
        placeholder: $data.translate.description,
        label: $data.translate.description,
        class: "w-full",
        onUpdated: $options.setDescription
      }, null, 8 /* PROPS */, ["text", "placeholder", "label", "onUpdated"])])) : _createCommentVNode("v-if", true), $props.showAllFields ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_single_select, {
        modelValue: $data.motherControlChosen,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.motherControlChosen = $event;
        }),
        title: $data.translate.motherControl,
        options: $options.montherControlsOptions,
        placeholder: $data.translate.motherControl,
        "un-sorted": true,
        "with-reset": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])])) : _createCommentVNode("v-if", true), !$props.isMotherMode ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_user_selector, {
        "available-options": $options.controlUsers,
        "selected-options": $data.data.responsible,
        title: $data.translate.responsible,
        "error-message": $data.errorMessages[$data.errorFields.RESPONSIBLE] || '',
        onSelected: _cache[2] || (_cache[2] = function ($event) {
          return $options.propertyChange($event, 'responsible');
        }),
        onOpen: _cache[3] || (_cache[3] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[4] || (_cache[4] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["available-options", "selected-options", "title", "error-message"])])) : _createCommentVNode("v-if", true), !$props.isMotherMode ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_organisation_selector, {
        "available-options": _ctx.organisations,
        "selected-options": $data.data.organisations,
        title: $data.translate.organisations,
        "error-message": $data.errorMessages[$data.errorFields.ORGANISATION] || '',
        onSelected: _cache[5] || (_cache[5] = function ($event) {
          return $options.propertyChange($event, 'organisations');
        }),
        onOpen: _cache[6] || (_cache[6] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[7] || (_cache[7] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["available-options", "selected-options", "title", "error-message"])])) : _createCommentVNode("v-if", true), _createVNode(_component_frameworks_selector, {
        class: "mb-4",
        "selected-options": $data.data.solutions || [],
        onSelected: _cache[8] || (_cache[8] = function ($event) {
          return $options.propertyChange($event, 'solutions');
        }),
        onOpen: _cache[9] || (_cache[9] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[10] || (_cache[10] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["selected-options"]), _createVNode(_component_single_select, {
        modelValue: $data.data.activityTypeId,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = function ($event) {
          return $data.data.activityTypeId = $event;
        }),
        class: "mb-4",
        title: $data.translate.activityType,
        options: $options.activityTypeOptions,
        placeholder: $data.translate.activityType,
        "un-sorted": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"]), $props.showAssociations ? (_openBlock(), _createElementBlock("div", _hoisted_6, [!$data.motherControlChosen ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_button, {
        type: "util",
        text: "",
        class: "cursor-pointer flex items-center glob-l1-sb text-rm-text",
        onClick: _cache[13] || (_cache[13] = function ($event) {
          return $data.showAssociationsModal = true;
        })
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_feather_icon, {
            icon: "plus",
            class: "mr-1",
            onClick: _cache[12] || (_cache[12] = function ($event) {
              return $data.showAssociationsModal = true;
            })
          }), _createElementVNode("span", null, _toDisplayString($data.translate.addAssociations), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })])) : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($data.translate.associationValuesWillBeInheritedFromTheMother), 1 /* TEXT */)), $data.showAssociationsModal ? (_openBlock(), _createBlock(_component_modal, {
        key: 2,
        header: $data.translate.associations,
        "button-ok-text": $data.translate.save,
        "dismiss-on-click-outside": false,
        onAccept: $options.onAssociationsModalSubmit,
        onDismiss: $options.closeAssociationsModal
      }, {
        body: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_9, [_createElementVNode("p", _hoisted_10, _toDisplayString($data.translate.youCanAddTheFollowingAttributes), 1 /* TEXT */), _createElementVNode("p", {
            class: "mb-3 glob-h2 text-rm-text",
            title: $data.translate.attributes
          }, _toDisplayString($data.translate.attributes), 9 /* TEXT, PROPS */, _hoisted_11), $props.attributes && Object.keys($props.attributes).length ? (_openBlock(), _createBlock(_component_attributes_list, {
            key: 0,
            class: "mb-6",
            "attributes-map": $props.attributes
          }, null, 8 /* PROPS */, ["attributes-map"])) : _createCommentVNode("v-if", true), _createVNode(_component_control_details_associations, {
            class: "pb-1",
            "selected-items": $options.selectedAssociations,
            onChanged: _cache[14] || (_cache[14] = function ($event) {
              return $data.associationsTempValue[$event.key] = $event.value;
            })
          }, null, 8 /* PROPS */, ["selected-items"])])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["header", "button-ok-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $props.showAssociations && $options.associationsList && Object.keys($options.associationsList).length ? (_openBlock(), _createBlock(_component_attributes_list, {
        key: 6,
        attributes: $options.associationsList
      }, null, 8 /* PROPS */, ["attributes"])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "accept-on-enter", "ok-button-disabled", "dismiss-button-disabled", "onAccept"]);
}