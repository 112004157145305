function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "m-0 bg-gray-125 overflow-auto max-h-80"
};
var _hoisted_2 = {
  key: 1,
  class: "m-0 bg-gray-125 overflow-auto max-h-80"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_checkboxes = _resolveComponent("checkboxes");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_select, {
    options: $data.RismaSelectOptions,
    "selected-options": $data.RismaSelectOptionsSelected,
    "label-key": "option",
    onSelected: _cache[0] || (_cache[0] = function ($event) {
      return $data.RismaSelectOptionsSelected = $event;
    })
  }, null, 8 /* PROPS */, ["options", "selected-options"]), _cache[10] || (_cache[10] = _createElementVNode("span", null, "Output:", -1 /* HOISTED */)), $data.RismaSelectOptionsSelected.length ? (_openBlock(), _createElementBlock("pre", _hoisted_1, [_createElementVNode("code", null, _toDisplayString($data.RismaSelectOptionsSelected), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[12] || (_cache[12] = _createElementVNode("p", null, "RismaSelect without 'SelectAll' feature, just add `:enable-select-all=\"false\"`", -1 /* HOISTED */)), _createVNode(_component_risma_select, {
    "enable-select-all": false,
    options: $data.RismaSelectOptions,
    "selected-options": [],
    "label-key": "option",
    placeholder: _ctx.$trans("It's a placeholder")
  }, null, 8 /* PROPS */, ["options", "placeholder"]), _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[14] || (_cache[14] = _createElementVNode("p", null, "RismaSelect with custom markup to option by slot", -1 /* HOISTED */)), _createVNode(_component_risma_select, {
    "enable-select-all": false,
    options: $data.RismaSelectCustomlabel,
    "selected-options": $data.RismaSelectCustomlabelSelected,
    "label-key": "title",
    placeholder: _ctx.$trans("It's a placeholder"),
    onSelected: _cache[1] || (_cache[1] = function ($event) {
      return $data.RismaSelectCustomlabelSelected = $event;
    })
  }, {
    labelCustom: _withCtx(function (props) {
      return [_createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(props.option.title), 1 /* TEXT */), _createVNode(_component_feather_icon, {
        icon: props.option.icon,
        class: "stroke-3 inline"
      }, null, 8 /* PROPS */, ["icon"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["options", "selected-options", "placeholder"]), _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[16] || (_cache[16] = _createElementVNode("p", null, "RismaSelect with options grouped", -1 /* HOISTED */)), _createVNode(_component_checkboxes, {
    options: {
      label: 'Clickable Group Selection',
      checked: false
    },
    onUpdated: _cache[2] || (_cache[2] = function ($event) {
      return $data.groupSelectable = $event.isChecked;
    })
  }), _createVNode(_component_risma_select, {
    options: $data.groupedOptions,
    "selected-options": $data.groupedSelectedOptions,
    "label-key": "name",
    "enable-select-all": false,
    "group-values": "libs",
    "group-label": "language",
    "group-select": $data.groupSelectable,
    "track-by": "name",
    onSelected: _cache[3] || (_cache[3] = function ($event) {
      return $data.groupedSelectedOptions = $event;
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "group-select"]), _cache[17] || (_cache[17] = _createElementVNode("span", null, "Output:", -1 /* HOISTED */)), $data.groupedSelectedOptions.length ? (_openBlock(), _createElementBlock("pre", _hoisted_2, [_createElementVNode("code", null, _toDisplayString($data.groupedSelectedOptions), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_select, {
    options: $data.RismaSelectOptions,
    "selected-options": $data.RismaSelectOptionsSelected,
    title: "RismaSelect disabled",
    placeholder: "Placeholder text",
    "label-key": "option",
    disabled: true,
    "help-text": "Help text for disabled element (can be shown for any state)"
  }, null, 8 /* PROPS */, ["options", "selected-options"]), _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_select, {
    options: $data.RismaSelectOptions,
    "selected-options": $data.RismaSelectOptionsSelected,
    title: "MaxVisibleTags = 0",
    "max-visible-tags": 0,
    "label-key": "option",
    onSelected: _cache[4] || (_cache[4] = function ($event) {
      return $data.RismaSelectOptionsSelected = $event;
    })
  }, null, 8 /* PROPS */, ["options", "selected-options"]), _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_select, {
    options: $data.RismaSelectOptions,
    "selected-options": $data.RismaSelectOptionsSelectedSecondary,
    "label-key": "option",
    title: "Only one line of elements is possible",
    "has-tag-wrap-new-line": false,
    onSelected: _cache[5] || (_cache[5] = function ($event) {
      return $data.RismaSelectOptionsSelectedSecondary = $event;
    })
  }, null, 8 /* PROPS */, ["options", "selected-options"]), _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_select, {
    options: $data.RismaSelectOptions,
    "selected-options": $data.RismaSelectOptionsSelected,
    "label-key": "option",
    title: "Has error message",
    "error-message": "Help text",
    "has-tag-wrap-new-line": false,
    onSelected: _cache[6] || (_cache[6] = function ($event) {
      return $data.RismaSelectOptionsSelected = $event;
    })
  }, null, 8 /* PROPS */, ["options", "selected-options"]), _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_select, {
    options: [],
    "selected-options": [],
    "label-key": "option",
    title: "Empty options list"
  }), _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_select, {
    options: [].concat(_toConsumableArray($data.RismaSelectOptions), [$data.RismaSelectTruncateOption]),
    "selected-options": $data.RismaSelectTruncateSelected,
    "label-key": "option",
    title: "Test truncation for very long element",
    onSelected: _cache[7] || (_cache[7] = function ($event) {
      return $data.RismaSelectTruncateSelected = $event;
    })
  }, null, 8 /* PROPS */, ["options", "selected-options"]), _cache[24] || (_cache[24] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_select, {
    options: $data.RismaSelectOptionsExpanded,
    "selected-options": $data.RismaSelectOptionsExpandedSelected,
    "label-key": "option",
    title: "Add new option button",
    "add-new-option-label": "Add option",
    class: "mb-2",
    onSelected: _cache[8] || (_cache[8] = function ($event) {
      return $data.RismaSelectOptionsExpandedSelected = $event;
    }),
    onTag: $options.handleCreateTag
  }, null, 8 /* PROPS */, ["options", "selected-options", "onTag"]), _createVNode(_component_risma_select, {
    options: $data.RismaSelectOptionsExpanded,
    "selected-options": $data.RismaSelectOptionsExpandedSelected,
    "label-key": "option",
    title: "Add new option button with bottom position of the button",
    "add-new-option-label": "Add option",
    "new-option-position": "bottom",
    onSelected: _cache[9] || (_cache[9] = function ($event) {
      return $data.RismaSelectOptionsExpandedSelected = $event;
    }),
    onTag: $options.handleCreateTag
  }, null, 8 /* PROPS */, ["options", "selected-options", "onTag"])]);
}