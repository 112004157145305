import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "incident-description"
};
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = {
  key: 2,
  class: "mb-3"
};
var _hoisted_4 = {
  key: 3,
  class: "mb-3.5 pb-3 border-b"
};
var _hoisted_5 = ["innerHTML"];
var _hoisted_6 = {
  key: 0,
  class: "min-w-150px"
};
var _hoisted_7 = {
  key: 6,
  class: "mt-8 mb-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$statusInstr, _$props$data$question;
  var _component_notification = _resolveComponent("notification");
  var _component_required_fields_notification = _resolveComponent("required-fields-notification");
  var _component_confidential_box = _resolveComponent("confidential-box");
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_traffic_light_selector = _resolveComponent("traffic-light-selector");
  var _component_single_select = _resolveComponent("single-select");
  var _component_frameworks_selector = _resolveComponent("frameworks-selector");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  var _component_questionnaire_stats = _resolveComponent("questionnaire-stats");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.archived ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "info",
    closeable: false
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($options.archivedMessage), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $props.isCreateMode && $props.isCreateAttempted ? (_openBlock(), _createBlock(_component_required_fields_notification, {
    key: 1,
    "missing-required-fields": $options.missingRequiredFields
  }, null, 8 /* PROPS */, ["missing-required-fields"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [!$props.isCreateMode && !!_ctx.getSettingValue('feature.incident_confidential_enabled') ? (_openBlock(), _createBlock(_component_confidential_box, {
    key: 0,
    confidential: !!$props.data.confidential,
    "read-only": $options.confidentialReadOnly,
    "confidentiality-names": $options.confidentialityNames,
    onChanged: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('propertyChanged', {
        property: 'confidential',
        value: +$event
      });
    })
  }, null, 8 /* PROPS */, ["confidential", "read-only", "confidentiality-names"])) : _createCommentVNode("v-if", true), $options.toggleMenuOptions.length && !$props.isUserLocked && !$props.isCreateMode ? (_openBlock(), _createBlock(_component_toggle_menu, {
    key: 1,
    options: $options.toggleMenuOptions,
    class: "ml-auto"
  }, null, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true)]), $props.includeTitle ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_input_field, {
    modelValue: _ctx.title,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.title = $event;
    }),
    title: $data.translate.title,
    invalid: !!$props.titleErrorMessage,
    "error-help-text": $props.titleErrorMessage,
    "focus-on-mount": $props.includeTitle && $props.isCreateMode,
    placeholder: $data.translate.enterTitle,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "error-help-text", "focus-on-mount", "placeholder"])])) : _createCommentVNode("v-if", true), !$props.isCreateMode && (_$options$statusInstr = $options.statusInstructions) !== null && _$options$statusInstr !== void 0 && _$options$statusInstr.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.instructions,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
    class: "glob-link-inside",
    innerHTML: $options.statusInstructions
  }, null, 8 /* PROPS */, _hoisted_5)])) : _createCommentVNode("v-if", true), !$props.isCreateMode && $options.isFieldInOptional('description') ? (_openBlock(), _createBlock(_component_tiny_mce_box, {
    key: 4,
    headline: $data.translate.description,
    "initial-content": _ctx.description,
    "read-only": $props.readOnly,
    onChanged: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.description = $event;
    })
  }, null, 8 /* PROPS */, ["headline", "initial-content", "read-only"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass(["grid gap-3 xl:gap-6", $props.isCreateMode ? 'mb-3 xl:grid-cols-4' : 'my-5 xl:grid-cols-3'])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["min-w-220px", {
      'xl:col-span-2': $props.isCreateMode
    }])
  }, [_createVNode(_component_date_time_picker, {
    modelValue: $options.incidentOccurred,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $options.incidentOccurred = $event;
    }),
    title: $data.translate.incidentOccurred,
    disabled: $props.readOnly,
    "delete-enabled": true,
    class: "w-full"
  }, null, 8 /* PROPS */, ["modelValue", "title", "disabled"])], 2 /* CLASS */), _ctx.severity !== null && _ctx.severity !== undefined && $options.isFieldInOptional('severity') ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_traffic_light_selector, {
    modelValue: _ctx.severity,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return _ctx.severity = $event;
    }),
    "read-only": $props.readOnly,
    "light-type": $data.moduleName,
    title: $data.translate.severity
  }, null, 8 /* PROPS */, ["modelValue", "read-only", "light-type", "title"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass(["relative min-w-180px w-full mr-5", $props.isCreateMode ? 'mb-3' : 'mb-5'])
  }, [$options.showStatusSelector ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    modelValue: $options.workflowStatusId,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $options.workflowStatusId = $event;
    }),
    title: $data.translate.status,
    disabled: $props.readOnly,
    "un-sorted": true,
    options: $options.stateList
  }, null, 8 /* PROPS */, ["modelValue", "title", "disabled", "options"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)], 2 /* CLASS */), $props.isCreateMode ? (_openBlock(), _createBlock(_component_frameworks_selector, {
    key: 5,
    "selected-options": $data.frameworks || [],
    onSelected: $options.onFrameworksChange,
    onOpen: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('open');
    }),
    onClose: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.$emit('close');
    })
  }, null, 8 /* PROPS */, ["selected-options", "onSelected"])) : _createCommentVNode("v-if", true), _ctx.activityTypes.length && $props.data.activityTypeId ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_activity_custom_fields, {
    "activity-type": $options.currentActivityType,
    "custom-field-values": $props.data.customFieldValues,
    disabled: $props.readOnly,
    "list-of-disabled-fields": $options.disabledCustomFields,
    "list-of-hidden-fields": $options.listOfHiddenFields,
    onChanged: _cache[8] || (_cache[8] = function ($event) {
      return _ctx.$emit('propertyChanged', {
        property: 'customFieldValuesParsed',
        value: $event
      });
    })
  }, null, 8 /* PROPS */, ["activity-type", "custom-field-values", "disabled", "list-of-disabled-fields", "list-of-hidden-fields"])])) : _createCommentVNode("v-if", true), (_$props$data$question = $props.data.questionnaires) !== null && _$props$data$question !== void 0 && _$props$data$question.length ? (_openBlock(), _createBlock(_component_questionnaire_stats, {
    key: 7,
    questionnaires: $props.data.questionnaires
  }, null, 8 /* PROPS */, ["questionnaires"])) : _createCommentVNode("v-if", true)]);
}