import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'CollapsiblePanel',
  introduction: 'The collapsible-panel component is useful for reducing vertical space with large amounts of information.',
  description: 'Use templates with v-slot:header and v-slot:content',
  token: '<collapsible-panel>{slot:header}{slot:content}</collapsible-panel>',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    localStorageKey: {
      required: false,
      type: String,
      default: '',
      note: 'locale storage key to set element after mounted'
    },
    overflowVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['collapseChanged'],
  setup: function setup(props) {
    return {
      isExpanded: getLocalStorageComputed(props.localStorageKey, false, true)
    };
  },
  methods: {
    startTransition: function startTransition(el) {
      el.style.height = el.scrollHeight + 'px';
      el.style.overflow = 'hidden';
    },
    endTransition: function endTransition(el) {
      el.style.height = '';
      el.style.removeProperty('overflow');
    },
    toggleCollapse: function toggleCollapse() {
      if (this.disabled) return;
      this.isExpanded = !this.isExpanded;
      this.$emit('collapseChanged', this.isExpanded);
    },
    setIsExpanded: function setIsExpanded(value) {
      this.isExpanded = value;
    }
  }
};