function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { MODULES } from '@/constants/modules';
var projects = {
  compliance: [{
    id: 1,
    access_level: 4,
    canCreate: true,
    show_gap: true,
    supervisor: 0,
    locked: false
  }, {
    id: 11,
    access_level: 4,
    canCreate: true,
    show_gap: true,
    supervisor: 0,
    locked: false
  }, {
    id: 19,
    access_level: 4,
    canCreate: true,
    show_gap: true,
    supervisor: 0,
    locked: false
  }, {
    id: 20,
    access_level: 4,
    canCreate: true,
    show_gap: true,
    supervisor: 0,
    locked: false
  }],
  risk: [{
    id: 1,
    access_level: 4,
    canCreate: true,
    locked: false
  }, {
    id: 2,
    access_level: 4,
    canCreate: true,
    locked: false
  }, {
    id: 3,
    access_level: 4,
    canCreate: true,
    locked: false
  }]
};
export var adminUser = {
  objType: 'user',
  id: 1,
  username: 'ADM',
  initials: 'ADM',
  fullname: 'Test Site',
  show: 'username',
  display_name: 'ADM',
  email: 'admSome@bigGDRPSystems.com',
  level_execution: 4,
  active: 1,
  level_admin: 1,
  level_controls: 4,
  level_risk: 4,
  level_incidents: 4,
  level_contracts: 4,
  level_company: 4,
  level_information_assets: 4,
  level_process_library: 4,
  is_admin: true,
  lang: 'en_GB',
  projects: projects,
  iaAccess: true,
  level_e_locked: 0,
  level_r_locked: 0,
  level_c_locked: 0,
  level_incidents_locked: 0,
  level_level_process_library_locked: 0,
  userHasProfileImage: false,
  performance_report_access: 0,
  AccessLevels: {
    PolicyManagement: {
      title: 'Policy Management',
      level: 4,
      locked: false,
      adminAccess: true,
      canCreate: true,
      canDelete: true
    },
    ContractManagement: {
      title: 'Contract Management',
      level: 4,
      locked: false,
      adminAccess: true,
      canCreate: true,
      canDelete: true
    },
    InformationAssets: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, MODULES.COMPLIANCE_IA_SYSTEM, {
      title: 'Information Assets',
      level: 4,
      locked: false,
      adminAccess: true,
      canCreate: true,
      canDelete: true
    }), MODULES.COMPLIANCE_IA_PROCESSOR, {
      title: 'Information Assets',
      level: 4,
      locked: false,
      adminAccess: true,
      canCreate: true,
      canDelete: true
    }), MODULES.COMPLIANCE_IA_CONTROLLER, {
      title: 'Information Assets',
      level: 4,
      locked: false,
      adminAccess: true,
      canCreate: true,
      canDelete: true
    }), MODULES.COMPLIANCE_INFORMATION_ASSETS, {
      42: {
        title: 'Information Assets',
        level: 4,
        locked: false,
        adminAccess: true,
        canCreate: true,
        canDelete: true
      }
    }),
    Controls: {
      title: 'Controls',
      level: 4,
      locked: false,
      adminAccess: true,
      canCreate: true,
      canDelete: true,
      canCreateMotherControl: true
    }
  }
};
export var adminUserWithRisksProjects = _objectSpread(_objectSpread({}, adminUser), {}, {
  projects: projects
});
export var regularUser = {
  iaAccess: false
};
export var singleItem = {
  active: true,
  objType: 'user',
  id: 45,
  username: 'AAN',
  initials: 'AAN',
  fullname: 'Arne Andersen',
  display_name: 'AAN',
  email: 'aan2@risma.dk',
  level_execution: 1,
  level_controls: 1,
  level_incidents: 1,
  level_risk: 1,
  is_admin: false,
  lang: null,
  orgs: ',179,',
  organisations: [179],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
};
export var itemList = [{
  active: true,
  objType: 'user',
  id: 45,
  username: 'AAN',
  initials: 'AAN',
  fullname: 'Arne Andersen',
  display_name: 'AAN',
  email: 'aan2@risma.dk',
  level_execution: 1,
  level_controls: 1,
  level_incidents: 1,
  level_risk: 1,
  level_process_library: 0,
  is_admin: false,
  iaAccess: true,
  lang: null,
  orgs: ',179,',
  organisations: [179],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1,
  username: 'ADM',
  initials: 'NAS',
  fullname: 'BusinessDemo Template',
  display_name: 'ADM',
  email: 'template@rismasystems.com',
  level_execution: 4,
  level_controls: 4,
  level_incidents: 4,
  level_risk: 4,
  level_process_library: 4,
  is_admin: true,
  iaAccess: true,
  lang: 'en_GB',
  orgs: ',197,',
  organisations: [197],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: true
}, {
  active: true,
  objType: 'user',
  id: 1000,
  username: 'ADMOLD',
  initials: 'ADM_OLD',
  fullname: 'Demo Administrator X',
  display_name: 'ADMOLD',
  email: 'adm@rismasystems.com',
  level_execution: 4,
  level_controls: 4,
  level_risk: 4,
  level_process_library: 4,
  is_admin: true,
  iaAccess: true,
  lang: 'da_DK',
  orgs: ',172,181,189,',
  organisations: [172, 181, 189],
  organisationsReadonly: [178, 194],
  projects: projects,
  policiesAccess: true
}, {
  active: true,
  objType: 'user',
  id: 43,
  username: 'ANM',
  initials: 'ANM',
  fullname: 'Anders Madsen',
  display_name: 'ANM',
  email: 'anm2@risma.dk',
  level_execution: 2,
  level_controls: 0,
  level_risk: 0,
  level_process_library: 0,
  is_admin: false,
  iaAccess: true,
  lang: null,
  orgs: ',59,',
  organisations: [59],
  organisationsReadonly: [170],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 44,
  username: 'BBI',
  initials: 'BBI',
  fullname: 'Bjarne Binderkranz',
  display_name: 'BBI',
  email: 'bbi2@risma.dk',
  level_execution: 2,
  level_controls: 0,
  level_risk: 2,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',173,',
  organisations: [173],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 46,
  username: 'CCA',
  initials: 'CCA',
  fullname: 'Claus Carlsen',
  display_name: 'CCA',
  email: 'cca2@risma.dk',
  level_execution: 2,
  level_controls: 2,
  level_risk: 2,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',172,',
  organisations: [172],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 42,
  username: 'CEO',
  initials: 'CEO',
  fullname: 'Carl Erik Olsen',
  display_name: 'CEO',
  email: 'ceo2@risma.dk',
  level_execution: 1,
  level_controls: 1,
  level_risk: 1,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',53,',
  organisations: [53],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 58,
  username: 'CFO',
  initials: 'CFO',
  fullname: 'Christina Forchhammer',
  display_name: 'CFO',
  email: 'cfo2@risma.dk',
  level_execution: 0,
  level_controls: 0,
  level_risk: 3,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',171,',
  organisations: [171],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 49,
  username: 'CPO',
  initials: 'CPO',
  fullname: 'Christian Poulsen',
  display_name: 'CPO',
  email: 'cpo2@risma.dk',
  level_execution: 2,
  level_controls: 3,
  level_risk: 2,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',180,',
  organisations: [180],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1439,
  username: 'CRNtest',
  initials: 'XYZ',
  fullname: 'CRN test',
  display_name: 'CRNtest',
  email: 'crntest@risma.dk',
  level_execution: 1,
  level_controls: 1,
  level_risk: 1,
  level_process_library: 0,
  is_admin: false,
  lang: 'da_DK',
  orgs: ',,',
  organisations: [],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1164,
  username: 'DBL',
  initials: 'DBL',
  fullname: 'Diego',
  display_name: 'DBL',
  email: 'dbl@rismasystems.com',
  level_execution: 4,
  level_controls: 4,
  level_risk: 4,
  level_process_library: 4,
  is_admin: true,
  lang: null,
  orgs: ',172,',
  organisations: [172],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: true
}, {
  active: true,
  objType: 'user',
  id: 47,
  username: 'DDI',
  initials: 'DDI',
  fullname: 'Dorthe Didriksen',
  display_name: 'DDI',
  email: 'ddi2@risma.dk',
  level_execution: 2,
  level_controls: 1,
  level_risk: 2,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',177,',
  organisations: [177],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1485,
  username: 'ENG',
  initials: '',
  fullname: 'Eng sprog',
  display_name: 'ENG',
  email: 'eng@risma.com',
  level_execution: 2,
  level_controls: 2,
  level_risk: 2,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',,',
  organisations: [],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1486,
  username: 'ENG1',
  initials: '',
  fullname: 'xxx',
  display_name: 'ENG1',
  email: 'xc@risma.dk',
  level_execution: 2,
  level_controls: 2,
  level_risk: 2,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',,',
  organisations: [],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 50,
  username: 'GOG',
  initials: 'GOG',
  fullname: 'Gorm Gregersen',
  display_name: 'GOG',
  email: 'gog2@rismasystems.com',
  level_execution: 0,
  level_controls: 1,
  level_risk: 0,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',181,',
  organisations: [181],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1479,
  username: 'GOG12',
  initials: 'GOG12',
  fullname: 'Gitte Olsen Gardner',
  display_name: 'GOG12',
  email: 'GOG1@risma.dk',
  level_execution: 0,
  level_controls: 3,
  level_risk: 0,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',173,',
  organisations: [173],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 51,
  username: 'HAH',
  initials: 'HAH',
  fullname: 'Hanne Hansen',
  display_name: 'HAH',
  email: 'hah2@risma.dk',
  level_execution: 2,
  level_controls: 0,
  level_risk: 1,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',171,',
  organisations: [171],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 52,
  username: 'JOJ',
  initials: 'JOJ',
  fullname: 'Joachim Jønsson',
  display_name: 'JOJ',
  email: 'joj2@risma.dk',
  level_execution: 1,
  level_controls: 1,
  level_risk: 1,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',170,',
  organisations: [170],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 53,
  username: 'KAB',
  initials: 'KAB',
  fullname: 'Kasper',
  display_name: 'KAB',
  email: 'risma@rismasystems.com',
  level_execution: 4,
  level_controls: 4,
  level_risk: 4,
  level_process_library: 1,
  is_admin: true,
  lang: 'en_GB',
  orgs: ',186,',
  organisations: [186],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: true
}, {
  active: true,
  objType: 'user',
  id: 54,
  username: 'LAL',
  initials: 'LAL',
  fullname: 'Lauritz Larsen',
  display_name: 'LAL',
  email: 'lal2@risma.dk',
  level_execution: 1,
  level_controls: 0,
  level_risk: 0,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',60,',
  organisations: [60],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1491,
  username: 'NASTEST',
  initials: '',
  fullname: 'Nicolai test opret 2',
  display_name: 'NASTEST',
  email: 'nnn@risma.dk',
  level_execution: 3,
  level_controls: 3,
  level_risk: 3,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',,',
  organisations: [],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1490,
  username: 'NASTEST3',
  initials: '',
  fullname: 'Nicolai test opret',
  display_name: 'NASTEST3',
  email: 'nnn3@risma.dk',
  level_execution: 4,
  level_controls: 4,
  level_risk: 4,
  level_process_library: 4,
  is_admin: true,
  lang: null,
  orgs: ',177,',
  organisations: [177],
  organisationsReadonly: [61],
  projects: projects,
  policiesAccess: true
}, {
  active: true,
  objType: 'user',
  id: 55,
  username: 'NIN',
  initials: 'NIN',
  fullname: 'Niels Normann',
  display_name: 'NIN',
  email: 'nin2@risma.dk',
  level_execution: 1,
  level_controls: 1,
  level_risk: 1,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',182,',
  organisations: [182],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 60,
  username: 'Nicolaitest æøå',
  initials: 'IVI',
  fullname: 'Ivan Iversen',
  display_name: 'Nicolaitest æøå',
  email: 'ivi@risma.dk',
  level_execution: 2,
  level_controls: 1,
  level_risk: 1,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',172,',
  organisations: [172],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 56,
  username: 'ORO',
  initials: 'ORO',
  fullname: 'Orla Olsen',
  display_name: 'ORO',
  email: 'oro2@risma.dk',
  level_execution: 3,
  level_controls: 0,
  level_risk: 0,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',178,',
  organisations: [178],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 57,
  username: 'PEP',
  initials: 'PEP',
  fullname: 'Peter Povlsen',
  display_name: 'PEP',
  email: 'pep2@risma.dk',
  level_execution: 2,
  level_controls: 0,
  level_risk: 0,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',171,',
  organisations: [171],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 39,
  username: 'PRI',
  initials: 'PRI',
  fullname: 'Demo Privileged User',
  display_name: 'PRI',
  email: 'pri2@rismasystems.com',
  level_execution: 2,
  level_controls: 2,
  level_risk: 2,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',197,',
  organisations: [197],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1478,
  username: 'PRIL',
  initials: 'PRIL',
  fullname: 'Låst privilegeret bruger',
  display_name: 'PRIL',
  email: 'PRIL@risma.dk',
  level_execution: 2,
  level_controls: 2,
  level_risk: 2,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',,',
  organisations: [],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 38,
  username: 'REG',
  initials: 'REG',
  fullname: 'Demo Regular User',
  display_name: 'REG',
  email: 'reg@rismasystems.com',
  level_execution: 1,
  level_controls: 1,
  level_risk: 1,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',53,197,',
  organisations: [53, 197],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1109,
  username: 'REGL',
  initials: 'REGL',
  fullname: 'Regular User L',
  display_name: 'REGL',
  email: 'regl@risma.dk',
  level_execution: 1,
  level_controls: 1,
  level_risk: 1,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',,',
  organisations: [],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1157,
  username: 'Riskbruger',
  initials: 'Riskbruger',
  fullname: 'Risk Bruger',
  display_name: 'Riskbruger',
  email: 'riskbruger@risma.dk',
  level_execution: 0,
  level_controls: 0,
  level_risk: 3,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',170,',
  organisations: [170],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 40,
  username: 'SUP',
  initials: 'SUP',
  fullname: 'Demo Super User',
  display_name: 'SUP',
  email: 'sup2@rismasystems.com',
  level_execution: 3,
  level_controls: 3,
  level_risk: 3,
  level_process_library: 1,
  is_admin: false,
  lang: 'en_GB',
  orgs: ',53,197,',
  organisations: [53, 197],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: true
}, {
  active: true,
  objType: 'user',
  id: 1477,
  username: 'SUPL',
  initials: 'SUPL',
  fullname: 'Låst superbruger',
  display_name: 'SUPL',
  email: 'SUPL@risma.dk',
  level_execution: 3,
  level_controls: 3,
  level_risk: 3,
  level_process_library: 4,
  is_admin: false,
  lang: 'da_DK',
  orgs: ',,',
  organisations: [],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: true
}, {
  active: true,
  objType: 'user',
  id: 59,
  username: 'SUS',
  initials: 'SUS',
  fullname: 'Susse Skougaard',
  display_name: 'SUS',
  email: 'sus2@risma.dk',
  level_execution: 0,
  level_controls: 0,
  level_risk: 0,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',176,',
  organisations: [176],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1482,
  username: 'TO',
  initials: '',
  fullname: 'Teser organisation',
  display_name: 'TO',
  email: 'test@risma.sk',
  level_execution: 2,
  level_controls: 2,
  level_risk: 2,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',197,',
  organisations: [197],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1438,
  username: 'Test',
  initials: 'Test',
  fullname: 'Test',
  display_name: 'Test',
  email: 'test11@risma.dk',
  level_execution: 2,
  level_controls: 1,
  level_risk: 1,
  level_process_library: 0,
  is_admin: false,
  lang: 'da_DK',
  orgs: ',,',
  organisations: [],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1475,
  username: 'Test1',
  initials: 'Test1',
  fullname: 'Test bruger 1',
  display_name: 'Test1',
  email: 'test1@risma.dk',
  level_execution: 3,
  level_controls: 3,
  level_risk: 3,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',181,182,183,',
  organisations: [181, 182, 183],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1474,
  username: 'Testbruger',
  initials: 'Testbruger',
  fullname: 'Testbruger',
  display_name: 'Testbruger',
  email: 'testtest@risma.dk',
  level_execution: 3,
  level_controls: 3,
  level_risk: 3,
  level_process_library: 0,
  is_admin: false,
  lang: 'da_DK',
  orgs: ',,',
  organisations: [],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1165,
  username: 'USR',
  initials: 'USR',
  fullname: 'User Swapper',
  display_name: 'USR',
  email: 'sutmin@lige.nu',
  level_execution: 1,
  level_controls: 1,
  level_risk: 1,
  level_process_library: 1,
  is_admin: false,
  lang: null,
  orgs: ',59,',
  organisations: [59],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: true
}, {
  active: true,
  objType: 'user',
  id: 48,
  username: 'controlsbruger',
  initials: 'controlsbruger',
  fullname: 'Carl Christian Olesen',
  display_name: 'controlsbruger',
  email: 'cco2@risma.dk',
  level_execution: 0,
  level_controls: 2,
  level_risk: 0,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',61,',
  organisations: [61],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1441,
  username: 'dansk',
  initials: 'dansk',
  fullname: 'dansk',
  display_name: 'dansk',
  email: 'dansk@rismasytems.com',
  level_execution: 1,
  level_controls: 1,
  level_risk: 1,
  level_process_library: 1,
  is_admin: false,
  lang: 'da_DK',
  orgs: ',,',
  organisations: [],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: true
}, {
  active: true,
  objType: 'user',
  id: 1476,
  username: 'exebruger',
  initials: 'exebruger',
  fullname: 'Execution bruger',
  display_name: 'exebruger',
  email: 'exec@risma.dk',
  level_execution: 1,
  level_controls: 0,
  level_risk: 0,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',,',
  organisations: [],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1440,
  username: 'sprog',
  initials: 'sprog',
  fullname: 'sprogtest',
  display_name: 'sprog',
  email: 'testa@rimasystems.com',
  level_execution: 1,
  level_controls: 1,
  level_risk: 1,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',,',
  organisations: [],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}, {
  active: true,
  objType: 'user',
  id: 1437,
  username: 'ÆØÅ',
  initials: 'ÆØÅ',
  fullname: 'ÆØÅ',
  display_name: 'ÆØÅ',
  email: 'aeoeaa@risma.dk',
  level_execution: 1,
  level_controls: 1,
  level_risk: 1,
  level_process_library: 0,
  is_admin: false,
  lang: null,
  orgs: ',,',
  organisations: [],
  organisationsReadonly: [],
  projects: projects,
  policiesAccess: false
}];
export var mockUsers = [{
  id: 1,
  label: 'User A'
}, {
  id: 2,
  label: 'User B'
}, {
  id: 3,
  label: 'User C'
}, {
  id: 4,
  label: 'User D'
}, {
  id: 5,
  label: 'User E'
}, {
  id: 6,
  label: 'User F'
}, {
  id: 7,
  label: 'User G'
}, {
  id: 8,
  label: 'User H'
}, {
  id: 9,
  label: 'User I'
}, {
  id: 10,
  label: 'User A1'
}, {
  id: 11,
  label: 'User B2'
}, {
  id: 12,
  label: 'User C3'
}, {
  id: 13,
  label: 'User D4'
}, {
  id: 14,
  label: 'User E5'
}, {
  id: 15,
  label: 'User F6'
}, {
  id: 16,
  label: 'User G7'
}, {
  id: 17,
  label: 'User H8'
}, {
  id: 18,
  label: 'User I9'
}];