function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { useFrameworksStore } from '@/Store/frameworksStore';
import * as Utils from '@/utils/Utils';
import { comparePathStrings, compareDates, compareNumbers } from '@/utils/compare';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { sortByIndex } from '@/utils/sort';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { ControlObjectProps } from '@/constants/ControlsReportProps';
import { DateFormats } from '@/constants/DateFormats';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { MODULES } from '@/constants/modules';
import { TabTypes } from '@/constants/Tabs';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { isDateValid, getEndOfDateUnitFormatted, getStartOfDateUnitFormatted, prepareDateTimezone, toDateTimeFormatWithTimezone } from '@/utils/date';
var Protocols = {
  HTTP: 'http://',
  HTTPS: 'https://'
};
var CONTROL_REVIEW_ACTIONS = {
  rejected: 'rejected'
};
export var ControlReportsMixin = {
  mixins: [SaveReportMixin],
  data: function data() {
    return {
      isReportSaved: false,
      dataset: [],
      initRange: {
        start: null,
        end: null
      },
      deadlineBefore: null,
      deadlineAfter: null,
      storedRange: null,
      columnFiltersSorting: [{
        key: 'cpath',
        sortingFunc: this.sortingByCpath
      }, {
        key: ControlObjectProps.MOTHERCONTROL_CPATH,
        sortingFunc: compareNumbers
      }],
      columnSorting: [{
        key: 'cpath',
        sortingFunc: this.sortingByCpathObject
      }],
      completedReviewActions: ['completed', 'partially_completed']
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, ['users'])), mapState(useOrganisationsStore, ['organisations'])), mapState(useTagsStore, ['tags'])), mapState(useActivityTypesStore, {
    activityTypes: 'controlActivityTypes'
  })), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })),
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getControlTrafficLightByNumber'])), {}, {
    previewModalDismissed: function previewModalDismissed(isUpdated) {
      if (!isUpdated) return;
      this.refreshTable();
    },
    getDateRange: function getDateRange() {
      this.storedRange = localStorage.getItem(this.localStorageRangeKey);
      var initRangeStartDefault = getStartOfDateUnitFormatted('month', DateFormats.DATE_TIME_FORMAT_FULL);
      var initRangeEndDefault = getEndOfDateUnitFormatted('month', DateFormats.DATE_TIME_FORMAT_FULL);
      var rangeValues = this.storedRange ? this.storedRange.split(' - ') : [];

      // Default (current month)
      if (rangeValues.length === 2 && isDateValid(rangeValues[0]) && isDateValid(rangeValues[1])) {
        this.deadlineAfter = rangeValues[0];
        this.deadlineBefore = rangeValues[1];
      } else {
        this.deadlineAfter = initRangeStartDefault;
        this.deadlineBefore = initRangeEndDefault;
      }
      this.initRange.start = this.deadlineAfter;
      this.initRange.end = this.deadlineBefore;
    },
    changedDates: function changedDates(e) {
      if (e) {
        this.refreshTable();
      }
    },
    initDateRange: function initDateRange() {
      var _this$$route$query;
      var query = (_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.query;
      if (query) {
        this.storeDateRangeInLocalStorage(JSON.parse(query));
      }
      this.getDateRange();
    },
    storeDateRangeInLocalStorage: function storeDateRangeInLocalStorage(data) {
      var _data$query;
      var dateRange = (_data$query = data.query) === null || _data$query === void 0 || (_data$query = _data$query.reportOptions) === null || _data$query === void 0 ? void 0 : _data$query.dateRange.join(' - ');
      localStorage.setItem(this.localStorageRangeKey, dateRange);
    },
    onLoadReport: function onLoadReport(data) {
      this.applySettingsFromLoadedReport(data);
    },
    loadDataFromQuery: function loadDataFromQuery() {
      var applyDateRangeFromQuery = false;
      this.loadSavedReport(applyDateRangeFromQuery);
    },
    prepareItemBaseFields: function prepareItemBaseFields(item) {
      var resultFields = {};
      for (var key in this.reportFields) {
        var value = Utils.getDeep(item, key);
        var result = value ? value : '';
        var isCompletedAfterDeadline = false;
        switch (key) {
          case ControlObjectProps.TRAFFIC_LIGHT:
            isCompletedAfterDeadline = item.completed && compareDates(item.completed, item.deadline) === 1;
            result = item.isMothercontrol ? {
              field: ''
            } : this.prepareTrafficLightField(item.trafficLight, isCompletedAfterDeadline);
            break;
          case ControlObjectProps.COMPLETED_EFFICIENCY:
            if (item.completedEfficiency !== null && item.completedEfficiency !== undefined) result = this.prepareTrafficLightField(item.completedEfficiency);
            break;
          case ControlObjectProps.REJECTED_REVIEWS:
            result = this.prepareRejections(item.reviews);
            break;
          case ControlObjectProps.TITLE:
            result = this.prepareTitleField(item);
            break;
          case ControlObjectProps.STATUS:
            result = this.prepareStatusField(item);
            break;
          case ControlObjectProps.KEY_CONTROL:
            result = {
              field: value ? this.translate.yes : this.translate.no
            };
            break;
          case ControlObjectProps.KEY_AUTOMATIC_MANUAL:
            result = {
              field: value ? this.translate.automatic : this.translate.manual
            };
            break;
          case ControlObjectProps.KEY_PREVENTIVE_DETECTIVE:
            result = {
              field: value ? this.translate.preventive : this.translate.detective
            };
            break;
          case ControlObjectProps.KEY_HIGH_LOW_COMPLEXITY:
            result = {
              field: value ? this.translate.high : this.translate.low
            };
            break;
          case ControlObjectProps.CONFIDENTIAL:
            result = {
              field: item.confidential ? this.translate.yes : this.translate.no
            };
            break;
          case ControlObjectProps.FREQUENCY:
            result = {
              field: this.$trans(RismaControlsUtils.frequency2text(item.frequency))
            };
            break;
          case ControlObjectProps.USER_IDS_RESPONSIBLE:
          case ControlObjectProps.USER_IDS_REVIEW:
          case ControlObjectProps.USER_IDS_ESCALATION:
            result = this.prepareUsersField(result);
            break;
          case ControlObjectProps.REVIEW_USER_ID:
            result = this.prepareReviewUserIdField(item);
            break;
          case ControlObjectProps.ACTIVITY_TYPE_ID:
            result = this.prepareActivityTypeField(result);
            break;
          case ControlObjectProps.ORGANISATION_IDS:
            result = {
              field: Utils.findPropsByIds(result, this.organisations, 'visible_name', '; ')
            };
            break;
          case ControlObjectProps.ORGANISATION_GROUPS:
            result = this.prepareOrganisationGroupsField(item);
            break;
          case ControlObjectProps.DAILY_TEAM_IDS:
            result = this.prepareDailyTeamField(item, key);
            break;
          case ControlObjectProps.DEADLINE:
            result = {
              field: toDateTimeFormatWithTimezone(result, item.deadlineTimezone, DateFormats.DATE_TIME_FORMAT)
            };
            break;
          case ControlObjectProps.COMPLETED:
            result = this.prepareCompletedDateField(item);
            break;
          case ControlObjectProps.COMPLETED_USER_ID:
            result = this.prepareCompletedUserIdField(item);
            break;
          case ControlObjectProps.COMPLETED_COMMENTS:
            result = this.prepareCompletedCommentsField(item);
            break;
          case ControlObjectProps.COMPLETED_FILE_NAME:
            result = this.prepareCompletedUrlField(item);
            break;
          case ControlObjectProps.REVIEW_DEADLINE:
            result = this.prepareReviewDeadlineField(item);
            break;
          case ControlObjectProps.REVIEW_APPROVED:
            result = this.prepareReviewApprovedField(item);
            break;
          case ControlObjectProps.REVIEW_COMMENTS:
            result = this.prepareReviewCommentsField(item);
            break;
          case ControlObjectProps.REVIEW_URL:
            result = this.prepareReviewUrlField(item);
            break;
          case ControlObjectProps.ACCOUNTS:
          case ControlObjectProps.RISKS:
          case ControlObjectProps.PROCESSES:
            result = {
              field: Utils.findProps(result, 'title') || this.translate.none
            };
            break;
          case ControlObjectProps.TAGS:
            result = this.prepareTagField(item);
            break;
          case ControlObjectProps.FRAMEWORKS:
            result = Utils.findPropsByIdsSorted(item.solutionIds, this.frameworks, 'displayName');
            break;
          case ControlObjectProps.ATTACHMENTS:
          case ControlObjectProps.MOTHERCONTROL_ATTACHMENTS:
            result = this.prepareAttachmentsField(value);
            break;
          case ControlObjectProps.LINKED_ACTIVITIES_CONTROL:
          case ControlObjectProps.LINKED_ACTIVITIES_RISK:
          case ControlObjectProps.LINKED_ACTIVITIES_EXECUTION:
          case ControlObjectProps.LINKED_DATAPROCESSOR_PROCESSOR:
          case ControlObjectProps.LINKED_SYSTEM:
          case ControlObjectProps.LINKED_PROCESSOR_TREE_NODE:
            result = this.prepareLinkedActivitiesField(item, key);
            break;
        }
        resultFields[key] = _objectSpread({
          field: result
        }, _typeof(result) === 'object' && result !== null ? result : {});
      }
      return resultFields;
    },
    prepareItemCustomFields: function prepareItemCustomFields(item) {
      var resultFields = {};
      for (var key in this.customFields) {
        if (key.endsWith(this.customUnitPrefix.toLowerCase())) {
          resultFields[key] = {
            field: this.customFields[key].field
          };
          continue;
        }
        var result = this.prepareCustomField(item, key);
        resultFields[key] = _objectSpread({
          field: result
        }, result);
      }
      return resultFields;
    },
    prepareItemGapsFields: function prepareItemGapsFields(item) {
      var resultFields = {};
      for (var key in this.complianceGapsFields) {
        var result = this.prepareLinkedCompliances(item, key);
        resultFields[key] = _objectSpread({
          field: result
        }, result);
      }
      return resultFields;
    },
    prepareTitleField: function prepareTitleField(item) {
      return {
        activity: getFieldsFromObjectForPreview(item),
        isActive: item.active,
        fieldText: item.title,
        confidential: item.confidential || false,
        hoverTitle: DatatableRenderer.getItemHoverTitle(item)
      };
    },
    prepareTrafficLightField: function prepareTrafficLightField() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var useExtendedTraffics = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var field = this.getControlTrafficLightByNumber(data || 0, useExtendedTraffics);
      return {
        field: field,
        color: field.color,
        fieldText: field.legend
      };
    },
    prepareDailyTeamField: function prepareDailyTeamField(item, key) {
      var _item$key$users;
      var result = '',
        users = '';
      if (item[key] && item[key].organisations) {
        result = Utils.findPropsByIds(item[key].organisations, this.organisations, 'visible_name');
      }
      if (item[key] && (_item$key$users = item[key].users) !== null && _item$key$users !== void 0 && _item$key$users.length) {
        users = Utils.findPropsByIds(item[key].users, this.users, 'display_name');
        if (result) {
          result += ', ';
        }
        result += users;
      }
      return {
        field: result
      };
    },
    prepareStatusField: function prepareStatusField(item) {
      var result = this.$trans(RismaControlsUtils.state2text(item.rismaState));
      if (item.rismaState === RismaControlsUtils.states.STATE_COMPLETED && item.reviewStatus === 'partially_completed') {
        result = this.$trans('Completed with actions');
      }
      if (item.rismaState === RismaControlsUtils.states.STATE_PARTIALLY_READY_FOR_REVIEW || item.rismaState === RismaControlsUtils.states.STATE_PARTIALLY_REVIEW_REJECTED) {
        result += ' *';
      }
      return {
        field: result
      };
    },
    prepareUsersField: function prepareUsersField(userIds) {
      if (!userIds || !userIds.length) return {
        field: '',
        fieldText: ''
      };
      var userIdsModified = userIds.map(function (id) {
        return +id;
      });
      var filteredUsers = this.users.filter(function (user) {
        return userIdsModified.includes(+user.id);
      });
      var result = [];
      var allItemsText = [];
      filteredUsers.forEach(function (user) {
        user.active ? result.push(user.display_name) : result.push(Utils.prepareInactiveHtml(user.display_name));
        allItemsText.push(user.display_name);
      });
      return {
        field: result.join(', '),
        fieldText: allItemsText.join(', ')
      };
    },
    prepareCustomField: function prepareCustomField(item, key) {
      var _this$customFields, _item$customFields;
      var result = {
        field: '',
        fieldText: '',
        fieldType: ((_this$customFields = this.customFields) === null || _this$customFields === void 0 ? void 0 : _this$customFields[key].fieldType) || null
      };
      if (!((_item$customFields = item.customFields) !== null && _item$customFields !== void 0 && _item$customFields.length)) {
        return result;
      }
      var id = key.split('-')[1];
      var customField = item.customFields.find(function (cf) {
        return +cf.id === +id;
      });
      if (!customField) return result;
      this.prepareCustomFieldValue(result, item.customFieldValues, customField);
      return _objectSpread({}, result[key]);
    },
    prepareAttachmentsField: function prepareAttachmentsField(attachments) {
      if (!attachments || !attachments.length) {
        return {
          field: '',
          fieldText: ''
        };
      }
      var activities = '';
      var allItemsText = [];
      attachments.forEach(function (item) {
        activities += Utils.prepareLink(item.url, item.title || item.filename);
        allItemsText.push(item.title || item.filename);
      });
      return {
        field: activities,
        fieldText: allItemsText.join(', ')
      };
    },
    prepareTagField: function prepareTagField(item) {
      var tags = this.tags.filter(function (t) {
        return item.tagIds.includes(t.id);
      }).map(function (t) {
        return t.tag;
      }).join(', ');
      return {
        field: tags,
        fieldText: tags
      };
    },
    prepareActivityTypeField: function prepareActivityTypeField(activityTypeId) {
      var activityType = this.activityTypes.find(function (a) {
        return a.id === activityTypeId;
      });
      return {
        field: activityType ? activityType.label : ''
      };
    },
    prepareOrganisationGroupsField: function prepareOrganisationGroupsField(data) {
      if (!data.organisationGroups || !data.organisationGroups.length) return {
        field: ''
      };
      return {
        field: data.organisationGroups.map(function (group) {
          return group.label;
        }).join('; ')
      };
    },
    prepareCompletedDateField: function prepareCompletedDateField(item) {
      var createdAt = this.getCompletedLastField(item, 'created') || item.completed || '';
      return {
        field: toDateTimeFormatWithTimezone(createdAt, item.deadlineTimezone, DateFormats.DATE_TIME_FORMAT)
      };
    },
    prepareCompletedUserIdField: function prepareCompletedUserIdField(item) {
      var userId = this.getCompletedLastField(item, 'userId') || item.completedUserid;
      return this.prepareUsersField([userId]);
    },
    prepareCompletedCommentsField: function prepareCompletedCommentsField(item) {
      var completeComment = this.getCompletedLastField(item, 'comment') || item.completedComments || '';
      return {
        field: completeComment,
        fieldText: completeComment
      };
    },
    prepareCompletedUrlField: function prepareCompletedUrlField(item) {
      var _this = this;
      var files = item.completedFiles;
      var allItemsText = [];
      var result = '';
      var isUrlFilled = item.completedUrl && item.completedUrl !== Protocols.HTTP && item.completedUrl !== Protocols.HTTPS;
      if (!(files !== null && files !== void 0 && files.length) && !isUrlFilled) {
        return {
          field: '',
          fieldText: ''
        };
      }
      if (isUrlFilled) {
        result += Utils.prepareLink(item.completedUrl, item.completedUrlTitle || item.completedUrl);
        allItemsText.push(item.completedUrlTitle || item.completedUrl);
      }
      if (files && files.length) {
        files.forEach(function (file) {
          result += Utils.prepareLink(_this.prepareAttachmentUrl(file.id), file.name);
          allItemsText.push(file.name);
        });
      }
      return {
        field: result,
        fieldText: allItemsText.join(', ')
      };
    },
    prepareLinkedCompliances: function prepareLinkedCompliances(item, key) {
      var _this2 = this;
      var projectId = key.split('-')[1];
      var result = {
        activities: [],
        fieldText: '',
        id: item.id
      };
      if (!item.linkedCompliances) return result;
      var compliancesByProject = item === null || item === void 0 ? void 0 : item.linkedCompliances.filter(function (item) {
        return +item.compliance.projectId === +projectId;
      });
      if (!compliancesByProject || !compliancesByProject.length) return result;
      compliancesByProject.forEach(function (item) {
        var node = item.compliance;
        var article = item.compliance.article;
        if (article.hidden) return;
        var gapAnalysis = {
          id: "".concat(node.nodeId, "_").concat(article.id),
          title: article.article + article.articleTitle,
          url: _this2.getLinkToNodeGapTab(projectId, node.nodeId, article.id),
          objType: ObjectTypes.COMPLIANCE_GAP_ANALYSIS,
          compliance_projects_id: +projectId,
          nodeId: node.nodeId,
          route: {
            tab: TabTypes.GAPANALYSIS,
            id: article.id
          }
        };
        result.activities.push({
          activity: gapAnalysis,
          fieldText: gapAnalysis.title
        });
      });
      result.fieldText = result.activities.map(function (activity) {
        return activity.fieldText;
      }).join(', ');
      return result;
    },
    //review fields
    prepareReviewApprovedField: function prepareReviewApprovedField(item) {
      if (item.rismaState === RismaControlsUtils.states.STATE_REVIEW_NOT_PERFORMED) {
        return {
          fieldText: '',
          field: ''
        };
      }
      var createdAt = this.getReviewLastField(item, 'created') || item.reviewApproved || '';
      return {
        field: prepareDateTimezone(createdAt, item.deadlineTimezone, DateFormats.DATE_TIME_FORMAT)
      };
    },
    prepareReviewUserIdField: function prepareReviewUserIdField(item) {
      if (item.rismaState === RismaControlsUtils.states.STATE_REVIEW_NOT_PERFORMED) {
        return {
          fieldText: '',
          field: ''
        };
      }
      var userId = this.getReviewLastField(item, 'userId') || item.reviewUserId || '';
      return this.prepareUsersField([userId]);
    },
    prepareReviewUrlField: function prepareReviewUrlField(item) {
      var _this3 = this;
      var lastReview = this.getReviewLastField(item);
      var allItemsText = [];
      var result = '';
      if (!lastReview) {
        return {
          field: '',
          fieldText: ''
        };
      }
      if (lastReview.url && lastReview.url !== Protocols.HTTP && lastReview.url !== Protocols.HTTPS) {
        result += Utils.prepareLink(lastReview.url, lastReview.urlTitle || lastReview.url);
        allItemsText.push(lastReview.urlTitle || lastReview.url);
      }
      if (lastReview.files && lastReview.files.length) {
        lastReview.files.forEach(function (file) {
          result += Utils.prepareLink(_this3.prepareAttachmentUrl(file.id), file.name);
          allItemsText.push(file.name);
        });
      }
      return {
        field: result,
        fieldText: allItemsText.join(', ')
      };
    },
    prepareReviewCommentsField: function prepareReviewCommentsField(item) {
      if (item.rismaState === RismaControlsUtils.states.STATE_REVIEW_NOT_PERFORMED) {
        return {
          field: '',
          fieldText: ''
        };
      }
      var comment = this.getReviewLastField(item, 'comment') || '';
      return {
        field: comment,
        fieldText: comment
      };
    },
    prepareReviewDeadlineField: function prepareReviewDeadlineField(item) {
      var reviewDate = this.getReviewLastField(item, 'reviewDeadline') || item.reviewDeadline || '';
      var result = toDateTimeFormatWithTimezone(reviewDate, item.deadlineTimezone, DateFormats.DATE_TIME_FORMAT);
      return {
        field: result,
        fieldText: result
      };
    },
    //general
    prepareLinkedActivitiesField: function prepareLinkedActivitiesField(item, key) {
      var result = {
        activities: []
      };
      if (!Object.keys(item.links).length) return result;
      switch (key) {
        case ControlObjectProps.LINKED_ACTIVITIES_EXECUTION:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links[MODULES.EXECUTION], this.initiatives, 'path')), {}, {
            id: item.id
          });
          break;
        case ControlObjectProps.LINKED_ACTIVITIES_CONTROL:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links[MODULES.CONTROLS], this.allControls, 'path')), {}, {
            id: item.id
          });
          break;
        case ControlObjectProps.LINKED_ACTIVITIES_RISK:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links[MODULES.RISK], this.risks, 'rno', this.getRiskValues)), {}, {
            id: item.id
          });
          break;
        case ControlObjectProps.LINKED_DATAPROCESSOR_PROCESSOR:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links[MODULES.COMPLIANCE_IA_PROCESSOR], this.iaDataProcessors)), {}, {
            id: item.id
          });
          break;
        case ControlObjectProps.LINKED_SYSTEM:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links[MODULES.COMPLIANCE_IA_SYSTEM], this.systems)), {}, {
            id: item.id
          });
          break;
        case ControlObjectProps.LINKED_PROCESSOR_TREE_NODE:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links[MODULES.COMPLIANCE_PROCESS_TREE_NODE], this.processLibraryNodes)), {}, {
            id: item.id
          });
          break;
      }
      return result;
    },
    getReviewLastField: function getReviewLastField(item) {
      var _this4 = this;
      var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var result = '';
      if (!item.reviews || !item.reviews.length) return result;
      var reversedReviews = item.reviews.slice(0).reverse();
      var mostRecentReview = reversedReviews.find(function (review) {
        return !_this4.completedReviewActions.includes(review.action);
      });
      if (mostRecentReview) {
        result = field ? mostRecentReview[field] : mostRecentReview;
      }
      return result;
    },
    getCompletedLastField: function getCompletedLastField(item) {
      var _this5 = this;
      var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var result = null;
      if (!item.reviews || !item.reviews.length) return result;
      var reversedReviews = item.reviews.slice(0).reverse();
      var mostRecentItem = reversedReviews.find(function (review) {
        return _this5.completedReviewActions.includes(review.action);
      });
      if (mostRecentItem) {
        result = field ? mostRecentItem[field] : mostRecentItem;
      }
      return result;
    },
    getRiskValues: function getRiskValues(risk) {
      if (this.getSettingValue('feature.show_risk_links_with_levels') !== 1) {
        return '';
      }
      return DatatableRenderer.getRiskValues(risk, this.riskProjects);
    },
    prepareAttachmentUrl: function prepareAttachmentUrl(id) {
      return "/api/2.0/controls/files/".concat(id);
    },
    prepareRejections: function prepareRejections() {
      var reviews = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return reviews.filter(function (review) {
        return review.action === CONTROL_REVIEW_ACTIONS.rejected;
      }).length;
    },
    sortingByCpath: function sortingByCpath(data) {
      return sortByIndex(data);
    },
    sortingByCpathObject: function sortingByCpathObject(data, key) {
      return data.sort(function (a, b) {
        var x = Utils.purifyItemField(a[key], true);
        var y = Utils.purifyItemField(b[key], true);
        return comparePathStrings(x, y);
      });
    },
    getLinkToNodeGapTab: function getLinkToNodeGapTab(projectId, nodeId, articleId) {
      return "/compliance/".concat(projectId, "/edit/").concat(nodeId, "/gap/").concat(articleId);
    }
  })
};