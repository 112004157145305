import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_single_select, {
    modelValue: $data.SingleSelectValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.SingleSelectValue = $event;
    }),
    title: "Test title",
    disabled: false,
    "with-search": true,
    options: $data.SingleSelectOptions,
    placeholder: "Choose one of ..."
  }, null, 8 /* PROPS */, ["modelValue", "options"]), _createTextVNode(" Selected value: " + _toDisplayString($data.SingleSelectValue) + " ", 1 /* TEXT */), _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[9] || (_cache[9] = _createElementVNode("h3", {
    class: "font-bold"
  }, "Value not selected example:", -1 /* HOISTED */)), _createVNode(_component_single_select, {
    modelValue: $data.SingleSelectValue2,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.SingleSelectValue2 = $event;
    }),
    disabled: false,
    options: $data.SingleSelectOptions,
    placeholder: "Select"
  }, null, 8 /* PROPS */, ["modelValue", "options"]), _createTextVNode(" Selected value: " + _toDisplayString($data.SingleSelectValue2) + " ", 1 /* TEXT */), _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[12] || (_cache[12] = _createElementVNode("h3", {
    class: "font-bold"
  }, "Select with grouped options:", -1 /* HOISTED */)), _createVNode(_component_single_select, {
    modelValue: $data.SingleSelectValue3,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.SingleSelectValue3 = $event;
    }),
    disabled: false,
    options: $data.SingleSelectOptions3,
    "un-sorted": true,
    placeholder: "Select"
  }, null, 8 /* PROPS */, ["modelValue", "options"]), _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[14] || (_cache[14] = _createElementVNode("h3", {
    class: "font-bold"
  }, "Select with grouped options and custom label:", -1 /* HOISTED */)), _createVNode(_component_single_select, {
    modelValue: $data.SingleSelectValue3,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.SingleSelectValue3 = $event;
    }),
    disabled: false,
    options: $data.SingleSelectOptions3,
    "un-sorted": true,
    placeholder: "Select"
  }, {
    singleLabel: _withCtx(function (_ref) {
      var option = _ref.option;
      return [option ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(option.groupName) + ": " + _toDisplayString(option.label), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "options"]), _cache[15] || (_cache[15] = _createElementVNode("h3", {
    class: "font-bold"
  }, "Select with mix of long and short grouped options", -1 /* HOISTED */)), _createVNode(_component_single_select, {
    modelValue: $data.SingleSelectValue6,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $data.SingleSelectValue6 = $event;
    }),
    options: $data.SingleSelectOptions4,
    "un-sorted": true,
    placeholder: "Select"
  }, {
    singleLabel: _withCtx(function (_ref2) {
      var option = _ref2.option;
      return [option ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(option.groupName) + ": " + _toDisplayString(option.label), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "options"]), _createVNode(_component_single_select, {
    modelValue: $data.SingleSelectValue5,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.SingleSelectValue5 = $event;
    }),
    title: "Required single select with search",
    disabled: false,
    options: $options.longTextOptions,
    "un-sorted": true,
    "required-text": _ctx.$trans('This field is required'),
    "with-search": true,
    class: "mt-4",
    placeholder: "Select"
  }, null, 8 /* PROPS */, ["modelValue", "options", "required-text"]), _createVNode(_component_single_select, {
    modelValue: $data.SingleSelectValue4,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $data.SingleSelectValue4 = $event;
    }),
    title: "Short single select with long text items",
    disabled: false,
    options: $options.longTextOptions,
    "un-sorted": true,
    class: "max-w-250px mt-4",
    placeholder: "Select"
  }, null, 8 /* PROPS */, ["modelValue", "options"]), _createVNode(_component_single_select, {
    title: "Short single select with long text items (disabled)",
    "model-value": 3,
    disabled: true,
    options: $options.longTextOptions,
    "un-sorted": true,
    class: "max-w-250px mt-4",
    placeholder: "Select"
  }, null, 8 /* PROPS */, ["options"]), _createVNode(_component_single_select, {
    title: "long single select with short text items (disabled)",
    "model-value": '1',
    disabled: true,
    options: $data.SingleSelectOptions,
    "un-sorted": true,
    "help-text": 'Do X before editing',
    class: "max-w-500px mt-4",
    placeholder: "Select"
  }, null, 8 /* PROPS */, ["options"]), _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1 /* HOISTED */))]);
}