import i18n from '@/i18n';
import { art46TransferBasis } from '@/constants/art46TransferBasis';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export function getInfoFields(activity, type) {
  return [{
    title: activity.objType === ObjectTypes.COMPANY ? i18n.t('Company description') : i18n.t('Description'),
    field: activity.description,
    isHtml: true
  }, {
    title: i18n.t('Location'),
    field: activity.location
  }, {
    title: i18n.t('Address'),
    field: activity.address,
    isHtml: true
  }, {
    title: i18n.t('Phone'),
    field: activity.phone
  }, {
    title: i18n.t('Reg. number'),
    field: activity.regNo
  }, {
    title: i18n.t('Country code/Country'),
    field: activity.countryCode
  }, {
    title: i18n.t('Art. 46 transfer basis'),
    field: getCountryTransferLegalBasis(activity)
  }, {
    title: i18n.t('VAT number'),
    field: activity.vatNo
  }, {
    hide: type !== AssetsTypes.PROCESSORS && activity.objType !== ObjectTypes.COMPANY,
    title: i18n.t('Contact name'),
    field: activity.contactName
  }, {
    title: i18n.t('Email'),
    field: activity.email
  }];
}
function getCountryTransferLegalBasis(activity) {
  var _art46TransferBasis$f;
  if (!activity.countryTransferLegalBasisId) {
    return '';
  }
  return (_art46TransferBasis$f = art46TransferBasis(i18n.t).find(function (basis) {
    return basis.value === activity.countryTransferLegalBasisId;
  })) === null || _art46TransferBasis$f === void 0 ? void 0 : _art46TransferBasis$f.label;
}