import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'AddOptionButton',
  introduction: 'Icon that appears on hover to delete item',
  token: '<add-option-button />',
  components: {
    FeatherIcon: FeatherIcon
  },
  emits: ['click'],
  data: function data() {
    return {
      translate: {
        addOption: this.$trans('Add option')
      }
    };
  }
};