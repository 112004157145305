import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "my-8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_panel_toggle = _resolveComponent("panel-toggle");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_panel_toggle, {
    "model-value": $data.selectedState,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.selectedState = $event;
    })
  }, null, 8 /* PROPS */, ["model-value"])]), _createTextVNode(" Selected value - " + _toDisplayString($data.selectedState), 1 /* TEXT */)], 64 /* STABLE_FRAGMENT */);
}