import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mt-24 max-w-500px m-auto"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  key: 0,
  class: "my-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [$data.notificationMessages ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.notificationMessages), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
    src: $options.logoSrc,
    class: "w-48 mx-auto"
  }, null, 8 /* PROPS */, _hoisted_2), _createVNode(_component_risma_title, {
    title: $options.pageData.mainText,
    type: "big",
    class: "text-center main-text mb-8"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $options.pageData.subText,
    type: "medium",
    truncate: false,
    class: "text-center mb-8"
  }, null, 8 /* PROPS */, ["title"]), !$data.isResetSuccess ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.enterEmailAddress,
    type: "medium",
    class: "text-blue-750"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
    modelValue: $data.email,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.email = $event;
    }),
    placeholder: $data.translate.emailAddress,
    invalid: !!$data.emailError,
    "error-help-text": $data.emailError,
    class: "my-2",
    type: "text",
    onOnEnter: $options.pageData.buttonEvent
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "invalid", "error-help-text", "onOnEnter"])])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_button, {
    text: $options.pageData.buttonText,
    type: "save",
    disabled: $data.isLocked,
    "is-custom-button-size": true,
    class: "w-full reset-button",
    onClick: $options.pageData.buttonEvent
  }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])]);
}