import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createSlots as _createSlots, mergeProps as _mergeProps } from "vue";
var _hoisted_1 = {
  ref: "staticTable",
  class: "static-table-container"
};
var _hoisted_2 = {
  class: "flex border-2 border-gray-350 px-3 py-2 rounded-md w-48 static-table-switcher-label"
};
var _hoisted_3 = {
  class: "pl-1 text-blue-750"
};
var _hoisted_4 = {
  class: "static-table-wrapper overflow-x-auto"
};
var _hoisted_5 = ["title"];
var _hoisted_6 = {
  class: "pr-4"
};
var _hoisted_7 = {
  class: "ml-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_column_switcher = _resolveComponent("risma-column-switcher");
  var _component_drag_and_drop_icon = _resolveComponent("drag-and-drop-icon");
  var _component_static_table_row = _resolveComponent("static-table-row");
  var _component_draggable = _resolveComponent("draggable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.visibilitySwitch ? (_openBlock(), _createBlock(_component_risma_column_switcher, {
    key: 0,
    ref: "columnSwitcher",
    columns: $data.visibleColumns,
    "always-visible-columns": $props.visibilityHiddenOptions,
    "always-visible-columns-key": "label",
    class: "pb-4 inline-flex",
    onChanged: $options.setVisibility
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", _hoisted_2, [_createVNode(_component_feather_icon, {
        icon: "columns",
        class: "text-blue-750"
      }), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$trans($props.visibilitySwitchLabel)), 1 /* TEXT */)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columns", "always-visible-columns", "onChanged"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [_createVNode(_component_draggable, _mergeProps({
    modelValue: $data.preparedDataset,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.preparedDataset = $event;
    }),
    disabled: !$props.draggable,
    "item-key": "label",
    tag: "table",
    class: ['static-table', $props.theme],
    "component-data": {
      type: 'transition'
    }
  }, $data.DragOptions, {
    handle: ".drag-icon",
    onChange: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('onDragChange', $data.preparedDataset);
    })
  }), {
    header: _withCtx(function () {
      return [_createElementVNode("thead", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, function (title, k) {
        return _openBlock(), _createElementBlock("th", {
          key: k,
          style: _normalizeStyle($options.getColumnWidth(k)),
          title: $options.getColumnHeaderHoverTitle(title),
          class: _normalizeClass(['static-table-head-cell text-left', {
            hidden: !$options.checkVisibleColumn(k, title, $data.hiddenColumns, true)
          }])
        }, [_createElementVNode("div", _hoisted_6, [_createElementVNode("h3", {
          class: _normalizeClass(["glob-h3 text-rm-text", {
            truncate: $props.isHeadersTruncated
          }])
        }, _toDisplayString(title), 3 /* TEXT, CLASS */)])], 14 /* CLASS, STYLE, PROPS */, _hoisted_5);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),
    item: _withCtx(function (_ref) {
      var element = _ref.element,
        index = _ref.index;
      return [(_openBlock(), _createBlock(_component_static_table_row, {
        key: index,
        item: element,
        style: _normalizeStyle({
          backgroundImage: $props.watermarkImage
        }),
        "prevent-full-page-updates": $props.preventFullPageUpdates,
        "hover-titles": $props.hoverTitles,
        "cells-max-width": $props.columnsMaxWidth,
        class: _normalizeClass([{
          'cursor-pointer': $props.isRowClickable
        }, $props.datasetTable[index].additionalRowClasses || '']),
        "additional-row-classes-text": $props.datasetTable[index].additionalRowClasses ? $props.additionalRowClassesText : '',
        "is-children-icon-shown": $props.isChildrenIconShown,
        "hidden-column-indexes": $data.hiddenColumns,
        "item-props-to-show-children": $props.itemPropsToShowChildren,
        "show-child-default-value": $props.showChildDefaultValue,
        draggable: $props.draggable,
        onClick: function onClick($event) {
          return _ctx.$emit('click:row', index);
        }
      }, _createSlots({
        "drag-icon": _withCtx(function () {
          return [_createElementVNode("div", _hoisted_7, [_createVNode(_component_drag_and_drop_icon, {
            class: "drag-icon"
          })])];
        }),
        _: 2 /* DYNAMIC */
      }, [_renderList(_ctx.$slots, function (_, name) {
        return {
          name: name,
          fn: _withCtx(function (slotData) {
            return [_renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(slotData)), undefined, true)];
          })
        };
      })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["item", "style", "prevent-full-page-updates", "hover-titles", "cells-max-width", "class", "additional-row-classes-text", "is-children-icon-shown", "hidden-column-indexes", "item-props-to-show-children", "show-child-default-value", "draggable", "onClick"]))];
    }),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["modelValue", "disabled", "class"])])], 512 /* NEED_PATCH */);
}