// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.markdown[data-v-b644c852] {
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
}
.markdown[data-v-b644c852]  h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 1rem;
}
.markdown[data-v-b644c852]  h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-top: 1rem;
}
.markdown[data-v-b644c852]  h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin-top: 1rem;
}
.markdown[data-v-b644c852]  h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 1rem;
}
.markdown[data-v-b644c852]  ol {
  list-style: decimal;
}
.markdown[data-v-b644c852]  ul {
  list-style: disc;
}
.markdown[data-v-b644c852]  ul,
.markdown[data-v-b644c852]  ol {
  line-height: 1rem;
  padding-left: 40px;
  margin: 0.5rem 0 1rem;
}
.markdown[data-v-b644c852]  p {
  margin: 0.5rem 0;
}
.markdown[data-v-b644c852]  ul,
.markdown[data-v-b644c852]  ol,
.markdown[data-v-b644c852]  p {
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 1.25rem;
}
.markdown[data-v-b644c852]  a:not([data-citation-index]) {
  text-decoration: underline;
  font-weight: 500;
  color: #2182fd;
}
.markdown[data-v-b644c852]  ol > li::marker {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}
.markdown[data-v-b644c852]  blockquote {
  font-weight: 500;
  font-style: italic;
  border-inline-start-width: 0.25rem;
  padding-inline-start: 1rem;
  quotes: "“" "”" "‘" "’";
}
.markdown[data-v-b644c852]  img {
  margin: 1rem 0;
}
.markdown[data-v-b644c852]  pre {
  background-color: #1f2937;
  color: #e5e7eb;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 1.25rem;
  padding: 8px 12px;
  margin: 1rem 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
