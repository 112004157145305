function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapActions } from 'pinia';
import { useWorkflowStore } from '@/Store/workflowStore';
import { getWorkflowStatuses, updateWorkflowStatusesByModule, updateWorkflowStatus } from '@/api/admin/workflow';
import DragAndDropIcon from '@/components/Atoms/FeatherIcon/DragAndDropIcon';
import draggable from 'vuedraggable';
import { DragOptions } from '@/constants/DragOptions';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import { getWorkflowStatusTitle } from '@/constants/admin/WorkflowStatuses';
import { MODULES } from '@/constants/modules';
import { saveStatus } from '@/constants/SaveStatuses';
export default {
  name: 'WorkflowStatuses',
  components: {
    draggable: draggable,
    DragAndDropIcon: DragAndDropIcon,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    InputField: InputField,
    Checkboxes: Checkboxes
  },
  data: function data() {
    return {
      saveStatus: saveStatus.SAVED,
      allWorkflowStatuses: [],
      updatedWorkflowStatuses: [],
      uniqueNames: new Set(),
      selectedModule: MODULES.CONTRACTS,
      DragOptions: DragOptions,
      statuses: [],
      updateTimer: 0,
      newStatusName: '',
      workflowStatus: {},
      getWorkflowStatusTitle: getWorkflowStatusTitle,
      updateInProgress: false,
      translate: getTranslate['WorkflowStatuses']()
    };
  },
  computed: {
    allWorkflowStatusesByModule: function allWorkflowStatusesByModule() {
      var result = {};
      this.allWorkflowStatuses.forEach(function (status) {
        var module = status.module;
        if (!result[module]) result[module] = [];
        result[module].push(status);
      });
      return result;
    },
    selectedStatuses: {
      get: function get() {
        return this.allWorkflowStatusesByModule[this.selectedModule] || [];
      },
      set: function set(value) {
        this.allWorkflowStatusesByModule[this.selectedModule] = value;
      }
    },
    isIncident: function isIncident() {
      return this.selectedModule === MODULES.INCIDENTS;
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useWorkflowStore, {
    loadAllWorkflows: 'load'
  })), {}, {
    getData: function getData() {
      var _this = this;
      return this.getAllWorkflowStatuses().catch(function (err) {
        var _err$response$error, _err$response;
        _this.$notify({
          title: (_err$response$error = err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.error) !== null && _err$response$error !== void 0 ? _err$response$error : _this.translate.anErrorOccurred,
          type: 'error'
        });
        _this.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    getAllWorkflowStatuses: function getAllWorkflowStatuses() {
      var _this2 = this;
      return getWorkflowStatuses().then(function (_ref) {
        var list = _ref.list;
        return _this2.allWorkflowStatuses = list;
      });
    },
    statusMoved: function statusMoved(_ref2) {
      var moved = _ref2.moved;
      this.saveStatus = saveStatus.SAVING;
      this.workflowStatus = _objectSpread(_objectSpread({}, moved.element), {}, {
        order: moved.newIndex + 1
      });
      return this.updateWorkflowStatusIndex();
    },
    updateWorkflowStatusIndex: function updateWorkflowStatusIndex() {
      var _this3 = this;
      return updateWorkflowStatus(this.selectedModule, this.workflowStatus).then(function () {
        _this3.$notify({
          title: ["".concat(_this3.translate.workflowStatus, " ").concat(_this3.translate.updated), getWorkflowStatusTitle(_this3.workflowStatus.title)]
        });
        return _this3.getData();
      }).then(this.loadAllWorkflows).then(function () {
        return _this3.saveStatus = saveStatus.SAVED;
      }).catch(function (err) {
        var _err$response$error2, _err$response2;
        _this3.$notify({
          title: (_err$response$error2 = err === null || err === void 0 || (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.error) !== null && _err$response$error2 !== void 0 ? _err$response$error2 : _this3.translate.anErrorOccurred,
          type: 'error'
        });
        _this3.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    updateTitle: function updateTitle(status, value) {
      var _this4 = this;
      this.updateInProgress = true;
      this.updatedWorkflowStatuses = this.getUpdatedWorkflowStatuses(status, value);
      this.uniqueNames.add(status.title);
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(function () {
        return _this4.updateMultipleTitles();
      }, this.$autoSaveDebounceTime);
    },
    getUpdatedWorkflowStatuses: function getUpdatedWorkflowStatuses(status, value) {
      var field = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'title';
      if (this.updatedWorkflowStatuses.length) {
        return this.updatedWorkflowStatuses.map(function (_ref3) {
          var id = _ref3.id,
            title = _ref3.title,
            finalStatus = _ref3.finalStatus;
          if (id === status.id) return _defineProperty({
            id: id,
            title: title,
            finalStatus: finalStatus
          }, field, value);
          return {
            id: id,
            title: title,
            finalStatus: finalStatus
          };
        });
      }
      return this.selectedStatuses.map(function (_ref5) {
        var id = _ref5.id,
          title = _ref5.title,
          finalStatus = _ref5.finalStatus;
        if (id === status.id) return _defineProperty({
          id: id,
          title: title,
          finalStatus: finalStatus
        }, field, value);
        return {
          id: id,
          title: title,
          finalStatus: finalStatus
        };
      });
    },
    updateMultipleTitles: function updateMultipleTitles() {
      var _this5 = this;
      if (!this.validateMultipleStatuses()) return;
      this.saveStatus = saveStatus.SAVING;
      var workflowStatusesNames = Array.from(this.uniqueNames).map(function (title) {
        return getWorkflowStatusTitle(title);
      }).join(', ');
      return updateWorkflowStatusesByModule(this.selectedModule, {
        workflowStatuses: this.updatedWorkflowStatuses
      }).then(function () {
        return _this5.getData();
      }).then(this.loadAllWorkflows).then(function () {
        _this5.saveStatus = saveStatus.SAVED;
        _this5.updatedWorkflowStatuses = [];
        _this5.$notify({
          title: ["".concat(_this5.translate.workflowStatuses, " ").concat(_this5.translate.updated), workflowStatusesNames],
          focus: false
        });
        _this5.uniqueNames.clear();
      }).catch(function (err) {
        var _err$response$error3, _err$response3;
        _this5.$notify({
          title: (_err$response$error3 = err === null || err === void 0 || (_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : _err$response3.error) !== null && _err$response$error3 !== void 0 ? _err$response$error3 : _this5.translate.anErrorOccurred,
          type: 'error'
        });
        _this5.saveStatus = saveStatus.NOT_SAVED;
      }).finally(function () {
        return _this5.updateInProgress = false;
      });
    },
    createStatus: function createStatus() {
      var _this6 = this;
      if (!this.validateStatus(this.newStatusName)) return;
      this.saveStatus = saveStatus.SAVING;
      var otherStatuses = this.selectedStatuses.map(function (_ref7) {
        var id = _ref7.id,
          title = _ref7.title;
        return {
          id: id,
          title: title
        };
      });
      var workflowStatuses = [].concat(_toConsumableArray(otherStatuses), [{
        title: this.newStatusName
      }]);
      updateWorkflowStatusesByModule(this.selectedModule, {
        workflowStatuses: workflowStatuses
      }).then(function (_ref8) {
        var list = _ref8.list;
        _this6.$notify({
          title: ["".concat(_this6.translate.workflowStatus, " ").concat(_this6.translate.created.toLowerCase()), getWorkflowStatusTitle(_this6.newStatusName)]
        });
        var newStatus = list[list.length - 1];
        var newStatusWithModule = _objectSpread(_objectSpread({}, newStatus), {}, {
          module: newStatus.module || _this6.selectedModule
        });
        _this6.allWorkflowStatuses.push(newStatusWithModule);
        _this6.newStatusName = '';
        _this6.saveStatus = saveStatus.SAVED;
      }).then(this.loadAllWorkflows).catch(function (err) {
        var _err$response$error4, _err$response4;
        _this6.$notify({
          title: (_err$response$error4 = err === null || err === void 0 || (_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : _err$response4.error) !== null && _err$response$error4 !== void 0 ? _err$response$error4 : _this6.translate.anErrorOccurred,
          type: 'error'
        });
        _this6.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    handleDeleteStatus: function handleDeleteStatus(status) {
      var _this7 = this;
      this.workflowStatus = status;
      this.$confirm(this.translate.deleteWorkflowStatus, "".concat(this.translate.areYouSureYouWantToDelete, " ").concat(getWorkflowStatusTitle(status.title), "?"), function (res) {
        return res && _this7.deleteWorkflowStatus();
      }, {
        buttonOkText: this.translate.delete
      });
    },
    deleteWorkflowStatus: function deleteWorkflowStatus() {
      var _this8 = this;
      this.saveStatus = saveStatus.SAVING;
      var title = this.workflowStatus.title;
      var workflowStatuses = this.selectedStatuses.filter(function (status) {
        return status.title !== title;
      }).map(function (_ref9) {
        var id = _ref9.id,
          title = _ref9.title;
        return {
          id: id,
          title: title
        };
      });
      updateWorkflowStatusesByModule(this.selectedModule, {
        workflowStatuses: workflowStatuses
      }).then(function () {
        _this8.$notify({
          title: ["".concat(_this8.translate.workflowStatus, " ").concat(_this8.translate.deleted), getWorkflowStatusTitle(title)]
        });
        _this8.allWorkflowStatuses = _this8.allWorkflowStatuses.filter(function (status) {
          return status.title !== title;
        });
        _this8.saveStatus = saveStatus.SAVED;
      }).then(this.loadAllWorkflows).catch(function (err) {
        var _err$response$error5, _err$response5;
        _this8.$notify({
          title: (_err$response$error5 = err === null || err === void 0 || (_err$response5 = err.response) === null || _err$response5 === void 0 ? void 0 : _err$response5.error) !== null && _err$response$error5 !== void 0 ? _err$response$error5 : _this8.translate.anErrorOccurred,
          type: 'error'
        });
        _this8.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    validateStatus: function validateStatus(statusName) {
      if (!statusName) {
        this.$notify({
          title: this.translate.pleaseFillOutTheTitle,
          type: 'error'
        });
        return false;
      }
      if (this.selectedStatuses.map(function (_ref10) {
        var title = _ref10.title;
        return title;
      }).includes(statusName)) {
        this.$notify({
          title: this.translate.titleHaveToBeUnique,
          type: 'error'
        });
        return false;
      }
      return true;
    },
    validateMultipleStatuses: function validateMultipleStatuses() {
      var titles = this.updatedWorkflowStatuses.map(function (_ref11) {
        var title = _ref11.title;
        return title;
      });
      if (!titles.every(Boolean)) {
        this.$notify({
          title: this.translate.pleaseFillOutTheTitle,
          type: 'error',
          focus: false
        });
        return false;
      }
      var uniqueTitles = new Set(titles);
      if (uniqueTitles.size !== this.updatedWorkflowStatuses.length) {
        this.$notify({
          title: this.translate.titleHaveToBeUnique,
          type: 'error',
          focus: false
        });
        return false;
      }
      return true;
    },
    handleUpdate: function handleUpdate() {
      if (!this.updateTimer || !this.updatedWorkflowStatuses.length) return;
      clearTimeout(this.updateTimer);
      this.updateMultipleTitles();
    },
    onChangeBox: function onChangeBox(item, value) {
      item.finalStatus = value.isChecked;
      this.updatedWorkflowStatuses = this.getUpdatedWorkflowStatuses(item, value.isChecked, 'finalStatus');
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateMultipleTitles();
    }
  })
};