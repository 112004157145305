import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = {
  class: "w-1/2 pr-3"
};
var _hoisted_3 = {
  class: "mb-3"
};
var _hoisted_4 = {
  class: "mb-3"
};
var _hoisted_5 = {
  class: "mb-3"
};
var _hoisted_6 = {
  key: 0,
  class: "flex items-center mb-1"
};
var _hoisted_7 = {
  class: "flex flex-grow"
};
var _hoisted_8 = {
  class: "flex items-center mb-1"
};
var _hoisted_9 = {
  class: "flex flex-grow"
};
var _hoisted_10 = {
  class: "w-1/2 pl-3"
};
var _hoisted_11 = {
  key: 1,
  class: "mt-5"
};
var _hoisted_12 = {
  class: "flex justify-between items-center mb-3"
};
var _hoisted_13 = {
  class: "flex w-1/2 pr-3"
};
var _hoisted_14 = {
  key: 0
};
var _hoisted_15 = {
  class: "flex justify-between items-center mb-3"
};
var _hoisted_16 = {
  class: "flex w-1/2 pr-3"
};
var _hoisted_17 = {
  class: "w-1/2 pl-3"
};
var _hoisted_18 = {
  key: 0,
  class: "flex justify-between items-center mb-3"
};
var _hoisted_19 = {
  class: "flex w-1/2 pr-3"
};
var _hoisted_20 = {
  class: "w-1/2 pl-3"
};
var _hoisted_21 = {
  key: 2,
  class: "flex items-center mb-3"
};
var _hoisted_22 = {
  key: 3
};
var _hoisted_23 = {
  key: 0,
  class: "flex items-center mb-3"
};
var _hoisted_24 = {
  class: "flex justify-end mt-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_text_box = _resolveComponent("text-box");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_help_tool_tip = _resolveComponent("help-tool-tip");
  var _component_risma_switch = _resolveComponent("risma-switch");
  var _component_input_field = _resolveComponent("input-field");
  var _component_options_list = _resolveComponent("options-list");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_question_dependency_editor = _resolveComponent("question-dependency-editor");
  var _component_frameworks_selector = _resolveComponent("frameworks-selector");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  return _openBlock(), _createElementBlock("div", null, [$data.errors.length ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList($data.errors, function (error, i) {
    return _openBlock(), _createElementBlock("div", {
      key: i,
      class: "mb-2 p-2 bg-red-210"
    }, _toDisplayString(error), 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
    options: $options.headlines,
    title: $data.translate.headline,
    "model-value": $data.data.headline,
    placeholder: $data.translate.typeHere,
    "with-search": true,
    "with-reset": false,
    class: "mb-3",
    "onUpdate:modelValue": $options.updateHeadlineFromList,
    "onUpdate:searchValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.headlineSearch = $event;
    }),
    onExpanded: $options.updateHeadlineWithSearchValue
  }, null, 8 /* PROPS */, ["options", "title", "model-value", "placeholder", "onUpdate:modelValue", "onExpanded"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_text_box, {
    label: $data.translate.questionText,
    text: $data.data.question,
    "min-height": 66,
    placeholder: $data.translate.typeHere,
    onUpdated: _cache[1] || (_cache[1] = function ($event) {
      return $data.data.question = $event;
    })
  }, null, 8 /* PROPS */, ["label", "text", "placeholder"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_text_box, {
    label: $data.translate.helpText,
    text: $data.data.help,
    placeholder: $data.translate.typeHere,
    "min-height": 66,
    onUpdated: _cache[2] || (_cache[2] = function ($event) {
      return $data.data.help = $event;
    })
  }, null, 8 /* PROPS */, ["label", "text", "placeholder"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.questionSettings,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), $data.data.questiontype === $data.allQuestionTypes.singleChoice.id || $data.data.questiontype === $data.allQuestionTypes.multipleChoice.id ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.allowCreationOfNewAnswers,
    type: "small",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_help_tool_tip, null, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.ifYouAllowCreationOfNewAnswers), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })]), _createVNode(_component_risma_switch, {
    "model-value": $options.allowNewAnswerOption,
    options: $options.answerOptions,
    "is-labels-show": true,
    class: "pl-3",
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.data.allowNewAnswerOption = $event;
    })
  }, null, 8 /* PROPS */, ["model-value", "options"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createTextVNode(_toDisplayString($options.reportName) + " ", 1 /* TEXT */), _createVNode(_component_help_tool_tip, null, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.reportTooltip), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })]), _createVNode(_component_risma_switch, {
    "model-value": $options.isArticle30,
    options: $options.defaultSelectedOptions,
    "is-labels-show": true,
    class: "pl-3",
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $data.data.isArticle30 = $event;
    })
  }, null, 8 /* PROPS */, ["model-value", "options"])])])]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_input_field, {
    modelValue: $data.data.questionnumber,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.data.questionnumber = $event;
    }),
    title: $data.translate.questionNumber,
    required: true,
    placeholder: $data.translate.typeHere,
    type: "text",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_single_select, {
    "model-value": $data.data.questiontype,
    title: $data.translate.questionType,
    options: $options.questionTypes,
    class: "mb-3",
    "onUpdate:modelValue": $options.handleQuestionTypeChange
  }, null, 8 /* PROPS */, ["model-value", "title", "options", "onUpdate:modelValue"]), $data.data.questiontype === $data.allQuestionTypes.customLists.id ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    modelValue: $data.data.customValueList,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $data.data.customValueList = $event;
    }),
    class: "mb-3 -mt-1",
    options: $options.customListsOptions,
    placeholder: $data.translate.chooseCustomList
  }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])) : _createCommentVNode("v-if", true), $data.data.questiontype === $data.allQuestionTypes.customInformationAsset.id ? (_openBlock(), _createBlock(_component_single_select, {
    key: 1,
    modelValue: $data.data.informationAssetActivityType,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return $data.data.informationAssetActivityType = $event;
    }),
    class: "mb-3 -mt-1",
    options: $options.assetsOptions,
    placeholder: "".concat($data.translate.chooseCustom, " ").concat(_ctx.informationAssetsTitle())
  }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])) : _createCommentVNode("v-if", true), $data.data.questiontype === $data.allQuestionTypes.singleChoice.id || $data.data.questiontype === $data.allQuestionTypes.multipleChoice.id ? (_openBlock(), _createBlock(_component_options_list, {
    key: 2,
    "predefined-options": $data.data.optionDetails,
    placeholder: $data.translate.typeHere,
    draggable: true,
    "show-empty-fields-error": $data.showEmptyFieldsError,
    "save-button-click-counter": $data.saveButtonClickCounter,
    class: "mb-3",
    onOnOptionsChange: _cache[8] || (_cache[8] = function ($event) {
      return $data.data.optionDetails = $event;
    })
  }, null, 8 /* PROPS */, ["predefined-options", "placeholder", "show-empty-fields-error", "save-button-click-counter"])) : _createCommentVNode("v-if", true)])]), $options.isDataflowAvailable ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $data.translate.dataFlowSettings,
    type: "medium",
    truncate: false,
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createVNode(_component_risma_title, {
    title: $data.translate.allowAnswerOptionsToBeIncludedInDataFlow,
    type: "small",
    truncate: false,
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_help_tool_tip, null, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.theAnswersProvidedFromTheCurrentLinkedToDataFlows), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })]), _createVNode(_component_risma_select, {
    options: $options.dataflowTypesFiltered,
    "selected-options": $options.selectedDataFlowTypes,
    placeholder: $data.translate.select,
    "track-by": "key",
    "label-key": "title",
    class: "w-1/2 pl-3",
    onSelected: $options.handleDataFlowChanged
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onSelected"])]), $options.isDataFlowDataTypeSelected && !$options.isIATypeSelected ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createVNode(_component_risma_title, {
    title: $data.translate.linkDataTypeToLegalBasisQuestion,
    type: "small",
    truncate: false,
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_help_tool_tip, null, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.theAnswersProvidedFromTheCurrentQuestionLegalBasis), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })]), _createElementVNode("div", _hoisted_17, [_createVNode(_component_single_select, {
    modelValue: $data.data.legalBasisQuestionId,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
      return $data.data.legalBasisQuestionId = $event;
    }),
    options: $options.questionOptions,
    "with-reset": true,
    placeholder: $data.translate.select
  }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])])]), $options.isDataflowWizardEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createVNode(_component_risma_title, {
    title: $data.translate.linkDataTypeToRetentionQuestion,
    type: "small",
    truncate: false,
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_help_tool_tip, null, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.theAnswersProvidedFromTheCurrentQuestionStorage), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })]), _createElementVNode("div", _hoisted_20, [_createVNode(_component_single_select, {
    modelValue: $data.data.retentionQuestionId,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = function ($event) {
      return $data.data.retentionQuestionId = $event;
    }),
    options: $options.questionOptions,
    "with-reset": true,
    placeholder: $data.translate.select
  }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.isOutsourcing ? (_openBlock(), _createElementBlock("div", _hoisted_21, [_createVNode(_component_risma_title, {
    title: $data.translate.allowAnswerOptionsToBeIncludedInOutsourcing,
    type: "medium",
    class: "flex-shrink-0 mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_help_tool_tip, null, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.theAnswersProvidedFromTheCurrentLinkedToOutcoursingReport), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_risma_select, {
    options: $data.outsourcingTypes,
    "selected-options": $options.selectedOutsourcingTypes,
    placeholder: $data.translate.select,
    "track-by": "key",
    "label-key": "title",
    class: "flex-grow ml-8",
    onSelected: $options.handleOutsourcingChanged
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onSelected"])])) : _createCommentVNode("v-if", true), _createCommentVNode(" legacy, temporary hidden  "), false ? (_openBlock(), _createElementBlock("div", _hoisted_22, [$options.isDataProcessor || $options.isDPM ? (_openBlock(), _createElementBlock("div", _hoisted_23, [_createVNode(_component_risma_title, {
    title: $data.translate.allowAnswerOptionsToBeIncludedInDataProces,
    type: "medium",
    class: "flex-shrink-0 mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_help_tool_tip, null, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.theAnswersProvidedFromTheCurrentAssistRiskAssessment), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_risma_select, {
    options: $data.DPMTypes,
    "selected-options": $options.selectedDPMTypes,
    placeholder: $data.translate.select,
    "track-by": "key",
    "label-key": "title",
    class: "flex-grow ml-8",
    onSelected: $options.handleDPMFlagsChanged
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "onSelected"])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.dependencyEditorIsEnabled ? (_openBlock(), _createBlock(_component_question_dependency_editor, {
    key: 4,
    "model-value": $data.data,
    questions: $props.questions,
    onChanged: $options.onQuestionChange
  }, null, 8 /* PROPS */, ["model-value", "questions", "onChanged"])) : _createCommentVNode("v-if", true), $options.hasOverallFramework ? (_openBlock(), _createBlock(_component_frameworks_selector, {
    key: 5,
    "selected-options": $options.selectedFrameworks,
    class: "w-1/2 pr-3 mt-5",
    onSelected: $options.onFrameworksSelected
  }, null, 8 /* PROPS */, ["selected-options", "onSelected"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_24, [_createVNode(_component_risma_button, {
    text: $data.translate.cancel,
    type: "util",
    class: "mr-2",
    onClick: $options.cancel
  }, null, 8 /* PROPS */, ["text", "onClick"]), $data.isSaving ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_risma_button, {
    key: 1,
    text: $data.data.id ? $data.translate.save : $data.translate.create,
    type: "save",
    func: "submit",
    onClick: $options.handleOnSubmit
  }, null, 8 /* PROPS */, ["text", "onClick"]))])]);
}