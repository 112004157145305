import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "newChoice relative flex w-64"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_popup = _resolveComponent("risma-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_button, {
    text: $data.translate.addNewAnswer,
    type: "util",
    onClick: _withModifiers($options.togglePopup, ["stop"])
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "plus",
        class: "stroke-2 mr-2"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"]), _createVNode(_component_risma_popup, {
    class: "w-64",
    "is-hidden": $data.isHiddenPopup,
    onHide: $options.togglePopup
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_input_field, {
        modelValue: $data.choiceLabel,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.choiceLabel = $event;
        }),
        title: $data.translate.newAnswer,
        "focus-on-mount": true,
        invalid: !!$data.errorMessage,
        "error-help-text": $data.errorMessage,
        class: "mb-2",
        type: "text",
        onOnEnter: $options.handleNewAnswer
      }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "error-help-text", "onOnEnter"]), _createVNode(_component_risma_button, {
        text: $data.translate.add,
        type: "save",
        onClick: $options.handleNewAnswer
      }, null, 8 /* PROPS */, ["text", "onClick"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-hidden", "onHide"])]);
}