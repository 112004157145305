function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import OptionsList from '@/components/Molecules/OptionsList/OptionsList';
import { sortNumericByPropComplex } from '@/utils/sort';
import { MODULES } from '@/constants/modules';
import { CustomFieldTypes, getCustomFieldTypeText } from '@/constants/CustomFieldTypes';
export default {
  name: 'CustomFieldTypes',
  token: '<custom-field-types />',
  description: 'This is a selector with types of custom field and their optional parameters',
  components: {
    InputField: InputField,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    OptionsList: OptionsList
  },
  props: {
    field: {
      type: Object,
      require: true,
      default: function _default() {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    moduleType: {
      type: String,
      require: true,
      default: ''
    },
    customFields: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All possible custom fields'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    saveButtonClickCounter: {
      required: false,
      type: Number,
      default: 0,
      note: 'required to not display errors below newly created items before user make another save button click'
    }
  },
  emits: ['edit'],
  data: function data() {
    return {
      customFieldTypes: CustomFieldTypes,
      customFieldsFiltered: [],
      MODULES: MODULES,
      fieldTypes: this.prepareFieldTypes(),
      availableOrder: [],
      unSorted: true,
      translate: getTranslate['CustomFieldTypes']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    confirmOnDelete: function confirmOnDelete() {
      return {
        title: this.translate.deleteAnswerOption,
        body: this.translate.areYouSureYouWantToDeleteThisAnswerOption
      };
    },
    hasActiveField: function hasActiveField() {
      return this.customFieldsFiltered.filter(function (item) {
        return item.isActive;
      }).length > 0;
    },
    fieldTypesAll: function fieldTypesAll() {
      var result = _toConsumableArray(this.fieldTypes);
      var excludeTypes = [CustomFieldTypes.COMBINED, CustomFieldTypes.NUMERICAL_UNIT];
      if (this.moduleType !== MODULES.CONTROLS || this.getSettingValue('feature.repeatable_fields') !== 1) {
        excludeTypes.push(CustomFieldTypes.ROW_GROUP);
      }
      if (this.moduleType === MODULES.CONTROLS || this.moduleType === MODULES.EXECUTION || this.moduleType === MODULES.INCIDENTS || this.moduleType === MODULES.CONTRACTS) {
        excludeTypes.push(CustomFieldTypes.TREND);
      }
      return result.filter(function (item) {
        return !excludeTypes.includes(item.value);
      });
    },
    fieldType: function fieldType() {
      return this.field.typeId;
    },
    errors: function errors() {
      return this.field.errors || {};
    }
  }),
  mounted: function mounted() {
    var _this$field;
    if (!((_this$field = this.field) !== null && _this$field !== void 0 && _this$field.typeId)) {
      this.$emit('edit', 'typeId', CustomFieldTypes.SELECT);
      this.prepareCustomFieldsFiltered();
    } else {
      if (this.field.children) {
        this.prepareCustomFieldsFiltered();
        this.updateChosenCustomFields(this.field.children);
        this.prepareAvailableOrder();
        this.orders = this.getOrders();
      }
    }
  },
  methods: {
    onChangeFieldType: function onChangeFieldType(value) {
      if (value) {
        this.$emit('edit', 'typeId', value);
      }
    },
    updateDropdownOption: function updateDropdownOption(options) {
      this.$emit('edit', 'options', options);
    },
    onChangeChosenCustomFieldOrder: function onChangeChosenCustomFieldOrder(field, e) {
      if (e) {
        this.swapOrder(field.order, e);
        field.order = e;
        this.$forceUpdate();
        this.sortByOrder(this.customFieldsFiltered);
        this.updateRepeatableFields();
      }
    },
    swapOrder: function swapOrder(value, e) {
      var prevCustomField = this.customFieldsFiltered.find(function (field) {
        return field.order === e;
      });
      if (prevCustomField) {
        prevCustomField.order = value;
      }
    },
    sortByOrder: function sortByOrder(data) {
      if (data && data.length) {
        sortNumericByPropComplex(data, 'order');
      }
    },
    getOrders: function getOrders() {
      var length = this.customFieldsFiltered.filter(function (item) {
        return item.isActive;
      }).length;
      return Array.from({
        length: length
      }, function (v, k) {
        return {
          label: k + 1 + '',
          value: k + 1
        };
      });
    },
    refreshFieldOrders: function refreshFieldOrders() {
      var _this = this;
      var index = 0;
      this.customFieldsFiltered.map(function (field) {
        if (field.isActive) {
          field.order = _this.availableOrder[index];
          index++;
        } else {
          field.order = null;
        }
      });
    },
    prepareAvailableOrder: function prepareAvailableOrder() {
      var length = this.customFieldsFiltered.filter(function (item) {
        return item.isActive;
      }).length;
      if (length) {
        this.availableOrder = Array.from({
          length: length
        }, function (v, k) {
          return ++k;
        });
      }
    },
    findNextAvailableOrder: function findNextAvailableOrder() {
      var result = this.availableOrder.slice(-1).pop();
      if (result) {
        result += 1;
      } else {
        result = 1;
      }
      this.availableOrder.push(result);
      return result;
    },
    updateChosenCustomFields: function updateChosenCustomFields(data) {
      var _this2 = this;
      if (data) {
        data.map(function (item, index) {
          _this2.customFieldsFiltered.map(function (field) {
            if (item.id === field.id) {
              field.isActive = true;
              field.order = ++index;
            }
          });
        });
      }
      this.sortByOrder(this.customFieldsFiltered);
    },
    prepareCustomFieldsFiltered: function prepareCustomFieldsFiltered() {
      var _this3 = this;
      this.customFieldsFiltered = this.customFields.filter(function (item) {
        return item.typeId !== _this3.customFieldTypes.ROW_GROUP && item.module === MODULES.CONTROLS;
      });
      this.customFieldsFiltered.map(function (field) {
        field.isActive = false;
        field.order = null;
      });
    },
    prepareFieldTypes: function prepareFieldTypes() {
      var result = [];
      for (var key in CustomFieldTypes) {
        result.push({
          label: this.$trans(getCustomFieldTypeText(CustomFieldTypes[key])),
          value: CustomFieldTypes[key]
        });
      }
      return result;
    },
    setChosenCustomFieldActive: function setChosenCustomFieldActive(field, e) {
      var _this4 = this;
      if (e) {
        if (!field.order) {
          field.order = this.findNextAvailableOrder();
        }
      }
      this.orders = this.getOrders();
      this.prepareAvailableOrder();
      this.refreshFieldOrders();
      this.$forceUpdate();
      this.$nextTick(function () {
        _this4.sortByOrder(_this4.customFieldsFiltered);
      });
      this.updateRepeatableFields();
    },
    updateUnit: function updateUnit(value) {
      this.$emit('edit', 'unit', value);
    },
    updateRepeatableFields: function updateRepeatableFields() {
      var children = this.customFieldsFiltered.filter(function (item) {
        return item.isActive;
      });
      this.$emit('edit', 'children', children);
    }
  }
};