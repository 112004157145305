import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, Transition as _Transition } from "vue";
var _hoisted_1 = {
  class: "border border-gray-350 p-4 w-full"
};
var _hoisted_2 = {
  class: "relative"
};
var _hoisted_3 = {
  class: "w-1/2 pr-6 pb-6"
};
var _hoisted_4 = {
  class: "flex flex-col min-h-220px"
};
var _hoisted_5 = {
  key: 1,
  class: "flex justify-between"
};
var _hoisted_6 = {
  key: 1,
  class: "flex align-center justify-center flex-grow items-center"
};
var _hoisted_7 = {
  key: 0
};
var _hoisted_8 = {
  class: "flex py-2"
};
var _hoisted_9 = ["title", "innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_doughnut_chart = _resolveComponent("risma-doughnut-chart");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  var _component_static_table = _resolveComponent("static-table");
  var _component_collapsible_panel = _resolveComponent("collapsible-panel");
  return $options.tag ? (_openBlock(), _createBlock(_component_collapsible_panel, {
    key: 0,
    "local-storage-key": "gdpr_status_overview_locale_key_".concat($props.projectId),
    "overflow-visible": true
  }, {
    header: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.statusOverview
      }, null, 8 /* PROPS */, ["title"])];
    }),
    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
        title: $options.note,
        type: "small",
        class: "pr-4 text-blue-750 text-sm absolute right-0"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_3, [$data.isChartsLoaded ? (_openBlock(), _createBlock(_component_risma_date_range_picker, {
        key: 0,
        title: $data.translate.choosePeriod,
        "delete-enabled": true,
        "start-date": $data.dateRange.startDate,
        "end-date": $data.dateRange.endDate,
        opens: "right",
        onChanged: $options.changeDatePicker,
        onDiscard: $options.onDateDiscard
      }, null, 8 /* PROPS */, ["title", "start-date", "end-date", "onChanged", "onDiscard"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_4, [!$data.isChartsLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.chartsData, function (chart, index) {
        return _openBlock(), _createElementBlock("div", {
          key: chart.title + index,
          class: "w-1/4 px-4 flex flex-col"
        }, [_createVNode(_component_risma_title, {
          title: chart.title,
          class: "mb-4 text-center"
        }, null, 8 /* PROPS */, ["title"]), chart.isValueExist ? (_openBlock(), _createBlock(_component_risma_doughnut_chart, {
          key: 0,
          class: "m-auto",
          "chart-max-width": "250px",
          datasets: chart.datasets,
          labels: chart.labels,
          "show-data-labels": true,
          "percentage-value": false,
          "use-default-export": false,
          "custom-legend-enabled": true,
          cover: false,
          onChartClicked: function onChartClicked($event) {
            return $options.clickSlice($event, chart, index);
          }
        }, null, 8 /* PROPS */, ["datasets", "labels", "onChartClicked"])) : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($data.translate.noControlsFound), 1 /* TEXT */))]);
      }), 128 /* KEYED_FRAGMENT */))])), _createVNode(_Transition, {
        name: "fade"
      }, {
        default: _withCtx(function () {
          return [$data.pieChartsStaticTable ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("strong", null, _toDisplayString($data.translate.controls) + ": " + _toDisplayString(_ctx.$trans($data.pieChartsStaticTable.title)), 1 /* TEXT */), _createElementVNode("button", {
            class: "inline leading-5 focus:outline-none px-2",
            onClick: _cache[0] || (_cache[0] = function () {
              return $options.hideTable && $options.hideTable.apply($options, arguments);
            })
          }, [_createVNode(_component_feather_icon, {
            icon: "x",
            class: "stroke-2 text-blue-750",
            onClick: $options.hideTable
          }, null, 8 /* PROPS */, ["onClick"])])]), _createVNode(_component_static_table, {
            columns: $data.pieChartsStaticTable.columns,
            "dataset-table": $data.pieChartsStaticTable.dataset
          }, {
            text: _withCtx(function (_ref) {
              var slotData = _ref.slotData;
              return [_createVNode(_component_router_link, {
                to: slotData.url
              }, {
                default: _withCtx(function () {
                  return [_createElementVNode("span", {
                    class: "hover:underline",
                    title: slotData.titleParam,
                    innerHTML: slotData.title
                  }, null, 8 /* PROPS */, _hoisted_9)];
                }),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])];
            }),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["columns", "dataset-table"])])) : _createCommentVNode("v-if", true)];
        }),
        _: 1 /* STABLE */
      })])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["local-storage-key"])) : _createCommentVNode("v-if", true);
}