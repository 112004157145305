// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea[data-v-04a4505d]:hover:not(.disabled):not(.error) {
  box-shadow: 0px 0px 0px 2px rgb(235, 235, 235);
}
textarea[data-v-04a4505d]:focus:not(.disabled):not(.error) {
  box-shadow: 0px 0px 0px 2px rgba(33, 130, 253, 0.2);
}
textarea.error[data-v-04a4505d]:focus {
  box-shadow: 0px 0px 0px 2px rgba(242, 80, 80, 0.2);
}
textarea[data-v-04a4505d] {
  background-image: url("/assets/svg/stripes_corner.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 9px 9px;
}
textarea[data-v-04a4505d]::-webkit-resizer, textarea[data-v-04a4505d]::-webkit-scrollbar-corner {
  background-image: url("/assets/svg/stripes_corner.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 9px 9px;
}
.resize-none[data-v-04a4505d] {
  resize: none;
}
.resize-y[data-v-04a4505d] {
  resize: vertical;
}
.resize-x[data-v-04a4505d] {
  resize: horizontal;
}
.resize[data-v-04a4505d] {
  resize: both;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
