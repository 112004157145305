import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "risk-probability-assessment"
};
var _hoisted_2 = {
  key: 0,
  class: "risk-probability-header"
};
var _hoisted_3 = {
  class: "risk-probability-content"
};
var _hoisted_4 = {
  class: "flex flex-wrap justify-between mb-8 -mr-3"
};
var _hoisted_5 = {
  key: 0,
  class: "flex-grow w-3/10 min-w-150px mr-3 mb-3"
};
var _hoisted_6 = {
  key: 1,
  class: "flex-grow w-3/10 min-w-150px mr-3 mb-3"
};
var _hoisted_7 = {
  key: 2,
  class: "flex-grow w-3/10 min-w-150px mr-3 mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.isTitleShown ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.riskParameters,
    class: "main-title",
    type: "big"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [$props.threatShown ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_select, {
    title: $data.translate.threat,
    options: $props.threats,
    "selected-options": $data.threatsSelected,
    placeholder: $data.translate.selectThreat,
    "label-key": "label",
    "track-by": "value",
    disabled: $options.isThreatListDisabled,
    "show-labels": false,
    onSelected: $options.onTreatSelect
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "disabled", "onSelected"]), _createVNode(_component_tiny_mce_box, {
    type: "comment",
    height: "120px",
    "initial-content": $data.comments[$data.RiskBaseModelProps.THREAT_COMMENT],
    "read-only": $props.disabled,
    "always-open": !!$options.keepCommentFieldsExpanded,
    onChanged: _cache[0] || (_cache[0] = function ($event) {
      return $options.onCommentChanged($data.RiskBaseModelProps.THREAT_COMMENT, $event);
    })
  }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open"])])) : _createCommentVNode("v-if", true), $props.threatLevelShown ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_single_select, {
    modelValue: $data.levelOfThreatSelected,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
      return $data.levelOfThreatSelected = $event;
    }), $options.onSelectorChanged],
    title: $props.threatLevelTitle,
    placeholder: $options.threatLevelPlaceholder,
    options: $props.levelOfThreats,
    "un-sorted": $data.unSorted,
    disabled: $options.isLevelOfThreatsListDisabled
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options", "un-sorted", "disabled", "onUpdate:modelValue"]), _createVNode(_component_tiny_mce_box, {
    type: "comment",
    height: "120px",
    "initial-content": $data.comments[$data.RiskBaseModelProps.THREAT_LEVEL_COMMENT],
    "read-only": $props.disabled,
    "always-open": !!$options.keepCommentFieldsExpanded,
    onChanged: _cache[2] || (_cache[2] = function ($event) {
      return $options.onCommentChanged($data.RiskBaseModelProps.THREAT_LEVEL_COMMENT, $event);
    })
  }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open"])])) : _createCommentVNode("v-if", true), $props.vulnerabilityShown ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_select, {
    title: $data.translate.vulnerability,
    options: $props.vulnerabilities,
    "selected-options": $data.vulnerabilitiesSelected,
    placeholder: $data.translate.selectVulnerability,
    "label-key": "label",
    "track-by": "value",
    disabled: $options.isVulnerabilitiesListDisabled,
    "show-labels": false,
    onSelected: $options.onVulnerabilitySelect
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "disabled", "onSelected"]), _createVNode(_component_tiny_mce_box, {
    type: "comment",
    height: "120px",
    "initial-content": $data.comments[$data.RiskBaseModelProps.VULNERABILITY_COMMENT],
    "read-only": $props.disabled,
    "always-open": !!$options.keepCommentFieldsExpanded,
    onChanged: _cache[3] || (_cache[3] = function ($event) {
      return $options.onCommentChanged($data.RiskBaseModelProps.VULNERABILITY_COMMENT, $event);
    })
  }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open"])])) : _createCommentVNode("v-if", true)])])]);
}