import i18n from '@/i18n';
export var getTranslate = {
  ChatConfirmationDialog: function ChatConfirmationDialog() {
    return {
      cancel: i18n.t('Cancel'),
      confirm: i18n.t('Confirm'),
      pleaseConfirmThatYouWantToClear: i18n.t('Please confirm that you want to clear the chat.'),
      thisWillPermanentlyErase: i18n.t('This will permanently erase your chat history.'),
      pleaseConfirmThatYouWantToDelete: i18n.t('Please confirm that you want to close and delete the chat.'),
      termsOfService: i18n.t('Terms of Service'),
      youAreAboutToChangeRegion: i18n.t('You are about to change the region, which means that the previous session will be closed. Do you want to continue?'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      aiGeneratedContentMayBe: function aiGeneratedContentMayBe() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        return i18n.t('AI generated content may be incorrect and may not be relied upon. We advise you to not include personal data in your prompts. Please refer to our %s Terms and Conditions for RISMA AI Partner %s for further information.', args);
      }
    };
  }
};