import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_mini_tree = _resolveComponent("mini-tree");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [$data.showModal ? (_openBlock(), _createBlock(_component_modal, {
        key: 0,
        header: $options.modalHeader,
        "dismiss-on-click-outside": false,
        "button-ok-text": $options.modalOkText,
        "button-dismiss-text": $data.translate.cancel,
        type: "small",
        onAccept: $options.onAcceptModalClick,
        onDismiss: $options.toggleShowModal
      }, {
        body: _withCtx(function () {
          return [_createElementVNode("div", null, [_createVNode(_component_input_field, {
            modelValue: $data.newFrameworkData.title,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $data.newFrameworkData.title = $event;
            }),
            modelModifiers: {
              trim: true
            },
            title: $data.translate.title,
            invalid: !!$data.createErrorMessage,
            "error-help-text": $data.createErrorMessage,
            placeholder: $data.translate.enterTitle,
            "focus-on-mount": true,
            type: "text"
          }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "error-help-text", "placeholder"])])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        title: $data.translate.frameworkId,
        type: "big",
        class: "mb-5"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
        title: _ctx.$trans($data.description),
        truncate: false,
        type: "small",
        class: "mb-5"
      }, null, 8 /* PROPS */, ["title"]), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 1
      })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_mini_tree, {
        branch: $data.treeData,
        "show-tree-filters": false,
        "node-emits": $data.frameworkEmits,
        "add-new-node-label": $data.translate.createNewFramework,
        "local-storage-unique-key": "framework",
        onSelect: _ctx.selectPath,
        onAddNode: _cache[1] || (_cache[1] = function ($event) {
          return $options.toggleShowModal($event, 'create');
        }),
        onAddSubNode: _cache[2] || (_cache[2] = function ($event) {
          return $options.toggleShowModal($event, 'create');
        }),
        "onEdit:title": _cache[3] || (_cache[3] = function ($event) {
          return $options.toggleShowModal($event, 'update');
        }),
        "onEdit:enabled": $options.handleEnableUpdate,
        onDeleteNode: $options.handleDeleteNode
      }, null, 8 /* PROPS */, ["branch", "node-emits", "add-new-node-label", "onSelect", "onEdit:enabled", "onDeleteNode"])]))])];
    }),
    _: 1 /* STABLE */
  });
}