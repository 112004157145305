import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "governance-review-modal"
};
var _hoisted_2 = {
  class: "flex mt-6"
};
var _hoisted_3 = {
  class: "col-4 pr-2"
};
var _hoisted_4 = {
  class: "col-4 px-2"
};
var _hoisted_5 = {
  class: "col-4 pl-2"
};
var _hoisted_6 = {
  class: "flex mt-6"
};
var _hoisted_7 = {
  class: "col-4 pr-2"
};
var _hoisted_8 = {
  class: "col-4 px-2"
};
var _hoisted_9 = {
  class: "col-4 pl-2"
};
var _hoisted_10 = {
  class: "flex mt-6"
};
var _hoisted_11 = {
  class: "col-4 pr-2 relative"
};
var _hoisted_12 = {
  class: "col-4 px-2"
};
var _hoisted_13 = {
  class: "col-4 pl-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_single_select = _resolveComponent("single-select");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_deadline_preview = _resolveComponent("deadline-preview");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_help_tool_tip = _resolveComponent("help-tool-tip");
  var _component_modal = _resolveComponent("modal");
  return $data.currentReview ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$props.showModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    "button-ok-text": $data.translate.save,
    "button-dismiss-text": $data.translate.cancel,
    "dismiss-on-click-outside": false,
    "accept-on-enter": !$data.isRismaSelectOpen,
    header: "",
    type: "big",
    "modal-max-width": "1200px",
    onDismiss: $options.handleDismiss,
    onAccept: $options.handleAccept
  }, {
    body: _withCtx(function () {
      var _$data$errors, _$data$errors2, _$data$errors3, _$data$errors4;
      return [$data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        ref: "notificationError",
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.notificationMessage), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */)) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.currentReview.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.currentReview.title = $event;
        }),
        modelModifiers: {
          trim: true
        },
        "focus-on-mount": true,
        title: $data.translate.title,
        type: "text",
        invalid: (_$data$errors = $data.errors) === null || _$data$errors === void 0 ? void 0 : _$data$errors.title,
        "error-help-text": (_$data$errors2 = $data.errors) === null || _$data$errors2 === void 0 ? void 0 : _$data$errors2.title,
        class: "mb-6"
      }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "error-help-text"]), _createVNode(_component_tiny_mce_box, {
        type: "mini",
        height: "250px",
        headline: $data.translate.description,
        "always-open": true,
        "initial-content": $data.currentReview.description,
        onChanged: _cache[1] || (_cache[1] = function ($event) {
          return $data.currentReview.description = $event;
        })
      }, null, 8 /* PROPS */, ["headline", "initial-content"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
        modelValue: $data.currentReview.frequency,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.currentReview.frequency = $event;
        }),
        title: $data.translate.frequency,
        options: $options.frequencies,
        "un-sorted": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_date_time_picker, {
        modelValue: $options.deadline,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $options.deadline = $event;
        }),
        title: $data.translate.deadline
      }, null, 8 /* PROPS */, ["modelValue", "title"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
        title: $data.translate.deadlinePreview,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), $data.currentReview.deadline !== '' && $data.currentReview.frequency !== '' ? (_openBlock(), _createBlock(_component_deadline_preview, {
        key: 0,
        deadline: $data.currentReview.deadline,
        frequency: $data.currentReview.frequency,
        timezone: $options.timezone
      }, null, 8 /* PROPS */, ["deadline", "frequency", "timezone"])) : _createCommentVNode("v-if", true)])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_select, {
        title: $data.translate.responsible,
        options: $options.usersWithAccessToControls,
        "error-message": ((_$data$errors3 = $data.errors) === null || _$data$errors3 === void 0 ? void 0 : _$data$errors3.responsible) || '',
        "selected-options": $options.usersFromIds($data.currentReview.userIds.responsible),
        "label-key": "display_name",
        "open-direction": "top",
        onSelected: _cache[4] || (_cache[4] = function ($event) {
          return $data.currentReview.userIds.responsible = $event === null ? [] : $event.map(function (u) {
            return u.id;
          });
        }),
        onOpen: _cache[5] || (_cache[5] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[6] || (_cache[6] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["title", "options", "error-message", "selected-options"])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_select, {
        title: $data.translate.escalatingTo,
        options: $options.usersWithAccessToControls,
        "selected-options": $options.usersFromIds($data.currentReview.userIds.escalation),
        "label-key": "display_name",
        "open-direction": "top",
        onSelected: _cache[7] || (_cache[7] = function ($event) {
          return $data.currentReview.userIds.escalation = $event === null ? [] : $event.map(function (u) {
            return u.id;
          });
        }),
        onOpen: _cache[8] || (_cache[8] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[9] || (_cache[9] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["title", "options", "selected-options"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_select, {
        title: $data.translate.reviewer,
        options: $options.usersWithAccessToControls,
        "selected-options": $options.usersFromIds($data.currentReview.userIds.review),
        "label-key": "display_name",
        "open-direction": "top",
        onSelected: _cache[10] || (_cache[10] = function ($event) {
          return $data.currentReview.userIds.review = $event === null ? [] : $event.map(function (u) {
            return u.id;
          });
        }),
        onOpen: _cache[11] || (_cache[11] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[12] || (_cache[12] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["title", "options", "selected-options"])])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createVNode(_component_help_tool_tip, {
        class: "help-tool-tip right-0 mr-2"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.inOrderToSetReviewDeadlineYouHaveToSelec), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_date_time_picker, {
        modelValue: $options.revDeadline,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = function ($event) {
          return $options.revDeadline = $event;
        }),
        title: $data.translate.reviewDeadline,
        "plugin-options": $options.datePickerOption,
        disabled: $data.currentReview.userIds.review.length <= 0
      }, null, 8 /* PROPS */, ["modelValue", "title", "plugin-options", "disabled"])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_select, {
        title: $data.translate.organisation,
        options: $props.organisations,
        "error-message": ((_$data$errors4 = $data.errors) === null || _$data$errors4 === void 0 ? void 0 : _$data$errors4.organisation) || '',
        "selected-options": $options.organisationFromIds($data.currentReview.organisationIds),
        "label-key": "visible_name",
        "open-direction": "top",
        onSelected: _cache[14] || (_cache[14] = function ($event) {
          return $data.currentReview.organisationIds = $event === null ? [] : $event.map(function (o) {
            return o.id;
          });
        }),
        onOpen: _cache[15] || (_cache[15] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[16] || (_cache[16] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["title", "options", "error-message", "selected-options"])]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_risma_select, {
        title: $data.translate.tags,
        options: $options.tagsWithLockedTag,
        "selected-options": $data.currentReview.tags,
        "locked-options": $options.idsOfLockedTags,
        "label-key": "tag",
        "open-direction": "top",
        onSelected: $options.onTagSelected,
        onOpen: _cache[17] || (_cache[17] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[18] || (_cache[18] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["title", "options", "selected-options", "locked-options", "onSelected"])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["button-ok-text", "button-dismiss-text", "accept-on-enter", "onDismiss", "onAccept"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true);
}