import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$errorMessage;
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_switch = _resolveComponent("risma-switch");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_single_select, {
    "model-value": $props.selectedOptions[$data.DataFlowFields.CURRENT_DATA_SUBJECT],
    options: $props.data[$data.DataFlowFields.STAKEHOLDER_OPTIONS],
    class: "my-4",
    placeholder: $data.translate.choose + ' ' + $data.stakeholderName,
    "un-sorted": true,
    "required-text": ((_$props$errorMessage = $props.errorMessage) === null || _$props$errorMessage === void 0 ? void 0 : _$props$errorMessage.text) || '',
    "onUpdate:modelValue": $options.onOptionChange
  }, {
    singleLabel: _withCtx(function (_ref) {
      var option = _ref.option;
      return [option ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(option.groupName) + ": " + _toDisplayString(option.label), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model-value", "options", "placeholder", "required-text", "onUpdate:modelValue"]), _createVNode(_component_risma_title, {
    class: "mb-2",
    type: "medium",
    title: $data.translate.dataDirection
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_switch, {
    modelValue: $data.selectedDirection,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $data.selectedDirection = $event;
    }), _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('update:selected', {
        value: $event,
        field: $data.DataFlowFields.CURRENT_DATA_SUBJECT_DIRECTION
      });
    })],
    options: $data.dataDirectionOptions,
    class: "w-fit",
    "is-labels-show": true
  }, null, 8 /* PROPS */, ["modelValue", "options"])]);
}