import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  key: 0,
  class: "relative mb-3"
};
var _hoisted_3 = {
  class: "glob-l1-sb"
};
var _hoisted_4 = {
  class: "flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.attach + ' ' + $options.informationAssetsName,
    "button-ok-text": $data.translate.apply,
    "button-dismiss-text": $data.translate.cancel,
    "modal-fixed-width": "530px",
    onAccept: $options.updateLinks,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dismissModal');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_single_select, {
        modelValue: $data.selectedLinkOption,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.selectedLinkOption = $event;
        }),
        title: $options.selectIALabel,
        options: $options.linkOptions
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"])]), $data.selectedLinkOption ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_select, {
        options: $options.selectableItems,
        "selected-options": $data.selectedItems,
        placeholder: $options.selectLinkPlaceholder,
        title: $options.selectLinkLabel,
        "label-key": "title",
        "onUpdate:modelValue": $options.onLinkSelect
      }, _createSlots({
        tagLabelCustom: _withCtx(function (_ref) {
          var option = _ref.option;
          return [option.trafficLight !== undefined ? (_openBlock(), _createBlock(_component_traffic_light, {
            key: 0,
            color: _ctx.getTrafficLightColor(option),
            class: "inline-block align-baseline mr-2"
          }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(option.title || option.name), 1 /* TEXT */)];
        }),
        labelCustom: _withCtx(function (_ref2) {
          var option = _ref2.option;
          return [_createElementVNode("div", _hoisted_4, [option.trafficLight !== undefined && option.trafficLight !== null ? (_openBlock(), _createBlock(_component_traffic_light, {
            key: 0,
            color: _ctx.getTrafficLightColor(option),
            class: "inline-block align-baseline mr-2"
          }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(option.title || option.label), 1 /* TEXT */)])];
        }),
        _: 2 /* DYNAMIC */
      }, [$options.showCreateItemButton ? {
        name: "newOption",
        fn: _withCtx(function () {
          return [_createElementVNode("div", {
            class: "flex items-center cursor-pointer text-rm-signal-grey-dark hover:border-rm-text border-rm-signal-grey-dark hover:text-rm-text hover:underline",
            onClick: _cache[1] || (_cache[1] = function ($event) {
              return _ctx.$emit('showAssetCreationModal', $options.assetTypeBySelectedModule);
            })
          }, [_createVNode(_component_feather_icon, {
            icon: "plus",
            width: "18",
            height: "18",
            class: "border-2 stroke-2 border-inherit rounded-full mr-2"
          }), _createElementVNode("span", _hoisted_3, _toDisplayString($data.translate.createNew), 1 /* TEXT */)])];
        }),
        key: "0"
      } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["options", "selected-options", "placeholder", "title", "onUpdate:modelValue"])])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}