function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { OBJ_TYPE_TO_MODULE, ObjectTypes } from '@/constants/ObjectTypes';
import { saveStatus as _saveStatus } from '@/constants/SaveStatuses';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { getAllTrees, getNodesByProcessTreeId, getInformationAssets } from '@/api/risma/data';
import { getAllQuestionAnswersForNode, updateQuestionForNode, updateContractQuestion as _updateContractQuestion, getInformationAssetQuestionAnswersByType, getAllQuestionAnswersForContract, getAllQuestionAnswersForRisk, getAllQuestionAnswersForIncident, updateIncidentQuestion as _updateIncidentQuestion, updateRiskQuestion as _updateRiskQuestion } from '@/api/compliance/questionnaire';
import { getAllByType, getSingleByType, updateIAQuestionByTypeIdAndQuestionId } from '@/api/compliance/informationAssets';
import { logReport as _logReport } from '@/api/reports/reports';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Questionnaire from '@/components/Organisms/Compliance/questionnaire';
import Notification from '@/components/Molecules/Notification';
import { UserLevels, UserAccessFieldByModule } from '@/constants/UserLevels';
import { MODULES } from '@/constants/modules';
export default {
  name: 'QuestionnairePage',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Questionnaire: Questionnaire,
    Notification: Notification
  },
  introduction: 'This component is just a simple page for the questionnaire',
  description: 'Use this component on questionnaire pages',
  token: '<questionnaire-page></questionnaire-page>',
  props: {
    data: {
      required: false,
      type: Object,
      default: function _default() {},
      note: 'Current node object'
    },
    questionnaireId: {
      required: false,
      type: [String, Number],
      default: '',
      note: 'Current questionnaireId'
    },
    saveStatus: {
      type: String,
      required: false,
      default: _saveStatus.SAVED
    }
  },
  emits: ['update:isPageWithCustomUpdateDirty', 'updateValue', 'saveStatusClicked', 'questionAnswered'],
  data: function data() {
    return {
      questions: [],
      questionsSnap: [],
      errorDetails: '',
      queue: [],
      informationAssets: null,
      myInformationAssetsByType: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, AssetsTypes.SYSTEMS, []), AssetsTypes.CONTROLLERS, []), AssetsTypes.PROCESSORS, []), AssetsTypes.INFORMATION_ASSETS_API, []),
      isLoaded: false,
      nodeElement: {},
      processTreeId: null,
      item: this.data,
      isQueueStopped: false,
      questionTypes: QuestionTypes(),
      translate: {
        thereWasAnErrorSavingYourAnswerToQuestion: this.$trans('There was an error saving your answer to question number: '),
        questionnaire: this.$trans('Questionnaire')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserComplianceProjectById: 'complianceProjectById',
    userHasAccessLevel: 'userHasAccessLevel',
    riskProjectById: 'riskProjectById'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'complianceIACustomActivityTypes'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    showExportAsCsvButton: function showExportAsCsvButton() {
      var supportedTypes = [AssetsTypes.SYSTEMS, AssetsTypes.PROCESSORS, AssetsTypes.CONTROLLERS, AssetsTypes.INFORMATION_ASSETS_API];
      return !!this.getSettingValue('feature.compliance.ia_csv_export') && supportedTypes.includes(this.pageType);
    },
    currentLocation: function currentLocation() {
      return [this.pageType, this.data.title || this.data.name, this.translate.questionnaire, this.rightQuestionnaire.title];
    },
    dataModule: function dataModule() {
      return OBJ_TYPE_TO_MODULE[this.data.objType] || this.pageType;
    },
    rightQuestionnaire: function rightQuestionnaire() {
      var _this = this;
      var questionnaire = this.item.questionnaires.find(function (item) {
        return item.questionnaireId === +_this.questionnaireId;
      });
      if (!questionnaire) questionnaire = this.item.questionnaire;
      return questionnaire;
    },
    isReadOnly: function isReadOnly() {
      if (this.dataModule === MODULES.COMPLIANCE || this.dataModule === MODULES.RISK) {
        var project = this.dataModule === MODULES.COMPLIANCE ? this.currentUserComplianceProjectById(this.data.compliance_projects_id) : this.riskProjectById(this.data.projectId);
        if (!project) return false;
        return project.locked;
      }
      var field = UserAccessFieldByModule[this.dataModule];
      return !!this.currentUser["".concat(field, "_locked")];
    },
    getQuestionnaireAPI: function getQuestionnaireAPI() {
      switch (this.data.objType) {
        case ObjectTypes.COMPLIANCE_NODE:
          return this.getComplianceQuestionAnswers;
        case ObjectTypes.CONTRACT:
          return this.getContractQuestionAnswers;
        case ObjectTypes.INCIDENTS_INCIDENT:
          return this.getIncidentQuestionAnswers;
        case ObjectTypes.RISK_RISK:
          return this.getRiskQuestionAnswers;
        default:
          return this.getIAQuestionAnswers;
      }
    },
    updateQuestionAPI: function updateQuestionAPI() {
      switch (this.data.objType) {
        case ObjectTypes.COMPLIANCE_NODE:
          return this.updateComplianceQuestion;
        case ObjectTypes.CONTRACT:
          return this.updateContractQuestion;
        case ObjectTypes.INCIDENTS_INCIDENT:
          return this.updateIncidentQuestion;
        case ObjectTypes.RISK_RISK:
          return this.updateRiskQuestion;
        default:
          return this.updateIAQuestion;
      }
    },
    pageType: function pageType() {
      var objType = this.data.objType;
      switch (objType) {
        case ObjectTypes.COMPLIANCE_NODE:
          return 'compliance';
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM:
          return AssetsTypes.SYSTEMS;
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR:
          return AssetsTypes.PROCESSORS;
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER:
          return AssetsTypes.CONTROLLERS;
        case ObjectTypes.INFORMATION_ASSET:
          return AssetsTypes.INFORMATION_ASSETS_API;
        case ObjectTypes.CONTRACT:
          return 'contract';
        case ObjectTypes.INCIDENTS_INCIDENT:
          return 'incident';
        case ObjectTypes.RISK_RISK:
          return 'risk';
        default:
          throw new Error('Unsupported objType');
      }
    },
    needMyInformationAssets: function needMyInformationAssets() {
      return this.getSettingValue('feature.ia_limit_access_in_question') && !this.userHasAccessLevel('level_information_assets', UserLevels.SUPER);
    }
  }),
  watch: {
    questionnaireId: function questionnaireId() {
      var _this2 = this;
      this.isLoaded = false;
      this.getAll().then(function () {
        _this2.isLoaded = true;
      });
    },
    saveStatus: function saveStatus(value) {
      if (value === _saveStatus.SAVING) {
        this.isQueueStopped = false;
        this.runQueue();
      } else if (value === _saveStatus.NOT_SAVED) {
        this.isQueueStopped = true;
      }
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    this.getAll().then(function () {
      _this3.isLoaded = true;
    });
  },
  methods: {
    getAll: function getAll() {
      var promises = [this.getQuestionnaire(), this.getAllInformationAssets(), this.getNodeElementAllData()];
      if (this.needMyInformationAssets) promises.push(this.getMyInformationAssets());
      return Promise.all(promises);
    },
    getDataForIA: function getDataForIA() {
      var _this4 = this;
      return getSingleByType(this.pageType, this.data.id).then(function (response) {
        return _this4.item = response;
      });
    },
    getNodeElementAllData: function getNodeElementAllData() {
      return getAllTrees().then(this.setIdToProcessTreeIdAndGetNodes);
    },
    setIdToProcessTreeIdAndGetNodes: function setIdToProcessTreeIdAndGetNodes(response) {
      var _this5 = this;
      if (response[0].id !== null) {
        this.processTreeId = response[0].id;
      }
      return getNodesByProcessTreeId(this.processTreeId).then(function (response) {
        return _this5.nodeElement = response;
      });
    },
    getMyInformationAssets: function getMyInformationAssets() {
      var promises = [this.getMyInformationAssetsByType(AssetsTypes.SYSTEMS), this.getMyInformationAssetsByType(AssetsTypes.CONTROLLERS), this.getMyInformationAssetsByType(AssetsTypes.PROCESSORS), this.getMyInformationAssetsByType(AssetsTypes.INFORMATION_ASSETS_API)];
      return Promise.all(promises);
    },
    getMyInformationAssetsByType: function getMyInformationAssetsByType(type) {
      var _this6 = this;
      return getAllByType(type).then(function (_ref) {
        var list = _ref.list;
        return _this6.myInformationAssetsByType[type] = list;
      });
    },
    getAllInformationAssets: function getAllInformationAssets() {
      var _this7 = this;
      return getInformationAssets().then(function (_ref2) {
        var list = _ref2.list;
        return _this7.informationAssets = list;
      });
    },
    getQuestionnaire: function getQuestionnaire() {
      var _this8 = this;
      return this.getQuestionnaireAPI().then(function (_ref3) {
        var list = _ref3.list;
        _this8.questions = list;
        _this8.questionsSnap = _toConsumableArray(list);
      });
    },
    getComplianceQuestionAnswers: function getComplianceQuestionAnswers() {
      return getAllQuestionAnswersForNode(this.data.compliance_projects_id, this.data.id, this.questionnaireId);
    },
    getContractQuestionAnswers: function getContractQuestionAnswers() {
      return getAllQuestionAnswersForContract(this.data.id, this.questionnaireId);
    },
    getIncidentQuestionAnswers: function getIncidentQuestionAnswers() {
      return getAllQuestionAnswersForIncident(this.data.id, this.questionnaireId);
    },
    getRiskQuestionAnswers: function getRiskQuestionAnswers() {
      return getAllQuestionAnswersForRisk(this.data.projectId, this.data.projectPeriodId, this.data.rno, this.questionnaireId);
    },
    getIAQuestionAnswers: function getIAQuestionAnswers() {
      return getInformationAssetQuestionAnswersByType(this.pageType, this.data.id, this.questionnaireId);
    },
    logReport: function logReport(count) {
      var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      return _logReport(this.dataModule, count, this.currentLocation, {}, format);
    },
    handleNewAsset: function handleNewAsset(_ref4) {
      var data = _ref4.data,
        type = _ref4.type,
        questionId = _ref4.questionId;
      this.informationAssets = [].concat(_toConsumableArray(this.informationAssets), [data]);
      var questionIdx = this.questions.findIndex(function (qu) {
        return +qu.id === +questionId;
      });
      if (questionIdx !== -1) {
        var updatedQuestion = JSON.parse(JSON.stringify(this.questions[questionIdx]));
        updatedQuestion.informationAssets[type].push(data);
        this.questions[questionIdx] = updatedQuestion;
        this.prepareAndUpdateQuestion(updatedQuestion);
      }
    },
    prepareAndUpdateQuestion: function prepareAndUpdateQuestion(question) {
      /* questionnaire would always show answered questions, but if something wrong -> take previous version of the answer from questionSnap */
      var index = this.questions.findIndex(function (item) {
        return item.id === question.id;
      });
      this.questions[index] = _objectSpread({}, question);
      this.questions.splice(); //doesn`t see question upd

      var preparedQuestion = this.prepareQuestionForUpdate(question);
      this.queue = this.prepareQueue(this.queue, preparedQuestion);
      var isForced = question.questiontype !== this.questionTypes.multipleChoice.id && question.questiontype !== this.questionTypes.informationAsset.id && question.questiontype !== this.questionTypes.informationAssetSystems.id && question.questiontype !== this.questionTypes.informationAssetProcessors.id && question.questiontype !== this.questionTypes.informationAssetControllers.id && question.questiontype !== this.questionTypes.processLibrary.id && question.questiontype !== this.questionTypes.customInformationAsset.id && question.questiontype !== this.questionTypes.date.id && question.questiontype !== this.questionTypes.freeText.id && question.questiontype !== this.questionTypes.attachmentAndFreeText.id && question.questiontype !== this.questionTypes.dateTime.id;
      this.$emit('update:isPageWithCustomUpdateDirty', {
        value: true,
        isForced: isForced
      });
    },
    prepareQueue: function prepareQueue(queue, inputItem) {
      var mapQueue = new Map(queue.map(function (item) {
        return [item.id, item];
      }));
      mapQueue.set(inputItem.id, inputItem);
      return _toConsumableArray(mapQueue.values());
    },
    runQueue: function runQueue() {
      if (this.queue.length > 0) {
        var preparedQuestion = this.queue.shift();
        this.updateQuestionFromQueue(preparedQuestion);
      } else {
        this.$emit('update:isPageWithCustomUpdateDirty', {
          value: false
        });
      }
    },
    prepareQuestionForUpdate: function prepareQuestionForUpdate(question) {
      var preparedQuestion = {};
      preparedQuestion.id = question.id;
      preparedQuestion.answers_options = question.answers_options;
      if (question.questiontype === 12) {
        preparedQuestion.processLibraryId = [];
        if (Object.keys(preparedQuestion.answers_options)[0] !== 'null') {
          // check for empty answers_options
          Object.keys(preparedQuestion.answers_options).forEach(function (element) {
            preparedQuestion.processLibraryId.push(element);
          });
        }
        delete preparedQuestion.answers_options;
      }
      if (question.informationAssets) {
        preparedQuestion.informationAssets = {};
        Object.keys(question.informationAssets).forEach(function (iaKey) {
          preparedQuestion.informationAssets[iaKey] = question.informationAssets[iaKey].map(function (ia) {
            return +ia.id;
          });
        });
      }
      if (question.freeText !== undefined) {
        preparedQuestion.freeText = question.freeText;
      }
      preparedQuestion.files = question.files;
      preparedQuestion.questionnumber = question.questionnumber;
      preparedQuestion.dateTime = question.dateTime;
      return preparedQuestion;
    },
    updateQuestionFromQueue: function updateQuestionFromQueue(question) {
      var _this9 = this;
      this.updateQuestion(question).then(function () {
        if (!_this9.isQueueStopped) {
          //if new answer came while upd
          _this9.runQueue();
        }
      }).catch(function (error) {
        var text = error.response && error.response.error || error;
        _this9.errorDetails = _this9.translate.thereWasAnErrorSavingYourAnswerToQuestion + question.questionnumber + '.<br>' + text;
        setTimeout(function () {
          _this9.errorDetails = '';
        }, _this9.$debounceTimeout * 3);
      });
    },
    updateComplianceQuestion: function updateComplianceQuestion(question) {
      return updateQuestionForNode(this.data.compliance_projects_id, this.data.id, question);
    },
    updateContractQuestion: function updateContractQuestion(question) {
      return _updateContractQuestion(this.data.id, question);
    },
    updateIncidentQuestion: function updateIncidentQuestion(question) {
      return _updateIncidentQuestion(this.data.id, question);
    },
    updateRiskQuestion: function updateRiskQuestion(question) {
      return _updateRiskQuestion(this.data.projectId, this.data.projectPeriodId, this.data.rno, question);
    },
    updateIAQuestion: function updateIAQuestion(question) {
      return updateIAQuestionByTypeIdAndQuestionId(this.pageType, this.item.id, question.id, question);
    },
    updateQuestion: function updateQuestion(question) {
      var _this10 = this;
      var index = this.questions.findIndex(function (item) {
        return item.id === question.id;
      });
      return this.updateQuestionAPI(question).then(function (response) {
        _this10.questions[index] = response;
        _this10.questionsSnap[index] = response;
        _this10.$emit('questionAnswered');
        if (response.node && response.node.values) {
          _this10.$emit('updateValue', {
            property: 'values',
            value: response.node.values
          });
        }
      }).catch(function (error) {
        _this10.$emit('update:isPageWithCustomUpdateDirty', {
          value: !!_this10.queue.length
        });
        _this10.questions[index] = _objectSpread({}, _this10.questionsSnap[index]);
        _this10.questions.splice();
        throw error;
      });
    }
  }
};