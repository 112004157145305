import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { TOOLTIP_MODES, TOOLTIP_POSITIONS } from '@/constants/Tooltips';
export default {
  name: 'DynamicTooltip',
  introduction: 'tooltip with additional functionality',
  description: "The dynamic tooltip has two modes: 'primary' and 'secondary.'\n    Primary: This mode remains visible until it is closed.\n    Secondary: This mode functions like the original tooltip and appears on hover.",
  token: "<dynamic-tooltip :title=''>\n                <slot name=\"tooltipTrigger\" />\n                <slot name=\"tooltipBody\" />\n            </dynamic-tooltip>",
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    title: {
      type: String,
      required: true,
      default: '',
      node: 'the title used in the tooltip'
    },
    position: {
      type: String,
      required: false,
      default: TOOLTIP_POSITIONS.BOTTOM,
      validator: function validator(val) {
        return Object.values(TOOLTIP_POSITIONS).includes(val);
      },
      note: "Position of tooltip - 'left', 'top', 'right', 'bottom'"
    },
    initialMode: {
      type: String,
      required: false,
      default: TOOLTIP_MODES.PRIMARY,
      validator: function validator(val) {
        return Object.values(TOOLTIP_MODES).includes(val);
      }
    },
    tooltipStyles: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      notes: 'Updates the style of the tooltip box'
    }
  },
  emits: ['dismiss'],
  data: function data() {
    return {
      mode: TOOLTIP_MODES.PRIMARY
    };
  },
  computed: {
    primaryMode: function primaryMode() {
      return this.mode === TOOLTIP_MODES.PRIMARY;
    }
  },
  watch: {
    initialMode: {
      handler: function handler(value) {
        this.mode = value;
      },
      immediate: true
    }
  },
  methods: {
    dismiss: function dismiss() {
      var _this = this;
      this.mode = '';
      setTimeout(function () {
        _this.mode = TOOLTIP_MODES.SECONDARY;
        _this.$emit('dismiss');
      }, 100);
    }
  }
};