import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "glob-l1-sb"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_popup = _resolveComponent("risma-popup");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: "flex items-center cursor-pointer text-rm-signal-grey-dark hover:border-rm-text border-rm-signal-grey-dark hover:text-rm-text hover:underline",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.handleCreateNewOption && $options.handleCreateNewOption.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    width: "18",
    height: "18",
    class: "border-2 stroke-2 border-inherit rounded-full mr-2"
  }), _createElementVNode("span", _hoisted_1, _toDisplayString($props.addNewOptionLabel), 1 /* TEXT */)]), _createVNode(_component_risma_popup, {
    class: _normalizeClass(["w-64", {
      'left-0': $props.position === 'bottom'
    }]),
    "is-hidden": $data.isHiddenPopup,
    onHide: _cache[2] || (_cache[2] = function ($event) {
      return $data.isHiddenPopup = true;
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_input_field, {
        modelValue: $data.newOptionValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.newOptionValue = $event;
        }),
        title: $props.addNewOptionLabel,
        "focus-on-mount": true,
        class: "mb-2",
        type: "text",
        onOnEnter: $options.handleNewAnswer
      }, null, 8 /* PROPS */, ["modelValue", "title", "onOnEnter"]), _createVNode(_component_risma_button, {
        text: $data.translate.add,
        type: "save",
        onClick: $options.handleNewAnswer
      }, null, 8 /* PROPS */, ["text", "onClick"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class", "is-hidden"])]);
}