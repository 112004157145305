function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import DelegationList from '@/components/Atoms/DelegationList/DelegationList';
import DelegationModal from '@/components/Molecules/DelegationModal';
import { DelegationModalSettings, DelegationTypes } from '@/constants/relations/DelegationModal';
import { getContractTypes } from '@/constants/contracts/contractTypes';
export default {
  name: 'ContractTypeSelector',
  components: {
    SingleSelect: SingleSelect,
    DelegationList: DelegationList,
    DelegationModal: DelegationModal
  },
  props: {
    contractId: {
      type: Number,
      default: 0,
      note: 'used to search children availability list'
    },
    parentId: {
      type: Number,
      default: 0,
      note: 'used to search parent from the list'
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    contractType: {
      type: Number,
      required: true
    },
    contracts: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of all available contracts'
    }
  },
  emits: ['update:contractType', 'update:list'],
  data: function data() {
    return {
      contractTypes: getContractTypes(),
      showDelegationModal: false,
      translate: {
        contractType: this.$trans('Contract type')
      }
    };
  },
  computed: {
    hasDelegationList: function hasDelegationList() {
      return this.isOverarching || this.isSubsequentOrAssociated;
    },
    delegationSettings: function delegationSettings() {
      if (!this.hasDelegationList) return;
      return _objectSpread(_objectSpread({}, DelegationModalSettings[DelegationTypes.contract]), {}, {
        title: this.delegationListTitle,
        isMultiple: this.isOverarching
      });
    },
    isOverarching: function isOverarching() {
      return this.contractType === this.contractTypes.OVERARCHING.value;
    },
    isSubsequentOrAssociated: function isSubsequentOrAssociated() {
      return this.contractType === this.contractTypes.SUBSEQUENT_OR_ASSOCIATED.value;
    },
    delegationModalSelectedIds: function delegationModalSelectedIds() {
      return this.delegationListAvailableOptions.map(function (_ref) {
        var id = _ref.id;
        return id;
      });
    },
    delegationModalOptions: function delegationModalOptions() {
      if (!this.hasDelegationList) return [];
      if (this.isOverarching) return this.subsequentOptionsList;
      return this.availableParentContracts;
    },
    delegationListAvailableOptions: function delegationListAvailableOptions() {
      if (!this.hasDelegationList) return [];
      if (this.isOverarching) return this.subsequentSelectedContractsList;
      return [this.parentContract];
    },
    delegationListTitle: function delegationListTitle() {
      var _this = this;
      return Object.values(this.contractTypes).find(function (_ref2) {
        var value = _ref2.value;
        return value === _this.contractType;
      }).value === this.contractTypes.SUBSEQUENT_OR_ASSOCIATED.value ? this.contractTypes.OVERARCHING.label : this.contractTypes.SUBSEQUENT_OR_ASSOCIATED.label;
    },
    contractTypeOptions: function contractTypeOptions() {
      return Object.values(this.contractTypes);
    },
    parentContract: function parentContract() {
      var _this2 = this;
      if (!this.isSubsequentOrAssociated || !this.parentId) return '';
      return this.contracts.find(function (contract) {
        return contract.id === _this2.parentId;
      });
    },
    availableParentContracts: function availableParentContracts() {
      var _this3 = this;
      return this.contracts.filter(function (contract) {
        return contract.contractType === _this3.contractTypes.OVERARCHING.value && contract.id !== _this3.contractId;
      });
    },
    subsequentSelectedContractsList: function subsequentSelectedContractsList() {
      var _this4 = this;
      if (!this.isOverarching) return [];
      return this.contracts.filter(function (contract) {
        return contract.parentId === _this4.contractId;
      });
    },
    subsequentOptionsList: function subsequentOptionsList() {
      var _this5 = this;
      return this.contracts.filter(function (contract) {
        return contract.contractType === _this5.contractTypes.SUBSEQUENT_OR_ASSOCIATED.value && (contract.parentId === _this5.contractId || !contract.parentId) && contract.id !== _this5.contractId;
      });
    }
  },
  methods: {
    editHandle: function editHandle() {
      this.showDelegationModal = true;
    },
    onApplyChanges: function onApplyChanges(list) {
      this.$emit('update:list', list);
      this.showDelegationModal = false;
    }
  }
};