import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  class: "relative mb-3"
};
var _hoisted_3 = {
  class: "glob-l1-sb"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_single_select = _resolveComponent("single-select");
  var _component_category_modal = _resolveComponent("category-modal");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $options.modalHeaderTitle,
    "button-ok-text": $options.buttonOkLabel,
    "button-dismiss-text": $data.translate.close,
    onAccept: $options.handleAccept,
    onDismiss: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        "model-value": $data.title,
        title: $data.translate.title,
        "focus-on-mount": true,
        "pre-text": $data.title,
        placeholder: $data.translate.enterTitle,
        type: "text",
        invalid: !$data.isTitleValid,
        "error-help-text": $data.translate.titleCannotBeEmpty,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.title = $event;
        })
      }, null, 8 /* PROPS */, ["model-value", "title", "pre-text", "placeholder", "invalid", "error-help-text"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
        options: $options.selectableCategories,
        title: $data.translate.selectCategory,
        "model-value": $data.selectedCategoryId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.selectedCategoryId = $event;
        })
      }, {
        newOption: _withCtx(function () {
          return [_createElementVNode("div", {
            class: "flex items-center cursor-pointer text-rm-signal-grey-dark hover:border-rm-text border-rm-signal-grey-dark hover:text-rm-text hover:underline",
            onClick: _cache[1] || (_cache[1] = function () {
              return $options.openCreationModal && $options.openCreationModal.apply($options, arguments);
            })
          }, [_createVNode(_component_feather_icon, {
            icon: "plus",
            width: "18",
            height: "18",
            class: "border-2 stroke-2 border-inherit rounded-full mr-2"
          }), _createElementVNode("span", _hoisted_3, _toDisplayString($data.translate.createNew), 1 /* TEXT */)])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["options", "title", "model-value"])])]), $data.categoryModalShown ? (_openBlock(), _createBlock(_component_category_modal, {
        key: 0,
        onCreate: $options.handleCreateCategory,
        onDismiss: _cache[3] || (_cache[3] = function ($event) {
          return $data.categoryModalShown = false;
        })
      }, null, 8 /* PROPS */, ["onCreate"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}