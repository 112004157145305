function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  class: "flex justify-end mt-5"
};
var _hoisted_3 = {
  class: "mb-3 glob-h2 text-rm-dark-blue-navy"
};
var _hoisted_4 = {
  class: "mb-2"
};
var _hoisted_5 = {
  class: "grid grid-cols-3 gap-6 mb-3"
};
var _hoisted_6 = {
  class: "relative"
};
var _hoisted_7 = {
  class: "relative"
};
var _hoisted_8 = {
  key: 0,
  class: "pl-3 text-sm text-red-50"
};
var _hoisted_9 = {
  class: "flex gap-x-3"
};
var _hoisted_10 = {
  key: 0,
  class: "pl-3 text-sm text-red-50"
};
var _hoisted_11 = {
  key: 4,
  class: "mt-8"
};
var _hoisted_12 = {
  class: "flex items-center mb-2"
};
var _hoisted_13 = {
  class: "mr-2 glob-h2 text-rm-dark-blue-navy"
};
var _hoisted_14 = {
  key: 0,
  class: "mt-6"
};
var _hoisted_15 = {
  class: "grid grid-cols-3 gap-x-6 gap-y-6"
};
var _hoisted_16 = {
  class: "flex items-center mb-2 mt-8"
};
var _hoisted_17 = {
  class: "flex"
};
var _hoisted_18 = {
  class: "mr-2 mb-3 glob-h2 text-rm-dark-blue-navy"
};
var _hoisted_19 = {
  key: 0,
  class: "flex justify-end ml-auto"
};
var _hoisted_20 = {
  class: "w-250px pr-5 py-1 text-left"
};
var _hoisted_21 = {
  class: "pl-1 pr-5 py-1 text-left"
};
var _hoisted_22 = {
  class: "pr-5 py-1"
};
var _hoisted_23 = {
  class: "pl-1 pr-5 py-1"
};
var _hoisted_24 = {
  key: 0,
  class: "flex justify-end ml-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_cvr_modal = _resolveComponent("cvr-modal");
  var _component_company_wizard_step_l_e_i = _resolveComponent("company-wizard-step-l-e-i");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_modal = _resolveComponent("modal");
  var _component_company_link_modal = _resolveComponent("company-link-modal");
  var _component_information_asset_creation_modal = _resolveComponent("information-asset-creation-modal");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_input_field_select = _resolveComponent("input-field-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_single_select = _resolveComponent("single-select");
  var _component_input_field = _resolveComponent("input-field");
  var _component_help_tool_tip = _resolveComponent("help-tool-tip");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_router_link = _resolveComponent("router-link");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$data.showCVRModal ? (_openBlock(), _createBlock(_component_cvr_modal, {
    key: 0,
    "modal-data": $data.modalCVRData,
    onAccept: $options.onCVRModalSubmit,
    onDismiss: $options.resetCVRModal
  }, null, 8 /* PROPS */, ["modal-data", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), $data.showLeiSelectModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 1,
    header: $data.translate.dataFetchedFromLEIRegister,
    onDismiss: $options.onLeiSelectModalDismiss
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_company_wizard_step_l_e_i, {
        "selected-options": _defineProperty({}, $data.CompanyWizardFields.COMPANY_OBJECT, $props.data),
        "onUpdate:selected": $options.onLeiUpdated
      }, null, 8 /* PROPS */, ["selected-options", "onUpdate:selected"])];
    }),
    footer: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [$data.leiFailedToLoad === null ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
        key: 0
      })) : (_openBlock(), _createBlock(_component_buttons_row, {
        key: 1,
        data: $options.buttonsRowData,
        onKeyup: _cache[0] || (_cache[0] = _withKeys(_withModifiers(function () {}, ["stop"]), ["enter"]))
      }, null, 8 /* PROPS */, ["data"]))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "onDismiss"])) : _createCommentVNode("v-if", true), $data.showIALinkModal ? (_openBlock(), _createBlock(_component_company_link_modal, {
    key: 2,
    "linked-information-assets": $props.data.connectedInformationAssets,
    onDismissModal: _cache[1] || (_cache[1] = function ($event) {
      return $data.showIALinkModal = false;
    }),
    onShowAssetCreationModal: $options.onShowAssetCreationModal,
    onUpdateLinks: $options.onUpdateIALinks
  }, null, 8 /* PROPS */, ["linked-information-assets", "onShowAssetCreationModal", "onUpdateLinks"])) : _createCommentVNode("v-if", true), $data.showAssetCreationModal ? (_openBlock(), _createBlock(_component_information_asset_creation_modal, {
    key: 3,
    type: $data.createAssetType,
    "data-processors": $data.dataProcessors,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return $data.showAssetCreationModal = false;
    }),
    onCreated: $options.onNewAssetCreated
  }, null, 8 /* PROPS */, ["type", "data-processors", "onCreated"])) : _createCommentVNode("v-if", true), _createElementVNode("h2", _hoisted_3, _toDisplayString($data.translate.companyMasterData), 1 /* TEXT */), _createElementVNode("div", _hoisted_4, [_createVNode(_component_tiny_mce_box, {
    type: "mini",
    height: "250px",
    "editor-wrapper-class": "mt-2",
    headline: $data.translate.description,
    "read-only": $props.readOnly,
    "initial-content": $props.data.description,
    onChanged: $options.changeDescription
  }, null, 8 /* PROPS */, ["headline", "read-only", "initial-content", "onChanged"])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_input_field_select, {
    title: $data.translate.vatNumber,
    "input-value": _ctx.vatNo,
    "option-value": _ctx.countryCode,
    "input-placeholder": $data.translate.vatNumber,
    options: _ctx.countries,
    maxlength: $data.fieldMaxSymbols,
    "input-enabled": !$props.readOnly,
    "option-enabled": !$props.readOnly,
    "with-search": true,
    type: "text",
    class: "col-span-2",
    invalid: !$options.isValidVatNo,
    "error-help-text": $data.translate.invalidVatNumber,
    onInputChange: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.vatNo = $event;
    }),
    onOptionChange: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.countryCode = $event;
    }),
    onOnEnter: $options.handleGetInfo
  }, null, 8 /* PROPS */, ["title", "input-value", "option-value", "input-placeholder", "options", "maxlength", "input-enabled", "option-enabled", "invalid", "error-help-text", "onOnEnter"]), !$props.readOnly ? (_openBlock(), _createElementBlock("p", {
    key: 0,
    class: _normalizeClass(["absolute right-0 top-0 text-sm text-gray-450 hover:text-rm-text flex items-center", $options.isGetInfoDisabled ? 'cursor-not-allowed' : 'cursor-pointer']),
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return $options.handleGetInfo($options.isGetInfoDisabled);
    })
  }, [_createElementVNode("span", null, _toDisplayString($data.translate.getData), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    icon: "download",
    class: "stroke-2 ml-2"
  })], 2 /* CLASS */)) : _createCommentVNode("v-if", true)]), _cache[23] || (_cache[23] = _createElementVNode("div", null, null, -1 /* HOISTED */)), _cache[24] || (_cache[24] = _createElementVNode("div", null, null, -1 /* HOISTED */)), _createVNode(_component_single_select, {
    "model-value": _ctx.parentId,
    title: $data.translate.parentCompany,
    options: $options.parentOptions,
    placeholder: $data.translate.chooseParentCompany,
    "with-reset": true,
    disabled: $props.readOnly,
    "with-search": true,
    "onUpdate:modelValue": $options.handleParentSelection
  }, null, 8 /* PROPS */, ["model-value", "title", "options", "placeholder", "disabled", "onUpdate:modelValue"]), _createVNode(_component_single_select, {
    "model-value": _ctx.companyType,
    title: $data.translate.associationToParent,
    options: $options.companyTypeOptions,
    placeholder: $data.translate.chooseAssociationToParent,
    "with-reset": true,
    disabled: !_ctx.parentId || $props.readOnly,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return _ctx.companyType = $event;
    })
  }, null, 8 /* PROPS */, ["model-value", "title", "options", "placeholder", "disabled"]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_input_field, _mergeProps({
    modelValue: _ctx.lei,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return _ctx.lei = $event;
    }),
    invalid: !$options.isValidLEI,
    "allowed-symbols-regex": "[a-zA-Z0-9]",
    enabled: !$props.readOnly
  }, $data.LEI_INPUT_PROPS), null, 16 /* FULL_PROPS */, ["modelValue", "invalid", "enabled"]), !$options.isValidLEI ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString($data.translate.leiNumberMustBeExactly20), 1 /* TEXT */)) : _createCommentVNode("v-if", true), !$props.readOnly ? (_openBlock(), _createElementBlock("p", {
    key: 1,
    class: "absolute right-0 top-0 text-sm text-gray-450 hover:text-rm-text cursor-pointer flex items-center",
    onClick: _cache[8] || (_cache[8] = function ($event) {
      return $data.showLeiSelectModal = true;
    })
  }, [_createElementVNode("span", null, _toDisplayString($data.translate.getLEINumber), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    icon: "download",
    class: "stroke-2 ml-2"
  })])) : _createCommentVNode("v-if", true)]), _createVNode(_component_input_field, {
    modelValue: _ctx.address,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
      return _ctx.address = $event;
    }),
    title: $data.translate.address,
    maxlength: $data.fieldMaxSymbols,
    "is-static-height": true,
    enabled: !$props.readOnly,
    placeholder: $data.translate.address,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "maxlength", "enabled", "placeholder"]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_input_field, {
    modelValue: _ctx.zipCode,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = function ($event) {
      return _ctx.zipCode = $event;
    }),
    title: $data.translate.zipcode,
    class: "w-1/2",
    enabled: !$props.readOnly,
    maxlength: $data.fieldMaxSymbols,
    "is-static-height": true,
    placeholder: $data.translate.zipcode,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "enabled", "maxlength", "placeholder"]), _createVNode(_component_input_field, {
    modelValue: _ctx.city,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = function ($event) {
      return _ctx.city = $event;
    }),
    title: $data.translate.city,
    class: "w-1/2",
    enabled: !$props.readOnly,
    maxlength: $data.fieldMaxSymbols,
    "is-static-height": true,
    placeholder: $data.translate.city,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "enabled", "maxlength", "placeholder"])]), _createVNode(_component_input_field, {
    modelValue: _ctx.phone,
    "onUpdate:modelValue": _cache[12] || (_cache[12] = function ($event) {
      return _ctx.phone = $event;
    }),
    title: $data.translate.phone,
    maxlength: $data.fieldMaxSymbols,
    "is-static-height": true,
    placeholder: $data.translate.phone,
    enabled: !$props.readOnly,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "maxlength", "placeholder", "enabled"]), _createElementVNode("div", null, [_createVNode(_component_input_field, {
    modelValue: $props.data.email,
    "onUpdate:modelValue": [_cache[13] || (_cache[13] = function ($event) {
      return $props.data.email = $event;
    }), $options.updateEmail],
    title: $data.translate.email,
    placeholder: $data.translate.email,
    "is-static-height": true,
    maxlength: $data.fieldMaxSymbols,
    invalid: !$options.isValidCompanyEmail,
    enabled: !$props.readOnly,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "maxlength", "invalid", "enabled", "onUpdate:modelValue"]), !$options.isValidCompanyEmail ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString($data.translate.invalidEmailAddress), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])]), $options.isDoraVisible ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_12, [_createElementVNode("h2", _hoisted_13, _toDisplayString($data.translate.doraRegistry), 1 /* TEXT */), _createVNode(_component_help_tool_tip, {
    "tooltip-classes": "w-max",
    "react-on-click": true
  }, {
    "tooltip-body": _withCtx(function () {
      return _cache[25] || (_cache[25] = [_createElementVNode("div", {
        class: _normalizeClass(['flex items-center justify-center w-4 h-4 border-2 rounded-full text-0.7rem', 'border-rm-dark-blue-navy text-center leading-3 font-semibold cursor-pointer'])
      }, " i ", -1 /* HOISTED */)]);
    }),
    default: _withCtx(function () {
      return [_createElementVNode("span", null, _toDisplayString($data.translate.onlyCheckIf), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })]), _createElementVNode("div", null, [_createVNode(_component_checkboxes, {
    "model-value": $options.doraEnabledFlag,
    "label-position": "right",
    "label-type": "small",
    class: "-ml-2",
    disabled: $props.readOnly,
    "checkbox-size": 20,
    options: $options.doraOptions,
    onUpdated: $options.handleDoraChange
  }, null, 8 /* PROPS */, ["model-value", "disabled", "options", "onUpdated"])])]), _ctx.doraEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createVNode(_component_single_select, {
    "model-value": _ctx.ebaCompanyType,
    disabled: $props.readOnly,
    "with-reset": true,
    title: $data.translate.companyType + ' (01.02.0040)',
    options: $options.ebaCompanyTypeOptions,
    placeholder: $data.translate.chooseCompanyType,
    "onUpdate:modelValue": _cache[14] || (_cache[14] = function ($event) {
      return _ctx.ebaCompanyType = $event;
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "title", "options", "placeholder"]), _createVNode(_component_single_select, {
    "model-value": _ctx.ebaCompanyHierarchy,
    disabled: $props.readOnly,
    "with-reset": true,
    placeholder: $data.translate.chooseCompanyHierarchy,
    title: $data.translate.companyHierarchy + ' (01.02.0050)',
    options: $options.companyHierarchyOptions,
    "onUpdate:modelValue": _cache[15] || (_cache[15] = function ($event) {
      return _ctx.ebaCompanyHierarchy = $event;
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "placeholder", "title", "options"]), _createVNode(_component_input_field, {
    modelValue: _ctx.ebaBranchCode,
    "onUpdate:modelValue": _cache[16] || (_cache[16] = function ($event) {
      return _ctx.ebaBranchCode = $event;
    }),
    enabled: !$props.readOnly,
    title: $data.translate.branchCode + ' (01.03.0010)',
    placeholder: $data.translate.branchCode,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "enabled", "title", "placeholder"]), _createVNode(_component_input_field, {
    modelValue: _ctx.ebaCompetentAuthority,
    "onUpdate:modelValue": _cache[17] || (_cache[17] = function ($event) {
      return _ctx.ebaCompetentAuthority = $event;
    }),
    enabled: !$props.readOnly,
    title: $data.translate.competentAuthority + ' (01.01.0050)',
    placeholder: $data.translate.competentAuthority,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "enabled", "title", "placeholder"]), _createVNode(_component_input_field_select, {
    title: $data.translate.totalAssetsValue + ' (01.02.0110)',
    "input-value": _ctx.ebaTotalAssetsValue,
    "option-value": _ctx.ebaTotalAssetsCurrency,
    "input-placeholder": $data.translate.totalAssetsValue,
    options: $options.ebaCurrencyOptions,
    maxlength: $data.fieldMaxSymbols,
    "input-enabled": !$props.readOnly,
    "option-enabled": !$props.readOnly,
    "with-search": true,
    "allowed-symbols-regex": "[0-9]",
    type: "text",
    onInputChange: _cache[18] || (_cache[18] = function ($event) {
      return _ctx.ebaTotalAssetsValue = $event;
    }),
    onOptionChange: _cache[19] || (_cache[19] = function ($event) {
      return _ctx.ebaTotalAssetsCurrency = $event;
    })
  }, null, 8 /* PROPS */, ["title", "input-value", "option-value", "input-placeholder", "options", "maxlength", "input-enabled", "option-enabled"])])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.assets.length ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 5
  }, [_createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("h2", _hoisted_18, _toDisplayString($data.translate.assets), 1 /* TEXT */)]), !$props.readOnly && $options.hasIAModule ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createElementVNode("div", {
    class: "flex items-center cursor-pointer",
    onClick: _cache[20] || (_cache[20] = function ($event) {
      return $data.showIALinkModal = true;
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-2 mr-2"
  }), _createElementVNode("span", null, _toDisplayString($options.attachIATitle), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true)]), _createElementVNode("table", null, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_20, [_createVNode(_component_risma_label, {
    title: $data.translate.name,
    class: "mb-1"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_21, [_createVNode(_component_risma_label, {
    title: $data.translate.type,
    class: "mb-1"
  }, null, 8 /* PROPS */, ["title"])]), _cache[26] || (_cache[26] = _createElementVNode("th", null, null, -1 /* HOISTED */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.assets, function (asset) {
    return _openBlock(), _createElementBlock("tr", {
      key: asset.id
    }, [_createElementVNode("td", _hoisted_22, [$options.isPreview ? (_openBlock(), _createBlock(_component_router_link, {
      key: 0,
      to: asset.url
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(asset.name || asset.title), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])) : (_openBlock(), _createBlock(_component_preview_modal_link, {
      key: 1,
      activity: asset,
      onUpdated: _cache[21] || (_cache[21] = function ($event) {
        return $data.updatedAssetFromPreview = $event;
      }),
      onDismissModal: $options.updateAsset
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("span", null, _toDisplayString(asset.name || asset.title), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDismissModal"]))]), _createElementVNode("td", _hoisted_23, _toDisplayString(asset.moduleTitle), 1 /* TEXT */), _createElementVNode("td", null, [!$props.readOnly ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      icon: "x",
      class: "stroke-2 mr-2 cursor-pointer text-red-50",
      onClick: function onClick($event) {
        return $options.unlinkAssetFromPreviewList(asset);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])]);
  }), 128 /* KEYED_FRAGMENT */))])])], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 6
  }, [!$props.readOnly && $options.hasIAModule ? (_openBlock(), _createElementBlock("div", _hoisted_24, [_createElementVNode("div", {
    class: "flex items-center cursor-pointer",
    onClick: _cache[22] || (_cache[22] = function ($event) {
      return $data.showIALinkModal = true;
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-2 mr-2"
  }), _createElementVNode("span", null, _toDisplayString($options.attachIATitle), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */))])) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  }));
}