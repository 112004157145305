function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { patchUtilSettings } from '@/api/risma/settings';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import AiTermsOfServiceModal from '@/components/Organisms/Admin/AiTermsOfServiceModal';
import HelpToolTip from '@/components/Molecules/HelpToolTip';
import { getRegionsOptions } from '@/components/Organisms/AiAssistant/utils';
export default {
  name: 'AdminAiPartnerAccess',
  components: {
    Checkboxes: Checkboxes,
    SingleSelect: SingleSelect,
    AiTermsOfServiceModal: AiTermsOfServiceModal,
    HelpToolTip: HelpToolTip
  },
  data: function data() {
    var _this = this;
    return {
      showTermsOfServices: false,
      isAiEnabled: [],
      isHiddenForCompliance: [],
      checkboxOptions: [{
        value: 1,
        label: this.$trans('Enable AI Partner for all users')
      }],
      hideAiOptions: [{
        value: 1,
        label: this.$trans('Hide AI Partner in the compliance projects')
      }],
      selectedRegion: 'DK',
      translate: {
        regionTitle: this.$trans('Default region'),
        introductionText: function introductionText() {
          return _this.$trans('Enable the AI Partner feature for all users by accepting the %sTerms of Service%s. Once accepted, you can also select a default region for the AI Partner for all users, customizing the experience based on local laws. To learn more press %s here%s.', ['<a href="#" data-terms="true" class="underline text-rm-active-blue">', '</a>', '<a href="https://support.rismasystems.com/ai-partner#anchor-1" target="_blank" rel="noopener noreferrer" class="underline text-rm-active-blue">', '</a>']);
        },
        confirmAlertMessage: this.$trans('Are you sure you want to disable the AI Partner for all users?'),
        thisSettingsWillHide: this.$trans('This setting will hide the Al Partner from compliance projects and disable its ability to assist with regulatory text. The setting applies to all users.'),
        areYouSureThisWill: this.$trans('Are you sure? The setting applies to all users.')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    aiTermsAccepted: 'aiTermsAccepted',
    aiDefaultRegion: 'aiDefaultRegion',
    getSettingValue: 'getValue'
  })), {}, {
    regionOptions: function regionOptions() {
      return getRegionsOptions().map(function (item) {
        return {
          label: item.name,
          value: item.value
        };
      });
    },
    note: function note() {
      return this.translate.introductionText();
    }
  }),
  mounted: function mounted() {
    this.isAiEnabled = [this.aiTermsAccepted];
    this.selectedRegion = this.aiDefaultRegion;
    if (this.getSettingValue('feature.compliance.ai_assistant_disabled')) {
      this.isHiddenForCompliance = [this.getSettingValue('feature.compliance.ai_assistant_disabled')];
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    loadSettings: 'load'
  })), {}, {
    updateTermsAcceptedSetting: function updateTermsAcceptedSetting(value) {
      return patchUtilSettings({
        'app.overall.ai_terms_accepted': value
      });
    },
    handleAiAccess: function handleAiAccess(event) {
      var _this2 = this;
      var _ref = event[0] || {},
        _ref$value = _ref.value,
        value = _ref$value === void 0 ? false : _ref$value;
      if (value) {
        this.showTermsOfServices = true;
      } else {
        this.$confirm('', this.translate.confirmAlertMessage, function (res) {
          return res ? _this2.updateTermsAcceptedSetting(0).then(function () {
            return _this2.loadSettings();
          }) : _this2.isAiEnabled = [1];
        });
      }
    },
    acceptTermsOfServices: function acceptTermsOfServices() {
      var _this3 = this;
      this.showTermsOfServices = false;
      this.updateTermsAcceptedSetting(1).then(function () {
        return _this3.loadSettings();
      }).then(function () {
        return _this3.isAiEnabled = [_this3.aiTermsAccepted];
      });
    },
    dismissTermsOfServices: function dismissTermsOfServices() {
      if (!this.aiTermsAccepted) {
        this.isAiEnabled = [];
      }
      this.showTermsOfServices = false;
    },
    handleChangeRegion: function handleChangeRegion(region) {
      var _this4 = this;
      patchUtilSettings({
        'app.overall.ai_default_region': region
      }).then(function () {
        return _this4.loadSettings();
      });
    },
    updateComplianceEnableSetting: function updateComplianceEnableSetting(value) {
      var _this5 = this;
      return patchUtilSettings({
        'feature.compliance.ai_assistant_disabled': value
      }).then(function () {
        return _this5.loadSettings();
      });
    },
    handleHideForCompliance: function handleHideForCompliance(event) {
      var _this6 = this;
      var _ref2 = event[0] || {},
        _ref2$value = _ref2.value,
        value = _ref2$value === void 0 ? 0 : _ref2$value;
      if (value) {
        this.$confirm('', this.translate.areYouSureThisWill, function (res) {
          return res ? _this6.updateComplianceEnableSetting(event.length) : _this6.isHiddenForCompliance = [];
        });
      } else {
        this.updateComplianceEnableSetting(event.length);
      }
    },
    handleNoteClick: function handleNoteClick(event) {
      var _event$target;
      var isTermsLink = event === null || event === void 0 || (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.getAttribute('data-terms');
      if (isTermsLink) {
        this.showTermsOfServices = true;
      }
    }
  })
};