// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/svg/chevron_solid-blue.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/svg/chevron_solid-gray.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* vertical line is divided on 2 parts, vertical-arrow-first and vertical-arrow-last so they could show different colors */
.vertical-arrow-first::before,
.vertical-arrow-last::before,
.hori-arrow::after,
.vertical-arrow-first-hidden::after {
  content: "";
  position: absolute;
  background-color: #e5e9eb;
}
.vertical-arrow-first.vertical-arrow-first-selected::before,
.vertical-arrow-last.vertical-arrow-last-selected::before,
.hori-arrow.hori-arrow-selected::after {
  background-color: #0e2954;
}
.hori-arrow::after {
  width: 22px;
  height: 2px;
  left: -47px;
  z-index: 0;
}
.vertical-arrow-first::before {
  width: 2px;
  z-index: 2;
  left: -27px;
  top: 0px;
  height: calc(50% + 1px);
}
.vertical-arrow-first:not(.vertical-arrow-first-selected)::before {
  z-index: 0;
}
.first-child-vertical-arrow::before {
  left: -47px;
  top: -36px;
  height: calc(50% + 37px);
}
.vertical-arrow-last::before {
  height: 100%;
  width: 2px;
  left: -7px;
  top: 11px;
  z-index: 1;
}
.vertical-arrow-last-headless::before {
  top: 20px;
}
.vertical-arrow-first-hidden::after {
  height: calc(50% - 1px);
  width: 2px;
  left: -27px;
  top: 0;
  z-index: 3;
  background-color: #FFFFFF;
}
.vertical-arrow-last:last-child::before {
  height: 0 !important;
}
.hori-arrow-on-hidden-children::after {
  left: -27px;
}
.icon-position {
  position: absolute;
  left: -36.5px;
  z-index: 10;
  height: 22px;
  width: 20px;
  background-color: #FFFFFF;
  background-size: 100%;
}
.icon-blue {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.icon-gray {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
.sortable-drag .vertical-arrow::after, .sortable-drag .vertical-arrow::before, .sortable-drag .hori-arrow::after {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
