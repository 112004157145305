import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = ["title"];
var _hoisted_2 = {
  key: 1,
  class: "control-details-body row"
};
var _hoisted_3 = {
  class: "col-4 control-details-body-column mb-4"
};
var _hoisted_4 = {
  class: "col-4 control-details-body-column mb-4"
};
var _hoisted_5 = {
  class: "col-4 control-details-body-column"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_link_item = _resolveComponent("link-item");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'inherited-from-mother': $props.isDaughter
    }, "control-details-associations"])
  }, [_createElementVNode("p", {
    class: "mb-3 glob-h2 text-rm-text",
    title: $options.associationsTitle
  }, _toDisplayString($options.associationsTitle), 9 /* TEXT, PROPS */, _hoisted_1), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$options.accountsWithChildren && !$options.inputsDisabled ? (_openBlock(), _createBlock(_component_risma_select, {
    key: 0,
    "enable-select-all": true,
    "label-key": $data.labelKey,
    options: $options.accountsWithChildren,
    "selected-options": $options.selectedAccounts,
    "un-sorted": $data.unSorted,
    placeholder: $options.accountPlaceholder,
    title: $options.accountTitle,
    "title-type": "small",
    onSelected: _cache[0] || (_cache[0] = function ($event) {
      return $options.handleSelected('accounts', $event);
    })
  }, null, 8 /* PROPS */, ["label-key", "options", "selected-options", "un-sorted", "placeholder", "title"])) : _createCommentVNode("v-if", true), $options.preparedData.accounts && $options.inputsDisabled ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList($options.preparedData.accounts, function (tag) {
    return _openBlock(), _createBlock(_component_link_item, {
      key: tag.id,
      title: tag.name,
      tooltip: tag.tooltip,
      link: tag.url,
      "is-tag": true,
      class: "w-fit mb-1"
    }, null, 8 /* PROPS */, ["title", "tooltip", "link"]);
  }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_4, [$data.processes && !$options.inputsDisabled ? (_openBlock(), _createBlock(_component_risma_select, {
    key: 0,
    "enable-select-all": true,
    "label-key": $data.labelKey,
    options: $data.processes,
    "selected-options": $props.selectedItems.processes || [],
    placeholder: $options.processesPlaceholder,
    title: $options.processesTitle,
    "title-type": "small",
    onSelected: _cache[1] || (_cache[1] = function ($event) {
      return $options.handleSelected('processes', $event);
    })
  }, null, 8 /* PROPS */, ["label-key", "options", "selected-options", "placeholder", "title"])) : _createCommentVNode("v-if", true), $options.preparedData.processes && $options.inputsDisabled ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList($options.preparedData.processes, function (tag) {
    return _openBlock(), _createBlock(_component_link_item, {
      key: tag.id,
      title: tag.name,
      tooltip: tag.tooltip,
      link: tag.url,
      "is-tag": true,
      class: "w-fit mb-1"
    }, null, 8 /* PROPS */, ["title", "tooltip", "link"]);
  }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_5, [$data.risks && !$options.inputsDisabled ? (_openBlock(), _createBlock(_component_risma_select, {
    key: 0,
    "enable-select-all": true,
    "label-key": $data.labelKey,
    options: $data.risks,
    "selected-options": $props.selectedItems.risks || [],
    placeholder: $options.risksPlaceholder,
    title: $options.risksTitle,
    "title-type": "small",
    onSelected: _cache[2] || (_cache[2] = function ($event) {
      return $options.handleSelected('risks', $event);
    })
  }, null, 8 /* PROPS */, ["label-key", "options", "selected-options", "placeholder", "title"])) : _createCommentVNode("v-if", true), $options.preparedData.risks && $options.inputsDisabled ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList($options.preparedData.risks, function (tag) {
    return _openBlock(), _createBlock(_component_link_item, {
      key: tag.id,
      title: tag.name,
      tooltip: tag.tooltip,
      link: tag.url,
      "is-tag": true,
      class: "w-fit mb-1"
    }, null, 8 /* PROPS */, ["title", "tooltip", "link"]);
  }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true)])]))], 2 /* CLASS */);
}