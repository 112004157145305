function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import DelegationList from '@/components/Atoms/DelegationList/DelegationList';
import DelegationModal from '@/components/Molecules/DelegationModal';
import TagCreationModal from '@/components/Molecules/Modal/TagCreationModal';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import FrameworkIdRelationDetails from '@/components/Molecules/FrameworkIdRelationDetails';
import { filterAsset } from '@/utils/format/objectsFromIds';
import { DelegationModalSettings, DelegationTypes, DelegationItemTypes } from '@/constants/relations/DelegationModal';
import { ObjectTypes, isObjectUsingCustomRaci as _isObjectUsingCustomRaci } from '@/constants/ObjectTypes';
import { MODULES } from '@/constants/modules';
import { checkERMByProjectId } from '@/utils/risks/check';
import { getUsersWithoutTimeout } from '@/api/risma/data';
export default {
  name: 'DelegationWell',
  components: {
    DelegationList: DelegationList,
    DelegationModal: DelegationModal,
    TagCreationModal: TagCreationModal,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    FrameworkIdRelationDetails: FrameworkIdRelationDetails
  },
  mixins: [RaciTitleMixin],
  introduction: 'Delegation section for activities',
  description: "If an activity supports RACI, this component can create the delegation section for it.\n                  The component should v-model on the entire activity object, so therefore the activity need to have the following keys:\n                  organisationIds, responsibleUserIds, accountableUserIds, consultedUserIds and informedUserIds",
  token: '<delegation-well :v-model="activity"/>',
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Activity to build delegation with'
    },
    organisations: {
      type: Array,
      required: true,
      note: 'List of organisations to choose from'
    },
    initiatives: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of initiatives to choose from'
    },
    initiativeTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of initiative types to choose from'
    },
    riskManagementLevels: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of management levels for risk'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If the fields are readonly and not editable'
    },
    currentActivityType: {
      type: Object,
      required: false,
      default: null,
      note: 'Current activity type of the activity'
    }
  },
  emits: ['addNewItem', 'propertyChanged'],
  data: function data() {
    return {
      data: [],
      selectedType: '',
      selectedItemType: '',
      showDelegationModal: false,
      isDelegationModalSelectedMultiple: true,
      isDelegationModalWithReset: true,
      DelegationTypes: DelegationTypes,
      DelegationItemTypes: DelegationItemTypes,
      showCreateModal: false,
      users: [],
      isLoaded: true,
      userFields: ['responsibleUserIds', 'reviewersUserIds', 'escalationUserIds', 'accountableUserIds', 'consultedUserIds', 'informedUserIds', 'notificationsUserIds', 'dailyTeamUsersIds'],
      translate: getTranslate['DelegationWell']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), mapState(useTagsStore, ['tags'])), {}, {
    accessRights: function accessRights() {
      var data = {
        module: this.modelValue.module
      };
      if (data.module === MODULES.COMPLIANCE) {
        data.projectId = this.modelValue.compliance_projects_id;
      }
      if (data.module === MODULES.RISK) {
        data.projectId = this.modelValue.projectId;
      }
      return data;
    },
    selectedUserIds: function selectedUserIds() {
      var _this = this;
      var items = this.userFields.reduce(function (list, field) {
        var _this$modelValue$fiel;
        if ((_this$modelValue$fiel = _this.modelValue[field]) !== null && _this$modelValue$fiel !== void 0 && _this$modelValue$fiel.length) {
          list.push.apply(list, _toConsumableArray(_this.modelValue[field]));
        }
        return list;
      }, []);
      return _toConsumableArray(new Set(items.map(function (id) {
        return +id;
      }))).filter(function (id) {
        return id !== _this.currentUser.id;
      });
    },
    maxVisibleTags: function maxVisibleTags() {
      return this.getSettingValue('risma.max_visible_users_orgs');
    },
    reviewerListTitle: function reviewerListTitle() {
      return this.translate.reviewer;
    },
    escalationListTitle: function escalationListTitle() {
      return this.translate.escalation;
    },
    activityTypeTitle: function activityTypeTitle() {
      return this.translate.activityType;
    },
    notificationTypeTitle: function notificationTypeTitle() {
      return this.translate.notifications;
    },
    organisationsListTitle: function organisationsListTitle() {
      return this.translate.organisations;
    },
    managementLevelTitle: function managementLevelTitle() {
      return this.translate.managementLevel;
    },
    dailyTeamUserTitle: function dailyTeamUserTitle() {
      return "".concat(this.translate.dailyTeam, " - ").concat(this.translate.users);
    },
    dailyTeamOrganisationTitle: function dailyTeamOrganisationTitle() {
      return "".concat(this.translate.dailyTeam, " - ").concat(this.translate.organisations);
    },
    tagListTitle: function tagListTitle() {
      return this.translate.tags;
    },
    dependingOnTitle: function dependingOnTitle() {
      return this.getSettingValue('risma.depending_on_name') || this.translate.dependingOn;
    },
    precursorToTitle: function precursorToTitle() {
      return this.getSettingValue('risma.precursor_to_name') || this.translate.precursorTo;
    },
    isERM: function isERM() {
      return this.modelValue.objType === ObjectTypes.RISK_RISK && checkERMByProjectId(this.modelValue.projectId, this.currentUser);
    },
    isAction: function isAction() {
      return this.modelValue.objType === ObjectTypes.EXECUTION_INITIATIVE;
    },
    isContract: function isContract() {
      return this.modelValue.objType === ObjectTypes.CONTRACT;
    },
    isObjectUsingCustomRaci: function isObjectUsingCustomRaci() {
      return _isObjectUsingCustomRaci(this.modelValue.objType);
    },
    activityTypeRaciNames: function activityTypeRaciNames() {
      var _this$currentActivity, _this$currentActivity2, _this$currentActivity3, _this$currentActivity4;
      if (!this.isContract) return {};
      return {
        responsible: (_this$currentActivity = this.currentActivityType) === null || _this$currentActivity === void 0 ? void 0 : _this$currentActivity.responsibleLabel,
        accountable: (_this$currentActivity2 = this.currentActivityType) === null || _this$currentActivity2 === void 0 ? void 0 : _this$currentActivity2.accountableLabel,
        consulted: (_this$currentActivity3 = this.currentActivityType) === null || _this$currentActivity3 === void 0 ? void 0 : _this$currentActivity3.consultedLabel,
        informed: (_this$currentActivity4 = this.currentActivityType) === null || _this$currentActivity4 === void 0 ? void 0 : _this$currentActivity4.informedLabel
      };
    },
    responsibleListTitle: function responsibleListTitle() {
      if (this.isERM) {
        return this.translate.dailyRiskOwner;
      }
      return this.activityTypeRaciNames.responsible || this.responsibleTitle(this.isRACIUsed(this.modelValue.objType));
    },
    accountableListTitle: function accountableListTitle() {
      if (this.isERM) {
        return this.translate.primaryRiskOwner;
      }
      return this.activityTypeRaciNames.accountable || this.accountableTitle(this.isRACIUsed(this.modelValue.objType));
    },
    consultedListTitle: function consultedListTitle() {
      return this.activityTypeRaciNames.consulted || this.consultedTitle(this.isRACIUsed(this.modelValue.objType));
    },
    informedListTitle: function informedListTitle() {
      return this.activityTypeRaciNames.informed || this.informedTitle(this.isRACIUsed(this.modelValue.objType));
    },
    responsible: function responsible() {
      return filterAsset(this.users, this.modelValue.responsibleUserIds);
    },
    reviewers: function reviewers() {
      return filterAsset(this.users, this.modelValue.reviewersUserIds);
    },
    escalation: function escalation() {
      return filterAsset(this.users, this.modelValue.escalationUserIds);
    },
    accountable: function accountable() {
      return filterAsset(this.users, this.modelValue.accountableUserIds);
    },
    consulted: function consulted() {
      return filterAsset(this.users, this.modelValue.consultedUserIds);
    },
    informed: function informed() {
      return filterAsset(this.users, this.modelValue.informedUserIds);
    },
    orgs: function orgs() {
      return filterAsset(this.organisations, this.modelValue.organisationIds);
    },
    notifications: function notifications() {
      return filterAsset(this.users, this.modelValue.notificationsUserIds);
    },
    dailyTeamUsers: function dailyTeamUsers() {
      return filterAsset(this.users, this.modelValue.dailyTeamUsersIds);
    },
    dailyTeamOrganisations: function dailyTeamOrganisations() {
      return filterAsset(this.organisations, this.modelValue.dailyTeamOrganisationsIds);
    },
    tagsAssigned: function tagsAssigned() {
      return filterAsset(this.tags, this.modelValue.tagIds);
    },
    initiativeTypeAssigned: function initiativeTypeAssigned() {
      return filterAsset(this.initiativeTypes, this.modelValue.initiativeType);
    },
    hasDependingOnFromActivityType: function hasDependingOnFromActivityType() {
      return this.getActionOptionalFieldStateByName('depending_on');
    },
    hasDailyUsersFromActivityType: function hasDailyUsersFromActivityType() {
      return this.getActionOptionalFieldStateByName('daily_users');
    },
    hasDailyOrganisationsFromActivityType: function hasDailyOrganisationsFromActivityType() {
      return this.getActionOptionalFieldStateByName('daily_organisations');
    },
    hasNotificationsFromActivityType: function hasNotificationsFromActivityType() {
      return this.getActionOptionalFieldStateByName('notifications');
    },
    dependingOnAssigned: function dependingOnAssigned() {
      return filterAsset(this.initiatives, this.modelValue.dependingOn);
    },
    precursorAssigned: function precursorAssigned() {
      return filterAsset(this.initiatives, this.modelValue.precursorTo);
    },
    hideInformed: function hideInformed() {
      return !!this.getSettingValue('feature.hide_informed_from_raci');
    },
    managementLevels: function managementLevels() {
      return filterAsset(this.riskManagementLevels, this.modelValue.managementLevelId);
    },
    canBeCreated: function canBeCreated() {
      return this.selectedType === this.DelegationTypes.tags;
    },
    delegationModalSettings: function delegationModalSettings() {
      return DelegationModalSettings[this.selectedType];
    },
    delegationModalOptionsList: function delegationModalOptionsList() {
      return this[this.selectedType];
    },
    creationComponent: function creationComponent() {
      return 'TagCreationModal';
    },
    delegationLabel: function delegationLabel() {
      var basic = this.translate.addNew;
      switch (this.selectedItemType) {
        case DelegationItemTypes.RESPONSIBLE_USER:
          return "".concat(basic, " ").concat(this.responsibleListTitle);
        case DelegationItemTypes.REVIEWER_USER:
          return "".concat(basic, " ").concat(this.reviewerListTitle);
        case DelegationItemTypes.ESCALATION_USER:
          return "".concat(basic, " ").concat(this.escalationListTitle);
        case DelegationItemTypes.ACCOUNTABLE_USER:
          return "".concat(basic, " ").concat(this.accountableListTitle);
        case DelegationItemTypes.CONSULTED_USER:
          return "".concat(basic, " ").concat(this.consultedListTitle);
        case DelegationItemTypes.INFORMED_USER:
          return "".concat(basic, " ").concat(this.informedListTitle);
        case DelegationItemTypes.ORGANISATION:
          return "".concat(basic, " ").concat(this.organisationsListTitle);
        case DelegationItemTypes.NOTIFICATION_USER:
          return "".concat(basic, " ").concat(this.notificationTypeTitle);
        case DelegationItemTypes.MANAGEMENT_LEVEL:
          return "".concat(basic, " ").concat(this.managementLevelTitle);
        case DelegationItemTypes.DAILY_TEAM_USER:
          return "".concat(basic, " ").concat(this.dailyTeamUserTitle);
        case DelegationItemTypes.DAILY_TEAM_ORGANISATION:
          return "".concat(basic, " ").concat(this.dailyTeamOrganisationTitle);
        case DelegationItemTypes.TAG:
          return "".concat(basic, " ").concat(this.tagListTitle);
        case DelegationItemTypes.DEPENDING:
          return "".concat(basic, " ").concat(this.dependingOnTitle);
        default:
          return '';
      }
    }
  }),
  mounted: function mounted() {
    this.getSelectedUsers();
  },
  methods: {
    updateSelectedUserList: function updateSelectedUserList(users) {
      var _this2 = this;
      if (this.selectedType !== DelegationTypes.users) return;
      users.forEach(function (user) {
        if (_this2.users.find(function (item) {
          return item.id === user.id;
        })) return;
        _this2.users.push(user);
      });
    },
    getSelectedUsers: function getSelectedUsers() {
      var _this3 = this;
      if (!this.selectedUserIds.length) {
        this.users = [this.currentUser];
        return;
      }
      this.isLoaded = false;
      return getUsersWithoutTimeout({
        filters: {
          id: this.selectedUserIds
        }
      }).then(function (_ref) {
        var list = _ref.list;
        return _this3.users = [].concat(_toConsumableArray(list), [_this3.currentUser]);
      }).finally(function () {
        return _this3.isLoaded = true;
      });
    },
    onApplyChanges: function onApplyChanges(ids) {
      this.$emit('propertyChanged', {
        property: this.selectedItemType,
        value: ids
      });
      this.showDelegationModal = false;
    },
    getActionOptionalFieldStateByName: function getActionOptionalFieldStateByName(fieldName) {
      if (!this.initiativeTypeAssigned || !this.initiativeTypeAssigned.length) return true;
      return this.initiativeTypeAssigned[0].optionalFields.indexOf(fieldName) !== -1;
    },
    editDelegationItem: function editDelegationItem(list, selectedType) {
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DelegationTypes.users;
      var isMultiple = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      var withReset = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
      this.delegationModalSelectedIds = list.map(function (item) {
        return item.id;
      });
      this.isDelegationModalSelectedMultiple = isMultiple;
      this.isDelegationModalWithReset = withReset;
      this.showDelegationModal = true;
      this.selectedItemType = selectedType;
      this.selectedType = type;
    },
    onCreateItem: function onCreateItem(response) {
      this.showCreateModal = !this.showCreateModal;
      this.delegationModalSelectedIds = [].concat(_toConsumableArray(this.delegationModalSelectedIds), [response.id]); // watch in delegationmodal doesn't see the update
      this.$emit('addNewItem', this.selectedType, response);
    }
  }
};