function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import { getAttributes } from '@/api/admin/gapschema';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import { gapFlags } from '@/constants/compliance/GapTypes';
export default {
  name: 'GapSchemaArticleModal',
  introduction: 'Component which createor update a article',
  description: 'Component with form of article creation in modal box.',
  token: '<gap-schema-article-modal :domains="[]" :gapSchemaId=""/>',
  components: {
    RismaSelect: RismaSelect,
    Modal: Modal,
    InputField: InputField,
    RismaTitle: RismaTitle,
    TextBox: TextBox,
    FrameworksSelector: FrameworksSelector,
    ButtonsRow: ButtonsRow,
    SingleSelect: SingleSelect
  },
  props: {
    article: {
      type: Object,
      default: null
    },
    domains: {
      type: [Object, Array],
      required: true
    },
    gapSchemaId: {
      type: [String, Number],
      required: true
    }
  },
  emits: ['onSubmit', 'toggleModal', 'createOption', 'close', 'open'],
  data: function data() {
    return {
      errors: {},
      attributesArr: [],
      formData: this.getFormData(),
      selectedLabelsPerAttributes: new Map(),
      preselectedLabelOptions: {},
      labelOptions: {},
      isHiddenPopup: true,
      newOptionValue: '',
      translate: getTranslate['GapSchemaArticleModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    domainsList: function domainsList() {
      return this.domains.map(function (domain) {
        return {
          value: domain.id,
          label: domain.label
        };
      });
    },
    hasOverallFramework: function hasOverallFramework() {
      return this.getSettingValue('feature.overall.extended_framework');
    },
    selectedFrameworks: function selectedFrameworks() {
      var _this = this;
      return this.frameworks.filter(function (framework) {
        return _this.formData.solutionIds.includes(framework.id);
      });
    },
    flagOptions: function flagOptions() {
      var flags = gapFlags(this.$trans);
      return Object.keys(flags).map(function (key) {
        return flags[key];
      });
    },
    selectedFlags: function selectedFlags() {
      var _this2 = this;
      return this.flagOptions.filter(function (option) {
        return option.value & _this2.formData.flags;
      });
    },
    buttonsRowData: function buttonsRowData() {
      return [{
        text: this.translate.cancel,
        type: 'util',
        func: 'button',
        clickEvent: this.toggleModal
      }, {
        text: this.article.id ? this.translate.save : this.translate.create,
        type: 'save',
        func: 'submit',
        clickEvent: this.handleOnSubmit
      }];
    }
  }),
  watch: {
    domains: {
      deep: true,
      handler: function handler() {
        this.formData.domain = this.article.domain;
      }
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    getAttributes(this.gapSchemaId).then(function (_ref) {
      var attributes = _ref.attributes;
      _this3.attributesArr = attributes;
      _this3.attributesArr.forEach(function (attribute) {
        _this3.selectedLabelsPerAttributes.set(attribute.id, []);
        _this3.preselectedLabelOptions[attribute.id] = _this3.createPreselectedOptions(attribute);
        _this3.labelOptions[attribute.id] = _this3.createOptionsFromLabels(attribute === null || attribute === void 0 ? void 0 : attribute.labels);
      });
    });
  },
  methods: {
    getFormData: function getFormData() {
      if (this.article.id) {
        return {
          domain: this.article.domain,
          article: this.article.article,
          articleTitle: this.article.articleTitle,
          requirement: this.article.requirement,
          flags: this.article.flags,
          solutionIds: this.article.solutionIds
        };
      }
      return {
        domain: '',
        article: '',
        articleTitle: '',
        requirement: '',
        flags: 0,
        solutionIds: []
      };
    },
    toggleModal: function toggleModal() {
      this.$emit('toggleModal');
    },
    handleOnSubmit: function handleOnSubmit() {
      if (this.handleValidation()) {
        var attributeIds = [];
        var _iterator = _createForOfIteratorHelper(this.selectedLabelsPerAttributes.values()),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var ids = _step.value;
            attributeIds = [].concat(_toConsumableArray(attributeIds), _toConsumableArray(ids));
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        this.$emit('onSubmit', _objectSpread(_objectSpread({}, this.formData), {}, {
          attributeIds: attributeIds
        }));
      }
    },
    handleValidation: function handleValidation() {
      this.errors = _objectSpread({}, this.validateGeneralFields());
      return !Object.keys(this.errors).length;
    },
    validateGeneralFields: function validateGeneralFields() {
      var _this4 = this;
      var errors = {};
      var fieldsTocheck = ['article', 'articleTitle', 'requirement'];
      fieldsTocheck.forEach(function (item) {
        if (_this4.formData[item].length < 1) {
          errors[item] = _this4.translate.pleaseFillOutThisField;
        }
      });
      if (!this.formData.domain.id) {
        errors.domain = this.translate.pleaseFillOutThisField;
      }
      return errors;
    },
    addNewOption: function addNewOption(label) {
      this.$emit('createOption', label);
    },
    onSelectDomain: function onSelectDomain(id) {
      this.formData.domain = this.domains.find(function (domain) {
        return domain.id === id;
      });
    },
    onSelectFlags: function onSelectFlags(flags) {
      if (!Array.isArray(flags)) {
        flags = [flags];
      }
      var newFlagValue = flags.reduce(function (acc, flag) {
        return acc + flag.value;
      }, 0);
      this.formData.flags = newFlagValue;
    },
    createOptionsFromLabels: function createOptionsFromLabels() {
      var labels = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return labels.map(function (item) {
        return {
          label: item.label,
          value: item.id
        };
      });
    },
    createPreselectedOptions: function createPreselectedOptions(attribute) {
      var _this$article;
      var options = [];
      var labelIds = [];
      var preselectedAttributes = ((_this$article = this.article) === null || _this$article === void 0 ? void 0 : _this$article.attributes) || {};
      var preselectedLabels = preselectedAttributes[attribute.category];
      if (preselectedLabels && preselectedLabels.length) {
        preselectedLabels.forEach(function (preselectedLabel) {
          var selectedLabel = attribute.labels.find(function (attributeLabelItem) {
            return preselectedLabel === attributeLabelItem.label;
          });
          if (!selectedLabel) return;
          labelIds.push(selectedLabel.id);
          options.push({
            value: selectedLabel.id,
            label: selectedLabel.label
          });
        });
      }
      this.selectedLabelsPerAttributes.set(attribute.id, labelIds);
      return options;
    },
    onLabelsUpdate: function onLabelsUpdate(attributeId, labels) {
      this.selectedLabelsPerAttributes.set(attributeId, labels.map(function (label) {
        return label.value;
      }));
    },
    onFrameworksSelected: function onFrameworksSelected(frameworks) {
      this.formData.solutionIds = frameworks.map(function (_ref2) {
        var id = _ref2.id;
        return id;
      });
    },
    handleCreateNewOption: function handleCreateNewOption() {
      this.isHiddenPopup = false;
      this.newOptionValue = '';
    },
    handleNewAnswer: function handleNewAnswer() {
      this.addNewOption(this.newOptionValue);
      this.newOptionValue = '';
      this.isHiddenPopup = true;
    }
  }
};