function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { createCustomField as _createCustomField, editCustomField as _editCustomField } from '@/api/admin/admin';
import { CustomFieldTypes as CF_TYPES } from '@/constants/CustomFieldTypes';
import { MODULES } from '@/constants/modules';
import { Colors } from '@/Colors';
import Modal from '@/components/Molecules/Modal/Modal';
import CustomFieldTypes from '@/components/Molecules/CustomFieldTypes';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Notification from '@/components/Molecules/Notification';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import { CustomFieldValidationMixin } from '@/components/Organisms/Admin/CustomFieldValidationMixin';
import { IATitleMixin } from '@/mixins/IATitleMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { AdminTypesModalMixin } from '@/components/Organisms/Admin/AdminTypesModalMixin';
import { AdminCustomFieldsAndActivityTypesMixin } from '@/components/Organisms/Admin/AdminCustomFieldsAndActivityTypesMixin';
import * as Utils from '@/utils/Utils';
export default {
  name: 'AdminCustomFieldsModal',
  introduction: 'Modal with custom fields in /business/admin',
  description: 'Modal for creation & edit custom fields. Used together with AdminCustomFieldsWell component',
  token: '<admin-custom-fields-modal></admin-custom-fields-modal>',
  components: {
    Modal: Modal,
    InputField: InputField,
    SingleSelect: SingleSelect,
    ButtonsRow: ButtonsRow,
    Notification: Notification,
    CustomFieldTypes: CustomFieldTypes
  },
  mixins: [AdminTypesModalMixin, IATitleMixin, CustomFieldValidationMixin, ProcessLibraryTitleMixin, AdminCustomFieldsAndActivityTypesMixin],
  props: {
    settings: {
      type: Object,
      required: true,
      note: 'Settings from /utils/settings'
    },
    customFields: {
      type: Array,
      required: false,
      default: function _default() {},
      note: 'All possible custom fields'
    }
  },
  emits: ['created', 'edited'],
  data: function data() {
    return {
      isEditMode: false,
      colors: Colors,
      customFieldTypes: CF_TYPES,
      showModal: false,
      showButtons: false,
      notificationError: null,
      notificationSuccess: null,
      orders: [],
      availableOrder: [],
      MODULES: MODULES,
      fieldDeafultValue: {
        label: '',
        explanatoryText: '',
        module: '',
        typeId: '',
        options: [],
        unit: '',
        children: [],
        errors: {}
      },
      field: {},
      saveButtonClickCounter: 0,
      translate: getTranslate['AdminCustomFieldsModal']()
    };
  },
  computed: {
    modalHeader: function modalHeader() {
      return this.isEditMode ? this.translate.editCustomField : this.translate.createCustomField;
    },
    labelErrorNotification: function labelErrorNotification() {
      var errors = this.field.errors;
      return errors && errors.label ? this.translate.pleaseFillOutThisField : '';
    },
    buttonsRowData: function buttonsRowData() {
      return [{
        text: this.translate.cancel,
        type: 'util',
        clickEvent: this.toggleModal
      }, {
        text: this.isEditMode ? this.translate.save : this.translate.create,
        type: 'save',
        clickEvent: this.saveField
      }];
    }
  },
  methods: {
    onCreateOpen: function onCreateOpen(module) {
      this.beforeOpen();
      this.isEditMode = false;
      this.field.module = module || this.moduleTypesOptions[0].value;
    },
    onEditOpen: function onEditOpen(data) {
      this.beforeOpen();
      this.isEditMode = true;
      this.field = _objectSpread({}, this.fieldDeafultValue);

      // Populate values from server
      if (data) {
        this.field = _objectSpread(_objectSpread({}, this.field), data);
        if (data.options) {
          this.field.options = Utils.getProperSelectedOptions(data.options);
        }
        if (data.children) {
          this.field.children = data.children.map(function (child, index) {
            return _objectSpread(_objectSpread({}, child), {}, {
              order: ++index
            });
          });
        }
      }
    },
    onChangeModuleType: function onChangeModuleType(e) {
      if (e) {
        this.field.module = e;
        if (this.field.module !== MODULES.CONTROLS && this.field.typeId === this.customFieldTypes.ROW_GROUP) {
          this.field.typeId = null;
        }
      }
    },
    saveField: function saveField() {
      this.saveButtonClickCounter++;
      if (this.validate(this.field) && !this.lockButtons) {
        this.lockButtons = true;
        var data = this.prepeareDataForSaving(this.field);
        return this.field.id ? this.editCustomField(this.field.id, data) : this.createCustomField(data);
      }
    },
    createCustomField: function createCustomField(data) {
      var _this = this;
      return _createCustomField(data).then(function (data) {
        _this.clearFields();
        _this.toggleModal();
        _this.$emit('created', data);
      }).catch(function (e) {
        if (e && e.response && e.response.data && e.response.data.error) {
          _this.showErrorMessage(e.response.data.error);
        }
        throw e;
      }).finally(function () {
        return _this.lockButtons = false;
      });
    },
    editCustomField: function editCustomField(id, data) {
      var _this2 = this;
      return _editCustomField(id, data).then(function (data) {
        _this2.$emit('edited', data);
      }).catch(function (e) {
        throw e;
      }).finally(function () {
        return _this2.lockButtons = false;
      });
    },
    clearFields: function clearFields() {
      this.notificationError = '';
      this.field = _objectSpread({}, this.fieldDeafultValue);
    },
    editCustomFieldType: function editCustomFieldType(key, value) {
      this.field = _objectSpread(_objectSpread({}, this.field), {}, _defineProperty({}, key, value));
    },
    showErrorMessage: function showErrorMessage(msg) {
      this.notificationError = msg;
      this.notificationSuccess = null;
      if (this.$refs.notifError) {
        this.$refs.notifError.resetDismiss();
      }
    }
  }
};