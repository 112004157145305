import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex flex-col mb-3 sm:flex-row"
};
var _hoisted_2 = {
  key: 3
};
var _hoisted_3 = {
  key: 4
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_traffic_light_selector = _resolveComponent("traffic-light-selector");
  var _component_frameworks_selector = _resolveComponent("frameworks-selector");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.createIncident,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    "dismiss-on-click-outside": false,
    "accept-on-enter": false,
    onDismiss: $options.dismissCreateModal,
    onAccept: $options.createIncident
  }, {
    body: _withCtx(function () {
      var _$options$selectedInc, _$options$selectedInc2;
      return [$data.isCreateAttempted && $options.missingRequiredFieldsNames.length ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        closeable: true
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", null, _toDisplayString($data.translate.theFormHasMandatoryFields), 1 /* TEXT */), _createElementVNode("div", null, _toDisplayString($options.missingRequiredFieldsNames.join(', ')), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.newIncident.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.newIncident.title = $event;
        }),
        title: $data.translate.title,
        invalid: $data.isCreateAttempted && $options.missingFields.title,
        placeholder: $data.translate.enterTitle,
        "focus-on-mount": true,
        type: "text",
        class: "mb-3"
      }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "placeholder"]), _createVNode(_component_single_select, {
        modelValue: $data.newIncident.activityTypeId,
        "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
          return $data.newIncident.activityTypeId = $event;
        }), $options.onActivityTypeSelect],
        title: $data.translate.activityType,
        options: $options.incidentActivityTypesOptions,
        "with-reset": false,
        placeholder: $data.translate.choose,
        class: _normalizeClass(["mb-3", $data.isCreateAttempted && $options.missingFields.activityType ? 'invalid' : ''])
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder", "class", "onUpdate:modelValue"]), $options.showDescription ? (_openBlock(), _createBlock(_component_tiny_mce_box, {
        key: 1,
        headline: $data.translate.description,
        "always-open": true,
        class: "mb-3",
        onChanged: _cache[2] || (_cache[2] = function ($event) {
          return $data.newIncident.description = $event;
        })
      }, null, 8 /* PROPS */, ["headline"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_date_time_picker, {
        modelValue: $data.newIncident.incidentHappenedAt,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.newIncident.incidentHappenedAt = $event;
        }),
        title: $data.translate.incidentOccurred,
        "delete-enabled": true,
        class: "mb-3 sm:mr-2 sm:mb-0 basis-1/2"
      }, null, 8 /* PROPS */, ["modelValue", "title"]), $options.showSeverity ? (_openBlock(), _createBlock(_component_traffic_light_selector, {
        key: 0,
        modelValue: $data.newIncident.severity,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $data.newIncident.severity = $event;
        }),
        title: $data.translate.severity,
        "light-type": $data.MODULES.INCIDENTS,
        class: "basis-1/2"
      }, null, 8 /* PROPS */, ["modelValue", "title", "light-type"])) : _createCommentVNode("v-if", true)]), $data.newIncident.activityTypeId ? (_openBlock(), _createBlock(_component_frameworks_selector, {
        key: 2,
        "selected-options": $data.newIncident.solutionIds,
        class: "mb-3",
        onSelected: _cache[5] || (_cache[5] = function ($event) {
          return $data.newIncident.solutionIds = $event;
        })
      }, null, 8 /* PROPS */, ["selected-options"])) : _createCommentVNode("v-if", true), (_$options$selectedInc = $options.selectedIncidentActivityType) !== null && _$options$selectedInc !== void 0 && (_$options$selectedInc = _$options$selectedInc.fields) !== null && _$options$selectedInc !== void 0 && _$options$selectedInc.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_activity_custom_fields, {
        "activity-type": $options.selectedIncidentActivityType,
        "growing-fields": true,
        "list-of-hidden-fields": $options.listOfHiddenFields,
        "ignore-visibility-level": true,
        "list-of-disabled-fields": $options.listOfDisabledFields,
        onChanged: _cache[6] || (_cache[6] = function ($event) {
          return $data.newIncident.customFieldValuesParsed = $event;
        })
      }, null, 8 /* PROPS */, ["activity-type", "list-of-hidden-fields", "list-of-disabled-fields"])])) : _createCommentVNode("v-if", true), (_$options$selectedInc2 = $options.selectedIncidentActivityType) !== null && _$options$selectedInc2 !== void 0 && (_$options$selectedInc2 = _$options$selectedInc2.responsibleUsers) !== null && _$options$selectedInc2 !== void 0 && _$options$selectedInc2.length ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($options.responsibleNotification), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onDismiss", "onAccept"]);
}