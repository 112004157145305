function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { DateFormats } from '@/constants/DateFormats';
import { formatTimeUtc } from '@/utils/date';
export var mapQuestionAnswers = function mapQuestionAnswers(questions, informationAssets, questionAnswers, files, nodes, questionnaireId, getLinkedCompaniesFn) {
  var cells = {};
  questions.forEach(function (question) {
    var obj = {
      question: question.question,
      number: question.questionnumber,
      answers: [],
      helpText: question.help,
      informationAssets: {
        controllers: [],
        processors: [],
        systems: [],
        custom: []
      },
      solutionIds: question.solutionIds
    };
    if (getLinkedCompaniesFn) {
      obj.informationAssets.companies = [];
    }
    var answer = questionAnswers ? questionAnswers[question.id] : null;
    var isRightQuestionnaireId = !questionnaireId || +question.questionnaireId === +questionnaireId;
    if (answer && isRightQuestionnaireId) {
      if (answer.freeText && answer.freeText.length > 0) {
        obj.answers.push(answer.freeText);
      }
      if (answer.dateTime && answer.dateTime.length > 0) {
        var datetimeFormat = question.questiontype === 14 ? DateFormats.DATE_FORMAT : DateFormats.DATE_TIME_FORMAT;
        obj.answers.push(formatTimeUtc(answer.dateTime, datetimeFormat));
      }
      if (answer.optionIds) {
        var optionIds = answer.optionIds.split(',');
        optionIds.forEach(function (optionId) {
          if (question.options[optionId]) {
            obj.answers.push(question.options[optionId]);
          }
        });
      }
      if (answer.controllerIds) {
        var controllerIds = answer.controllerIds.split(',');
        controllerIds.forEach(function (controllerId) {
          if (informationAssets.controllers[controllerId]) {
            obj.informationAssets.controllers.push(informationAssets.controllers[controllerId]);
          }
        });
      }
      if (answer.processorIds) {
        var processorIds = answer.processorIds.split(',');
        processorIds.forEach(function (processorId) {
          var _informationAssets$pr;
          if (!informationAssets.processors[processorId]) {
            return;
          }
          if (getLinkedCompaniesFn && (_informationAssets$pr = informationAssets.processors[processorId]) !== null && _informationAssets$pr !== void 0 && _informationAssets$pr.companyId) {
            var company = getLinkedCompaniesFn(informationAssets.processors[processorId]);
            if (company) {
              obj.informationAssets.companies.push(company);
            } else {
              obj.informationAssets.processors.push(informationAssets.processors[processorId]);
            }
          } else {
            obj.informationAssets.processors.push(informationAssets.processors[processorId]);
          }
        });
      }
      if (answer.systemIds) {
        var systemIds = answer.systemIds.split(',');
        systemIds.forEach(function (systemId) {
          if (informationAssets.systems[systemId]) {
            obj.informationAssets.systems.push(informationAssets.systems[systemId]);
          }
        });
      }
      if (answer.informationAssetIds) {
        var informationAssetIds = answer.informationAssetIds.split(',');
        informationAssetIds.forEach(function (informationAssetId) {
          if (informationAssets.custom[informationAssetId]) {
            obj.informationAssets.custom.push(informationAssets.custom[informationAssetId]);
          }
        });
      }
      if (answer.fileIds) {
        var fileIds = answer.fileIds.split(',');
        fileIds.forEach(function (fileId) {
          if (files[fileId]) {
            var file = files[fileId];
            var iconClass = file.mimeType !== null ? 'file-o' : 'link';
            var icon = "<i class=\"fa fa-".concat(iconClass, "\"></i>");
            var str = "<a href=\"".concat(file.url, "\" target=\"_blank\">").concat(icon, " ").concat(file.filename, "</a>");
            obj.answers.push(str);
          }
        });
      }
      if (answer.processTreeNodeIds) {
        var processTreeNodeIds = answer.processTreeNodeIds.split(',');
        processTreeNodeIds.forEach(function (el) {
          if (nodes[el]) {
            var str = "<router-link to=\"".concat(nodes[el].url, "\" title=\"").concat(nodes[el].title, "\"> ").concat(nodes[el].title, " </router-link>");
            obj.answers.push(str);
          }
        });
      }
    }
    var key = question.questionnumber + question.question;
    if (cells[key] === undefined) {
      cells[key] = obj;
    } else {
      cells[key].answers = [].concat(_toConsumableArray(cells[key].answers), _toConsumableArray(obj.answers));
      cells[key].informationAssets.controllers = [].concat(_toConsumableArray(cells[key].informationAssets.controllers), _toConsumableArray(obj.informationAssets.controllers));
      cells[key].informationAssets.processors = [].concat(_toConsumableArray(cells[key].informationAssets.processors), _toConsumableArray(obj.informationAssets.processors));
      cells[key].informationAssets.systems = [].concat(_toConsumableArray(cells[key].informationAssets.systems), _toConsumableArray(obj.informationAssets.systems));
    }
  });
  var returnArray = [];
  Object.keys(cells).forEach(function (key) {
    returnArray.push(cells[key]);
  });
  return returnArray;
};