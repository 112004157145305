import TextBox from '@/components/Atoms/Inputs/TextBox';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { ResizeOptions } from '@/constants/ResizeOptions';
import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'CustomFieldTextBox',
  token: '<custom-field-text-box/>',
  directives: {
    clickOutside: clickOutside
  },
  components: {
    TextBox: TextBox,
    FeatherIcon: FeatherIcon
  },
  props: {
    field: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: ''
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Are custom fields disabled?'
    },
    placeholder: {
      required: false,
      default: '',
      type: String,
      note: 'A placeholder text'
    },
    resize: {
      required: false,
      type: String,
      default: ResizeOptions.Y,
      note: 'Resize option'
    },
    localStorageKey: {
      type: String,
      required: false,
      default: '',
      note: 'Name of localStorage key'
    }
  },
  emits: ['updated'],
  data: function data() {
    return {
      isTextBoxExpanded: false,
      showChevron: false,
      translate: {
        pleaseFillOutThisField: this.$trans('Please fill out this field.')
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      var _this$$refs$disabledT;
      if (((_this$$refs$disabledT = _this.$refs.disabledTextBox) === null || _this$$refs$disabledT === void 0 ? void 0 : _this$$refs$disabledT.scrollHeight) > 44) {
        _this.showChevron = true;
      }
    });
  },
  methods: {
    closeExpand: function closeExpand() {
      this.isTextBoxExpanded = false;
    }
  }
};