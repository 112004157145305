import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = {
  class: "flex justify-between items-center"
};
var _hoisted_3 = {
  class: "my-controls"
};
var _hoisted_4 = {
  key: 0,
  class: "controls-nothing"
};
var _hoisted_5 = {
  class: "controls-nothing-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_catalogue_header = _resolveComponent("catalogue-header");
  var _component_control_filters = _resolveComponent("control-filters");
  var _component_controls_list = _resolveComponent("controls-list");
  var _component_vertical_menu_wrapper = _resolveComponent("vertical-menu-wrapper");
  var _component_complete_modal = _resolveComponent("complete-modal");
  var _component_control_review_modal = _resolveComponent("control-review-modal");
  return !_ctx.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_vertical_menu_wrapper, null, {
    menu: _withCtx(function () {
      return [_createVNode(_component_vertical_menu, {
        items: $options.menuItems,
        "exact-match": true
      }, null, 8 /* PROPS */, ["items"])];
    }),
    content: _withCtx(function () {
      return [$options.notificationObj ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: $options.notificationObj.type
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.notificationObj.message), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
        title: $options.currentGroup.label,
        type: "big"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_catalogue_header, {
        "view-type": $setup.currentView,
        "onUpdate:viewType": _cache[0] || (_cache[0] = function ($event) {
          return $setup.currentView = $event;
        }),
        "view-types": $data.STANDARD_VIEWS,
        "is-overview-options-available": false,
        class: "mr-4"
      }, null, 8 /* PROPS */, ["view-type", "view-types"])]), _createVNode(_component_control_filters, {
        modelValue: $data.filters,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.filters = $event;
        }),
        data: _ctx.filtersData,
        "state-local": $data.stateLocal,
        onResetFilters: $options.resetFilters
      }, null, 8 /* PROPS */, ["modelValue", "data", "state-local", "onResetFilters"]), _createElementVNode("div", _hoisted_3, [$options.noControls ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
        title: $data.translate.nothingToShow,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_5, _toDisplayString($data.translate.noControlsWarning), 1 /* TEXT */)])) : (_openBlock(), _createBlock(_component_controls_list, {
        key: 1,
        activities: $options.currentGroup.activities,
        "filtered-organisations": _ctx.organisations,
        "button-type": $options.currentGroup.buttonType,
        "additional-fields": $options.currentGroup.additionalFields,
        "current-group": $options.currentGroup.key,
        "view-type": $setup.currentView,
        onShowCompleteModal: $options.showCompleteModal,
        onShowReviewModal: $options.showReviewModal,
        onCompleteFromPreviewMode: _cache[2] || (_cache[2] = function ($event) {
          return $options.controlUpdate($data.currentControlId, $event, true);
        }),
        onUpdateFromPreviewMode: _cache[3] || (_cache[3] = function ($event) {
          return $data.updatedControlFromPreview = $event;
        }),
        onOnDismissModal: $options.updateFromPreviewMode,
        onDeleteItem: _cache[4] || (_cache[4] = function ($event) {
          return _ctx.$emit('deleteItem', $event);
        }),
        onReloadRootPage: $options.loadData
      }, null, 8 /* PROPS */, ["activities", "filtered-organisations", "button-type", "additional-fields", "current-group", "view-type", "onShowCompleteModal", "onShowReviewModal", "onOnDismissModal", "onReloadRootPage"]))])];
    }),
    _: 1 /* STABLE */
  }), $data.isCompleteModalShow ? (_openBlock(), _createBlock(_component_complete_modal, {
    key: 0,
    id: $data.currentControlId,
    cpath: $data.currentControlCpath,
    onClose: _cache[5] || (_cache[5] = function ($event) {
      return $data.isCompleteModalShow = false;
    }),
    onUpdate: _cache[6] || (_cache[6] = function ($event) {
      return $options.controlUpdate($data.currentControlId, $event);
    })
  }, null, 8 /* PROPS */, ["id", "cpath"])) : _createCommentVNode("v-if", true), $data.isReviewModalShow ? (_openBlock(), _createBlock(_component_control_review_modal, {
    key: 1,
    id: $data.currentControlId + '',
    cpath: $data.currentControlCpath,
    onClose: _cache[7] || (_cache[7] = function ($event) {
      return $data.isReviewModalShow = false;
    }),
    onUpdate: _cache[8] || (_cache[8] = function ($event) {
      return $options.controlUpdate($data.currentControlId, $event);
    })
  }, null, 8 /* PROPS */, ["id", "cpath"])) : _createCommentVNode("v-if", true)]));
}