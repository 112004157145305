import { getTranslate } from './translate';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import { focus } from '@/utils/directives/focus';
export default {
  name: 'RismaVueMultiselectDropdown',
  components: {
    FeatherIcon: FeatherIcon,
    LoadingIndicatorSmall: LoadingIndicatorSmall
  },
  directives: {
    focus: focus
  },
  props: {
    selectedOptions: {
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'list of all selected options with only trackby map field'
    },
    openDirection: {
      required: false,
      default: '',
      type: String,
      note: 'Fixed opening direction (instead of auto). Options are "above"/"top" or "below"/"bottom'
    },
    isEmpty: {
      type: Boolean,
      default: false
    },
    filteredOptions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    enableSelectAll: {
      type: Boolean,
      default: true,
      note: 'Boolean to determine if show `Select All` feature in select'
    },
    currentPointerIndex: {
      type: Number,
      default: 0
    },
    trackBy: {
      required: false,
      type: String,
      default: 'id',
      note: 'Key to compare options by'
    },
    labelKey: {
      type: String,
      required: true,
      note: 'The key to create the labels by'
    },
    searchEnabled: {
      type: Boolean,
      default: false
    },
    groupSelect: {
      type: Boolean,
      required: false,
      default: false,
      note: 'group-select is used to define if selecting the group label should select/unselect all values in the group, or do nothing.'
    },
    areAllSelected: {
      type: Boolean,
      required: false,
      default: false
    },
    lockedOptions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    searchPropValue: {
      type: String,
      default: ''
    },
    allDataShown: {
      type: Boolean,
      default: true
    },
    isLazyLoadingShown: {
      type: Boolean,
      default: false
    }
  },
  emits: ['select', 'selectAll', 'inputBlur', 'search', 'close', 'inputEvent', 'hover', 'onMouseleave', 'load'],
  data: function data() {
    return {
      searchValue: '',
      translate: getTranslate['RismaVueMultiselectDropdown']()
    };
  },
  computed: {
    selectAllText: function selectAllText() {
      if (this.areAllSelected) return this.translate.deselectAll;
      return this.translate.selectAll;
    }
  },
  mounted: function mounted() {
    if (this.searchEnabled) this.$refs.input.focus();
    this.searchValue = this.searchPropValue;
    if (!this.isEmpty) this.$refs.options.addEventListener('scroll', this.onItemsListScroll);
  },
  methods: {
    onItemsListScroll: function onItemsListScroll(event) {
      if (this.allDataShown) return;
      var target = event.target;
      var bottomOfWindow = target.scrollHeight - target.scrollTop - target.clientHeight < 1;
      if (bottomOfWindow) {
        this.$emit('load');
      }
    },
    focusSelected: function focusSelected(index) {
      if (index === -1) return;
      var selectedEl = this.$refs.options.children[index];
      var element = this.$refs.options.getBoundingClientRect();
      var elementRelativeOffset = selectedEl.offsetTop + selectedEl.offsetHeight;
      var listHeightWithScroll = element.height + this.$refs.options.scrollTop;
      if (elementRelativeOffset > listHeightWithScroll || selectedEl.offsetTop < this.$refs.options.scrollTop) {
        this.$refs.options.scrollTop = elementRelativeOffset - element.height;
      }
    },
    getTextAndBgClasses: function getTextAndBgClasses(option, index) {
      var classes = '';
      var addHoverClass = (!option.isGroup || option.isGroup && this.groupSelect) && !this.lockedOptions.includes(option[this.trackBy]) && this.currentPointerIndex === index;
      var isSelected = this.selectedOptions.includes(option[this.trackBy]);
      if (isSelected) {
        classes += 'text-rm-active-blue glob-h3';
        if (!addHoverClass) {
          classes += ' bg-rm-active-blue--10';
        }
      }
      if (addHoverClass) {
        classes += ' cursor-pointer bg-rm-active-blue--6';
      }
      return classes;
    }
  }
};