// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answered-questions[data-v-324042dc] {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  display: inline-block;
  padding: 10px;
  margin-bottom: 2px;
  width: 100%;
  height: 500px;
  overflow: auto;
}
.answered-questions li[data-v-324042dc] {
  margin: 0 0 0.75rem;
}
.answered-questions .question-item[data-v-324042dc] {
  display: flex;
  justify-content: space-between;
}
.answered-questions .question-item .question[data-v-324042dc],
.answered-questions .question-item .show-answer[data-v-324042dc] {
  cursor: pointer;
  font-weight: 700;
  font-style: italic;
  font-size: 15px;
  color: #0e2954;
}
.answered-questions .question-item .question[data-v-324042dc] {
  width: 85%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.answered-questions .question-item .question[data-v-324042dc]:hover {
  white-space: normal;
}
.answered-questions .question-item .show-answer[data-v-324042dc] {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.answered-questions .question-item .show-answer-label[data-v-324042dc] {
  padding-right: 3px;
}
.question-answer-score[data-v-324042dc] {
  float: right;
}
.question-answer-text[data-v-324042dc] {
  max-width: 87%;
  display: inline-block;
}
.assessments-wrap[data-v-324042dc] {
  display: flex;
  width: 100%;
}
.assessments-wrap .main-right-title[data-v-324042dc] {
  margin: 0 0 2rem;
}
.assessments-wrap .right-top-description p[data-v-324042dc],
.assessments-wrap .right-top-second-description p[data-v-324042dc] {
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
.assessments-wrap .right-top-second-description[data-v-324042dc] {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.assessments-wrap .traffic-holder[data-v-324042dc] {
  margin: 0 5px 0 0;
}
.assessments-wrap .right-block-col-right[data-v-324042dc] {
  margin-top: 5rem;
}
.assessment-textarea-wrap[data-v-324042dc] {
  margin-top: 2rem;
}
.assessment-textarea-wrap[data-v-324042dc]  .ql-container {
  height: 260px;
}
.right-top[data-v-324042dc] {
  margin: 0 0 1rem;
}
[data-v-324042dc]  .tag-item {
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(235 235 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(230 240 255 / var(--tw-bg-opacity));
  padding: 0.25rem;
}
[data-v-324042dc]  .tag-item:last-child {
  margin-bottom: 0px;
}
[data-v-324042dc]  .tag-item .delete-btn {
  margin-left: auto;
  stroke-width: 3;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
