import { Http } from '../index';
import * as HttpUtils from '../httpUtils';
import { userHasCompaniesAccess } from '@/routes/auth';
export function getCompanies() {
  var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  if (!userHasCompaniesAccess()) {
    return Promise.resolve({
      list: []
    });
  }
  return HttpUtils.handleRequestResponse(Http.get('/companies/company' + (query ? "?".concat(query) : '')));
}
export function getCompany(id) {
  var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  if (!userHasCompaniesAccess()) {
    return Promise.resolve(null);
  }
  return HttpUtils.handleRequestResponse(Http.get("/companies/company/".concat(id).concat(query)));
}
export function addCompany(data) {
  return HttpUtils.handleRequestResponse(Http.post('/companies/company', data));
}
export function editCompany(data) {
  return HttpUtils.handleRequestResponse(Http.patch("/companies/company/".concat(data.id), data));
}
export function deleteCompany(id) {
  return HttpUtils.handleRequestResponse(Http.delete("/companies/company/".concat(id)));
}
export function getContacts() {
  var companyId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  if (!userHasCompaniesAccess()) {
    return Promise.resolve({
      list: []
    });
  }
  var url = '/companies/contacts';
  if (companyId) {
    url += '?companyId=' + companyId;
  }
  return HttpUtils.handleRequestResponse(Http.get(url));
}
export function getContactsByCompanyId(companyId) {
  return HttpUtils.handleRequestResponse(Http.get("/companies/company/".concat(companyId, "/contacts")));
}
export function addContact(companyId, data) {
  return HttpUtils.handleRequestResponse(Http.post("/companies/company/".concat(companyId, "/contacts"), data));
}
export function editContact(data) {
  return HttpUtils.handleRequestResponse(Http.patch("/companies/contacts/".concat(data.id), data));
}
export function deleteContact(id) {
  return HttpUtils.handleRequestResponse(Http.delete("/companies/contacts/".concat(id)));
}
export function getFunctions(companyId) {
  return HttpUtils.handleRequestResponse(Http.get("/companies/company/".concat(companyId, "/functions")));
}
export function addFunction(companyId, data) {
  return HttpUtils.handleRequestResponse(Http.post("/companies/company/".concat(companyId, "/functions"), data));
}
export function editFunction(companyId, data) {
  return HttpUtils.handleRequestResponse(Http.patch("/companies/company/".concat(companyId, "/functions/").concat(data.id), data));
}
export function deleteFunction(companyId, id) {
  return HttpUtils.handleRequestResponse(Http.delete("/companies/company/".concat(companyId, "/functions/").concat(id)));
}
export function getSelectBoxOptions(name) {
  return HttpUtils.handleRequestResponse(Http.get("data/selectbox_options/".concat(name)));
}