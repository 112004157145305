function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { Colors } from '@/Colors';
import { TabTypes } from '@/constants/Tabs';
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import { useMainStore } from '@/Store/mainStore';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
export default {
  name: 'ActionGap',
  description: 'This is a preview component for links page - gap tab',
  components: {
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    PreviewModalLink: PreviewModalLink
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: function _default() {}
    },
    gapList: {
      requred: true,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      Colors: Colors,
      TabTypes: TabTypes,
      translate: getTranslate['ActionGap']()
    };
  },
  computed: {
    linkToNodeGapTab: function linkToNodeGapTab() {
      return "/compliance/".concat(this.data.projectId, "/edit/").concat(this.data.nodeId, "/gap/");
    },
    enhancedGapList: function enhancedGapList() {
      var _this = this;
      return this.gapList.map(function (gap) {
        var trafficLight = _this.getGapLight(gap.analysis_rag);
        var result = _objectSpread(_objectSpread({}, gap), {}, {
          url: _this.linkToNodeGapTab + gap.articleId,
          compliance_projects_id: _this.data.projectId,
          color: trafficLight === null || trafficLight === void 0 ? void 0 : trafficLight.color,
          icon: trafficLight.icon,
          route: {
            tab: TabTypes.GAPANALYSIS,
            id: gap.articleId
          }
        });
        return result;
      });
    }
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useMainStore, ['closeAllPreviews'])), mapActions(useTrafficLights, ['getGapLight'])), {}, {
    generateTargetRoute: function generateTargetRoute(articleId) {
      return {
        path: this.linkToNodeGapTab + articleId,
        force: true
      };
    },
    onLinkClick: function onLinkClick(articleId) {
      if ("".concat(this.linkToNodeGapTab).concat(articleId).startsWith(this.$router.currentRoute.value.fullPath)) {
        this.closeAllPreviews();
      }
    }
  })
};