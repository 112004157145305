function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import RismaExcel from '@/components/Atoms/Excel/RismaExcel';
import RismaSelectorBar from '@/components/Molecules/RismaSelectorBar';
import RismaBarChart from '@/components/Molecules/Charts/RismaBarChart';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import Notification from '@/components/Molecules/Notification';
import GapSummaryTable from '@/components/Organisms/Compliance/GapSummaryTable';
import GapTable from '@/components/Organisms/Compliance/Dashboard/GapTable';
import { RismaSelectorBarMixin } from '@/mixins/RismaSelectorBarMixin';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { ComplianceDashboardMixin } from '@/components/Pages/Compliance/ComplianceDashboardMixin';
export default {
  name: 'GapDashboard',
  components: {
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    TrafficLight: TrafficLight,
    RismaExcel: RismaExcel,
    RismaSelectorBar: RismaSelectorBar,
    RismaBarChart: RismaBarChart,
    RismaDoughnutChart: RismaDoughnutChart,
    Notification: Notification,
    GapSummaryTable: GapSummaryTable,
    GapTable: GapTable
  },
  mixins: [RaciTitleMixin, ComplianceDashboardMixin, RismaSelectorBarMixin],
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Project id from route'
    },
    stripped: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      gapByDomain: [],
      domains: [],
      sliceSelected: {},
      localStorageKey: "gap_dashboard_filters_".concat(this.projectId),
      gapFilter: [1, 2, 3, 4, 5],
      exportFileName: 'General GAP summary',
      updatedInPreview: false,
      solutionsPropName: 'gapArticle.solutions',
      translate: getTranslate['GapDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, {
    currentUserShowGapByProjectId: 'showGapByProjectId',
    isAdmin: 'isAdmin'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'complianceActivityTypes'
  })), {}, {
    filteredActivityTypeIds: function filteredActivityTypeIds() {
      return this.activityTypes.reduce(function (list, activityType) {
        var _activityType$optiona;
        if ((activityType === null || activityType === void 0 || (_activityType$optiona = activityType.optionalFields) === null || _activityType$optiona === void 0 ? void 0 : _activityType$optiona.indexOf('gap_schema')) !== -1) list.push(activityType.id);
        return list;
      }, []);
    },
    enableGapRiskModule: function enableGapRiskModule() {
      var _this$currentProject;
      return !!((_this$currentProject = this.currentProject) !== null && _this$currentProject !== void 0 && _this$currentProject.enableGapRiskModule);
    },
    showRiskAssessment: function showRiskAssessment() {
      var _this$currentProject2;
      return !!((_this$currentProject2 = this.currentProject) !== null && _this$currentProject2 !== void 0 && _this$currentProject2.showRiskAssessment);
    },
    showGapArticleTypes: function showGapArticleTypes() {
      var _this$currentProject3;
      return !!((_this$currentProject3 = this.currentProject) !== null && _this$currentProject3 !== void 0 && _this$currentProject3.showGapArticleTypes);
    },
    exportColumns: function exportColumns() {
      return {
        businessarea: this.translate.businessArea,
        questionnaire: this.translate.questionnaire,
        assessments: this.translate.totalNumberOfAssessments,
        notset: this.translate.notSet,
        nogap: this.translate.noGap,
        gaplow: this.translate.gapLow,
        gapmeduim: this.translate.gapMedium,
        gaphigh: this.translate.gapHigh,
        gapna: this.translate.na
      };
    },
    selectors: function selectors() {
      return [{
        list: 'nodes',
        label: this.translate.nodes,
        preSelected: this.predefinedNodesFilter,
        toggle: {
          title: this.translate.includeUnderlyingNodes,
          option: this.includeChildToggleOption,
          event: this.onIncludeChildNodesToggle
        }
      }, {
        list: 'organisations',
        label: this.translate.organisations,
        preSelected: this.predefinedOrganisationsFilter
      }, {
        list: 'users',
        label: this.responsibleTitle(true),
        preSelected: this.predefinedUsersFilter
      }, {
        list: 'tags',
        label: this.translate.tags,
        preSelected: this.predefinedTagsFilter
      }, {
        list: 'frameworks',
        label: this.translate.frameworks,
        preSelected: this.predefinedFrameworksFilter,
        toggle: {
          title: this.translate.includeUnderlyingFrameworks,
          option: this.includeFrameworkChildToggleOption,
          event: this.onIncludeFrameworksChildNodesToggle
        }
      }];
    },
    filteredNodes: function filteredNodes() {
      var _this$newGapData;
      var nodes = (_this$newGapData = this.newGapData) === null || _this$newGapData === void 0 ? void 0 : _this$newGapData.byNode;
      if (!nodes) return [];
      var nodesIdsList = Object.keys(nodes).map(function (id) {
        return +id;
      });
      return this.nodes.filter(function (_ref) {
        var id = _ref.id;
        return nodesIdsList.includes(id);
      });
    },
    exportDataset: function exportDataset() {
      return this.prepareExportDataset(this.filteredNodes);
    },
    gapSummaryColumns: function gapSummaryColumns() {
      return [this.translate.businessArea, this.translate.questionnaire, this.translate.totalNumberOfAssessments];
    },
    gapSummaryDataset: function gapSummaryDataset() {
      var _this = this;
      var gapOrder = ['gap_records_not_set', 'gap_records_no_gap', 'gap_records_low_gap', 'gap_records_medium_gap', 'gap_records_high_gap', 'gap_records_na'];
      return this.filteredNodes.map(function (node) {
        var _node$questionnaire;
        var id = node.id,
          objType = node.objType,
          url = node.url;
        return {
          node: {
            title: node.title,
            activity: {
              id: id,
              objType: objType,
              url: url,
              compliance_projects_id: +_this.projectId
            }
          },
          questionnaire: (_node$questionnaire = node.questionnaire) === null || _node$questionnaire === void 0 ? void 0 : _node$questionnaire.title,
          total: node.values.gap_records_count,
          gaps: gapOrder.map(function (gapKey) {
            return node.values[gapKey] || 0;
          })
        };
      });
    },
    currentProject: function currentProject() {
      return this.complianceProjectById(this.projectId);
    }
  }),
  mounted: function mounted() {
    localStorage.setItem("compliance-dashboard-tab-".concat(this.projectId), 'gap');
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useComplianceProjectsStore, {
    complianceProjectById: 'getProjectById'
  })), {}, {
    handleListChange: function handleListChange(data) {
      this.sliceSelected = {};
      this.fetchData(data);
    },
    prepareExportDataset: function prepareExportDataset(nodes) {
      return nodes.map(function (node) {
        return {
          businessarea: node.title,
          questionnaire: node.questionnaire ? node.questionnaire.title : 'N/A',
          assessments: node.values.gap_records_count,
          notset: node.values.gap_records_not_set,
          nogap: node.values.gap_records_no_gap,
          gaplow: node.values.gap_records_low_gap,
          gapmeduim: node.values.gap_records_medium_gap,
          gaphigh: node.values.gap_records_high_gap,
          gapna: node.values.gap_records_na
        };
      });
    },
    loadData: function loadData() {
      var _this2 = this;
      var promises = [this.getGapSchemas()];
      if (this.showGapArticleTypes) promises.push(this.getGapTypes(this.projectId));
      return Promise.all(promises).catch(function (error) {
        return _this2.handleDashboardError(error);
      });
    },
    loadDashboardData: function loadDashboardData(filterString) {
      var _this3 = this;
      this.loadingStats = true;
      return this.getGapDashboard(filterString).then(function () {
        return _this3.loadingStats = false;
      });
    },
    onDismissPreview: function onDismissPreview() {
      if (this.updatedInPreview) {
        this.fetchData();
        this.getTreeByProjectId(this.projectId);
        this.updatedInPreview = false;
      }
    }
  })
};