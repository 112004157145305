var _excluded = ["options", "unit", "children"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import debounce from 'lodash/debounce';
import { getTranslate } from './translate';
import { getScoring as _getScoring, updateScoring, deleteScoring as _deleteScoring, updateTableCalculation, updateScoringTableValue, updateScoringTableFieldWeight } from '@/api/risk/scoring';
import { getFieldGroupsByScoringId as _getFieldGroupsByScoringId } from '@/api/admin/fieldGroups';
import { deleteCustomField } from '@/api/admin/admin';
import MainWell from '@/components/Atoms/Containers/MainWell';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import ScrollTopButton from '@/components/Atoms/Buttons/ScrollTopButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import RiskMatrixAxisParametersWell from '@/components/Organisms/Admin/RiskMatrixAxisParametersWell';
import CustomRiskFieldsWell from '@/components/Organisms/Admin/CustomRiskFieldsWell';
import ScoringOtherParameters from '@/components/Organisms/Admin/ScoringOtherParameters';
import { ErmRiskStatus } from '@/constants/risks/RiskStatus';
import { CustomFieldValidationMixin } from '@/components/Organisms/Admin/CustomFieldValidationMixin';
import { SETTINGS_ROUTE_NAMES } from '@/constants/routes/settings';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { isOnlyLettersNumbersSpacesDashes } from '@/utils/validations';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
export default {
  name: 'ErmPeriodDescription',
  introduction: 'Main edit page for scoring period',
  description: 'Show and edit scoring period. For ERM risks',
  components: {
    InputField: InputField,
    FeatherIcon: FeatherIcon,
    MainWell: MainWell,
    RiskMatrixAxisParametersWell: RiskMatrixAxisParametersWell,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    ScoringOtherParameters: ScoringOtherParameters,
    CustomRiskFieldsWell: CustomRiskFieldsWell,
    ScrollTopButton: ScrollTopButton,
    Checkboxes: Checkboxes
  },
  mixins: [CustomFieldValidationMixin],
  props: {
    scoringId: {
      type: [String, Number],
      require: true,
      default: null
    }
  },
  data: function data() {
    return {
      saveButtonClickCounter: 0,
      isLoaded: false,
      showModal: false,
      scoring: {},
      status: false,
      updatedData: {},
      fieldGroups: null,
      fieldDeafultValue: {
        typeId: null,
        options: [],
        unit: '',
        children: [],
        errors: {}
      },
      isDirty: false,
      isTitleValid: true,
      isUpdatingScoring: false,
      isDeletingScoring: false,
      RiskBaseModelProps: RiskBaseModelProps,
      translate: getTranslate['ErmPeriodDescription']()
    };
  },
  computed: {
    isReadonly: function isReadonly() {
      return this.scoring.status === ErmRiskStatus.OPEN || this.scoring.status === ErmRiskStatus.CLOSED;
    },
    isEditingMode: function isEditingMode() {
      return this.scoring.status === ErmRiskStatus.EDITING;
    },
    isPeriodClosed: function isPeriodClosed() {
      return this.scoring.status === ErmRiskStatus.CLOSED;
    },
    savePeriodTitle: function savePeriodTitle() {
      return this.status && !this.scoring.isCurrent ? this.translate.saveAndOpen : this.translate.save;
    },
    scoringFormated: function scoringFormated() {
      var _this = this;
      var _this$scoring = this.scoring,
        z_axis_scores = _this$scoring.z_axis_scores,
        z_axis_name = _this$scoring.z_axis_name,
        z_axis_values = _this$scoring.z_axis_values;
      var result = _objectSpread({
        z_axis_scores: z_axis_scores,
        z_axis_name: z_axis_name,
        z_axis_values: z_axis_values
      }, this.updatedData);
      var _loop = function _loop() {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
          key = _Object$entries$_i[0],
          value = _Object$entries$_i[1];
        if (key === 'z_axis_scores') {
          result.zAxisLevels = value;
          delete result.z_axis_scores;
        }
        if (key === 'z_axis_name') {
          result.zAxisName = value;
          delete result.z_axis_name;
        }
        if (key === 'z_axis_values') {
          result.zAxisLabels = Object.keys(value).map(function (key, index) {
            return _objectSpread(_objectSpread({}, value[key]), {}, {
              score: index + 1,
              label: value[key].value
            });
          });
          delete result.z_axis_values;
        }
        if (key === 'fields') {
          result.fields = _this.prepareFieldsToSave(value);
        }
      };
      for (var _i = 0, _Object$entries = Object.entries(result); _i < _Object$entries.length; _i++) {
        _loop();
      }
      if (this.status !== this.scoring.status) {
        result.status = this.status ? ErmRiskStatus.OPEN : this.scoring.status;
      }
      return result;
    },
    otherParameters: function otherParameters() {
      var _this2 = this;
      return [1, 2].map(function (item) {
        return {
          title: "".concat(_this2.translate.otherParameters, " #").concat(item),
          label: _this2.scoring[_this2.getScoringKey('label', item)],
          labels: _this2.scoring[_this2.getScoringKey('labels', item)],
          inUse: _this2.scoring[_this2.getScoringKey('inUse', item)]
        };
      });
    },
    isSaveBtnDisabled: function isSaveBtnDisabled() {
      return !this.isDirty || this.isUpdatingScoring;
    }
  },
  created: function created() {
    this.onAxisTableChangeDebounced = debounce(this.onAxisTableChange, 1000);
  },
  mounted: function mounted() {
    var _this3 = this;
    Promise.all([this.getFieldGroupsByScoringId(), this.getScoring()]).then(function () {
      return _this3.isLoaded = true;
    }).catch(function (error) {
      _this3.$router.push({
        name: SETTINGS_ROUTE_NAMES.erm_admin,
        params: {
          errorScoring: error
        }
      });
    });
  },
  methods: {
    getScoring: function getScoring() {
      var _this4 = this;
      return _getScoring(this.scoringId).then(function (result) {
        _this4.scoring = result;
        _this4.scoring.fields.forEach(function (item, index) {
          return _this4.scoring.fields[index] = _objectSpread(_objectSpread({}, _this4.fieldDeafultValue), item);
        });
        _this4.status = _this4.scoring.status === ErmRiskStatus.OPEN;
      }).catch(function (error) {
        var _error$response;
        throw error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error;
      });
    },
    getFieldGroupsByScoringId: function getFieldGroupsByScoringId() {
      var _this5 = this;
      return _getFieldGroupsByScoringId(this.scoringId).then(function (response) {
        _this5.fieldGroups = response.list;
      });
    },
    updateFieldGroups: function updateFieldGroups(groups) {
      this.fieldGroups = groups;
    },
    updateFieldsOrder: function updateFieldsOrder(fields) {
      this.scoring.fields = fields;
      var prearedFields = this.prepareFieldsToSave(fields);
      this.updatePeriod(this.scoringId, {
        fields: prearedFields
      });
    },
    onAxisTableChange: function onAxisTableChange(_ref) {
      var _this6 = this;
      var fieldname = _ref.fieldname,
        value = _ref.value,
        fieldType = _ref.fieldType,
        id = _ref.id;
      var promise;
      if (fieldType === 'fieldweight') {
        promise = updateScoringTableFieldWeight(this.scoring.id, {
          fieldId: id,
          fieldWeight: value
        });
      } else {
        var params = "scoring=".concat(this.scoring.id, "&fieldname=").concat(fieldname, "&fieldvalue=").concat(value);
        promise = updateScoringTableValue(params);
      }
      return promise.then(function () {
        return _this6.getScoring();
      }).catch(function (error) {
        return _this6.$notify({
          title: _this6.$trans(error.response.error),
          type: 'error'
        });
      });
    },
    onCalculationChange: function onCalculationChange(type, id, calculationType) {
      var _this7 = this;
      return updateTableCalculation(id, calculationType).then(function () {
        _this7.scoring[type]['calculationType'] = calculationType;
      });
    },
    deleteScoring: function deleteScoring() {
      var _this8 = this;
      if (this.isDeletingScoring) return;
      this.isDeletingScoring = true;
      return _deleteScoring(this.scoring.id).then(function () {
        _this8.$router.push('/settings/' + SETTINGS_ROUTE_NAMES.erm_admin);
      }).catch(function (error) {
        var _error$response2;
        var errorMessage = error.message || (error === null || error === void 0 || (_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.error);
        _this8.$notify({
          title: errorMessage,
          type: 'error'
        });
      });
    },
    showConfirmation: function showConfirmation() {
      var _this9 = this;
      this.$confirm('', this.translate.areYouSureYouWantToDeleteThis, function (res) {
        return res && _this9.deleteScoring();
      });
    },
    updateTitle: function updateTitle(value) {
      this.isTitleValid = isOnlyLettersNumbersSpacesDashes(value);
      this.scoring.version = value;
      this.updatedData.version = value;
      this.setIsDirty(true);
    },
    onMatrixAxisParametersChange: function onMatrixAxisParametersChange(key, value) {
      var scoringKey = this.getScoringKey(key) || key;
      if (value !== null && _typeof(value) === 'object' && !Array.isArray(value)) {
        this.scoring[scoringKey] = _objectSpread(_objectSpread({}, this.scoring[scoringKey]), value);
        this.updatedData[scoringKey] = _objectSpread(_objectSpread({}, this.updatedData[scoringKey]), value);
      } else {
        this.scoring[scoringKey] = value;
        this.updatedData[scoringKey] = value;
      }
      this.setIsDirty(true);
    },
    onOtherParametersChange: function onOtherParametersChange(_ref2, index) {
      var key = _ref2.key,
        value = _ref2.value;
      var scoringKey = this.getScoringKey(key, index);
      if (key === 'labels') {
        for (var _i2 = 0, _Object$entries2 = Object.entries(value); _i2 < _Object$entries2.length; _i2++) {
          var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
            objKey = _Object$entries2$_i[0],
            objValue = _Object$entries2$_i[1];
          var updatedKey = this.getOtherParametersKey(key, index, objKey);
          this.updatedData[updatedKey] = objValue;
        }
      } else {
        var _updatedKey = this.getOtherParametersKey(key, index);
        this.updatedData[_updatedKey] = value;
      }
      this.scoring[scoringKey] = value;
      this.setIsDirty(true);
    },
    getScoringKey: function getScoringKey(key, index) {
      var config = {
        label: "impactOther".concat(index, "Label"),
        labels: "impactOther".concat(index, "Labels"),
        inUse: "impactOther".concat(index, "InUse"),
        likelihoodLabels: 'likelihood_labels'
      };
      return config[key];
    },
    getOtherParametersKey: function getOtherParametersKey(key, index, scoreIndex) {
      var config = {
        label: "impact_other".concat(index, "_name"),
        labels: "impact_other".concat(index, "_score").concat(scoreIndex),
        inUse: "impact_other".concat(index, "_inuse")
      };
      return config[key];
    },
    onCustomFieldChange: function onCustomFieldChange(fields) {
      this.scoring.fields = fields;
      this.updatedData.fields = fields;
      this.setIsDirty(true);
    },
    validateCustomFields: function validateCustomFields() {
      var _this10 = this;
      var customFieldsValid = true;
      this.scoring.fields.forEach(function (item) {
        if (!_this10.validate(item)) {
          customFieldsValid = false;
        }
      });
      return customFieldsValid;
    },
    onUpdateScoring: function onUpdateScoring() {
      var _this11 = this;
      this.saveButtonClickCounter++;
      var isCFValid = this.validateCustomFields();
      if (!isCFValid) {
        this.$nextTick(function () {
          var errorEl = _this11.$el.querySelector('.option-list-error');
          if (!errorEl) return;
          _this11.$scrollTo(errorEl, 100, {
            offset: -150
          });
        });
      }
      if (!this.isTitleValid) return;
      isCFValid && this.updatePeriod(this.scoringId, this.scoringFormated);
    },
    updatePeriod: function updatePeriod(scoringId, data) {
      var _this12 = this;
      this.isUpdatingScoring = true;
      return updateScoring(scoringId, data).then(function (response) {
        _this12.scoring = response;
        _this12.updatedData = {};
        _this12.setIsDirty(false);
        _this12.$notify({
          title: _this12.translate.masterDataSaved
        });
      }).catch(function (error) {
        return _this12.$notify({
          title: error.message,
          type: 'error'
        });
      }).finally(function () {
        _this12.isUpdatingScoring = false;
      });
    },
    onCustomFieldDelete: function onCustomFieldDelete(id) {
      var _this13 = this;
      return deleteCustomField(id).then(function () {
        return _this13.getScoring();
      });
    },
    prepareFieldsToSave: function prepareFieldsToSave(fields) {
      var _this14 = this;
      return fields.map(function (field) {
        var options = field.options,
          unit = field.unit,
          children = field.children,
          rest = _objectWithoutProperties(field, _excluded);
        delete rest.errors;
        rest.fieldType = field.typeId;
        if (rest.typeId === CustomFieldTypes.SELECT) {
          rest.options = options;
        }
        if (rest.typeId === CustomFieldTypes.ROW_GROUP) {
          rest.children = _this14.prepareIds(children);
        }
        if (rest.typeId === CustomFieldTypes.NUMERICAL) {
          rest.unit = unit;
        }
        return rest;
      });
    },
    onOpenCloseChange: function onOpenCloseChange($event) {
      this.status = $event.isChecked;
      this.setIsDirty();
    },
    setIsDirty: function setIsDirty() {
      var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.isDirty = value;
    },
    backToPrevPage: function backToPrevPage() {
      this.$router.push({
        name: SETTINGS_ROUTE_NAMES.erm_admin
      });
    },
    moveToAdmin: function moveToAdmin() {
      this.$router.push(SETTINGS_ROUTE_NAMES.erm_admin);
    }
  }
};