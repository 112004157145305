function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { stripRecognisedHTMLOnly } from '@/utils';
import { getUserLanguage, generateKey, formatCurrency as _formatCurrency } from '@/utils/Utils';
import { focus } from '@/utils/directives/focus';
var inputTypes = ['color', 'date', 'number', 'password', 'search', 'text', 'url', 'file'];
var MINUS_CHAR_CODE = 45;
export default {
  name: 'InputField',
  introduction: 'for when the user is typing',
  description: 'This is a simple input field that emits an "updated"-event when the value changes',
  token: '<input-field type="text" preText="Already filled out data"/>',
  directives: {
    focus: focus
  },
  components: {
    RismaLabel: RismaLabel,
    FeatherIcon: FeatherIcon
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
      validator: function validator(type) {
        return inputTypes.indexOf(type) !== -1;
      },
      note: "A type of input html tag. Possible values: ".concat(inputTypes.join(', '))
    },
    preText: {
      required: false,
      type: String,
      default: '',
      note: 'text that if any should be written in the input field'
    },
    required: {
      type: Boolean,
      default: false,
      note: 'HTML5 Required input field'
    },
    placeholder: {
      required: false,
      type: String,
      default: '',
      note: 'placeholder text that is written when input is empty'
    },
    modelValue: {
      required: false,
      type: [String, Number],
      //u can use Number for type 'number' (then v-model can work with it)
      default: '',
      note: 'v-model'
    },
    enabled: {
      required: false,
      type: Boolean,
      default: true,
      note: 'Used to enable/disable field, e.g. for showing read-only pages'
    },
    maxlength: {
      required: false,
      type: [Number, String],
      default: 255,
      note: 'DOM native maxlength property for input'
    },
    max: {
      required: false,
      type: [Number, String],
      default: null,
      note: 'DOM native max property for input'
    },
    min: {
      required: false,
      type: [Number, String],
      default: null,
      note: 'DOM native min property for input'
    },
    step: {
      required: false,
      type: [Number, String],
      default: null,
      note: 'DOM native step property for input'
    },
    externalStyles: {
      required: false,
      type: String,
      default: '',
      note: 'Styles for input element'
    },
    externalClasses: {
      required: false,
      type: String,
      default: '',
      note: 'Classes for input element'
    },
    tabindex: {
      type: [Number, String],
      required: false,
      default: 0,
      note: 'index for tabbing'
    },
    autocomplete: {
      type: String,
      required: false,
      default: undefined,
      note: 'autocomplete if possible'
    },
    enableCurrencyTemplate: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Determine if currency format will be enabled. Use it only with type="text"'
    },
    accept: {
      type: String,
      require: false,
      default: '',
      note: 'to define the types of files that the component can select'
    },
    focusOnMount: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Determine if input will be focused after mounting'
    },
    normalizeFn: {
      type: Function,
      required: false,
      default: null,
      note: 'If specified the value after input changed will be overwritten by returned value'
    },
    invalid: {
      type: Boolean,
      required: false,
      default: null,
      note: 'Use this with true value to add some red borders around input field'
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    inputId: {
      type: String,
      required: false,
      default: null
    },
    isStaticHeight: {
      type: Boolean,
      default: false,
      required: false,
      note: 'used to align components'
    },
    hasCharacterCounter: {
      type: Boolean,
      default: false,
      required: false,
      note: 'enables the character counter feature for the input field'
    },
    helpText: {
      type: String,
      default: '',
      required: false,
      note: 'help text when input field is disabled'
    },
    errorHelpText: {
      type: String,
      default: '',
      required: false,
      note: 'help text when input field is in error state'
    },
    isActiveProp: {
      type: Boolean,
      default: false
    },
    allowedSymbolsRegex: {
      type: String,
      default: null,
      required: false,
      note: 'use [a-zA-Z0-9] only for letters and numbers'
    }
  },
  emits: ['updated', 'input', 'update:modelValue', 'focus', 'blur', 'onEnter', 'chooseFileInit', 'onEsc', 'error'],
  data: function data() {
    return {
      rawText: '',
      isFocused: false,
      counterLimitWidth: 0,
      regexForValidation: null
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    id: function id() {
      return this.inputId || (this.title ? generateKey() : null);
    },
    editedText: function editedText() {
      if (this.enableCurrencyTemplate) {
        return this.rawText ? this.rawText.toString().split(this.separator.thousand).join('') : '';
      }
      return this.rawText;
    },
    saveFormat: function saveFormat() {
      if (this.enableCurrencyTemplate) {
        var value = this.editedText.split(this.separator.decimal);
        var leftSide = value[0];
        var rightSide = value[1] || '00';
        return leftSide + '.' + rightSide;
      }
      if (this.type === 'number') {
        return this.rawText === '' ? '' : +this.rawText;
      }
      return this.rawText;
    },
    locale: function locale() {
      return getUserLanguage(this.currentUser) || 'en';
    },
    separator: function separator() {
      var tempValue = 1111.1;
      var formattedValue = new Intl.NumberFormat(this.locale).format(tempValue);
      return {
        decimal: formattedValue[formattedValue.length - 2],
        thousand: formattedValue[1]
      };
    },
    decimalCharCode: function decimalCharCode() {
      if (this.enableCurrencyTemplate) {
        return this.separator.decimal.charCodeAt(0);
      }
      return 46;
    },
    baseStyles: function baseStyles() {
      var baseStyles = 'w-full min-h-40px glob-h3-sr text-rm-text pl-4 py-3 pr-8';
      var placeholderStyles = 'placeholder:text-rm-grey';
      var borderStyle = this.invalid ? 'border-rm-signal-red' : 'border-rm-grey';
      var extraStyles = 'bg-rm-white border rounded-md outline truncate transition duration-300';
      return "".concat(baseStyles, " ").concat(borderStyle, " ").concat(extraStyles, " ").concat(placeholderStyles);
    },
    isErrorOccurred: function isErrorOccurred() {
      return this.invalid || !this.isInputValid;
    },
    interactionStyles: function interactionStyles() {
      var disabledStyles = 'disabled:bg-rm-wisp disabled:border-rm-grey disabled:text-gray-650';
      var base = "".concat(disabledStyles, " outline outline-none outline-offset-0 outline-2");
      var hoverStyles = this.enabled ? 'hover-not-focus' : '';
      if (this.enableCurrencyTemplate) {
        base += ' lining-nums tracking-wider';
      }
      if (this.isFocused || this.isActiveProp) {
        if (this.isErrorOccurred) {
          base += ' !border-rm-signal-red !outline-rm-signal-red--20';
        } else {
          base += " !border-rm-active-blue !outline-rm-active-blue--20 ".concat(hoverStyles);
        }
      }
      if (!this.enabled) {
        base += ' cursor-not-allowed';
      }
      return base;
    },
    isInputValid: function isInputValid() {
      if (this.type !== 'text' || this.enableCurrencyTemplate) return true;
      return (this.rawText + '').trim() === stripRecognisedHTMLOnly((this.editedText || '') + '');
    },
    showCounterLimit: function showCounterLimit() {
      return this.hasCharacterCounter && (this.rawText.length === this.maxlength || this.isFocused);
    },
    helpTextExtraStyles: function helpTextExtraStyles() {
      if (this.showCounterLimit) {
        return {
          paddingRight: "".concat(this.counterLimitWidth, "px"),
          marginRight: '10px'
        };
      }
      return '';
    },
    showHelpTextContainer: function showHelpTextContainer() {
      return this.errorHelpText && this.invalid || this.helpText;
    }
  }),
  watch: {
    preText: {
      handler: function handler() {
        this.setText(this.preText);
      }
    },
    modelValue: function modelValue() {
      if (this.modelValue !== this.saveFormat) {
        this.setText(this.modelValue);
      }
    },
    rawText: function rawText(newValue, oldValue) {
      if (newValue !== oldValue && this.showCounterLimit) {
        this.updateCounterLimitWidth();
      }
    },
    isFocused: function isFocused(_isFocused) {
      if (_isFocused && this.showCounterLimit) {
        this.updateCounterLimitWidth();
      }
    },
    isErrorOccurred: function isErrorOccurred(value) {
      this.$emit('error', value);
    }
  },
  created: function created() {
    this.setText(this.preText || this.modelValue);
    if (this.allowedSymbolsRegex) {
      this.regexForValidation = new RegExp(this.allowedSymbolsRegex);
    }
  },
  methods: {
    onKeyPress: function onKeyPress(evt) {
      if (this.regexForValidation) {
        var charCode = evt.keyCode || evt.key.charCodeAt(0);
        var char = String.fromCharCode(charCode);
        if (!this.regexForValidation.test(char)) {
          evt.preventDefault();
          return;
        }
      }
      if (this.type !== 'number' && !this.enableCurrencyTemplate) return;
      this.validateNumber(evt);
    },
    validateNumber: function validateNumber(evt) {
      //fixes safari bug
      var charCode = evt.keyCode || evt.key.charCodeAt(0);
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== +this.decimalCharCode && charCode !== MINUS_CHAR_CODE) {
        evt.preventDefault();
        return;
      }
      if (charCode === +this.decimalCharCode && evt.target.value.indexOf(this.separator.decimal) !== -1) {
        evt.preventDefault();
      }
    },
    chooseFileInit: function chooseFileInit(event) {
      this.type === 'file' && this.$emit('chooseFileInit', event);
    },
    formatValue: function formatValue() {
      var _this$editedText;
      this.rawText = (_this$editedText = this.editedText) !== null && _this$editedText !== void 0 ? _this$editedText : '';
    },
    formatCurrency: function formatCurrency() {
      var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.rawText = _formatCurrency(value || this.saveFormat || '', this.locale);
    },
    setText: function setText(text) {
      this.rawText = text !== null && text !== void 0 ? text : '';
      if (this.enableCurrencyTemplate && this.type === 'text') {
        this.formatCurrency(text);
      }
    },
    onClearIconClick: function onClearIconClick() {
      this.setText('');
      this.emit();
    },
    emit: function emit() {
      if (!this.enabled) return;
      if (this.normalizeFn) {
        this.setText(this.normalizeFn(this.rawText));
      }
      if (this.isInputValid) {
        this.emitUpdate();
      }
    },
    emitUpdate: function emitUpdate() {
      this.$emit('update:modelValue', this.saveFormat);
      this.$emit('updated', this.saveFormat);
    },
    onFocus: function onFocus() {
      this.isFocused = true;
      this.formatValue();
      this.$emit('focus', this.saveFormat);
    },
    onBlur: function onBlur() {
      this.isFocused = false;
      if (this.enableCurrencyTemplate && this.type === 'text') {
        this.formatCurrency();
      }
      if (!this.isInputValid) {
        this.rawText = stripRecognisedHTMLOnly(this.rawText);
        this.emitUpdate();
      }
      this.$emit('blur', this.saveFormat);
    },
    onClick: function onClick(event) {
      this.chooseFileInit(event);
    },
    onEnter: function onEnter(event) {
      this.chooseFileInit(event);
      this.$emit('onEnter', this.saveFormat);
    },
    onEsc: function onEsc() {
      this.$emit('onEsc', this.saveFormat);
    },
    updateCounterLimitWidth: function updateCounterLimitWidth() {
      var _this = this;
      this.$nextTick(function () {
        var _this$$refs$counterLi;
        _this.counterLimitWidth = Math.max((_this$$refs$counterLi = _this.$refs.counterLimit) === null || _this$$refs$counterLi === void 0 ? void 0 : _this$$refs$counterLi.offsetWidth, 40);
      });
    }
  }
};