export default {
  name: 'AttributesList',
  description: 'Styled list of attributes',
  token: "<attributes-list :attributes-map='{atrKey1: ['option1', 'option2']}' />",
  props: {
    attributesMap: {
      required: true,
      type: Object,
      note: 'Set of items to be displayed'
    }
  },
  computed: {
    attributesFormatted: function attributesFormatted() {
      var _this = this;
      if (!Object.keys(this.attributesMap)) return [];
      var keys = Object.keys(this.attributesMap);
      var result = keys.map(function (key) {
        return {
          key: key,
          items: _this.attributesMap[key]
        };
      });
      return result;
    }
  }
};