import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "p-4 bg-gray-250 transition-all"
};
var _hoisted_2 = {
  class: "pb-2"
};
var _hoisted_3 = {
  class: "pb-4"
};
var _hoisted_4 = {
  class: "flex pb-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    truncate: false,
    title: _ctx.$trans($props.metadata.title)
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    truncate: false,
    title: _ctx.$trans($props.metadata.description)
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.metadata.parameters, function (parameter, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "mr-8 max-w-250px w-full"
    }, [parameter.type === $data.TYPES.multiSelect ? (_openBlock(), _createBlock(_component_risma_select, {
      key: 0,
      title: _ctx.$trans(parameter.description),
      options: parameter.options,
      "selected-options": $options.selectedOptions[index],
      "label-key": "label",
      "enable-select-all": true,
      "un-sorted": true,
      "track-by": "value",
      placeholder: $data.translate.select,
      onSelected: function onSelected($event) {
        return $options.handleSelect($event, index);
      }
    }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "onSelected"])) : _createCommentVNode("v-if", true), parameter.type === $data.TYPES.singleSelect ? (_openBlock(), _createBlock(_component_single_select, {
      key: 1,
      title: _ctx.$trans(parameter.description),
      "model-value": $options.selectedOptions[index],
      "required-text": $data.translate.pleaseFillOutThisField,
      "un-sorted": true,
      options: parameter.options,
      placeholder: $data.translate.select,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.updateData($event, index);
      }
    }, null, 8 /* PROPS */, ["title", "model-value", "required-text", "options", "placeholder", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))])])]);
}