import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_router_view = _resolveComponent("router-view");
  var _component_vertical_menu_wrapper = _resolveComponent("vertical-menu-wrapper");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_vertical_menu_wrapper, null, {
    menu: _withCtx(function () {
      return [_createVNode(_component_vertical_menu, {
        items: $options.menuItems
      }, null, 8 /* PROPS */, ["items"])];
    }),
    content: _withCtx(function () {
      return [_createVNode(_component_router_view)];
    }),
    _: 1 /* STABLE */
  })]);
}