import i18n from '@/i18n';
export var getTranslate = {
  ControlFilters: function ControlFilters() {
    return {
      mainFilters: i18n.t('Main filters'),
      deadline: i18n.t('Deadline'),
      noFilter: i18n.t('No filter'),
      organisations: i18n.t('Organisations'),
      noFiltersApplied: i18n.t('No filters applied'),
      motherControls: i18n.t('Mother controls'),
      tags: i18n.t('Tags'),
      responsible: i18n.t('Responsible'),
      search: i18n.t('Search'),
      searchForNoOrTitle: i18n.t('Search for No or Title'),
      showDeactivatedControls: i18n.t('Show deactivated controls'),
      groupAndSort: i18n.t('Group and sort'),
      groupBy: i18n.t('Group by'),
      sortBy: i18n.t('Sort by'),
      resetOptions: i18n.t('Reset options'),
      processes: i18n.t('Processes'),
      appliedFilters: i18n.t('Applied filters'),
      activityType: i18n.t('Activity type'),
      framework: i18n.t('Framework'),
      includeUnderlyingFrameworks: i18n.t('Incl. underlying framework')
    };
  },
  ControlRelationsSectionDetails: function ControlRelationsSectionDetails() {
    return {
      areYouSure: i18n.t('Are you sure?'),
      confirm: i18n.t('Confirm'),
      cancel: i18n.t('Cancel'),
      activityType: i18n.t('Activity type'),
      noActivityType: i18n.t('No activity type'),
      motherControl: i18n.t('Mother Control'),
      choose: i18n.t('Choose'),
      showCommentsPreformatted: i18n.t('Show comments pre-formatted'),
      youAreAboutToChangeThisControlToADaughter: i18n.t('You are about to change this control to a daughter control. The control will be renumbered according to the mother control. Please confirm.'),
      toBeAbleToChangeActivityType: i18n.t('To be able to change activity type please delete all repeatable fields')
    };
  },
  ControlsList: function ControlsList() {
    return {
      completion: i18n.t('Completion'),
      review: i18n.t('Review'),
      signoffPeriodPending: i18n.t('Signoff period pending')
    };
  }
};