var insetTypes = {
  DEFAULT: 'default',
  ZERO: 'zero'
};
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'OverlayDeleteButton',
  introduction: 'Icon that appears on hover to delete item',
  token: '<overlay-delete-icon></overlay-delete-icon>',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: 'x'
    },
    iconVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    iconSize: {
      type: [String, Number],
      required: false,
      default: 12
    },
    iconColorClass: {
      type: String,
      required: false,
      default: 'text-rm-signal-grey-dark'
    },
    circleSize: {
      type: [String, Number],
      required: false,
      default: 20
    },
    inset: {
      type: String,
      required: false,
      default: insetTypes.DEFAULT
    }
  },
  emits: ['onDeleteIconClick'],
  data: function data() {
    return {
      deleteIconHover: false,
      insetTypes: insetTypes
    };
  }
};