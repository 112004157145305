import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "py-4 px-5"
};
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  var _component_internal_section_overview = _resolveComponent("internal-section-overview");
  var _component_expansion_panel = _resolveComponent("expansion-panel");
  return _openBlock(), _createBlock(_component_expansion_panel, {
    class: "mt-4"
  }, {
    header: _withCtx(function () {
      var _$props$section$servi;
      return [_createVNode(_component_risma_title, {
        title: ((_$props$section$servi = $props.section.serviceProviders[0]) === null || _$props$section$servi === void 0 ? void 0 : _$props$section$servi.label) || '',
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])];
    }),
    "header-menu": _withCtx(function () {
      return [_createVNode(_component_toggle_menu, {
        position: "absolute",
        "menu-style": "minimal",
        options: $options.toggleMenuOptions
      }, null, 8 /* PROPS */, ["options"])];
    }),
    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [$data.selectedView === $data.VIEWS.overview ? (_openBlock(), _createBlock(_component_internal_section_overview, {
        key: 0,
        "selected-options": $props.section,
        options: $props.options,
        onUpdate: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('update', $event);
        })
      }, null, 8 /* PROPS */, ["selected-options", "options"])) : (_openBlock(), _createElementBlock("div", _hoisted_2))])];
    }),
    _: 1 /* STABLE */
  });
}