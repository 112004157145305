function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { getTranslate } from './translate';
import { addChildToNode } from '@/api/compliance/tree';
import { getAllControllers as _getAllControllers } from '@/api/compliance/informationAssets';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import HelpToolTip from '@/components/Molecules/HelpToolTip';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import { getDPOLabel } from '@/utils/Compliance/complianceProject';
export default {
  name: 'ComplianceCreationModal',
  introduction: 'ComplianceCreationModal multiselect popup',
  token: '<compliance-creation-modal />',
  components: {
    Modal: Modal,
    InputField: InputField,
    Notification: Notification,
    RismaSelect: RismaSelect,
    HelpToolTip: HelpToolTip,
    FrameworksSelector: FrameworksSelector
  },
  props: {
    project: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    parentId: {
      type: [Number, String],
      required: false,
      default: null
    },
    nodeTitle: {
      type: String,
      required: false,
      default: ''
    },
    createParams: {
      type: Object,
      required: false,
      default: null
    },
    dpoEnabled: {
      type: Boolean,
      required: true
    },
    preselectedFrameworks: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['dismiss', 'create'],
  data: function data() {
    return {
      title: '',
      errorMessage: '',
      titleError: '',
      dpoName: '',
      controllersOptions: [],
      selectedControllers: [],
      selectedFrameworks: [],
      isLoading: false,
      translate: getTranslate['ComplianceCreationModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, {
    currentUser: 'currentUser'
  })), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), {}, {
    projectId: function projectId() {
      return this.project.id;
    }
  }, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    dpoLabel: function dpoLabel() {
      return getDPOLabel();
    },
    isDPOEnabled: function isDPOEnabled() {
      return this.dpoEnabled && !this.createParams.copyFrom;
    },
    isFrameworkEnabled: function isFrameworkEnabled() {
      return !this.createParams.copyFrom;
    },
    dataControllersEnabled: function dataControllersEnabled() {
      return this.getSettingValue('feature.enable_data_controllers') && !this.createParams.copyFrom;
    }
  }),
  beforeMount: function beforeMount() {
    var _this$preselectedFram,
      _this = this;
    this.title = this.nodeTitle || '';
    this.dataControllersEnabled && this.getAllControllers();
    var preselectedIds = (_this$preselectedFram = this.preselectedFrameworks) !== null && _this$preselectedFram !== void 0 && _this$preselectedFram.length ? this.preselectedFrameworks : this.project.solutionIds;
    preselectedIds === null || preselectedIds === void 0 || preselectedIds.forEach(function (id) {
      var framework = _this.frameworks.find(function (framework) {
        return framework.id === id;
      });
      if (!framework) return;
      _this.selectedFrameworks.push(framework);
    });
  },
  methods: {
    getAllControllers: function getAllControllers() {
      var _this2 = this;
      return _getAllControllers().then(function (_ref) {
        var list = _ref.list;
        _this2.controllersOptions = list.map(function (controller) {
          return {
            id: controller.id,
            label: controller.name
          };
        });
      });
    },
    createNode: function createNode(params) {
      var _this3 = this;
      this.isLoading = true;
      return addChildToNode(this.projectId, params).then(function (response) {
        if (typeof response.headers !== 'undefined') {
          _this3.errorMessage = _this3.translate.errorDuringCreation;
          return;
        }
        _this3.$emit('create', response);
      }).catch(function (response) {
        var _response$response;
        _this3.errorMessage = "".concat(_this3.translate.couldNotCreateNode, " - timeout? (").concat(response === null || response === void 0 || (_response$response = response.response) === null || _response$response === void 0 ? void 0 : _response$response.error, ")");
      }).finally(function () {
        return _this3.isLoading = false;
      });
    },
    handleOnSubmit: function handleOnSubmit() {
      var data = _objectSpread({
        title: this.title,
        solutionIds: this.selectedFrameworks.map(function (item) {
          return item.id;
        })
      }, this.createParams);
      if (this.parentId && this.parentId !== -1) {
        data.appendTo = +this.parentId;
        data.responsibleUserIds = [this.currentUser.id];
      }
      if (!this.handleValidation(data)) {
        return;
      }
      if (this.dpoName) {
        data.dpoName = this.dpoName;
      }
      if (this.selectedControllers.length) {
        data.links = _defineProperty({}, 'compliance_ia_controller', this.selectedControllers.map(function (item) {
          return item.id;
        }));
      }
      return this.createNode(data);
    },
    handleValidation: function handleValidation(data) {
      this.titleError = '';
      if (!data.title) {
        this.titleError = this.translate.titleCannotBeEmpty;
      }
      return !this.titleError;
    }
  }
};