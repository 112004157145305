import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "create-initiative-modal"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_text_box = _resolveComponent("text-box");
  var _component_frameworks_selector = _resolveComponent("frameworks-selector");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_modal, {
    header: $data.translate.createAction,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.dismiss,
    "accept-on-enter": !$data.isRismaSelectOpen,
    "dismiss-on-click-outside": false,
    onDismiss: _cache[11] || (_cache[11] = function ($event) {
      return _ctx.$emit('dismiss');
    }),
    onAccept: $options.handleSelection
  }, {
    body: _withCtx(function () {
      var _$data$errors, _$data$errors2;
      return [_createVNode(_component_input_field, {
        title: $data.translate.title,
        "pre-text": $data.initiativeItem.title,
        enabled: !$props.preset.title,
        "focus-on-mount": true,
        placeholder: $data.translate.enterTitle,
        type: "text",
        class: "mb-3",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.initiativeItem.title = $event;
        })
      }, null, 8 /* PROPS */, ["title", "pre-text", "enabled", "placeholder"]), !$props.preset.parentId ? (_openBlock(), _createBlock(_component_single_select, {
        key: 0,
        modelValue: $data.initiativeItem.parent,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.initiativeItem.parent = $event;
        }),
        title: $data.translate.parent,
        "un-sorted": true,
        "required-text": ((_$data$errors = $data.errors) === null || _$data$errors === void 0 ? void 0 : _$data$errors.parent) || '',
        options: $options.initiativesOptions,
        "with-search": true,
        class: "mb-3"
      }, null, 8 /* PROPS */, ["modelValue", "title", "required-text", "options"])) : _createCommentVNode("v-if", true), _createVNode(_component_activity_type_selector, {
        modelValue: $data.initiativeItem.activityTypeId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.initiativeItem.activityTypeId = $event;
        }),
        "activity-types": _ctx.activityTypes,
        label: $data.translate.activityType,
        class: "mb-3"
      }, null, 8 /* PROPS */, ["modelValue", "activity-types", "label"]), _createVNode(_component_risma_select, {
        title: $data.translate.responsible,
        options: _ctx.users,
        "error-message": ((_$data$errors2 = $data.errors) === null || _$data$errors2 === void 0 ? void 0 : _$data$errors2.responsible) || '',
        "selected-options": $data.initiativeItem.responsible,
        placeholder: $data.translate.users,
        "label-key": "display_name",
        class: "mb-3",
        onSelected: _cache[3] || (_cache[3] = function ($event) {
          return $data.initiativeItem.responsible = $event;
        }),
        onOpen: _cache[4] || (_cache[4] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[5] || (_cache[5] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["title", "options", "error-message", "selected-options", "placeholder"]), _createVNode(_component_text_box, {
        text: $data.initiativeItem.description,
        rows: 2,
        placeholder: $data.translate.description,
        label: $data.translate.description,
        class: "w-full mb-3",
        onUpdated: _cache[6] || (_cache[6] = function ($event) {
          return $data.initiativeItem.description = $event;
        })
      }, null, 8 /* PROPS */, ["text", "placeholder", "label"]), _createVNode(_component_text_box, {
        text: $data.initiativeItem.target,
        rows: 2,
        placeholder: $data.translate.target,
        label: $data.translate.target,
        class: "w-full mb-3",
        onUpdated: _cache[7] || (_cache[7] = function ($event) {
          return $data.initiativeItem.target = $event;
        })
      }, null, 8 /* PROPS */, ["text", "placeholder", "label"]), _createVNode(_component_frameworks_selector, {
        "selected-options": $data.initiativeItem.selectedFrameworks || [],
        onSelected: _cache[8] || (_cache[8] = function ($event) {
          return $data.initiativeItem.selectedFrameworks = $event;
        }),
        onOpen: _cache[9] || (_cache[9] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[10] || (_cache[10] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["selected-options"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "accept-on-enter", "onAccept"])]);
}