function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { createRegexpForFrameworks, createRegexpForUnderlyingFrameworks } from '@/utils/regex';
import i18n from '@/i18n';
var FILTER_FIELDS = {
  FRAMEWORKS: 'frameworks'
};
export var ADDITIONAL_FILTER_ACTIONS = _defineProperty({}, FILTER_FIELDS.FRAMEWORKS, {
  uncheck: {
    event: createRegexpForFrameworks,
    delta: createRegexpForFrameworks('').split('|').length - 1
  },
  check: {
    event: createRegexpForUnderlyingFrameworks,
    delta: createRegexpForUnderlyingFrameworks('').split('|').length - 1
  }
});
export var getAdditionalFilterOptions = function getAdditionalFilterOptions() {
  return _defineProperty({}, FILTER_FIELDS.FRAMEWORKS, {
    label: i18n.t('Incl. underlying framework'),
    value: false,
    actions: ADDITIONAL_FILTER_ACTIONS[FILTER_FIELDS.FRAMEWORKS]
  });
};
export var calculateFilterDelta = function calculateFilterDelta(filterKey, value) {
  var actions = ADDITIONAL_FILTER_ACTIONS[filterKey];
  if (!actions) return;
  return value ? actions.check.delta : actions.uncheck.delta;
};