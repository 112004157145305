var _excluded = ["gaps", "gapTypeIds"];
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
import { searchGaps } from '@/api/compliance/reports/gap';
import { getTreeByProjectId } from '@/api/compliance/tree';
import { getRiskProjects } from '@/api/risk/risk';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { filterAsset, getOrganisationsFromIds, getUsersFromIds } from '@/utils/format/objectsFromIds';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { prepareDatasetToExport } from '@/utils/export/prepareToExport';
import { printTable } from '@/utils/export/print';
import { exportToXlsx } from '@/utils/export/excel';
import { mapToCommaList } from '@/utils/format/mapTo';
import * as Utils from '@/utils/Utils';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaPagesize from '@/components/Atoms/RismaDatatable/RismaPagesize';
import Pagination from '@/components/Atoms/Pagination';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { GapResults, NoSearchResults, ReportSearchForm } from '../index';
import GapAnalysis from '@/models/compliance/GapAnalysis';
import { gapReportColumns } from '@/constants/compliance/GapReportDatatableColumns';
import { ReportTypes, ReportLogTypes } from '@/constants/reports';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'GapReport',
  components: {
    NoSearchResults: NoSearchResults,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    GapResults: GapResults,
    ReportSearchForm: ReportSearchForm,
    RismaDatatable: RismaDatatable,
    PreviewModalLink: PreviewModalLink,
    TrafficLight: TrafficLight,
    Pagination: Pagination,
    RismaPagesize: RismaPagesize,
    FeatherIcon: FeatherIcon,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    SaveReport: SaveReport,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  mixins: [RismaDatatableDefaultMixin, RaciTitleMixin, ProcessLibraryTitleMixin, SaveReportMixin],
  props: {
    projectId: {
      type: [Number, String],
      required: true,
      note: 'Id of current project'
    },
    savedReportOptions: {
      type: Object,
      default: null
    }
  },
  setup: function setup(props) {
    var localStorageKey = "gap_report_filters_".concat(props.projectId);
    return {
      reportOptions: getLocalStorageComputed(localStorageKey, {}, true)
    };
  },
  data: function data() {
    return {
      confirmCallback: null,
      confirmMessage: '',
      filenameToExport: 'RISMAbusiness',
      printHeadTitle: 'RISMAcompliance',
      datasetToExport: [],
      pageStart: 0,
      pageSize: 25,
      totalOutput: 0,
      gapNumberToShowConfirmModal: 1000,
      pagingOptions: [10, 25, 50, 100],
      sorting: {
        sortField: '',
        sortDir: ''
      },
      output: [],
      dataset: [],
      loading: false,
      selectedGAPS: [],
      selectedUsers: [],
      selectedNodes: [],
      selectedTags: [],
      selectedOrganisations: [],
      selectedAttributes: [],
      extendedView: true,
      noResults: false,
      columnsMeta: [{
        targets: ['gapSchemaTitle', 'processOwner', 'rpiGross', 'rpiNet'],
        visible: false
      }, {
        targets: ['domain', 'tags'],
        width: '200px'
      }, {
        targets: ['attributes'],
        width: '400px'
      }, {
        targets: ['analysis', 'analysisControlsInitiatives', 'linkedControls', 'linkedActions', 'processLibrary', 'linkedRisks'],
        width: '600px'
      }, {
        targets: ['nodeTitle', 'rootNodeTitle', 'subHeadline', 'requirements', 'organisation'],
        width: '300px'
      }],
      alwaysVisibleExportColumns: ['id', 'nodeId', 'organisation', 'requirements', 'articleId'],
      tree: null,
      project: {},
      searchArgs: {},
      searchError: '',
      riskProjects: [],
      slotVForFields: ['linkedControls', 'linkedActions', 'processLibrary', 'linkedRisks'],
      hasBackendSorting: true,
      showDefaultPagination: false,
      disabledFilterByColumns: ['rootNodeTitle', 'analysis', 'RiskAssessment', 'analysisControlsInitiatives', 'requirements', 'articleId'],
      translate: getTranslate['gap'](),
      includeChildNodes: false,
      gapTypesSelected: [],
      gapTypeIdSelected: null,
      reportType: ReportTypes.COMPLIANCE_GAP,
      initialFilters: {
        nodes: [],
        includeChildNodes: false,
        scoringIncluded: true
      },
      isExpanded: false,
      updated: false,
      stateLocal: "gap_report_table_formatting_".concat(this.projectId)
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useTagsStore, ['tags'])), mapState(useTrafficLights, {
    lights: 'gapTrafficLights'
  })), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), {}, {
    hasOverallFramework: function hasOverallFramework() {
      return this.getSettingValue('feature.overall.extended_framework');
    },
    columns: function columns() {
      var columns = {
        nodeId: this.translate.process + ' ' + this.translate.id,
        domain: this.translate.domain,
        nodeTitle: this.translate.process,
        rootNodeTitle: this.translate.parentProcess,
        trafficLight: this.translate.trafficLight,
        headline: this.translate.headline,
        subHeadline: this.translate.subHeadline,
        frameworks: this.translate.frameworks,
        analysis: this.translate.analysis,
        attributes: this.translate.attributes,
        linkedControls: this.translate.linkedControls,
        linkedActions: this.translate.linkedActions,
        processLibrary: this.processLibraryTitle,
        linkedRisks: this.translate.linkedRisks,
        id: this.translate.gapId,
        gapSchemaTitle: this.translate.gapSchemaName,
        organisation: this.translate.organisation,
        processOwner: this.responsibleTitle(true),
        requirements: this.translate.requirement,
        tags: this.translate.tags,
        articleId: this.translate.article + ' ID'
      };
      if (this.project.enableGapRiskModule) {
        columns.analysisControlsInitiatives = this.translate.riskAssessment, columns.impactBefore = this.translate.impactBefore;
        columns.likelihoodBefore = this.translate.likelihoodBefore;
        columns.impactAfter = this.translate.impactAfter;
        columns.likelihoodAfter = this.translate.likelihoodAfter;
        columns.rpiBefore = this.translate.rpi + ' ' + this.translate.before;
        columns.rpiAfter = this.translate.rpi + ' ' + this.translate.after;
      }
      if (!this.hasOverallFramework) delete columns.frameworks;
      if (this.showGapArticlesTypes) columns.gapTypes = this.translate.gapTypes;
      return columns;
    },
    columnsOrder: function columnsOrder() {
      var gapRiskModuleColumns = this.project.enableGapRiskModule ? gapReportColumns.gapRiskModuleColumns : [];
      return [].concat(_toConsumableArray(gapReportColumns.baseColumns), _toConsumableArray(gapRiskModuleColumns), _toConsumableArray(gapReportColumns.extraColumns));
    },
    filters: function filters() {
      var _this$selectedAttribu;
      var filters = {};
      if (this.selectedGAPS.length > 0) {
        var ids = this.selectedGAPS.map(function (sGAP) {
          return sGAP.number;
        });
        filters['traffic_light'] = ids;
      }
      if (this.selectedNodes.length > 0) {
        filters['id'] = this.selectedNodes;
      }
      if (this.selectedUsers.length > 0) {
        filters['responsible'] = this.selectedUsers;
      }
      if ((_this$selectedAttribu = this.selectedAttributes) !== null && _this$selectedAttribu !== void 0 && _this$selectedAttribu.length) {
        filters['attributeLabel'] = this.selectedAttributes;
      }
      if (this.selectedOrganisations.length > 0) {
        filters['organisationIds'] = this.selectedOrganisations;
      }
      if (this.selectedTags.length) {
        filters['tagIds'] = this.selectedTags;
      }
      if (this.includeChildNodes) {
        filters['includeUnderlyingNodes'] = true;
      }
      if (this.showGapArticlesTypes) {
        filters['gapTypeIds'] = this.gapTypeIds;
      }
      return filters;
    },
    gapTypeIds: function gapTypeIds() {
      return this.enabledGapTypeMultiselect ? this.gapTypesSelected.map(function (type) {
        return type.id;
      }) : [this.gapTypeIdSelected];
    },
    confirmExportMessage: function confirmExportMessage() {
      if (this.totalOutput < this.gapNumberToShowConfirmModal) return '';
      return "".concat(this.translate.areYouSureYouWantToExport, " ").concat(this.totalOutput, " ").concat(this.translate.rowsToExcel, "?");
    },
    confirmPrintMessage: function confirmPrintMessage() {
      if (this.totalOutput < this.gapNumberToShowConfirmModal) return '';
      return "".concat(this.translate.areYouSureYouWantToPrint, " ").concat(this.totalOutput, " ").concat(this.translate.rows, "?");
    },
    enabledGapTypeMultiselect: function enabledGapTypeMultiselect() {
      return this.getSettingValue('feature.gap_type_multiselect');
    },
    showGapArticlesTypes: function showGapArticlesTypes() {
      return !!this.project.showGapArticleTypes;
    },
    gapTypesOptions: function gapTypesOptions() {
      var _this$project$gapType;
      var types = (_this$project$gapType = this.project.gapTypes) !== null && _this$project$gapType !== void 0 ? _this$project$gapType : [];
      return types.map(function (item) {
        return {
          value: item.id,
          label: item.label,
          id: item.id
        };
      });
    }
  }),
  watch: {
    savedReportOptions: function savedReportOptions() {
      if (this.savedReportOptions) {
        this.setAndLoadSavedReport(this.savedReportOptions);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    Promise.all([getTreeByProjectId(this.projectId), this.complianceProjectById(this.projectId), getRiskProjects()]).then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 3),
        tree = _ref2[0],
        project = _ref2[1],
        riskProjects = _ref2[2];
      _this.tree = tree;
      _this.project = project;
      _this.riskProjects = riskProjects.list;
    }).then(this.setInitialState);
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useComplianceProjectsStore, {
    complianceProjectById: 'getProjectById'
  })), {}, {
    setInitialState: function setInitialState() {
      if (this.savedReportOptions) {
        this.setAndLoadSavedReport(this.savedReportOptions);
      } else {
        this.setInitialValues(this.reportOptions);
      }
    },
    createDataForTable: function createDataForTable(output) {
      var _this2 = this;
      var result = [];
      output.forEach(function (gapRow) {
        var organisationsName = gapRow.nodeOrganisations.map(function (iOrg) {
          return iOrg.visible_name;
        });
        var responsibleUsers = gapRow.nodeResponsible.map(function (user) {
          return user.display_name;
        });
        var row = {
          nodeId: {
            field: gapRow.nodeId
          },
          domain: {
            field: gapRow.domain || ''
          },
          nodeTitle: {
            field: {
              activity: getFieldsFromObjectForPreview(gapRow),
              title: gapRow.nodeTitle
            },
            fieldText: gapRow.nodeTitle
          },
          rootNodeTitle: {
            field: gapRow.rootNodeTitle
          },
          trafficLight: {
            field: DatatableRenderer.prepareGapColorField(gapRow.trafficLight),
            fieldText: gapRow.trafficLight
          },
          headline: {
            field: gapRow.headline
          },
          subHeadline: {
            field: gapRow.subHeadline
          },
          analysis: {
            field: gapRow.analysis
          },
          attributes: {
            attributes: gapRow.attributes,
            field: _this2.getAttributesField(gapRow.attributes)
          },
          linkedControls: {
            field: gapRow.linkedControls.filter(function (link) {
              return link;
            }).map(function (link) {
              return {
                activity: _objectSpread(_objectSpread({}, getFieldsFromObjectForPreview(link)), {}, {
                  trafficLight: DatatableRenderer.prepareTrafficLightField(link.trafficLight)
                }),
                lineThrough: !link.active,
                title: "".concat(link.cpath, " ").concat(link.title)
              };
            }),
            fieldText: gapRow.linkedControls.filter(function (item) {
              return item;
            }).map(function (link) {
              return "".concat(link.cpath, " ").concat(link.title);
            }).join(', '),
            id: gapRow.nodeId
          },
          linkedActions: {
            field: gapRow.linkedActions.filter(function (link) {
              return link;
            }).map(function (link) {
              return {
                activity: _objectSpread(_objectSpread({}, getFieldsFromObjectForPreview(link)), {}, {
                  trafficLight: DatatableRenderer.prepareTrafficLightField(link.trafficLight)
                }),
                showCheckmark: link.closed,
                title: "".concat(link.ipath, " ").concat(link.title)
              };
            }),
            fieldText: gapRow.linkedActions.filter(function (item) {
              return item;
            }).map(function (link) {
              return "".concat(link.ipath, " ").concat(link.title);
            }).join(', '),
            id: gapRow.nodeId
          },
          processLibrary: {
            field: gapRow.processLibrary.filter(function (item) {
              return item;
            }).map(function (link) {
              return {
                activity: getFieldsFromObjectForPreview(link),
                title: link.title
              };
            }),
            fieldText: gapRow.processLibrary.filter(function (item) {
              return item;
            }).map(function (link) {
              return link.title;
            }).join(', '),
            id: gapRow.nodeId
          },
          linkedRisks: {
            field: gapRow.linkedRisks.filter(function (link) {
              var _link$project;
              return link === null || link === void 0 || (_link$project = link.project) === null || _link$project === void 0 ? void 0 : _link$project.enabled;
            }).map(function (link) {
              return {
                activity: getFieldsFromObjectForPreview(link),
                lineThrough: link.closed,
                title: "".concat(link.rno, " ").concat(link.title, " ").concat(_this2.getRiskValues(link))
              };
            }),
            fieldText: gapRow.linkedRisks.filter(function (item) {
              return item;
            }).map(function (link) {
              return "".concat(link.rno, " ").concat(link.title);
            }).join(', '),
            id: gapRow.nodeId
          },
          id: {
            field: gapRow.id
          },
          articleId: {
            field: gapRow.articleId
          },
          gapSchemaTitle: {
            field: gapRow.gapSchemaTitle
          },
          organisation: {
            field: organisationsName.join('; ')
          },
          processOwner: {
            field: responsibleUsers.join(', ')
          },
          requirements: {
            field: gapRow.requirement
          },
          tags: {
            field: _this2.getTagsNames(_this2.tags, gapRow.tags)
          }
        };
        if (_this2.hasOverallFramework) {
          row.frameworks = {
            field: Utils.findPropsByIds(gapRow.frameworks, _this2.frameworks, 'displayName')
          };
        }
        if (_this2.project.enableGapRiskModule) {
          row.analysisControlsInitiatives = {
            field: gapRow.analysisControlsInitiatives
          };
          row.likelihoodBefore = {
            field: gapRow.likelihoodGross
          };
          row.impactBefore = {
            field: gapRow.impactGross
          };
          row.likelihoodAfter = {
            field: gapRow.likelihoodNet
          };
          row.impactAfter = {
            field: gapRow.impactNet
          };
          row.rpiAfter = {
            field: gapRow.rpiNet
          };
          row.rpiBefore = {
            field: gapRow.rpiGross
          };
        }
        if (_this2.showGapArticlesTypes) {
          row.gapTypes = {
            field: mapToCommaList(filterAsset(_this2.gapTypesOptions, gapRow.gapTypeIds || []), 'label')
          };
        }
        result.push(row);
      });
      return result;
    },
    getRiskValues: function getRiskValues(risk) {
      if (this.getSettingValue('feature.show_risk_links_with_levels') !== 1) {
        return '';
      }
      return DatatableRenderer.getRiskValues(risk, this.riskProjects);
    },
    gapLabels: function gapLabels(light) {
      return this.$trans(light.label);
    },
    onReportSearch: function onReportSearch(params) {
      this.pageStart = 0;
      this.handleDoSearch(params, true, true);
    },
    handleDoSearch: function handleDoSearch(_ref3) {
      var _this3 = this;
      var _ref3$organisations = _ref3.organisations,
        organisations = _ref3$organisations === void 0 ? [] : _ref3$organisations,
        _ref3$users = _ref3.users,
        users = _ref3$users === void 0 ? [] : _ref3$users,
        _ref3$nodes = _ref3.nodes,
        nodes = _ref3$nodes === void 0 ? [] : _ref3$nodes,
        _ref3$tags = _ref3.tags,
        tags = _ref3$tags === void 0 ? [] : _ref3$tags,
        _ref3$attributes = _ref3.attributes,
        attributes = _ref3$attributes === void 0 ? [] : _ref3$attributes,
        _ref3$includeChildNod = _ref3.includeChildNodes,
        includeChildNodes = _ref3$includeChildNod === void 0 ? true : _ref3$includeChildNod;
      var resetQuery = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var log = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      this.searchArgs = {
        organisations: organisations,
        users: users,
        nodes: nodes,
        tags: tags,
        attributes: attributes,
        includeChildNodes: includeChildNodes
      };
      this.output = [];
      this.datasetSlice = [];
      this.selectedOrganisations = organisations;
      this.selectedUsers = users;
      this.selectedAttributes = attributes;
      this.selectedNodes = nodes;
      this.selectedTags = tags;
      this.includeChildNodes = includeChildNodes;
      this.noResults = false;
      this.searchError = '';
      this.loading = true;
      resetQuery && this.resetSavedReportQuery();
      this.updateReportOptions();
      var promise = searchGaps(this.projectId, this.filters, this.pageSize, this.pageStart, this.sorting, log);
      return promise.then(function (response) {
        _this3.totalOutput = response.total;
        _this3.output = _this3.prepareOutput(response);
        _this3.dataset = _this3.createDataForTable(_this3.output);
        _this3.enhanceDataset(_this3.dataset, _this3.columns);
        if (_this3.output.length === 0) {
          _this3.noResults = true;
        }
      }).catch(function () {
        _this3.noResults = true;
        _this3.searchError = _this3.translate.errorOccurredDuringSearchPleaseDoublecheck;
      }).finally(function () {
        _this3.$nextTick(function () {
          return _this3.loading = false;
        });
      });
    },
    updateReportOptions: function updateReportOptions() {
      this.reportOptions = _objectSpread(_objectSpread({}, this.searchArgs), {}, {
        gaps: this.selectedGAPS.map(function (item) {
          return item.number;
        }),
        gapTypeIds: this.gapTypeIds
      });
    },
    getUnlimitedGaps: function getUnlimitedGaps(logType) {
      return searchGaps(this.projectId, this.filters, null, 0, this.sorting, logType);
    },
    onConfirm: function onConfirm() {
      this.confirmCallback();
      this.confirmCallback = null;
    },
    onExcelExportClick: function onExcelExportClick(columnsToExport) {
      var _this4 = this;
      var excelExportFunc = function excelExportFunc() {
        return _this4.prepareDataToExport(columnsToExport, ReportLogTypes.EXCEL).then(function () {
          exportToXlsx(_this4.datasetToExport, columnsToExport, _this4.filenameToExport);
        });
      };
      if (this.confirmExportMessage) {
        this.confirmCallback = excelExportFunc;
        this.confirmMessage = this.confirmExportMessage;
        this.$confirm('', this.confirmMessage, function (res) {
          return res && _this4.onConfirm();
        });
        return;
      }
      return excelExportFunc();
    },
    onPrintClick: function onPrintClick(columnsToExport) {
      var _this5 = this;
      var printFunc = function printFunc() {
        return _this5.prepareDataToExport(columnsToExport, ReportLogTypes.PRINT).then(function () {
          printTable(_this5.datasetToExport, columnsToExport, _this5.printHeadTitle);
        });
      };
      if (this.confirmPrintMessage) {
        this.confirmCallback = printFunc;
        this.confirmMessage = this.confirmPrintMessage;
        this.$confirm('', this.confirmMessage, function (res) {
          return res && _this5.onConfirm();
        });
        return;
      }
      return printFunc();
    },
    prepareDataToExport: function prepareDataToExport(columnsToExport, logType) {
      var _this6 = this;
      return this.getUnlimitedGaps(logType).then(function (response) {
        var exportOutput = _this6.prepareOutput(response);
        var exportDataset = _this6.createDataForTable(exportOutput);
        _this6.datasetToExport = prepareDatasetToExport(exportDataset, columnsToExport, true);
      });
    },
    prepareOutput: function prepareOutput(gapRows) {
      var _this7 = this;
      var output = [];
      var allNodes = gapRows.meta.nodes;
      var node = {
        id: null
      };
      gapRows.list && gapRows.list.forEach(function (gapData) {
        var _gapArticle$domain;
        if (!node.id || node.id !== gapData.nodeId) {
          node = allNodes.find(function (n) {
            return n.id === gapData.nodeId;
          });
        }
        var gapSchema = gapRows.meta.gapSchemas[node.gapSchemaId];
        var gapArticle = gapSchema && gapSchema.articles.find(function (art) {
          return art.id === gapData.articleId;
        });
        var gap = _this7.getGapAnalysis(gapData.articleId, gapData);
        if (_this7.filters['traffic_light'] && !_this7.filters['traffic_light'].includes(gap.analysis_rag)) {
          return;
        }
        var gapRow = {
          nodeId: node.id,
          domain: gapArticle === null || gapArticle === void 0 || (_gapArticle$domain = gapArticle.domain) === null || _gapArticle$domain === void 0 ? void 0 : _gapArticle$domain.label,
          trafficLight: gap.analysis_rag,
          nodeTitle: node.title,
          rootNodeTitle: _this7.getRootNodeTitle(node),
          nodeUrl: node.url,
          nodeOrganisations: getOrganisationsFromIds(node.organisationIds, _this7.organisations),
          nodeProjectId: node.compliance_projects_id,
          objType: gap.objType,
          nodeResponsible: getUsersFromIds(node.responsibleUserIds, _this7.users),
          gapSchemaTitle: gapSchema.title,
          id: gap.id,
          headline: gapArticle.article,
          subHeadline: gapArticle.articleTitle,
          analysis: gap.analysis,
          attributes: gapArticle.attributes,
          analysisControlsInitiatives: gap.analysisControlsInitiatives,
          linkedControls: gap.links.control || [],
          linkedActions: gap.links.execution || [],
          processLibrary: gap.links.compliance_process_tree_node || [],
          linkedRisks: gap.links.risk || [],
          requirement: gapArticle.requirement,
          tags: node.tagIds,
          likelihoodNet: gap.likelihood_net,
          likelihoodGross: gap.likelihood_gross,
          impactNet: gap.impact_net,
          impactGross: gap.impact_gross,
          rpiNet: gap.rpi_net,
          rpiGross: gap.rpi_gross,
          articleId: gap.articleId,
          gapTypeIds: gap.types,
          frameworks: gapArticle.solutionIds || []
        };
        output.push(gapRow);
      });
      return output;
    },
    getGapAnalysis: function getGapAnalysis(articleId, gapData) {
      return new GapAnalysis(articleId, gapData);
    },
    pageChange: function pageChange(e) {
      this.pageLength = this.pageSize;
      this.pageStart = e.mounted ? 0 : e.start;
      if (e.mounted || this.loading) return;
      this.refreshTable(false).then(function () {
        document.body.scrollTop = document.body.scrollHeight;
      });
    },
    backendSortRequest: function backendSortRequest(_ref4) {
      var index = _ref4.index,
        dir = _ref4.dir;
      this.sorting = {
        sortField: index,
        sortDir: dir
      };
      this.refreshTable().then(function () {
        document.body.scrollTop = document.body.scrollHeight;
      });
    },
    onPagesizeChange: function onPagesizeChange(newPageSize) {
      this.pageSize = newPageSize;
    },
    handleClearQuery: function handleClearQuery() {
      this.selectedNodes = [];
      this.selectedUsers = [];
      this.selectedOrganisations = [];
      this.selectedGAPS = [];
      this.selectedAttributes = [];
      this.gapTypesSelected = [];
      this.reportOptions = {};
      this.pageStart = 0;
      this.resetSavedReportQuery();
    },
    getRootNodeTitle: function getRootNodeTitle(node) {
      if (!node.visibleRootNodeId) return '';
      var visibleRootNode = this.tree.children.find(function (visibleRootNode) {
        return visibleRootNode.id === node.visibleRootNodeId;
      });
      if (!visibleRootNode) return '';
      return visibleRootNode.title;
    },
    setSelectedGaps: function setSelectedGaps(event) {
      this.selectedGAPS = event;
    },
    refreshTable: function refreshTable() {
      var resetQuery = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      return this.handleDoSearch(this.searchArgs, resetQuery);
    },
    getTagsNames: function getTagsNames(tags, ids) {
      return mapToCommaList(filterAsset(tags, ids), 'tag');
    },
    setAndLoadSavedReport: function setAndLoadSavedReport(initialOptions) {
      var _this8 = this;
      this.setInitialValues(initialOptions);
      return this.handleDoSearch(initialOptions, false).then(function () {
        var _this8$datasetSlice;
        return ((_this8$datasetSlice = _this8.datasetSlice) === null || _this8$datasetSlice === void 0 ? void 0 : _this8$datasetSlice.length) && _this8.loadSavedReport();
      });
    },
    setInitialValues: function setInitialValues(_ref5) {
      var gaps = _ref5.gaps,
        gapTypeIds = _ref5.gapTypeIds,
        rest = _objectWithoutProperties(_ref5, _excluded);
      this.initialFilters = _objectSpread(_objectSpread({}, this.initialFilters), rest);
      if (gaps !== null && gaps !== void 0 && gaps.length) {
        this.selectedGAPS = this.lights.filter(function (item) {
          return gaps.includes(item.number);
        });
      }
      if (this.enabledGapTypeMultiselect && gapTypeIds) {
        this.gapTypesSelected = this.gapTypesOptions.filter(function (type) {
          return gapTypeIds.includes(type.id);
        });
      }
      if (!this.enabledGapTypeMultiselect && gapTypeIds) {
        this.gapTypeIdSelected = gapTypeIds[0];
      }
    },
    getAttributesField: function getAttributesField(attributes) {
      var result = '';
      if (!attributes) return '';
      Object.entries(attributes).forEach(function (_ref6) {
        var _ref7 = _slicedToArray(_ref6, 2),
          key = _ref7[0],
          attributes = _ref7[1];
        result += "".concat(key, ": ").concat(attributes.join(', '), ".\n");
      });
      return result;
    },
    getItemHoverTitle: function getItemHoverTitle(item) {
      return DatatableRenderer.getItemHoverTitle(item);
    },
    dismissPreviewModal: function dismissPreviewModal() {
      if (this.updated) {
        this.refreshTable();
      }
      this.updated = false;
    }
  })
};