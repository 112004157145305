import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = {
  class: "glob-l1-sb"
};
var _hoisted_3 = {
  key: 2,
  class: "flex justify-end"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_single_select = _resolveComponent("single-select");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: _ctx.$trans($props.modalSettings.title),
    "button-ok-text": $data.translate.apply,
    "button-dismiss-text": $data.translate.cancel,
    "dismiss-on-click-outside": false,
    onAccept: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('updateDelegationItem', $props.isMultiple ? $data.selectedData.map(function (item) {
        return item.id;
      }) : $data.selectedData);
    }),
    onDismiss: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('closeDelegationModal');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [$props.isMultiple ? (_openBlock(), _createBlock(_component_risma_select, {
        key: 0,
        title: $options.optionsLabel,
        options: $options.sortedOptionsList,
        "selected-options": $data.selectedData,
        "label-key": $props.modalSettings.labelKey,
        "enable-select-all": false,
        api: $options.isUserList ? $options.getUsersAPI : null,
        placeholder: $options.searchPlaceholder,
        "search-fields": $props.modalSettings.searchFields,
        "additional-parameters": $options.userAPIAdditionalParameters,
        "all-data-shown": $options.allDataShown,
        "is-lazy-loading-shown": $data.isLazyLoading,
        onLoad: $options.handleLoadNewDataPortion,
        onSelected: $options.selected,
        "onUpdate:searchValue": $options.handleInput
      }, _createSlots({
        _: 2 /* DYNAMIC */
      }, [$props.showAddItem ? {
        name: "newOption",
        fn: _withCtx(function () {
          return [_createElementVNode("div", {
            class: "flex items-center cursor-pointer text-rm-signal-grey-dark hover:border-rm-text border-rm-signal-grey-dark hover:text-rm-text hover:underline",
            onClick: _cache[0] || (_cache[0] = function () {
              return $options.openCreationModal && $options.openCreationModal.apply($options, arguments);
            })
          }, [_createVNode(_component_feather_icon, {
            icon: "plus",
            width: "18",
            height: "18",
            class: "border-2 stroke-2 border-inherit rounded-full mr-2"
          }), _createElementVNode("span", _hoisted_2, _toDisplayString($data.translate.createNew), 1 /* TEXT */)])];
        }),
        key: "0"
      } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "options", "selected-options", "label-key", "api", "placeholder", "search-fields", "additional-parameters", "all-data-shown", "is-lazy-loading-shown", "onLoad", "onSelected", "onUpdate:searchValue"])) : (_openBlock(), _createBlock(_component_single_select, {
        key: 1,
        title: $options.optionsLabel,
        options: $options.singleSelectOptions,
        "model-value": $data.selectedData,
        "with-reset": true,
        "with-search": true,
        "un-sorted": true,
        "all-data-shown": $options.allDataShown,
        "is-lazy-loading-shown": $data.isLazyLoading,
        placeholder: $options.searchPlaceholder,
        onLoad: $options.handleLoadNewDataPortion,
        "onUpdate:modelValue": $options.onSingleSelectChange
      }, null, 8 /* PROPS */, ["title", "options", "model-value", "all-data-shown", "is-lazy-loading-shown", "placeholder", "onLoad", "onUpdate:modelValue"])), $options.showAssignToMeButton ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("span", {
        class: "cursor-pointer text-blue-350 hover:text-blue-750 font-semibold",
        onClick: _cache[1] || (_cache[1] = function () {
          return $options.assignToMe && $options.assignToMe.apply($options, arguments);
        })
      }, _toDisplayString($data.translate.assignToMe), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text"]);
}