import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaPopup from '@/components/Atoms/Containers/RismaPopup';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'RismaVueMultiselectPopUp',
  components: {
    InputField: InputField,
    RismaButton: RismaButton,
    RismaPopup: RismaPopup,
    FeatherIcon: FeatherIcon
  },
  props: {
    addNewOptionLabel: {
      type: String,
      default: '',
      required: false
    },
    position: {
      type: String,
      default: 'top',
      note: 'position of the button, applies additional styles'
    }
  },
  emits: ['tag'],
  data: function data() {
    return {
      newOptionValue: '',
      isHiddenPopup: true,
      translate: {
        add: this.$trans('Add')
      }
    };
  },
  methods: {
    handleNewAnswer: function handleNewAnswer() {
      this.$emit('tag', this.newOptionValue);
      this.newOptionValue = '';
      this.isHiddenPopup = true;
    },
    handleCreateNewOption: function handleCreateNewOption() {
      this.isHiddenPopup = false;
      this.newOptionValue = '';
    }
  }
};