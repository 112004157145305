export function mapToCommaListWithLinks(arr, url) {
  var field = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'name';
  var linkId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'id';
  var isRouterLink = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  if (!arr || !arr.length) return '';
  return arr.map(function (obj) {
    var linkUrl = obj[url] || "".concat(url).concat(obj[linkId]);
    var data = typeof field === 'string' ? obj[field] : getRightDataMapping(obj, field);
    return isRouterLink ? "<router-link to=\"".concat(linkUrl, "\">").concat(data, "</router-link>") : "<a target=\"_blank\" href=\"".concat(linkUrl, "\">").concat(data, "</a>");
  }).join(', ');
}
export function mapToCommaList(arr, field) {
  return mapToSeparatorList(arr, field);
}
function getRightDataMapping(obj, fields) {
  var existingField = fields.find(function (field) {
    return obj[field];
  });
  return obj[existingField] || '';
}
export function mapToSeparatorList(arr) {
  var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  var separator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ', ';
  if (!(arr !== null && arr !== void 0 && arr.length)) return '';
  return arr.map(function (obj) {
    return typeof field === 'string' ? obj[field] : getRightDataMapping(obj, field);
  }).join(separator);
}