import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_frameworks_selector = _resolveComponent("frameworks-selector");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.newProcessLibraryNode,
    "header-truncate": false,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    "accept-on-enter": !$data.isRismaSelectOpen,
    "ok-button-disabled": $data.isLoading,
    "dismiss-button-disabled": $data.isLoading,
    "dismiss-on-click-outside": false,
    onAccept: $options.handleOnSubmit,
    onDismiss: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return $data.errorMessage = '';
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.errorMessage), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        modelValue: $data.newNode.title,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.newNode.title = $event;
        }),
        title: $data.translate.title,
        "focus-on-mount": true,
        invalid: !!$data.titleError,
        "error-help-text": $data.titleError,
        placeholder: $data.translate.enterTitle,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "error-help-text", "placeholder"])]), !$props.isFrameworksHidden ? (_openBlock(), _createBlock(_component_frameworks_selector, {
        key: 1,
        "selected-options": $data.newNode.frameworks,
        onSelected: _cache[2] || (_cache[2] = function ($event) {
          return $data.newNode.frameworks = $event;
        }),
        onOpen: _cache[3] || (_cache[3] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[4] || (_cache[4] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["selected-options"])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "accept-on-enter", "ok-button-disabled", "dismiss-button-disabled", "onAccept"]);
}