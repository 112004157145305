import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = ["title"];
var _hoisted_3 = ["title", "onClick"];
var _hoisted_4 = {
  key: 1,
  class: "text-base"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [Object.keys(_ctx.typesByQuestion).length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typesByQuestion, function (questionData, questionNumber) {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: questionNumber
    }, [_createVNode(_component_risma_title, {
      type: "medium",
      title: $data.translate.chooseLegalBasisBehindDataTypes
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
      type: "small",
      title: "".concat($data.translate.question, " ").concat(questionNumber)
    }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(questionData.dataTypes, function (item, index) {
      return _openBlock(), _createElementBlock("div", {
        key: "basis-table-row-".concat(index),
        class: "flex items-center mb-2"
      }, [_createElementVNode("p", {
        class: "w-1/2 text-base text-gray-750",
        title: item.text
      }, _toDisplayString(item.text), 9 /* TEXT, PROPS */, _hoisted_2), _createVNode(_component_single_select, {
        "model-value": $props.selectedOptions[$data.fieldSelected][item.uniqueId],
        options: questionData.options,
        placeholder: $data.translate.chooseLegalBasis,
        "un-sorted": true,
        class: "!w-1/2 !min-w-1/2 mr-4",
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return _ctx.selectOption(questionData.options, $event, item.uniqueId);
        }
      }, null, 8 /* PROPS */, ["model-value", "options", "placeholder", "onUpdate:modelValue"]), _createElementVNode("span", {
        title: $data.translate.applyLegalBasisToAll,
        class: _normalizeClass($props.selectedOptions[$data.fieldSelected][item.uniqueId] ? 'cursor-pointer' : 'text-gray-350'),
        onClick: function onClick($event) {
          return _ctx.selectAll(questionData.options, questionData.dataTypes, item.uniqueId);
        }
      }, [_createVNode(_component_feather_icon, {
        width: "30",
        height: "30",
        icon: "copy"
      })], 10 /* CLASS, PROPS */, _hoisted_3)]);
    }), 128 /* KEYED_FRAGMENT */))], 64 /* STABLE_FRAGMENT */);
  }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("p", null, _toDisplayString($data.translate.legalBasisHasntBeenConfigured), 1 /* TEXT */), _createElementVNode("p", null, _toDisplayString($data.translate.contactYourAdministratorToConfigure), 1 /* TEXT */)]))]);
}