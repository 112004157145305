import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  ref: "toggleMenu",
  class: "relative"
};
var _hoisted_2 = ["title", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "dot-wrap flex rounded-full p-px cursor-pointer hover:bg-blue-75 flex items-center",
    onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
      return $options.toggleVisibility && $options.toggleVisibility.apply($options, arguments);
    }, ["stop"]))
  }, [_createVNode(_component_feather_icon, {
    width: "35",
    height: "35",
    "fill-class": "fill-blueMain",
    class: "ml-px",
    icon: "more-horizontal"
  })]), $data.isVisible ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["toggle-menu-modal", [$props.position, {
      'py-3 px-2': $props.menuStyle === 'classic'
    }]]),
    style: _normalizeStyle({
      minWidth: "".concat($props.menuWidth, "px"),
      left: $data.positionLeft,
      top: $data.positionTop,
      right: $data.positionRight
    })
  }, [_createElementVNode("ul", {
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.closeMenu && $options.closeMenu.apply($options, arguments);
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.visibleOptions, function (option) {
    return _openBlock(), _createElementBlock("li", {
      key: option.id,
      class: _normalizeClass(["flex cursor-pointer items-center p-1.5", option.class || ['hover:bg-rGrey-400', {
        'text-xs ': $props.menuStyle === 'minimal'
      }]]),
      style: _normalizeStyle([option.style]),
      title: option.title,
      onClick: option.action
    }, [option.icon ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      class: _normalizeClass(["mr-1", option.class || {
        'stroke-blue': $props.menuStyle === 'minimal'
      }]),
      icon: option.icon
    }, null, 8 /* PROPS */, ["class", "icon"])) : _createCommentVNode("v-if", true), _cache[2] || (_cache[2] = _createTextVNode("   ")), _createElementVNode("span", null, _toDisplayString(option.title), 1 /* TEXT */)], 14 /* CLASS, STYLE, PROPS */, _hoisted_2);
  }), 128 /* KEYED_FRAGMENT */))])], 6 /* CLASS, STYLE */)) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, $options.closeMenu]]);
}