import InputField from '@/components/Atoms/Inputs/InputField';
export default {
  name: '',
  components: {
    InputField: InputField
  },
  data: function data() {
    return {
      testText: 'Test text'
    };
  }
};