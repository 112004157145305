// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.management-table .single-select[data-v-4e9b8e5e] {
  width: 4rem;
  border: none;
  flex: none;
}
.management-table[data-v-4e9b8e5e]  .select-selected {
  border: none;
  outline: none;
  background: transparent;
  width: 70px;
}
.management-table[data-v-4e9b8e5e]  .select-selected .value-wrap {
  padding-right: 0;
}
.management-table[data-v-4e9b8e5e]  .custom-select-inner {
  background: transparent;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
