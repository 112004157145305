// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.read-only[data-v-6fb34816]  textarea {
  pointer-events: none;
  background-color: transparent;
}
.read-only[data-v-6fb34816]  .radio-buttons {
  pointer-events: none;
  background-color: transparent;
}
.read-only[data-v-6fb34816]  .compliance-attachments .innerWrap {
  display: none;
}
.navigate-arrow[data-v-6fb34816]:hover {
  background: #CCCCCC;
}
.questionnaire-content[data-v-6fb34816] {
  contain: inline-size;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
