function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
import { getAnswerOptions } from '@/utils/questionnaire';
import { Colors } from '@/Colors';
export default {
  name: 'GapSchemaQuestions',
  components: {
    RismaTitle: RismaTitle
  },
  props: {
    questions: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function data() {
    return {
      allColors: Colors,
      expandedQuestions: {},
      questionTypes: QuestionTypes()
    };
  },
  computed: {
    answerOptionsByQuestions: function answerOptionsByQuestions() {
      return this.questions.reduce(function (res, question) {
        res[question.id] = getAnswerOptions(question);
        return res;
      }, {});
    }
  },
  watch: {
    questions: {
      deep: true,
      handler: function handler() {
        this.questionsToExpandedQuestions();
      }
    }
  },
  mounted: function mounted() {
    this.questionsToExpandedQuestions();
  },
  methods: {
    fullLengthQuestion: function fullLengthQuestion(event) {
      event.target.classList.remove('truncate');
    },
    truncateQuestion: function truncateQuestion(event) {
      event.target.classList.add('truncate');
    },
    toggleAnswer: function toggleAnswer(questionId) {
      this.expandedQuestions[questionId] = !this.expandedQuestions[questionId];
    },
    questionsToExpandedQuestions: function questionsToExpandedQuestions() {
      var _this = this;
      this.questions.forEach(function (question) {
        _this.expandedQuestions[question.id] = true;
      });
    },
    getRightTextByAnswer: function getRightTextByAnswer(answer) {
      if (_typeof(answer) === 'object') {
        return "".concat(this.$trans(answer.title), ": ").concat(answer.items);
      }
      return answer;
    }
  }
};