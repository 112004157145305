import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-fit"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", {
    class: _normalizeClass([$props.modelValue === $data.toggleStates.FILE ? $data.selectedClasses : $data.nonSelectedClasses + ' border-r-0', $data.defaultClasses, 'rounded-l-md']),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.select($data.toggleStates.FILE);
    })
  }, _toDisplayString($data.translate.file), 3 /* TEXT, CLASS */), _createElementVNode("span", {
    class: _normalizeClass([$props.modelValue === $data.toggleStates.LINK ? $data.selectedClasses : $data.nonSelectedClasses + ' border-l-0', $data.defaultClasses, 'rounded-r-md']),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.select($data.toggleStates.LINK);
    })
  }, _toDisplayString($data.translate.link), 3 /* TEXT, CLASS */)]);
}