// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-4[data-v-090c93c8] {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.governance-review-modal[data-v-090c93c8]  {
  /* overriding changes made from risma1 */
}
.governance-review-modal[data-v-090c93c8]  .risma-title {
  display: block !important;
}
.governance-review-modal[data-v-090c93c8]  .notification p {
  margin-bottom: 3px;
}
.governance-review-modal[data-v-090c93c8]  .modal-alert-wrap .modal-mask .modal-header .risma-title h1 {
  white-space: normal !important;
}
.governance-review-modal[data-v-090c93c8]  .top {
  background: #FFFFFF !important;
  position: absolute !important;
  z-index: 1000 !important;
  height: auto !important;
  top: auto !important;
}
.governance-review-modal .input-field[data-v-090c93c8] {
  width: 30%;
}
.governance-review-modal div[class^=col][data-v-090c93c8]:first-child {
  padding-left: 0;
}
.governance-review-modal div[class^=col][data-v-090c93c8]:last-child {
  padding-right: 0;
}
.governance-review-modal .rowWrapper[data-v-090c93c8] {
  height: 6rem;
  margin-top: 0.5rem;
  padding-right: 2px;
}
.help-tool-tip[data-v-090c93c8] {
  position: absolute;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
