function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { Colors } from '@/Colors';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { getInfoFields } from '@/utils/contactCard';
export default {
  name: 'ContactCard',
  introduction: 'ContactCard component',
  description: 'A container for displaying contact information',
  token: '<contact-card type="processors|controllers|systems|custom" info="{}" />',
  components: {
    PreviewModalLink: PreviewModalLink,
    RismaTitle: RismaTitle
  },
  props: {
    type: {
      type: String,
      required: true,
      note: 'The type of contact card',
      validator: function validator(value) {
        return [AssetsTypes.PROCESSORS, AssetsTypes.CONTROLLERS, AssetsTypes.SYSTEMS, AssetsTypes.CUSTOM, 'companies'].includes(value);
      }
    },
    info: {
      type: Object,
      required: true,
      note: 'The object with data'
    },
    hasPreviewMode: {
      type: Boolean,
      default: true,
      required: false,
      note: 'if it has previewModalLink instead of routerlink title'
    },
    isCutVersion: {
      type: Boolean,
      default: false,
      required: false,
      note: 'sometimes some data should not be presented'
    },
    nameVisible: {
      type: Boolean,
      default: true,
      required: false,
      note: 'Indicates whether the name of the activity is shown'
    }
  },
  emits: ['updateFromPreviewMode', 'dismissModal'],
  data: function data() {
    return {
      assetsTypes: AssetsTypes,
      translate: {
        status: this.$trans('Status'),
        description: this.$trans('Description'),
        companyName: this.$trans('Company name'),
        companyDescription: this.$trans('Company description')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isCompany: function isCompany() {
      return this.activeInfoObj.objType === ObjectTypes.COMPANY;
    },
    companyStatus: function companyStatus() {
      var _this = this;
      if (this.isCompany) {
        var linkedCompanyTrafficLight = Colors[ObjectTypes.COMPANY].find(function (color) {
          var _this$activeInfoObj;
          return +color.number === ((_this$activeInfoObj = _this.activeInfoObj) === null || _this$activeInfoObj === void 0 ? void 0 : _this$activeInfoObj.vatStatus);
        });
        return linkedCompanyTrafficLight ? this.$trans(linkedCompanyTrafficLight.label) : '';
      }
      return '';
    },
    activeInfoObj: function activeInfoObj() {
      return this.info.companyInfo || this.info;
    },
    infoFields: function infoFields() {
      return getInfoFields(this.activeInfoObj, this.type);
    }
  })
};