import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 1,
  class: "w-min"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  class: "border-separate"
};
var _hoisted_4 = {
  class: "text-left pr-8"
};
var _hoisted_5 = {
  class: "text-left pr-8"
};
var _hoisted_6 = {
  class: "text-left pr-8"
};
var _hoisted_7 = {
  class: "pr-8"
};
var _hoisted_8 = {
  class: "pr-8"
};
var _hoisted_9 = {
  class: "pr-8"
};
var _hoisted_10 = {
  class: "pr-8"
};
var _hoisted_11 = {
  key: 0,
  class: "mb-10"
};
var _hoisted_12 = {
  class: "w-300px"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_single_select = _resolveComponent("single-select");
  var _component_options_list = _resolveComponent("options-list");
  var _component_risma_button = _resolveComponent("risma-button");
  return $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [!_ctx.isInformationAssetsDisabled ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.riskAssessmentOnAssets,
    type: "big",
    class: "mb-4"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("table", _hoisted_3, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.name,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.enableRiskAssessment,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_6, [_createVNode(_component_risma_title, {
    title: $data.translate.risksCreatedIn,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])])])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.settingsData, function (item, index) {
    return _openBlock(), _createElementBlock("tr", {
      key: index
    }, [_createElementVNode("td", _hoisted_7, [_createVNode(_component_risma_title, {
      title: _ctx.$trans(item.name),
      type: "small"
    }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("td", _hoisted_8, [_createVNode(_component_risma_toggle, {
      "model-value": $data.data[item.switchSettingName],
      onInput: function onInput($event) {
        return $options.addSettingToData(+$event, item.switchSettingName);
      }
    }, null, 8 /* PROPS */, ["model-value", "onInput"])]), _createElementVNode("td", null, [_createVNode(_component_single_select, {
      modelValue: $data.data[item.riskIdSettingName],
      "onUpdate:modelValue": [function ($event) {
        return $data.data[item.riskIdSettingName] = $event;
      }, function ($event) {
        return $options.addSettingToData($event, item.riskIdSettingName);
      }],
      options: $options.riskProjectsOptions,
      "un-sorted": true,
      "with-reset": true,
      placeholder: $data.translate.noRiskProject,
      class: "w-300px"
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options", "placeholder"])])]);
  }), 128 /* KEYED_FRAGMENT */)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customInformationAssetsModules, function (item) {
    return _openBlock(), _createElementBlock("tr", {
      key: item.id
    }, [_createElementVNode("td", _hoisted_9, [_createVNode(_component_risma_title, {
      title: item.label,
      type: "small"
    }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("td", _hoisted_10, [_createVNode(_component_risma_toggle, {
      "model-value": item.riskEnabled,
      onInput: function onInput($event) {
        return item.riskEnabled = !item.riskEnabled;
      }
    }, null, 8 /* PROPS */, ["model-value", "onInput"])]), _createElementVNode("td", null, [_createVNode(_component_single_select, {
      modelValue: item.riskProjectId,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return item.riskProjectId = $event;
      },
      options: $options.riskProjectsOptions,
      "un-sorted": true,
      "with-reset": true,
      placeholder: $data.translate.noRiskProject,
      class: "min-w-300px"
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options", "placeholder"])])]);
  }), 128 /* KEYED_FRAGMENT */))])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [_ctx.getSettingValue('feature.compliance_nodes_risk_assessment') ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $data.translate.riskAssessmentOnNodes,
    type: "big",
    class: "mb-4"
  }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.complianceProjects, function (project) {
    return _openBlock(), _createElementBlock("div", {
      key: project.id,
      class: "flex items-center mb-3 justify-between"
    }, [_createVNode(_component_risma_title, {
      title: project.title,
      type: "medium",
      class: "mr-3 min-w-250px"
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
      modelValue: project.riskProjectId,
      "onUpdate:modelValue": [function ($event) {
        return project.riskProjectId = $event;
      }, function ($event) {
        return $options.onProjectAssessmentChange(project.id, $event);
      }],
      options: $options.riskProjectsOptionsWithoutErm,
      "un-sorted": true,
      "with-reset": true,
      placeholder: $data.translate.noRiskProject,
      style: {
        width: '300px'
      }
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options", "placeholder"])]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.rtoAndRpoFields,
    type: "big",
    class: "mb-4"
  }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.rtoRpoGroup, function (group, key) {
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: "flex items-center mb-8 gap-x-8 justify-between"
    }, [_createVNode(_component_risma_title, {
      title: _ctx.$trans(group.title),
      type: "medium",
      class: "self-start mr-3 pt-2 min-w-250px"
    }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_options_list, {
      "predefined-options": group.values,
      placeholder: $data.translate.pleaseEnterAValue,
      draggable: false,
      "show-empty-fields-error": true,
      "error-message": !(group.values && group.values.length) ? $data.translate.enterAnOption : '',
      "label-property": "value",
      class: "mb-3",
      onOnOptionsChange: function onOnOptionsChange($event) {
        return $options.updateDropdownOption($event, key);
      }
    }, null, 8 /* PROPS */, ["predefined-options", "placeholder", "error-message", "onOnOptionsChange"])])]);
  }), 128 /* KEYED_FRAGMENT */))]), _createVNode(_component_risma_button, {
    text: _ctx.$trans("Save settings"),
    type: "save",
    class: "mt-8 mr-10",
    onClick: $options.saveSettings
  }, null, 8 /* PROPS */, ["text", "onClick"])]));
}