// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-7fd3e16c]  svg {
  display: inline;
}
[data-v-7fd3e16c]  .data-lane {
  text-align: center;
  margin-bottom: 0.5rem;
}
[data-v-7fd3e16c]  .data-lane:first-child {
  margin-top: 10vh;
}
[data-v-7fd3e16c]  .data-lane .lane-column .arrow-line::before {
  content: "";
  position: absolute;
  left: -42px;
  top: 20px;
  border-top: 1px solid #737373;
  width: 32px;
}
[data-v-7fd3e16c]  .data-lane .lane-column .always-first-arrow-line {
  width: 100%;
}
[data-v-7fd3e16c]  .data-lane .lane-column .arrow-left {
  left: -75px;
  border-right: 9px solid #737373;
}
[data-v-7fd3e16c]  .data-lane .lane-column .arrow-right {
  left: -10px;
  border-left: 9px solid #737373;
}
[data-v-7fd3e16c]  .data-lane .lane-column:not(:first-child) .arrow-line:not(.always-first-arrow-line)::before {
  top: calc(20px + 1rem);
}
[data-v-7fd3e16c]  .data-lane .lane-column:first-child .arrow-line::before,[data-v-7fd3e16c]  .data-lane .always-first-arrow-line::before {
  width: 65px !important;
  left: -75px !important;
}
[data-v-7fd3e16c]  .data-lane .lane-column:not(:first-child) .datatype-arrow {
  /*custom pos for all right arrows (except first one, it doesn\`t have 1rem pt)*/
  top: 33px !important;
}
[data-v-7fd3e16c]  .data-lane .lane-column:first-child .arrow-left,[data-v-7fd3e16c]  .data-lane .lane-column .arrow-right,[data-v-7fd3e16c]  .data-lane .system-arrow {
  /*arrows are set everywhere except left datatype errors (not the first one)*/
  position: absolute;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  top: 17px;
}
[data-v-7fd3e16c]  .data-lane .lane-column:not(:last-child) .arrow-line:not(.always-first-arrow-line)::after {
  content: "";
  position: absolute;
  left: -42px;
  top: 20px;
  border-left: 1px solid #737373;
  height: calc(100% + 1rem);
}
[data-v-7fd3e16c]  .data-lane .lane-column:nth-child(n+2) {
  padding-top: 1rem;
}
[data-v-7fd3e16c]  .inline-size {
  contain: inline-size;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
