function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import draggable from 'vuedraggable';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import DragAndDropIcon from '@/components/Atoms/FeatherIcon/DragAndDropIcon';
import { DragOptions } from '@/constants/DragOptions';
export default {
  name: 'QuizQuestion',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    InputField: InputField,
    draggable: draggable,
    DragAndDropIcon: DragAndDropIcon
  },
  props: {
    question: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    questionIndex: {
      type: Number,
      required: true,
      default: 0
    },
    listIndexes: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['update:question', 'delete:question', 'error'],
  data: function data() {
    return {
      currentQuestion: {},
      startQuestion: {},
      DragOptions: DragOptions,
      translate: getTranslate['QuizQuestion']()
    };
  },
  computed: {
    headline: function headline() {
      return this.translate.question + ' ' + this.questionIndex;
    }
  },
  watch: {
    currentQuestion: {
      deep: true,
      handler: function handler() {
        this.$emit('update:question', _objectSpread(_objectSpread({}, this.currentQuestion), {}, {
          headline: this.headline
        }));
      }
    }
  },
  beforeMount: function beforeMount() {
    this.currentQuestion = cloneDeep(this.question);
    if (!this.currentQuestion.options.length) {
      this.createNewQuestionOption();
    }
    this.startQuestion = cloneDeep(this.currentQuestion);
  },
  methods: {
    deleteOption: function deleteOption(index) {
      if (this.currentQuestion.options.length === 1) {
        this.$emit('error', this.translate.notPossibleToDeleteLastOption);
        return;
      }
      if (this.currentQuestion.options[index].correctAnswer) this.currentQuestion.options[0].correctAnswer = true;
      this.currentQuestion.options.splice(index, 1);
    },
    deleteQuestion: function deleteQuestion() {
      this.$emit('delete:question', !isEqual(this.currentQuestion, this.startQuestion));
    },
    setTrueAnswer: function setTrueAnswer(index) {
      this.currentQuestion.options.forEach(function (option, optionIndex) {
        return option.correctAnswer = optionIndex === index ? 1 : 0;
      });
    },
    createNewQuestionOption: function createNewQuestionOption() {
      if (this.currentQuestion.options.length >= this.listIndexes.length) return;
      this.currentQuestion.options.push({
        correctAnswer: !this.currentQuestion.options.length ? 1 : 0,
        label: '',
        optionNumber: this.currentQuestion.options.length + 1
      });
    }
  }
};