import { getTranslate } from './translate';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import StaticTable from '@/components/Molecules/StaticTable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import FinancialDefinitionsEditModal from '@/components/Organisms/Contracts/FinancialDefinitionsEditModal';
import { VAT_STATUSES } from '@/constants/companies/vatStatuses';
import { getCompanies } from '@/api/admin/companies';
import { getDoraReports as _getDoraReports } from '@/api/contracts/contracts';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'DoraReport',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    SingleSelect: SingleSelect,
    StaticTable: StaticTable,
    PreviewModalLink: PreviewModalLink,
    FeatherIcon: FeatherIcon,
    FinancialDefinitionsEditModal: FinancialDefinitionsEditModal
  },
  setup: function setup() {
    return {
      selectedCompanyId: getLocalStorageComputed('dora_report_company_id', null)
    };
  },
  data: function data() {
    return {
      isLoaded: true,
      companies: [],
      reportsList: [],
      showDefinitionModal: false,
      translate: getTranslate['DoraReport']()
    };
  },
  computed: {
    companyOptions: function companyOptions() {
      return this.companies.filter(function (company) {
        return company.doraEnabled && !company.parentId && (company.vatStatus === VAT_STATUSES.UNKNOWN.value || company.vatStatus === VAT_STATUSES.NORMAL.value);
      }).map(function (_ref) {
        var name = _ref.name,
          id = _ref.id;
        return {
          value: id + '',
          label: name
        };
      });
    },
    columns: function columns() {
      return [this.translate.id, this.translate.name, this.translate.status, this.translate.excel, this.translate.csv];
    },
    dataset: function dataset() {
      var _this = this;
      return this.reportsList.map(function (item) {
        var is99 = item.id === 'B_99.01';
        return {
          id: item.id,
          name: {
            name: _this.$trans(item.name),
            icon: is99 ? 'edit' : null,
            action: is99 ? _this.openDefinitionModal : null
          },
          status: _this.extendDoraReportsData(item.errors),
          excel: _this.getDownloadUrl(item, '?format=xlsx'),
          csv: _this.getDownloadUrl(item)
        };
      });
    }
  },
  mounted: function mounted() {
    this.getExtendedData();
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      return getCompanies().then(function (_ref2) {
        var list = _ref2.list;
        return _this2.companies = list;
      });
    },
    getExtendedData: function getExtendedData() {
      var _this3 = this;
      var withNotification = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.isLoaded = false;
      return this.getData().then(function () {
        if (_this3.selectedCompanyId) {
          if (_this3.companyOptions.find(function (item) {
            return item.value === _this3.selectedCompanyId;
          })) _this3.getDoraReports(_this3.selectedCompanyId);else {
            _this3.selectedCompanyId = null;
            _this3.isLoaded = true;
            if (withNotification) _this3.$notify({
              title: _this3.translate.previouslySelectedParentCompany,
              type: 'error'
            });
          }
        }
      });
    },
    isReady: function isReady(data) {
      return Object.values(data).every(function (item) {
        var _item$fields;
        return !(item !== null && item !== void 0 && (_item$fields = item.fields) !== null && _item$fields !== void 0 && _item$fields.length);
      });
    },
    uploadDoraReport: function uploadDoraReport(selectedCompanyId) {
      if (selectedCompanyId === this.selectedCompanyId) return;
      this.selectedCompanyId = selectedCompanyId;
      if (!selectedCompanyId) return;
      return this.getDoraReports(selectedCompanyId);
    },
    getDoraReports: function getDoraReports(companyId) {
      var _this4 = this;
      this.isLoaded = false;
      return _getDoraReports(companyId).then(function (_ref3) {
        var list = _ref3.list;
        return _this4.reportsList = list;
      }).catch(function (error) {
        return _this4.$notify({
          title: error.response.error,
          type: 'error'
        });
      }).finally(function () {
        return _this4.isLoaded = true;
      });
    },
    extendDoraReportsData: function extendDoraReportsData(errors) {
      return errors.map(function (str) {
        var parts = str.split(/({.*?})/).filter(Boolean);
        return parts.map(function (part) {
          if (part.startsWith('{') && part.endsWith('}')) return JSON.parse(part);
          return part;
        });
      });
    },
    openDefinitionModal: function openDefinitionModal() {
      this.showDefinitionModal = true;
    },
    getDownloadUrl: function getDownloadUrl(data) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      return data.validated ? "/api/2.0/".concat(data.url).concat(params) : '';
    }
  }
};