import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "relative grid grid-cols-two-col gap-x-4"
};
var _hoisted_2 = {
  class: "mb-3"
};
var _hoisted_3 = {
  class: "mb-3"
};
var _hoisted_4 = {
  class: "mb-3"
};
var _hoisted_5 = {
  class: "mb-3"
};
var _hoisted_6 = {
  class: "mb-3"
};
var _hoisted_7 = {
  class: "mb-3"
};
var _hoisted_8 = {
  class: "mb-3"
};
var _hoisted_9 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_single_select = _resolveComponent("single-select");
  var _component_input_field_select = _resolveComponent("input-field-select");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $options.renewHeader,
    "button-ok-text": $data.translate.save,
    "button-dismiss-text": $data.translate.cancel,
    "ok-button-disabled": !!$options.errorMessage,
    onAccept: _cache[11] || (_cache[11] = function ($event) {
      return _ctx.$emit('update', $data.modalData);
    }),
    onDismiss: _cache[12] || (_cache[12] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$options.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        closeable: false
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.errorMessage), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
        modelValue: $data.modalData.workflowStatusId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.modalData.workflowStatusId = $event;
        }),
        title: $data.translate.newStatus,
        placeholder: $data.translate.newStatus,
        options: $options.statuses
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_input_field_select, {
        title: $data.translate.newContractAmount,
        "input-value": $data.modalData.contractAmount,
        "option-value": $data.modalData.contractAmountCurrency,
        "input-placeholder": $data.translate.contractAmount,
        options: $props.currencyOptions,
        "un-sorted": true,
        tabindex: 6,
        "enable-currency-template": true,
        type: "text",
        onInputChange: _cache[1] || (_cache[1] = function ($event) {
          return $data.modalData.contractAmount = $event;
        }),
        onOptionChange: _cache[2] || (_cache[2] = function ($event) {
          return $data.modalData.contractAmountCurrency = $event;
        })
      }, null, 8 /* PROPS */, ["title", "input-value", "option-value", "input-placeholder", "options"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
        modelValue: $data.modalData.paymentInterval,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.modalData.paymentInterval = $event;
        }),
        title: $data.translate.paymentInterval,
        placeholder: $data.translate.choose,
        options: $data.paymentIntervalOptions,
        "un-sorted": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_single_select, {
        modelValue: $data.modalData.paymentIntervalStartMonth,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $data.modalData.paymentIntervalStartMonth = $event;
        }),
        title: $data.translate.paymentStartMonth,
        placeholder: $data.translate.choose,
        options: $data.paymentMonthOptions,
        "un-sorted": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_date_time_picker, {
        modelValue: $data.modalData.startDate,
        "onUpdate:modelValue": [_cache[5] || (_cache[5] = function ($event) {
          return $data.modalData.startDate = $event;
        }), _cache[6] || (_cache[6] = function ($event) {
          return $data.fieldUpdated = 'startDate';
        })],
        title: $data.translate.startDate,
        "plugin-options": $data.datePickerOptions
      }, null, 8 /* PROPS */, ["modelValue", "title", "plugin-options"])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_date_time_picker, {
        modelValue: $data.modalData.endDate,
        "onUpdate:modelValue": [_cache[7] || (_cache[7] = function ($event) {
          return $data.modalData.endDate = $event;
        }), _cache[8] || (_cache[8] = function ($event) {
          return $data.fieldUpdated = 'endDate';
        })],
        title: $data.translate.expirationDate,
        "plugin-options": $data.datePickerOptions
      }, null, 8 /* PROPS */, ["modelValue", "title", "plugin-options"])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_date_time_picker, {
        modelValue: $data.modalData.bindingDate,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
          return $data.modalData.bindingDate = $event;
        }),
        title: $data.translate.commitmentDeadline,
        "plugin-options": $data.datePickerOptions
      }, null, 8 /* PROPS */, ["modelValue", "title", "plugin-options"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_date_time_picker, {
        modelValue: $data.modalData.renewalDate,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = function ($event) {
          return $data.modalData.renewalDate = $event;
        }),
        title: $data.translate.renewalDeadline,
        "plugin-options": $data.datePickerOptions
      }, null, 8 /* PROPS */, ["modelValue", "title", "plugin-options"])])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "ok-button-disabled"]);
}