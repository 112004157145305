function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import cloneDeep from 'lodash/cloneDeep';
import { mapActions } from 'pinia';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
import { createGapType as _createGapType, patchGapType as _patchGapType, deleteGapType as _deleteGapType, getGapTypes as _getGapTypes } from '@/api/compliance/gaps';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import OptionsList from '@/components/Molecules/OptionsList/OptionsList';
export default {
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    OptionsList: OptionsList
  },
  props: {
    complianceProjectId: {
      type: [String, Number],
      required: true,
      default: null
    }
  },
  data: function data() {
    return {
      notificationError: false,
      gapTypes: [],
      initialGapTypes: [],
      gapTypesLoading: false,
      saveButtonClickCounter: 0,
      translate: getTranslate['GapTypesList']()
    };
  },
  computed: {
    isGapTypesValid: function isGapTypesValid() {
      return !this.gapTypes.find(function (gapType) {
        return !gapType.label;
      });
    },
    confirmOnDelete: function confirmOnDelete() {
      return {
        title: this.translate.areYouSure,
        body: this.translate.areYouSureYouWantToDeleteThisGapType
      };
    }
  },
  watch: {
    complianceProjectId: function complianceProjectId(projectId) {
      this.getGapTypes(projectId);
    }
  },
  mounted: function mounted() {
    if (this.complianceProjectId) {
      this.getGapTypes(this.complianceProjectId);
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useComplianceProjectsStore, {
    loadComplianceProjects: 'load'
  })), {}, {
    getGapTypes: function getGapTypes(projectId) {
      var _this = this;
      if (!projectId) return;
      this.gapTypesLoading = true;
      return _getGapTypes(projectId).then(function (_ref) {
        var list = _ref.list;
        var sortedTypes = list.sort(function (a, b) {
          return a.number - b.number;
        });
        _this.gapTypes = cloneDeep(sortedTypes);
        _this.initialGapTypes = cloneDeep(sortedTypes);
      }).finally(function () {
        _this.gapTypesLoading = false;
      });
    },
    patchGapType: function patchGapType(gapType) {
      if (!this.complianceProjectId) return;
      return _patchGapType(this.complianceProjectId, gapType);
    },
    deleteGapType: function deleteGapType(gapTypeId) {
      if (!this.complianceProjectId) return;
      return _deleteGapType(this.complianceProjectId, gapTypeId);
    },
    createGapType: function createGapType(gapType) {
      if (!this.complianceProjectId) return;
      return _createGapType(this.complianceProjectId, gapType);
    },
    onOptionsChange: function onOptionsChange(options) {
      this.gapTypes = options.map(function (gapType, index) {
        gapType.number = index + 1;
        return gapType;
      });
    },
    onSaveGapTypes: function onSaveGapTypes() {
      var _this2 = this;
      this.notificationError = false;
      this.saveButtonClickCounter++;
      if (!this.isGapTypesValid) {
        this.notificationError = true;
        return;
      }
      var promises = [];
      var initialGapTypesInUseIds = [];
      this.gapTypesLoading = true;
      this.gapTypes.forEach(function (gapType) {
        if (!gapType.id) {
          promises.push(_this2.createGapType(gapType));
          return;
        }
        var initialGapType = _this2.initialGapTypes.find(function (initialItem) {
          return initialItem.id === gapType.id;
        });
        if (!initialGapType) return;
        if (initialGapType.number !== gapType.number || initialGapType.label !== gapType.label) {
          promises.push(_this2.patchGapType(gapType));
        }
        initialGapTypesInUseIds.push(initialGapType.id);
      });
      this.initialGapTypes.forEach(function (initialItem) {
        if (initialGapTypesInUseIds.includes(initialItem.id)) return;
        promises.push(_this2.deleteGapType(initialItem.id));
      });
      return Promise.all(promises).then(function () {
        return _this2.getGapTypes(_this2.complianceProjectId);
      }).then(function () {
        return _this2.$notify({
          title: _this2.translate.changesSaved,
          type: 'success'
        });
      }).then(function () {
        return _this2.loadComplianceProjects();
      }).catch(function () {
        return _this2.$notify({
          title: _this2.translate.somethingWentWrong,
          type: 'error'
        });
      }).finally(function () {
        _this2.gapTypesLoading = false;
      });
    }
  })
};