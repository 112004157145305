function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RiskReportGrid from '@/components/Organisms/Risk/RiskReportGrid';
import RiskGridDisplay from '@/components/Molecules/Risk/RiskGridDisplay';
import RiskReportsFilters from '@/components/Molecules/Risk/RiskReports/RiskReportsFilters';
import RiskReportsFiltersErm from '@/components/Molecules/Risk/RiskReports/RiskReportsFiltersErm';
import { CommonAssetsMixin } from '@/mixins/CommonAssetsMixin';
import { RiskUniversalMixin } from '@/components/Pages/Risks/RiskUniversalMixin';
import { RiskGridMixin } from '@/mixins/RiskGridMixin';
import * as RiskApi from '@/api/risk/risk';
import { getCurrentScoring as _getCurrentScoring, getAllScorings } from '@/api/risk/scoring';
import * as DataAPI from '@/api/risma/data';
import * as Utils from '@/utils/Utils';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import { UserLevels } from '@/constants/UserLevels';
import { RiskStatus, ErmRiskStatus } from '@/constants/risks/RiskStatus';
import RiskReportUtilsRisk from '@/components/Pages/Risks/RiskReports/RiskReportUtilsRisk';
import RiskReportUtilsErm from '@/components/Pages/Risks/RiskReports/RiskReportUtilsErm';
import { filterKeysMap, filtersIdentifierMap, filterOptionsErm, getOptionsFromScoring, prepareReportOptions, getFiltersFromReportOptions, wrongCustomFields } from '@/components/Pages/Risks/RiskReports/RiskReportUtils';
import { checkERMByProjectId } from '@/utils/risks/check';
import { getNodesForSelectBox } from '@/utils/format/Compliance';
import { MODULES } from '@/constants/modules';
export default {
  name: 'RiskReportsRisks',
  introduction: 'Main Risk Report page component',
  description: 'Complex page component',
  token: '<risk-report />',
  components: {
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RiskGridDisplay: RiskGridDisplay,
    RiskReportGrid: RiskReportGrid,
    RiskReportsFilters: RiskReportsFilters,
    RiskReportsFiltersErm: RiskReportsFiltersErm
  },
  mixins: [CommonAssetsMixin, RiskUniversalMixin, RiskGridMixin],
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Id of the Risk Project'
    },
    savedReportOptions: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      allRisks: [],
      risks: [],
      riskStateMatrix: [],
      includeUnderlyingRisks: false,
      comparePeriod: null,
      comparePeriodId: {},
      comparePeriodByConsequence: {},
      previousPeriods: [],
      isLoaded: false,
      isReportLoading: false,
      unSorted: true,
      threatSelected: [],
      levelOfThreatSelected: [],
      vulnerabilitySelected: [],
      systems: [],
      linkedSystemsSelected: [],
      organisationGroups: [],
      responsibleSelected: [],
      managementLevels: [],
      tagsSelected: [],
      riskApprovalEnabled: false,
      processLibraryNodes: [],
      complianceNodes: [],
      incidents: [],
      nothingToShowMessageVisible: false,
      scoring: null,
      filters: {},
      gridData: [],
      riskTypes: [],
      parentRisksByPeriod: {},
      riskGridUpdateKey: 1,
      reportOptions: {},
      previousPositionsState: [],
      translate: getTranslate['RiskReportsRisks']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), mapState(useActivityTypesStore, {
    activityTypes: 'riskActivityTypes'
  })), {}, {
    riskDescription: function riskDescription() {
      return this.translate.specifyYourReportByChoosingAPeriodAndACon;
    },
    isErm: function isErm() {
      return checkERMByProjectId(this.projectId, this.currentUser);
    },
    renderedPeriods: function renderedPeriods() {
      if (!this.periods) {
        return [];
      }
      var periodPredicate = function periodPredicate(item) {
        return item.status !== RiskStatus.DRAFT;
      };
      if (this.isErm) {
        periodPredicate = function periodPredicate(item) {
          return item.status !== ErmRiskStatus.NEW && item.status !== ErmRiskStatus.EDITING;
        };
      }
      return this.periods.filter(periodPredicate).map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          label: item.title || item.version,
          value: item.id || item.id
        });
      });
    },
    riskUsers: function riskUsers() {
      var _this = this;
      return this.activeUsers.filter(function (user) {
        var _user$projects;
        if (user.is_admin) {
          return true;
        }
        if (_this.isErm && user.level_risk > UserLevels.NONE) {
          return true;
        }
        return (user === null || user === void 0 || (_user$projects = user.projects) === null || _user$projects === void 0 ? void 0 : _user$projects[MODULES.RISK].filter(function (item) {
          return item.id === +_this.projectId && item.access_level > UserLevels.NONE;
        }).length) > 0;
      });
    },
    riskGridData: function riskGridData() {
      // TODO: Fix riskStateMatrix
      if (!this.currentPeriod) {
        return null;
      }
      var matrix = this.getMatrix([]);
      matrix.grid = this.riskStateMatrix;
      return matrix;
    },
    currentPeriod: function currentPeriod() {
      var _this2 = this;
      return this.renderedPeriods.find(function (item) {
        return item.value === _this2.filters.periodId;
      }) || this.renderedPeriods[0];
    },
    isCurrentPeriodSelected: function isCurrentPeriodSelected() {
      var _this$currentPeriod;
      return !this.isErm ? (_this$currentPeriod = this.currentPeriod) === null || _this$currentPeriod === void 0 ? void 0 : _this$currentPeriod.isCurrent : this.scoring.id === this.currentPeriod.id;
    },
    consequences: function consequences() {
      if (!this.currentPeriod || !this.currentPeriod.consequenceRows) {
        return [];
      }
      return this.currentPeriod.consequenceRows.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          value: item.id
        });
      });
    },
    includeUnderlyingRisksOptions: function includeUnderlyingRisksOptions() {
      return [{
        name: 'Include',
        state: this.filters.includeUnderlyingRisks
      }];
    },
    organisationGroupsSelect: function organisationGroupsSelect() {
      var orgGroup = this.organisationGroups.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          value: item.id + ''
        });
      });
      return [{
        label: this.$trans('All'),
        value: 'all'
      }].concat(orgGroup);
    },
    utils: function utils() {
      // Utils according to project Id;
      return this.isErm ? RiskReportUtilsErm : RiskReportUtilsRisk;
    },
    filterOptions: function filterOptions() {
      if (this.isErm) {
        var obj = _objectSpread({
          periods: this.renderedPeriods,
          impact: this.currentPeriod && getOptionsFromScoring(this.currentPeriod, 'impact'),
          likelihood: this.currentPeriod && getOptionsFromScoring(this.currentPeriod, 'likelihood'),
          managementLevels: this.managementLevels,
          tags: this.tags,
          users: this.riskUsers,
          organisationsList: this.organisations,
          organisationGroups: this.organisationGroupsSelect,
          riskTypes: this.riskTypes
        }, filterOptionsErm());
        if (!this.riskApprovalEnabled) {
          delete obj.approval;
        }
        return obj;
      }
      return {
        periods: this.renderedPeriods,
        riskAssessments: this.consequences,
        threats: this.threats,
        levelOfThreats: this.levelOfThreatsOptions,
        vulnerabilities: this.vulnerabilities,
        systems: this.systems,
        users: this.riskUsers,
        tags: this.tags,
        organisationsList: this.organisations,
        includeUnderlyingRisks: this.filters.includeUnderlyingRisksOptions,
        parentRisks: this.parentRisksList,
        activityTypes: this.activityTypes
      };
    },
    parentRisksList: function parentRisksList() {
      var _this$filters;
      return this.parentRisksByPeriod[(_this$filters = this.filters) === null || _this$filters === void 0 ? void 0 : _this$filters.periodId] || [];
    },
    levelOfThreatsOptions: function levelOfThreatsOptions() {
      if (this.isErm || !this.currentPeriod || !this.currentPeriod.threatLevels) {
        return [];
      }
      return this.currentPeriod.threatLevels.map(function (item) {
        return {
          label: item.title,
          id: item.id
        };
      });
    },
    levelOfThreatsFull: function levelOfThreatsFull() {
      if (this.isErm) return [];
      var threatLevels = this.periods.reduce(function (acc, curr) {
        acc.push.apply(acc, _toConsumableArray(curr.threatLevels));
        return acc;
      }, []);
      return threatLevels.map(function (item) {
        return {
          label: item.title,
          id: item.id
        };
      });
    },
    probabilitiesByPeriod: function probabilitiesByPeriod() {
      if (this.isErm || !this.currentPeriod) return [];
      return this.currentPeriod.probabilityRows.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          value: item.id
        });
      });
    },
    riskAssessmentsMatchLabels: function riskAssessmentsMatchLabels() {
      // table to get row label - [RiskAssessmentid][PeriodId] = Label
      return this.periods.reduce(function (acc, curr) {
        if (curr.consequenceRows) {
          acc[curr.id] = curr.consequenceRows.reduce(function (obj, current) {
            obj[current.id] = current.label;
            return obj;
          }, {});
        }
        return acc;
      }, {});
    },
    inherentRisksEnabled: function inherentRisksEnabled() {
      return this.currentRiskProject.inherentRisksEnabled;
    },
    localStorageKey: function localStorageKey() {
      return "risk".concat(this.projectId, "_report_filters-").concat(this.matrixInverted);
    }
  }),
  watch: {
    savedReportOptions: function savedReportOptions() {
      if (this.savedReportOptions) {
        this.reportOptions = _objectSpread({}, this.savedReportOptions);
        this.fetchReport(this.reportOptions);
      }
    },
    reportOptions: function reportOptions() {
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.reportOptions));
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    handleGetProcessLibraryNodes: function handleGetProcessLibraryNodes() {
      var _this3 = this;
      var processTreeId;
      return new Promise(function (resolve, reject) {
        _this3.getProcessLibraryTree().then(function (response) {
          if (response[0].id !== null) {
            processTreeId = response[0].id;
            return _this3.getNodes(response[0].id);
          } else {
            resolve();
          }
        }).then(function (rootNode) {
          _this3.processLibraryNodes = [];
          Utils.pushChildrenToParent(rootNode, _this3.processLibraryNodes);
          _this3.processLibraryNodes = _this3.processLibraryNodes.map(function (node) {
            return _objectSpread(_objectSpread({}, node), {}, {
              processTreeId: processTreeId
            });
          });
          resolve();
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    getProcessLibraryTree: function getProcessLibraryTree() {
      return new Promise(function (resolve, reject) {
        DataAPI.getAllTrees().then(function (response) {
          return resolve(response);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    getNodes: function getNodes(processLibraryTreeId) {
      return new Promise(function (resolve, reject) {
        DataAPI.getNodesByProcessTreeId(processLibraryTreeId).then(function (response) {
          return resolve(response);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    getComplianceNodes: function getComplianceNodes() {
      var _this4 = this;
      return getNodesForSelectBox().then(function (nodes) {
        _this4.complianceNodes = nodes;
      });
    },
    getIncidents: function getIncidents() {
      var _this5 = this;
      return DataAPI.getIncidents().then(function (_ref) {
        var list = _ref.list;
        return _this5.incidents = list;
      }).catch(function () {});
    },
    fetchSavedReport: function fetchSavedReport(savedOptions) {
      this.reportOptions = _objectSpread({}, savedOptions);
      this.fetchReport(this.reportOptions);
    },
    fetchReport: function fetchReport(reportOptions) {
      this.updateFilters(reportOptions);
      this.setDefaultComparePeriod();
      this.setPreviousPeriods();
      return this.createReport();
    },
    updateFilters: function updateFilters(reportOptions) {
      if (this.isErm) {
        var likelihood = this.filterOptions.likelihood ? this.filterOptions.likelihood[0] : getOptionsFromScoring(this.scoring, 'likelihood')[0];
        var impact = this.filterOptions.impact ? this.filterOptions.impact[0] : getOptionsFromScoring(this.currentPeriod, 'impact')[0];
        this.filters = {
          periodId: this.scoring.id,
          yAxis: likelihood ? likelihood.value : '',
          xAxis: impact ? impact.value : '',
          organisationGroups: 'all',
          status: 'all',
          updated: 'all',
          topRisks: 'all',
          managementLevels: this.managementLevels,
          tags: []
        };
      } else {
        this.filters = {
          periodId: reportOptions !== null && reportOptions !== void 0 && reportOptions.periodId && this.periods.find(function (item) {
            return item.id === reportOptions.periodId;
          }) ? reportOptions.periodId : this.periods.find(function (item) {
            return item.isCurrent;
          }).id,
          includeUnderlyingRisks: this.includeUnderlyingRisks,
          tags: []
        };
      }
      var _getFiltersFromReport = getFiltersFromReportOptions(reportOptions, this.filterOptions),
        _getFiltersFromReport2 = _getFiltersFromReport.filters,
        filters = _getFiltersFromReport2 === void 0 ? {} : _getFiltersFromReport2,
        _getFiltersFromReport3 = _getFiltersFromReport.matrix,
        matrix = _getFiltersFromReport3 === void 0 ? [] : _getFiltersFromReport3;
      this.filters = _objectSpread(_objectSpread({}, this.filters), filters);
      this.riskStateMatrix = matrix;
    },
    getData: function getData() {
      var _this6 = this;
      var promises = [this.getRiskProjects(), this.getAssets(), this.getControlsFromData(), this.getInitiatives(), this.handleGetProcessLibraryNodes(), this.getComplianceNodes(), this.getIncidents()];
      if (this.isErm) {
        promises = promises.concat([this.getScorings(), this.getCurrentScoring(), this.getRiskManagementLevels(), this.getRiskTypes(), this.getOrganisationGroups()]);
      } else {
        promises.push(this.getParentRisksList());
      }
      return Promise.all(promises).then(function () {
        if (_this6.isErm) {
          _this6.currentRiskProject = _this6.riskProjects.find(function (item) {
            return +item.id === +_this6.projectId;
          });
          _this6.riskApprovalEnabled = !!_this6.currentRiskProject.riskApproval;
        } else {
          _this6.prepareData();
          _this6.afterPrepareData();
        }
        if (_this6.savedReportOptions) {
          _this6.reportOptions = _objectSpread({}, _this6.savedReportOptions);
        } else {
          _this6.reportOptions = JSON.parse(localStorage.getItem(_this6.localStorageKey)) || {};
        }
        return _this6.fetchReport(_this6.reportOptions);
      }).then(function () {
        _this6.isLoaded = true;
      });
    },
    getScorings: function getScorings() {
      var _this7 = this;
      return getAllScorings().then(function (periods) {
        _this7.periods = periods.list.reverse();
      });
    },
    getCurrentScoring: function getCurrentScoring() {
      var _this8 = this;
      return _getCurrentScoring().then(function (scoring) {
        _this8.scoring = scoring;
        _this8.filters.periodId = _this8.scoring.id;
      });
    },
    getRiskManagementLevels: function getRiskManagementLevels() {
      var _this9 = this;
      return RiskApi.getRisksManagementLevels().then(function (_ref2) {
        var list = _ref2.list;
        return _this9.managementLevels = list;
      });
    },
    getRiskTypes: function getRiskTypes() {
      var _this10 = this;
      return RiskApi.getRiskTypes().then(function (_ref3) {
        var list = _ref3.list;
        return _this10.riskTypes = list;
      });
    },
    getOrganisationGroups: function getOrganisationGroups() {
      var _this11 = this;
      return DataAPI.getOrganisationalGroups().then(function (_ref4) {
        var list = _ref4.list;
        return _this11.organisationGroups = list;
      });
    },
    getRiskReports: function getRiskReports(consequenceId, comparablePeriod) {
      var _this12 = this;
      this.allRisks = [];
      if (!consequenceId) {
        this.risks = [];
      }
      this.isReportLoading = true;
      var params = this.createParams(comparablePeriod);
      return RiskApi.getReports(params).then(function (_ref5) {
        var list = _ref5.list;
        _this12.isReportLoading = false;
        _this12.allRisks = _toConsumableArray(list);
        if (_this12.isErm) {
          _this12.risks = _this12.extendRiskData(list, _this12.filters);
        } else if (consequenceId) {
          if (Array.isArray(consequenceId)) {
            consequenceId.forEach(function (consequenceIdItem) {
              return _this12.changePreviousRiskDataForConsequence(list, consequenceIdItem);
            });
            return;
          }
          return _this12.changePreviousRiskDataForConsequence(list, consequenceId);
        } else {
          _this12.risks = _this12.extendRiskData(_this12.filterByPeriod(list), _this12.consequences, _this12.riskAssessmentsMatchLabels);
        }
        _this12.risks = _this12.filterRisks(_this12.risks);
      }).catch(function (e) {
        throw e;
      });
    },
    getParentRisksList: function getParentRisksList() {
      var _this13 = this;
      return RiskApi.getParentRisksList(this.projectId).then(function (response) {
        _this13.parentRisksByPeriod = response;
      });
    },
    filterByPeriod: function filterByPeriod(data) {
      var _this14 = this;
      return data.filter(function (item) {
        return item.projectPeriodId === _this14.currentPeriod.id;
      });
    },
    filterRisks: function filterRisks(data) {
      var _this15 = this;
      // Filter by Consequence parameter on UI
      var result = this.isErm ? data : data.filter(function (item) {
        var consequense = item.residualConsequenceRowScores.find(function (item) {
          return _this15.filters.riskAssessments.find(function (it) {
            return it.id === item.rowId;
          }) && item.column.value > 0;
        });
        var probability = item.residualProbabilityRowScores.find(function (item) {
          return _this15.filters.riskAssessments.find(function (it) {
            return it.id === item.rowId;
          }) && item.column.value > 0;
        });
        return consequense && probability;
      });
      // Filter by selected cells in the matrix
      var selectedMatrix = this.findSelectedMatrix();
      if (selectedMatrix.length) {
        result = this.utils.filterRiskBySelectedMatrix(result, selectedMatrix, this.filters, this.matrixInverted);
      }
      return result;
    },
    findSelectedMatrix: function findSelectedMatrix() {
      var result = [];
      this.riskStateMatrix.map(function (row, rowIndex) {
        row.map(function (cell, cellIndex) {
          if (cell && cell.length && cell[0].isActive) {
            if (!result.find(function (item) {
              return item.x === cellIndex && item.y === rowIndex;
            })) {
              result.push({
                x: cellIndex,
                y: rowIndex
              });
            }
          }
        });
      });
      result.map(function (item) {
        ++item.x;
        ++item.y;
      });
      return result;
    },
    extendRiskData: function extendRiskData(data) {
      var _this16 = this;
      for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        rest[_key - 1] = arguments[_key];
      }
      var result = _toConsumableArray(data);
      result.map(function (item) {
        var _this16$utils;
        item._scores = {};
        (_this16$utils = _this16.utils).extendRiskData.apply(_this16$utils, [item].concat(rest));
        if (_this16.matrixInverted) {
          var _this16$utils2;
          (_this16$utils2 = _this16.utils).invertRiskData.apply(_this16$utils2, [item].concat(rest));
        }
      });
      return result;
    },
    createParams: function createParams(comparablePeriodId) {
      var _this$filters$parentR,
        _this17 = this;
      var _this$filters$customF = this.filters.customFields,
        customFields = _this$filters$customF === void 0 ? {} : _this$filters$customF;
      var params = {
        filters: {
          projectId: this.projectId
        },
        custom_fields: {},
        data: ['customFields', 'comparePeriod', 'linkedActivities', 'linkedAssets']
      };
      if ((_this$filters$parentR = this.filters.parentRisks) !== null && _this$filters$parentR !== void 0 && _this$filters$parentR.length && !this.filters.includeUnderlyingRisks) {
        this.filters.includeUnderlyingRisks = true;
      }
      if (this.filters.includeUnderlyingRisks) {
        params.filters['underlyingrisks'] = 'include';
      }
      if (comparablePeriodId || this.comparePeriodId) {
        params.filters['comparePeriodId'] = comparablePeriodId || this.comparePeriodId;
      }
      if (this.isErm) {
        params.filters['scoringId'] = this.currentPeriod.id;
      } else {
        params.filters['projectPeriodId'] = this.currentPeriod.id;
      }
      Object.entries(this.filters).forEach(function (_ref6) {
        var _ref7 = _slicedToArray(_ref6, 2),
          key = _ref7[0],
          value = _ref7[1];
        var filterKey = filterKeysMap[key] || key;
        var exceptions = ['xAxis', 'yAxis', 'periodId', 'includeUnderlyingRisks', 'customFields'].indexOf(filterKey) !== -1;
        if (exceptions || value === 'all') return;
        if (value && Array.isArray(value)) {
          value.forEach(function (item) {
            if (!params.filters[filterKey]) {
              params.filters[filterKey] = [];
            }
            params.filters[filterKey].push(item[filtersIdentifierMap[key] || 'id']);
          });
        } else if (value) {
          params.filters[filterKey] = value;
        }
      });
      Object.entries(customFields).forEach(function (_ref8) {
        var _ref9 = _slicedToArray(_ref8, 2),
          key = _ref9[0],
          value = _ref9[1];
        if (value === 'all') return;
        if (wrongCustomFields[key] && _this17.isErm) {
          var customField = _this17.currentPeriod.fields.find(function (item) {
            return item.label === key;
          });
          var index = Object.keys(customField.options).find(function (item) {
            return customField.options[item].id == value;
          });
          params.filters[wrongCustomFields[key]] = index;
        } else {
          params.custom_fields[key] = value;
        }
      });
      return params;
    },
    handleCreateReport: function handleCreateReport(filters, riskAssessmentMapping) {
      var _this18 = this;
      this.filters = filters;
      this.resetReportSavedOptions();
      this.setPreviousPeriods();
      if (!this.isErm) {
        this.adjustComparedPeriodWithPreviousReport(riskAssessmentMapping);
      }
      this.setDefaultComparePeriod();
      this.createReport().then(function () {
        return _this18.getAdditionalReportsByComparablePeriods();
      }).then(function () {
        return _this18.reportOptions = prepareReportOptions(filters, _this18.riskStateMatrix);
      });
    },
    getAdditionalReportsByComparablePeriods: function getAdditionalReportsByComparablePeriods() {
      var _this19 = this;
      if (this.isErm) return Promise.resolve();
      var comparableConsequenceMap = {};
      this.filters.riskAssessments.forEach(function (riskAssessment) {
        var _this19$comparePeriod;
        var comparablePeriodId = (_this19$comparePeriod = _this19.comparePeriodByConsequence[riskAssessment.id]) === null || _this19$comparePeriod === void 0 ? void 0 : _this19$comparePeriod.id;
        if (comparablePeriodId && +comparablePeriodId !== +_this19.comparePeriodId) {
          comparableConsequenceMap[comparablePeriodId] = [].concat(_toConsumableArray(comparableConsequenceMap[comparablePeriodId] || []), [riskAssessment.id]);
        }
      });
      return Promise.all(Object.entries(comparableConsequenceMap).map(function (_ref10) {
        var _ref11 = _slicedToArray(_ref10, 2),
          comparablePeriodId = _ref11[0],
          consequenceId = _ref11[1];
        return _this19.getRiskReports(consequenceId, comparablePeriodId);
      }));
    },
    createReport: function createReport() {
      var _this20 = this;
      var isUpdated = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      if (!isUpdated) return Promise.resolve();
      var isValid = this.isErm ? this.filters.yAxis && this.filters.xAxis : this.filters.riskAssessments && this.filters.riskAssessments.length;
      if (!isValid) return Promise.resolve();
      this.nothingToShowMessageVisible = false;
      this.gridData = this.createGridData();
      return this.getRiskReports().then(function () {
        return _this20.nothingToShowMessageVisible = !_this20.risks.length;
      });
    },
    setDefaultComparePeriod: function setDefaultComparePeriod() {
      var _this21 = this;
      if (this.currentPeriod.previousId !== undefined && this.currentPeriod.previousId !== null) {
        this.comparePeriodId = this.currentPeriod.previousId;
        var defaultComparablePeriod = this.renderedPeriods.find(function (period) {
          return period.id === _this21.comparePeriodId;
        });
        if (this.filters.riskAssessments) {
          this.filters.riskAssessments.forEach(function (row) {
            _this21.comparePeriodByConsequence[row.id] = _this21.comparePeriodByConsequence[row.id] || defaultComparablePeriod;
          });
        } else if (this.isErm) {
          var previousPeriod = this.comparePeriodByConsequence['erm'] && this.previousPeriods.find(function (period) {
            return period.id === _this21.comparePeriodByConsequence['erm'].id;
          }) || defaultComparablePeriod;
          this.comparePeriodByConsequence['erm'] = previousPeriod;
        }
      }
    },
    adjustComparedPeriodWithPreviousReport: function adjustComparedPeriodWithPreviousReport(riskAssessmentsIdsMap) {
      var _this22 = this;
      var comparePeriodByConsequence = {};
      this.filters.riskAssessments.forEach(function (row, index) {
        var _this22$comparePeriod;
        var consequenceId = row.id;
        if (_this22.previousPositionsState[index] && riskAssessmentsIdsMap) {
          consequenceId = riskAssessmentsIdsMap && riskAssessmentsIdsMap[row.id];
        }
        var comparablePeriodId = (_this22$comparePeriod = _this22.comparePeriodByConsequence[consequenceId]) === null || _this22$comparePeriod === void 0 ? void 0 : _this22$comparePeriod.id;
        var comparablePeriod = comparablePeriodId && _this22.previousPeriods.find(function (period) {
          return period.id === comparablePeriodId;
        });
        comparePeriodByConsequence[row.id] = comparablePeriod;
      });
      this.comparePeriodByConsequence = _objectSpread({}, comparePeriodByConsequence);
    },
    setPreviousPeriods: function setPreviousPeriods() {
      var _this23 = this;
      if (+this.currentPeriod.id === +this.renderedPeriods[this.renderedPeriods.length - 1].id) {
        this.previousPeriods = [];
        return;
      }
      var index = this.renderedPeriods.findIndex(function (period) {
        return +period.id === +_this23.currentPeriod.previousId;
      });
      this.previousPeriods = this.renderedPeriods.slice(index);
    },
    onStateChanged: function onStateChanged(e) {
      this.riskStateMatrix = _toConsumableArray(e);
    },
    onPeriodChanged: function onPeriodChanged(_ref12) {
      var consequenceId = _ref12.consequenceId,
        event = _ref12.event;
      this.comparePeriod = this.renderedPeriods.filter(function (item) {
        return item.id === event;
      });
      if (this.comparePeriod.length > 0) {
        this.comparePeriod = this.comparePeriod[0];
        this.comparePeriodId = this.comparePeriod.id;
        this.comparePeriodByConsequence[consequenceId] = this.comparePeriod;
      }
      this.comparePeriodByConsequence = _objectSpread({}, this.comparePeriodByConsequence);
      this.getRiskReports(consequenceId);
    },
    changePreviousRiskDataForConsequence: function changePreviousRiskDataForConsequence(data, consequenceId) {
      var _this24 = this;
      var result = _toConsumableArray(data);
      result.map(function (item) {
        var risk = _this24.risks.find(function (risk) {
          return risk.id === item.id;
        });
        if (!risk) return;
        var foundConsequence = item[RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES].find(function (item) {
          return item.rowId === consequenceId;
        });
        var foundProbability = item[RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES].find(function (item) {
          return item.rowId === consequenceId;
        });
        risk._scores[consequenceId].scoresPrevious = _this24.utils.getPreviousScores(item, _this24.riskAssessmentsMatchLabels, foundConsequence, foundProbability);
      });
      // To update arrows on the matrix need to update risks array
      this.risks = _toConsumableArray(this.risks);
    },
    resetReportSavedOptions: function resetReportSavedOptions() {
      if (this.savedReportOptions) {
        this.$router.replace({
          query: {}
        });
      }
    },
    handleResetFilters: function handleResetFilters() {
      this.reportOptions = {};
      this.updateFilters();
      this.riskStateMatrix = [];
      this.riskGridUpdateKey++;
    },
    handleChangePeriod: function handleChangePeriod(key, filters) {
      var axisValue = filters[key];
      var periodId = filters.periodId;
      this.filters = _objectSpread(_objectSpread({}, filters), {}, _defineProperty({
        periodId: periodId
      }, key, axisValue));
    },
    onUpdateFromPreviewMode: function onUpdateFromPreviewMode(updatedData) {
      var updatedRiskIdx = this.risks.findIndex(function (risk) {
        return risk.id === updatedData.id;
      });
      if (updatedRiskIdx === -1) return;
      this.risks[updatedRiskIdx] = _objectSpread(_objectSpread({}, this.risks[updatedRiskIdx]), {}, {
        displayHeadline: updatedData.displayHeadline,
        description: updatedData.description,
        organisationIds: updatedData.organisationIds,
        tagIds: updatedData.tagIds,
        links: updatedData.links,
        approved: updatedData.approved,
        approvalHistory: updatedData.approvalHistory,
        gridStatus: updatedData.gridStatus
      });
    },
    createGridData: function createGridData() {
      var gridDataArray = this.utils.createGridData(this.filters, this.currentPeriod);
      if (!this.matrixInverted) return gridDataArray;
      return gridDataArray.map(function (gridData) {
        return {
          label: gridData.label,
          xLabel: gridData.yLabel,
          yLabel: gridData.xLabel,
          gridSizeX: gridData.gridSizeY,
          gridSizeY: gridData.gridSizeX,
          id: gridData.id
        };
      });
    },
    togglePreviousPosition: function togglePreviousPosition(_ref13) {
      var event = _ref13.event,
        index = _ref13.index;
      this.previousPositionsState[index] = event;
    }
  }
};