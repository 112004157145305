// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip__arrow[data-v-14b17ebe] {
  overflow: hidden;
  position: absolute;
}
.tooltip__arrow[data-v-14b17ebe]:after {
  content: "";
  position: absolute;
  top: 4px;
  width: 17px;
  height: 17px;
  transform: rotate(45deg);
}
.tooltip--top[data-v-14b17ebe],
.tooltip--bottom[data-v-14b17ebe] {
  right: 0;
}
.tooltip--top .tooltip__arrow[data-v-14b17ebe],
.tooltip--bottom .tooltip__arrow[data-v-14b17ebe] {
  height: 11px;
  width: 17px;
}
.tooltip--top .tooltip__arrow[data-v-14b17ebe]:after,
.tooltip--bottom .tooltip__arrow[data-v-14b17ebe]:after {
  top: 4px;
}
.tooltip--top[data-v-14b17ebe] {
  bottom: 100%;
  padding-bottom: 9px;
}
.tooltip--top .tooltip__arrow[data-v-14b17ebe] {
  bottom: 0px;
  right: 24px;
  transform: rotate(180deg);
}
.tooltip--bottom[data-v-14b17ebe] {
  top: 100%;
  padding-top: 9px;
}
.tooltip--bottom .tooltip__arrow[data-v-14b17ebe] {
  top: 0px;
  right: 24px;
}
.tooltip--right[data-v-14b17ebe],
.tooltip--left[data-v-14b17ebe] {
  top: -23px;
}
.tooltip--right .tooltip__arrow[data-v-14b17ebe],
.tooltip--left .tooltip__arrow[data-v-14b17ebe] {
  top: 24px;
  height: 24px;
  width: 12px;
}
.tooltip--left[data-v-14b17ebe] {
  right: 100%;
  padding-right: 10px;
}
.tooltip--left .tooltip__arrow[data-v-14b17ebe] {
  right: -1px;
}
.tooltip--left .tooltip__arrow[data-v-14b17ebe]:after {
  left: -9px;
  top: 4px;
}
.tooltip--right[data-v-14b17ebe] {
  left: 100%;
  padding-left: 10px;
}
.tooltip--right .tooltip__arrow[data-v-14b17ebe] {
  left: -1px;
}
.tooltip--right .tooltip__arrow[data-v-14b17ebe]:after {
  right: -9px;
}
.tooltip--primary .tooltip__inner[data-v-14b17ebe] {
  box-shadow: 0px 8px 8px rgba(190, 190, 190, 0.2), 8px 0px 8px rgba(33, 130, 253, 0.1), -8px 0px 8px rgba(33, 130, 253, 0.1);
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(0 161 224 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(0 172 239 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.tooltip--primary .tooltip__arrow[data-v-14b17ebe]:after {
  border-width: 1px;
  border-color: rgba(190, 190, 190, 0.2);
  --tw-bg-opacity: 1;
  background-color: rgb(0 172 239 / var(--tw-bg-opacity));
}
.tooltip--secondary .tooltip__inner[data-v-14b17ebe] {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(190 190 190 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(68 68 68 / var(--tw-text-opacity));
}
.tooltip--secondary .tooltip__arrow[data-v-14b17ebe]:after {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(190 190 190 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
