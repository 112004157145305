import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import { DataFlowFields } from '@/constants/compliance/WizardFlow';
export default {
  name: 'DataFlowWizardStepDataType',
  components: {
    RismaSelect: RismaSelect
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    selectedOptions: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      },
      note: 'selected options for all steps'
    },
    errorMessage: {
      type: Object,
      default: function _default() {
        return {
          field: null,
          text: ''
        };
      }
    }
  },
  emits: ['update:selected'],
  data: function data() {
    return {
      currentSelected: [],
      DataFlowFields: DataFlowFields,
      translate: {
        chooseDataTypes: this.$trans('Choose data types'),
        dataType: this.$trans('Data type'),
        addedDataTypes: this.$trans('Added data types')
      }
    };
  }
};