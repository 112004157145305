function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import i18n from '@/i18n';
import cloneDeep from 'lodash/cloneDeep';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
import { addOption as _addOption } from '@/api/compliance/questions';
import HelpText from '@/components/Atoms/HelpText/HelpText';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import NewChoice from '@/components/Molecules/Questionnaire/NewChoice';
import SingleAssetChoice from '@/components/Molecules/Questionnaire/SingleAssetChoice';
import SingleChoice from '@/components/Molecules/Questionnaire/SingleChoice';
import MultiChoice from '@/components/Molecules/Questionnaire/MultiChoice';
import ProcessLibraryChoice from '@/components/Molecules/Questionnaire/ProcessLibraryChoice';
import AttachmentChoice from '@/components/Molecules/Questionnaire/AttachmentChoice';
import FreeTextChoice from '@/components/Molecules/Questionnaire/FreeTextChoice';
import InformationAssetsChoice from '@/components/Molecules/Questionnaire/InformationAssetsChoice';
import FrameworkOverall from '@/components/Atoms/FrameworkOverall';
import AiClarifySender from '@/components/Organisms/AiAssistant/AiClarifySender';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
import { DateFormats } from '@/constants/DateFormats';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { formatTimeUtc } from '@/utils/date';
import { enableAIForQuestionnairePage } from '@/utils/aiAssistant';
export default {
  name: 'Question',
  introduction: 'Question is common sketch from various questions types',
  description: 'Create new question based on props.',
  nodeElements: {},
  token: "\n<question :question=\"question.question\" :information-assets=\"question.informationAssets\" />\n// Look at KitchenSink for properties\n",
  components: {
    HelpText: HelpText,
    NewChoice: NewChoice,
    SingleAssetChoice: SingleAssetChoice,
    SingleChoice: SingleChoice,
    MultiChoice: MultiChoice,
    AttachmentChoice: AttachmentChoice,
    FreeTextChoice: FreeTextChoice,
    InformationAssetsChoice: InformationAssetsChoice,
    ProcessLibraryChoice: ProcessLibraryChoice,
    DateTimePicker: DateTimePicker,
    FrameworkOverall: FrameworkOverall,
    AiClarifySender: AiClarifySender
  },
  props: {
    question: {
      type: Object,
      required: true,
      note: 'Properties for question is id, question-number, headline, questiontype, help, question, informationAssets.'
    },
    informationAssets: {
      type: Object,
      required: true,
      note: 'Properties for answers depends on type of question.'
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of custom information assets activity types'
    },
    showHeadline: {
      type: Boolean,
      required: true,
      note: 'Should we add a RismaTitle with the question headline'
    },
    nodeElement: {
      required: false,
      type: Object,
      note: 'A list of all nodes',
      default: function _default() {
        return {};
      }
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Questionn is be read-only'
    },
    myInformationAssetsByType: {
      type: Object,
      default: function _default() {
        return {};
      },
      required: false
    }
  },
  emits: ['forceUpdate', 'newAsset', 'update'],
  data: function data() {
    return {
      t: {
        question: i18n.t('Question'),
        answers: i18n.t('Answers')
      },
      toggleQuestion: true,
      AssetsTypes: AssetsTypes,
      QuestionTypes: QuestionTypes()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    isSystemsEnabled: 'isSystemsEnabled',
    isDataProcessorsEnabled: 'isDataProcessorsEnabled',
    isDataControllersEnabled: 'isDataControllersEnabled',
    aiAssistantEnabled: 'aiAssistantEnabled'
  })), {}, {
    hasOverallFramework: function hasOverallFramework() {
      return this.getSettingValue('feature.overall.extended_framework');
    },
    currentQuestion: function currentQuestion() {
      return cloneDeep(this.question);
    },
    currentComplianceProject: function currentComplianceProject() {
      var _this$currentQuestion;
      return this.complianceProjectById((_this$currentQuestion = this.currentQuestion.node) === null || _this$currentQuestion === void 0 ? void 0 : _this$currentQuestion.compliance_projects_id);
    },
    projectTitle: function projectTitle() {
      var _this$currentComplian;
      return (_this$currentComplian = this.currentComplianceProject) === null || _this$currentComplian === void 0 ? void 0 : _this$currentComplian.title;
    },
    showAiClarifySender: function showAiClarifySender() {
      var _this$currentComplian2;
      if (!this.aiAssistantEnabled || !this.currentComplianceProject) return false;
      return enableAIForQuestionnairePage((_this$currentComplian2 = this.currentComplianceProject) === null || _this$currentComplian2 === void 0 ? void 0 : _this$currentComplian2.type);
    },
    filteredCustomIA: function filteredCustomIA() {
      var _this$question,
        _this$informationAsse,
        _this = this;
      if (!((_this$question = this.question) !== null && _this$question !== void 0 && (_this$question = _this$question.questionobject) !== null && _this$question !== void 0 && _this$question.informationAssetActivityType)) return [];
      return ((_this$informationAsse = this.informationAssets) === null || _this$informationAsse === void 0 ? void 0 : _this$informationAsse.custom.filter(function (ia) {
        return _this.question.questionobject.informationAssetActivityType === ia.activityTypeId;
      })) || [];
    },
    myFilteredCustomIA: function myFilteredCustomIA() {
      var _this$question2,
        _this$myInformationAs,
        _this2 = this;
      if (!((_this$question2 = this.question) !== null && _this$question2 !== void 0 && (_this$question2 = _this$question2.questionobject) !== null && _this$question2 !== void 0 && _this$question2.informationAssetActivityType)) return [];
      return ((_this$myInformationAs = this.myInformationAssetsByType) === null || _this$myInformationAs === void 0 ? void 0 : _this$myInformationAs.informationAssets.filter(function (ia) {
        return _this2.question.questionobject.informationAssetActivityType === ia.activityTypeId;
      })) || [];
    },
    includeSubprocessors: function includeSubprocessors() {
      return this.getSettingValue('feature.ia_questions_include_subprocessors') !== 0;
    },
    showFullQuestionsList: function showFullQuestionsList() {
      return this.getSettingValue('feature.show_full_list_of_questionnaires');
    },
    myFilteredProcessorsIds: function myFilteredProcessorsIds() {
      var _this$myInformationAs2;
      return this.getFilteredProcessors((_this$myInformationAs2 = this.myInformationAssetsByType) === null || _this$myInformationAs2 === void 0 ? void 0 : _this$myInformationAs2.processors) || [];
    },
    filteredProcessors: function filteredProcessors() {
      var _this$informationAsse2;
      return this.getFilteredProcessors((_this$informationAsse2 = this.informationAssets) === null || _this$informationAsse2 === void 0 ? void 0 : _this$informationAsse2.processors);
    },
    informationAssetsWithFilteredProcessors: function informationAssetsWithFilteredProcessors() {
      return _objectSpread(_objectSpread({}, this.informationAssets), {}, {
        processors: this.filteredProcessors
      });
    },
    filteredProcessorsQuestion: function filteredProcessorsQuestion() {
      var _this$question4;
      if (this.includeSubprocessors) {
        var _this$question3;
        return ((_this$question3 = this.question) === null || _this$question3 === void 0 || (_this$question3 = _this$question3.informationAssets) === null || _this$question3 === void 0 ? void 0 : _this$question3.processors) || [];
      }
      return ((_this$question4 = this.question) === null || _this$question4 === void 0 || (_this$question4 = _this$question4.informationAssets) === null || _this$question4 === void 0 ? void 0 : _this$question4.processors.filter(function (processor) {
        var _processor$parents;
        return !((_processor$parents = processor.parents) !== null && _processor$parents !== void 0 && _processor$parents.length);
      })) || [];
    },
    filteredIAQuestion: function filteredIAQuestion() {
      return _objectSpread(_objectSpread({}, this.question.informationAssets), {}, {
        processors: this.filteredProcessorsQuestion
      });
    },
    selectedCustomIA: function selectedCustomIA() {
      var _this$question$inform;
      return ((_this$question$inform = this.question.informationAssets) === null || _this$question$inform === void 0 ? void 0 : _this$question$inform.custom.map(function (ia) {
        ia.name = ia.title;
        return ia;
      })) || [];
    },
    selectedSystems: function selectedSystems() {
      var _this$question5;
      return ((_this$question5 = this.question) === null || _this$question5 === void 0 || (_this$question5 = _this$question5.informationAssets) === null || _this$question5 === void 0 ? void 0 : _this$question5.systems) || [];
    },
    selectedControllers: function selectedControllers() {
      var _this$question6;
      return ((_this$question6 = this.question) === null || _this$question6 === void 0 || (_this$question6 = _this$question6.informationAssets) === null || _this$question6 === void 0 ? void 0 : _this$question6.controllers) || [];
    },
    dateFormat: function dateFormat() {
      return this.question.questiontype === this.QuestionTypes.date.id ? DateFormats.DATE_FORMAT : DateFormats.DATE_TIME_FORMAT;
    },
    dateTime: function dateTime() {
      return this.question.dateTime ? formatTimeUtc(this.question.dateTime, this.dateFormat) : '';
    },
    datePickerOptions: function datePickerOptions() {
      return {
        format: this.dateFormat,
        widgetPositioning: {
          vertical: 'bottom',
          horizontal: 'left'
        }
      };
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions(useComplianceProjectsStore, {
    complianceProjectById: 'getProjectById'
  })), {}, {
    getAnswers: function getAnswers(answer) {
      if (Object.keys(answer).length === 0) {
        this.currentQuestion.answers_options = {
          null: 'undefined'
        };
      } else {
        this.currentQuestion.answers_options = Object.assign({}, answer);
      }
      this.$emit('update', this.currentQuestion);
      Object.keys(answer).length === 0 ? this.question.answers_options = {} : null;
    },
    getSingleAnswers: function getSingleAnswers(event) {
      this.getAnswers(event);
      this.$emit('forceUpdate');
    },
    getFreeText: function getFreeText(freeText) {
      this.currentQuestion.freeText = freeText;
      this.$emit('update', this.currentQuestion);
    },
    getIA: function getIA(_ref) {
      var data = _ref.data,
        type = _ref.type;
      this.currentQuestion.informationAssets[type] = data;
      this.$emit('update', this.currentQuestion);
    },
    getFiles: function getFiles(files) {
      this.currentQuestion.files = files;
      this.$emit('update', this.currentQuestion);
      this.$emit('forceUpdate');
    },
    getDate: function getDate(date) {
      this.currentQuestion.dateTime = date;
      this.$emit('update', this.currentQuestion);
    },
    addOption: function addOption(optionLabel) {
      var _this3 = this;
      return _addOption(this.question.id, optionLabel).then(function (data) {
        var id = data.id,
          label = data.label;
        if (_this3.currentQuestion.questiontype === _this3.QuestionTypes.singleChoice.id || typeof _this3.currentQuestion.answers_options.length !== 'undefined') {
          _this3.currentQuestion.answers_options = {};
        }
        _this3.currentQuestion.options[id] = label;
        _this3.currentQuestion.optionDetails.push(data);
        _this3.currentQuestion.answers_options[id] = label;
        _this3.$forceUpdate();
        _this3.$emit('update', _this3.currentQuestion);
      });
    },
    getFilteredProcessors: function getFilteredProcessors(processors) {
      if (this.includeSubprocessors) {
        return processors || [];
      }
      return processors.filter(function (processor) {
        var _processor$parents2;
        return !((_processor$parents2 = processor.parents) !== null && _processor$parents2 !== void 0 && _processor$parents2.length);
      }) || [];
    }
  })
};