function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import { Colors } from '@/Colors';
import { clickOutside } from '@/utils/directives/clickOutside';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { TRAFFIC_LIGHT_MODULES } from '@/constants/modules';
export default {
  name: 'TrafficLightSelector',
  components: {
    RismaLabel: RismaLabel,
    TrafficLight: TrafficLight
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
      note: 'Preselected light. will default to the gray light'
    },
    lightType: {
      type: String,
      required: false,
      default: TRAFFIC_LIGHT_MODULES.default,
      note: 'Which type of light? company, gaps, trafficLights, incidents or default'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If traffic light is not editable'
    },
    lightWithAttribute: {
      required: false,
      type: Boolean,
      default: false,
      note: 'If extra information about an traffic light shows when the mouse moves over the element'
    },
    customTrafficLights: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    size: {
      type: String,
      default: 'default',
      note: 'the width and the height of the traffic light'
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    errorHelpText: {
      type: String,
      default: '',
      required: false,
      note: 'help text when input field is in error state'
    }
  },
  emits: ['update:modelValue'],
  data: function data() {
    return {
      selectedLight: null,
      colors: Colors,
      showSelector: false
    };
  },
  computed: {
    colorTitle: function colorTitle() {
      var _this$trafficLightsDa;
      return this.lightWithAttribute ? (_this$trafficLightsDa = this.trafficLightsData[this.selectedLight]) === null || _this$trafficLightsDa === void 0 ? void 0 : _this$trafficLightsDa.legend : '';
    },
    trafficLight: function trafficLight() {
      var _this = this;
      if (this.customTrafficLights.length) {
        return this.customTrafficLights.find(function (option) {
          return +option.number === +_this.selectedLight;
        });
      }
      return this.trafficLightsData.find(function (option) {
        return +option.number === +_this.selectedLight;
      });
    },
    showLabels: function showLabels() {
      return this.lightType === ObjectTypes.COMPANY;
    },
    trafficLightsData: function trafficLightsData() {
      return this.customTrafficLights.length ? this.customTrafficLights : this.getTrafficLightsForModule(this.lightType);
    }
  },
  watch: {
    modelValue: function modelValue(newValue) {
      this.selectedLight = newValue;
    }
  },
  beforeMount: function beforeMount() {
    this.selectedLight = this.modelValue;
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightsForModule'])), {}, {
    handleSelect: function handleSelect(number) {
      this.selectedLight = number;
      this.showSelector = false;
      this.$emit('update:modelValue', number);
    },
    toggleToolTip: function toggleToolTip() {
      if (this.readOnly) return;
      this.showSelector = !this.showSelector;
    },
    hideTooltip: function hideTooltip() {
      this.showSelector = false;
    }
  })
};