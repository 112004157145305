import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  key: 2
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = {
  class: "flex justify-between items-center mb-4"
};
var _hoisted_5 = {
  class: "p-5 border bg-zinc-50 border-gray-350 shadow-md"
};
var _hoisted_6 = {
  class: "flex flex-col lg:w-1/2 m-auto"
};
var _hoisted_7 = {
  class: "mb-5"
};
var _hoisted_8 = {
  class: "flex flex-col lg:flex-wrap lg:flex-row mb-5"
};
var _hoisted_9 = {
  class: "min-w-250px lg:w-31/100 mr-5 mb-5"
};
var _hoisted_10 = {
  key: 0,
  class: "min-w-180px lg:w-31/100 mr-5"
};
var _hoisted_11 = {
  key: 0,
  class: "mb-5 z-0"
};
var _hoisted_12 = {
  class: "lg:hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_required_fields_notification = _resolveComponent("required-fields-notification");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_input_field = _resolveComponent("input-field");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_traffic_light_selector = _resolveComponent("traffic-light-selector");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  return _openBlock(), _createElementBlock("div", null, [$data.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error",
    closeable: false,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.errorMessage = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.errorMessage), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_required_fields_notification, {
    "missing-required-fields": $options.missingRequiredFields
  }, null, 8 /* PROPS */, ["missing-required-fields"]), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : $data.defaultActivityType ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    class: "mb-4",
    type: "big",
    title: "RISMA Systems"
  }), !$options.isCreationSettingEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($data.translate.thisIncidentCreationPageHasntBeenEnabled), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.descriptionTextByRows, function (row, idx) {
    return _openBlock(), _createElementBlock("div", {
      key: idx,
      class: "mb-2"
    }, _toDisplayString(row), 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", null, [_createVNode(_component_risma_button, {
    class: "hidden lg:block",
    text: $data.translate.submit,
    disabled: $data.isLoading || !!$options.missingRequiredFields.length,
    type: "save",
    onClick: $options.createAnonymousIncident
  }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_input_field, {
    modelValue: $data.title,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.title = $event;
    }),
    title: $data.translate.title,
    invalid: !!$data.titleError,
    "error-help-text": $data.titleError,
    type: "text",
    class: "mr-5"
  }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "error-help-text"])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_date_time_picker, {
    modelValue: $data.incidentHappenedAt,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.incidentHappenedAt = $event;
    }),
    title: $data.translate.incidentOccurred,
    "delete-enabled": true,
    class: "w-full"
  }, null, 8 /* PROPS */, ["modelValue", "title"])]), $options.severityInOptional ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_risma_title, {
    title: $data.translate.severity,
    type: "medium",
    class: "separate"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_traffic_light_selector, {
    modelValue: $data.severity,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.severity = $event;
    }),
    class: "mt-2",
    "light-type": $data.moduleName
  }, null, 8 /* PROPS */, ["modelValue", "light-type"])])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", null, [_createVNode(_component_activity_custom_fields, {
    "activity-type": $data.defaultActivityType,
    "growing-fields": true,
    "ignore-visibility-level": true,
    "list-of-disabled-fields": $options.listOfDisabledFields,
    "list-of-hidden-fields": $options.listOfHiddenFields,
    onChanged: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.customFieldsParsed = $event;
    })
  }, null, 8 /* PROPS */, ["activity-type", "list-of-disabled-fields", "list-of-hidden-fields"])]), $options.descriptionInOptional ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_tiny_mce_box, {
    headline: $data.translate.description,
    "initial-content": $data.description,
    "always-open": true,
    onChanged: _cache[5] || (_cache[5] = function ($event) {
      return $data.description = $event;
    })
  }, null, 8 /* PROPS */, ["headline", "initial-content"])])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_button, {
    text: $data.translate.submit,
    disabled: $data.isLoading || !!$options.missingRequiredFields.length,
    "is-custom-button-size": true,
    class: "m-auto max-w-220px mobile-button",
    type: "save",
    onClick: $options.createAnonymousIncident
  }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])])]))])) : _createCommentVNode("v-if", true)]);
}