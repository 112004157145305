import CompanyEntry from '@/components/Pages/Companies/CompanyEntry';
import CompanyDescription from '@/components/Pages/Companies/CompanyDescription';
import LinksTab from '@/components/Organisms/LinksTab';
import Changelog from '@/components/Molecules/Changelog';
import CompanyContacts from '@/components/Pages/Companies/CompanyContacts';
import CompanyFunctions from '@/components/Pages/Companies/CompanyFunctions';
import Companies from '@/components/Pages/Admin/Companies';
import { TabTypes } from '@/constants/Tabs';
import { MODULES } from '@/constants/modules';
import { userHasCompaniesAccess, isAdmin } from '@/routes/auth';
import { useSettingsStore } from '@/Store/settingsStore';
export default (function () {
  return [{
    path: '/company/:id/',
    component: CompanyEntry,
    redirect: {
      name: 'CompanyDescription'
    },
    meta: {
      userRightsFunction: function userRightsFunction() {
        return userHasCompaniesAccess();
      }
    },
    props: true,
    children: [{
      path: TabTypes.DESCRIPTION,
      name: 'CompanyDescription',
      component: CompanyDescription
    }, {
      path: TabTypes.CONTACTS,
      component: CompanyContacts
    }, {
      path: TabTypes.FUNCTIONS,
      component: CompanyFunctions,
      meta: {
        userRightsFunction: function userRightsFunction() {
          return useSettingsStore().getValue('feature.company.dora_enable');
        }
      }
    }, {
      path: TabTypes.LINKS,
      component: LinksTab,
      props: {
        module: MODULES.COMPANY
      }
    }, {
      path: TabTypes.CHANGELOG,
      component: Changelog
    }]
  }, {
    path: '/companies',
    component: Companies,
    name: 'Companies',
    meta: {
      userRightsFunction: function userRightsFunction() {
        return userHasCompaniesAccess() || isAdmin();
      }
    }
  }];
});