import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "admin-period-main-wrap w-4/5"
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  class: "mb-8 flex justify-between"
};
var _hoisted_4 = {
  class: "flex w-4/5 max-w-900px flex-col"
};
var _hoisted_5 = {
  key: 0,
  class: "text-rm-signal-red glob-l1 mt-1"
};
var _hoisted_6 = {
  class: "mb-8"
};
var _hoisted_7 = {
  class: "mb-8"
};
var _hoisted_8 = {
  class: "w-64"
};
var _hoisted_9 = {
  class: "mb-8"
};
var _hoisted_10 = {
  class: "w-64"
};
var _hoisted_11 = {
  key: 0,
  class: "text-rm-signal-red glob-l1 mt-1 ml-4"
};
var _hoisted_12 = {
  key: 0,
  class: "flex justify-end mb-2"
};
var _hoisted_13 = {
  class: "flex items-center cursor-pointer"
};
var _hoisted_14 = {
  key: 1,
  class: "flex justify-end mb-2"
};
var _hoisted_15 = {
  class: "flex items-center cursor-pointer"
};
var _hoisted_16 = {
  class: "mr-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  var _component_level_of_threat = _resolveComponent("level-of-threat");
  var _component_input_field = _resolveComponent("input-field");
  var _component_consequence = _resolveComponent("consequence");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createElementBlock("section", _hoisted_2, [_createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      var _$data$errors, _$data$errors2, _$data$errors3, _$data$errors4, _$data$errors5;
      return [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
        ref: "detailTitle",
        title: $data.activity.title,
        placeholder: $data.translate.enterTitle,
        "min-input-width": 150,
        class: _normalizeClass({
          'border-rm-signal-red': (_$data$errors = $data.errors) === null || _$data$errors === void 0 ? void 0 : _$data$errors.title
        }),
        editable: !($options.isPeriodClosed || $options.isPeriodOpen),
        onTitleChange: _cache[0] || (_cache[0] = function ($event) {
          return $options.updateProperty('title', $event);
        })
      }, null, 8 /* PROPS */, ["title", "placeholder", "class", "editable"]), (_$data$errors2 = $data.errors) !== null && _$data$errors2 !== void 0 && _$data$errors2.title ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString((_$data$errors3 = $data.errors) === null || _$data$errors3 === void 0 ? void 0 : _$data$errors3.title), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), !($options.isPeriodClosed || $options.isPeriodOpen) ? (_openBlock(), _createBlock(_component_toggle_menu, {
        key: 0,
        options: $options.toggleMenuOptions
      }, null, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_level_of_threat, {
        activity: $data.activity,
        readonly: $options.isPeriodClosed,
        "default-options": $data.defaultLevelOfThreatOptions,
        onChanged: $options.onLevelChanged
      }, null, 8 /* PROPS */, ["activity", "readonly", "default-options", "onChanged"])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_input_field, {
        modelValue: $data.activity.probabilityLabel,
        "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
          return $data.activity.probabilityLabel = $event;
        }), _cache[2] || (_cache[2] = function ($event) {
          return $options.updateProperty('probabilityLabel', $event);
        })],
        enabled: $options.isPeriodEditable || $options.isPeriodOpen,
        type: "text",
        maxlength: "50"
      }, null, 8 /* PROPS */, ["modelValue", "enabled"])]), _createVNode(_component_consequence, {
        class: "pt-8",
        "consequence-rows": $data.activity.probabilityRows || [],
        "consequence-levels": $data.activity.probabilityLevels || 0,
        readonly: !$options.isPeriodEditable,
        "identical-columns": $data.activity.onlyColumns,
        "row-key": "likelihoodColumns",
        onChanged: _cache[3] || (_cache[3] = function ($event) {
          return $options.onDataChanged($event, $data.PROBABILITY.LABELS_KEY);
        })
      }, null, 8 /* PROPS */, ["consequence-rows", "consequence-levels", "readonly", "identical-columns"])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_input_field, {
        modelValue: $data.activity.consequenceLabel,
        "onUpdate:modelValue": [_cache[4] || (_cache[4] = function ($event) {
          return $data.activity.consequenceLabel = $event;
        }), _cache[5] || (_cache[5] = function ($event) {
          return $options.updateProperty('consequenceLabel', $event);
        })],
        enabled: $options.isPeriodEditable || $options.isPeriodOpen,
        type: "text",
        maxlength: "50"
      }, null, 8 /* PROPS */, ["modelValue", "enabled"])]), _createVNode(_component_consequence, {
        class: "pt-8",
        "consequence-rows": $data.activity.consequenceRows || [],
        "consequence-levels": $data.activity.consequenceLevels || 0,
        readonly: !$options.isPeriodEditable,
        "identical-columns": $data.activity.onlyColumns,
        onChanged: _cache[6] || (_cache[6] = function ($event) {
          return $options.onDataChanged($event, $data.CONSEQUENCE.LABELS_KEY);
        })
      }, null, 8 /* PROPS */, ["consequence-rows", "consequence-levels", "readonly", "identical-columns"]), (_$data$errors4 = $data.errors) !== null && _$data$errors4 !== void 0 && _$data$errors4.consequence ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString((_$data$errors5 = $data.errors) === null || _$data$errors5 === void 0 ? void 0 : _$data$errors5.consequence), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", null, [$options.isDraft ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("label", _hoisted_13, [_createElementVNode("span", null, _toDisplayString($data.translate.openThisPeriodAndCloseThePrevious), 1 /* TEXT */), _createVNode(_component_checkboxes, {
        options: $data.openPeriod,
        "checkbox-size": 20,
        onUpdated: _cache[7] || (_cache[7] = function ($event) {
          return $data.openPeriod = $event.isChecked;
        })
      }, null, 8 /* PROPS */, ["options"])])])) : _createCommentVNode("v-if", true), $options.riskApprovalEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("label", _hoisted_15, [_createElementVNode("span", _hoisted_16, _toDisplayString($data.translate.resetAllRiskApprovals), 1 /* TEXT */), _createVNode(_component_checkboxes, {
        options: $data.resetApprovals,
        "checkbox-size": 20,
        onUpdated: _cache[8] || (_cache[8] = function ($event) {
          return $data.resetApprovals = $event.isChecked;
        })
      }, null, 8 /* PROPS */, ["options"])])])) : _createCommentVNode("v-if", true), _createVNode(_component_buttons_row, {
        class: "justify-end",
        data: $options.buttonsRowData
      }, null, 8 /* PROPS */, ["data"])])];
    }),
    _: 1 /* STABLE */
  })])) : _createCommentVNode("v-if", true)])])]);
}