// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-title[data-v-7c97983c]::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border-top: 1px solid #CCCCCC;
  width: 1.5rem;
}
.data-title[data-v-7c97983c]::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  border-left: 1px solid #CCCCCC;
  height: 50%;
}
.section-item[data-v-7c97983c]::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border-top: 1px solid #CCCCCC;
  width: 1.5rem;
}
.section-item[data-v-7c97983c]::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-left: 1px solid #CCCCCC;
}
.section-item[data-v-7c97983c]:last-of-type::after {
  height: 50%;
}
.right-section[data-v-7c97983c] {
  contain: inline-size;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
