import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "glob-l1-sb"
};
var _hoisted_2 = {
  class: "flex items-center"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  class: "flex items-center"
};
var _hoisted_5 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_control_creation_modal = _resolveComponent("control-creation-modal");
  var _component_create_initiative_modal = _resolveComponent("create-initiative-modal");
  var _component_single_select = _resolveComponent("single-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $options.modalTitle,
    "dismiss-on-click-outside": false,
    "button-ok-text": $data.translate.apply,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: _cache[4] || (_cache[4] = function ($event) {
      return $options.updateLinks($data.project);
    }),
    onDismiss: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.isControlCreationModalShow ? (_openBlock(), _createBlock(_component_control_creation_modal, {
        key: 0,
        onUpdate: $options.addControl,
        onClose: _cache[0] || (_cache[0] = function ($event) {
          return $data.isControlCreationModalShow = false;
        })
      }, null, 8 /* PROPS */, ["onUpdate"])) : _createCommentVNode("v-if", true), $data.isCreationInitiativeModalShow ? (_openBlock(), _createBlock(_component_create_initiative_modal, {
        key: 1,
        initiatives: $options.filteredInitiatives,
        onDismiss: _cache[1] || (_cache[1] = function ($event) {
          return $data.isCreationInitiativeModalShow = false;
        }),
        onAccept: $options.addInitiative
      }, null, 8 /* PROPS */, ["initiatives", "onAccept"])) : _createCommentVNode("v-if", true), $props.projectSelectVisible ? (_openBlock(), _createBlock(_component_single_select, {
        key: 2,
        title: $data.translate.selectProjectOrActivity,
        "model-value": $data.project,
        "with-search": true,
        options: $options.filteredProjectsList,
        placeholder: $data.translate.choose,
        disabled: $props.disabledProjectSelect,
        "onUpdate:modelValue": $options.selectProject
      }, null, 8 /* PROPS */, ["title", "model-value", "options", "placeholder", "disabled", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), $data.project ? (_openBlock(), _createElementBlock("div", {
        key: 3,
        class: _normalizeClass(['relative', {
          'mt-8': $props.projectSelectVisible
        }])
      }, [_createVNode(_component_risma_select, {
        options: $options.sortedSelectableItems,
        "selected-options": $options.enhancedSelectedLinks,
        title: $data.translate.select + ' ' + $options.linkLabel,
        "search-fields": $options.searchFields,
        "label-key": "title",
        placeholder: $data.translate.clickToSelect + ' ' + $options.linkLabel,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.selectedLinks = $event;
        })
      }, _createSlots({
        tagLabelCustom: _withCtx(function (_ref) {
          var option = _ref.option;
          return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [option.trafficLightColor !== null && option.trafficLightColor !== undefined ? (_openBlock(), _createBlock(_component_traffic_light, {
            key: 0,
            color: option.trafficLightColor,
            class: "inline-block align-baseline mr-2",
            size: "small"
          }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true)]), option.path || option.rno ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(option.path || option.rno) + "  ", 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(option.formattedTitle || option.title || option.name), 1 /* TEXT */), $data.project === $data.MODULES.EXECUTION && option.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
            key: 1,
            icon: "check",
            class: "ml-2"
          })) : _createCommentVNode("v-if", true)])];
        }),
        labelCustom: _withCtx(function (_ref2) {
          var option = _ref2.option;
          return [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, [option.trafficLightColor !== null && option.trafficLightColor !== undefined ? (_openBlock(), _createBlock(_component_traffic_light, {
            key: 0,
            color: option.trafficLightColor,
            class: "inline-block align-baseline mr-2",
            size: "small"
          }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true)]), option.path || option.rno ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(option.path || option.rno) + "  ", 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(option.formattedTitle || option.title || option.name), 1 /* TEXT */), $data.project === $data.MODULES.EXECUTION && option.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
            key: 1,
            icon: "check",
            class: "ml-2"
          })) : _createCommentVNode("v-if", true)])];
        }),
        _: 2 /* DYNAMIC */
      }, [$options.showAddItemButton ? {
        name: "newOption",
        fn: _withCtx(function () {
          return [_createElementVNode("div", {
            class: "flex items-center cursor-pointer text-rm-signal-grey-dark hover:border-rm-text border-rm-signal-grey-dark hover:text-rm-text hover:underline",
            onClick: _cache[2] || (_cache[2] = function ($event) {
              return $options.openCreationModal($data.project);
            })
          }, [_createVNode(_component_feather_icon, {
            icon: "plus",
            width: "18",
            height: "18",
            class: "border-2 stroke-2 border-inherit rounded-full mr-2"
          }), _createElementVNode("span", _hoisted_1, _toDisplayString($data.translate.createNew), 1 /* TEXT */)])];
        }),
        key: "0"
      } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["options", "selected-options", "title", "search-fields", "placeholder"])], 2 /* CLASS */)) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text"]);
}