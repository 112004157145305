function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import RismaOrgSelector from '@/components/Molecules/OrganisationSelector';
import Notification from '@/components/Molecules/Notification';
import * as userAPI from '@/api/admin/user';
import { capitalize } from '@/utils';
import { passwordErrorMessage, getPasswordLength } from '@/utils/notifications';
import { isEmailValid, isPasswordValid } from '@/utils/validations';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
var CHECKBOX_OPTION_VALUE = {
  API_USER: 'api_user',
  SEND_EMAIL: 'sendWelcomeEmail'
};
export default {
  name: 'UserEditModal',
  introduction: 'Modal that contains on administration page',
  description: 'Component that is used to create a new user or update the old one',
  token: "<user-edit-modal\n      v-show=\"opened\"\n      :type=\"type\"\n      :information=\"information\"\n      @dismiss=\"toogleModal('closed')\"\n      @create=\"createAction\"/>",
  components: {
    Modal: Modal,
    InputField: InputField,
    RismaOrgSelector: RismaOrgSelector,
    Notification: Notification,
    Checkboxes: Checkboxes,
    ButtonsRow: ButtonsRow
  },
  props: {
    type: {
      required: true,
      type: String,
      default: 'create',
      note: 'Can be `create|update` (for creating new user or for updating the old one)'
    },
    allOrganisations: {
      required: true,
      type: Array,
      default: function _default() {},
      note: 'all organisations in organisation selector'
    },
    information: {
      required: false,
      type: Object,
      default: function _default() {},
      note: 'all the information that were sent if you need to update User'
    }
  },
  emits: ['createUser', 'updateUser', 'dismiss'],
  data: function data() {
    return {
      headerTitle: '',
      okText: '',
      accessRows: {},
      selectedOrganisations: [],
      selectedUsers: [],
      isLoaded: false,
      password: '',
      usernameError: false,
      initialsError: false,
      passwordError: false,
      fullNameError: false,
      hasErrorFromBackend: false,
      isRismaSelectOpen: false,
      errorsFromBackend: '',
      emailError: false,
      data: {},
      invalidEmail: '',
      notificationMessage: '',
      defaultObject: {
        username: '',
        initials: '',
        fullname: '',
        email: '',
        organisations: [],
        api_user: 0,
        sendWelcomeEmail: true
      },
      forcePasswordUpdate: false,
      translate: getTranslate['UserEditModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    descriptionOfTheError: function descriptionOfTheError() {
      return this.translate.pleaseFillOutThisField;
    },
    descriptionOfThePasswordError: function descriptionOfThePasswordError() {
      return passwordErrorMessage(this.getSettingValue('feature.enterprise_security'), this.passwordSettingLength);
    },
    apiUserNotificationMessage: function apiUserNotificationMessage() {
      return this.translate.toProtectAPIaccount.replace('_amount_', this.passwordSettingLength);
    },
    descriptionOfTheEmailError: function descriptionOfTheEmailError() {
      if (this.invalidEmail) {
        return this.invalidEmail + this.translate.isAnInvalidEmailAddress;
      } else {
        return this.translate.pleaseFillOutThisField;
      }
    },
    isCreateType: function isCreateType() {
      return this.type === 'create';
    },
    passwordSettingLength: function passwordSettingLength() {
      return getPasswordLength(this.data.api_user, this.getSettingValue('feature.api_user_min_password_length'), this.getSettingValue('feature.enterprise_security'));
    },
    checkboxOptions: function checkboxOptions() {
      var list = [{
        value: CHECKBOX_OPTION_VALUE.API_USER,
        checked: !!this.data.api_user,
        label: this.translate.apiUser
      }];
      if (this.isCreateType) {
        list.push({
          value: CHECKBOX_OPTION_VALUE.SEND_EMAIL,
          checked: !!this.data.sendWelcomeEmail,
          label: this.translate.sendWelcomeEmailToUser
        });
      }
      return list;
    },
    buttonsRowData: function buttonsRowData() {
      var buttonClasses = 'capitalize';
      var list = [{
        text: this.translate.dismiss,
        type: 'util',
        buttonClasses: buttonClasses,
        clickEvent: this.dismissForm
      }, {
        text: this.okText,
        type: 'save',
        buttonClasses: buttonClasses,
        clickEvent: this.createUser
      }];
      if (this.getSettingValue('feature.google_mfa_enabled') && this.data.mfa_type) {
        list.unshift({
          text: this.translate.disableMfaForUser,
          type: 'util',
          buttonClasses: buttonClasses,
          clickEvent: this.disableMfa
        });
      }
      return list;
    }
  }),
  mounted: function mounted() {
    this.data = _objectSpread(_objectSpread({}, this.defaultObject), this.information);
    this.fillTheForm();
  },
  methods: {
    disableMfa: function disableMfa() {
      this.updateUserRequest({
        id: this.data.id,
        mfa_type: 0
      }, false);
    },
    createUser: function createUser() {
      if (!this.isValid()) {
        return;
      }
      var data = this.getAllData();
      if (!this.isCreateType) {
        this.updateUserRequest(data);
      } else {
        this.createUserRequest(data);
      }
    },
    handleCheckBoxOptionChange: function handleCheckBoxOptionChange(data) {
      var field = data.value;
      var isAPIUser = data.value === CHECKBOX_OPTION_VALUE.API_USER;
      var value = isAPIUser ? data.isChecked ? 1 : 0 : data.isChecked;
      this.data[field] = value;
      if (isAPIUser) {
        var _this$information;
        this.forcePasswordUpdate = (this.isCreateType || !((_this$information = this.information) !== null && _this$information !== void 0 && _this$information.api_user)) && value;
        this.notificationMessage = value ? this.apiUserNotificationMessage : null;
      }
    },
    createUserRequest: function createUserRequest(data) {
      var _this = this;
      userAPI.createNewUser(data).then(function (user) {
        _this.$emit('createUser', user);
        _this.dismissForm();
        _this.setFalseErrorMessages();
      }).catch(function (msg) {
        _this.hasErrorFromBackend = true;
        _this.errorsFromBackend = _this.getValidError(msg.response.error);
      });
    },
    updateUserRequest: function updateUserRequest(data) {
      var _this2 = this;
      var dismiss = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      userAPI.updateUser(data, data.id).then(function (user) {
        _this2.$emit('updateUser', data);
        if (dismiss) {
          _this2.dismissForm();
          _this2.setFalseErrorMessages();
        } else {
          _this2.data = user;
        }
      }).catch(function (msg) {
        _this2.hasErrorFromBackend = true;
        _this2.errorsFromBackend = _this2.getValidError(msg.response.error);
      });
    },
    getValidError: function getValidError(error) {
      var _this3 = this;
      var passwordString = 'password must have a length greater than or equal to ';
      return JSON.parse(error).map(function (err) {
        if (err.startsWith(passwordString)) return capitalize(_this3.$trans(passwordString + '14')).replace('14', _this3.passwordSettingLength);
        if (_this3.$trans(err) === err) return _this3.$trans(capitalize(err));
        return capitalize(_this3.$trans(err));
      });
    },
    getAllData: function getAllData() {
      var selOrgs = this.selectedOrganisations.map(function (element) {
        return element = element.id;
      });
      var data = _objectSpread(_objectSpread({}, this.data), {}, {
        organisations: selOrgs,
        orgs: selOrgs.join(',')
      });
      if (this.password.length) {
        data.password = this.password;
      }
      return data;
    },
    isEmpty: function isEmpty(field) {
      return !(field !== null && field !== void 0 && field.length);
    },
    isValid: function isValid() {
      this.usernameError = this.isEmpty(this.data.username);
      this.initialsError = this.isEmpty(this.data.initials);
      this.passwordError = !this.isValidPassword(this.password);
      this.fullNameError = this.isEmpty(this.data.fullname);
      this.emailError = !this.isValidEmail(this.data.email) || this.isEmpty(this.data.email);
      if (this.usernameError || this.initialsError || this.fullNameError || this.emailError || this.passwordError) {
        return false;
      }
      return true;
    },
    isValidPassword: function isValidPassword() {
      if (this.isCreateType && !this.password.length) {
        return false;
      }
      if (this.password.length === 0 && !this.forcePasswordUpdate) return true;
      if (this.getSettingValue('feature.enterprise_security')) {
        return isPasswordValid(this.password, this.passwordSettingLength);
      }
      if (this.data.api_user) {
        return this.password.length >= this.passwordSettingLength;
      }
      return this.password.length >= 8;
    },
    isValidEmail: function isValidEmail(email) {
      if (!(email !== null && email !== void 0 && email.length) || !isEmailValid(email)) {
        this.invalidEmail = this.data.email;
        return false;
      }
      this.invalidEmail = '';
      return true;
    },
    dismissForm: function dismissForm() {
      this.setEmpty();
      this.$emit('dismiss');
    },
    fillTheForm: function fillTheForm() {
      if (!this.isCreateType) {
        this.headerTitle = this.translate.updateUser;
        this.okText = this.translate.update;
        this.setSelectedOrgs();
      } else {
        this.setEmpty();
        this.headerTitle = this.translate.createUser;
        this.okText = this.translate.create;
      }
      this.setFalseErrorMessages();
    },
    setSelectedOrgs: function setSelectedOrgs() {
      var _this4 = this;
      var orgs = this.data.organisations;
      if (orgs) {
        var filtered = [];
        orgs.forEach(function (element) {
          filtered.push(_this4.allOrganisations.filter(function (item) {
            return item.id == element;
          })[0]);
        });
        this.selectedOrganisations = filtered;
      }
    },
    setEmpty: function setEmpty() {
      this.selectedOrganisations = [];
      this.password = '';
    },
    setFalseErrorMessages: function setFalseErrorMessages() {
      this.fullNameError = false;
      this.usernameError = false;
      this.initialsError = false;
      this.emailError = false;
      this.passwordError = false;
      this.hasErrorFromBackend = false;
    },
    updateOrganisations: function updateOrganisations(e) {
      this.selectedOrganisations = e ? [].concat(e) : [];
    }
  }
};