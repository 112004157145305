function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import DynamicTooltip from '@/components/Molecules/DynamicTooltip';
import AiAssistantIcon from '@/components/Atoms/FeatherIcon/AiAssistantIcon';
import { TOOLTIP_MODES } from '@/constants/Tooltips';
import { ColorSettings } from '@/constants/admin/CustomizationSettings';
var FILL_COLORS = {
  rmGrey: 'rgb(190, 190, 190)',
  rmGreyStone: 'rgb(222, 226, 230)',
  rmActiveBlue: 'rgb(33, 130, 253)',
  currentColor: 'currentColor'
};
export default {
  name: 'AiButtonWithTooltip',
  components: {
    DynamicTooltip: DynamicTooltip,
    AiAssistantIcon: AiAssistantIcon
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: TOOLTIP_MODES.PRIMARY
    },
    position: {
      type: String,
      required: false,
      default: 'bottom',
      note: 'Position of tooltip'
    },
    tooltipStyles: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    iconType: {
      type: String,
      required: false,
      default: 'default',
      note: 'Position of tooltip'
    }
  },
  emits: ['close:tooltip', 'click:icon'],
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    aiTermsAccepted: 'aiTermsAccepted',
    getSettingValue: 'getValue'
  })), {}, {
    tooltipText: function tooltipText() {
      return this.$trans('Try out the new AI Partner! An administrator must first enable it in the %ssettings%s. To learn more click %s here %s', ["<a style=\"font-weight: 600;text-decoration: underline; cursor: pointer; color: ".concat(this.mode === TOOLTIP_MODES.PRIMARY ? '#fff' : '#00acef', ";\" data-ai-admin=\"true\" >"), '</a>', "<a style=\"font-weight: 600;text-decoration: underline; color: ".concat(this.mode === TOOLTIP_MODES.PRIMARY ? '#fff' : '#00acef', ";\" target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://support.rismasystems.com/ai-partner#anchor-1\">"), '</a>']);
    },
    btnClasses: function btnClasses() {
      if (this.iconType === 'primary') {
        return "ml-2 flex w-8 h-8 mr-2 rounded ".concat(this.aiTermsAccepted ? 'hover:bg-trans-15' : 'cursor-not-allowed');
      }
      return "ml-2 w-6 h-[18px] border rounded-sm py-[3px] leading-5 ".concat(this.aiTermsAccepted ? 'border-rm-active-blue hover:bg-rm-active-blue--6' : 'border-rm-grey cursor-not-allowed');
    },
    iconStyles: function iconStyles() {
      if (this.iconType === 'primary') {
        var _ColorSettings$settin = ColorSettings.settingsTextColor,
          settingName = _ColorSettings$settin.settingName,
          color = _ColorSettings$settin.color;
        var isNavColorChanged = color !== this.getSettingValue(settingName);
        var useCurrentColor = this.aiTermsAccepted || isNavColorChanged;
        return {
          width: 27,
          height: 19,
          fillColor: useCurrentColor ? FILL_COLORS.currentColor : FILL_COLORS.rmGrey
        };
      }
      return {
        width: 15,
        height: 10,
        fillColor: this.aiTermsAccepted ? FILL_COLORS.rmActiveBlue : FILL_COLORS.rmGrey
      };
    }
  }),
  methods: {
    handleTooltipClick: function handleTooltipClick(event) {
      var _event$target;
      var isAiSettingsLink = event === null || event === void 0 || (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.getAttribute('data-ai-admin');
      if (isAiSettingsLink) {
        this.$router.push({
          name: 'AdminAiPartnerAccess'
        });
      }
    }
  }
};