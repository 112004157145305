function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { MenuKeys } from '@/constants/menu/MenuKeys';
import { UserLevels } from '@/constants/UserLevels';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
function getAdminMenuItems(project) {
  return {
    url: '/compliance/tree/' + project.id,
    title: project.title,
    type: project.type,
    id: project.id,
    rights: true,
    class: 'complianceProject',
    sorting: project.sorting
  };
}
function getReportsChildren(project) {
  var aliasRegex = new RegExp("compliance/reports/".concat(project.id, "/outsourcing/\\d+"));
  var reportsChildren = [];
  reportsChildren.push({
    url: '/compliance/reports/' + project.id + '/nodes'
  });
  reportsChildren.push({
    url: '/compliance/reports/' + project.id + '/gap'
  });
  reportsChildren.push({
    url: '/compliance/reports/' + project.id + '/dataflow'
  });
  reportsChildren.push({
    url: '/compliance/reports/' + project.id + '/outsourcing',
    aliasesRegex: aliasRegex
  });
  Object.keys(project.reports).forEach(function (report) {
    reportsChildren.push({
      url: '/compliance/reports/' + project.id + '/' + report
    });
  });
  return reportsChildren;
}
function showOverviewMenu(project) {
  return project.showOverviewMenuItem;
}
function showDashboardsMenu(project) {
  return project.type !== ProjectTypes.ESG;
}
function getProjectMenuItems(project, user, reportsChildren, i18n, settings) {
  var aliasRegex = new RegExp("compliance/".concat(project.id, "/edit/\\d+/\\w+"));
  var showOverview = showOverviewMenu(project);
  var showDashboards = showDashboardsMenu(project);
  var defaultTreeName = 'Tree';
  var treeTitle = project.treeMenuItemTitle || defaultTreeName;
  return {
    url: '/compliance/tree/' + project.id,
    title: project.title,
    translatable: false,
    type: project.type,
    rights: true,
    aliasesRegex: aliasRegex,
    id: project.id,
    class: 'complianceProject',
    sorting: project.sorting,
    children: [{
      url: '/compliance/overview/' + project.id,
      title: 'Overview',
      rights: showOverview
    }, {
      url: '/compliance/tree/' + project.id,
      title: showOverview ? treeTitle : 'Overview',
      translatable: treeTitle !== defaultTreeName && showOverview ? false : true,
      rights: true
    }, {
      url: '/compliance/dashboard/' + project.id,
      title: 'Dashboards',
      rights: (project.access_level >= UserLevels.PRIVILEGED || user.is_admin) && showDashboards,
      children: [{
        url: '/compliance/dashboard/' + project.id + '/gap'
      }, {
        url: '/compliance/dashboard/' + project.id + '/questions'
      }]
    }, {
      url: '/compliance/reports/' + project.id,
      title: 'Reports',
      rights: project.access_level >= UserLevels.PRIVILEGED || user.is_admin,
      children: reportsChildren
    }, {
      url: "/compliance/management-reports/".concat(project.id),
      title: 'Management reporting',
      rights: !!settings['feature.compliance.compliance_management_report'] && project.type === ProjectTypes.CSRD
    }, {
      url: '/compliance/supervisor/' + project.id,
      title: project.type === 1 ? i18n.t('DPO') : i18n.t('Supervisor'),
      rights: (settings['feature.supervisor_enabled'] ? true : false) && (project.supervisor === 1 || user.is_admin || project.access_level > UserLevels.SUPER) && showDashboards
    }]
  };
}
function addAdditionalMenuItems(menuStructure, user, settings) {
  var customIA = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  var iaBaseUrl = '/compliance/informationassets';
  var customList = customIA.map(function (item) {
    return {
      url: "".concat(iaBaseUrl, "/type/").concat(item.id),
      title: item.label,
      translatable: false,
      isHiddenInSubMenu: true,
      rights: user.iaAccess === true
    };
  });
  menuStructure.main.unshift({
    url: "".concat(iaBaseUrl, "/type"),
    title: settings['feature.information_assets_name'] || 'Information assets',
    translatable: !settings['feature.information_assets_name'],
    rights: user.iaAccess === true && settings['feature.asset_management_menu'] !== 1 && settings['feature.disable_information_assets'] !== 1,
    aliasesRegex: '/compliance/informationassets/*',
    menuKey: MenuKeys.ia,
    children: [{
      url: "".concat(iaBaseUrl, "/type/all"),
      title: 'Overview',
      rights: user.iaAccess === true
    }, {
      url: "".concat(iaBaseUrl, "/type/controllers"),
      title: 'Controllers',
      isHiddenInSubMenu: true,
      rights: user.iaAccess === true && (settings['feature.enable_data_controllers'] === undefined || settings['feature.enable_data_controllers'])
    }, {
      url: "".concat(iaBaseUrl, "/type/processors"),
      title: 'Processors',
      isHiddenInSubMenu: true,
      rights: user.iaAccess === true && (settings['feature.enable_data_processors'] === undefined || settings['feature.enable_data_processors'])
    }, {
      url: "".concat(iaBaseUrl, "/type/systems"),
      title: 'Systems',
      isHiddenInSubMenu: true,
      rights: user.iaAccess === true && (settings['feature.enable_systems'] === undefined || settings['feature.enable_systems'])
    }].concat(_toConsumableArray(customList), [{
      url: "".concat(iaBaseUrl, "/reports"),
      title: 'Reports',
      rights: user.iaAccess === true,
      children: [{
        url: "".concat(iaBaseUrl, "/reports/questionnaire")
      }, {
        url: "".concat(iaBaseUrl, "/reports/audit")
      }, {
        url: "".concat(iaBaseUrl, "/reports/answered")
      }]
    }])
  });
  menuStructure.admin.unshift({
    url: '/compliance/informationassets',
    title: settings['feature.information_assets_name'] || 'Information assets',
    translatable: !settings['feature.information_assets_name'],
    rights: user.iaAccess === true
  });
}
function buildComplianceMenu(projects, user, settings, i18n) {
  var customIA = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
  var menuStructure = {
    main: [],
    admin: []
  };
  projects.forEach(function (project) {
    if (project.access_level <= 0) return;
    var extendedProject = _objectSpread(_objectSpread({}, project), useComplianceProjectsStore().getProjectById(project.id) || {});
    menuStructure.admin.push(getAdminMenuItems(extendedProject, user, settings));
    var reportsChildren = getReportsChildren(extendedProject);
    menuStructure.main.push(getProjectMenuItems(extendedProject, user, reportsChildren, i18n, settings));
  });
  menuStructure.main.sort(function (pr1, pr2) {
    return pr1.sorting - pr2.sorting;
  });
  menuStructure.admin.sort(function (pr1, pr2) {
    return pr1.sorting - pr2.sorting;
  });
  addAdditionalMenuItems(menuStructure, user, settings, customIA);
  return menuStructure;
}
export default buildComplianceMenu;