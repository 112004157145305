function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { getTranslate } from './translate';
import * as projectApi from '@/api/compliance/projects';
import * as gapAdminApi from '@/api/rismaadmin/gap';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import MainWell from '@/components/Atoms/Containers/MainWell';
export default {
  name: 'UploadGap',
  components: {
    MainWell: MainWell,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaToggle: RismaToggle,
    RismaFileUpload: RismaFileUpload,
    RismaButton: RismaButton,
    InputField: InputField,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    RismaDatatable: RismaDatatable
  },
  mixins: [RismaDatatableDefaultMixin],
  data: function data() {
    return {
      projects: [],
      projectId: null,
      gapSchemaType: null,
      gapTitle: '',
      files: [],
      updateAllNodes: false,
      origins: [{
        value: 'Customer',
        label: 'Customer'
      }, {
        value: 'Plesner',
        label: 'Plesner'
      }],
      origin: null,
      loading: false,
      previewDataset: null,
      columnsMeta: [{
        targets: ['suggestedActions', 'suggestedControls'],
        width: '300px'
      }, {
        targets: ['attributes'],
        width: '200px'
      }, {
        targets: ['frameworks'],
        width: '200px'
      }],
      invalidFields: {},
      translate: getTranslate['UploadGap']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, ['isDataProcessorsEnabled'])), mapState(useFrameworksStore, ['enabledFrameworks'])), {}, {
    gapSchemaTypes: function gapSchemaTypes() {
      var gapTypes = [{
        value: null,
        label: 'Compliance project'
      }];
      if (this.isDataProcessorsEnabled) {
        gapTypes.push({
          value: 102,
          label: 'Data processor'
        });
      }
      return gapTypes;
    },
    columns: function columns() {
      return {
        orderBy: this.translate.ordering,
        article: this.translate.article,
        articleTitle: this.translate.articleTitle,
        domain: this.translate.domain,
        requirement: this.translate.requirement,
        flags: this.translate.flags,
        suggestedActions: this.translate.suggestedActions,
        suggestedControls: this.translate.suggestedControls,
        attributes: this.translate.attributes,
        frameworks: this.translate.frameworks
      };
    }
  }),
  mounted: function mounted() {
    var _this = this;
    projectApi.getAll().then(function (response) {
      _this.projects = response.list.map(function (project) {
        return {
          value: project.id,
          label: project.title
        };
      });
    });
  },
  methods: {
    createGapSchema: function createGapSchema() {
      var _this2 = this;
      if (!this.hasValidFields()) return;
      var params = this.getParams();
      gapAdminApi.uploadGap(params).then(function () {
        _this2.$notify({
          title: 'Upload ok',
          type: 'success'
        });
      }).catch(function (error) {
        if (error.response === undefined) {
          return;
        }
        var errorMessage = typeof error.response === 'string' ? error.response : error.response.message ? "".concat(error.response.error, " - ").concat(error.response.message) : error.response.error;
        _this2.$notify({
          title: "Error during upload: ".concat(errorMessage),
          type: 'error'
        });
      });
    },
    getParams: function getParams() {
      return {
        projectId: this.gapSchemaType === null ? this.projectId : null,
        gapSchemaType: this.gapSchemaType,
        file: this.files[0],
        updateAllNodes: this.updateAllNodes,
        gapTitle: this.gapTitle,
        origin: this.origin
      };
    },
    loadPreviewTable: function loadPreviewTable() {
      var _this3 = this;
      this.loading = true;
      var params = this.getParams();
      var lastArticle = null;
      params.dryRun = true;
      gapAdminApi.uploadGap(params).then(function (response) {
        var formattedParsedArticles = [];
        response.list.forEach(function (article) {
          lastArticle = article;
          var frameworkNames = _this3.enabledFrameworks.filter(function (_ref) {
            var _article$frameworks;
            var id = _ref.id;
            return (_article$frameworks = article.frameworks) === null || _article$frameworks === void 0 ? void 0 : _article$frameworks.includes(id);
          }).map(function (_ref2) {
            var displayName = _ref2.displayName;
            return displayName;
          });
          formattedParsedArticles.push({
            orderBy: {
              field: article.orderBy
            },
            article: {
              field: article.article
            },
            articleTitle: {
              field: article.articleTitle
            },
            domain: {
              field: article.domain
            },
            requirement: {
              field: article.requirement
            },
            flags: {
              field: article.flags
            },
            suggestedActions: _this3.prepareListField(article.suggestedActions || []),
            suggestedControls: _this3.prepareListField(article.suggestedControls || []),
            attributes: _this3.prepareListField(article.attributes || []),
            frameworks: _this3.prepareListField(frameworkNames)
          });
        });
        _this3.previewDataset = formattedParsedArticles;
        _this3.enhanceDataset(_this3.previewDataset, _this3.columns);
      }).catch(function (error) {
        var errorMessage = '';
        if (error instanceof TypeError) {
          errorMessage = "".concat(error, ". On article: ").concat(lastArticle.article, " ").concat(lastArticle.articleTitle);
        } else {
          errorMessage = typeof error.response === 'string' ? error.response : error.response.message ? "".concat(error.response.error, " - ").concat(error.response.message) : error.response.error;
        }
        _this3.$notify({
          title: "Error during upload: ".concat(errorMessage),
          type: 'error'
        });
      }).finally(function () {
        return _this3.loading = false;
      });
    },
    hasValidFields: function hasValidFields() {
      var _this4 = this;
      var invalidFields = {};
      var requiredFields = ['origin', 'gapTitle'];
      if (this.gapSchemaType === null) requiredFields.push('projectId');
      requiredFields.forEach(function (field) {
        if (!_this4[field]) {
          invalidFields[field] = _this4.translate.pleaseFillOutThisField;
        }
      });
      this.invalidFields = invalidFields;
      return Object.keys(invalidFields).length === 0;
    }
  }
};