function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
import { getCurrentUser, getInitiatives, getControls } from '@/api/risma/data';
import { logReport as _logReport } from '@/api/reports/reports';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import StaticTabs from '@/components/Atoms/StaticTabs';
import StaticTabItem from '@/components/Atoms/StaticTabItem';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaBarChart from '@/components/Molecules/Charts/RismaBarChart';
import Notification from '@/components/Molecules/Notification';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import RismaSelectorBar from '@/components/Molecules/RismaSelectorBar';
import GapTable from '@/components/Organisms/Compliance/Dashboard/GapTable';
import SupervisorInitiatives from './SupervisorInitiatives';
import SupervisorControls from './SupervisorControls';
import { ComplianceDashboardMixin } from '@/components/Pages/Compliance/ComplianceDashboardMixin';
import { gapsToInitiatives, gapsToControls, nullKeysToString } from '@/utils';
import { getUsersFromIds, getOrganisationsFromIds } from '@/utils/format/objectsFromIds';
import { addGapsToControls } from '@/utils/format/gapListToActivityList';
import { getDataQueryStringFromArray } from '@/api/httpUtils';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { RismaSelectorBarMixin } from '@/mixins/RismaSelectorBarMixin';
import { UserLevels } from '@/constants/UserLevels';
import { calcExecutionRights, calcControlsRights } from '@/menus/quicknav';
import { MODULES } from '@/constants/modules';
export default {
  name: 'SupervisorDashboard',
  components: {
    GapTable: GapTable,
    RismaSelectorBar: RismaSelectorBar,
    RismaDoughnutChart: RismaDoughnutChart,
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    TrafficLight: TrafficLight,
    FeatherIcon: FeatherIcon,
    SupervisorControls: SupervisorControls,
    SupervisorInitiatives: SupervisorInitiatives,
    StaticTabItem: StaticTabItem,
    StaticTabs: StaticTabs,
    Notification: Notification,
    RismaBarChart: RismaBarChart
  },
  mixins: [RaciTitleMixin, ComplianceDashboardMixin, RismaSelectorBarMixin],
  introduction: 'supervisor dashboard page',
  description: 'this page shows the dashboards for the supervisor',
  token: '<supervisor-dashboard></supervisor-dashboard>',
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    var projectId = to.params.projectId;
    getCurrentUser().then(function (user) {
      var project = user.projects[MODULES.COMPLIANCE].find(function (project) {
        return project.id === +projectId;
      });
      if (!project || !project.supervisor && !user.level_admin && project.access_level <= UserLevels.SUPER) {
        next('/');
      } else {
        next(function (vm) {
          vm.projectType = project.type;
        });
      }
    });
  },
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Project id from route'
    }
  },
  data: function data() {
    return {
      lastUsedParams: {},
      projectType: null,
      controls: [],
      allInitiatives: [],
      localStorageKey: "supervisor_dashboard_filters_".concat(this.projectId),
      gapDataDownloadedAtOnce: true,
      solutionsPropName: 'solutionIds',
      translate: getTranslate['SupervisorDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    getAllSettings: 'getAllSettings'
  })), mapState(useUserStore, {
    currentUser: 'currentUser'
  })), {}, {
    currentLocation: function currentLocation() {
      var _this$currentProject;
      return [((_this$currentProject = this.currentProject) === null || _this$currentProject === void 0 ? void 0 : _this$currentProject.title) || '', 'DPO', this.activeTab];
    },
    enableGapRiskModule: function enableGapRiskModule() {
      var _this$currentProject2;
      return !!((_this$currentProject2 = this.currentProject) !== null && _this$currentProject2 !== void 0 && _this$currentProject2.enableGapRiskModule) || '';
    },
    showRiskAssessment: function showRiskAssessment() {
      var _this$currentProject3;
      return !!((_this$currentProject3 = this.currentProject) !== null && _this$currentProject3 !== void 0 && _this$currentProject3.showRiskAssessment) || '';
    },
    title: function title() {
      return this.projectType === 1 ? this.translate.dpoDashboard : this.translate.supervisorDashboard;
    },
    gapsAvailable: function gapsAvailable() {
      var gapArticleMap = this.gapDashboardData.gapArticleMap;
      return gapArticleMap && gapArticleMap.size > 0;
    },
    initiatives: function initiatives() {
      if (!this.gapData) return [];
      var initiatives = gapsToInitiatives(this.gapData);
      initiatives = this.addNodeTitleToGap(initiatives);
      return initiatives;
    },
    gapFilter: function gapFilter() {
      // Refactor this function to not use $refs
      var filter = this.$refs.filter;
      if (!filter) {
        return [];
      }
      var gaps = filter.$refs.gaps;
      if (!gaps || !gaps[0]) {
        return [];
      }
      return gaps[0].selected.map(function (selectedGap) {
        return selectedGap.id;
      });
    },
    pieChartData: function pieChartData() {
      var _this = this;
      var labels = [];
      var data = [];
      var backgroundColor = [];
      var total = 0;
      this.filteredOverview.forEach(function (entry) {
        labels.push(_this.$trans(entry.title));
        data.push(entry.value);
        backgroundColor.push(entry.color);
        total += +entry.value;
      });
      return {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: backgroundColor,
          total: total
        }]
      };
    },
    filteredOverview: function filteredOverview() {
      return this.getFilteredOverview(this.gapFilter, this.gapOverview);
    },
    supervisorTabs: function supervisorTabs() {
      return {
        actions: calcExecutionRights(this.currentUser, this.getAllSettings),
        controls: calcControlsRights(this.currentUser, this.getAllSettings)
      };
    },
    activeTab: function activeTab() {
      if (this.$route.hash) return this.$route.hash;
      if (this.supervisorTabs.actions) return '#actions';
      if (this.supervisorTabs.controls) return '#controls';
      return '';
    },
    selectors: function selectors() {
      return [{
        list: 'gaps',
        label: this.translate.gaps,
        preSelected: this.predefinedGapFilter
      }, {
        list: 'nodes',
        label: this.translate.nodes,
        preSelected: this.predefinedNodesFilter,
        toggle: {
          title: this.translate.includeUnderlyingNodes,
          option: this.includeChildToggleOption,
          event: this.onIncludeChildNodesToggle
        }
      }, {
        list: 'organisations',
        label: this.translate.organisations,
        preSelected: this.predefinedOrganisationsFilter
      }, {
        list: 'users',
        label: this.responsibleTitle(true),
        preSelected: this.predefinedUsersFilter
      }, {
        list: 'tags',
        label: this.translate.tags,
        preSelected: this.predefinedTagsFilter
      }];
    },
    predefinedGapFilter: function predefinedGapFilter() {
      return [{
        id: this.gapTrafficLights[3].number,
        label: this.$trans(this.gapTrafficLights[3].legend),
        color: this.gapTrafficLights[3].color,
        text_color: this.gapTrafficLights[3].text_color
      }, {
        id: this.gapTrafficLights[4].number,
        label: this.$trans(this.gapTrafficLights[4].legend),
        color: this.gapTrafficLights[4].color,
        text_color: this.gapTrafficLights[4].text_color
      }];
    },
    currentProject: function currentProject() {
      return this.complianceProjectById(this.projectId);
    }
  }),
  mounted: function mounted() {
    this.fetchInitiatives();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useComplianceProjectsStore, {
    complianceProjectById: 'getProjectById'
  })), {}, {
    loadDashboardData: function loadDashboardData(filterString, filterObj) {
      var _this2 = this;
      var promises = [this.getGapDashboard(filterString), this.getGaps(filterObj, filterString)];
      this.isLoading = true;
      return Promise.all(promises).then(this.onDataFetched).then(this.fetchControls).catch(function (error) {
        return _this2.handleDashboardError(error);
      }).finally(function () {
        if (filterString === _this2.currentFilter) _this2.isLoading = false;
      });
    },
    loadData: function loadData() {
      var _this3 = this;
      return this.getGapSchemas().catch(function (error) {
        return _this3.handleDashboardError(error);
      });
    },
    processLinks: function processLinks(links) {
      var _this4 = this;
      links = links.filter(function (item) {
        return item !== null;
      });
      links.forEach(function (link) {
        link = nullKeysToString(link);
        Object.keys(link.userIds).forEach(function (key) {
          link.userIds[key] = getUsersFromIds(link.userIds[key], _this4.users);
        });
        link.organisationIds = getOrganisationsFromIds(link.organisationIds, _this4.orgs);
      });
    },
    onDataFetched: function onDataFetched() {
      this.handleGapLinks();
    },
    handleGapLinks: function handleGapLinks() {
      var _this5 = this;
      this.gapData.map(function (gap) {
        var _gap$links, _gap$links2;
        if ((gap === null || gap === void 0 || (_gap$links = gap.links) === null || _gap$links === void 0 ? void 0 : _gap$links.execution) !== undefined) {
          _this5.processLinks(gap.links.execution);
        }
        if ((gap === null || gap === void 0 || (_gap$links2 = gap.links) === null || _gap$links2 === void 0 ? void 0 : _gap$links2.control) !== undefined) {
          _this5.processLinks(gap.links.control);
        }
      });
    },
    addNodeTitleToGap: function addNodeTitleToGap(activities) {
      var _this6 = this;
      activities.map(function (activity) {
        activity.gap.map(function (gap) {
          var node = _this6.nodes.find(function (node) {
            return node.id === gap.nodeId;
          });
          gap.nodeTitle = node.title;
          gap.nodeUrl = node.url;
          gap.nodeProjectId = node.compliance_projects_id;
        });
      });
      return activities;
    },
    fetchInitiatives: function fetchInitiatives() {
      var _this7 = this;
      return getInitiatives().then(function (_ref) {
        var list = _ref.list;
        return _this7.allInitiatives = list;
      });
    },
    fetchControls: function fetchControls() {
      var _this8 = this;
      var controls = gapsToControls(this.gapData);
      if (controls.length === 0) {
        this.controls = [];
        return;
      }
      var idList = controls.map(function (control) {
        return control.id;
      });
      var query = 'idList=' + idList.join(',') + '&' + getDataQueryStringFromArray(['organisationIds']);
      this.lastUsedParams = {
        idList: idList,
        data: ['organisationIds']
      };
      return getControls('?' + query).then(function (response) {
        var controls = response.list;
        addGapsToControls(controls, _this8.gapData);
        controls = _this8.addNodeTitleToGap(controls);
        _this8.controls = controls;
      });
    },
    logReport: function logReport(_ref2) {
      var count = _ref2.count,
        type = _ref2.type;
      var params = this.activeTab === '#actions' ? {} : this.lastUsedParams;
      return _logReport(MODULES.COMPLIANCE, count, this.currentLocation, params, type);
    },
    getFilteredOverview: function getFilteredOverview(gapFilter, gapOverview) {
      if (gapFilter.length === 0) {
        return gapOverview;
      }
      return gapOverview.filter(function (gapDataItem) {
        return gapFilter.some(function (selectedGap) {
          return gapDataItem.traffic_light == selectedGap;
        });
      });
    },
    onDismissPreview: function onDismissPreview() {
      this.fetchData(JSON.parse(localStorage.getItem(this.localStorageKey)).filterObj);
    },
    getGapDataByIdx: function getGapDataByIdx(idx) {
      var gapByIndex = this.filteredOverview[idx];
      return this.getGapData(gapByIndex);
    }
  })
};