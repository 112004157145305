function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import ChatTag from '@/components/Organisms/AiAssistant/ChatTag';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TypingIndicator from '@/components/Atoms/LoadingIndicators/TypingIndicator';
import MarkdownRenderer from '@/components/Atoms/MarkdownRenderer';
import { SENDER_ROLES } from '@/components/Organisms/AiAssistant/utils';
export default {
  name: 'ChatMessage',
  components: {
    ChatTag: ChatTag,
    FeatherIcon: FeatherIcon,
    TypingIndicator: TypingIndicator,
    MarkdownRenderer: MarkdownRenderer
  },
  props: {
    message: {
      type: String,
      required: true
    },
    context: {
      type: String,
      required: false,
      default: '',
      note: 'question context'
    },
    sender: {
      type: String,
      required: true,
      validator: function validator(value) {
        return Object.keys(SENDER_ROLES).includes(value);
      }
    },
    source: {
      type: String,
      required: false,
      default: '',
      note: 'identify where message comes from'
    },
    quickQuestions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    citations: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    reloadMessageEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    disclaimerEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isDeletable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['add:quickQuestion', 'open:citation', 'reload:message', 'delete:message'],
  data: function data() {
    return {
      linesNumber: 4,
      isCopying: false,
      copyTimer: null,
      translate: {
        copy: this.$trans('Copy').toLowerCase(),
        retry: this.$trans('retry')
      }
    };
  },
  computed: {
    formattedMessage: function formattedMessage() {
      return this.replaceDocs(this.message);
    },
    styleByRole: function styleByRole() {
      if (this.isUserRole) {
        return 'self-end ml-4 bg-rm-active-blue--10 rounded-t-md rounded-bl-md';
      }
      return "mr-4 bg-rm-wisp rounded-t-md rounded-br-md glob-l2-sb";
    },
    isCustomRole: function isCustomRole() {
      return this.sender === SENDER_ROLES.custom;
    },
    isUserRole: function isUserRole() {
      return this.sender === SENDER_ROLES.user;
    },
    isSystemRole: function isSystemRole() {
      return this.sender === SENDER_ROLES.system;
    },
    sourceDetails: function sourceDetails() {
      var _this$source$split = this.source.split(' > '),
        _this$source$split2 = _slicedToArray(_this$source$split, 3),
        projectName = _this$source$split2[0],
        nodeName = _this$source$split2[1],
        fieldName = _this$source$split2[2];
      return {
        projectName: projectName,
        nodeName: nodeName,
        fieldName: fieldName
      };
    },
    copyIconStyles: function copyIconStyles() {
      return this.isCopying ? 'text-rm-signal-grey-dark' : 'text-rm-grey hover:text-rm-signal-grey-dark';
    }
  },
  unmounted: function unmounted() {
    if (this.copyTimer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    handleQuickQuestionClick: function handleQuickQuestionClick(event) {
      event.selected = true;
      this.$emit('add:quickQuestion', {
        message: event.label,
        context: this.context,
        source: this.source,
        actualMessage: event.actualMessage,
        quickQuestion: true
      });
    },
    handleCitationClick: function handleCitationClick(event) {
      var _event$target;
      var index = event === null || event === void 0 || (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.getAttribute('data-citation-index');
      if (index === undefined || index === null) return;
      this.$emit('open:citation', this.citations[index]);
    },
    replaceDocs: function replaceDocs() {
      var _this = this;
      var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      if (!message) return message;
      var docPattern = /\s?\[doc(\d+)\]/g;
      var matched = message.match(docPattern);
      var mapping = {};
      if (matched) {
        mapping = Array.from(new Set(matched.map(function (item) {
          return item.trim();
        }))).map(function (ref) {
          return +ref.match(/\d+/)[0];
        }).reduce(function (acc, current, index) {
          acc[current] = ++index;
          return acc;
        }, {});
      }
      return message.replace(docPattern, function (_, number) {
        return _this.getDocTemplate(number - 1, mapping[number] || number);
      });
    },
    getDocTemplate: function getDocTemplate(citationIndex, refNumber) {
      return "&nbsp;<a\n                href=\"#\"\n                class=\"inline-block relative -top-0.5rem -left-0.5 text-rm-active-blue h-3 min-w-3 bg-rm-active-blue--20 text-center leading-[11px] rounded-sm font-semibold no-underline border border-transparent hover:border-rm-active-blue text-[0.625rem]\"\n                data-citation-index=\"".concat(citationIndex, "\">").concat(refNumber, "</a>");
    },
    copyToClipboard: function copyToClipboard(text) {
      var _this2 = this;
      this.isCopying = true;
      navigator.clipboard.writeText(text);
      this.copyTimer = setTimeout(function () {
        _this2.isCopying = false;
      }, 1500);
    }
  }
};