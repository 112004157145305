import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow } from "vue";
var _hoisted_1 = {
  class: "questionnaire-assessment-page"
};
var _hoisted_2 = {
  key: 1,
  class: "assessments-wrap"
};
var _hoisted_3 = {
  class: "w-1/3 pr-4"
};
var _hoisted_4 = {
  class: "mt-4"
};
var _hoisted_5 = {
  for: "score-sort"
};
var _hoisted_6 = {
  key: 0,
  class: "answered-questions"
};
var _hoisted_7 = ["onClick"];
var _hoisted_8 = {
  class: "question"
};
var _hoisted_9 = {
  class: "show-answer"
};
var _hoisted_10 = {
  class: "show-answer-label"
};
var _hoisted_11 = {
  class: "question-answer-score"
};
var _hoisted_12 = {
  class: "question-answer-text"
};
var _hoisted_13 = {
  class: "w-2/3 pl-2 relative"
};
var _hoisted_14 = {
  key: 0,
  class: "absolute top-0 bottom-0 right-0 left-0 z-10"
};
var _hoisted_15 = {
  class: "right-block-col-left col-8"
};
var _hoisted_16 = {
  class: "right-top"
};
var _hoisted_17 = {
  key: 0,
  class: "right-top-description"
};
var _hoisted_18 = {
  key: 1,
  class: "right-top-second-description"
};
var _hoisted_19 = {
  class: "traffic-holder"
};
var _hoisted_20 = {
  class: "assessment-textarea-wrap"
};
var _hoisted_21 = {
  class: "mt-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_links_modal = _resolveComponent("links-modal");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_assessment_indicators = _resolveComponent("assessment-indicators");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_selector_tags = _resolveComponent("selector-tags");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showLinksModal ? (_openBlock(), _createBlock(_component_links_modal, {
    key: 0,
    "projects-list": $options.projectsOptions,
    "links-options": $options.linksOptions,
    "preselected-links": $data.preselectedLinks,
    initiatives: $data.initiatives,
    onSelectProject: $options.setLinksOption,
    onUpdateLinks: $options.changeLinks,
    onAddNewItem: $options.addNewItem,
    onDismiss: $options.toggleLinksModal
  }, null, 8 /* PROPS */, ["projects-list", "links-options", "preselected-links", "initiatives", "onSelectProject", "onUpdateLinks", "onAddNewItem", "onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [!$data.loaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.loaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.answeredQuestions,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_4, [_createElementVNode("label", _hoisted_5, [_withDirectives(_createElementVNode("input", {
    id: "score-sort",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.isSortedByScore = $event;
    }),
    type: "checkbox"
  }, null, 512 /* NEED_PATCH */), [[_vModelCheckbox, $data.isSortedByScore]]), _createTextVNode(" " + _toDisplayString($data.translate.sortByScore), 1 /* TEXT */)])]), $options.filteredAnsweredQuestions && $options.filteredAnsweredQuestions.length ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredAnsweredQuestions, function (item) {
    return _openBlock(), _createElementBlock("li", {
      key: item.id
    }, [_createElementVNode("div", {
      class: "question-item",
      onClick: function onClick($event) {
        return $options.toggleVisibility(item);
      }
    }, [_createElementVNode("span", _hoisted_8, _toDisplayString(item.questionnumber) + ". " + _toDisplayString(item.question), 1 /* TEXT */), _createElementVNode("span", _hoisted_9, [_createElementVNode("span", _hoisted_10, _toDisplayString($data.translate.answer), 1 /* TEXT */), _createVNode(_component_feather_icon, {
      class: "inline-block",
      color: $data.colors.system.primaryLight,
      icon: "".concat(item._expanded ? 'chevron-up' : 'chevron-down')
    }, null, 8 /* PROPS */, ["color", "icon"])])], 8 /* PROPS */, _hoisted_7), _withDirectives(_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.answers_options, function (answer, answerKey) {
      return _openBlock(), _createElementBlock("div", {
        key: answerKey,
        class: "question-answer"
      }, [_createElementVNode("span", _hoisted_11, _toDisplayString($options.getAnswereScore(item.questionobject, answerKey)), 1 /* TEXT */), _createElementVNode("span", _hoisted_12, _toDisplayString(answer), 1 /* TEXT */)]);
    }), 128 /* KEYED_FRAGMENT */))], 512 /* NEED_PATCH */), [[_vShow, item._expanded]])]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_13, [$options.currentProject.locked ? (_openBlock(), _createElementBlock("div", _hoisted_14)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createVNode(_component_risma_title, {
    title: $data.translate.assessment,
    class: "main-right-title"
  }, null, 8 /* PROPS */, ["title"]), $props.data.questionnaireScore && $props.data.questionnaireScore.totalScore ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createElementVNode("p", null, [_createTextVNode(_toDisplayString($data.translate.theAnswersStatedInTheQuestionnaireHasSumUp) + ": ", 1 /* TEXT */), _createElementVNode("strong", null, _toDisplayString($props.data.questionnaireScore.totalScore), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), $props.data.questionnaireScore && $props.data.questionnaireScore.helpText ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createVNode(_component_traffic_light, {
    color: $props.data.questionnaireScore.helpText.color || '#000000'
  }, null, 8 /* PROPS */, ["color"])]), _createElementVNode("p", null, _toDisplayString($props.data.questionnaireScore.helpText.assessmentText), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]), $data.assessmentIndicatorData && $props.data ? (_openBlock(), _createBlock(_component_assessment_indicators, {
    key: 0,
    "model-value": $data.assessmentIndicatorData,
    "total-score": $props.data.questionnaireScore.totalScore
  }, null, 8 /* PROPS */, ["model-value", "total-score"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_20, [_createVNode(_component_tiny_mce_box, {
    type: "mini",
    "always-open": true,
    "read-only": !!$options.currentProject.locked,
    "initial-content": _ctx.assessment,
    headline: $data.translate.assessment,
    onChanged: $options.updateAssessment
  }, null, 8 /* PROPS */, ["read-only", "initial-content", "headline", "onChanged"])]), _createElementVNode("div", _hoisted_21, [!$options.currentProject.locked ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    class: "mb-2",
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.toggleLinksModal && $options.toggleLinksModal.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "inline-block stroke-3 align-text-bottom"
  }), _createTextVNode(" " + _toDisplayString($data.translate.addLinks), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredLinkModules, function (linkModule, index) {
    return _openBlock(), _createElementBlock("div", {
      key: linkModule + index,
      class: "py-4"
    }, [_createVNode(_component_selector_tags, {
      module: linkModule,
      tags: $options.links[linkModule],
      title: $data.ModuleTitles[linkModule],
      "has-traffic-lights": true,
      "has-delete-options": !$options.currentProject.locked,
      onDelete: function onDelete($event) {
        return $options.deleteLink(linkModule, $event);
      }
    }, null, 8 /* PROPS */, ["module", "tags", "title", "has-delete-options", "onDelete"])]);
  }), 128 /* KEYED_FRAGMENT */))])])])])) : _createCommentVNode("v-if", true)])]);
}