import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = {
  class: "flex-grow w-1/2"
};
var _hoisted_4 = {
  key: 2,
  class: "flex mb-10"
};
var _hoisted_5 = {
  key: 0,
  class: "w-80 mr-10 mb-3"
};
var _hoisted_6 = {
  key: 1,
  class: "w-80"
};
var _hoisted_7 = {
  key: 3
};
var _hoisted_8 = {
  class: "mb-4"
};
var _hoisted_9 = {
  key: 5,
  class: "my-8"
};
var _hoisted_10 = {
  key: 6,
  class: "options"
};
var _hoisted_11 = {
  class: "col-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_wizard_flow_wrapper = _resolveComponent("wizard-flow-wrapper");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_risk_grid = _resolveComponent("risk-grid");
  var _component_risk_assessments_isms = _resolveComponent("risk-assessments-isms");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_doughnut_chart = _resolveComponent("risma-doughnut-chart");
  var _component_questionnaire_stats = _resolveComponent("questionnaire-stats");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [!$props.data ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [$options.showWizardFlow ? (_openBlock(), _createBlock(_component_wizard_flow_wrapper, {
    key: 0,
    questionnaires: $props.data.questionnaires,
    "node-id": $props.nodeId,
    "project-id": $props.projectId
  }, null, 8 /* PROPS */, ["questionnaires", "node-id", "project-id"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_tiny_mce_box, {
    class: _normalizeClass($options.showWizardFlow ? 'pr-6' : ''),
    headline: $options.descriptionLabel,
    "initial-content": _ctx.description,
    "read-only": $options.isFieldReadyOnly($data.NodeFields.DESCRIPTION),
    "ai-source": $options.aiSourceForDescription,
    onChanged: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.description = $event;
    })
  }, null, 8 /* PROPS */, ["class", "headline", "initial-content", "read-only", "ai-source"]), $options.showPurpose ? (_openBlock(), _createBlock(_component_tiny_mce_box, {
    key: 0,
    headline: $options.purposeLabel,
    "initial-content": _ctx.purpose,
    "read-only": $options.isFieldReadyOnly($data.NodeFields.PURPOSE),
    class: "mt-4",
    "ai-source": $options.aiSourceForPurpose,
    onChanged: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.purpose = $event;
    })
  }, null, 8 /* PROPS */, ["headline", "initial-content", "read-only", "ai-source"])) : _createCommentVNode("v-if", true), $options.showStatus ? (_openBlock(), _createBlock(_component_tiny_mce_box, {
    key: 1,
    headline: $options.statusLabel,
    "initial-content": _ctx.status,
    "read-only": !!$options.currentProject.locked,
    class: "mb-6 mt-4",
    onChanged: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.status = $event;
    })
  }, null, 8 /* PROPS */, ["headline", "initial-content", "read-only"])) : _createCommentVNode("v-if", true)]), $options.showRiskAssessment && $data.isRiskDataLoaded && _ctx.currentPeriod && _ctx.riskRelevantToProject ? (_openBlock(), _createBlock(_component_risk_grid, {
    key: 0,
    "risk-grid-data": $options.matrix,
    "without-z-axis": true,
    "grid-box-size": $data.gridBoxSize,
    "enable-prev-position": false,
    "is-grid-clickable": false,
    "start-color": _ctx.startColor,
    "end-color": _ctx.endColor,
    "axis-with-labels": false,
    "custom-heatmap-options": _ctx.currentRiskProject.customHeatmapEnabled ? _ctx.currentRiskProject.heatmapOptions : [],
    class: "pl-10 pt-12",
    "dot-label-hover-function": $options.dotHoverFunction,
    "dot-connector-enabled": _ctx.inherentRisksEnabled
  }, null, 8 /* PROPS */, ["risk-grid-data", "grid-box-size", "start-color", "end-color", "custom-heatmap-options", "dot-label-hover-function", "dot-connector-enabled"])) : _createCommentVNode("v-if", true)]), $options.showRiskAssessment && $data.isRiskDataLoaded ? (_openBlock(), _createBlock(_component_risk_assessments_isms, {
    key: 1,
    activity: _ctx.risk,
    "obj-type": $props.data.objType,
    "current-risk-project": _ctx.currentRiskProject,
    period: _ctx.currentPeriod,
    threats: _ctx.threatsWithCategoryLabel,
    "custom-threats": _ctx.customThreats,
    "custom-threats-enabled": _ctx.customThreatsEnabled,
    "inherent-risks-enabled": _ctx.inherentRisksEnabled,
    "read-only": !!$options.currentProject.locked || _ctx.isCreatingRisk,
    "threat-update-in-progress": _ctx.threatUpdateInProgress,
    class: "mb-10",
    onChanged: _ctx.onRiskAssesmentsChange,
    onUpdateCustomThreat: _ctx.onUpdateCustomThreat
  }, null, 8 /* PROPS */, ["activity", "obj-type", "current-risk-project", "period", "threats", "custom-threats", "custom-threats-enabled", "inherent-risks-enabled", "read-only", "threat-update-in-progress", "onChanged", "onUpdateCustomThreat"])) : _createCommentVNode("v-if", true), $options.showRtoRpo ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_ctx.rtoOptions && _ctx.rtoOptions.length ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_single_select, {
    title: $data.translate.recoveryTimeObjective,
    "model-value": _ctx.rtoValue,
    options: _ctx.rtoOptions,
    "un-sorted": true,
    "with-reset": true,
    placeholder: $data.translate.selectRto,
    disabled: !!$options.rtoRpoReadOnly,
    class: "mr-2",
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return _ctx.selectRecoveryValue($event, _ctx.rtoFieldId);
    })
  }, null, 8 /* PROPS */, ["title", "model-value", "options", "placeholder", "disabled"])])) : _createCommentVNode("v-if", true), _ctx.rpoOptions && _ctx.rpoOptions.length ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_single_select, {
    title: $data.translate.recoveryPointObjective,
    "model-value": _ctx.rpoValue,
    options: _ctx.rpoOptions,
    "un-sorted": true,
    "with-reset": true,
    placeholder: $data.translate.selectRpo,
    disabled: !!$options.rtoRpoReadOnly,
    class: "mr-2",
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return _ctx.selectRecoveryValue($event, _ctx.rpoFieldId);
    })
  }, null, 8 /* PROPS */, ["title", "model-value", "options", "placeholder", "disabled"])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.showGapAnalysis ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $data.translate.gapAnalysis,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", null, _toDisplayString($data.translate.gapNumber) + ": " + _toDisplayString($props.data.values.gap_records_count), 1 /* TEXT */), _createElementVNode("div", null, _toDisplayString($data.translate.gapsSet) + ": " + _toDisplayString($props.data.values.gap_records_answered), 1 /* TEXT */), _createElementVNode("div", null, _toDisplayString($data.translate.gapSetAsNa) + ": " + _toDisplayString($props.data.values.gap_records_na), 1 /* TEXT */)]), _createVNode(_component_risma_doughnut_chart, {
    datasets: $options.gapAnalysisChartData.datasets,
    labels: $options.gapAnalysisChartData.labels,
    "show-data-labels": true,
    "percentage-value": false,
    "legend-position": "right",
    class: "max-w-350px"
  }, null, 8 /* PROPS */, ["datasets", "labels"])])) : _createCommentVNode("v-if", true), $options.showQuestionnaireStats ? (_openBlock(), _createBlock(_component_questionnaire_stats, {
    key: 4,
    questionnaires: $props.data.questionnaires
  }, null, 8 /* PROPS */, ["questionnaires"])) : _createCommentVNode("v-if", true), _ctx.activityTypes.length && $props.data.activityTypeId && $props.data.activityTypeId !== -1 && $props.data.customFieldValues ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_activity_custom_fields, {
    "activity-type": _ctx.currentActivityType,
    "custom-field-values": $props.data.customFieldValues,
    disabled: !!$options.currentProject.locked,
    "project-id": $props.projectId,
    onChanged: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('propertyChanged', {
        property: 'customFieldValuesParsed',
        value: $event
      });
    })
  }, null, 8 /* PROPS */, ["activity-type", "custom-field-values", "disabled", "project-id"])])) : _createCommentVNode("v-if", true), $options.canDeleteNode ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("p", null, [_createElementVNode("span", {
    class: "inline-flex cursor-pointer",
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return $options.confirmDelete();
    })
  }, [_createVNode(_component_feather_icon, {
    color: "black",
    icon: "x"
  }), _createElementVNode("u", null, _toDisplayString($data.translate.deleteNode), 1 /* TEXT */)])])])])) : _createCommentVNode("v-if", true)]))]);
}