import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "grid grid-cols-3 gap-4 items-end"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_select, {
    title: $options.ictServiceProviderLabel,
    options: $props.options.serviceProviders,
    "selected-options": $props.selectedOptions.serviceProviders,
    placeholder: $data.translate.ictServiceProvider,
    disabled: $props.readOnly,
    "label-key": "label",
    "track-by": "value",
    onSelected: _cache[0] || (_cache[0] = function ($event) {
      return $options.handleUpdate('serviceProviders', $event);
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "disabled"]), _createVNode(_component_risma_select, {
    title: $options.functionIdentifierLabel,
    options: $props.options.companyFunctions,
    "selected-options": $props.selectedOptions.functionIds,
    placeholder: $data.translate.functionIdentifier,
    disabled: $props.readOnly,
    "label-key": "label",
    "track-by": "value",
    onSelected: _cache[1] || (_cache[1] = function ($event) {
      return $options.handleUpdate('functionIds', $event);
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "disabled"]), _createVNode(_component_risma_select, {
    title: $options.typeOfIctServicesLabel,
    options: $props.options.typeOfICTService,
    "selected-options": $props.selectedOptions.typeOfService,
    placeholder: $data.translate.typeOfIctServices,
    disabled: $props.readOnly,
    "label-key": "label",
    "track-by": "value",
    onSelected: _cache[2] || (_cache[2] = function ($event) {
      return $options.handleUpdate('typeOfService', $event);
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "disabled"]), _createVNode(_component_single_select, {
    "model-value": $props.selectedOptions.storageOfData,
    disabled: $props.readOnly,
    title: $options.storageOfDataLabel,
    options: $props.options.binaryYesNo,
    placeholder: $data.translate.storageOfData,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $options.handleUpdate('storageOfData', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "title", "options", "placeholder"]), _createVNode(_component_risma_select, {
    title: $options.dataLocationLabel,
    options: $props.options.countryCodes,
    "selected-options": $props.selectedOptions.dataLocation,
    placeholder: $data.translate.dataLocation,
    disabled: $props.readOnly,
    "label-key": "label",
    "track-by": "value",
    onSelected: _cache[4] || (_cache[4] = function ($event) {
      return $options.handleUpdate('dataLocation', $event);
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "disabled"]), _createVNode(_component_risma_select, {
    title: $options.managementLocationLabel,
    options: $props.options.countryCodes,
    "selected-options": $props.selectedOptions.dataManagementLocation,
    placeholder: $data.translate.ictServiceProvider,
    disabled: $props.readOnly,
    "label-key": "label",
    "track-by": "value",
    onSelected: _cache[5] || (_cache[5] = function ($event) {
      return $options.handleUpdate('dataManagementLocation', $event);
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "disabled"]), _createVNode(_component_single_select, {
    "model-value": $props.selectedOptions.dataSensitiveness,
    disabled: $props.readOnly,
    placeholder: $data.translate.dataSensitiveness,
    title: $options.dataSensitivenessLabel,
    options: $props.options.sensitivenessOfData,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $options.handleUpdate('dataSensitiveness', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "placeholder", "title", "options"]), _createVNode(_component_single_select, {
    "model-value": $props.selectedOptions.relianceLevel,
    disabled: $props.readOnly,
    placeholder: $data.translate.relianceLevel,
    title: $options.relianceLevelLabel,
    options: $props.options.relianceLevel,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return $options.handleUpdate('relianceLevel', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "placeholder", "title", "options"])]);
}