import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  class: "assessment-indicators-wrap"
};
var _hoisted_2 = {
  class: "assessments-list"
};
var _hoisted_3 = {
  key: 0,
  class: "item-title item-title-first"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.modelValue, function (item, index) {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      style: _normalizeStyle({
        width: "".concat(item.width, "%")
      }),
      class: _normalizeClass([{
        filled: item.isFilled
      }, "list-item"])
    }, [_cache[0] || (_cache[0] = _createElementVNode("div", {
      class: "line"
    }, null, -1 /* HOISTED */)), _createElementVNode("span", {
      class: _normalizeClass(["item-title", [{
        '!right-3': index === $props.modelValue.length - 1 && item.title.length === 5
      }, {
        '!right-4': index === $props.modelValue.length - 1 && item.title.length === 6
      }]])
    }, _toDisplayString(item.title), 3 /* TEXT, CLASS */), index === 0 ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(item.minScore), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 6 /* CLASS, STYLE */);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", {
    style: _normalizeStyle({
      width: "".concat($options.progressInPercent, "%")
    }),
    class: "progress-line"
  }, null, 4 /* STYLE */)]);
}