function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import * as Utils from '@/utils/Utils';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import TrafficLightSelector from '@/components/Organisms/Selectors/TrafficLightSelector';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import InputFieldSelect from '@/components/Atoms/Inputs/InputFieldSelect';
import HelpToolTip from '@/components/Molecules/HelpToolTip';
import CustomFieldTextBox from '@/components/Molecules/CustomFieldTextBox';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import { CustomFieldsBaseMixin } from '@/components/Molecules/CustomFieldsBaseMixin';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
export default {
  name: 'RepeatableFields',
  introduction: 'Repeatable Fields',
  description: 'Repeatable custom fields (on DEP)',
  token: '<repeatable-fields :data="data"/>',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    InputField: InputField,
    TrafficLightSelector: TrafficLightSelector,
    DateTimePicker: DateTimePicker,
    InputFieldSelect: InputFieldSelect,
    HelpToolTip: HelpToolTip,
    CustomFieldTextBox: CustomFieldTextBox,
    RismaSelect: RismaSelect
  },
  mixins: [CustomFieldsBaseMixin],
  props: {
    id: {
      type: Number,
      required: true,
      note: 'Main id of repeatable cf'
    },
    data: {
      type: Object,
      required: true,
      note: 'Repeatable field self (field)'
    },
    customFieldValues: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Saved custom field values'
    },
    allFields: {
      type: Array,
      required: true,
      note: 'All fields (for save)'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Are custom fields disabled?'
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      entityGroups: [],
      CustomFieldTypes: CustomFieldTypes,
      currentTextareaId: null,
      timeout: null,
      translate: getTranslate['RepeatableFields']()
    };
  },
  beforeMount: function beforeMount() {
    if (this.customFieldValues.length) {
      this.setupCustomFieldValues();
    } else {
      this.addRowToEntityGroups();
    }
  },
  methods: {
    addRowToEntityGroups: function addRowToEntityGroups() {
      this.entityGroups.push(this.preparePureEntity(this.data.children));
    },
    emitChanges: function emitChanges() {
      var _this = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        var values = _this.entityGroups.length ? _this.entityGroups : [_this.preparePureEntity(_this.data.children)];
        _this.$emit('changed', values);
      }, 300);
    },
    onChangeRepeatableField: function onChangeRepeatableField(field, e, rowNumber) {
      field.selectedValue = e;
      field.rowNumber = rowNumber;
      this.emitChanges();
    },
    setupCustomFieldValues: function setupCustomFieldValues() {
      var _this$customFieldValu,
        _this2 = this;
      var currentCfValues = ((_this$customFieldValu = this.customFieldValues.find(function (cf) {
        return cf.fieldId === _this2.id && cf.rows;
      })) === null || _this$customFieldValu === void 0 ? void 0 : _this$customFieldValu.rows) || [];
      currentCfValues = Array.isArray(currentCfValues) ? currentCfValues : Object.values(currentCfValues);
      currentCfValues.forEach(function (row, rowIdx) {
        _this2.addRowToEntityGroups();
        row.forEach(function (cfValue) {
          var existingField = _this2.entityGroups[rowIdx].find(function (cf) {
            return cf.id === cfValue.fieldId;
          });
          if (!existingField) return;
          if (existingField.typeId === CustomFieldTypes.SELECT) {
            var options = Utils.getProperSelectedOptions(existingField.options);
            var selectedOption = options.find(function (option) {
              return option.id === cfValue.value;
            });
            existingField.selectedValue = (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value) || null;
          } else if (existingField.typeId === CustomFieldTypes.SELECT_MULTI) {
            var _options = Utils.getProperSelectedOptions(existingField.options);
            var _selectedOption = _options.find(function (option) {
              return option.id === cfValue.value;
            });
            if (!existingField.selectedValue) {
              existingField.selectedValue = [];
            }
            if (_selectedOption) {
              existingField.selectedValue.push(_selectedOption);
            }
          } else {
            existingField.selectedValue = cfValue.value;
          }
        });
      });
    },
    onDelete: function onDelete(rowNumber) {
      var _this3 = this;
      this.$confirm(this.translate.deleteRepeatableField, this.translate.areYouSureYouWantToDeleteThisRepeatableField, function (res) {
        return res && _this3.removeEntity(rowNumber);
      }, {
        buttonOkText: this.translate.delete
      });
    },
    removeEntity: function removeEntity(rowNumber) {
      if (!this.entityGroups[rowNumber]) return;
      this.entityGroups = this.entityGroups.filter(function (_, index) {
        return index !== rowNumber;
      });
      this.emitChanges();
    },
    preparePureEntity: function preparePureEntity(data) {
      var result = data.map(function (item) {
        return _objectSpread({}, item);
      });
      return result;
    },
    toggleTextareaSize: function toggleTextareaSize(fieldId) {
      this.currentTextareaId = fieldId;
    }
  }
};