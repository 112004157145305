function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import cloneDeep from 'lodash/cloneDeep';
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { updateUser as _updateUser, changeUserActiveStatus } from '@/api/admin/user';
import { setUserProjectLevel } from '@/api/risma/data';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import Notification from '@/components/Molecules/Notification';
import { TabTypes } from '@/constants/Tabs';
import { createTabsList } from '@/utils/base/tabs';
import { saveStatus } from '@/constants/SaveStatuses';
import { MODULES } from '@/constants/modules';
import { getPasswordLength } from '@/utils/notifications';
import { isEmailValid, isPasswordValid } from '@/utils/validations';
import { ObjectTypes, OBJ_TYPE_TO_MODULE } from '@/constants/ObjectTypes';
export default {
  name: 'UserEntry',
  components: {
    DepWell: DepWell,
    Notification: Notification
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    id: {
      type: [String, Number],
      require: true,
      default: null,
      note: 'user id'
    }
  },
  emits: ['changeTab', 'updated'],
  data: function data() {
    return {
      isLoaded: false,
      user: null,
      userStatusChanged: false,
      userChanges: false,
      projectChanges: {},
      saveStatus: saveStatus.SAVED,
      updateTimer: 0,
      notificationMessage: '',
      notificationType: '',
      dirtyUser: {},
      forcePasswordUpdate: false,
      translate: getTranslate['UserEntry']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    tabs: function tabs() {
      var baseUrl = "/user/".concat(this.id);
      return createTabsList([TabTypes.DESCRIPTION, TabTypes.RIGHTS], baseUrl);
    },
    apiUserNotificationMessage: function apiUserNotificationMessage() {
      return this.translate.toProtectAPIaccount.replace('_amount_', this.passwordSettingLength);
    },
    fullUser: function fullUser() {
      return _objectSpread(_objectSpread({}, this.user), this.dirtyUser);
    },
    depHeaderProps: function depHeaderProps() {
      if (!this.fullUser) return {};
      return {
        id: this.fullUser.id,
        title: this.fullUser.fullname,
        editableTitle: true,
        tabs: this.tabs,
        isAttachmentEnabled: false,
        saveStatus: this.saveStatus,
        titleField: 'fullname',
        module: MODULES.USERS
      };
    },
    passwordSettingLength: function passwordSettingLength() {
      return getPasswordLength(this.fullUser.api_user, this.getSettingValue('feature.api_user_min_password_length'), this.getSettingValue('feature.enterprise_security'));
    }
  }),
  beforeMount: function beforeMount() {
    this.user = this.getUserById(this.id);
    if (!this.user) {
      this.handleShowNotification('error', this.translate.userDoesntExist);
    }
    this.isLoaded = true;
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useUserStore, {
    setCurrentUser: 'set'
  })), mapActions(useUsersStore, {
    updateUserInStore: 'update',
    getUserById: 'getUserById'
  })), {}, {
    setUpdateTimer: function setUpdateTimer() {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.saveUpdates, this.$debounceTimeout);
    },
    userProjectChanged: function userProjectChanged(updatedProject) {
      var property = updatedProject.project_id ? "compliance_".concat(updatedProject.project_id) : "risk_".concat(updatedProject.r_project_id);
      this.projectChanges = _defineProperty({}, property, updatedProject);
      this.saveUpdates();
    },
    userPropertiesChanged: function userPropertiesChanged(properties) {
      if (Object.keys(this.dirtyUser).length === 0) {
        this.dirtyUser = cloneDeep(this.user);
      }
      this.dirtyUser = _objectSpread(_objectSpread({}, this.dirtyUser), properties);
      this.userChanges = true;
      this.saveUpdates();
    },
    userPropertyChanged: function userPropertyChanged(_ref) {
      var _this = this;
      var property = _ref.property,
        value = _ref.value;
      if (property === 'active') {
        this.userStatusChanged = true;
      } else {
        this.userChanges = true;
      }
      if (property === 'api_user') {
        this.forcePasswordUpdate = !this.user.api_user && value;
        this.$nextTick(function () {
          value ? _this.handleShowNotification('info', _this.apiUserNotificationMessage) : _this.handleDismissNotification();
        });
      }
      if (Object.keys(this.dirtyUser).length === 0) {
        this.dirtyUser = cloneDeep(this.user);
      }
      this.dirtyUser[property] = value;
      this.setUpdateTimer();
    },
    saveUpdates: function saveUpdates() {
      var _this2 = this;
      var isClicked = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (!this.user.api_user && this.dirtyUser.api_user && !isClicked) return;
      this.saveStatus = saveStatus.SAVING;
      if (!this.isValid()) {
        //if it was removed and after that it would be hard for user to understand whats wrong
        if (!this.user.api_user && this.dirtyUser.api_user && !this.password) this.handleShowNotification('info', this.apiUserNotificationMessage);
        this.saveStatus = saveStatus.NOT_SAVED;
        return;
      }
      var promises = [this.updateUser(), this.updateUserStatus(), this.updateProjects()];
      return Promise.all(promises).then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 3),
          user = _ref3[0],
          userStatus = _ref3[1],
          projects = _ref3[2];
        if (projects.length) {
          projects.forEach(_this2.updateUserRightsByProject);
        }
        if (user) {
          _this2.user = user;
        }
        if (userStatus) {
          _this2.user.active = userStatus.active;
        }
        _this2.handleDismissNotification();
        _this2.$emit('updated', _this2.fullUser);
      }).catch(function (error) {
        var _error$response;
        var errorMessage = (error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error) || _this2.translate.anErrorOccurred;
        _this2.handleShowNotification('error', errorMessage);
      }).finally(function () {
        _this2.dirtyUser = {};
        _this2.saveStatus = saveStatus.SAVED;
      });
    },
    updateUser: function updateUser() {
      var _this3 = this;
      if (!this.userChanges) return Promise.resolve(null);
      return _updateUser(this.fullUser, this.fullUser.id).then(function (response) {
        _this3.userChanges = false;
        return response;
      });
    },
    updateUserStatus: function updateUserStatus() {
      var _this4 = this;
      if (!this.userStatusChanged) return Promise.resolve(null);
      return changeUserActiveStatus(this.fullUser.id, !this.fullUser.active ? {} : {
        undelete: 1
      }, this.fullUser).then(function (response) {
        _this4.userStatusChanged = false;
        return response;
      });
    },
    updateProjects: function updateProjects() {
      var _this5 = this;
      var promises = [];
      Object.values(this.projectChanges).forEach(function (params) {
        promises.push(setUserProjectLevel(params));
      });
      return Promise.all(promises).then(function () {
        var response = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        _this5.projectChanges = {};
        return response;
      });
    },
    updateUserRightsByProject: function updateUserRightsByProject(project) {
      var field = !project.riskProjectId ? OBJ_TYPE_TO_MODULE[ObjectTypes.COMPLIANCE_PROJECT] : OBJ_TYPE_TO_MODULE[ObjectTypes.RISK_PROJECT];
      var idx = this.fullUser.projects[field].findIndex(function (item) {
        return (project.riskProjectId || project.projectId) === item.id;
      });
      if (idx === -1) return;
      this.fullUser.projects[field][idx] = _objectSpread(_objectSpread({}, this.fullUser.projects[field][idx]), {}, {
        access_level: project.accessLevel,
        locked: project.locked,
        supervisor: project.supervisor
      });
      this.updateUserInStore(this.fullUser);
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
    },
    handleShowNotification: function handleShowNotification(type, message) {
      this.notificationType = type;
      this.notificationMessage = this.$trans(message);
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        clearTimeout(this.updateTimer);
        this.saveUpdates(true);
      }
    },
    isValid: function isValid() {
      var _this$fullUser, _this$fullUser2;
      var isPasswordEmpty = !this.fullUser.password && !this.forcePasswordUpdate;
      var isPasswordAcceptable = this.getSettingValue('feature.enterprise_security') ? isPasswordValid(this.fullUser.password, this.passwordSettingLength) : this.fullUser.api_user ? ((_this$fullUser = this.fullUser) === null || _this$fullUser === void 0 || (_this$fullUser = _this$fullUser.password) === null || _this$fullUser === void 0 ? void 0 : _this$fullUser.length) >= this.passwordSettingLength : ((_this$fullUser2 = this.fullUser) === null || _this$fullUser2 === void 0 || (_this$fullUser2 = _this$fullUser2.password) === null || _this$fullUser2 === void 0 ? void 0 : _this$fullUser2.length) >= 8;
      return this.fullUser && isEmailValid(this.fullUser.email) && (isPasswordEmpty || isPasswordAcceptable) && this.fullUser.username && this.fullUser.initials;
    }
  })
};