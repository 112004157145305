function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import AuditOverview from '@/components/Pages/Tabs/AuditOverview';
import ContractEntry from '@/components/Pages/Contracts/ContractEntry';
import ContractDescription from '@/components/Pages/Contracts/ContractDescription';
import ContractFinance from '@/components/Pages/Contracts/ContractFinance';
import ContractsOverviewWrapper from '@/components/Pages/Contracts/ContractsOverviewWrapper';
import ContractsFileDocuments from '@/components/Pages/Contracts/ContractsFileDocuments';
import ContractsDPAOverview from '@/components/Pages/Contracts/ContractsDPAOverview';
import ContractsDashboard from '@/components/Pages/Contracts/ContractsDashboard';
import ContractsMonthlyOverview from '@/components/Organisms/Contracts/ContractsMonthlyOverview';
import ContractsPayment from '@/components/Organisms/Contracts/ContractsPayment';
import ContractHistory from '@/components/Pages/Contracts/ContractHistory';
import ContractRelatedContracts from '@/components/Pages/Contracts/ContractRelatedContracts';
import ContractDocuments from '@/components/Pages/Contracts/ContractDocuments';
import MyCompanies from '@/components/Pages/Contracts/MyCompanies';
import LinksTab from '@/components/Organisms/LinksTab';
import Changelog from '@/components/Molecules/Changelog';
import QuestionnaireReport from '@/components/Pages/InformationAssets/Reports/QuestionnaireReport';
import ContractsReportWrapper from '@/components/Pages/Contracts/ContractsReportWrapper';
import QuestionnairePage from '@/components/Pages/Compliance/NodeEdit/QuestionnairePage';
import DoraReport from '@/components/Pages/Contracts/DoraReport';
import ContractPartyOverview from '@/components/Pages/Contracts/ContractPartyOverview';
import { userHasLevelContracts } from '@/routes/auth';
import { MODULES } from '@/constants/modules';
import { TabTypes, Tabs } from '@/constants/Tabs';
import { CONTRACT_STATUSES } from '@/constants/contracts/contract';
import { UserLevels } from '@/constants/UserLevels';
import { COMPANY } from '@/constants/routes/company';
import { getReportOptionsFromQuery } from '@/utils/report';
import { useSettingsStore } from '@/Store/settingsStore';
var ContractsOverviewComponent = function ContractsOverviewComponent() {
  if (useSettingsStore().getValue('feature.thin_contracts_overview_enable')) {
    return import('@/components/Pages/Contracts/ContractsThinOverview');
  }
  return import('@/components/Pages/Contracts/ContractsOverview');
};
export default (function () {
  return [{
    path: '/contracts/overview',
    component: ContractsOverviewWrapper,
    meta: {
      userRightsFunction: userHasLevelContracts(UserLevels.NORMAL)
    },
    redirect: {
      name: 'ContractsOverview'
    },
    children: [{
      path: 'all',
      name: 'ContractsOverview',
      component: ContractsOverviewComponent
    }, {
      path: 'my',
      name: 'MyContracts',
      component: ContractsOverviewComponent,
      props: function props() {
        return {
          isMy: true
        };
      }
    }, {
      path: 'mycompanies',
      component: MyCompanies,
      name: COMPANY.MyCompanies
    }, {
      path: 'expired',
      name: 'ExpiredContracts',
      component: ContractsOverviewComponent,
      props: function props() {
        return {
          isExpired: true
        };
      }
    }, {
      path: 'dpa',
      component: ContractsDPAOverview
    }, {
      path: 'documents',
      component: ContractsFileDocuments
    }, {
      path: 'content',
      component: ContractsFileDocuments,
      props: function props() {
        return {
          isContent: true
        };
      }
    }]
  }, {
    path: '/contracts/reports',
    component: ContractsReportWrapper,
    redirect: '/contracts/reports/main',
    meta: {
      userRightsFunction: userHasLevelContracts(UserLevels.PRIVILEGED)
    },
    children: [{
      path: 'questionnaire',
      component: QuestionnaireReport,
      name: 'ContractQuestionnaireReport',
      meta: {},
      props: function props(route) {
        var savedReportOptions = getReportOptionsFromQuery(route.query);
        return _objectSpread(_objectSpread({}, route.params), {}, {
          savedReportOptions: savedReportOptions,
          module: MODULES.CONTRACTS
        });
      }
    }, {
      path: 'main',
      name: 'ContractsReport',
      component: function component() {
        if (useSettingsStore().getValue('feature.thin_contracts_report_enable')) {
          return import('@/components/Pages/Contracts/ContractsThinReport');
        }
        return import('@/components/Pages/Contracts/ContractsReport');
      }
    }]
  }, {
    path: '/contracts/dashboard',
    name: 'ContractsDashboard',
    component: ContractsDashboard,
    redirect: {
      path: "/contracts/dashboard/".concat(CONTRACT_STATUSES.expiring)
    },
    meta: {
      keyFunction: function keyFunction() {
        return '/contracts/dashboard';
      },
      userRightsFunction: userHasLevelContracts(UserLevels.PRIVILEGED)
    },
    children: [{
      path: 'payment',
      component: ContractsPayment
    }, {
      path: ':status',
      props: true,
      component: ContractsMonthlyOverview
    }]
  }, {
    path: '/contracts/dora',
    component: DoraReport,
    props: true,
    meta: {
      userRightsFunction: function userRightsFunction() {
        return useSettingsStore().getValue('feature.company.dora_enable') && userHasLevelContracts(UserLevels.PRIVILEGED)();
      }
    }
  }, {
    path: '/contracts/contract/:id',
    component: ContractEntry,
    redirect: {
      name: 'ContractDescription'
    },
    props: true,
    meta: {
      keyFunction: function keyFunction($route) {
        return "/contracts/contract/".concat($route.params.id);
      }
    },
    children: [{
      path: TabTypes.DESCRIPTION,
      name: 'ContractDescription',
      component: ContractDescription
    }, {
      path: TabTypes.PAYMENTS,
      component: ContractFinance,
      name: 'ContractFinance',
      beforeEnter: function beforeEnter(to, __, next) {
        var isFinanceTabEnabled = useSettingsStore().getValue('feature.contracts_finance_tab_enabled');
        isFinanceTabEnabled !== 1 ? next({
          name: 'ContractDescription',
          params: _objectSpread({}, to.params)
        }) : next();
      }
    }, {
      path: 'dpm/:idItem?',
      name: 'ContractAudit',
      props: function props(route) {
        return {
          id: route.params.id,
          module: MODULES.CONTRACTS,
          idItem: route.params.idItem
        };
      },
      component: AuditOverview
    }, {
      path: TabTypes.LINKS,
      component: LinksTab,
      props: {
        module: MODULES.CONTRACTS
      }
    }, {
      path: TabTypes.CONTRACT_PARTY,
      component: ContractPartyOverview
    }, {
      path: Tabs[TabTypes.RELATED_CONTRACTS].url,
      component: ContractRelatedContracts
    }, {
      path: TabTypes.HISTORY,
      component: ContractHistory
    }, {
      path: TabTypes.DOCUMENTS,
      component: ContractDocuments
    }, {
      path: TabTypes.CHANGELOG,
      component: Changelog
    }, {
      path: 'questionnaire/:questionnaireId',
      component: QuestionnairePage,
      props: function props(route) {
        return {
          id: route.params.id,
          pageType: 'contract',
          questionnaireId: route.params.questionnaireId
        };
      },
      meta: {
        keyFunction: function keyFunction($route) {
          return 'questionnaire/' + $route.params.questionnaireId;
        }
      }
    }]
  }];
});