import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_router_view = _resolveComponent("router-view");
  var _component_vertical_menu_wrapper = _resolveComponent("vertical-menu-wrapper");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_vertical_menu_wrapper, null, {
    menu: _withCtx(function () {
      return [$options.navItemLists.length ? (_openBlock(), _createBlock(_component_vertical_menu, {
        key: 0,
        items: $options.navItemLists,
        "opened-groups": $data.openedGroups
      }, null, 8 /* PROPS */, ["items", "opened-groups"])) : _createCommentVNode("v-if", true)];
    }),
    content: _withCtx(function () {
      return [_createVNode(_component_router_view, null, {
        default: _withCtx(function (_ref) {
          var Component = _ref.Component;
          return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            "filtered-risk-projects": $options.filteredRiskProjects,
            onRefreshMenu: $options.prepareNavigation,
            onRefreshMenuWithRiskProjects: $options.init,
            onRefreshRiskProjects: $options.getRiskProjects,
            onUpdateHeader: _cache[0] || (_cache[0] = function ($event) {
              return _ctx.$emit('updateHeader');
            })
          }, null, 40 /* PROPS, NEED_HYDRATION */, ["filtered-risk-projects", "onRefreshMenu", "onRefreshMenuWithRiskProjects", "onRefreshRiskProjects"]))];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  })]);
}