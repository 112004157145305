import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = ["onMouseenter"];
var _hoisted_2 = ["title"];
var _hoisted_3 = {
  key: 0,
  class: "h-1rem w-1.2rem ml-1 mr-0.5 content-center flex-none"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tags, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: "tag_item_".concat(index),
      class: "max-w-full",
      onMousedown: _cache[0] || (_cache[0] = _withModifiers(function () {}, ["prevent"])),
      onMouseleave: _cache[1] || (_cache[1] = function ($event) {
        return $data.hoveredElementIndex = null;
      }),
      onMouseenter: function onMouseenter($event) {
        return $data.hoveredElementIndex = index;
      }
    }, [_renderSlot(_ctx.$slots, "tag", {
      option: item
    }, function () {
      return [_createElementVNode("div", {
        class: _normalizeClass(["max-w-inherit mr-2 mb-0 min-h-6 flex items-center pl-2 py-0.5 border rounded-md glob-l1-m", $options.getTagsClasses(item[$props.trackBy])]),
        style: _normalizeStyle(item.color ? {
          color: $data.hoveredElementIndex === index ? item.color : item.text_color || 'white',
          background: $data.hoveredElementIndex === index ? item.text_color : item.color,
          borderColor: item.color
        } : '')
      }, [_renderSlot(_ctx.$slots, "tagLabel", {
        option: item
      }, function () {
        return [_createElementVNode("span", {
          title: item[$props.labelKey],
          class: _normalizeClass(["break-all text-left", {
            truncate: $props.truncateTagText
          }])
        }, _toDisplayString($props.truncateLength ? _ctx.$truncate(item[$props.labelKey], $props.truncateLength) : item[$props.labelKey]), 11 /* TEXT, CLASS, PROPS */, _hoisted_2)];
      }), !$props.disabled && !$props.lockedOptions.includes(item[$props.trackBy]) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_feather_icon, {
        tabindex: $props.tabindex,
        icon: "x",
        class: _normalizeClass(["m-auto cursor-pointer hover:stroke-3 stroke-2 w-4 h-4 hover:w-1.2rem pl-0.5 pr-0.5", item.color ? 'text-inherit' : 'text-rm-active-blue']),
        onMousedown: _withModifiers(function ($event) {
          return _ctx.$emit('remove', index);
        }, ["prevent"]),
        onKeyup: _withKeys(_withModifiers(function ($event) {
          return _ctx.$emit('remove', index);
        }, ["stop"]), ["enter"])
      }, null, 8 /* PROPS */, ["tabindex", "class", "onMousedown", "onKeyup"])])) : _createCommentVNode("v-if", true)], 6 /* CLASS, STYLE */)];
    })], 40 /* PROPS, NEED_HYDRATION */, _hoisted_1);
  }), 128 /* KEYED_FRAGMENT */);
}