import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import OptionsList from '@/components/Molecules/OptionsList/OptionsList';
export default {
  name: 'GapAttributesList',
  components: {
    RismaTitle: RismaTitle,
    InputField: InputField,
    OptionsList: OptionsList
  },
  props: {
    category: {
      type: String,
      required: true,
      default: null
    },
    labels: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    showErrors: {
      type: Boolean,
      required: false,
      default: false
    },
    categoryError: {
      type: String,
      required: false,
      default: ''
    },
    saveButtonClickCounter: {
      required: false,
      type: Number,
      default: 0,
      note: 'required to not display errors below newly created items before user make another save button click'
    }
  },
  emits: ['updateCategory', 'updateLabels'],
  data: function data() {
    return {
      localLabels: [],
      translate: getTranslate['GapAttributesList']()
    };
  },
  computed: {
    confirmOnDelete: function confirmOnDelete() {
      var body = this.localLabels.length <= 1 ? this.translate.areYouSureYouWantToDeleteAttributeCategory : this.translate.areYouSureYouWantToDeleteAttributeValue;
      return {
        title: this.translate.delete,
        body: body
      };
    }
  },
  beforeMount: function beforeMount() {
    this.localLabels = this.labels.map(function (item) {
      return {
        label: item.label,
        id: item.id
      };
    });
  },
  methods: {
    onOptionsChange: function onOptionsChange(options) {
      this.$emit('updateLabels', options);
    }
  }
};