import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
export default {
  name: 'RismaSelectKitchen',
  components: {
    RismaSelect: RismaSelect,
    FeatherIcon: FeatherIcon,
    Checkboxes: Checkboxes
  },
  data: function data() {
    return {
      RismaSelectOptions: [{
        id: '1',
        option: 'Hello'
      }, {
        id: '2',
        option: 'To'
      }, {
        id: '3',
        option: 'You'
      }, {
        id: '4',
        option: 'Me'
      }, {
        id: '5',
        option: 'And'
      }, {
        id: '6',
        option: 'Irene'
      }],
      RismaSelectTruncateOption: {
        id: '7',
        option: 'Very long title that has about 218 characters length, needed to test truncation, by default from the spec it should be 200 characters, but maybe it should be less. This text is also added to cover up last 40 characters'
      },
      RismaSelectTruncateSelected: [],
      RismaSelectOptionsSelected: [{
        id: '2',
        option: 'To'
      }],
      RismaSelectOptionsSelectedSecondary: [{
        id: '2',
        option: 'To'
      }],
      RismaSelectNewOptions: [],
      RismaSelectCustomlabel: [{
        id: 1,
        title: "It's first option",
        icon: 'arrow-left'
      }, {
        id: 2,
        title: "It's second option",
        icon: 'arrow-right'
      }],
      RismaSelectCustomlabelSelected: [],
      groupedOptions: [{
        language: 'Javascript',
        libs: [{
          name: 'Vue.js',
          category: 'Front-end'
        }, {
          name: 'Adonis',
          category: 'Backend'
        }]
      }, {
        language: 'Ruby',
        libs: [{
          name: 'Rails',
          category: 'Backend'
        }, {
          name: 'Sinatra',
          category: 'Backend'
        }]
      }, {
        language: 'Other',
        libs: [{
          name: 'Laravel',
          category: 'Backend'
        }, {
          name: 'Phoenix',
          category: 'Backend'
        }]
      }],
      RismaSelectOptionsExpanded: [{
        id: '1',
        option: 'Hello'
      }, {
        id: '2',
        option: 'To'
      }, {
        id: '3',
        option: 'You'
      }, {
        id: '4',
        option: 'Me'
      }, {
        id: '5',
        option: 'And'
      }, {
        id: '6',
        option: 'Irene'
      }],
      RismaSelectOptionsExpandedSelected: [],
      groupedSelectedOptions: [],
      groupSelectable: false
    };
  },
  methods: {
    handleCreateTag: function handleCreateTag(name) {
      this.RismaSelectOptionsExpanded.push({
        id: this.RismaSelectOptionsExpanded.length + 1,
        option: name
      });
    }
  }
};