function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useWorkflowStore } from '@/Store/workflowStore';
import { getMy as getMyIncidents, getArchived as getArchivedIncidents } from '@/api/incident/incident';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Notification from '@/components/Molecules/Notification';
import PreviewListTile from '@/components/Molecules/PreviewList/PreviewListTile';
import CompactCard from '@/components/Molecules/Cards/CompactCard';
import ViewSwitcher from '@/components/Molecules/ViewSwitcher';
import PreviewListExpanded from '@/components/Molecules/PreviewList/PreviewListExpanded';
import VerticalMenu from '@/components/Molecules/VerticalMenu/VerticalMenu';
import VerticalMenuWrapper from '@/components/Molecules/Wrappers/VerticalMenuWrapper';
import { STANDARD_VIEWS } from '@/constants/ViewTypes';
import { viewTypes } from '@/constants/ListViewType';
import { SortFieldsCompatibility } from '@/constants/SortFieldsCompatibility';
import { MODULES } from '@/constants/modules';
import { MypageSettings } from '@/constants/mypage/MypageSettings';
import { MypageKeys } from '@/constants/mypage/MypageKeys';
import { check403AccessError } from '@/utils/handleAPIErrors';
import { getSortFunction, sortByArrayOfFunctions, sortNumbers } from '@/utils/sort';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
var IncidentKeys = {
  MY_LIST: 'my',
  ARCHIVED_LIST: 'archived'
};
export default {
  name: 'MyIncidents',
  description: 'List of my incidents as cards',
  components: {
    RismaTitle: RismaTitle,
    PreviewListTile: PreviewListTile,
    CompactCard: CompactCard,
    ViewSwitcher: ViewSwitcher,
    SingleSelect: SingleSelect,
    PreviewListExpanded: PreviewListExpanded,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    VerticalMenu: VerticalMenu,
    VerticalMenuWrapper: VerticalMenuWrapper,
    Notification: Notification
  },
  props: {
    currentRouteGroup: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup: function setup() {
    var router = useRouter();
    var isMy = computed(function () {
      return router.currentRoute.value.name === 'MyIncidents';
    });
    return {
      isMy: isMy,
      sortBy: getLocalStorageComputed("my_incidents_sort_by".concat(isMy.value ? '' : '_archived'), SortFieldsCompatibility.INCID_HAPPEND_AT),
      viewSelected: getLocalStorageComputed('my_incidents_display_view', viewTypes.TILES)
    };
  },
  data: function data() {
    return {
      incidents: [],
      STANDARD_VIEWS: STANDARD_VIEWS,
      viewTypes: viewTypes,
      MODULE_NAME: MODULES.INCIDENTS,
      WATERMARK_IMAGE: "url('/assets/svg/ikon_incident.svg')",
      isLoaded: false,
      localKey: 0,
      path: '/incident/my/',
      translate: getTranslate['MyIncidents']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useActivityTypesStore, {
    activityTypes: 'incidentActivityTypes'
  })), mapState(useWorkflowStore, ['getAllStatuses'])), {}, {
    allWsFlat: function allWsFlat() {
      return this.getAllStatuses(MODULES.INCIDENTS);
    },
    sortOptions: function sortOptions() {
      var sortingOptions = MypageSettings(this.$trans)[MypageKeys.MY_INCIDENTS].sorting.sortOptions;
      sortingOptions.splice(3, 0, {
        id: 10,
        label: this.translate.incidentArchived,
        value: SortFieldsCompatibility.INCID_ARCHIVED_AT
      });
      return sortingOptions;
    },
    sortOptionsFiltered: function sortOptionsFiltered() {
      if (!this.isMy) return this.sortOptions;
      return this.sortOptions.filter(function (item) {
        return item.value !== SortFieldsCompatibility.INCID_ARCHIVED_AT;
      });
    },
    filteredIncidents: function filteredIncidents() {
      var result = _defineProperty(_defineProperty({}, IncidentKeys.MY_LIST, []), IncidentKeys.ARCHIVED_LIST, []);
      this.incidents.forEach(function (incident) {
        if (incident.archived) {
          result[IncidentKeys.ARCHIVED_LIST].push(incident);
        } else {
          result[IncidentKeys.MY_LIST].push(incident);
        }
      });
      return result;
    },
    incidentGroups: function incidentGroups() {
      var _this = this;
      var activityTypesInUse = this.filteredIncidents[IncidentKeys.MY_LIST].reduce(function (obj, incident) {
        if (incident.archived) return obj;
        var activityTypeId = incident.activityTypeId + '';
        if (obj[activityTypeId]) {
          obj[activityTypeId].items.push(incident);
        } else {
          var _this$activityTypes$f;
          var label = ((_this$activityTypes$f = _this.activityTypes.find(function (type) {
            return +type.id === +activityTypeId;
          })) === null || _this$activityTypes$f === void 0 ? void 0 : _this$activityTypes$f.label) || '';
          obj[activityTypeId] = {
            key: activityTypeId,
            title: label,
            path: _this.path + activityTypeId,
            items: [incident]
          };
        }
        return obj;
      }, {});
      return [{
        key: IncidentKeys.MY_LIST,
        title: this.translate.myIncidents,
        path: this.path,
        items: this.filteredIncidents[IncidentKeys.MY_LIST]
      }].concat(_toConsumableArray(Object.values(activityTypesInUse)), [{
        key: IncidentKeys.ARCHIVED_LIST,
        title: this.translate.archivedIncidents,
        path: '/incident/' + IncidentKeys.ARCHIVED_LIST,
        items: this.filteredIncidents[IncidentKeys.ARCHIVED_LIST]
      }]);
    },
    incidentsSorted: function incidentsSorted() {
      var _this$sortOptions$fin,
        _this2 = this;
      var descending = (_this$sortOptions$fin = this.sortOptions.find(function (_ref) {
        var value = _ref.value;
        return value === _this2.sortBy;
      })) === null || _this$sortOptions$fin === void 0 ? void 0 : _this$sortOptions$fin.descending;
      var sortFunction = getSortFunction({
        sortBy: this.sortBy,
        users: this.users,
        organisations: this.organisations,
        workflowStatuses: this.allWsFlat
      });
      return sortByArrayOfFunctions(this.currentGroup.items, [sortFunction, sortNumbers('id')], !descending);
    },
    currentGroup: function currentGroup() {
      var _this3 = this;
      if (this.currentRouteGroup) {
        /* incidents/my/archived check (incidents/archived is right path) */
        if (this.currentRouteGroup === IncidentKeys.ARCHIVED_LIST && this.isMy) {
          return null;
        }
        return this.incidentGroups.find(function (group) {
          return group.key === _this3.currentRouteGroup;
        }) || null;
      }
      return this.incidentGroups.find(function (group) {
        return group.key === IncidentKeys.MY_LIST;
      });
    },
    menuItems: function menuItems() {
      if (!this.activityTypes.length || !this.incidentGroups.length) return [];
      return this.incidentGroups.filter(function (group) {
        return group.key !== IncidentKeys.ARCHIVED_LIST;
      }).map(function (group) {
        return {
          title: "".concat(group.title, " (").concat(group.items.length, ")"),
          path: group.path
        };
      });
    },
    getRightApi: function getRightApi() {
      return this.isMy ? getMyIncidents : getArchivedIncidents;
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'incident');
    }
  }),
  watch: {
    currentRouteGroup: function currentRouteGroup() {
      this.localKey++;
    }
  },
  mounted: function mounted() {
    var _this4 = this;
    this.getData().then(function () {
      return _this4.isLoaded = true;
    });
  },
  methods: {
    getData: function getData() {
      var _this5 = this;
      return this.getRightApi().then(function (list) {
        return _this5.incidents = list || [];
      });
    },
    updateFromPreviewMode: function updateFromPreviewMode(item) {
      var index = this.incidents.findIndex(function (incident) {
        return +incident.id === +item.id;
      });
      if (index === -1) return;
      this.incidents[index] = item;
    }
  }
};