import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, renderList as _renderList } from "vue";
var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = {
  key: 2
};
var _hoisted_3 = {
  class: "flex flex-wrap mb-1"
};
var _hoisted_4 = ["innerHTML"];
var _hoisted_5 = {
  class: "flex flex-wrap mb-1"
};
var _hoisted_6 = {
  key: 1
};
var _hoisted_7 = {
  key: 0,
  class: "flex flex-wrap mb-1"
};
var _hoisted_8 = {
  key: 1
};
var _hoisted_9 = {
  key: 0,
  class: "flex flex-wrap mb-1"
};
var _hoisted_10 = ["innerHTML"];
var _hoisted_11 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: _normalizeClass({
      'mb-1': !$props.isCutVersion
    })
  }, [$props.nameVisible ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [$props.hasPreviewMode ? (_openBlock(), _createBlock(_component_preview_modal_link, {
    key: 0,
    activity: $props.info,
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updateFromPreviewMode', $event);
    }),
    onDismissModal: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('dismissModal');
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($props.info.name || $props.info.title), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"])) : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($props.info.name || $props.info.title), 1 /* TEXT */)), $props.info.companyInfo ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.description + ':',
    type: "medium",
    class: "mr-1"
  }, null, 8 /* PROPS */, ["title"]), $props.info.description ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "list-reset-styles",
    innerHTML: $props.info.description
  }, null, 8 /* PROPS */, _hoisted_4)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.companyName + ':',
    type: "medium",
    class: "mr-1"
  }, null, 8 /* PROPS */, ["title"]), $props.hasPreviewMode ? (_openBlock(), _createBlock(_component_preview_modal_link, {
    key: 0,
    activity: $options.activeInfoObj,
    onUpdated: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('updateFromPreviewMode', $event);
    }),
    onDismissModal: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('dismissModal');
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.activeInfoObj.name), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"])) : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($options.activeInfoObj.name), 1 /* TEXT */))])])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */), $options.isCompany ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.status + ':',
    type: "medium",
    class: "mr-1"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", null, _toDisplayString($options.companyStatus), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), !$props.isCutVersion ? (_openBlock(), _createElementBlock("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.infoFields, function (infoField, idx) {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: idx
    }, [infoField.field && !infoField.hide ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_risma_title, {
      title: infoField.title + ':',
      type: "medium",
      class: "mr-1"
    }, null, 8 /* PROPS */, ["title"]), infoField.isHtml ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "list-reset-styles",
      innerHTML: infoField.field
    }, null, 8 /* PROPS */, _hoisted_10)) : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(infoField.field), 1 /* TEXT */))])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)]);
}