import InputField from '@/components/Atoms/Inputs/InputField';
import CustomFieldTypes from '@/components/Molecules/CustomFieldTypes';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'CustomRiskField',
  components: {
    InputField: InputField,
    FeatherIcon: FeatherIcon,
    CustomFieldTypes: CustomFieldTypes,
    RismaButton: RismaButton
  },
  props: {
    customField: {
      type: Object,
      require: true,
      default: function _default() {}
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    saveButtonClickCounter: {
      required: false,
      type: Number,
      default: 0,
      note: 'required to not display errors below newly created items before user make another save button click'
    }
  },
  emits: ['edit', 'delete'],
  data: function data() {
    return {
      translate: {
        fieldLabel: this.$trans('Field label'),
        pleaseFillOutThisField: this.$trans('Please fill out this field.'),
        remove: this.$trans('Remove')
      }
    };
  },
  methods: {
    handleUpdateCF: function handleUpdateCF() {
      for (var _len = arguments.length, event = new Array(_len), _key = 0; _key < _len; _key++) {
        event[_key] = arguments[_key];
      }
      return this.$emit('edit', {
        id: this.customField.id,
        event: event
      });
    }
  }
};