import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_text_box = _resolveComponent("text-box");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_select, {
    options: $props.data[$data.DataFlowFields.SYSTEMS],
    "selected-options": $props.selectedOptions[$data.DataFlowFields.SYSTEMS_SELECTED],
    placeholder: $data.translate.chooseThirdParties,
    "label-key": "text",
    "track-by": "uniqueId",
    class: "my-4",
    "group-values": "items",
    "group-label": "label",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('update:selected', {
        value: $event,
        field: $data.DataFlowFields.SYSTEMS_SELECTED
      });
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder"]), _createVNode(_component_text_box, {
    text: $props.selectedOptions[$data.DataFlowFields.SYSTEMS_COMMENT],
    placeholder: $data.translate.comments,
    label: $data.translate.comments,
    class: "w-full",
    onUpdated: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('update:selected', {
        value: $event,
        field: $data.DataFlowFields.SYSTEMS_COMMENT
      });
    })
  }, null, 8 /* PROPS */, ["text", "placeholder", "label"])]);
}