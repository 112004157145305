import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  class: "processtree-content"
};
var _hoisted_2 = {
  class: "processtree-datatable"
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = {
  class: "hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_save_report = _resolveComponent("save-report");
  var _component_hierarchical_list = _resolveComponent("hierarchical-list");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_save_report, {
    type: $data.reportType,
    "state-local": $data.stateLocal,
    onLoad: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.applySettingsFromLoadedReport($event);
    })
  }, null, 8 /* PROPS */, ["type", "state-local"]), Object.keys($options.columns).length && $data.isLoaded ? (_openBlock(), _createBlock(_component_risma_datatable, {
    key: 0,
    ref: "reportsDataTable",
    "dataset-main": _ctx.datasetSlice,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "page-length": $data.pageLength,
    order: $data.order,
    "filter-by-column": true,
    "decouple-filter-fields": $data.decoupleFilterFields,
    "disabled-filter-by-columns": $data.disabledFilterByColumns,
    "columns-meta": $data.columnsMeta,
    "wrapper-height-sticky": 350,
    "state-local": $data.stateLocal,
    "loading-indicator-state": $data.datatableUploadState,
    "additional-filter-options": $data.additionalFilterOptions,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged,
    "onUpdate:visibility": $options.onVisibilityChange
  }, _createSlots({
    title: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_createVNode(_component_hierarchical_list, {
        node: item.field.node,
        "show-children": true,
        "has-preview-mode": true,
        "toggle-children": false,
        onUpdated: _cache[1] || (_cache[1] = function ($event) {
          return $data.updatedItem = $event;
        }),
        "onUpdated:governanceReview": $options.updateGovReviewFromItemPreview,
        onDismissModal: $options.onDismissModal
      }, null, 8 /* PROPS */, ["node", "onUpdated:governanceReview", "onDismissModal"])];
    }),
    governanceReview: _withCtx(function (_ref2) {
      var item = _ref2.item;
      return [item.fieldText ? (_openBlock(), _createBlock(_component_preview_modal_link, {
        key: 0,
        activity: item.activity,
        class: "hover:underline",
        onUpdated: function onUpdated($event) {
          return $options.updateGovernanceReview($event, item.item);
        },
        onDismissModal: function onDismissModal($event) {
          return $options.onDismissGovReviewModal(item.item);
        }
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(item.fieldText), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onUpdated", "onDismissModal"])) : (_openBlock(), _createElementBlock("span", _hoisted_3))];
    }),
    _: 2 /* DYNAMIC */
  }, [_renderList($data.slotLinkFields, function (linkCol) {
    return {
      name: linkCol,
      fn: _withCtx(function (_ref3) {
        var item = _ref3.item;
        return [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.field, function (linkItem, index) {
          return _openBlock(), _createBlock(_component_preview_modal_link, {
            key: "".concat(linkCol, "_").concat(index),
            activity: linkItem.activity,
            class: "block hover:underline",
            onUpdated: function onUpdated($event) {
              return $options.updateLinkedFromPreview($event, linkCol);
            },
            onDismissModal: $options.onLinkModalDismiss
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("div", null, _toDisplayString(linkItem.title) + _toDisplayString(index === item.field.length - 1 ? '' : ','), 1 /* TEXT */)];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onUpdated", "onDismissModal"]);
        }), 128 /* KEYED_FRAGMENT */))])];
      })
    };
  }), _renderList(_ctx.trafficLightRismaDatatableFields, function (trafficLightCol) {
    return {
      name: trafficLightCol,
      fn: _withCtx(function (_ref4) {
        var item = _ref4.item;
        return [item.field.color ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(trafficLightCol)
        }, [_createVNode(_component_traffic_light, {
          color: item.field.color
        }, null, 8 /* PROPS */, ["color"]), _createElementVNode("p", _hoisted_4, _toDisplayString(item.field.value) + "-" + _toDisplayString(item.field.label), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("span", {
          key: "".concat(trafficLightCol, "_span")
        }))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "page-length", "order", "decouple-filter-fields", "disabled-filter-by-columns", "columns-meta", "state-local", "loading-indicator-state", "additional-filter-options", "onPageChange", "onOnStateChanged", "onUpdate:visibility"])) : _createCommentVNode("v-if", true), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : _createCommentVNode("v-if", true)])]);
}