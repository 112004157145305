import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["innerHTML"];
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = {
  class: "w-200px"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_single_select = _resolveComponent("single-select");
  var _component_help_tool_tip = _resolveComponent("help-tool-tip");
  var _component_ai_terms_of_service_modal = _resolveComponent("ai-terms-of-service-modal");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("p", {
    class: "mb-4",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.handleNoteClick && $options.handleNoteClick.apply($options, arguments);
    }),
    innerHTML: $options.note
  }, null, 8 /* PROPS */, _hoisted_1), _createVNode(_component_checkboxes, {
    modelValue: $data.isAiEnabled,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.isAiEnabled = $event;
    }),
    options: $data.checkboxOptions,
    class: "mb-8 -ml-2",
    onUpdated: $options.handleAiAccess
  }, null, 8 /* PROPS */, ["modelValue", "options", "onUpdated"]), _createVNode(_component_single_select, {
    modelValue: $data.selectedRegion,
    "onUpdate:modelValue": [_cache[2] || (_cache[2] = function ($event) {
      return $data.selectedRegion = $event;
    }), $options.handleChangeRegion],
    title: $data.translate.regionTitle,
    disabled: !_ctx.aiTermsAccepted,
    options: $options.regionOptions,
    class: "max-w-300px mb-8"
  }, null, 8 /* PROPS */, ["modelValue", "title", "disabled", "options", "onUpdate:modelValue"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_checkboxes, {
    modelValue: $data.isHiddenForCompliance,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.isHiddenForCompliance = $event;
    }),
    options: $data.hideAiOptions,
    class: "mb-8 -ml-2",
    onUpdated: $options.handleHideForCompliance
  }, null, 8 /* PROPS */, ["modelValue", "options", "onUpdated"]), _createVNode(_component_help_tool_tip, {
    "react-on-click": true,
    class: "mt-2.5 -ml-2"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("p", _hoisted_3, _toDisplayString($data.translate.thisSettingsWillHide), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })]), $data.showTermsOfServices ? (_openBlock(), _createBlock(_component_ai_terms_of_service_modal, {
    key: 0,
    "only-doc": !!_ctx.aiTermsAccepted,
    onAccept: $options.acceptTermsOfServices,
    onDismiss: $options.dismissTermsOfServices
  }, null, 8 /* PROPS */, ["only-doc", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true)]);
}