import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "risk-filters"
};
var _hoisted_2 = {
  class: "risk-filters-content"
};
var _hoisted_3 = {
  class: "risk-filters-section risk-filters-section-orgs"
};
var _hoisted_4 = {
  key: 0,
  class: "risk-filters-section risk-filters-section-prisk"
};
var _hoisted_5 = {
  class: "risk-filters-section risk-filters-section-tags"
};
var _hoisted_6 = {
  class: "risk-filters-section risk-filters-section-search"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_org_selector = _resolveComponent("risma-org-selector");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_input_field = _resolveComponent("input-field");
  var _component_field_set = _resolveComponent("field-set");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_field_set, {
    title: $data.translate.mainFilters
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_org_selector, {
        "available-options": $props.organisations,
        "selected-options": $props.organisationsSelected,
        title: $data.translate.organisations,
        onSelected: _cache[0] || (_cache[0] = function (orgs) {
          return _ctx.$emit('update-orgs', orgs);
        })
      }, null, 8 /* PROPS */, ["available-options", "selected-options", "title"])]), $props.parentRisks.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_single_select, {
        title: $data.translate.parentRisk,
        placeholder: $data.translate.noFiltersApplied,
        options: $props.parentRisks,
        "model-value": $props.parentRiskSelected,
        "un-sorted": true,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function (parentRisks) {
          return _ctx.$emit('update-prisk', parentRisks);
        })
      }, null, 8 /* PROPS */, ["title", "placeholder", "options", "model-value"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_select, {
        title: $data.translate.tags,
        placeholder: $data.translate.tags,
        options: $props.tags,
        "selected-options": $props.tagsSelected,
        "label-key": "tag",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function (tag) {
          return _ctx.$emit('update-tag', tag);
        })
      }, null, 8 /* PROPS */, ["title", "placeholder", "options", "selected-options"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_input_field, {
        title: $data.translate.search,
        "model-value": $props.search,
        placeholder: $data.translate.searchForNoOrTitle,
        type: "text",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return _ctx.$emit('update-search', $event.trim());
        })
      }, null, 8 /* PROPS */, ["title", "model-value", "placeholder"])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"])]);
}