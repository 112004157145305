import { calcComplianceRights, calcRiskRights, calcExecutionRights, calcControlsRights, calcIncidentsRights, calcContractRights, checkForComplianceProjects, calcProcessLibraryRights, calcCompanyRights } from '@/menus/quicknav';
import { ObjectTypes, ObjectTypeToUserField } from '@/constants/ObjectTypes';
import { useSettingsStore } from '@/Store/settingsStore';
import { MODULES } from '@/constants/modules';
export var buildNavBar = function buildNavBar(settings, user) {
  return {
    userguide: {
      title: settings['app.user_guide_title'],
      rights: settings['feature.welcome_page_menu'],
      url: '/userguide'
    },
    compliance: {
      title: 'Compliance',
      rights: calcComplianceRights(user, settings) && (user === null || checkForComplianceProjects(user.projects[MODULES.COMPLIANCE])) //UserRightsTable has user === null
    },
    policies: {
      title: useSettingsStore().processLibraryName,
      property: ObjectTypeToUserField[ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE],
      rights: calcProcessLibraryRights(user, settings)
    },
    assets: {
      title: settings['feature.information_assets_name'] || 'Assets',
      property: ObjectTypeToUserField[ObjectTypes.INFORMATION_ASSET],
      rights: settings['feature.disable_information_assets'] !== 1 && (user === null || settings['feature.asset_management_menu'] === 1 && (user === null || user === void 0 ? void 0 : user.iaAccess))
    },
    risk: {
      title: 'Risk',
      trans: false,
      property: ObjectTypeToUserField[ObjectTypes.RISK_RISK],
      rights: calcRiskRights(user, settings)
    },
    actions: {
      title: 'Actions',
      property: ObjectTypeToUserField[ObjectTypes.EXECUTION_INITIATIVE],
      rights: calcExecutionRights(user, settings)
    },
    controls: {
      title: 'Controls',
      property: ObjectTypeToUserField[ObjectTypes.CONTROLS_CONTROL],
      rights: calcControlsRights(user, settings)
    },
    contracts: {
      title: 'Contracts',
      property: ObjectTypeToUserField[ObjectTypes.CONTRACT],
      rights: calcContractRights(user, settings)
    },
    incident: {
      title: 'Incidents',
      property: ObjectTypeToUserField[ObjectTypes.INCIDENTS_INCIDENT],
      rights: calcIncidentsRights(user, settings)
    },
    companies: {
      title: 'Companies',
      rights: calcCompanyRights(user, settings),
      property: ObjectTypeToUserField[ObjectTypes.COMPANY],
      isRedirectLink: true,
      url: '/companies'
    }
  };
};