function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { getTranslate } from './translate';
import { addChildToNode } from '@/api/compliance/processtree';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import Notification from '@/components/Molecules/Notification';
export default {
  name: 'ProcessLibraryNodeCreationModal',
  description: 'Component for creating process nodes',
  token: '<process-library-node-creation-modal/>',
  components: {
    Modal: Modal,
    InputField: InputField,
    FrameworksSelector: FrameworksSelector,
    Notification: Notification
  },
  props: {
    treeId: {
      type: [Number, String],
      required: true,
      default: null
    },
    parentId: {
      type: [Number, String],
      required: false,
      default: null
    },
    nodeTitle: {
      type: String,
      required: false,
      default: ''
    },
    createParams: {
      type: Object,
      required: false,
      default: null
    },
    isFrameworksHidden: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['dismiss', 'create'],
  data: function data() {
    return {
      isRismaSelectOpen: false,
      newNode: {
        title: '',
        frameworks: []
      },
      isLoading: false,
      errorMessage: '',
      titleError: '',
      translate: getTranslate['ProcessLibraryNodeCreationModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    validNewNode: function validNewNode() {
      return this.newNode.title;
    },
    activityTypeId: function activityTypeId() {
      return this.getSettingValue('feature.process_library_default_activity_type_id');
    }
  }),
  beforeMount: function beforeMount() {
    this.newNode.title = this.nodeTitle || '';
  },
  methods: {
    createNode: function createNode(params) {
      var _this = this;
      this.isLoading = true;
      addChildToNode(this.treeId, params).then(function (response) {
        if (typeof response.headers !== 'undefined') {
          _this.errorMessage = _this.translate.errorDuringCreation;
          return;
        }
        _this.$emit('create', response);
      }).finally(function () {
        return _this.isLoading = false;
      });
    },
    handleOnSubmit: function handleOnSubmit() {
      var _this$newNode$framewo;
      var data = _objectSpread({
        title: this.newNode.title,
        solutionIds: (_this$newNode$framewo = this.newNode.frameworks) === null || _this$newNode$framewo === void 0 ? void 0 : _this$newNode$framewo.map(function (s) {
          return s.id;
        })
      }, this.createParams);
      if (this.parentId && this.parentId !== -1) {
        data.appendTo = +this.parentId;
      }
      if (this.activityTypeId) {
        data.activityTypeId = this.activityTypeId;
      }
      if (!this.currentUser.is_admin) {
        data.responsibleUserIds = [this.currentUser.id];
      }
      if (!this.handleValidation(data)) {
        return;
      }
      return this.createNode(data);
    },
    handleValidation: function handleValidation(data) {
      var errors = '';
      if (!data.title) {
        errors = this.translate.titleCannotBeEmpty;
      }
      this.titleError = errors;
      return !errors;
    }
  }
};