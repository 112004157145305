import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "bg-zinc-50 p-4"
};
var _hoisted_2 = {
  class: "flex justify-between bg-blue mb-2"
};
var _hoisted_3 = {
  class: "flex"
};
var _hoisted_4 = {
  class: "flex items-center my-2 relative"
};
var _hoisted_5 = {
  class: "mx-4"
};
var _hoisted_6 = ["title"];
var _hoisted_7 = {
  class: "ml-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_drag_and_drop_icon = _resolveComponent("drag-and-drop-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_input_field = _resolveComponent("input-field");
  var _component_draggable = _resolveComponent("draggable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_drag_and_drop_icon, {
    class: "drag-icon-questions mr-4"
  }), _createVNode(_component_risma_title, {
    type: "medium",
    title: $options.headline
  }, null, 8 /* PROPS */, ["title"])]), _createVNode(_component_feather_icon, {
    icon: "x",
    class: "cursor-pointer",
    onClick: $options.deleteQuestion
  }, null, 8 /* PROPS */, ["onClick"])]), _createVNode(_component_input_field, {
    "model-value": $data.currentQuestion.question,
    placeholder: $data.translate.insertQuestionDescription,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.currentQuestion.question = $event;
    })
  }, null, 8 /* PROPS */, ["model-value", "placeholder"]), $data.currentQuestion.options.length ? (_openBlock(), _createBlock(_component_draggable, _mergeProps({
    key: 0,
    modelValue: $data.currentQuestion.options,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.currentQuestion.options = $event;
    }),
    tag: "div",
    "component-data": {
      type: 'transition'
    },
    "item-key": "optionNumber",
    handle: ".drag-icon-options"
  }, $data.DragOptions), {
    item: _withCtx(function (_ref) {
      var element = _ref.element,
        index = _ref.index;
      return [_createElementVNode("div", _hoisted_4, [_createVNode(_component_drag_and_drop_icon, {
        class: "drag-icon-options ml-4"
      }), _createElementVNode("span", _hoisted_5, _toDisplayString($props.listIndexes[index]), 1 /* TEXT */), _createVNode(_component_input_field, {
        modelValue: element.label,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return element.label = $event;
        },
        class: "w-full border border-gray-350 bg-white option-title",
        placeholder: $data.translate.enterQuestionOption
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder"]), _createElementVNode("div", {
        title: element.correctAnswer ? $data.translate.correctAnswer : $data.translate.selectCorrectAnswer
      }, [_createVNode(_component_feather_icon, {
        icon: "check",
        class: _normalizeClass(["cursor-pointer w-8 h-8 border-gray-350 p-2 absolute right-2rem top-5px", element.correctAnswer ? 'bg-green-350 text-white' : 'bg-blue-85 text-gray-670']),
        onClick: function onClick($event) {
          return $options.setTrueAnswer(index);
        }
      }, null, 8 /* PROPS */, ["class", "onClick"])], 8 /* PROPS */, _hoisted_6), _createVNode(_component_feather_icon, {
        icon: "x",
        class: "cursor-pointer w-8 h-8 absolute p-2 right-0 top-0.4rem",
        onClick: function onClick($event) {
          return $options.deleteOption(index);
        }
      }, null, 8 /* PROPS */, ["onClick"])])];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["modelValue"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass(["flex items-center justify-end text-blue-450", $data.currentQuestion.options.length >= $props.listIndexes.length ? 'cursor-not-allowed' : 'cursor-pointer']),
    onClick: _cache[2] || (_cache[2] = function () {
      return $options.createNewQuestionOption && $options.createNewQuestionOption.apply($options, arguments);
    })
  }, [_createElementVNode("span", _hoisted_7, _toDisplayString($data.translate.addNewQuestionOption), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    icon: "plus"
  })], 2 /* CLASS */)]);
}