function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { getGapDashboard as _getGapDashboard, getDashboardReport } from '@/api/compliance/dashboard';
import { getAllGapSchemasByProjectId } from '@/api/compliance/gapschema';
import { getGapTypes as _getGapTypes } from '@/api/compliance/gaps';
import GapDashboardData from '@/models/compliance/GapDashboardData';
import { sortArrayByAnotherArray } from '@/utils/sort';
import { getErrorMessage } from '@/utils/handleAPIErrors';
import { removeDuplicates } from '@/utils/filterArray';
export var ComplianceDashboardMixin = {
  data: function data() {
    return {
      isLoading: true,
      sliceSelected: {},
      gapSchemas: [],
      hiddenArticles: [],
      nodes: [],
      gapTypes: [],
      gapData: null,
      gapDataMap: {},
      newGapData: null,
      loadingStats: true,
      loadingGaps: false,
      errorMessage: '',
      gapDataDownloadedAtOnce: false,
      currentFilter: null,
      lastAppliedFilters: {},
      includeChildToggleOption: true,
      includeFrameworkChildToggleOption: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useTagsStore, ['tags'])), mapState(useTrafficLights, ['gapTrafficLights'])), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), {}, {
    gapNumberLegendMapping: function gapNumberLegendMapping() {
      return this.gapTrafficLights.reduce(function (acc, item, index) {
        acc[index] = item.legend;
        return acc;
      }, {});
    },
    predefinedFilters: function predefinedFilters() {
      var _this = this;
      var filters = {};
      if (this.filterString) {
        var filterStrings = this.filterString.split('&');
        filterStrings.forEach(function (filterString) {
          var _filterString$split = filterString.split('='),
            _filterString$split2 = _slicedToArray(_filterString$split, 2),
            key = _filterString$split2[0],
            val = _filterString$split2[1];
          key = key.substring(7, key.length - 3);

          // Select all should not be here
          if (val === '0') return;
          if (filters[key] === undefined) {
            filters[key] = [];
          }
          if (key === 'analysisRAG') {
            var _this$findGapLightByL = _this.findGapLightByLabel(val),
              id = _this$findGapLightByL.number,
              color = _this$findGapLightByL.color,
              legend = _this$findGapLightByL.legend;
            val = {
              id: id,
              color: color,
              label: _this.$trans(legend)
            };
          }
          if (key === 'nodeIds') {
            if (!_this.allNodeIds.includes(+val)) return;
          }
          if (_this.solutionsPropName.startsWith(key)) {
            key = _this.solutionsPropName;
            val = val.split(',');
            filters[key] = val;
          } else {
            filters[key].push(val);
          }
        });
      }
      return filters;
    },
    predefinedNodesFilter: function predefinedNodesFilter() {
      if (this.predefinedFilters['nodeIds'] === undefined) {
        return [];
      } else {
        return this.predefinedFilters['nodeIds'].map(function (item) {
          return {
            id: parseInt(item),
            title: item
          };
        });
      }
    },
    predefinedOrganisationsFilter: function predefinedOrganisationsFilter() {
      if (this.predefinedFilters['organisationIds'] === undefined) {
        return [];
      } else {
        return this.predefinedFilters['organisationIds'].map(function (item) {
          return {
            id: parseInt(item),
            title: item
          };
        });
      }
    },
    predefinedUsersFilter: function predefinedUsersFilter() {
      if (this.predefinedFilters['responsibleUserIds'] === undefined) {
        return [];
      } else {
        return this.predefinedFilters['responsibleUserIds'].map(function (item) {
          return {
            id: parseInt(item),
            title: item
          };
        });
      }
    },
    predefinedTagsFilter: function predefinedTagsFilter() {
      var _this2 = this;
      if (this.predefinedFilters['tagIds'] === undefined) return [];
      return this.predefinedFilters['tagIds'].map(function (item) {
        return _this2.tags.find(function (tag) {
          return +tag.id === +item;
        });
      }).filter(function (item) {
        return item;
      });
    },
    predefinedFrameworksFilter: function predefinedFrameworksFilter() {
      var _this3 = this;
      if (this.predefinedFilters[this.solutionsPropName] === undefined) return [];
      return this.predefinedFilters[this.solutionsPropName].map(function (item) {
        return _this3.frameworks.find(function (framework) {
          return +framework.id === +item;
        });
      }).filter(function (item) {
        return item;
      });
    },
    gapDashboardData: function gapDashboardData() {
      var _this4 = this;
      var gaps = this.gapData || [];
      if (this.gapDataDownloadedAtOnce) {
        gaps = gaps.filter(function (gap) {
          var _gap$analysis_rag;
          var value = (_gap$analysis_rag = gap.analysis_rag) !== null && _gap$analysis_rag !== void 0 ? _gap$analysis_rag : 0;
          return value === _this4.sliceSelected.number;
        });
      }
      return new GapDashboardData(this.nodes, gaps, this.gapSchemas);
    },
    formattedGaps: function formattedGaps() {
      var _this5 = this;
      if (!this.gapDashboardData.nodesWithSelectedGaps) return [];
      var allGaps = [];
      this.gapDashboardData.nodesWithSelectedGaps.forEach(function (node) {
        _this5.addOrganisationToNode(node);
        node.gaps.forEach(function (gap) {
          gap.node = node;
        });
        allGaps.push.apply(allGaps, _toConsumableArray(node.gaps));
      });
      return allGaps;
    },
    gapTypesBarChart: function gapTypesBarChart() {
      var _this6 = this;
      if (!this.newGapData || !this.newGapData.byType || !this.newGapData.gapTypes) {
        return {
          datasets: [],
          labels: []
        };
      }
      var data = {};
      Object.keys(this.newGapData.gapTypes).forEach(function (gapKey) {
        data[gapKey] = _this6.newGapData.byType[gapKey] || 0;
      });
      var sortedIds = this.gapTypes.map(function (_ref) {
        var id = _ref.id;
        return "".concat(id);
      });
      var dataSorted = sortArrayByAnotherArray(Object.keys(data), sortedIds).map(function (id) {
        return data[id];
      });
      var labelsSorted = sortArrayByAnotherArray(Object.keys(this.newGapData.gapTypes), sortedIds).map(function (id) {
        return _this6.newGapData.gapTypes[id];
      });
      var datasets = {
        data: dataSorted,
        backgroundColor: '#4eb1b2'
      };
      return {
        labels: labelsSorted,
        datasets: [datasets]
      };
    },
    gapOverviewData: function gapOverviewData() {
      var data = [];
      var backgroundColor = [];
      this.gapOverview.forEach(function (item) {
        data.push(item.value);
        backgroundColor.push(item.color);
      });
      return [{
        data: data,
        backgroundColor: backgroundColor,
        total: data.reduce(function (total, item) {
          return total + item;
        }, 0)
      }];
    },
    gapOverviewLabels: function gapOverviewLabels() {
      var _this7 = this;
      return this.gapOverview.map(function (item) {
        return _this7.$trans(item.title);
      });
    },
    gapOverview: function gapOverview() {
      var _this8 = this;
      if (!this.newGapData || !this.newGapData.byGap) {
        return [];
      }
      var overview = [];
      var counts = this.newGapData.byGap;
      Object.keys(counts).forEach(function (rag) {
        if (counts[rag] !== 0) {
          var gapLight = _this8.getGapLight(rag);
          overview.push({
            title: gapLight.legend,
            traffic_light: parseInt(rag),
            value: counts[rag],
            color: gapLight.color,
            icon: gapLight.icon || ''
          });
        }
      });
      return overview;
    },
    pieSliceColors: function pieSliceColors() {
      var gapOverview = this.filteredOverview || this.gapOverview;
      return gapOverview.map(function (item) {
        return item.color;
      });
    },
    gapDomains: function gapDomains() {
      return this.getGapByDomainData(this.gapFilter, this.newGapData);
    },
    isTableShown: function isTableShown() {
      return !!(this.formattedGaps.length && Number.isInteger(this.sliceSelected.number));
    },
    isChildIncluded: function isChildIncluded() {
      return this.includeChildToggleOption;
    },
    selectedNodeIds: function selectedNodeIds() {
      var _this$lastAppliedFilt;
      return ((_this$lastAppliedFilt = this.lastAppliedFilters.filter) === null || _this$lastAppliedFilt === void 0 ? void 0 : _this$lastAppliedFilt.nodes.map(function (node) {
        return node.id;
      })) || [];
    },
    selectedOrganisationIds: function selectedOrganisationIds() {
      var _this$lastAppliedFilt2;
      return ((_this$lastAppliedFilt2 = this.lastAppliedFilters.filter) === null || _this$lastAppliedFilt2 === void 0 ? void 0 : _this$lastAppliedFilt2.organisations.map(function (organisations) {
        return organisations.id;
      })) || [];
    },
    childNodeIds: function childNodeIds() {
      return this.isChildIncluded ? this.getChildNodeIds(this.selectedNodeIds, this.nodes) : [];
    },
    filterString: function filterString() {
      return this.lastAppliedFilters.filterString || '';
    },
    filterStringWithChildNodes: function filterStringWithChildNodes() {
      var filterString = this.lastAppliedFilters.filterString || '';
      if (this.childNodeIds.length) {
        this.childNodeIds.forEach(function (nodeId) {
          filterString += '&filters[nodeIds][]=' + nodeId;
        });
      }
      return filterString;
    },
    sortedDomainIds: function sortedDomainIds() {
      if (!this.gapSchemas) return [];
      return this.gapSchemas.reduce(function (acc, cur) {
        cur.articles.forEach(function (article) {
          var _article$domain;
          var domainId = (_article$domain = article.domain) === null || _article$domain === void 0 ? void 0 : _article$domain.id;
          if (!domainId || acc.includes(domainId)) return;
          acc.push(domainId);
        });
        return acc;
      }, []);
    }
  }),
  mounted: function mounted() {
    this.setFiltersFromLocalStorage();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['findGapLightByLabel', 'getGapLight'])), {}, {
    getGapTypes: function getGapTypes(projectId) {
      var _this9 = this;
      return _getGapTypes(projectId).then(function (_ref2) {
        var list = _ref2.list;
        _this9.gapTypes = _toConsumableArray(list.sort(function (a, b) {
          return a.number - b.number;
        }));
      });
    },
    getFlatFrameworks: function getFlatFrameworks(frameworks) {
      var _this10 = this;
      var list = [];
      frameworks.forEach(function (framework) {
        _this10.buildDepthList(framework, list);
      });
      return removeDuplicates(list, 'id');
    },
    buildDepthList: function buildDepthList(framework) {
      var _this11 = this;
      var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      list.push(framework);
      framework.children && framework.children.forEach(function (item) {
        _this11.buildDepthList(item, list);
      });
      return list;
    },
    fetchData: function fetchData(filterObj) {
      var _this$lastAppliedFilt3;
      if (filterObj) {
        this.lastAppliedFilters = filterObj;
      }
      this.saveState({
        filterObj: this.lastAppliedFilters,
        includeChildNodes: this.isChildIncluded,
        includeFrameworkChildToggleOption: this.includeFrameworkChildToggleOption
      });
      var filterString = ((_this$lastAppliedFilt3 = this.lastAppliedFilters) === null || _this$lastAppliedFilt3 === void 0 ? void 0 : _this$lastAppliedFilt3.filterString) || '';
      if (this.isChildIncluded && this.childNodeIds) {
        filterString += '&filter[includeUnderlyingNodes]=true';
      }
      if (this.includeFrameworkChildToggleOption) {
        filterString += '&filter[includeUnderlyingFrameworks]=true';
      }
      this.currentFilter = filterString;
      this.loadDashboardData(filterString, filterObj || this.lastAppliedFilters);
    },
    clearSliceSelected: function clearSliceSelected() {
      this.sliceSelected.number = null;
      this.sliceSelected.label = '';
      this.sliceSelected.color = '';
      this.sliceSelected.width = '';
      this.sliceSelected.icon = '';
    },
    handleClickSlice: function handleClickSlice(index) {
      var _this$getGapDataByIdx = this.getGapDataByIdx(index),
        label = _this$getGapDataByIdx.label,
        number = _this$getGapDataByIdx.number,
        color = _this$getGapDataByIdx.color,
        icon = _this$getGapDataByIdx.icon;
      this.sliceSelected = {
        icon: icon,
        number: number,
        label: label,
        color: color,
        width: "".concat(this.$el.clientWidth - 30, "px")
      };
      if (!this.gapDataDownloadedAtOnce) {
        var filter = "filter[analysisRAG][]=".concat(label);
        if (this.filterStringWithChildNodes) {
          filter += "&".concat(this.filterStringWithChildNodes);
        }
        if (this.includeFrameworkChildToggleOption) {
          filter += '&filter[includeUnderlyingFrameworks]=true';
        }
        if (this.gapDataMap[filter]) {
          this.gapData = this.gapDataMap[filter];
        } else {
          this.currentFilter = filter;
          var neededMinimalFilterObject = {
            filter: {
              gaps: [{
                id: number,
                label: label
              }]
            }
          };
          this.getGaps(neededMinimalFilterObject, filter);
        }
      }
    },
    getGaps: function getGaps(filterObj, filterString) {
      var _this12 = this;
      this.loadingGaps = true;
      var filters = filterString ? '?' + this.getPreparedReportFilter(filterObj, filterString) : '';
      return getDashboardReport(this.projectId, filters).then(function (_ref3) {
        var list = _ref3.list;
        // check for the last api call
        if (filterString !== _this12.currentFilter) return;
        var filteredList = list.filter(function (_ref4) {
          var articleId = _ref4.articleId;
          return !_this12.hiddenArticles.includes(articleId);
        });
        _this12.gapData = filteredList;
        _this12.gapDataMap[filters] = filteredList;
        _this12.loadingGaps = false;
      });
    },
    getPreparedReportFilter: function getPreparedReportFilter(data, filterString) {
      var filtersArray = filterString.replaceAll('filter', 'filters').split('&');
      filtersArray.forEach(function (filterItem, index) {
        var _filterItem$split = filterItem.split('='),
          _filterItem$split2 = _slicedToArray(_filterItem$split, 2),
          key = _filterItem$split2[0],
          value = _filterItem$split2[1];
        key = key.substring(8, key.length - 3);
        if (key === 'analysisRAG') {
          var _data$filter$gaps$fin;
          var id = (_data$filter$gaps$fin = data.filter.gaps.find(function (item) {
            return item.label === value;
          })) === null || _data$filter$gaps$fin === void 0 ? void 0 : _data$filter$gaps$fin.id;
          filtersArray[index] = 'filters[traffic_light][]=' + id;
        }
        if (key === 'responsibleUserIds') {
          filtersArray[index] = 'filters[responsible][]=' + value;
        }
      });
      return filtersArray.join('&');
    },
    getGapDataByIdx: function getGapDataByIdx(idx) {
      var gapByIndex = this.gapOverview[idx];
      return this.getGapData(gapByIndex);
    },
    getGapData: function getGapData(gap) {
      if (!gap) return {};
      return {
        label: gap.title,
        number: gap.traffic_light,
        color: gap.color,
        icon: gap.icon
      };
    },
    addOrganisationToNode: function addOrganisationToNode(node) {
      var _this13 = this;
      var orgString = '';
      node.organisationIds && node.organisationIds.length > 0 && node.organisationIds.forEach(function (orgId) {
        var org = _this13.orgs.find(function (o) {
          return o.id == orgId;
        });
        if (org) {
          orgString += org.visible_name + ', ';
        }
      });
      node.organisation = orgString.slice(0, orgString.length - 2);
    },
    getGapSchemas: function getGapSchemas() {
      var _this14 = this;
      return getAllGapSchemasByProjectId(this.projectId, '?data[]=articleDetails').then(function (_ref5) {
        var list = _ref5.list;
        var gapSchemas = [];
        var hiddenArticles = [];
        list.forEach(function (item) {
          var articles = [];
          item.articles.forEach(function (article) {
            if (article.hidden) {
              hiddenArticles.push(article.id);
              return;
            }
            articles.push(article);
          });
          gapSchemas.push(_objectSpread(_objectSpread({}, item), {}, {
            articles: articles
          }));
        });
        _this14.gapSchemas = gapSchemas;
        _this14.hiddenArticles = hiddenArticles;
      }).catch(function (error) {
        return _this14.handleDashboardError(error);
      });
    },
    getGapDashboard: function getGapDashboard() {
      var _this15 = this;
      var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      return _getGapDashboard(this.projectId, filter).then(function (response) {
        // check for the last api call
        if (filter !== _this15.currentFilter) return;
        _this15.newGapData = response;
      }).catch(function (error) {
        return _this15.handleDashboardError(error);
      });
    },
    handleDashboardError: function handleDashboardError(error) {
      var _this16 = this;
      if (error.status === 414 || error.isAxiosError) {
        this.errorMessage = 'You have too many filter parameters selected, please deselect some.';
      } else {
        this.errorMessage = getErrorMessage(error);
      }
      setTimeout(function () {
        _this16.errorMessage = '';
      }, this.$debounceTimeout * 2);
    },
    getGapByDomainData: function getGapByDomainData(filterRags, gapData) {
      var _this17 = this;
      if (!gapData || !gapData.byDomain || !gapData.domains) {
        return {
          datasets: [],
          labels: []
        };
      }
      var finalDomainTemplate = _objectSpread({}, this.gapNumberLegendMapping);
      if (filterRags.length > 0) {
        Object.keys(finalDomainTemplate).forEach(function (key) {
          if (!filterRags.includes(+key)) {
            delete finalDomainTemplate[key];
          }
        });
      }
      var datasets = [];
      var gapStats = this.getSortedByDomainIds(gapData.byDomain);
      var domainMap = this.getSortedByDomainIds(gapData.domains);
      var relevantColors = this.gapTrafficLights.filter(function (gapColor) {
        return filterRags.length === 0 || filterRags.indexOf(gapColor.number) !== -1;
      });
      var colors = relevantColors.map(function (gapColor) {
        return gapColor.color;
      });
      Object.keys(finalDomainTemplate).map(function (key, idx) {
        var datasetObject = {
          label: _this17.$trans(finalDomainTemplate[key]),
          data: gapStats.map(function (domainObject) {
            return domainObject[key];
          }),
          backgroundColor: colors[idx]
        };
        datasetObject.data.some(function (item) {
          return !!item;
        }) && datasets.push(datasetObject);
      });
      return {
        datasets: datasets,
        labels: domainMap
      };
    },
    getSortedByDomainIds: function getSortedByDomainIds(items) {
      return this.sortedDomainIds.reduce(function (acc, cur) {
        var item = items[cur];
        return item ? [].concat(_toConsumableArray(acc), [item]) : acc;
      }, []);
    },
    getChildNodeIds: function getChildNodeIds(selectedNodeIds, nodes) {
      var filteredNodes = nodes.filter(function (node) {
        return selectedNodeIds.find(function (id) {
          return node.parentIds.includes(id);
        });
      });
      return filteredNodes.map(function (node) {
        return node.id;
      }).filter(function (id) {
        return !selectedNodeIds.includes(id);
      });
    },
    onIncludeChildNodesToggle: function onIncludeChildNodesToggle(event) {
      this.includeChildToggleOption = event;
      this.fetchData();
    },
    onIncludeFrameworksChildNodesToggle: function onIncludeFrameworksChildNodesToggle(event) {
      this.includeFrameworkChildToggleOption = event;
      this.fetchData();
    }
  })
};