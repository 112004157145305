// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is-ban[data-v-b0268ecc]::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #c64141;
  transform: rotate(45deg);
  transform-origin: top left;
  top: 13%;
  left: 15%;
  box-sizing: border-box;
}
.is-ban.ban-active[data-v-b0268ecc]:hover::before {
  height: 2px;
  top: 9%;
  left: 20%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
