import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { compareDates } from '@/utils/compare';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { getDateBeforeDate, calculateDifferenceAsDays, toLongLocalDateTranslated } from '@/utils/date';
var TOO_CLOSE = 1.5;
export default {
  name: 'ContractTimeline',
  description: 'Component for contracts timeline',
  token: '<contract-timeline/>',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    dates: {
      type: Array,
      required: true,
      note: 'Dates with labels that will be displayed on timeline'
    },
    contractId: {
      type: [Number, String],
      required: false,
      default: null,
      note: 'Necessary to save visibility state in localstorage'
    }
  },
  setup: function setup(props) {
    return {
      showTimeline: getLocalStorageComputed("contract_timeline_visibility_".concat(props.contractId), false, true)
    };
  },
  data: function data() {
    return {
      bellHeight: ['h-12', 'h-16', 'h-20'],
      bellsOnTimeline: {},
      timelineDates: []
    };
  },
  computed: {
    allDates: function allDates() {
      var dates = this.dates.reduce(function (acc, item) {
        item.data.forEach(function (dateObj) {
          if (dateObj.date !== null) {
            acc.push(dateObj.date);
          }
        });
        return acc;
      }, []);
      return dates.sort(function (a, b) {
        return compareDates(a, b);
      });
    },
    startDate: function startDate() {
      return this.allDates[0];
    },
    endDate: function endDate() {
      return this.allDates.slice(-1)[0];
    },
    contractLength: function contractLength() {
      return calculateDifferenceAsDays(this.endDate, this.startDate);
    },
    nowOnTimeline: function nowOnTimeline() {
      return this.getOnTimelineInPercent();
    }
  },
  watch: {
    dates: {
      handler: function handler() {
        this.setTimelineDates();
      },
      immediate: true
    }
  },
  methods: {
    toggleTimeline: function toggleTimeline() {
      this.showTimeline = !this.showTimeline;
    },
    getOnTimelineInPercent: function getOnTimelineInPercent(date) {
      var days = calculateDifferenceAsDays(date, this.startDate);
      return Math.min(days * 100 / this.contractLength, 100);
    },
    setTimelineDates: function setTimelineDates() {
      var _this = this;
      var result = [];
      this.bellsOnTimeline = {};
      this.dates.forEach(function (timelineObj) {
        var timelineData = timelineObj.data;
        timelineData.forEach(function (data) {
          var dateObj = _this.getFormattedDate(data.date, data.id, timelineObj.label, timelineObj.bgColor, timelineObj.textColor, timelineObj.showDateTitle);
          result.push(dateObj);
          if (data.notification) {
            var notificationDate = getDateBeforeDate(data.date, data.notification, 'days');
            var notificationObj = _this.getFormattedDate(notificationDate, data.id, data.notificationLabel, timelineObj.bgColor, timelineObj.textColor, timelineObj.showDateTitle, true);
            if (notificationObj.onTimeLine >= 0) {
              _this.calcBellHeights(notificationObj.id, notificationObj.onTimeLine);
              result.push(notificationObj);
            }
          }
        });
      });
      this.timelineDates = result;
    },
    getFormattedDate: function getFormattedDate(date, id, label, bgColor, textColor) {
      var showDateTitle = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
      var isBell = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
      var dateObj = {
        formatted: toLongLocalDateTranslated(date, this.$trans),
        onTimeLine: this.getOnTimelineInPercent(date),
        label: this.$trans(label),
        bgColor: bgColor,
        textColor: textColor,
        id: id,
        showDateTitle: showDateTitle,
        isBell: isBell
      };
      dateObj.title = "".concat(dateObj.formatted, "\n").concat(dateObj.label);
      return dateObj;
    },
    calcBellHeights: function calcBellHeights(newBellKey, timeLine) {
      var _this2 = this;
      var keys = Object.keys(this.bellsOnTimeline);
      var keysLength = keys.length;
      var intersectionWith = [];
      this.bellsOnTimeline[newBellKey] = {
        timeLine: timeLine,
        height: 0,
        order: keysLength
      };
      keys.forEach(function (key) {
        if (key !== newBellKey && _this2.getIsTooClose(timeLine, _this2.bellsOnTimeline[key].timeLine)) {
          intersectionWith.push(key);
        }
      });
      intersectionWith = intersectionWith.sort(function (key1, key2) {
        return _this2.bellsOnTimeline[key2].order - _this2.bellsOnTimeline[key1].order;
      });
      intersectionWith.forEach(function (key, index) {
        _this2.bellsOnTimeline[key].height = index + 1;
      });
    },
    getBellHeight: function getBellHeight(key) {
      var _this$bellsOnTimeline;
      var heightIndex = ((_this$bellsOnTimeline = this.bellsOnTimeline[key]) === null || _this$bellsOnTimeline === void 0 ? void 0 : _this$bellsOnTimeline.height) || null;
      if (!heightIndex || heightIndex >= this.bellHeight.length) {
        return this.bellHeight[0];
      }
      return this.bellHeight[heightIndex];
    },
    getIsTooClose: function getIsTooClose(timeline1, timeline2) {
      return Math.abs(timeline1 - timeline2) < TOO_CLOSE;
    },
    getDatesStyles: function getDatesStyles(date) {
      if (date.isBell) return '';
      var zIndex = date.showDateTitle ? 'z-10' : 'z-15';
      return "-translate-y-1/2 top-1/2 ".concat(zIndex);
    }
  }
};