import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "mb-4"
};
var _hoisted_2 = {
  class: "flex mb-4"
};
var _hoisted_3 = {
  key: 0,
  class: "col-12 text-center info-message"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
        modelValue: $data.selectedUser,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.selectedUser = $event;
        }),
        class: "!w-1/2 mr-10",
        title: $data.translate.filterByUsername,
        "with-reset": true,
        placeholder: $data.translate.chooseUser,
        options: $options.getOptionsUsers,
        "with-search": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options"]), _createVNode(_component_risma_date_range_picker, {
        class: "w-1/2",
        title: $data.translate.filterByDateRange,
        "start-date": $data.dateRange.startDate,
        "end-date": $data.dateRange.endDate,
        "date-format": $data.DateFormats.DATE_TIME_FORMAT_FULL,
        type: "datetime",
        onChanged: $options.changedDates,
        onDiscard: $options.onDateDiscard
      }, null, 8 /* PROPS */, ["title", "start-date", "end-date", "date-format", "onChanged", "onDiscard"])]), _createVNode(_component_risma_button, {
        text: $data.translate.createReport,
        type: "save",
        class: "ml-auto",
        onClick: $options.createReport
      }, null, 8 /* PROPS */, ["text", "onClick"])]), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0,
        class: "load"
      })) : _createCommentVNode("v-if", true), $data.showReport ? (_openBlock(), _createBlock(_component_main_well, {
        key: 1,
        class: _normalizeClass({
          'pb-5': !$data.showNothingToShowMessage
        })
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_risma_datatable, {
            "dataset-main": _ctx.datasetSlice,
            "dataset-total": $data.dataset,
            "dataset-total-sliced": _ctx.datasetSliceTotal,
            order: $data.orderNew,
            "wrapper-height-sticky": 500,
            onPageChange: _ctx.onPageChange,
            onOnStateChanged: _ctx.onStateChanged
          }, null, 8 /* PROPS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "order", "onPageChange", "onOnStateChanged"]), $data.showNothingToShowMessage ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
            title: $data.translate.nothingToShow
          }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.enterYourSearchCriteriaAboveToViewSearchRe), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  });
}