function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "flex items-center flex-wrap"
};
var _hoisted_2 = {
  class: "mr-2"
};
var _hoisted_3 = {
  class: "border border-gray-450 p-6 pt-1 mb-3"
};
var _hoisted_4 = {
  class: "p-2 font-bold text-lg"
};
var _hoisted_5 = {
  class: "flex flex-wrap -mb-4 items-center"
};
var _hoisted_6 = {
  key: 0,
  class: "w-215px mr-4 mb-4"
};
var _hoisted_7 = {
  key: 1,
  class: "w-215px mr-4 mb-4"
};
var _hoisted_8 = {
  key: 2,
  class: "w-80 mr-4 mb-4"
};
var _hoisted_9 = {
  class: "w-80 mr-4 mb-4"
};
var _hoisted_10 = {
  class: "w-215px mr-4 mb-4"
};
var _hoisted_11 = {
  key: 3,
  class: "w-215px mr-4 mb-4"
};
var _hoisted_12 = {
  key: 4,
  class: "w-215px mr-4 mb-4"
};
var _hoisted_13 = {
  key: 5,
  class: "w-215px mr-4 mb-4"
};
var _hoisted_14 = {
  key: 6,
  class: "w-215px mr-4 mb-4"
};
var _hoisted_15 = {
  key: 7,
  class: "min-w-370px w-min mr-4 mb-4"
};
var _hoisted_16 = {
  class: "flex mb-4"
};
var _hoisted_17 = {
  key: 0,
  class: "flex items-center mr-4 pt-5"
};
var _hoisted_18 = {
  key: 0,
  class: "border border-gray-450 p-6 pt-1 mb-3"
};
var _hoisted_19 = {
  class: "p-2 font-bold text-lg"
};
var _hoisted_20 = {
  class: "flex"
};
var _hoisted_21 = {
  key: 0,
  class: "w-215px mr-4"
};
var _hoisted_22 = {
  class: "w-48"
};
var _hoisted_23 = {
  class: "font-bold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_tag_item = _resolveComponent("tag-item");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_frameworks_selector = _resolveComponent("frameworks-selector");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: _normalizeClass(["flex items-center w-full mt-3 mb-3 font-semibold", [$options.showAppliedFilters ? 'justify-between' : 'justify-end', $props.headerStyles]])
  }, [$options.showAppliedFilters ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.appliedFilters, ":"),
    type: "medium",
    class: "mr-4"
  }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.appliedFilters, function (filter) {
    return _openBlock(), _createBlock(_component_tag_item, {
      key: filter.key,
      title: filter.title,
      class: "mr-2",
      onClose: function onClose($event) {
        return $options.removeFilter(filter.key);
      }
    }, null, 8 /* PROPS */, ["title", "onClose"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: "cursor-pointer flex px-2",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.filterByEntity(!$props.modelValue.showOverviewOptions, 'showOverviewOptions');
    })
  }, [_createElementVNode("p", _hoisted_2, _toDisplayString($options.showOverviewOptionsTitle), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    icon: "chevron-down",
    class: _normalizeClass(["stroke-blue stroke-3 inline-block", ['transform duration-500', {
      'rotate-180': $props.modelValue.showOverviewOptions
    }]])
  }, null, 8 /* PROPS */, ["class"])])], 2 /* CLASS */), _withDirectives(_createElementVNode("div", null, [_createElementVNode("fieldset", _hoisted_3, [_createElementVNode("legend", _hoisted_4, _toDisplayString($data.translate.mainFilters), 1 /* TEXT */), _createElementVNode("div", _hoisted_5, [$props.data.deadline ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_single_select, {
    title: $data.translate.deadline,
    placeholder: $data.translate.noFilter,
    options: $props.data.deadline,
    "model-value": $props.modelValue.deadline,
    "un-sorted": true,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $options.filterByEntity($event, 'deadline');
    })
  }, null, 8 /* PROPS */, ["title", "placeholder", "options", "model-value"])])) : _createCommentVNode("v-if", true), $props.data.organisations ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_select, {
    title: $data.translate.organisations,
    options: $props.data.organisations,
    "selected-options": $props.modelValue.organisations,
    placeholder: $data.translate.noFiltersApplied,
    "label-key": "visible_name",
    onSelected: _cache[2] || (_cache[2] = function ($event) {
      return $options.filterByEntity($event, 'organisations');
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])])) : _createCommentVNode("v-if", true), $props.data.activityTypes ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_risma_select, {
    title: $data.translate.activityType,
    options: $props.data.activityTypes,
    "selected-options": $props.modelValue.activityTypes,
    placeholder: $data.translate.noFiltersApplied,
    "label-key": "label",
    onSelected: _cache[3] || (_cache[3] = function ($event) {
      return $options.filterByEntity($event, 'activityTypes');
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_9, [_createVNode(_component_single_select, {
    title: $data.translate.motherControls,
    placeholder: $data.translate.noFiltersApplied,
    options: $props.data.mother,
    "model-value": $props.modelValue.mother,
    "un-sorted": true,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $options.filterByEntity($event, 'mother');
    })
  }, null, 8 /* PROPS */, ["title", "placeholder", "options", "model-value"])]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_risma_select, {
    title: $data.translate.tags,
    options: $props.data.tags,
    "selected-options": $props.modelValue.tags,
    placeholder: $data.translate.noFiltersApplied,
    "label-key": "tag",
    onSelected: _cache[5] || (_cache[5] = function ($event) {
      return $options.filterByEntity($event, 'tags');
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])]), $props.data.users ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_risma_select, {
    title: $data.translate.responsible,
    options: $props.data.users,
    "selected-options": $props.modelValue.responsible,
    placeholder: $data.translate.noFiltersApplied,
    "label-key": "display_name",
    onSelected: _cache[6] || (_cache[6] = function ($event) {
      return $options.filterByEntity($event, 'responsible');
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])])) : _createCommentVNode("v-if", true), $props.data.accounts ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createVNode(_component_risma_select, {
    title: $options.accountTitle,
    options: $props.data.accounts,
    "selected-options": $props.modelValue.accounts,
    "label-key": "title",
    placeholder: $data.translate.noFiltersApplied,
    onSelected: _cache[7] || (_cache[7] = function ($event) {
      return $options.filterByEntity($event, 'accounts');
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])])) : _createCommentVNode("v-if", true), $props.data.processes ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(_component_risma_select, {
    title: $options.processesTitle,
    options: $props.data.processes,
    "selected-options": $props.modelValue.processes,
    "label-key": "title",
    placeholder: $data.translate.noFiltersApplied,
    onSelected: _cache[8] || (_cache[8] = function ($event) {
      return $options.filterByEntity($event, 'processes');
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])])) : _createCommentVNode("v-if", true), $props.data.risks ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_risma_select, {
    title: $options.risksTitle,
    options: $props.data.risks,
    "selected-options": $props.modelValue.risks,
    "label-key": "title",
    placeholder: $data.translate.noFiltersApplied,
    onSelected: _cache[9] || (_cache[9] = function ($event) {
      return $options.filterByEntity($event, 'risks');
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])])) : _createCommentVNode("v-if", true), $props.data.frameworks ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createVNode(_component_frameworks_selector, {
    "selected-options": $props.modelValue.frameworks,
    placeholder: $data.translate.noFiltersApplied,
    "toggle-object": $options.frameworkToggleObject,
    onSelected: _cache[10] || (_cache[10] = function ($event) {
      return $options.filterByEntity($event, 'frameworks');
    })
  }, null, 8 /* PROPS */, ["selected-options", "placeholder", "toggle-object"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", null, [_createVNode(_component_input_field, {
    title: $data.translate.search,
    "model-value": $props.modelValue.search,
    placeholder: $data.translate.searchForNoOrTitle,
    type: "text",
    class: "w-215px mr-4",
    "onUpdate:modelValue": $options.handleSearch
  }, null, 8 /* PROPS */, ["title", "model-value", "placeholder", "onUpdate:modelValue"])]), $props.data.showDeactivated ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createVNode(_component_risma_toggle, {
    "model-value": $props.modelValue.showDeactivated,
    class: "mr-2",
    onInput: _cache[11] || (_cache[11] = function ($event) {
      return _ctx.$emit('update:modelValue', _objectSpread(_objectSpread({}, $props.modelValue), {}, {
        showDeactivated: $event
      })) && $options.saveState;
    })
  }, null, 8 /* PROPS */, ["model-value"]), _createVNode(_component_risma_title, {
    title: $data.translate.showDeactivatedControls,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)])])]), $props.data.groupByOptions ? (_openBlock(), _createElementBlock("fieldset", _hoisted_18, [_createElementVNode("legend", _hoisted_19, _toDisplayString($data.translate.groupAndSort), 1 /* TEXT */), _createElementVNode("div", _hoisted_20, [$props.data.groupByOptions ? (_openBlock(), _createElementBlock("div", _hoisted_21, [_createVNode(_component_single_select, {
    title: $data.translate.groupBy,
    placeholder: $data.translate.noFiltersApplied,
    options: $props.data.groupByOptions,
    "model-value": $props.modelValue.groupBy,
    "un-sorted": true,
    "onUpdate:modelValue": $options.groupBy
  }, null, 8 /* PROPS */, ["title", "placeholder", "options", "model-value", "onUpdate:modelValue"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_22, [_createVNode(_component_single_select, {
    modelValue: $props.modelValue.sortBy,
    "onUpdate:modelValue": [_cache[12] || (_cache[12] = function ($event) {
      return $props.modelValue.sortBy = $event;
    }), $options.saveState],
    title: $data.translate.sortBy,
    placeholder: $data.translate.sortBy,
    options: $props.data.sortByOptions,
    "un-sorted": true
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options", "onUpdate:modelValue"])])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: "inline-flex self-center cursor-pointer",
    onClick: _cache[13] || (_cache[13] = function () {
      return $options.resetFilters && $options.resetFilters.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "rotate-ccw",
    class: "text-gray-610 stroke-2 mr-1"
  }), _createElementVNode("span", _hoisted_23, _toDisplayString($data.translate.resetOptions), 1 /* TEXT */)])], 512 /* NEED_PATCH */), [[_vShow, $props.modelValue.showOverviewOptions]])]);
}