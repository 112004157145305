function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import i18n from '@/i18n';
import { MODULES } from '@/constants/modules';
var DefaultWorkflowStatuses = _defineProperty(_defineProperty({}, MODULES.INCIDENTS, ['Open', 'Reported', 'Closed', 'Mitigated']), MODULES.CONTRACTS, ['Draft', 'In Review', 'In Renewal', 'Canceled', 'Approved', 'Active', 'Expired']);
export var getWorkflowStatusTitle = function getWorkflowStatusTitle(title) {
  var _DefaultWorkflowStatu2;
  var module = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : MODULES.CONTRACTS;
  return (_DefaultWorkflowStatu2 = DefaultWorkflowStatuses[module]) !== null && _DefaultWorkflowStatu2 !== void 0 && _DefaultWorkflowStatu2.includes(title) ? i18n.t(title) : title;
};