function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useWorkflowStore } from '@/Store/workflowStore';
import { getSingle as getIncident, update as updateIncidents } from '@/api/incident/incident';
import { getQuestionnairesByQuestionnaireTypeId } from '@/api/compliance/questionnaire';
import { TabTypes, TabsDepRelations, DepRelationsTabDelegation, DepRelationsTabDetails, Tabs } from '@/constants/Tabs';
import { saveStatus } from '@/constants/SaveStatuses';
import { QuestionnaireTypes } from '@/constants/compliance/QuestionnaireTypes';
import { createTabsList } from '@/utils/base/tabs';
import { getLinksLabel } from '@/utils/links';
import { getParsedCfValues, getMissingRequiredFieldsLabels } from '@/utils/CustomFields';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import DelegationWell from '@/components/Organisms/General/coreWells/DelegationWell';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
import Notification from '@/components/Molecules/Notification';
import RequiredFieldsNotification from '@/components/Molecules/RequiredFieldsNotification';
import StatusTransition from '@/components/Organisms/StatusTransition';
import MultipleQuestionnaireSelector from '@/components/Molecules/QuestionnaireSelector/MultipleQuestionnaireSelector';
import { MODULES } from '@/constants/modules';
import { INCIDENT } from '@/constants/routes/incident';
import { UserLevels } from '@/constants/UserLevels';
import { isFieldInOptionalFields } from '@/utils/Utils';
import { isLockedIncidentUser } from '@/utils/access';
import { updateFileLocally } from '@/utils/Attachments';
import { catch403 } from '@/utils/handleAPIErrors';
export default {
  name: 'IncidentEntry',
  description: 'Common page for show incident',
  token: '<incident-entry />',
  components: {
    DepWell: DepWell,
    Notification: Notification,
    RequiredFieldsNotification: RequiredFieldsNotification,
    DelegationWell: DelegationWell,
    ActivityTypeSelector: ActivityTypeSelector,
    RismaTitle: RismaTitle,
    StatusTransition: StatusTransition,
    MultipleQuestionnaireSelector: MultipleQuestionnaireSelector
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    id: {
      required: true,
      type: [String, Number],
      note: 'Id of the incident or "new" for creation an incident'
    }
  },
  emits: ['changeTab', 'updated'],
  data: function data() {
    return {
      workflowStatusId: null,
      dirtyIncident: {},
      incident: null,
      saveStatus: saveStatus.SAVED,
      updateTimer: 0,
      module: MODULES.INCIDENTS,
      questionnaireType: QuestionnaireTypes.INCIDENT,
      questionnaires: [],
      tabsDepRelations: TabsDepRelations(),
      isDirty: false,
      isStatusUpdated: false,
      isPageWithCustomUpdateDirty: false,
      translate: {
        thisActivityIsReadonlyAsYourUserIsConfigu: this.$trans('This activity is read-only as your user is configured to only have read-only access'),
        activityType: this.$trans('Activity type'),
        areYouSureYouWantToChangeTheActivityType: this.$trans('Are you sure you want to change the activity type?'),
        pleaseFillOutThisField: this.$trans('Please fill out this field.')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettings: 'getValue'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'incidentActivityTypes'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useUsersStore, {
    unfilteredUsers: 'activeUsers'
  })), mapState(useUserStore, ['currentUser'])), mapState(useWorkflowStore, ['getWorkflow'])), {}, {
    users: function users() {
      return this.unfilteredUsers.filter(function (user) {
        return user.level_incidents > UserLevels.NONE || user.is_admin;
      });
    },
    isLoaded: function isLoaded() {
      return this.incident !== null;
    },
    isUserLocked: function isUserLocked() {
      return isLockedIncidentUser(this.currentUser);
    },
    readOnly: function readOnly() {
      var _this$incident;
      return this.isUserLocked || !!((_this$incident = this.incident) !== null && _this$incident !== void 0 && _this$incident.archived);
    },
    tabs: function tabs() {
      var _this$incident2;
      var tabs = [TabTypes.DESCRIPTION];
      var suffixTabs = [TabTypes.LINKS, TabTypes.CHANGELOG];
      if ((_this$incident2 = this.incident) !== null && _this$incident2 !== void 0 && (_this$incident2 = _this$incident2.questionnaires) !== null && _this$incident2 !== void 0 && _this$incident2.length) {
        this.incident.questionnaires.forEach(function (questionnaire) {
          if (!questionnaire.tabLabel) return;
          tabs.push(_objectSpread(_objectSpread({}, Tabs[TabTypes.QUESTIONNAIRE]), {}, {
            url: questionnaire.url,
            slicedUrl: "".concat(Tabs[TabTypes.QUESTIONNAIRE].url, "/").concat(questionnaire.questionnaireId),
            label: questionnaire.tabLabel,
            tabKey: TabTypes.QUESTIONNAIRE
          }));
        });
      }
      tabs.push.apply(tabs, suffixTabs);
      return createTabsList(tabs, "/incident/".concat(this.id), getLinksLabel(this.incident));
    },
    depHeaderProps: function depHeaderProps() {
      if (!this.isLoaded) {
        return {};
      }
      return {
        editableTitle: !this.readOnly,
        title: this.incident.title,
        filesList: this.incident.attachments,
        tabs: this.tabs,
        saveStatus: this.saveStatus,
        titleField: 'title',
        trafficLightSettings: this.incident.severity !== null ? {
          value: this.incident.severity,
          lightType: this.module,
          field: 'severity',
          readOnly: this.readOnly
        } : {},
        id: this.incident.id + '',
        moduleId: this.incident.id,
        module: this.module,
        editableAttachments: !this.readOnly
      };
    },
    depRelationsTabs: function depRelationsTabs() {
      return [DepRelationsTabDelegation(), DepRelationsTabDetails(this.hasRelationsTab)];
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.isLoaded && this.activityTypes.find(function (at) {
        return _this.incident.activityTypeId === at.id;
      });
    },
    hasRelationsTab: function hasRelationsTab() {
      return this.getSettings['feature.performance_report_enabled'] > 0 || this.incident && this.activityTypes.length;
    },
    missingRequiredFields: function missingRequiredFields() {
      return getMissingRequiredFieldsLabels(this.currentActivityType, this.incident.customFieldValuesParsed || getParsedCfValues(this.incident));
    },
    showIncidentWorkflowStatus: function showIncidentWorkflowStatus() {
      var _this$currentWorkflow;
      return this.isLoaded && !!((_this$currentWorkflow = this.currentWorkflow) !== null && _this$currentWorkflow !== void 0 && _this$currentWorkflow.activityTypeId);
    },
    currentWorkflow: function currentWorkflow() {
      return this.getWorkflow(MODULES.INCIDENTS, this.currentActivityType.id);
    },
    incidentFormatted: function incidentFormatted() {
      var _this2 = this;
      var incidentCopy = Object.assign({
        id: this.incident.id
      }, this.dirtyIncident);
      var optionalFields = ['severity', 'description'];
      var activityType = this.activityTypes.find(function (item) {
        return item.id === _this2.incident.activityTypeId;
      });
      optionalFields.forEach(function (field) {
        if (!isFieldInOptionalFields(activityType, field) || incidentCopy[field] === null) {
          delete incidentCopy[field];
          if (field === 'severity') {
            delete incidentCopy.trafficLight;
          }
        }
      });
      if (incidentCopy.customFieldValuesParsed) {
        incidentCopy.customFieldValues = incidentCopy.customFieldValuesParsed;
      }
      return incidentCopy;
    }
  }),
  mounted: function mounted() {
    this.getInitData();
  },
  methods: {
    getInitData: function getInitData() {
      var requests = [this.getIncidentData(this.id), this.getQuestionnairesList()];
      return Promise.all(requests);
    },
    getQuestionnairesList: function getQuestionnairesList() {
      var _this3 = this;
      return getQuestionnairesByQuestionnaireTypeId(this.questionnaireType).then(function (_ref) {
        var list = _ref.list;
        _this3.questionnaires = list.filter(function (item) {
          return item.selectable;
        }).map(function (item) {
          item.label = item.title;
          item.value = item.id;
          return item;
        });
      });
    },
    getIncidentData: function getIncidentData(id) {
      var _this4 = this;
      return getIncident(id).then(function (response) {
        _this4.incident = response;
        _this4.workflowStatusId = response.workflowStatusId;
        if (_this4.incident.incidentHappenedAt === null) {
          _this4.incident.incidentHappenedAt = '';
        }
      }).catch(function () {
        return catch403(_this4.$router, 'MyIncidents');
      });
    },
    incidentPropertyChanged: function incidentPropertyChanged(_ref2) {
      var property = _ref2.property,
        value = _ref2.value;
      this.isDirty = true;
      if (property === 'responsibleUserIds') {
        if (!value.length && this.incident.confidential) {
          this.dirtyIncident.confidential = 0;
        }
      }
      this.dirtyIncident[property] = value;
      this.incident = Object.assign({}, this.incident, this.dirtyIncident);
      this.setUpdateTimer();
    },
    saveChangesImmediately: function saveChangesImmediately(updatedProperty) {
      this.incidentPropertyChanged(updatedProperty);
      this.saveStatusClicked();
    },
    setUpdateTimer: function setUpdateTimer() {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.saveUpdates, this.$autoSaveDebounceTime);
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        clearTimeout(this.updateTimer);
        this.saveUpdates();
      }
    },
    saveUpdates: function saveUpdates() {
      var _this5 = this;
      this.saveStatus = saveStatus.SAVING;
      updateIncidents(this.incidentFormatted).then(function (response) {
        if (!Object.values(response).length) {
          return _this5.$router.push({
            name: INCIDENT.incidentList
          });
        }
        if (_this5.workflowStatusId !== response.workflowStatusId && !_this5.checkIfUserHasAccess(response)) {
          return _this5.$router.push({
            name: INCIDENT.myIncidentsList
          });
        }
        _this5.workflowStatusId = response.workflowStatusId;
        _this5.refreshDelegationUsers();
        _this5.dirtyIncident = {};
        if (response.questionnaires) {
          _this5.incident.questionnaires = response.questionnaires;
        }
        _this5.$emit('updated', _objectSpread({}, _this5.incident));
        _this5.saveStatus = saveStatus.SAVED;
      }).catch(function () {
        _this5.saveStatus = saveStatus.NOT_SAVED;
      }).finally(function () {
        _this5.isDirty = false;
      });
    },
    checkIfUserHasAccess: function checkIfUserHasAccess(incident) {
      var delegationProps = ['responsibleUserIds', 'accountableUserIds', 'consultedUserIds', 'informedUserIds'];
      var _this$currentUser = this.currentUser,
        id = _this$currentUser.id,
        level_incidents = _this$currentUser.level_incidents,
        is_admin = _this$currentUser.is_admin;
      return delegationProps.some(function (prop) {
        return incident[prop].includes(id);
      }) || level_incidents > UserLevels.PRIVILEGED || is_admin;
    },
    statusChanged: function statusChanged(property) {
      this.isStatusUpdated = true;
      this.incidentPropertyChanged(property);
    },
    refreshDelegationUsers: function refreshDelegationUsers() {
      if (this.$refs.delegationWell && this.isStatusUpdated && this.dirtyIncident.responsibleUserIds) {
        this.$refs.delegationWell.getSelectedUsers();
      }
      this.isStatusUpdated = false;
    },
    handleUpdateForCustomPageUpdate: function handleUpdateForCustomPageUpdate(data) {
      this.isPageWithCustomUpdateDirty = data.value;
      if (data.value) {
        this.setUpdateTimer();
        if (data.isForced) {
          this.saveStatusClicked();
        }
      } else if (!this.isDirty) {
        this.saveStatus = saveStatus.SAVED;
      }
    },
    onFileUpdate: function onFileUpdate(fileId) {
      var _this$incident3;
      updateFileLocally(fileId, (_this$incident3 = this.incident) === null || _this$incident3 === void 0 ? void 0 : _this$incident3.attachments);
      this.saveStatus = saveStatus.SAVED;
    },
    updatingAttachments: function updatingAttachments() {
      this.saveStatus = saveStatus.SAVING;
    },
    getActivityData: function getActivityData() {
      var _this6 = this;
      this.getIncidentData(this.id).then(function () {
        return _this6.saveStatus = saveStatus.SAVED;
      });
    },
    addQuestionnaire: function addQuestionnaire(id) {
      var questionnaire = this.questionnaires.find(function (item) {
        return +item.id === +id;
      });
      if (!questionnaire) return;
      this.isDirty = true;
      this.incidentPropertyChanged({
        property: 'questionnaires',
        value: this.incident.questionnaires ? [].concat(_toConsumableArray(this.incident.questionnaires), [{
          questionnaireId: id
        }]) : [{
          questionnaireId: id
        }]
      });
    },
    removeQuestionnaire: function removeQuestionnaire(id) {
      this.incidentPropertyChanged({
        property: 'questionnaires',
        value: this.incident.questionnaires.filter(function (q) {
          return q.questionnaireId != id;
        })
      });
    }
  }
};