import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue";
var _hoisted_1 = {
  class: "user-settings-profile"
};
var _hoisted_2 = {
  key: 1,
  class: "grid grid-cols-2 gap-x-24"
};
var _hoisted_3 = {
  class: "relative p-3"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "flex relative justify-center items-center p-2 border-2 border-gray-710"
};
var _hoisted_6 = {
  class: "absolute configuration-key text-blue-610 text-sm"
};
var _hoisted_7 = {
  class: "uppercase font-bold lining-nums"
};
var _hoisted_8 = {
  class: "divide-y"
};
var _hoisted_9 = {
  class: "user-info-wrapper py-4"
};
var _hoisted_10 = {
  class: "user-info"
};
var _hoisted_11 = {
  key: 0,
  class: "user-settings-organisations py-4"
};
var _hoisted_12 = {
  class: "user-settings-actions"
};
var _hoisted_13 = {
  class: "user-settings-language py-4"
};
var _hoisted_14 = {
  class: "flag-icon-wrap -my-1.5"
};
var _hoisted_15 = {
  class: "flag-label"
};
var _hoisted_16 = ["onClick"];
var _hoisted_17 = {
  class: "flag-label"
};
var _hoisted_18 = {
  class: "user-settings-password py-4"
};
var _hoisted_19 = {
  key: 1,
  class: "py-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_modal = _resolveComponent("modal");
  var _component_single_select = _resolveComponent("single-select");
  var _component_router_link = _resolveComponent("router-link");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showTwoFactorModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    "modal-fixed-width": '650',
    header: $data.translate.twoFactorAuthentication,
    "button-ok-text": $data.translate.save,
    "button-dismiss-text": $data.translate.cancel,
    "dismiss-on-click-outside": false,
    onAccept: $options.toggleTwoFactorModal,
    onDismiss: $options.disableMfa
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        class: "mb-3",
        title: $data.translate.pleaseOpenYourAuthenticatorApplicationOnYour,
        truncate: false,
        type: "small"
      }, null, 8 /* PROPS */, ["title"]), $data.qrLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("svg", {
        class: "absolute inset-0",
        width: "100%",
        height: "100%"
      }, [_createElementVNode("rect", {
        class: "angled-border",
        height: "100%",
        width: "100%"
      })], -1 /* HOISTED */)), _createElementVNode("img", {
        class: "object-contain w-full h-full",
        src: $data.qrImage,
        alt: "Generated QR code"
      }, null, 8 /* PROPS */, _hoisted_4)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", _hoisted_6, _toDisplayString($data.translate.configurationKey), 1 /* TEXT */), _createElementVNode("span", _hoisted_7, _toDisplayString($options.qrCodeFormatted), 1 /* TEXT */)])]))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.profileDetails,
    class: "user-initials",
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_risma_title, {
    title: $data.translate.username,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString(_ctx.currentUser.username), 1 /* TEXT */), _createVNode(_component_risma_title, {
    title: $data.translate.fullName,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString(_ctx.currentUser.fullname), 1 /* TEXT */), _createVNode(_component_risma_title, {
    title: $data.translate.initials,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString(_ctx.currentUser.initials), 1 /* TEXT */), _createVNode(_component_risma_title, {
    title: $data.translate.email,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString(_ctx.currentUser.email), 1 /* TEXT */)])]), $options.organisationsList.length ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $data.translate.organisationsYouAreConnectedTo,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.organisationsList, function (organisation) {
    return _openBlock(), _createElementBlock("span", {
      key: organisation.id,
      class: "organisation"
    }, _toDisplayString(organisation.name), 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createVNode(_component_risma_title, {
    title: $data.translate.language,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
    "model-value": $options.language,
    options: $options.languages,
    "with-reset": false
  }, {
    singleLabel: _withCtx(function (_ref) {
      var option = _ref.option;
      return [_createElementVNode("div", _hoisted_14, [_createElementVNode("div", {
        class: _normalizeClass("flag-icon flag-icon-".concat(option.img))
      }, null, 2 /* CLASS */), _createElementVNode("span", _hoisted_15, _toDisplayString(option.label), 1 /* TEXT */)])];
    }),
    option: _withCtx(function (_ref2) {
      var option = _ref2.option;
      return [_createElementVNode("a", {
        class: "flag-icon-wrap w-full",
        onClick: _withModifiers(function ($event) {
          return $options.updateLanguage(option.value);
        }, ["prevent"])
      }, [_createElementVNode("div", {
        class: _normalizeClass("flag-icon flag-icon-".concat(option.img))
      }, null, 2 /* CLASS */), _createElementVNode("span", _hoisted_17, _toDisplayString(option.label), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_16)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model-value", "options"])])]), _createElementVNode("div", _hoisted_18, [_createVNode(_component_risma_title, {
    title: $data.translate.password,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_router_link, {
    to: "/settings/change-password",
    class: "reset-password"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.changePassword), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })]), $options.isMfaFeatureEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createVNode(_component_risma_title, {
    class: "mb-2",
    title: $data.translate.twoFactorAuthentication,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    class: "mb-2",
    title: $data.translate.useAnAuthenticatorToGenerateVerificationCode,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), !_ctx.isMfaEnabled ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.enableAuthentication,
    type: "save",
    onClick: $options.enableMfa
  }, null, 8 /* PROPS */, ["text", "onClick"])) : $options.isUserHaveRightsToDisableMfa ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 1,
    text: $data.translate.disableAuthentication,
    type: "danger",
    onClick: $options.disableMfa
  }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    class: "mb-2",
    title: $data.translate.downloadAnAuthenticatorFromAppStoreplayStor,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"flex items-stretch\" data-v-7f902bdd><div class=\"flex items-center\" data-v-7f902bdd><a href=\"https://apps.apple.com/us/app/google-authenticator/id388497605?itsct=apps_box_badge&amp;itscg=30200\" class=\"flex\" style=\"overflow:hidden;border-radius:13px;height:61px;width:180px;\" data-v-7f902bdd><img src=\"https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1284940800&amp;h=7fc6b39acc8ae5a42ad4b87ff8c7f88d\" alt=\"Download on the App Store\" style=\"border-radius:13px;\" data-v-7f902bdd></a></div><div data-v-7f902bdd><a href=\"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1\" class=\"flex\" style=\"height:90px;width:232px;\" data-v-7f902bdd><img alt=\"Get it on Google Play\" src=\"https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png\" data-v-7f902bdd></a></div></div>", 1))])) : _createCommentVNode("v-if", true)])]);
}