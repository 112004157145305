// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting-item h1[data-v-4ab6ea3c] {
  font-size: 20px !important;
}
.sector-title h1[data-v-4ab6ea3c] {
  font-size: 22px !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
