function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useWorkflowStore } from '@/Store/workflowStore';
import { clickOutside } from '@/utils/directives/clickOutside';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import { UserLevels } from '@/constants/UserLevels';
import { getTranslate } from '@/components/Organisms/translate';
import { MODULES } from '@/constants/modules';
import { formatCurrentDateTime } from '@/utils/date';
import { DateFormats } from '@/constants/DateFormats';
export default {
  name: 'StatusTransition',
  token: '<status-transition />',
  components: {
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    RismaSelect: RismaSelect,
    TinyMceBox: TinyMceBox
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    incident: {
      type: Object,
      required: true,
      note: 'Incident object'
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    return {
      isDropdownOpen: false,
      showTransitionModal: false,
      transitionModalTitle: '',
      initialStatusNote: '',
      transition: {},
      translate: getTranslate['StatusTransition']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, ['activeUsersWithLevelAccess'])), mapState(useWorkflowStore, ['getWorkflow'])), {}, {
    responsibleOptions: function responsibleOptions() {
      return this.activeUsersWithLevelAccess('level_incidents', UserLevels.NORMAL);
    },
    responsibleSelected: function responsibleSelected() {
      var _this = this;
      return this.responsibleOptions.filter(function (_ref) {
        var id = _ref.id;
        return _this.transition.suggestedResponsible.includes(id);
      });
    },
    currentWorkflow: function currentWorkflow() {
      return this.getWorkflow(MODULES.INCIDENTS, this.incident.activityTypeId);
    },
    status: function status() {
      var _this$currentWorkflow,
        _this2 = this;
      return ((_this$currentWorkflow = this.currentWorkflow) === null || _this$currentWorkflow === void 0 ? void 0 : _this$currentWorkflow.workflowStatuses.find(function (status) {
        return status.id === _this2.incident.workflowStatusId;
      })) || null;
    },
    targetStatuses: function targetStatuses() {
      var _this$status, _this$currentWorkflow2;
      if ((_this$status = this.status) !== null && _this$status !== void 0 && _this$status.targetStatuses) {
        var ids = this.status.targetStatuses.map(function (st) {
          return st.id;
        });
        return this.currentWorkflow.workflowStatuses.filter(function (st) {
          return ids.includes(st.id);
        });
      }
      var startStatus = (_this$currentWorkflow2 = this.currentWorkflow) === null || _this$currentWorkflow2 === void 0 ? void 0 : _this$currentWorkflow2.workflowStatuses.find(function (status) {
        return status.isStartStatus;
      });
      return startStatus ? [startStatus] : [];
    },
    hasTargetStatuses: function hasTargetStatuses() {
      return this.targetStatuses.length;
    },
    transitionData: function transitionData() {
      return _objectSpread(_objectSpread({}, this.transition), {}, {
        responsibleUserIds: this.transition.suggestedResponsible
      });
    },
    isWorkflowStatusNoteUpdated: function isWorkflowStatusNoteUpdated() {
      return this.transitionData.workflowStatusNote !== this.initialStatusNote;
    }
  }),
  beforeMount: function beforeMount() {
    this.initTransitionObj();
  },
  methods: {
    onResponsibleSelected: function onResponsibleSelected(event) {
      this.transition.suggestedResponsible = event.map(function (_ref2) {
        var id = _ref2.id;
        return id;
      });
    },
    transitionTo: function transitionTo(_ref3) {
      var id = _ref3.id,
        title = _ref3.title,
        _ref3$suggestedRespon = _ref3.suggestedResponsible,
        suggestedResponsible = _ref3$suggestedRespon === void 0 ? [] : _ref3$suggestedRespon;
      this.showTransitionModal = true;
      this.transition.workflowStatusId = id;
      this.transition.suggestedResponsible = suggestedResponsible;
      this.transitionModalTitle = "".concat(this.translate.transitionIncidentTo, " ").concat(title);
      this.closeDropdown();
    },
    doTransition: function doTransition() {
      var _this3 = this;
      Object.keys(this.transitionData).forEach(function (key) {
        if (key === 'workflowStatusNote' && !_this3.isWorkflowStatusNoteUpdated) return;
        _this3.$emit('propertyChanged', {
          property: key,
          value: _this3.transitionData[key]
        });
      });
      this.closeModal();
    },
    closeDropdown: function closeDropdown() {
      this.isDropdownOpen = false;
    },
    toggleDropdown: function toggleDropdown() {
      if (!this.hasTargetStatuses) return;
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    closeModal: function closeModal() {
      this.showTransitionModal = false;
      this.initTransitionObj();
    },
    initTransitionObj: function initTransitionObj() {
      this.initialStatusNote = "<p>".concat(formatCurrentDateTime(DateFormats.DATE_TIME_FORMAT), ": </p>");
      this.transition = {
        workflowStatusId: 0,
        workflowStatusNote: this.initialStatusNote,
        suggestedResponsible: []
      };
    },
    getColorStyles: function getColorStyles(status) {
      var color = (status === null || status === void 0 ? void 0 : status.color) || '#4BBF5E';
      return "border-color: ".concat(color, "; color: ").concat(color, "; background-color: ").concat(color, "22;");
    }
  }
};