import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "w-full table-auto"
};
var _hoisted_2 = ["span"];
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = ["colspan"];
var _hoisted_5 = {
  colspan: "5"
};
var _hoisted_6 = {
  class: "p-2"
};
var _hoisted_7 = ["title"];
var _hoisted_8 = ["title"];
var _hoisted_9 = {
  class: "gap-table-cell"
};
var _hoisted_10 = {
  class: "hover:underline"
};
var _hoisted_11 = {
  key: 1
};
var _hoisted_12 = {
  key: 0,
  class: "gap-table-cell"
};
var _hoisted_13 = {
  class: "gap-table-records-count"
};
var _hoisted_14 = {
  class: "gap-table-gap-cell"
};
var _hoisted_15 = {
  key: 0,
  class: "gap-table-totals-row"
};
var _hoisted_16 = {
  class: "gap-table-totals-cell"
};
var _hoisted_17 = {
  key: 0
};
var _hoisted_18 = {
  class: "gap-table-totals-cell"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("table", _hoisted_1, [_createElementVNode("colgroup", null, [_createElementVNode("col", {
    span: $props.columns.length
  }, null, 8 /* PROPS */, _hoisted_2), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gapTrafficLights, function (gap) {
    return _openBlock(), _createElementBlock("col", {
      key: gap.id,
      style: _normalizeStyle({
        backgroundColor: gap.color
      })
    }, null, 4 /* STYLE */);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("thead", null, [$props.subheadEnabled ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [_createElementVNode("th", {
    colspan: $props.columns.length - 1
  }, null, 8 /* PROPS */, _hoisted_4), _createElementVNode("th", _hoisted_5, [_createElementVNode("h5", _hoisted_6, _toDisplayString($data.translate.gapIndicator), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), _createElementVNode("tr", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, function (column, key) {
    return _openBlock(), _createElementBlock("th", {
      key: column,
      title: column,
      class: _normalizeClass(["dark-gray", key != $props.columns.length - 1 ? 'gap-table-cell' : 'gap-table-cell-records-count'])
    }, _toDisplayString(column), 11 /* TEXT, CLASS, PROPS */, _hoisted_7);
  }), 128 /* KEYED_FRAGMENT */)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.gapColumns, function (column) {
    return _openBlock(), _createElementBlock("th", {
      key: column,
      title: column,
      class: "gap-table-gap-cell"
    }, _toDisplayString(column), 9 /* TEXT, PROPS */, _hoisted_8);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.datasetMain, function (row, index) {
    var _row$node;
    return _openBlock(), _createElementBlock("tr", {
      key: row.node.activity.id || index
    }, [_createElementVNode("td", _hoisted_9, [(_row$node = row.node) !== null && _row$node !== void 0 && (_row$node = _row$node.activity) !== null && _row$node !== void 0 && _row$node.id ? (_openBlock(), _createBlock(_component_preview_modal_link, {
      key: 0,
      activity: row.node.activity,
      onUpdated: _cache[0] || (_cache[0] = function ($event) {
        return _ctx.$emit('updateFromPreview');
      }),
      onDismissModal: _cache[1] || (_cache[1] = function ($event) {
        return _ctx.$emit('dismissPreviewModal');
      })
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("span", _hoisted_10, _toDisplayString(row.node.title), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"])) : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(row.node.title), 1 /* TEXT */))]), $options.questionnaireColumnExist ? (_openBlock(), _createElementBlock("td", _hoisted_12, _toDisplayString(row.questionnaire || 'N/A'), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("td", _hoisted_13, _toDisplayString(row.total), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.gaps, function (gap) {
      return _openBlock(), _createElementBlock("td", _hoisted_14, _toDisplayString(gap), 1 /* TEXT */);
    }), 256 /* UNKEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */)), $props.totalsRowEnabled ? (_openBlock(), _createElementBlock("tr", _hoisted_15, [_createElementVNode("td", _hoisted_16, _toDisplayString($data.translate.totals), 1 /* TEXT */), $options.questionnaireColumnExist ? (_openBlock(), _createElementBlock("td", _hoisted_17)) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.totals, function (total) {
    return _openBlock(), _createElementBlock("td", _hoisted_18, _toDisplayString(total), 1 /* TEXT */);
  }), 256 /* UNKEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])])]);
}