import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { clickOutside } from '@/utils/directives/clickOutside';
import { focus } from '@/utils/directives/focus';
export default {
  name: 'InputFieldSelectDropdown',
  directives: {
    clickOutside: clickOutside,
    focus: focus
  },
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    options: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'Available options. Each option is an object {value: Boolean|Number|String, label: String}'
    },
    modelValue: {
      required: false,
      default: null,
      type: [String, Boolean, Number],
      note: 'v-model. Selected option'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Is disabled'
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    isErrorOccured: {
      type: Boolean,
      default: false
    },
    optionPlaceholder: {
      type: String,
      default: ''
    },
    tabindex: {
      type: [Number, String],
      required: false,
      default: 0,
      note: 'index for tabbing'
    },
    predefinedValue: {
      type: Boolean,
      default: false
    },
    withSearch: {
      required: false,
      default: false,
      type: Boolean,
      note: 'Add possibility to filter options'
    },
    placeholder: {
      type: String,
      default: '',
      note: 'Placeholder'
    }
  },
  emits: ['update:modelValue', 'active'],
  data: function data() {
    return {
      expanded: false,
      currentPointerIndex: -1,
      filterString: '',
      translate: {
        select: this.$trans('Select'),
        noResultsMatchingSearchCriteria: this.$trans('No results matching the search criteria'),
        listIsEmpty: this.$trans('List is empty')
      }
    };
  },
  computed: {
    filteredOptions: function filteredOptions() {
      var options = this.options.slice(0);
      var loweredFilterString = this.filterString.toLowerCase();
      if (this.filterString) {
        options = options.filter(function (o) {
          return o.label.toLowerCase().includes(loweredFilterString);
        });
      }
      return options;
    },
    selectedValue: function selectedValue() {
      var _this$options$find,
        _this = this;
      var value = (_this$options$find = this.options.find(function (item) {
        return item.value === _this.modelValue;
      })) === null || _this$options$find === void 0 ? void 0 : _this$options$find.label;
      if (!value) return this.optionPlaceholder || this.translate.select;
      return this.$truncate(value, 20);
    },
    focusedStyles: function focusedStyles() {
      if (this.isErrorOccured) {
        if (this.isFocused || this.expanded) return 'border-rm-text hover:border-rm-signal-red--50';
        return 'border-rm-grey hover:border-rm-signal-red--50';
      }
      return (this.isFocused || this.expanded) && !this.disabled ? 'border-rm-active-blue hover:bg-rm-active-blue--10' : 'border-rm-grey hover:border-rm-signal-grey-dark';
    },
    textFocusedStyles: function textFocusedStyles() {
      if (this.isErrorOccured) {
        if (this.isFocused || this.expanded) return 'text-rm-text';
        return 'text-rm-grey';
      }
      return (this.isFocused || this.expanded) && !this.disabled ? 'text-rm-active-blue' : this.modelValue ? 'text-rm-text' : 'text-rm-grey hover:text-rm-text';
    }
  },
  methods: {
    handleExpand: function handleExpand() {
      if (this.disabled) return;
      this.expanded = !this.expanded;
      this.currentPointerIndex = -1;
      this.$emit('active', this.expanded);
    },
    hide: function hide() {
      if (!this.expanded) return;
      this.filterString = '';
      this.expanded = false;
      this.currentPointerIndex = -1;
      this.$emit('active', this.expanded);
    },
    show: function show() {
      if (this.disabled) return;
      this.expanded = true;
      this.currentPointerIndex = -1;
      this.$emit('active', this.expanded);
    },
    addFromPointer: function addFromPointer() {
      if (this.currentPointerIndex === -1) return;
      var option = this.filteredOptions[this.currentPointerIndex];
      if (!option) return;
      this.$emit('update:modelValue', option.value);
      this.hide();
    },
    moveDown: function moveDown() {
      if (!this.expanded) return;
      if (this.filteredOptions.length - 1 <= this.currentPointerIndex) {
        this.currentPointerIndex = 0;
        return;
      }
      this.currentPointerIndex += 1;
    },
    moveUp: function moveUp() {
      if (!this.expanded) return;
      if (this.currentPointerIndex <= 0) {
        this.currentPointerIndex = this.filteredOptions.length - 1;
        return;
      }
      this.currentPointerIndex -= 1;
    },
    handleOptionSelect: function handleOptionSelect(option) {
      this.$emit('update:modelValue', option.value);
      this.hide();
    }
  }
};