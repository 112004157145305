import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 1,
  class: "mt-5"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 1,
  class: "ml-2 mb-5"
};
var _hoisted_4 = {
  class: "mb-5 flex"
};
var _hoisted_5 = {
  key: 0,
  class: "ml-8"
};
var _hoisted_6 = {
  class: "mb-5"
};
var _hoisted_7 = {
  class: "mb-5 flex items-center"
};
var _hoisted_8 = {
  key: 0
};
var _hoisted_9 = {
  class: "flex items-center"
};
var _hoisted_10 = {
  key: 2
};
var _hoisted_11 = {
  key: 3,
  class: "mr-2"
};
var _hoisted_12 = {
  key: 4
};
var _hoisted_13 = {
  key: 5,
  class: "mt-5"
};
var _hoisted_14 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_input_field = _resolveComponent("input-field");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        class: "ml-2",
        title: "SAML Admin"
      }), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [!$data.samlData.hasCertificate ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_button, {
        text: $data.translate.createNewCertificate,
        type: "save",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $options.createCertificate(false);
        })
      }, null, 8 /* PROPS */, ["text"])])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, [_createVNode(_component_risma_title, {
        type: "medium",
        class: "mb-1",
        title: $data.translate.certificateExpirationDate + ':'
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.samlData.certificateExpirationDate), 1 /* TEXT */)]), $data.samlData.hasFutureCertificate ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", null, [_createVNode(_component_risma_title, {
        type: "medium",
        class: "mb-1",
        title: $data.translate.futureCertificateExpirationDate + ':'
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.samlData.futureCertificateExpirationDate), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_title, {
        type: "medium",
        class: "mb-1",
        title: $data.translate.privateKeyCreated + ':'
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.samlData.hasPrivateKey ? $data.translate.yes : $data.translate.no), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_title, {
        type: "medium",
        class: "mr-3",
        title: $data.translate.appId + ':'
      }, null, 8 /* PROPS */, ["title"]), !$data.editAppId ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString($data.samlData.AppId), 1 /* TEXT */)) : _createCommentVNode("v-if", true), !$data.editAppId ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 1,
        text: $data.translate.edit,
        type: "save",
        class: "mr-4",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return $data.editAppId = true;
        })
      }, null, 8 /* PROPS */, ["text"])) : _createCommentVNode("v-if", true), $data.editAppId ? (_openBlock(), _createBlock(_component_input_field, {
        key: 2,
        modelValue: $data.appId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.appId = $event;
        }),
        placeholder: $data.translate.appId,
        class: "max-w-250px mr-4",
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])) : _createCommentVNode("v-if", true), $data.editAppId ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 3,
        text: $data.translate.save,
        type: "save",
        onClick: _cache[3] || (_cache[3] = function ($event) {
          return $options.saveAppId();
        })
      }, null, 8 /* PROPS */, ["text"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_title, {
        type: "medium",
        class: "mr-3",
        title: $data.translate.authenticationMode + ':'
      }, null, 8 /* PROPS */, ["title"]), _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $data.loginMethod = $event;
        }),
        onChange: _cache[5] || (_cache[5] = function () {
          return $options.updateLoginMethod && $options.updateLoginMethod.apply($options, arguments);
        })
      }, _cache[8] || (_cache[8] = [_createElementVNode("option", {
        value: "local"
      }, "Local", -1 /* HOISTED */), _createElementVNode("option", {
        value: "oidc"
      }, "OIDC", -1 /* HOISTED */), _createElementVNode("option", {
        value: "saml"
      }, "SAML", -1 /* HOISTED */)]), 544 /* NEED_HYDRATION, NEED_PATCH */), [[_vModelSelect, $data.loginMethod]])])])), $data.samlData.hasCertificate && (!$data.samlData.hasFutureCertificate || $data.samlData.futureCertificateIsExpired) ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_risma_button, {
        text: $data.translate.createFutureCertificate,
        type: "save",
        onClick: _cache[6] || (_cache[6] = function ($event) {
          return $options.createCertificate(true);
        })
      }, null, 8 /* PROPS */, ["text"])])) : _createCommentVNode("v-if", true), $data.samlData.hasFutureCertificate ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_risma_button, {
        text: $data.translate.deleteFutureCertificate,
        type: "danger",
        onClick: _cache[7] || (_cache[7] = function ($event) {
          return $options.deleteFutureCertificate();
        })
      }, null, 8 /* PROPS */, ["text"])])) : _createCommentVNode("v-if", true), $data.samlData.hasFutureCertificate && !$data.samlData.futureCertificateIsExpired ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createVNode(_component_risma_button, {
        text: $data.translate.rollOverToTheFutureCertificate,
        type: "save",
        onClick: $options.rolloverCertificate
      }, null, 8 /* PROPS */, ["text", "onClick"])])) : _createCommentVNode("v-if", true), $data.samlData.metaDataUrl ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("a", {
        class: "font-semibold text-blue-750 cursor pointer hover:underline",
        href: $data.samlData.metaDataUrl
      }, _toDisplayString($data.translate.linkToMetaData), 9 /* TEXT, PROPS */, _hoisted_14)])) : _createCommentVNode("v-if", true)]))];
    }),
    _: 1 /* STABLE */
  })]);
}