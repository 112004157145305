import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = {
  class: "w-1/2 mr-3"
};
var _hoisted_3 = {
  class: "flex-grow"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_options_list = _resolveComponent("options-list");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.attributeCategory,
    type: "small",
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
    type: "text",
    "pre-text": $props.category,
    invalid: !!($props.categoryError && !$props.category),
    "error-help-text": $props.categoryError,
    maxlength: "255",
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updateCategory', $event);
    })
  }, null, 8 /* PROPS */, ["pre-text", "invalid", "error-help-text"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.attributeValues,
    type: "small",
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_options_list, {
    "predefined-options": $data.localLabels,
    placeholder: $data.translate.enterDropdownOption,
    draggable: true,
    "confirm-on-delete": $options.confirmOnDelete,
    "show-empty-fields-error": !!$props.showErrors,
    "show-unique-fields-error": !!$props.showErrors,
    "save-button-click-counter": $props.saveButtonClickCounter,
    class: "mb-3",
    onOnOptionsChange: $options.onOptionsChange
  }, null, 8 /* PROPS */, ["predefined-options", "placeholder", "confirm-on-delete", "show-empty-fields-error", "show-unique-fields-error", "save-button-click-counter", "onOnOptionsChange"])])])]);
}