function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import HorizontalResizeBox from '@/components/Atoms/Containers/HorizontalResizeBox';
import HorizontalResizeFallback from '@/components/Atoms/Containers/HorizontalResizeFallback';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import MiniTreeBranchDraggable from '@/components/Molecules/MiniTree/MiniTreeBranchDraggable';
import MiniTreeFilters from '@/components/Molecules/MiniTree/MiniTreeFilters';
import MiniTreeLevelSelector from '@/components/Molecules/MiniTree/MiniTreeLevelSelector';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { moveItemInList, isDropPositionSame } from '@/components/Molecules/MiniTree/utils';
export default {
  name: 'MiniTree',
  token: '<mini-tree :title="" :branch=""><slot /></mini-tree>',
  description: 'The component displays the tree and contents of the selected node. it contains MiniTreeBranch, MiniTreeFilters, MiniTreeLevelSelector and HorizontalResizeBox',
  components: {
    MiniTreeBranchDraggable: MiniTreeBranchDraggable,
    MiniTreeFilters: MiniTreeFilters,
    HorizontalResizeBox: HorizontalResizeBox,
    MiniTreeLevelSelector: MiniTreeLevelSelector,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    FeatherIcon: FeatherIcon,
    HorizontalResizeFallback: HorizontalResizeFallback
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    branch: {
      required: true,
      type: Object,
      default: function _default() {
        return {
          children: []
        };
      }
    },
    searchEnabled: {
      required: false,
      type: Boolean,
      default: false,
      note: 'It has other colors and functionality around click,etc. with search params'
    },
    loadState: {
      required: false,
      type: Boolean,
      default: true,
      note: 'isLoaded state that replaces risma tree branches with loadingIndicatorLarge'
    },
    addNodeEnabled: {
      required: false,
      type: Boolean,
      default: true
    },
    localStorageUniqueKey: {
      required: false,
      type: String,
      default: '',
      note: 'additional prefix for the local storage key that is also used for mini-tree-filters'
    },
    filterOptionsData: {
      required: false,
      type: Object,
      default: function _default() {
        return {
          users: [],
          activityTypes: []
        };
      }
    },
    nodeEmits: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    showTreeFilters: {
      type: Boolean,
      required: false,
      default: true
    },
    addNewNodeLabel: {
      type: String,
      required: false,
      default: ''
    },
    hasOptionalFunctionality: {
      type: Boolean,
      default: true
    },
    hasHorizontalResizeBox: {
      type: Boolean,
      default: true
    },
    hasToggleMenuProp: {
      type: Boolean,
      default: false,
      note: 'if toggle menu logic should be covered with one field'
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['search', 'addNode', 'select', 'move'],
  setup: function setup(props) {
    var localStorageKey = "mini_tree_".concat(props.localStorageUniqueKey);
    return {
      localStorageKey: localStorageKey,
      selectedPath: getLocalStorageComputed(localStorageKey, [], true)
    };
  },
  data: function data() {
    return {
      elementWidth: 400,
      openedArray: [],
      openedArrayBeforeSelected: [],
      level: null,
      maxPossibleLevel: 1,
      translate: {
        newNode: this.$trans('New node'),
        listIsEmpty: this.$trans('List is empty'),
        contentType: this.$trans('Selected node'),
        filters: this.$trans('Filters'),
        areYouSureYouWouldLikeToMoveThisNode: this.$trans('Are you sure you would like to move this node?')
      },
      lastAppliedFilters: null,
      areFiltersOpened: false,
      leftColumnMaxHeight: 350,
      leftPosition: -10000,
      topPosition: -10000
    };
  },
  computed: {
    calculatedLeftColumnHeightProperties: function calculatedLeftColumnHeightProperties() {
      return "".concat(this.areFiltersOpened).concat(this.maxPossibleLevel).concat(this.lastAppliedFilters);
    },
    nodeIdSelected: function nodeIdSelected() {
      var _this$selectedPath;
      return (_this$selectedPath = this.selectedPath[this.selectedPath.length - 1]) === null || _this$selectedPath === void 0 ? void 0 : _this$selectedPath.id;
    }
  },
  watch: {
    calculatedLeftColumnHeightProperties: function calculatedLeftColumnHeightProperties() {
      this.updateLeftColumnMaxHeight();
    },
    branch: function branch(value) {
      this.maxPossibleLevel = 1;
      this.setMaxPossibleLevel(value);
      if (!this.isSelectedPathValid(this.selectedPath)) this.resetSelectedPath();
    },
    searchEnabled: function searchEnabled(value) {
      var _this = this;
      this.level = null;
      if (value) {
        this.openedArrayBeforeSelected = _toConsumableArray(this.openedArray);
        this.setLevel('all');
      } else {
        this.openedArray = _toConsumableArray(this.openedArrayBeforeSelected);
        this.openedArrayBeforeSelected = [];

        //check if selected element is shown, otherwise remove selected
        var ids = this.selectedPath.map(function (item) {
          return item.id;
        }).slice(0, -1);
        if (!ids.every(function (id) {
          return _this.openedArray.includes(id);
        })) {
          this.resetSelectedPath();
        }
      }
    }
  },
  mounted: function mounted() {
    /* check that data in mini-tree wasn't changed since last load */
    if (this.selectedPath) {
      this.openedArray = this.selectedPath.map(function (item) {
        return item.id;
      });
      this.$emit('select', this.selectedPath);
    }
    this.setMaxPossibleLevel(this.branch);
  },
  methods: {
    updateLeftColumnMaxHeight: function updateLeftColumnMaxHeight() {
      var _this2 = this;
      var value = 140;
      this.$nextTick(function () {
        var element = _this2.$refs.filters;
        if (element) {
          value = element.clientHeight + 225;
        }
        _this2.leftColumnMaxHeight = value;
      });
    },
    isSelectedPathValid: function isSelectedPathValid(path) {
      var branch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.branch;
      if (!path) return true;
      var ids = path.map(function (item) {
        return item.id;
      });
      var children = branch === null || branch === void 0 ? void 0 : branch.children;
      var state = true;
      ids.forEach(function (id) {
        var _children;
        if (!children) return;
        var child = (_children = children) === null || _children === void 0 ? void 0 : _children.find(function (c) {
          return c.id === id;
        });
        if (!child) state = false;
        children = child === null || child === void 0 ? void 0 : child.children;
      });
      return state;
    },
    searchData: function searchData(data, firstEnter) {
      if (!firstEnter) this.resetSelectedPath();
      this.lastAppliedFilters = data;
      this.$emit('search', data);
    },
    setLevel: function setLevel(value) {
      this.level = value;
      this.openedArray = [];
      this.updateOpenedArrayFromLevelSelector(this.branch);
    },
    updateOpenedArrayFromLevelSelector: function updateOpenedArrayFromLevelSelector(data) {
      var _data$children,
        _this3 = this;
      var level = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      if (this.level !== 'all' && this.level < level) return;
      if (data.id) this.openedArray.push(data.id);
      if (!(data !== null && data !== void 0 && (_data$children = data.children) !== null && _data$children !== void 0 && _data$children.length)) return;
      level++;
      data.children.forEach(function (item) {
        _this3.updateOpenedArrayFromLevelSelector(item, level);
      });
    },
    setMaxPossibleLevel: function setMaxPossibleLevel(data) {
      var _data$children2,
        _this4 = this;
      var level = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      if (!(data !== null && data !== void 0 && (_data$children2 = data.children) !== null && _data$children2 !== void 0 && _data$children2.length)) return;
      if (this.maxPossibleLevel < level) this.maxPossibleLevel = level;
      level += 1;
      data.children.forEach(function (item) {
        _this4.setMaxPossibleLevel(item, level);
      });
    },
    setSelectedPath: function setSelectedPath(data) {
      var id = data.slice(-1)[0].id;
      this.selectedPath = data;
      if (!this.openedArray.includes(id)) {
        this.openedArray.push(id);
      }
      this.level = null;
      this.$emit('select', this.selectedPath);
    },
    resetSelectedPath: function resetSelectedPath() {
      this.selectedPath = [];
      this.$emit('select', this.selectedPath);
    },
    setOpened: function setOpened(item) {
      var index = this.openedArray.findIndex(function (id) {
        return id === item.id;
      });
      if (index === -1) {
        this.openedArray.push(item.id);
      } else {
        this.openedArray.splice(index, 1);
        this.cleanOpenedArray(item);
      }
      this.level = null;
    },
    cleanOpenedArray: function cleanOpenedArray(item) {
      var _item$children,
        _this5 = this;
      item === null || item === void 0 || (_item$children = item.children) === null || _item$children === void 0 || _item$children.forEach(function (node) {
        var _node$children;
        var index = _this5.openedArray.findIndex(function (id) {
          return id === node.id;
        });
        if (index !== -1) {
          _this5.openedArray.splice(index, 1);
        }
        if (node !== null && node !== void 0 && (_node$children = node.children) !== null && _node$children !== void 0 && _node$children.length) _this5.cleanOpenedArray(node);
      });
    },
    hideDropHint: function hideDropHint() {
      this.topPosition = -10000;
      this.leftPosition = -10000;
    },
    showDropHint: function showDropHint(_ref) {
      var top = _ref.top,
        left = _ref.left;
      var DROP_HINT_ICON_SIZE = 10;
      this.topPosition = top - DROP_HINT_ICON_SIZE;
      this.leftPosition = left - DROP_HINT_ICON_SIZE;
    },
    handleDragEnd: function handleDragEnd(_ref2) {
      var _this6 = this;
      var fromPath = _ref2.fromPath,
        toPath = _ref2.toPath,
        element = _ref2.element,
        type = _ref2.type,
        draggedContext = _ref2.draggedContext,
        relatedContext = _ref2.relatedContext;
      var dropPositionSame = isDropPositionSame(fromPath, toPath, type, relatedContext.element.children.length);
      if (!dropPositionSame) {
        this.$confirm('', this.translate.areYouSureYouWouldLikeToMoveThisNode, function (res) {
          if (res) {
            _this6.branch.children = moveItemInList(_this6.branch.children, fromPath, toPath, type, element);
            _this6.handleMove(type, draggedContext, relatedContext);
          }
        });
      }
      this.hideDropHint();
      this.resetSelectedPath();
    },
    handleMove: function handleMove(position, draggedContext, relatedContext) {
      var params;
      var target = relatedContext.element;
      switch (position) {
        case 'inside':
          params = target.children.length ? {
            insertAfter: target.children[target.children.length - 1].id
          } : {
            appendTo: target.id
          };
          break;
        case 'after':
          params = {
            insertAfter: target.id
          };
          break;
        case 'before':
          params = {
            insertBefore: target.id
          };
          break;
      }
      this.$emit('move', {
        child: draggedContext.element.id,
        options: params
      });
    }
  }
};