function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { getNodesFlat, getInitiatives as _getInitiatives, getControls as _getControls, getRisks, getInformationAssetsSystems, getInformationAssetsProcessors as _getInformationAssetsProcessors, getInformationAssetsControllers as _getInformationAssetsControllers } from '@/api/risma/data';
import { getAll as _getAllComplianceProjects } from '@/api/compliance/projects';
import { getAllQuestionnaires } from '@/api/compliance/questionnaire';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import { getAllGapSchemasByGapSchemaType } from '@/api/compliance/gapschema';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { ExtendedViewCustomFieldsMixin } from '@/components/Pages/InformationAssets/ExtendedViewCustomFieldsMixin';
import { generateFiltersQueryString } from '@/api/httpUtils';
import { getProcessTreeNodesForSelectBoxFromDataApi } from '@/utils/format/ProcessTree';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { mapToCommaListWithLinks, mapToCommaList } from '@/utils/format/mapTo';
import * as utils from '@/utils/Utils';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { vatStatusLabelByValue } from '@/constants/companies/vatStatuses';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { capitalize } from '@/utils';
export default {
  name: 'InformationAssetsExtendedView',
  components: {
    RismaDatatable: RismaDatatable,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    TrafficLight: TrafficLight,
    PreviewModalLink: PreviewModalLink,
    RismaToggle: RismaToggle
  },
  mixins: [RaciTitleMixin, RismaDatatableDefaultMixin, ExtendedViewCustomFieldsMixin],
  props: {
    typeId: {
      type: [Number, String],
      required: false,
      default: null,
      note: 'Id of information asset type'
    },
    informationAssets: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of information assets'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of all users'
    },
    confirmExportMessage: {
      required: false,
      type: String,
      default: '',
      note: 'Confirmation popup message to display before exporting to excel or print'
    },
    confirmPrintMessage: {
      required: false,
      type: String,
      default: '',
      note: 'Confirmation popup message to display before printing'
    }
  },
  emits: ['refreshData', 'update:showChildren'],
  data: function data() {
    return {
      dataset: [],
      columns: {},
      isLoaded: false,
      linkedNodes: [],
      initiatives: [],
      controls: [],
      processes: [],
      questionnaires: [],
      systems: [],
      processors: [],
      controllers: [],
      companies: [],
      risks: [],
      isLinkUpdated: false,
      updatedFromPreviewModal: false,
      showChildren: false,
      currentIndex: 0,
      hasBackendSorting: true,
      //RismaDatatableDefaultMixin backend sort
      firstInit: true,
      //RismaDatatableDefaultMixin backend sort
      disabledFilterByColumnsDefault: ['linkedNodes', 'systems', 'controls', 'initiatives', 'attachments', 'children'],
      slotLinkFields: ['systems', 'initiatives', 'controls', 'processes', 'linkedNodes', 'risks', 'companies', 'processors', 'controllers'],
      complianceProjects: [],
      usePagination: false,
      gapSchemas: [],
      translate: getTranslate['InformationAssetsExtendedView']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useActivityTypesStore, ['getActivityTypeById'])), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), mapState(useTagsStore, ['tags'])), {}, {
    columnsMeta: function columnsMeta() {
      var targets = Object.keys(this.columns);
      return [{
        targets: targets,
        width: '170px'
      }, {
        targets: ['name', 'description', 'initiatives', 'controls', 'risks'],
        width: '340px'
      }, {
        targets: ['linkedNodes'],
        width: '500px'
      }];
    },
    localStorageKey: function localStorageKey() {
      return "information_assets_extended_view_".concat(this.typeId);
    },
    childrenToggleText: function childrenToggleText() {
      return this.translate.showUnderlying + ' ' + this.$trans(capitalize(this.typeId)).toLowerCase();
    },
    hasChildrenFunctionality: function hasChildrenFunctionality() {
      return this.typeId === AssetsTypes.PROCESSORS || this.typeId === AssetsTypes.SYSTEMS;
    },
    slotLinkFieldsExtended: function slotLinkFieldsExtended() {
      return [].concat(_toConsumableArray(this.slotLinkFields), _toConsumableArray(this.getComplianceProjectsListColumns()));
    },
    disabledFilterByColumns: function disabledFilterByColumns() {
      var list = this.disabledFilterByColumnsDefault.slice();
      Object.keys(this.customFieldsColumns).forEach(function (item) {
        list.push(item);
      });
      list.push.apply(list, _toConsumableArray(this.getComplianceProjectsListColumns()));
      return list;
    },
    systemsEnabled: function systemsEnabled() {
      return this.getSettingValue('feature.enable_systems') == undefined || this.getSettingValue('feature.enable_systems');
    },
    showRiskAssessment: function showRiskAssessment() {
      return !this.getSettingValue('feature.hide_ia_risk_assessments');
    },
    isDataProcessorManagementEnabled: function isDataProcessorManagementEnabled() {
      return !!this.getSettingValue('feature.data_processor_management');
    },
    commonColumnsForAll: function commonColumnsForAll() {
      return {
        activityType: this.translate.activityType,
        solutionId: this.translate.frameworkId,
        questionnaireId: this.translate.questionnaire,
        completionDegree: this.translate.completionDegreeIn,
        questionnaireScoring: this.translate.questionnaireScoring,
        assessmentScoring: this.translate.assessmentScoring,
        tags: this.translate.tags,
        responsible: this.responsibleTitle(true),
        accountable: this.accountableTitle(true),
        consulted: this.consultedTitle(true),
        informed: this.informedTitle(true),
        organisations: this.translate.organisations
      };
    },
    childrenCountOnTheList: function childrenCountOnTheList() {
      if (!this.showChildren) return 0;
      var items = [];
      switch (this.typeId) {
        case AssetsTypes.PROCESSORS:
          items = this.processors.filter(function (_ref) {
            var parents = _ref.parents;
            return parents === null || parents === void 0 ? void 0 : parents.length;
          });
          break;
        case AssetsTypes.SYSTEMS:
          items = this.systems.filter(function (_ref2) {
            var parentId = _ref2.parentId;
            return parentId;
          });
          break;
      }
      return items.length;
    }
  }),
  watch: {
    informationAssets: function informationAssets() {
      this.getExtendedData();
    }
  },
  mounted: function mounted() {
    window.$router = this.$router; //jquery access to router, didn`t find other way
    this.getData(this.typeId);
  },
  methods: {
    getData: function getData() {
      var _this = this;
      return Promise.all([this.getSystems(), this.getInformationAssetsProcessors(), this.getInformationAssetsControllers(), this.getNodes(), this.getInitiatives(), this.getControls(), this.getRisk(), this.getProcessLibrary(), this.getAllComplianceProjects(), this.getGapProcSchemas(), this.getQuestionnaires()]).then(function () {
        _this.getExtendedData();
      });
    },
    getAllComplianceProjects: function getAllComplianceProjects() {
      var _this2 = this;
      return _getAllComplianceProjects().then(function (_ref3) {
        var list = _ref3.list;
        return _this2.complianceProjects = list;
      });
    },
    getSystems: function getSystems() {
      var _this3 = this;
      return getInformationAssetsSystems().then(function (_ref4) {
        var list = _ref4.list;
        return _this3.systems = list;
      });
    },
    getInformationAssetsProcessors: function getInformationAssetsProcessors() {
      var _this4 = this;
      return _getInformationAssetsProcessors().then(function (_ref5) {
        var list = _ref5.list;
        return _this4.processors = list;
      });
    },
    getInformationAssetsControllers: function getInformationAssetsControllers() {
      var _this5 = this;
      return _getInformationAssetsControllers().then(function (_ref6) {
        var list = _ref6.list;
        return _this5.controllers = list;
      });
    },
    getNodes: function getNodes() {
      var _this6 = this;
      return getNodesFlat().then(function (_ref7) {
        var list = _ref7.list;
        return _this6.linkedNodes = list;
      });
    },
    getInitiatives: function getInitiatives() {
      var _this7 = this;
      return _getInitiatives().then(function (_ref8) {
        var list = _ref8.list;
        return _this7.initiatives = list;
      });
    },
    getControls: function getControls() {
      var _this8 = this;
      return _getControls().then(function (_ref9) {
        var list = _ref9.list;
        return _this8.controls = list;
      });
    },
    getRisk: function getRisk() {
      var _this9 = this;
      return getRisks().then(function (_ref10) {
        var list = _ref10.list;
        return _this9.risks = list;
      });
    },
    getProcessLibrary: function getProcessLibrary() {
      var _this10 = this;
      return getProcessTreeNodesForSelectBoxFromDataApi().then(function (response) {
        return _this10.processes = response;
      });
    },
    getGapProcSchemas: function getGapProcSchemas() {
      var _this11 = this;
      return getAllGapSchemasByGapSchemaType(ProjectTypes.DATAPROCESSOR).then(function (_ref11) {
        var list = _ref11.list;
        return _this11.gapSchemas = list;
      });
    },
    getQuestionnaires: function getQuestionnaires() {
      var _this12 = this;
      return getAllQuestionnaires().then(function (_ref12) {
        var list = _ref12.list;
        return _this12.questionnaires = list;
      });
    },
    getCompanies: function getCompanies() {
      var _this13 = this;
      var filteredIds = this.informationAssets.map(function (ia) {
        return ia.companyId;
      }).filter(Boolean);
      if (!filteredIds.length) {
        this.companies = [];
        return Promise.resolve();
      }
      var query = generateFiltersQueryString({
        id: filteredIds
      });
      return _getCompanies(query).then(function (_ref13) {
        var list = _ref13.list;
        return _this13.companies = list;
      });
    },
    getExtendedData: function getExtendedData() {
      var _this14 = this;
      this.isLoaded = false;
      return this.getCompanies().then(function () {
        _this14.columns = _this14.getColumns();
        _this14.dataset = _this14.getDataset(_this14.informationAssets, _this14.typeId);
        _this14.enhanceDataset(_this14.dataset, _this14.columns);
        _this14.$emit('update:showChildren', _this14.childrenCountOnTheList);
        _this14.isLoaded = true;
      });
    },
    getColumns: function getColumns() {
      switch (this.typeId) {
        case AssetsTypes.CONTROLLERS:
          return this.getControllersColumns();
        case AssetsTypes.PROCESSORS:
          return this.getProcessorsColumns();
        case AssetsTypes.SYSTEMS:
          return this.getSystemsColumns();
      }
      return this.getActivityTypesColumns();
    },
    getCommonColumns: function getCommonColumns() {
      return _objectSpread(_objectSpread({
        uniqueId: 'ID',
        name: this.translate.name,
        parent: this.translate.parent,
        address: this.translate.address,
        countryCodeCountry: this.translate.countryCodeCountry,
        phoneNumber: this.translate.phoneNumber,
        vatNo: this.translate.vatNumber,
        regNo: this.translate.regNumber,
        email: this.translate.email
      }, this.commonColumnsForAll), {}, {
        linkedNodes: this.translate.nodesLinkedThroughQuestionnaire,
        description: this.translate.description
      });
    },
    getRiskAssessmentsColumns: function getRiskAssessmentsColumns() {
      return {
        before_likelihood: "".concat(this.translate.likelihood, " (").concat(this.translate.before, ")"),
        before_impact: "".concat(this.translate.impact, " (").concat(this.translate.before, ")"),
        before_rpi: "".concat(this.translate.rpi, " (").concat(this.translate.before, ")"),
        after_likelihood: "".concat(this.translate.likelihood, " (").concat(this.translate.after, ")"),
        after_impact: "".concat(this.translate.impact, " (").concat(this.translate.after, ")"),
        after_rpi: "".concat(this.translate.rpi, " (").concat(this.translate.after, ")")
      };
    },
    getControllersColumns: function getControllersColumns() {
      var riskAssessmentsColumns = this.showRiskAssessment ? this.getRiskAssessmentsColumns() : {};
      var commonColumns = this.getCommonColumns();
      delete commonColumns.parent;
      return _objectSpread(_objectSpread(_objectSpread({
        trafficLight: this.translate.trafficLight
      }, commonColumns), riskAssessmentsColumns), this.customFieldsColumns);
    },
    getProcessorsColumns: function getProcessorsColumns() {
      var linkedSystemsColumn = this.systemsEnabled ? {
        systems: this.translate.linkedSystems
      } : {};
      var riskAssessmentsColumns = this.showRiskAssessment ? this.getRiskAssessmentsColumns() : {};
      var columns = _objectSpread(_objectSpread(_objectSpread(_objectSpread({
        trafficLight: this.translate.trafficLight
      }, this.getCommonColumns()), riskAssessmentsColumns), {}, {
        questionnaireId: this.translate.questionnaire,
        processes: this.translate.processLibrary
      }, linkedSystemsColumn), {}, {
        gapSchema: this.translate.gapSchema,
        controls: this.translate.linkedControls,
        initiatives: this.translate.linkedActions,
        children: this.translate.underlyingProcessors,
        attachments: this.translate.attachments,
        companyName: this.translate.companyName,
        vatStatus: this.translate.vatStatus,
        zipCode: this.translate.zipcode,
        city: this.translate.city,
        contactName: this.translate.contactName
      }, this.customFieldsColumns);
      if (!this.showChildren) delete columns.parent;
      return columns;
    },
    getSystemsColumns: function getSystemsColumns() {
      var riskAssessmentsColumns = this.showRiskAssessment ? this.getRiskAssessmentsColumns() : {};
      var list = _objectSpread(_objectSpread(_objectSpread(_objectSpread({
        trafficLight: this.translate.trafficLight,
        uniqueId: 'ID',
        name: this.translate.name,
        parent: this.translate.parent,
        children: this.translate.underlyingSystems,
        location: this.translate.location,
        description: this.translate.description,
        purpose: this.translate.purpose,
        linkedNodes: this.translate.nodesLinkedThroughQuestionnaire
      }, riskAssessmentsColumns), {}, {
        email: this.translate.email,
        processors: this.translate.linkedProcessors,
        processes: this.translate.processLibrary,
        risks: this.translate.risks,
        initiatives: this.translate.linkedActions,
        controls: this.translate.linkedControls
      }, this.commonColumnsForAll), this.getComplianceProjectColumns()), {}, {
        attachments: this.translate.attachments
      }, this.customFieldsColumns);
      if (!this.showChildren) delete list.parent;
      return list;
    },
    getComplianceProjectColumns: function getComplianceProjectColumns() {
      var _this15 = this;
      var result = {};
      this.complianceProjects.forEach(function (project) {
        var label = _this15.getCompliancePropLabel(project.id);
        result[label] = "".concat(project.title, " links");
      });
      return result;
    },
    getActivityTypesColumns: function getActivityTypesColumns() {
      return _objectSpread(_objectSpread({
        trafficLight: this.translate.trafficLight,
        name: this.translate.name,
        description: this.translate.description,
        address: this.translate.address,
        postcode: this.translate.zipcode,
        city: this.translate.city,
        email: this.translate.email
      }, this.customFieldsColumns), this.commonColumnsForAll);
    },
    getDataset: function getDataset(informationAssets, type) {
      var _this16 = this;
      var dataset = [];
      informationAssets.forEach(function (ia) {
        var _ia$childrenObjects;
        var item = _this16.getInformationAssetData(type, ia);
        if (item) dataset.push(item);
        if (_this16.showChildren && (_ia$childrenObjects = ia.childrenObjects) !== null && _ia$childrenObjects !== void 0 && _ia$childrenObjects.length) {
          var list = [];
          ia.childrenObjects.forEach(function (element) {
            var data = _this16.getInformationAssetData(type, element, ia);
            if (data) {
              list.push(data);
            }
          });
          if (list.length) dataset.push.apply(dataset, list);
        }
      });
      return dataset;
    },
    getInformationAssetData: function getInformationAssetData(objType, element, parent) {
      switch (objType) {
        case AssetsTypes.CONTROLLERS:
          return this.getControllersData(element);
        case AssetsTypes.PROCESSORS:
          if (element.parents && element.parents.length > 0 && !this.showChildren) {
            return null;
          }
          return this.getProcessorsData(element, parent);
        case AssetsTypes.SYSTEMS:
          return this.getSystemsData(element, parent);
      }
      return this.getActivityTypesData(element);
    },
    getCommonData: function getCommonData(element) {
      var _element$activityType, _this$getActivityType;
      var company = this.companies.find(function (company) {
        return company.id === element.companyId;
      }) || {};
      var data = {
        uniqueId: {
          field: element.uniqueId
        },
        name: this.getName(element),
        parent: {},
        address: {
          field: company.address || element.address
        },
        countryCodeCountry: {
          field: company.countryCode || element.countryCode
        },
        phoneNumber: {
          field: company.phone || element.phoneNumber
        },
        vatNo: {
          field: company.vatNo || element.vatNo
        },
        regNo: {
          field: element.regNo
        },
        email: {
          field: company.email || element.email
        }
      };
      if (this.typeId === AssetsTypes.PROCESSORS) {
        data.companyName = {
          field: company.name || ''
        };
        data.vatStatus = {
          field: vatStatusLabelByValue[company.vatStatus] || ''
        };
        data.zipCode = {
          field: company.zipCode || ''
        };
        data.city = {
          field: company.city || ''
        };
        data.contactName = {
          field: element.contactName || ''
        };
      }
      return _objectSpread(_objectSpread({}, data), {}, {
        activityType: {
          field: ((_element$activityType = element.activityType) === null || _element$activityType === void 0 ? void 0 : _element$activityType.label) || ((_this$getActivityType = this.getActivityTypeById(element.activityTypeId)) === null || _this$getActivityType === void 0 ? void 0 : _this$getActivityType.label) || ''
        },
        linkedNodes: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(element.complianceNodes || [], this.linkedNodes)),
        description: {
          field: element.description || ''
        }
      });
    },
    getControllersData: function getControllersData(element) {
      var riskAssessmentsData = this.showRiskAssessment ? this.extractRiskAssessments(element.riskAssessments) : {};
      var commonData = this.getCommonData(element);
      delete commonData.parent;
      var trafficLight = DatatableRenderer.prepareTrafficLightField(element.trafficLight);
      return _objectSpread(_objectSpread(_objectSpread(_objectSpread({
        trafficLight: {
          field: trafficLight,
          fieldText: (trafficLight === null || trafficLight === void 0 ? void 0 : trafficLight.label) || ''
        }
      }, commonData), {}, {
        tags: {
          field: utils.findPropsByIds(element.tagIds, this.tags, 'tag')
        }
      }, riskAssessmentsData), this.getCustomFieldsData(element)), this.getCommonFieldsData(element));
    },
    getComplianceProjectsListColumns: function getComplianceProjectsListColumns() {
      var list = [];
      if (this.typeId === AssetsTypes.SYSTEMS) {
        var complianceLinkColumns = this.getComplianceProjectColumns();
        Object.keys(complianceLinkColumns).forEach(function (column) {
          list.push(column);
        });
      }
      return list;
    },
    getProcessorsData: function getProcessorsData(element) {
      var _this$gapSchemas$find;
      var parent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var riskAssessmentsData = this.showRiskAssessment ? this.extractRiskAssessments(element.riskAssessments) : {};
      var gapSchemaData = this.isDataProcessorManagementEnabled ? {
        gapSchema: {
          field: ((_this$gapSchemas$find = this.gapSchemas.find(function (schema) {
            return schema.id === element.gapSchemaId;
          })) === null || _this$gapSchemas$find === void 0 ? void 0 : _this$gapSchemas$find.title) || ''
        }
      } : {};
      var systemsData = this.systemsEnabled ? {
        systems: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(element.systems, this.systems))
      } : {};
      var trafficLight = DatatableRenderer.prepareTrafficLightField(element.trafficLight);
      var data = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
        trafficLight: {
          field: trafficLight,
          fieldText: (trafficLight === null || trafficLight === void 0 ? void 0 : trafficLight.label) || ''
        }
      }, this.getCommonData(element)), {}, {
        name: {
          fieldText: element.name,
          field: {
            activity: getFieldsFromObjectForPreview(element),
            title: element.name
          }
        },
        parent: {
          fieldText: parent.name,
          field: {
            activity: getFieldsFromObjectForPreview(parent),
            title: parent.name
          }
        }
      }, gapSchemaData), riskAssessmentsData), {}, {
        processes: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(element.links.compliance_process_tree_node, this.processes)),
        tags: {
          field: utils.findPropsByIds(element.tagIds, this.tags, 'tag')
        }
      }, systemsData), {}, {
        controls: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(element.links.control, this.controls, 'path')),
        initiatives: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(element.links.execution, this.initiatives, 'path')),
        children: this.getChildrenIAPreviewLinks(element.childrenObjects, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR),
        attachments: {
          field: mapToCommaListWithLinks(element.attachments, '', ['title', 'filename'], 'url', false)
        }
      }, this.getCustomFieldsData(element)), this.getCommonFieldsData(element));
      if (!this.showChildren) delete data.parent;
      return data;
    },
    getSystemsData: function getSystemsData(element) {
      var _element$links, _element$links2, _element$activityType2, _this$getActivityType2;
      var parent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var riskAssessmentsData = this.showRiskAssessment ? this.extractRiskAssessments(element.riskAssessments) : {};
      var trafficLight = DatatableRenderer.prepareTrafficLightField(element.trafficLight);
      var data = _objectSpread(_objectSpread(_objectSpread(_objectSpread({
        trafficLight: {
          field: trafficLight,
          fieldText: (trafficLight === null || trafficLight === void 0 ? void 0 : trafficLight.label) || ''
        },
        uniqueId: {
          field: element.uniqueId
        },
        name: {
          fieldText: element.name,
          field: {
            activity: getFieldsFromObjectForPreview(element),
            title: element.name
          }
        },
        parent: {
          fieldText: parent.name,
          field: {
            activity: getFieldsFromObjectForPreview(parent || {}),
            title: parent.name
          }
        },
        children: this.getChildrenIAPreviewLinks(element.childrenObjects, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM),
        location: {
          field: element.location
        },
        description: {
          field: element.description
        },
        purpose: {
          field: element.purpose
        },
        linkedNodes: {
          field: element.complianceNodes.map(function (item) {
            return {
              url: item.url,
              title: item.title,
              activity: item
            };
          }),
          fieldText: element.complianceNodes.map(function (item) {
            return item.title;
          }).join(', ')
        }
      }, riskAssessmentsData), {}, {
        email: {
          field: element.email
        },
        tags: {
          field: utils.findPropsByIds(element.tagIds, this.tags, 'tag')
        },
        processors: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(element.processors, this.processors)),
        processes: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(element.processes || [], this.processes)),
        risks: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links = element.links) === null || _element$links === void 0 ? void 0 : _element$links.risk, this.risks)),
        initiatives: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(element.initiatives || [], this.initiatives, 'path')),
        controls: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(element.controls || [], this.controls, 'path'))
      }, this.getComplianceProjectsData(element.links && ((_element$links2 = element.links) === null || _element$links2 === void 0 ? void 0 : _element$links2.compliance) || [])), {}, {
        attachments: {
          field: mapToCommaListWithLinks(element.attachments, '', 'title', 'url', false)
        },
        activityType: {
          field: ((_element$activityType2 = element.activityType) === null || _element$activityType2 === void 0 ? void 0 : _element$activityType2.label) || ((_this$getActivityType2 = this.getActivityTypeById(element.activityTypeId)) === null || _this$getActivityType2 === void 0 ? void 0 : _this$getActivityType2.label) || ''
        }
      }, this.getCustomFieldsData(element)), this.getCommonFieldsData(element));
      if (!this.showChildren) delete data.parent;
      return data;
    },
    getComplianceProjectsData: function getComplianceProjectsData(complianceIds) {
      var _this17 = this;
      var filteredItems = this.filterItems(this.linkedNodes, complianceIds);
      var result = {};
      filteredItems.forEach(function (item) {
        var label = _this17.getCompliancePropLabel(item.compliance_projects_id);
        Array.isArray(result[label]) ? result[label].push(item) : result[label] = [item];
      });
      this.complianceProjects.forEach(function (project) {
        var label = _this17.getCompliancePropLabel(project.id);
        if (!result[label]) {
          result[label] = [];
        }
      });
      Object.keys(result).forEach(function (key) {
        result[key] = {
          field: result[key].map(function (node) {
            return {
              activity: node,
              title: node.title
            };
          }),
          fieldText: result[key].map(function (node) {
            return node.title;
          }).join(', ')
        };
      });
      return result;
    },
    getActivityTypesData: function getActivityTypesData(element) {
      var company = this.companies.find(function (company) {
        return company.id === element.companyId;
      }) || {};
      var trafficLight = DatatableRenderer.prepareTrafficLightField(element.trafficLight);
      return _objectSpread(_objectSpread({
        trafficLight: {
          field: trafficLight,
          fieldText: (trafficLight === null || trafficLight === void 0 ? void 0 : trafficLight.label) || ''
        },
        name: this.getName(element),
        description: {
          field: element.description
        },
        tags: {
          field: utils.findPropsByIds(element.tagIds, this.tags, 'tag')
        },
        address: {
          field: company.address || element.address
        },
        postcode: {
          field: company.zipCode || element.postcode
        },
        city: {
          field: company.city || element.city
        },
        email: {
          field: company.email || element.email
        }
      }, this.getCommonFieldsData(element)), this.getCustomFieldsData(element));
    },
    getCommonFieldsData: function getCommonFieldsData(element) {
      var _element$questionnair, _element$questionnair2, _element$questionnair3, _element$questionnair4;
      var data = {
        responsible: {
          field: mapToCommaList(this.filterItems(this.users, element.responsibleUserIds || []), 'display_name')
        },
        accountable: {
          field: mapToCommaList(this.filterItems(this.users, element.accountableUserIds || []), 'display_name')
        },
        consulted: {
          field: mapToCommaList(this.filterItems(this.users, element.consultedUserIds || []), 'display_name')
        },
        informed: {
          field: mapToCommaList(this.filterItems(this.users, element.informedUserIds || []), 'display_name')
        },
        organisations: {
          field: mapToCommaList(this.filterItems(this.organisations, element.organisationIds), 'visible_name')
        },
        completionDegree: {
          field: this.getCompletionDegree((_element$questionnair = element.questionnaires) === null || _element$questionnair === void 0 ? void 0 : _element$questionnair[0])
        },
        questionnaireId: {
          field: utils.findPropsByIds((_element$questionnair2 = element.questionnaires) === null || _element$questionnair2 === void 0 ? void 0 : _element$questionnair2.map(function (item) {
            return item.questionnaireId;
          }), this.questionnaires, 'title')
        },
        questionnaireScoring: {
          field: this.getQuestionnaireScore((_element$questionnair3 = element.questionnaires) === null || _element$questionnair3 === void 0 ? void 0 : _element$questionnair3[0])
        },
        assessmentScoring: {
          field: this.getAssessmentScoring((_element$questionnair4 = element.questionnaires) === null || _element$questionnair4 === void 0 ? void 0 : _element$questionnair4[0])
        }
      };
      if (this.getSettingValue('feature.performance_report_enabled') > 0) {
        data.solutionId = {
          field: mapToCommaList(this.filterItems(this.frameworks, element.solutionIds || []), 'displayName')
        };
      }
      return data;
    },
    extractRiskAssessments: function extractRiskAssessments(riskArray) {
      var risk = {
        before_likelihood: {
          field: 0
        },
        before_impact: {
          field: 0
        },
        before_rpi: {
          field: 0
        },
        after_likelihood: {
          field: 0
        },
        after_impact: {
          field: 0
        },
        after_rpi: {
          field: 0
        }
      };
      if (riskArray && riskArray.length > 0) {
        riskArray.forEach(function (riskElement) {
          if (riskElement.riskIndex == 0) {
            risk.before_likelihood = {
              field: riskElement.likelihood
            };
            risk.before_impact = {
              field: riskElement.impact
            };
            risk.before_rpi = {
              field: riskElement.rpi
            };
          }
          if (riskElement.riskIndex == 1) {
            risk.after_likelihood = {
              field: riskElement.likelihood
            };
            risk.after_impact = {
              field: riskElement.impact
            };
            risk.after_rpi = {
              field: riskElement.rpi
            };
          }
        });
      }
      return risk;
    },
    updateLinkedFromPreview: function updateLinkedFromPreview(data, field) {
      // in systems compliance has nodes sliced, each has name like compliance-1, compliance-2, etc.
      if (this.slotLinkFields.indexOf(field) === -1) field = 'linkedNodes';
      var index = this[field].findIndex(function (item) {
        return item.id === data.id;
      });
      if (index === -1) return;
      this[field].splice(index, 1, data);
      this.isLinkUpdated = true;
    },
    onLinkModalDismiss: function onLinkModalDismiss() {
      if (!this.isLinkUpdated) return;
      this.dataset = this.getDataset(this.informationAssets, this.typeId);
      this.enhanceDataset(this.dataset, this.columns);
      this.isLinkUpdated = false;
    },
    getName: function getName(element) {
      return {
        fieldText: element.name || element.title,
        field: {
          activity: getFieldsFromObjectForPreview(element),
          title: element.name || element.title
        }
      };
    },
    filterItems: function filterItems() {
      var itemsToFilter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var items = arguments.length > 1 ? arguments[1] : undefined;
      if (!items || !Array.isArray(itemsToFilter)) return [];
      return itemsToFilter.filter(function (element) {
        return items.indexOf(element.id) !== -1;
      });
    },
    dismissPreviewModal: function dismissPreviewModal() {
      if (this.updatedFromPreviewModal) {
        this.$emit('refreshData');
        this.updatedFromPreviewModal = false;
      }
    },
    getCompliancePropLabel: function getCompliancePropLabel(id) {
      return id ? 'compliance-' + id : '';
    },
    getCompletionDegree: function getCompletionDegree(questionnaire) {
      return questionnaire !== null && questionnaire !== void 0 && questionnaire.questionnaireAnsweredPercentage ? "".concat(questionnaire.questionnaireAnsweredPercentage, " %") : '-';
    },
    getQuestionnaireScore: function getQuestionnaireScore(questionnaire) {
      return questionnaire !== null && questionnaire !== void 0 && questionnaire.questionnaireScore ? questionnaire.questionnaireScore.totalScore : '-';
    },
    getAssessmentScoring: function getAssessmentScoring(questionnaire) {
      var _questionnaire$questi;
      return questionnaire !== null && questionnaire !== void 0 && (_questionnaire$questi = questionnaire.questionnaireScore) !== null && _questionnaire$questi !== void 0 && _questionnaire$questi.helpText ? questionnaire.questionnaireScore.helpText.assessmentText : '-';
    },
    getChildrenIAPreviewLinks: function getChildrenIAPreviewLinks(childrenObjects) {
      return {
        field: childrenObjects ? childrenObjects.map(function (child) {
          return {
            activity: getFieldsFromObjectForPreview(child),
            title: child.name
          };
        }) : [],
        fieldText: childrenObjects ? childrenObjects.map(function (child) {
          return child.name;
        }).join(', ') : ''
      };
    },
    onShowChildrenChange: function onShowChildrenChange() {
      this.columns = this.getColumns();
      this.dataset = this.getDataset(this.informationAssets, this.typeId);

      //couldn't find a better way to inject component that shouldn't be there, without this thing it would always be the last element on the page
      this.imprintParentInsideLocalStore();
      this.enhanceDataset(this.dataset, this.columns);
      this.currentIndex++;
      this.$emit('update:showChildren', this.childrenCountOnTheList);
    },
    imprintParentInsideLocalStore: function imprintParentInsideLocalStore() {
      var storageData = localStorage.getItem(this.localStorageKey);
      if (!storageData) return;
      storageData = JSON.parse(storageData);
      var nameElementIndex = storageData.columns.findIndex(function (item) {
        return item.key === 'name';
      });
      if (nameElementIndex === -1) return;
      var parentElement = _objectSpread(_objectSpread({}, storageData.columns[nameElementIndex]), {}, {
        key: 'parent',
        label: this.translate.parent,
        visible: true
      });
      storageData.columns.splice(nameElementIndex + 1, 0, parentElement);
      localStorage.setItem(this.localStorageKey, JSON.stringify(storageData));
    }
  }
};