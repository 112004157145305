import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_router_view = _resolveComponent("router-view");
  var _component_vertical_menu_wrapper = _resolveComponent("vertical-menu-wrapper");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_vertical_menu_wrapper, null, {
    menu: _withCtx(function () {
      return [_createVNode(_component_vertical_menu, {
        items: $options.menuItems
      }, null, 8 /* PROPS */, ["items"])];
    }),
    content: _withCtx(function () {
      return [_createVNode(_component_router_view, null, {
        default: _withCtx(function (_ref) {
          var Component = _ref.Component;
          return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            "activity-types": _ctx.activityTypes
          }, null, 8 /* PROPS */, ["activity-types"]))];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  })]);
}