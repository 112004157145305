function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import StaticTable from '@/components/Molecules/StaticTable';
import { ContractGenericTableMixins } from '@/components/Pages/Contracts/ContractGenericTableMixins';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { ContractPartiesOverviewMixin } from '@/components/Pages/Contracts/ContractPartiesOverviewMixin';
import { CONTRACTS_TABLE_COLUMNS } from '@/constants/contracts/contractsTableColumns';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
import { MODULES } from '@/constants/modules';
import { getContractTypes } from '@/constants/contracts/contractTypes';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { editContract } from '@/api/contracts/contracts';
import { toLocalDate } from '@/utils/date';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { escapeHtml } from '@/utils';
export default {
  name: 'ContractGenericTable',
  introduction: 'generic component to show table for contracts',
  description: 'this generic component is used for both contract history and relating contracts',
  components: {
    StaticTable: StaticTable,
    PreviewModalLink: PreviewModalLink,
    FeatherIcon: FeatherIcon,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  mixins: [ContractGenericTableMixins, ContractPartiesOverviewMixin],
  props: {
    inputData: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'Data required for dataset'
    },
    noDataMessage: {
      required: false,
      type: String,
      default: '',
      note: 'The message to display if there is no data'
    },
    workflowStatuses: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of workflow statuses'
    },
    companies: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of companies'
    },
    hiddenColumns: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of columns to hide'
    },
    fixedWidth: {
      required: false,
      type: Boolean,
      default: false,
      note: "Describes if contract table columns should have fixed width. Useful if you try to align several tables with the same column width.\n                Column sizes will get appropriate values according to content"
    },
    removeFunction: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Determine if remove icon should be added to the last column'
    },
    activityTypeId: {
      required: false,
      type: Number,
      default: null,
      note: 'Pass it to show corresponding RACI names'
    }
  },
  emits: ['updated', 'dismissModal', 'updateContracts', 'delete'],
  data: function data() {
    return {
      allStatuses: [],
      StaticTableThemes: StaticTableThemes,
      contractTypes: getContractTypes(),
      companyFields: ['companies', 'internalParties', 'externalParties'],
      translate: {
        delete: this.$trans('Delete'),
        internal: this.$trans('Internal'),
        external: this.$trans('External')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser', 'isAdmin', 'userHasCompaniesAccess'])), mapState(useActivityTypesStore, ['getActivityTypeById'])), {}, {
    currentActivityType: function currentActivityType() {
      if (!this.activityTypeId) return null;
      return this.getActivityTypeById(this.activityTypeId);
    },
    isCurrentUserLocked: function isCurrentUserLocked() {
      return this.currentUser.level_contracts_locked;
    },
    dataset: function dataset() {
      var _this = this;
      return this.inputData.map(function (item) {
        var _Object$values$find, _item$links;
        var fullCompaniesData = item.companiesData || _this.getCompaniesData(item);
        var result = {
          contractNumber: escapeHtml(item.uniqueId) || '',
          title: {
            url: item.url,
            title: escapeHtml(item.title),
            archived: item.archived,
            confidential: item.confidential,
            hoverTitle: DatatableRenderer.getItemHoverTitle(item)
          },
          titlePreview: {
            data: item,
            hoverTitle: DatatableRenderer.getItemHoverTitle(item)
          },
          contractType: ((_Object$values$find = Object.values(_this.contractTypes).find(function (type) {
            return type.value === item.contractType;
          })) === null || _Object$values$find === void 0 ? void 0 : _Object$values$find.label) || '',
          controls: ((_item$links = item.links) === null || _item$links === void 0 || (_item$links = _item$links[MODULES.CONTROLS]) === null || _item$links === void 0 ? void 0 : _item$links.length) || '',
          companies: {
            companies: _this.isExtendedParties ? '' : fullCompaniesData,
            companiesString: _this.isExtendedParties ? '' : fullCompaniesData.map(function (item) {
              return item.name;
            }).join(', ')
          },
          internalParties: {
            companies: fullCompaniesData.internal,
            companiesString: _this.isExtendedParties ? fullCompaniesData.internal.map(function (item) {
              return item.name;
            }).join(', ') : ''
          },
          externalParties: {
            companies: fullCompaniesData.external,
            companiesString: _this.isExtendedParties ? fullCompaniesData.external.map(function (item) {
              return item.name;
            }).join(', ') : ''
          },
          responsible: _this.getResponsible(item.responsibleUserIds),
          activityType: _this.getActivityTypeLabel(item.activityTypeId),
          tags: _this.getTagTitles(item.tags || []),
          start: toLocalDate(item.startDate),
          end: toLocalDate(item.endDate),
          commitment: toLocalDate(item.bindingDate),
          amount: item.contractAmount || '',
          currency: item.contractAmountCurrency || '',
          status: _this.$trans(_this.getStatusById(item.workflowStatusId)),
          starred: {
            id: item.id,
            starred: item.starred
          },
          delete: {
            contractId: item.id
          }
        };
        _this.hiddenColumns.forEach(function (column) {
          return delete result[column];
        });
        if (!_this.userHasCompaniesAccess) {
          delete result.companies;
          delete result.internalParties;
          delete result.externalParties;
        }
        if (_this.isExtendedParties) {
          delete result.companies;
        } else {
          delete result.internalParties;
          delete result.externalParties;
        }
        return result;
      });
    },
    columns: function columns() {
      var columns = _objectSpread({}, CONTRACTS_TABLE_COLUMNS(this.currentActivityType));
      this.hiddenColumns.forEach(function (column) {
        return delete columns[column];
      });
      if (!this.userHasCompaniesAccess) {
        delete columns.companies;
      }
      if (!this.removeFunction) {
        delete columns.delete;
      }
      return Object.values(columns);
    },
    prepareColumnMaxWidth: function prepareColumnMaxWidth() {
      return this.fixedWidth ? _objectSpread(_objectSpread({}, Object.keys(CONTRACTS_TABLE_COLUMNS()).reduce(function (acc, current) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, current, '100px'));
      }, {})), {}, {
        starred: '25px'
      }) : {};
    }
  }),
  methods: {
    toggleContractStarStatus: function toggleContractStarStatus(data) {
      var _this2 = this;
      if (this.isCurrentUserLocked) return;
      editContract(_objectSpread(_objectSpread({}, data), {}, {
        starred: +!data.starred
      })).then(function () {
        return _this2.$emit('updateContracts');
      });
    }
  }
};