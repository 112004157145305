import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "flex-grow"
};
var _hoisted_2 = {
  key: 1,
  class: "flex"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  class: "lowercase align-text-bottom"
};
var _hoisted_6 = {
  class: "relative"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_stacked_bar_chart = _resolveComponent("stacked-bar-chart");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_gap_schema_navigation = _resolveComponent("gap-schema-navigation");
  var _component_gap = _resolveComponent("gap");
  var _component_tab_list = _resolveComponent("tab-list");
  var _component_gap_schema_questions = _resolveComponent("gap-schema-questions");
  var _component_gap_schema_heatmaps = _resolveComponent("gap-schema-heatmaps");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$data.loaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.loaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(["w-1/4 min-w-1/4 pr-5", $options.gapsClassObject])
  }, [_ctx.getSettingValue('feature.stacked_bar_chart') ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_stacked_bar_chart, {
    data: $options.barData,
    colors: $data.colors,
    onClicked: $options.barClick
  }, null, 8 /* PROPS */, ["data", "colors", "onClicked"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.filters, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "w-1/2 inline-block"
    }, [_createElementVNode("div", {
      style: _normalizeStyle({
        background: _ctx.gapColors[item || 0].color
      }),
      class: "w-5 h-5 inline-block cursor-pointer",
      onClick: function onClick($event) {
        return $options.barClick(item);
      }
    }, [_createVNode(_component_feather_icon, {
      icon: "x",
      class: "stroke-2 mr-2 text-white"
    })], 12 /* STYLE, PROPS */, _hoisted_4), _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$trans(_ctx.gapColors[item].label)) + " (" + _toDisplayString($options.barData[item]) + ") ", 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), _createVNode(_component_gap_schema_navigation, {
    gaps: $options.filteredGaps,
    "gap-active-id": $options.currentGap.articleId,
    class: "overflow-y-auto max-h-500px h-54vh",
    onSelectGap: $options.setCurrentGapIndex
  }, null, 8 /* PROPS */, ["gaps", "gap-active-id", "onSelectGap"])], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass(["gapContainer min-w-0 w-1/2 relative mx-2", {
      'px-10': !$options.showFullGapsList
    }])
  }, [!$options.showFullGapsList && $options.filteredGaps[$data.currentGapIndex - 1] ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["absolute left-0 top-1 cursor-pointer", {
      'opacity-50 cursor-not-allowed': false
    }]),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.currentGapIndex--;
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-left",
    class: "stroke-2",
    height: "35",
    width: "35"
  })])) : _createCommentVNode("v-if", true), !$options.showFullGapsList && $options.filteredGaps[$data.currentGapIndex + 1] ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(["absolute right-0 top-1 cursor-pointer", {
      'opacity-50 cursor-not-allowed': false
    }]),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $data.currentGapIndex++;
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-right",
    class: "stroke-2",
    width: "35",
    height: "35"
  })])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.visibleGaps, function (gap, index) {
    return _openBlock(), _createBlock(_component_gap, {
      ref_for: true,
      ref: "gap-".concat(gap.articleId),
      key: gap.articleId,
      "data-gapId": gap.article.id,
      "project-id": $props.data.compliance_projects_id,
      data: $props.data,
      gap: gap,
      processes: $data.processes,
      initiatives: $data.initiatives,
      "initiatives-for-create-initiative": $data.allInitiatives,
      controls: $data.controls,
      risks: $data.risks,
      "projects-options": $options.projectsOptions,
      "default-tag-name": $options.complianceProjectTagName,
      article: gap.article,
      projects: $data.projects,
      "current-project": $options.currentProject,
      "has-questionnaires": $data.hasQuestionnaires,
      "read-only": $options.isReadOnly,
      class: "min-h-5rem mb-12",
      style: _normalizeStyle($options.visibleGaps.length - 1 === index ? 'min-height: calc(100vh - 5rem - 120px)' : ''),
      onLiaTiaForce: $options.liaTiaForce,
      onChanged: function onChanged($event) {
        return $options.updateGap(gap, $event);
      },
      onLinksChanged: function onLinksChanged($event) {
        return $options.updateGap(gap, {
          links: $event
        });
      },
      onAddNewItem: $options.addNewItem,
      onStatusNotSaved: $options.changeStatusToNotSaved,
      onSetCurrentGapIndex: function onSetCurrentGapIndex($event) {
        return $options.setCurrentGapIndex(gap.articleId);
      }
    }, null, 8 /* PROPS */, ["data-gapId", "project-id", "data", "gap", "processes", "initiatives", "initiatives-for-create-initiative", "controls", "risks", "projects-options", "default-tag-name", "article", "projects", "current-project", "has-questionnaires", "read-only", "style", "onLiaTiaForce", "onChanged", "onLinksChanged", "onAddNewItem", "onStatusNotSaved", "onSetCurrentGapIndex"]);
  }), 128 /* KEYED_FRAGMENT */))])], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass(["bg-zinc-50 w-1/4 min-w-1/4 pl-5", $options.questionsClassObject])
  }, [_createVNode(_component_tab_list, {
    class: "flex mb-6",
    tabs: $options.tabsList,
    "default-value": $data.TABS_SLOT_NAMES.relevantQuestions,
    "background-class": "bg-zinc-50",
    onUpdate: _cache[2] || (_cache[2] = function ($event) {
      return $data.currentTab = $event;
    })
  }, null, 8 /* PROPS */, ["tabs", "default-value"]), $data.currentTab !== $data.TABS_SLOT_NAMES.heatmaps ? (_openBlock(), _createBlock(_component_gap_schema_questions, {
    key: 0,
    questions: $options.questionsLists
  }, null, 8 /* PROPS */, ["questions"])) : (_openBlock(), _createBlock(_component_gap_schema_heatmaps, {
    key: 1,
    class: "w-35rem0",
    "risk-project-id": $data.riskProjectId,
    period: $data.activePeriod,
    threats: $data.threats,
    risks: $data.risksReport
  }, null, 8 /* PROPS */, ["risk-project-id", "period", "threats", "risks"]))], 2 /* CLASS */)])) : _createCommentVNode("v-if", true)]);
}