import PanelToggle from '@/components/Atoms/UtilMenuWithToggle/PanelToggle';
export default {
  components: {
    PanelToggle: PanelToggle
  },
  data: function data() {
    return {
      selectedState: ''
    };
  }
};