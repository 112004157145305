import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  class: "risk-reports-threts"
};
var _hoisted_2 = {
  key: 1,
  class: "risk-reports-threts-body"
};
var _hoisted_3 = {
  class: "mb-4"
};
var _hoisted_4 = {
  class: "w-80 ml-20"
};
var _hoisted_5 = {
  key: 0
};
var _hoisted_6 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risk_grid = _resolveComponent("risk-grid");
  var _component_save_report = _resolveComponent("save-report");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!_ctx.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), _ctx.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
    modelValue: $data.consequenceId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.consequenceId = $event;
    }),
    class: "mb-4",
    title: $data.translate.riskAssessment,
    options: $options.consequenceOptions,
    placeholder: $data.translate.choose,
    "un-sorted": true
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])]), $data.consequenceId && Object.keys($data.period).length ? (_openBlock(), _createBlock(_component_risk_grid, {
    key: 0,
    "risk-grid-data": $options.matrix.data,
    "without-z-axis": true,
    "grid-box-size": $data.gridBoxSize,
    "enable-prev-position": false,
    "is-grid-clickable": false,
    "custom-heatmap-options": $options.customHeatmapOptions,
    "dot-label-hover-function": $options.dotHoverFunction
  }, null, 8 /* PROPS */, ["risk-grid-data", "grid-box-size", "custom-heatmap-options", "dot-label-hover-function"])) : _createCommentVNode("v-if", true)]), _createVNode(_component_save_report, {
    type: $data.reportType,
    "state-local": $data.stateLocal,
    "risk-project-id": $props.projectId,
    onLoad: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.applySettingsFromLoadedReport($event);
    })
  }, null, 8 /* PROPS */, ["type", "state-local", "risk-project-id"]), _createVNode(_component_risma_datatable, {
    ref: "reportsDataTable",
    "wrapper-height-sticky": 400,
    "filter-by-column": true,
    "dataset-main": _ctx.datasetSlice,
    "dataset-total": $data.datasetBig,
    "always-visible-export-columns": ['threat'],
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "decouple-filter-fields": _ctx.decoupleFilterFields,
    "print-title": $data.translate.threatReport,
    "state-local": $data.stateLocal,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged
  }, _createSlots({
    _: 2 /* DYNAMIC */
  }, [_renderList($data.activitySlotNames, function (slotName) {
    return {
      name: slotName,
      fn: _withCtx(function (_ref) {
        var _item$activities;
        var item = _ref.item;
        return [(_item$activities = item.activities) !== null && _item$activities !== void 0 && _item$activities.length ? (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.activities, function (activity) {
          return _openBlock(), _createBlock(_component_preview_modal_link, {
            key: activity.id,
            activity: activity
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(activity.formattedTitle), 1 /* TEXT */)];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"]);
        }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", _hoisted_6))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "decouple-filter-fields", "print-title", "state-local", "onPageChange", "onOnStateChanged"])])) : _createCommentVNode("v-if", true)]);
}