// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*H1 - Heading1 - large heading*/
.glob-h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
}

/*H2 - Heading2 - medium heading*/
.glob-h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}

/*H3 - Heading3 - small heading*/
.glob-h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

/*H3 - Heading3 - regular small heading, secondary*/
.glob-h3-sr {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

/*B1 - Body1 - default body text*/
.glob-b1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

/*L1 - Label1 - default label text*/
.glob-l1,
.multiselect__option--group.multiselect__option--disabled,
.multiselect__option--group {
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 16px;
}


/* Label1 - Medium  */
.glob-l1-m {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

/*L1 - Label1 - italic label, secondary*/
.glob-l1-i {
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    line-height: 16px;
}

/*L1 - Label1 - semibold label, secondary*/
.glob-l1-sb {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

/*L2 - Label2 - small label text*/
.glob-l2 {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}

/*L2 - Label2 - small semibold label, secondary*/
.glob-l2-sb {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
}

/*Link styles*/
.glob-link,
.glob-link:link,
.glob-link:visited,
.glob-link:hover,
.glob-link:active,
.glob-link:focus,

.glob-link-inside a,
.glob-link-inside a:link,
.glob-link-inside a:visited,
.glob-link-inside a:hover,
.glob-link-inside a:active,
.glob-link-inside a:focus {
    color: rgb(33, 130, 253);
    text-decoration: underline;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
