function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import draggable from 'vuedraggable';
import MiniTreeBranch from '@/components/Molecules/MiniTree/MiniTreeBranch';
import { determineDropPosition } from '@/components/Molecules/MiniTree/utils';
import { DragOptions } from '@/constants/DragOptions';
export default {
  display: 'MiniTreeBranchDraggable',
  components: {
    draggable: draggable,
    MiniTreeBranch: MiniTreeBranch
  },
  props: {
    list: {
      required: true,
      type: Object,
      default: function _default() {
        return {};
      }
    },
    selectedPath: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    parentId: {
      required: false,
      type: [String, Number],
      default: 0
    },
    searchEnabled: {
      required: false,
      type: Boolean,
      default: false
    },
    openedArray: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    nodeEmits: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    path: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    draggable: {
      type: Boolean,
      required: true
    },
    parentEnabled: {
      type: Boolean,
      default: false
    },
    hasToggleMenuProp: {
      type: Boolean,
      default: false,
      note: 'if toggle menu logic should be covered with one field'
    }
  },
  emits: ['update:selected', 'update:opened', 'update:path', 'edit:enabled', 'edit:title', 'drop:showHint', 'drag:end', 'addSubNode', 'edit:payingParty', 'deleteNode', 'edit:signingParty'],
  data: function data() {
    return {
      hoverTimeout: null,
      expandTimeout: null,
      position: {},
      dragOverEvent: null,
      isDragging: false,
      dragOptions: DragOptions
    };
  },
  methods: {
    mousePositionChanged: function mousePositionChanged(_ref) {
      var clientX = _ref.clientX,
        clientY = _ref.clientY;
      var mousePosition = this.dragOverEvent.originalEvent;
      return mousePosition.clientX !== clientX || mousePosition.clientY !== clientY;
    },
    moveCallback: function moveCallback(evt) {
      var mousePosition = evt.originalEvent;
      if (this.dragOverEvent && !this.mousePositionChanged(mousePosition)) {
        return false;
      }
      this.dragOverEvent = evt;
      this.handleMove(evt);
      return false;
    },
    handleMove: function handleMove(e) {
      var _e$relatedContext,
        _this = this;
      this.clearHoverTimeout();
      var node = (_e$relatedContext = e.relatedContext) === null || _e$relatedContext === void 0 ? void 0 : _e$relatedContext.element;
      var isOpened = this.openedArray.includes(node.id);
      if (!isOpened && node.children.length && this.position.type === 'inside') {
        this.hoverTimeout = setTimeout(function () {
          _this.openedArray.push(node.id);
          clearTimeout(_this.hoverTimeout);
        }, 500);
      }
      var rect = e.originalEvent.target.querySelector('.js-branch');
      if (rect) {
        this.position = determineDropPosition(rect, e.originalEvent);
        this.$emit('drop:showHint', this.position);
      }
      document.body.classList.add('noselect');
      return false;
    },
    handleDragEnd: function handleDragEnd() {
      if (!this.dragOverEvent) return;
      var _this$dragOverEvent = this.dragOverEvent,
        dragged = _this$dragOverEvent.dragged,
        related = _this$dragOverEvent.related,
        draggedContext = _this$dragOverEvent.draggedContext,
        relatedContext = _this$dragOverEvent.relatedContext;
      var fromPath = dragged.getAttribute('data-path').split(',');
      var relatedPath = related.getAttribute('data-path').split(',');
      this.$emit('drag:end', {
        fromPath: fromPath,
        toPath: relatedPath,
        element: draggedContext,
        type: this.position.type,
        draggedContext: draggedContext,
        relatedContext: relatedContext
      });
      this.$nextTick(function () {
        return document.body.classList.remove('noselect');
      });
      this.dragOverEvent = null;
      window.getSelection().removeAllRanges();
    },
    clearHoverTimeout: function clearHoverTimeout() {
      if (this.hoverTimeout) {
        clearTimeout(this.hoverTimeout);
        this.hoverTimeout = null;
      }
    },
    emitItemSelection: function emitItemSelection(event) {
      this.$emit('update:selected', event);
    },
    onItemSelect: function onItemSelect(event, item, index) {
      this.emitItemSelection([{
        id: item.id,
        index: index
      }].concat(_toConsumableArray(event)));
    }
  }
};