import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  key: 1,
  class: "flex flex-wrap-reverse"
};
var _hoisted_2 = {
  class: "flex items-center my-2"
};
var _hoisted_3 = {
  class: "table-global-search ml-auto"
};
var _hoisted_4 = {
  key: 3,
  class: "risma-datatable-content"
};
var _hoisted_5 = {
  class: "risma-datatable-scrollHead"
};
var _hoisted_6 = {
  class: "table-wrapper table-wrapper-head"
};
var _hoisted_7 = ["data-column-key", "data-column-index", "onClick"];
var _hoisted_8 = {
  ref: "body",
  class: "risma-datatable-scrollBody overflow-auto"
};
var _hoisted_9 = {
  key: 0,
  class: "no-matches"
};
var _hoisted_10 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_data_table_filter_tags = _resolveComponent("data-table-filter-tags");
  var _component_risma_excel = _resolveComponent("risma-excel");
  var _component_risma_print = _resolveComponent("risma-print");
  var _component_risma_column_switcher = _resolveComponent("risma-column-switcher");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_pagesize = _resolveComponent("risma-pagesize");
  var _component_input_field = _resolveComponent("input-field");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_draggable = _resolveComponent("draggable");
  var _component_data_table_column_filter = _resolveComponent("data-table-column-filter");
  var _component_pagination = _resolveComponent("pagination");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'filter-by-column': $props.filterByColumn
    })
  }, [$props.filterByColumn ? (_openBlock(), _createBlock(_component_data_table_filter_tags, {
    key: 0,
    ref: "dataTableFilterTagsRef",
    onRemoveFilter: $options.removeAppliedFilter
  }, null, 8 /* PROPS */, ["onRemoveFilter"])) : _createCommentVNode("v-if", true), $data.columns.length && $props.hasTopNavigation ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "excel", {
    columnsToExport: $options.columnsExport
  }, function () {
    return [_createVNode(_component_risma_excel, {
      columns: $options.columnsExport,
      "columns-types": $options.columnsTypes,
      "dataset-export": $options.datasetExport,
      filename: $props.excelFilename,
      "confirm-message": $props.confirmExportMessage,
      class: "mr-6",
      onExport: _cache[0] || (_cache[0] = function ($event) {
        return _ctx.$emit('export', {
          type: $data.ReportLogTypes.EXCEL,
          count: $event
        });
      })
    }, null, 8 /* PROPS */, ["columns", "columns-types", "dataset-export", "filename", "confirm-message"])];
  }, true), _renderSlot(_ctx.$slots, "print", {
    columnsToExport: $options.columnsExport
  }, function () {
    return [_createVNode(_component_risma_print, {
      "head-title": $props.printTitle,
      columns: $options.columnsExport,
      "dataset-export": $options.datasetExport,
      "media-print-css": $props.mediaPrintCss,
      "confirm-message": $props.confirmPrintMessage,
      class: "mr-6",
      onExport: _cache[1] || (_cache[1] = function ($event) {
        return _ctx.$emit('export', {
          type: $data.ReportLogTypes.PRINT,
          count: $event
        });
      })
    }, null, 8 /* PROPS */, ["head-title", "columns", "dataset-export", "media-print-css", "confirm-message"])];
  }, true), _createVNode(_component_risma_column_switcher, {
    columns: $data.columns,
    "has-all-switcher": true,
    "always-visible-columns": $props.alwaysVisibleExportColumns,
    class: "mr-6",
    onChanged: $options.onChangeVisibility
  }, null, 8 /* PROPS */, ["columns", "always-visible-columns", "onChanged"]), _createElementVNode("div", {
    class: "flex mr-6 text-blue-750 cursor-pointer",
    onClick: _cache[2] || (_cache[2] = function () {
      return $options.resetState && $options.resetState.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    class: "mr-1",
    icon: "delete"
  }), _createElementVNode("span", null, _toDisplayString($options.resetButtonLabel), 1 /* TEXT */)]), _renderSlot(_ctx.$slots, "pageSize", {}, function () {
    return [$props.paging ? (_openBlock(), _createBlock(_component_risma_pagesize, {
      key: 0,
      modelValue: $data.tableState.pageLength,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
        return $data.tableState.pageLength = $event;
      }),
      "paging-options": $props.pagingOptions,
      class: "mr-6"
    }, null, 8 /* PROPS */, ["modelValue", "paging-options"])) : _createCommentVNode("v-if", true)];
  }, true)]), _createElementVNode("div", _hoisted_3, [$props.withGlobalSearch ? (_openBlock(), _createBlock(_component_input_field, {
    key: 0,
    "model-value": $data.tableState.search.search,
    type: "search",
    placeholder: $data.translate.search,
    class: "w-230px",
    "onUpdate:modelValue": $options.updateSearch,
    onUpdated: _ctx.onGlobalSearch
  }, null, 8 /* PROPS */, ["model-value", "placeholder", "onUpdate:modelValue", "onUpdated"])) : _createCommentVNode("v-if", true)])])) : _createCommentVNode("v-if", true), $props.loadingIndicatorState ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 2,
    class: "my-10vh"
  })) : _createCommentVNode("v-if", true), $data.columns.length ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("table", {
    class: _normalizeClass(["table-fixed", {
      'pr-4': $data.bodyHasScroll
    }])
  }, [_createElementVNode("thead", null, [$options.visibleColumns.length ? (_openBlock(), _createBlock(_component_draggable, {
    key: 0,
    modelValue: $data.columns,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $data.columns = $event;
    }),
    "item-key": "key",
    tag: "tr",
    name: "table-columns",
    animation: 400,
    disabled: !$props.isDraggable,
    onChange: $options.onColumnDrag
  }, {
    item: _withCtx(function (_ref) {
      var element = _ref.element,
        index = _ref.index;
      return [element.visible ? (_openBlock(), _createElementBlock("th", {
        key: 0,
        class: _normalizeClass(["text-blue-750 text-left", ['datatable-col-' + element.key, element.css, $options.getSortClass(element), $props.headerCellStyle, {
          'preset-width': !$props.doResizing,
          'cursor-pointer': !$options.isDisabledColumnMenu(element.key)
        }]]),
        "data-column-key": element.key,
        "data-column-index": index,
        style: _normalizeStyle({
          width: element.width,
          'min-width': element.width,
          'max-width': element.width
        }),
        onClick: function onClick($event) {
          return $options.onTheadCellClick(element, $event);
        }
      }, _toDisplayString(element.label), 15 /* TEXT, CLASS, STYLE, PROPS */, _hoisted_7)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "disabled", "onChange"])) : _createCommentVNode("v-if", true)])], 2 /* CLASS */)])]), _createElementVNode("div", _hoisted_8, [$data.columns.length && !$props.datasetMain.length ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString($data.translate.noRecordsMatching), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "table-wrapper table-wrapper-body",
    style: _normalizeStyle($props.wrapperHeightSticky ? "height: ".concat($options.tbodyHeight, "; min-height: 200px;") : '')
  }, [_createElementVNode("table", {
    class: _normalizeClass({
      'table-fixed': !$props.doResizing
    })
  }, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.columns, function (column) {
    return _openBlock(), _createElementBlock(_Fragment, null, [column.visible ? (_openBlock(), _createElementBlock("th", {
      key: column.key,
      class: _normalizeClass(['datatable-col-' + column.key, {
        'preset-width': !$props.doResizing
      }, $props.headerCellStyle]),
      style: _normalizeStyle({
        width: column.width,
        'min-width': column.width,
        'max-width': column.width
      })
    }, [_createElementVNode("div", {
      style: _normalizeStyle({
        width: column.width
      })
    }, _toDisplayString(column.label), 5 /* TEXT, STYLE */)], 6 /* CLASS, STYLE */)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
  }), 256 /* UNKEYED_FRAGMENT */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.datasetMain, function (item, index) {
    return _openBlock(), _createElementBlock("tr", {
      key: index,
      name: "table-columns"
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.columns, function (column) {
      return _openBlock(), _createElementBlock(_Fragment, null, [column.visible ? (_openBlock(), _createElementBlock("td", {
        key: column.key,
        class: _normalizeClass(["align-top", ['col-' + column.key, $props.dataCellStyles]])
      }, [_renderSlot(_ctx.$slots, column.key, {
        item: item[column.key]
      }, function () {
        return [_createElementVNode("div", {
          innerHTML: item[column.key] && item[column.key].field + '' || ''
        }, null, 8 /* PROPS */, _hoisted_10)];
      }, true)], 2 /* CLASS */)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
    }), 256 /* UNKEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))])], 2 /* CLASS */)], 4 /* STYLE */)), $props.filterByColumn ? (_openBlock(), _createBlock(_component_data_table_column_filter, {
    key: 2,
    "is-visible": $data.displayColumnFilter,
    "filter-data": $data.filterData,
    "filter-options": $options.filterOptions,
    "additional-option": $data.additionalFilterOption,
    onClickOutside: _cache[5] || (_cache[5] = function ($event) {
      return $data.displayColumnFilter = false;
    }),
    onSort: $options.sortByColumn,
    onFilterOptionSearch: $options.onFilterOptionSearch,
    onFilterOptionSelect: $options.onFilterOptionSelect,
    onDeselectAll: $options.removeAppliedFilter,
    onAdditionalOptionChange: $options.onAdditionalFilterChanged
  }, null, 8 /* PROPS */, ["is-visible", "filter-data", "filter-options", "additional-option", "onSort", "onFilterOptionSearch", "onFilterOptionSelect", "onDeselectAll", "onAdditionalOptionChange"])) : _createCommentVNode("v-if", true)], 512 /* NEED_PATCH */), $props.showPagination ? (_openBlock(), _createBlock(_component_pagination, {
    key: 0,
    ref: "pagination",
    total: $props.datasetTotalSliced.length,
    "max-length": $options.datasetMaxLength,
    "per-page": $data.tableState.pageLength,
    "show-prev-next-buttons": true,
    "state-session": $options.stateSession,
    onChange: $options.emitPageChange
  }, null, 8 /* PROPS */, ["total", "max-length", "per-page", "state-session", "onChange"])) : _createCommentVNode("v-if", true)], 512 /* NEED_PATCH */)), [[_vShow, !$props.loadingIndicatorState]]) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}