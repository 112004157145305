import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "glob-h3 text-rm-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_input_field = _resolveComponent("input-field");
  var _component_expansion_panel = _resolveComponent("expansion-panel");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.definitionsFromEntitiesMakingUse,
    "button-ok-text": $data.translate.save,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.onEdit,
    onDismiss: $options.closeModal
  }, {
    body: _withCtx(function () {
      return [$data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
        key: 0
      })) : (_openBlock(true), _createElementBlock(_Fragment, {
        key: 1
      }, _renderList($options.panels, function (panel) {
        return _openBlock(), _createBlock(_component_expansion_panel, {
          class: "mb-2"
        }, {
          header: _withCtx(function () {
            return [_createElementVNode("span", _hoisted_1, _toDisplayString(panel.header), 1 /* TEXT */)];
          }),
          content: _withCtx(function () {
            return [_createElementVNode("div", {
              class: _normalizeClass(["grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-4 items-end px-4 mt-0.5 mb-4", {
                '2xl:grid-cols-3': panel.keys.length === 3
              }])
            }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(panel.keys, function (key) {
              return _openBlock(), _createBlock(_component_input_field, {
                modelValue: $data.definitionsValues[key],
                "onUpdate:modelValue": function onUpdateModelValue($event) {
                  return $data.definitionsValues[key] = $event;
                },
                title: $options.fieldNames[key],
                placeholder: $options.fieldNames[key],
                type: "text"
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "title", "placeholder"]);
            }), 256 /* UNKEYED_FRAGMENT */))], 2 /* CLASS */)];
          }),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */);
      }), 256 /* UNKEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"]);
}