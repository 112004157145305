import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "performance-container"
};
var _hoisted_2 = {
  key: 0,
  class: "bg-blue-10"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_router_view = _resolveComponent("router-view");
  var _component_vertical_menu_wrapper = _resolveComponent("vertical-menu-wrapper");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_vertical_menu_wrapper, {
    class: "pt-5"
  }, {
    menu: _withCtx(function () {
      return [_createVNode(_component_vertical_menu, {
        items: $data.topLinks,
        "exact-match": true
      }, null, 8 /* PROPS */, ["items"]), _cache[0] || (_cache[0] = _createElementVNode("hr", {
        class: "my-2 border-b bg-gray-450 border-gray-450"
      }, null, -1 /* HOISTED */)), _createVNode(_component_vertical_menu, {
        items: $options.dashboardLinks
      }, null, 8 /* PROPS */, ["items"]), _cache[1] || (_cache[1] = _createElementVNode("hr", {
        class: "my-2 border-b bg-gray-450 border-gray-450"
      }, null, -1 /* HOISTED */)), _createVNode(_component_vertical_menu, {
        items: $options.settingLink
      }, null, 8 /* PROPS */, ["items"])];
    }),
    content: _withCtx(function () {
      return [$data.dashboards.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.fullPath,
        dashboards: $data.dashboards,
        "performance-data": $data.performanceData,
        "performance-line-data": $data.performanceLineData,
        "monthly-daily-value": $setup.dailyMonthlyState,
        onProgressTypeChanged: $options.handleProgressTypeChanged,
        onCreateDashboard: $options.handleDashboardCreation,
        onDashboardsUpdated: $options.getData
      }, null, 8 /* PROPS */, ["dashboards", "performance-data", "performance-line-data", "monthly-daily-value", "onProgressTypeChanged", "onCreateDashboard", "onDashboardsUpdated"]))])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })]);
}