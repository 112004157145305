function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import isEqual from 'lodash/isEqual';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import { getTranslate } from './translate';
export default {
  name: 'EditComplianceProjectModal',
  components: {
    Modal: Modal,
    InputField: InputField,
    RismaLabel: RismaLabel,
    SingleSelect: SingleSelect,
    ActivityTypeSelector: ActivityTypeSelector,
    Checkboxes: Checkboxes,
    FrameworksSelector: FrameworksSelector
  },
  props: {
    project: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Project data to update'
    },
    questionnaires: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    gapSchemas: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    frameworks: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    activityTypes: {
      type: Array,
      required: true
    }
  },
  emits: ['accept', 'dismiss'],
  data: function data() {
    return {
      errors: {},
      isDirty: false,
      projectClone: {},
      projectKeys: {
        TITLE: 'title',
        DESCRIPTION_LABEL: 'descriptionLabel',
        PURPOSE_LABEL: 'purposeLabel',
        STATUS_LABEL: 'statusLabel',
        RECOMMENDED_QUESTIONS: 'recommendedQuestions',
        GAP_HEATMAPS: 'gapHeatmaps',
        SHOW_GAP_ARTICLE_TYPES: 'showGapArticleTypes',
        ENABLE_GAP_RISK_MODULE: 'enableGapRiskModule'
      },
      translate: getTranslate['EditComplianceProjectModal']()
    };
  },
  computed: {
    selectedFrameworks: function selectedFrameworks() {
      var _this = this;
      return this.frameworks.filter(function (framework) {
        var _this$project;
        return (_this$project = _this.project) === null || _this$project === void 0 ? void 0 : _this$project.solutionIds.includes(framework.id);
      });
    },
    requiredFields: function requiredFields() {
      return [this.projectKeys.TITLE, this.projectKeys.DESCRIPTION_LABEL, this.projectKeys.PURPOSE_LABEL, this.projectKeys.STATUS_LABEL];
    },
    checkBoxFields: function checkBoxFields() {
      return [{
        label: this.translate.enableRecommendedActivitiesForGaps,
        value: this.projectKeys.RECOMMENDED_QUESTIONS
      }, {
        label: this.translate.enableHeatMapsInGapSchema,
        value: this.projectKeys.GAP_HEATMAPS
      }, {
        label: this.translate.enableGapTypes,
        value: this.projectKeys.SHOW_GAP_ARTICLE_TYPES
      }, {
        label: this.translate.riskPrGap,
        value: this.projectKeys.ENABLE_GAP_RISK_MODULE
      }];
    },
    checkBoxValues: {
      get: function get() {
        var _this2 = this;
        return this.checkBoxFields.map(function (_ref) {
          var value = _ref.value;
          return _this2.project[value] ? value : null;
        }).filter(Boolean);
      },
      set: function set(selectedValues) {
        var _this3 = this;
        this.checkBoxFields.forEach(function (_ref2) {
          var value = _ref2.value;
          var included = selectedValues.includes(value);
          _this3.project[value] = typeof _this3.projectClone[value] === 'boolean' ? included : +included;
        });
      }
    }
  },
  mounted: function mounted() {
    this.projectClone = _objectSpread({}, this.project);
  },
  methods: {
    setFrameworkId: function setFrameworkId(values) {
      if (!this.project) return;
      this.project.solutionIds = values.map(function (framework) {
        return framework.id;
      });
    },
    submitModal: function submitModal() {
      if (isEqual(this.project, this.projectClone)) {
        return this.$emit('dismiss');
      }
      if (this.validateProject(this.project)) {
        this.prepareRenameValues(this.project, this.projectClone);
        this.$emit('accept', this.project);
      }
    },
    validateProject: function validateProject(project) {
      var _this4 = this;
      this.errors = {};
      this.requiredFields.forEach(function (fieldName) {
        if (!project[fieldName]) {
          _this4.errors[fieldName] = true;
        }
      });
      return Object.keys(this.errors).length === 0;
    },
    prepareRenameValues: function prepareRenameValues(project, clone) {
      var data = {
        descriptionLabel: this.translate.overallDescription,
        purposeLabel: this.translate.purpose,
        statusLabel: this.translate.processStatus
      };
      for (var property in data) {
        if (project[property] === data[property] && clone[property] === project[property]) project[property] = null;
      }
    }
  }
};