import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'VerticalMenuItem',
  introduction: 'Vertical menu item',
  description: 'Vertical menu item',
  token: '<vertical-menu-item item={path: "/dev/kitchensink/atoms", title: "Atoms", color: "#000"//optional} />',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    item: {
      type: Object,
      required: true,
      note: 'Objects with required fields: path, title'
    },
    selectedId: {
      type: [String, Number],
      required: false,
      default: '',
      note: 'Is used when in preview mode, to show selected item'
    },
    exactMatch: {
      type: Boolean,
      default: false,
      required: false
    }
  }
};