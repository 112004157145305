import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createBlock(_component_router_link, {
    to: $props.item.routeName ? {
      name: $props.item.routeName
    } : $props.item.path,
    class: _normalizeClass(["block mb-2 p-2 rounded text-gray-650 hover:bg-gray-250", $props.selectedId === $props.item.id ? 'glob-h3 bg-gray-250' : 'glob-h3-sr'])
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [$props.item.icon ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: $props.item.icon,
        style: {
          minWidth: '20px'
        },
        class: "transform stroke-2 mr-2"
      }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
        class: "w-full break-words",
        innerHTML: $props.item.title
      }, null, 8 /* PROPS */, _hoisted_2)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to", "class"]);
}