import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import { getTranslate } from './translate';
export default {
  name: 'InternalSectionOverview',
  components: {
    RismaSelect: RismaSelect,
    SingleSelect: SingleSelect
  },
  props: {
    selectedOptions: {
      required: true,
      type: Object
    },
    options: {
      required: true,
      type: Object
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update'],
  data: function data() {
    return {
      translate: getTranslate['InternalSectionOverview']()
    };
  },
  computed: {
    ictServiceProviderLabel: function ictServiceProviderLabel() {
      return "".concat(this.translate.ictServiceProvider, " (02.02.0030)");
    },
    functionIdentifierLabel: function functionIdentifierLabel() {
      return "".concat(this.translate.functionName, " (").concat(this.translate.functionIdentifier, ") (02.02.0050)");
    },
    typeOfIctServicesLabel: function typeOfIctServicesLabel() {
      return "".concat(this.translate.typeOfIctServices, " (02.02.0060)");
    },
    storageOfDataLabel: function storageOfDataLabel() {
      return "".concat(this.translate.storageOfData, " (02.02.0140)");
    },
    dataLocationLabel: function dataLocationLabel() {
      return "".concat(this.translate.dataLocation, " (02.02.0150)");
    },
    managementLocationLabel: function managementLocationLabel() {
      return "".concat(this.translate.managementLocation, " (02.02.0160)");
    },
    dataSensitivenessLabel: function dataSensitivenessLabel() {
      return "".concat(this.translate.dataSensitiveness, " (02.02.0170)");
    },
    relianceLevelLabel: function relianceLevelLabel() {
      return "".concat(this.translate.relianceLevel, " (02.02.0180)");
    }
  },
  methods: {
    handleUpdate: function handleUpdate(property, value) {
      this.selectedOptions[property] = value;
      this.$emit('update', this.selectedOptions);
    }
  }
};