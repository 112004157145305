import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = {
  class: "flex justify-between items-center"
};
var _hoisted_3 = {
  class: "flex mb-2 items-center"
};
var _hoisted_4 = {
  class: "flex"
};
var _hoisted_5 = {
  class: "w-60 ml-auto mr-4"
};
var _hoisted_6 = {
  class: "hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_save_report = _resolveComponent("save-report");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.mainReport,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_button, {
    text: $data.translate.switchToLegacyReports,
    type: "util",
    onClick: $options.switchToLegacyReports
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "repeat",
        class: "stroke-2 mr-1"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_save_report, {
    type: $data.reportType,
    "state-local": $data.stateLocal,
    "report-options": _ctx.dateRangeReportOptions,
    onLoad: _ctx.onLoadReport,
    onSaved: _ctx.afterReportSave
  }, null, 8 /* PROPS */, ["type", "state-local", "report-options", "onLoad", "onSaved"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_toggle, {
    modelValue: $data.showDeactivated,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.showDeactivated = $event;
    }),
    class: "mr-1",
    onInput: $options.toggleDeactivated
  }, null, 8 /* PROPS */, ["modelValue", "onInput"]), _createVNode(_component_risma_title, {
    title: $data.translate.showDeactivatedControls,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_single_select, {
    modelValue: $setup.selectedDateType,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $setup.selectedDateType = $event;
    }),
    options: $options.dateTypeOptions,
    disabled: false,
    "un-sorted": true
  }, null, 8 /* PROPS */, ["modelValue", "options"])]), _ctx.initRange.start && _ctx.initRange.end ? (_openBlock(), _createBlock(_component_risma_date_range_picker, {
    key: 0,
    ref: "rismaDateRangePicker",
    "start-date": _ctx.initRange.start,
    "end-date": _ctx.initRange.end,
    "local-storage-key": $data.localStorageRangeKey,
    tooltip: $data.translate.deadlineInterval,
    ranges: $data.ranges,
    onChanged: _ctx.changedDates
  }, null, 8 /* PROPS */, ["start-date", "end-date", "local-storage-key", "tooltip", "ranges", "onChanged"])) : _createCommentVNode("v-if", true)]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createBlock(_component_risma_datatable, {
    key: 1,
    ref: "reportsDataTable",
    "dataset-main": _ctx.datasetSlice,
    "dataset-total": _ctx.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "page-length": _ctx.pageLength,
    "wrapper-height-sticky": 550,
    "filter-by-column": true,
    "state-local": $data.stateLocal,
    "column-filters-sorting": _ctx.columnFiltersSorting,
    "column-sorting": _ctx.columnSorting,
    "columns-by-default": $options.columns,
    "disabled-filter-by-columns": $options.disabledFilterByColumns,
    "columns-meta": $data.columnsMeta,
    "decouple-filter-fields": $options.decoupleFilterFields,
    "additional-filter-options": $data.additionalFilterOptions,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged
  }, _createSlots({
    title: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_createVNode(_component_preview_modal_link, {
        class: _normalizeClass({
          'line-through': !item.isActive
        }),
        activity: item.activity,
        onDismissModal: _ctx.previewModalDismissed
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_risma_title_with_icon, {
            icon: "confidential",
            truncate: false,
            title: item.fieldText,
            "title-attribute": item.hoverTitle,
            "display-icon": item.confidential
          }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "activity", "onDismissModal"])];
    }),
    _: 2 /* DYNAMIC */
  }, [_renderList(_ctx.trafficLightRismaDatatableFields, function (trafficLightCol) {
    return {
      name: trafficLightCol,
      fn: _withCtx(function (_ref2) {
        var item = _ref2.item;
        return [item.field.color ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(trafficLightCol)
        }, [_createVNode(_component_traffic_light, {
          color: item.field.color
        }, null, 8 /* PROPS */, ["color"]), _createElementVNode("p", _hoisted_6, _toDisplayString(item.field.value) + "-" + _toDisplayString(item.field.label), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("span", {
          key: "".concat(trafficLightCol, "_span")
        }))];
      })
    };
  }), _renderList($options.linkedColumnsForPreview, function (linkCol) {
    return {
      name: linkCol,
      fn: _withCtx(function (_ref3) {
        var item = _ref3.item;
        return [item.activities && item.activities.length ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(linkCol, "_").concat(item.id)
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.activities, function (activityObj) {
          return _openBlock(), _createBlock(_component_preview_modal_link, {
            key: activityObj.id,
            class: "hover:underline",
            activity: activityObj.activity,
            "selected-route": activityObj.activity.route,
            onDismissModal: _ctx.previewModalDismissed
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_risma_title_with_icon, {
                icon: "confidential",
                type: "small",
                truncate: false,
                title: activityObj.fieldText,
                "display-icon": activityObj.activity.confidential
              }, null, 8 /* PROPS */, ["title", "display-icon"])];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "selected-route", "onDismissModal"]);
        }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", {
          key: linkCol
        }))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "page-length", "state-local", "column-filters-sorting", "column-sorting", "columns-by-default", "disabled-filter-by-columns", "columns-meta", "decouple-filter-fields", "additional-filter-options", "onPageChange", "onOnStateChanged"])) : _createCommentVNode("v-if", true)]);
}