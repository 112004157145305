import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "w-full"
};
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = {
  key: 1,
  class: "mb-4"
};
var _hoisted_4 = {
  class: "flex flex-row"
};
var _hoisted_5 = {
  class: "mt-2"
};
var _hoisted_6 = {
  key: 0,
  class: "mb-2"
};
var _hoisted_7 = {
  class: "flex items-center cursor-pointer"
};
var _hoisted_8 = {
  class: "flex"
};
var _hoisted_9 = {
  key: 0
};
var _hoisted_10 = {
  key: 1,
  class: "flex justify-end"
};
var _hoisted_11 = {
  key: 0,
  class: "ml-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_main_well = _resolveComponent("main-well");
  var _component_risk_matrix_axis_parameters_well = _resolveComponent("risk-matrix-axis-parameters-well");
  var _component_scoring_other_parameters = _resolveComponent("scoring-other-parameters");
  var _component_custom_risk_fields_well = _resolveComponent("custom-risk-fields-well");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_scroll_top_button = _resolveComponent("scroll-top-button");
  return $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_button, {
    text: _ctx.$trans('Back'),
    onClick: $options.backToPrevPage
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "chevron-left"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"])]), _createVNode(_component_risma_title, {
    title: $data.translate.period,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("section", null, [_createVNode(_component_main_well, {
    class: "w-full mb-6"
  }, {
    default: _withCtx(function () {
      return [$options.isReadonly ? (_openBlock(), _createBlock(_component_risma_title, {
        key: 0,
        title: $data.scoring.version,
        type: "big"
      }, null, 8 /* PROPS */, ["title"])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_input_field, {
        modelValue: $data.scoring.version,
        "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
          return $data.scoring.version = $event;
        }), $options.updateTitle],
        title: $data.translate.title,
        enabled: !$options.isReadonly,
        type: "text",
        class: "max-w-xl",
        maxlength: "30"
      }, null, 8 /* PROPS */, ["modelValue", "title", "enabled", "onUpdate:modelValue"]), _createElementVNode("p", {
        class: _normalizeClass({
          'text-red-50': !$data.isTitleValid
        })
      }, _toDisplayString($data.translate.thePeriodShouldConsistOfOnlyLettersAndNumb) + ". " + _toDisplayString($data.translate.maxLengthIs30Characters), 3 /* TEXT, CLASS */)]))];
    }),
    _: 1 /* STABLE */
  })]), _createElementVNode("section", null, [_createVNode(_component_risk_matrix_axis_parameters_well, {
    scoring: $data.scoring,
    "read-only": $options.isReadonly,
    onChange: $options.onMatrixAxisParametersChange,
    "onChange:table": _ctx.onAxisTableChangeDebounced,
    "onChange:calculation": $options.onCalculationChange,
    "onCreate:table": $options.getScoring,
    "onUpdate:calculationType": _cache[1] || (_cache[1] = function ($event) {
      return $data.scoring[$data.RiskBaseModelProps.CUSTOM_IMPACT].calculationType = $event;
    })
  }, null, 8 /* PROPS */, ["scoring", "read-only", "onChange", "onChange:table", "onChange:calculation", "onCreate:table"])]), _createElementVNode("section", null, [_createVNode(_component_main_well, {
    class: "mb-6"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.otherXaxisParameters,
        type: "big"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.otherParameters, function (otherParams, index) {
        return _openBlock(), _createBlock(_component_scoring_other_parameters, {
          key: "other-params-".concat(index),
          title: otherParams.title,
          label: otherParams.label,
          labels: otherParams.labels,
          "in-use": otherParams.inUse,
          "read-only": $options.isReadonly,
          class: "px-4",
          onChange: function onChange($event) {
            return $options.onOtherParametersChange($event, index + 1);
          }
        }, null, 8 /* PROPS */, ["title", "label", "labels", "in-use", "read-only", "onChange"]);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),
    _: 1 /* STABLE */
  })]), _createElementVNode("section", null, [_createVNode(_component_custom_risk_fields_well, {
    "scoring-id": $data.scoring.id,
    fields: $data.scoring.fields,
    "field-groups": $data.fieldGroups,
    "read-only": $options.isReadonly,
    "save-button-click-counter": $data.saveButtonClickCounter,
    onEdit: $options.onCustomFieldChange,
    onDelete: $options.onCustomFieldDelete,
    "onMove:groups": $options.updateFieldGroups,
    "onMove:fields": $options.updateFieldsOrder
  }, null, 8 /* PROPS */, ["scoring-id", "fields", "field-groups", "read-only", "save-button-click-counter", "onEdit", "onDelete", "onMove:groups", "onMove:fields"])]), _createElementVNode("div", _hoisted_5, [!$options.isReadonly ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("label", _hoisted_7, [_createElementVNode("span", null, _toDisplayString($data.translate.openThisPeriodAndCloseThePrevious), 1 /* TEXT */), _createVNode(_component_checkboxes, {
    options: $data.status,
    "checkbox-size": 20,
    onUpdated: $options.onOpenCloseChange
  }, null, 8 /* PROPS */, ["options", "onUpdated"])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_8, [$options.isReadonly ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_risma_button, {
    text: $data.translate.back,
    onClick: $options.backToPrevPage
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "chevron-left"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"])])) : (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", null, [_createVNode(_component_risma_button, {
    text: $data.translate.delete,
    type: "danger",
    onClick: $options.showConfirmation
  }, null, 8 /* PROPS */, ["text", "onClick"])]), $options.isEditingMode ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_risma_button, {
    text: $options.savePeriodTitle,
    type: "save",
    disabled: $options.isSaveBtnDisabled,
    onClick: $options.onUpdateScoring
  }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])) : _createCommentVNode("v-if", true)]))])]), _createVNode(_component_scroll_top_button)])) : _createCommentVNode("v-if", true);
}