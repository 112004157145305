function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import * as ReportsApi from '@/api/reports/reports';
import Modal from '@/components/Molecules/Modal/Modal';
import SavedReports from '@/components/Molecules/Reports/SavedReports';
import ReportSavingModal from '@/components/Molecules/Reports/ReportSavingModal';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'SaveReport',
  description: 'this component is used to display "Saved reports" and "Save current report" buttons',
  components: {
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    SavedReports: SavedReports,
    ReportSavingModal: ReportSavingModal,
    RismaButton: RismaButton
  },
  props: {
    type: {
      required: true,
      type: String,
      note: 'Type from report-schema.json'
    },
    stateLocal: {
      required: true,
      type: String,
      note: 'The name of a key in localStorage should be the same as in datatable'
    },
    riskProjectId: {
      type: [Number, String],
      default: null,
      note: 'Risk project id that corresponds to this report'
    },
    complianceProjectsId: {
      type: [Number, String],
      default: null,
      note: 'Compliance project id that corresponds to this report'
    },
    reportOptions: {
      type: Object,
      default: null,
      note: 'Report options that will be saved along with the report'
    }
  },
  emits: ['load', 'saved'],
  data: function data() {
    return {
      savedReportsVisible: false,
      showSaveReportModal: false,
      translate: getTranslate['SaveReport']()
    };
  },
  computed: {
    savedReportsHeader: function savedReportsHeader() {
      return this.translate.savedReports;
    },
    projectId: function projectId() {
      return this.riskProjectId || this.complianceProjectsId;
    }
  },
  methods: {
    hideSavedReports: function hideSavedReports() {
      this.savedReportsVisible = false;
    },
    onLoadReport: function onLoadReport(event) {
      this.hideSavedReports();
      this.$emit('load', event);
    },
    saveReport: function saveReport(title) {
      var _this = this;
      var description = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      this.showSaveReportModal = false;
      var data = this.prepareSaveData(this.type, title, description);
      return ReportsApi.saveReport(data).then(function () {
        _this.$notify({
          title: _this.translate.reportCreated
        });
        _this.$emit('saved');
      });
    },
    onReportShare: function onReportShare() {
      this.$notify({
        title: this.translate.reportShared
      });
    },
    onReportDelete: function onReportDelete() {
      this.$notify({
        title: this.translate.theReportWasSuccessfullyDeleted
      });
    },
    prepareSaveData: function prepareSaveData(type, title, description) {
      var settings = JSON.parse(localStorage.getItem(this.stateLocal));
      var additionalFilterValues = this.prepareAdditionalFilters(settings);
      var result = {
        query: _objectSpread({
          type: type,
          title: title,
          comment: description,
          server: {
            endpoint: '/utils/reports'
          },
          filters: this.prepareFiltersData(settings),
          display: this.prepareDisplayData(settings),
          reportOptions: this.reportOptions
        }, Object.keys(additionalFilterValues).length ? {
          extraFilters: additionalFilterValues
        } : {})
      };
      if (this.riskProjectId) {
        result.query.riskProjectsId = this.riskProjectId;
      }
      if (this.complianceProjectsId) {
        result.query.complianceProjectsId = this.complianceProjectsId;
      }
      return result;
    },
    prepareDisplayData: function prepareDisplayData(settings) {
      var result = {
        type: 'datatable',
        columns: [],
        // Visible columns
        sorting: {},
        search: (settings === null || settings === void 0 ? void 0 : settings.search) || {}
      };
      if (settings && settings.columns) {
        settings.columns.forEach(function (column) {
          if (column.visible) {
            result.columns.push(column.key);
          }
          if (column.sort) {
            result.sorting[column.sort.index] = column.sort.dir;
          }
        });
      }
      return result;
    },
    prepareFiltersData: function prepareFiltersData(settings) {
      var result = {};
      if (settings && settings.columns) {
        settings.columns.forEach(function (column) {
          if (column.search) {
            result[column.key] = column.search;
          }
        });
      }
      return result;
    },
    prepareAdditionalFilters: function prepareAdditionalFilters(settings) {
      var _settings$columns;
      return (settings === null || settings === void 0 || (_settings$columns = settings.columns) === null || _settings$columns === void 0 ? void 0 : _settings$columns.reduce(function (acc, column) {
        if (column.additionalFieldValue !== undefined) {
          acc[column.key] = column.additionalFieldValue;
        }
        return acc;
      }, {})) || {};
    }
  }
};