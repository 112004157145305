function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import { getSecurityGroups as _getSecurityGroups, updateSecurityGroup as _updateSecurityGroup, deleteSecurityGroup } from '@/api/compliance/admin';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import TabList from '@/components/Atoms/TabList';
import SecurityGroupProjects from '@/components/Molecules/Compliance/SecurityGroupProjects/SecurityGroupProjects';
import AdSettings from '@/components/Organisms/Compliance/Admin/AdSettings';
import SecurityGroupsCreationModal from '@/components/Organisms/Compliance/Admin/SecurityGroups/SecurityGroupsCreationModal';
import SecurityGroupsModal from '@/components/Organisms/Compliance/Admin/SecurityGroups/SecurityGroupsModal';
import UserSynchronisation from '@/components/Organisms/Compliance/Admin/UserSynchronisation';
import InputField from '@/components/Atoms/Inputs/InputField';
import { columns, adminLevels, accessLevels, accessLevelsBase, accessLevelsCompanies } from '@/constants/compliance/SecurityGroups';
import { typesAccessProjects } from '@/constants/compliance/TypesAccessProjects';
export default {
  name: 'SecurityGroups',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    SecurityGroupsCreationModal: SecurityGroupsCreationModal,
    SecurityGroupsModal: SecurityGroupsModal,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    SecurityGroupProjects: SecurityGroupProjects,
    AdSettings: AdSettings,
    FeatherIcon: FeatherIcon,
    Checkboxes: Checkboxes,
    TabList: TabList,
    UserSynchronisation: UserSynchronisation,
    InputField: InputField
  },
  data: function data() {
    return {
      adminLevels: adminLevels(),
      accessLevels: accessLevels,
      accessLevelsBase: accessLevelsBase,
      accessLevelsCompanies: accessLevelsCompanies,
      cellClasses: 'w-7/100 mr-1',
      isLoading: true,
      groups: [],
      isModalShow: false,
      modalData: {},
      currentTab: 'access',
      initialClientSecret: null,
      pageIsLoaded: false,
      showCreationModal: false,
      translate: getTranslate['SecurityGroups'](),
      typesAccessProjects: typesAccessProjects
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isUserSynchronisationEnabled: function isUserSynchronisationEnabled() {
      return this.getSettingValue('feature.user_sync.enabled');
    },
    generatedColumns: function generatedColumns() {
      return columns();
    },
    columns: function columns() {
      return [].concat(_toConsumableArray(this.generatedColumns), [this.translate.delete]);
    },
    tabs: function tabs() {
      return [{
        name: 'Access',
        slotName: 'access'
      }, {
        name: this.translate.userSynchronisation,
        slotName: 'microsoftGraph'
      }];
    },
    credentialsValid: function credentialsValid() {
      return !!this.getSettingValue('app.oidc.tenant_id') && !!this.getSettingValue('app.oidc.client_id') && !!this.initialClientSecret;
    }
  }),
  mounted: function mounted() {
    var _this = this;
    this.getSecurityGroups().finally(function () {
      _this.pageIsLoaded = true;
    });
  },
  methods: {
    getSecurityGroups: function getSecurityGroups(skipSpinner) {
      var _this2 = this;
      if (!skipSpinner) {
        this.isLoading = true;
      }
      return _getSecurityGroups().then(function (_ref) {
        var groups = _ref.groups,
          _ref$settings = _ref.settings,
          settings = _ref$settings === void 0 ? {} : _ref$settings;
        _this2.groups = _this2.formatSecurityGroups(groups);
        _this2.initialClientSecret = settings['app.oidc.client_secret'] || '';
      }).finally(function () {
        return _this2.isLoading = false;
      });
    },
    updateSecurityGroup: function updateSecurityGroup(group) {
      return _updateSecurityGroup(group.id, group);
    },
    getNumber: function getNumber(value) {
      return value ? value : 0;
    },
    formatSecurityGroup: function formatSecurityGroup(group) {
      var groupFormatted = {
        id: group.id,
        name: group.name,
        objectId: group.objectId,
        levelAdmin: this.getNumber(group.levelAdmin),
        levelControls: this.getNumber(group.levelControls),
        levelExecution: this.getNumber(group.levelExecution),
        levelIncidents: this.getNumber(group.levelIncidents),
        levelRisk: this.getNumber(group.levelRisk),
        levelContracts: this.getNumber(group.levelContracts),
        levelCompany: this.getNumber(group.levelCompany),
        levelInformationAssets: this.getNumber(group.levelInformationAssets),
        levelProcessLibrary: this.getNumber(group.levelProcessLibrary),
        lockedFields: !!group.lockedFields,
        complianceProjects: [],
        riskProjects: [],
        updated: false,
        isProjectsShown: false
      };
      group.projects.forEach(function (project) {
        if (project.complianceProjectsId) {
          groupFormatted.complianceProjects.push(Object.assign({}, project));
        } else if (project.riskProjectsId) {
          groupFormatted.riskProjects.push(Object.assign({}, project));
        }
      });
      return groupFormatted;
    },
    formatSecurityGroups: function formatSecurityGroups(groups) {
      var _this3 = this;
      return groups.map(function (group) {
        return _this3.formatSecurityGroup(group);
      });
    },
    handleSubmitModal: function handleSubmitModal(groupId, projectsType, data) {
      var _this4 = this;
      this.isModalShow = false;
      this.isLoading = true;
      var group = this.groups.find(function (group) {
        return group.id === groupId;
      });
      var groupUpdated = this.unformateGroup(group);
      if (!data.id) {
        if (projectsType === typesAccessProjects.compliance) {
          groupUpdated.complianceProjects.push(data);
        } else if (projectsType === typesAccessProjects.risk) {
          groupUpdated.riskProjects.push(data);
        }
      } else {
        if (projectsType === typesAccessProjects.compliance) {
          groupUpdated.complianceProjects.map(function (project) {
            if (data.id === project.id) {
              project.accessLevel = data.accessLevel;
              project.projectsId = data.projectId;
            }
            return project;
          });
        } else if (projectsType === typesAccessProjects.risk) {
          groupUpdated.riskProjects.map(function (project) {
            if (data.id === project.id) {
              project.accessLevel = data.accessLevel;
              project.projectsId = data.projectId;
            }
            return project;
          });
        }
      }
      this.updateSecurityGroup(groupUpdated).then(function () {
        _this4.modalData = {};
        return _this4.getSecurityGroups();
      });
    },
    getProjectShowTitle: function getProjectShowTitle(isShow) {
      return isShow ? this.translate.hideProjects : this.translate.showProjects;
    },
    getUpdatedGroups: function getUpdatedGroups() {
      var updatedGroups = [];
      this.groups.forEach(function (group) {
        if (group.updated) {
          var updatedGroup = Object.assign({}, group, {
            lockedFields: group.lockedFields ? 1 : 0
          });
          delete updatedGroup.updated;
          delete updatedGroup.isProjectsShown;
          updatedGroups.push(updatedGroup);
        }
      });
      return updatedGroups;
    },
    update: function update() {
      var _this5 = this;
      var updatedGroups = this.getUpdatedGroups();
      var promises = updatedGroups.map(function (group) {
        return _this5.updateSecurityGroup(group);
      });
      this.isLoading = true;
      return Promise.all(promises).then(function () {
        _this5.groups.map(function (group) {
          return group.updated = false;
        });
        _this5.isLoading = false;
      });
    },
    updateField: function updateField(groupId, type, newValue) {
      var group = this.groups.find(function (g) {
        return g.id === groupId;
      });
      group[type] = newValue;
      group.updated = true;
    },
    openModal: function openModal(_ref2) {
      var projectId = _ref2.projectId,
        securitygroupId = _ref2.securitygroupId,
        projectAccessId = _ref2.projectAccessId,
        accessLevel = _ref2.accessLevel,
        type = _ref2.type;
      this.modalData = {
        type: type,
        projectId: projectId,
        accessLevel: accessLevel,
        securitygroupId: securitygroupId,
        projectAccessId: projectAccessId
      };
      this.isModalShow = true;
    },
    unformateGroup: function unformateGroup(group) {
      var groupUnformatted = {};
      Object.assign(groupUnformatted, group, {
        lockedFields: group.lockedFields ? 1 : 0,
        complianceProjects: group.complianceProjects.map(function (project) {
          return Object.assign({}, project);
        }),
        riskProjects: group.riskProjects.map(function (project) {
          return Object.assign({}, project);
        })
      });
      delete groupUnformatted.updated;
      delete groupUnformatted.isProjectsShown;
      return groupUnformatted;
    },
    deleteGroup: function deleteGroup(id) {
      var _this6 = this;
      this.$confirm('', this.translate.areYouSureYouWantToDeleteThisSecurityGroup, function (res) {
        return res && _this6.onDeleteConfirm(id);
      });
    },
    onDeleteConfirm: function onDeleteConfirm(id) {
      var _this7 = this;
      return deleteSecurityGroup(id).then(function () {
        _this7.groups = _this7.groups.filter(function (item) {
          return item.id !== id;
        });
        _this7.$notify({
          title: _this7.translate.securityGroupHasBeenDeleted
        });
      }).catch(function (error) {
        return _this7.$notify({
          title: _this7.$trans(error.response.error),
          type: 'error'
        });
      });
    },
    handleOpenModal: function handleOpenModal(data) {
      this.openModal({
        projectId: data.projectId,
        securitygroupId: data.securityGroupId,
        accessLevel: data.accessLevel,
        projectAccessId: data.projectAccessId,
        type: data.typeAccessProjects
      });
    },
    handleGroupCreation: function handleGroupCreation() {
      this.showCreationModal = false;
      this.getSecurityGroups();
    }
  }
};