import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "question-selector w-full p-1"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["title"];
var _hoisted_4 = ["onClick"];
var _hoisted_5 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.expandedQuestions, function (question, key) {
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: "relative"
    }, [question.hasHeadline ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(["headline-wrapper flex flex-row ml-1 items-center cursor-pointer", key === 0 ? 'mt-1' : 'mt-4']),
      onClick: function onClick($event) {
        return $options.handleClosedHeadlines(question.headline);
      }
    }, [_createElementVNode("span", {
      title: question.headline,
      class: "ml-2 glob-h2 text-rm-text"
    }, _toDisplayString(question.headline), 9 /* TEXT, PROPS */, _hoisted_3), _createVNode(_component_feather_icon, {
      class: "mx-2",
      icon: $setup.closedHeadlines.includes(question.headline) ? 'chevron-down' : 'chevron-up'
    }, null, 8 /* PROPS */, ["icon"])], 10 /* CLASS, PROPS */, _hoisted_2)) : _createCommentVNode("v-if", true), !$setup.closedHeadlines.includes(question.headline) ? (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass(['truncate py-1 mb-1 px-4 glob-h3', !question.answeredDependencies ? 'text-rm-grey' : 'text-rm-text', {
        answered: question.isAnswered
      }, {
        'cursor-pointer': question.isClickable
      }]),
      onClick: function onClick($event) {
        return $options.changeQuestion(question.id);
      }
    }, [question.isAnswered ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      class: "float-left pt-1",
      icon: "check"
    })) : _createCommentVNode("v-if", true), _createElementVNode("span", {
      title: "".concat(question.questionnumber, ". ").concat(question.question)
    }, _toDisplayString(question.questionnumber) + ". " + _toDisplayString(question.question), 9 /* TEXT, PROPS */, _hoisted_5)], 10 /* CLASS, PROPS */, _hoisted_4)) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))]);
}