function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 2,
  class: "pt-4"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 0,
  class: "mt-7"
};
var _hoisted_4 = {
  class: "flex sticky z-1 top-80px bg-gray-25"
};
var _hoisted_5 = {
  class: "flex"
};
var _hoisted_6 = {
  class: "w-7/100"
};
var _hoisted_7 = {
  class: "flex my-1"
};
var _hoisted_8 = ["onClick"];
var _hoisted_9 = ["onClick"];
var _hoisted_10 = ["onClick"];
var _hoisted_11 = {
  key: 0
};
var _hoisted_12 = {
  class: "sticky bottom-0 bg-gray-25"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_security_groups_modal = _resolveComponent("security-groups-modal");
  var _component_security_groups_creation_modal = _resolveComponent("security-groups-creation-modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_ad_settings = _resolveComponent("ad-settings");
  var _component_tab_list = _resolveComponent("tab-list");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_security_group_projects = _resolveComponent("security-group-projects");
  var _component_user_synchronisation = _resolveComponent("user-synchronisation");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, {
    class: "w-full"
  }, {
    default: _withCtx(function () {
      return [$data.isModalShow ? (_openBlock(), _createBlock(_component_security_groups_modal, {
        key: 0,
        "type-access-projects": $data.modalData.type,
        "project-access-id": $data.modalData.projectAccessId,
        "project-id": $data.modalData.projectId,
        "security-group-id": $data.modalData.securitygroupId,
        "access-level": $data.modalData.accessLevel,
        onSubmit: $options.handleSubmitModal,
        onClose: _cache[0] || (_cache[0] = function ($event) {
          return $data.isModalShow = false;
        })
      }, null, 8 /* PROPS */, ["type-access-projects", "project-access-id", "project-id", "security-group-id", "access-level", "onSubmit"])) : _createCommentVNode("v-if", true), $data.showCreationModal ? (_openBlock(), _createBlock(_component_security_groups_creation_modal, {
        key: 1,
        onCreate: $options.handleGroupCreation,
        onDismiss: _cache[1] || (_cache[1] = function ($event) {
          return $data.showCreationModal = false;
        })
      }, null, 8 /* PROPS */, ["onCreate"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        title: $data.translate.singleSignonSettings
      }, null, 8 /* PROPS */, ["title"]), $data.pageIsLoaded ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 2
      }, [_createVNode(_component_ad_settings, {
        "initial-client-secret": $data.initialClientSecret,
        "onUpdated:clientSecret": _cache[2] || (_cache[2] = function ($event) {
          return $options.getSecurityGroups(true);
        })
      }, null, 8 /* PROPS */, ["initial-client-secret"]), $options.isUserSynchronisationEnabled ? (_openBlock(), _createBlock(_component_tab_list, {
        key: 0,
        tabs: $options.tabs,
        "background-class": "bg-zinc-50",
        class: "flex mt-4",
        onUpdate: _cache[3] || (_cache[3] = function ($event) {
          return $data.currentTab = $event;
        })
      }, null, 8 /* PROPS */, ["tabs"])) : _createCommentVNode("v-if", true), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 1
      })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [$data.currentTab === $options.tabs[0].slotName || !$options.isUserSynchronisationEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_button, {
        text: $data.translate.create,
        type: "save",
        onClick: _cache[4] || (_cache[4] = function ($event) {
          return $data.showCreationModal = true;
        })
      }, null, 8 /* PROPS */, ["text"]), !$data.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.generatedColumns, function (column) {
        return _openBlock(), _createBlock(_component_risma_title, {
          key: column,
          title: column,
          type: "medium",
          class: "w-7/100 first:w-1/5 mr-1 last:mr-0"
        }, null, 8 /* PROPS */, ["title"]);
      }), 128 /* KEYED_FRAGMENT */)), _cache[5] || (_cache[5] = _createElementVNode("span", {
        class: "w-7/100"
      }, null, -1 /* HOISTED */))]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.groups, function (group) {
        var _group$complianceProj, _group$riskProjects;
        return _openBlock(), _createElementBlock("div", {
          key: group.id,
          class: "mt-5"
        }, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_input_field, {
          "model-value": group.objectId,
          type: "text",
          class: "w-1/5 mr-1",
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'objectId', $event);
          }
        }, null, 8 /* PROPS */, ["model-value", "onUpdate:modelValue"]), _createVNode(_component_input_field, {
          "model-value": group.name,
          type: "text",
          class: _normalizeClass($data.cellClasses),
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'name', $event);
          }
        }, null, 8 /* PROPS */, ["model-value", "class", "onUpdate:modelValue"]), _createElementVNode("div", {
          class: _normalizeClass($data.cellClasses)
        }, [_createVNode(_component_single_select, {
          options: $data.adminLevels,
          "model-value": group.levelAdmin,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelAdmin', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])], 2 /* CLASS */), _createElementVNode("div", {
          class: _normalizeClass($data.cellClasses)
        }, [_createVNode(_component_single_select, {
          options: $data.accessLevels,
          "model-value": group.levelControls,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelControls', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])], 2 /* CLASS */), _createElementVNode("div", {
          class: _normalizeClass($data.cellClasses)
        }, [_createVNode(_component_single_select, {
          options: $data.accessLevels,
          "model-value": group.levelExecution,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelExecution', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])], 2 /* CLASS */), _createElementVNode("div", {
          class: _normalizeClass($data.cellClasses)
        }, [_createVNode(_component_single_select, {
          options: $data.accessLevels,
          "model-value": group.levelIncidents,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelIncidents', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])], 2 /* CLASS */), _createElementVNode("div", {
          class: _normalizeClass($data.cellClasses)
        }, [_createVNode(_component_single_select, {
          options: $data.accessLevels,
          "model-value": group.levelRisk,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelRisk', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])], 2 /* CLASS */), _createElementVNode("div", {
          class: _normalizeClass($data.cellClasses)
        }, [_createVNode(_component_single_select, {
          options: $data.accessLevels,
          "model-value": group.levelContracts,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelContracts', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])], 2 /* CLASS */), _createElementVNode("div", {
          class: _normalizeClass($data.cellClasses)
        }, [_createVNode(_component_single_select, {
          options: $data.accessLevelsCompanies,
          "model-value": group.levelCompany,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelCompany', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])], 2 /* CLASS */), _createElementVNode("div", {
          class: _normalizeClass($data.cellClasses)
        }, [_createVNode(_component_single_select, {
          options: $data.accessLevelsBase,
          "model-value": group.levelInformationAssets,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelInformationAssets', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])], 2 /* CLASS */), _createElementVNode("div", {
          class: _normalizeClass($data.cellClasses)
        }, [_createVNode(_component_single_select, {
          options: $data.accessLevels,
          "model-value": group.levelProcessLibrary,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.updateField(group.id, 'levelProcessLibrary', $event);
          }
        }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])], 2 /* CLASS */), _createElementVNode("div", {
          class: _normalizeClass($data.cellClasses)
        }, [_createVNode(_component_checkboxes, {
          "checkbox-size": 20,
          options: !!group.lockedFields,
          "center-checkbox": true,
          onUpdated: function onUpdated($event) {
            return $options.updateField(group.id, 'lockedFields', $event.isChecked);
          }
        }, null, 8 /* PROPS */, ["options", "onUpdated"])], 2 /* CLASS */), _createElementVNode("div", _hoisted_6, [_createVNode(_component_feather_icon, {
          icon: "x",
          class: "cursor-pointer text-red-50 h-10 ml-4",
          onClick: function onClick($event) {
            return $options.deleteGroup(group.id);
          }
        }, null, 8 /* PROPS */, ["onClick"])])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("a", {
          href: "#",
          class: "mr-4",
          onClick: _withModifiers(function ($event) {
            return $options.openModal({
              securitygroupId: group.id,
              type: $data.typesAccessProjects.compliance
            });
          }, ["stop", "prevent"])
        }, _toDisplayString($data.translate.createNewComplianceAccess), 9 /* TEXT, PROPS */, _hoisted_8), _createElementVNode("a", {
          href: "#",
          class: "mr-4",
          onClick: _withModifiers(function ($event) {
            return $options.openModal({
              securitygroupId: group.id,
              type: $data.typesAccessProjects.risk
            });
          }, ["stop", "prevent"])
        }, _toDisplayString($data.translate.createNewRiskAccess), 9 /* TEXT, PROPS */, _hoisted_9), (_group$complianceProj = group.complianceProjects) !== null && _group$complianceProj !== void 0 && _group$complianceProj.length || (_group$riskProjects = group.riskProjects) !== null && _group$riskProjects !== void 0 && _group$riskProjects.length ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "#",
          class: "mr-4",
          onClick: _withModifiers(function ($event) {
            return group.isProjectsShown = !group.isProjectsShown;
          }, ["stop", "prevent"])
        }, _toDisplayString($options.getProjectShowTitle(group.isProjectsShown)), 9 /* TEXT, PROPS */, _hoisted_10)) : _createCommentVNode("v-if", true)]), group.isProjectsShown ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_security_group_projects, {
          projects: [].concat(_toConsumableArray(group.complianceProjects), _toConsumableArray(group.riskProjects)),
          onSubmit: $options.handleOpenModal
        }, null, 8 /* PROPS */, ["projects", "onSubmit"])])) : _createCommentVNode("v-if", true)]);
      }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_button, {
        text: "".concat($data.translate.update),
        type: "save",
        class: "ml-auto",
        onClick: $options.update
      }, null, 8 /* PROPS */, ["text", "onClick"])])])) : _createCommentVNode("v-if", true)])) : (_openBlock(), _createBlock(_component_user_synchronisation, {
        key: 1,
        "credentials-valid": $options.credentialsValid
      }, null, 8 /* PROPS */, ["credentials-valid"]))]))], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 3
      }))];
    }),
    _: 1 /* STABLE */
  });
}