import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$errorMessage;
  var _component_risma_select = _resolveComponent("risma-select");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_select, {
    options: $props.data[$data.DataFlowFields.DATA_TYPE_OPTIONS],
    "selected-options": $props.selectedOptions[$data.DataFlowFields.CURRENT_TYPES_SELECTED],
    "label-key": "text",
    placeholder: $data.translate.chooseDataTypes,
    "error-message": ((_$props$errorMessage = $props.errorMessage) === null || _$props$errorMessage === void 0 ? void 0 : _$props$errorMessage.text) || '',
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('update:selected', {
        value: $event,
        field: $data.DataFlowFields.CURRENT_TYPES_SELECTED
      });
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "error-message"])]);
}