function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import * as ControlsApi from '@/api/controls/controls';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import UserSelector from '@/components/Molecules/UserSelector';
import OrganisationSelector from '@/components/Molecules/OrganisationSelector';
import Notification from '@/components/Molecules/Notification';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import Modal from '@/components/Molecules/Modal/Modal';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import ControlDetailsAssociations from '@/components/Molecules/Controls/ControlDetailsAssociations';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import AttributesList from '@/components/Atoms/AttributesList';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { getParsedCfValues } from '@/utils/CustomFields';
import { getAssociationName } from '@/utils/controls/associations';
import { UserLevels } from '@/constants/UserLevels';
export default {
  name: 'ControlCreationModal',
  introduction: 'ControlCreationModal multiselect popup',
  description: 'ControlCreationModal with  selector and list of selected items',
  token: '<control-creation-modal />',
  components: {
    Modal: Modal,
    InputField: InputField,
    SingleSelect: SingleSelect,
    OrganisationSelector: OrganisationSelector,
    UserSelector: UserSelector,
    Notification: Notification,
    TextBox: TextBox,
    FrameworksSelector: FrameworksSelector,
    FeatherIcon: FeatherIcon,
    ControlDetailsAssociations: ControlDetailsAssociations,
    AttributesList: AttributesList,
    RismaButton: RismaButton
  },
  props: {
    isMotherMode: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is Mother control'
    },
    motherControl: {
      type: Object,
      required: false,
      default: null,
      note: 'Mother control data object.'
    },
    preset: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      },
      note: 'Preset fields from activity'
    },
    showAllFields: {
      required: false,
      type: Boolean,
      default: false,
      note: 'If to show all available fields'
    },
    motherControlPreselectedMatch: {
      required: false,
      type: String,
      default: '',
      note: 'String for checking preselected mother control'
    },
    showAssociations: {
      required: false,
      type: Boolean,
      default: false
    },
    attributes: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['close', 'update'],
  data: function data() {
    return {
      data: {
        title: '',
        activityTypeId: null,
        description: '',
        organisations: [],
        solutions: [],
        responsible: [],
        accounts: [],
        risks: [],
        processes: []
      },
      errorFields: {
        TITLE: 'title',
        ORGANISATION: 'organisation',
        RESPONSIBLE: 'responsible'
      },
      associationsTempValue: {},
      showAssociationsModal: false,
      errorMessages: {},
      backendErrorMessage: '',
      motherControls: [],
      motherControlChosen: null,
      isRismaSelectOpen: false,
      translate: getTranslate['ControlCreationModal'](),
      isCreating: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useActivityTypesStore, ['controlActivityTypes'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useFrameworksStore, ['enabledFrameworks'])), {}, {
    controlUsers: function controlUsers() {
      return this.users.filter(function (user) {
        return user.level_controls > UserLevels.NONE || user.is_admin || user.level_admin;
      });
    },
    titleModal: function titleModal() {
      if (this.motherControlData) {
        return this.translate.createDaughterControl;
      }
      if (this.isMotherMode) {
        return this.translate.createMotherControl;
      }
      return this.translate.createControl;
    },
    activityTypeOptions: function activityTypeOptions() {
      return this.controlActivityTypes.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          value: item.id
        });
      });
    },
    montherControlsOptions: function montherControlsOptions() {
      return this.motherControls.map(function (item) {
        return {
          value: item.cpath,
          label: item.cpath + ' - ' + item.title
        };
      });
    },
    motherControlData: function motherControlData() {
      var _this = this;
      if (!this.motherControlChosen) return null;
      return this.motherControls.find(function (mc) {
        return mc.cpath === _this.motherControlChosen;
      }) || null;
    },
    formattedData: function formattedData() {
      var baseData = {
        title: this.data.title,
        activityTypeId: this.data.activityTypeId,
        solutionIds: this.data.solutions.map(function (item) {
          return item.id;
        }),
        description: this.data.description
      };
      if (this.isMotherMode) {
        return _objectSpread(_objectSpread({}, baseData), {}, {
          isMothercontrol: 1
        });
      }
      if (this.motherControlData) {
        baseData = _objectSpread(_objectSpread({}, baseData), this.inheritedData);
      } else {
        baseData = _objectSpread(_objectSpread({}, baseData), {}, {
          accounts: this.data.accounts.map(function (item) {
            return item.id;
          }),
          processes: this.data.processes.map(function (item) {
            return item.id;
          }),
          risks: this.data.risks.map(function (item) {
            return item.id;
          })
        });
      }
      return _objectSpread(_objectSpread({}, baseData), {}, {
        organisationIds: this.data.organisations.map(function (item) {
          return item.id;
        }),
        userIds: {
          responsible: this.data.responsible.map(function (item) {
            return item.id;
          })
        },
        isMothercontrol: 0
      });
    },
    inheritedData: function inheritedData() {
      var data = {};
      var customFieldValues = this.motherControlData.customFieldValues;
      var isActivityTypeMatched = this.motherControlData.activityTypeId === this.data.activityTypeId;
      if (isActivityTypeMatched && customFieldValues && customFieldValues.length) {
        data.customFieldValues = getParsedCfValues(this.motherControlData);
      }
      return _objectSpread(_objectSpread({}, data), {}, {
        mothercontrolId: this.motherControlData.cpath,
        accounts: this.motherControlData.accounts.map(function (el) {
          return el.id;
        }),
        processes: this.motherControlData.processes.map(function (el) {
          return el.id;
        }),
        risks: this.motherControlData.risks.map(function (el) {
          return el.id;
        })
      });
    },
    creationMethod: function creationMethod() {
      return this.isMotherMode ? 'createNewMotherControl' : 'createNewControl';
    },
    preselectedActivityTypeId: function preselectedActivityTypeId() {
      var setting = this.isMotherMode ? 'feature.mother_controls_default_activity_type_id' : 'feature.controls_default_activity_type_id';
      return this.getSettingValue(setting);
    },
    accountTitle: function accountTitle() {
      return getAssociationName(this.getSettingValue('risma.account_to_name'), 'Accounts');
    },
    processesTitle: function processesTitle() {
      return getAssociationName(this.getSettingValue('risma.process_to_name'), 'Processes');
    },
    risksTitle: function risksTitle() {
      return getAssociationName(this.getSettingValue('risma.risk_to_name'), 'Control risks');
    },
    selectedAssociations: function selectedAssociations() {
      return {
        accounts: this.data.accounts,
        processes: this.data.processes,
        risks: this.data.risks
      };
    },
    associationsList: function associationsList() {
      var result = {};
      if (this.motherControlData) {
        var _this$motherControlDa, _this$motherControlDa2, _this$motherControlDa3;
        result = _defineProperty(_defineProperty(_defineProperty({}, this.accountTitle, (_this$motherControlDa = this.motherControlData.accounts) === null || _this$motherControlDa === void 0 ? void 0 : _this$motherControlDa.map(function (item) {
          return item.title;
        })), this.processesTitle, (_this$motherControlDa2 = this.motherControlData.processes) === null || _this$motherControlDa2 === void 0 ? void 0 : _this$motherControlDa2.map(function (item) {
          return item.title;
        })), this.risksTitle, (_this$motherControlDa3 = this.motherControlData.risks) === null || _this$motherControlDa3 === void 0 ? void 0 : _this$motherControlDa3.map(function (item) {
          return item.title;
        }));
      } else {
        var _this$data$accounts, _this$data$processes, _this$data$risks;
        result = _defineProperty(_defineProperty(_defineProperty({}, this.accountTitle, (_this$data$accounts = this.data.accounts) === null || _this$data$accounts === void 0 ? void 0 : _this$data$accounts.map(function (item) {
          return item.title;
        })), this.processesTitle, (_this$data$processes = this.data.processes) === null || _this$data$processes === void 0 ? void 0 : _this$data$processes.map(function (item) {
          return item.title;
        })), this.risksTitle, (_this$data$risks = this.data.risks) === null || _this$data$risks === void 0 ? void 0 : _this$data$risks.map(function (item) {
          return item.title;
        }));
      }
      Object.keys(result).forEach(function (key) {
        var _result$key;
        if (!((_result$key = result[key]) !== null && _result$key !== void 0 && _result$key.length)) {
          delete result[key];
        }
      });
      return result;
    }
  }),
  beforeMount: function beforeMount() {
    this.setPreset();
    this.assignMotherControl();
    this.getMotherControls();
  },
  methods: {
    assignMotherControl: function assignMotherControl() {
      if (!this.motherControl) return;
      this.motherControlChosen = this.motherControl.cpath;
      if (!this.showAllFields) {
        this.motherControls = [this.motherControl];
      }
    },
    getMotherControls: function getMotherControls() {
      var _this2 = this;
      if (!this.showAllFields) return;
      return ControlsApi.getAllMotherControls().then(function (_ref) {
        var list = _ref.list;
        _this2.motherControls = list;
        _this2.checkMatchingMotherControl();
      });
    },
    checkMatchingMotherControl: function checkMatchingMotherControl() {
      var _this3 = this;
      var motherControl = this.motherControls.find(function (control) {
        return control.title.startsWith(_this3.motherControlPreselectedMatch);
      });
      if (motherControl) {
        this.motherControlChosen = motherControl.cpath;
      }
    },
    setPreset: function setPreset() {
      var _this$preset$solution,
        _this4 = this;
      if (this.preset.title) this.propertyChange(this.preset.title, 'title');
      if (this.preset.description) this.propertyChange(this.preset.description, 'description');
      if ((_this$preset$solution = this.preset.solutionIds) !== null && _this$preset$solution !== void 0 && _this$preset$solution.length) {
        var frameworks = this.enabledFrameworks.filter(function (fk) {
          return _this4.preset.solutionIds.includes(fk.id);
        });
        this.propertyChange(frameworks, 'solutions');
      }
      if (this.preselectedActivityTypeId) this.propertyChange(this.preselectedActivityTypeId, 'activityTypeId');
    },
    onAssociationsModalSubmit: function onAssociationsModalSubmit() {
      var _this5 = this;
      if (Object.keys(this.associationsTempValue)) {
        Object.entries(this.associationsTempValue).forEach(function (_ref2) {
          var _ref3 = _slicedToArray(_ref2, 2),
            key = _ref3[0],
            value = _ref3[1];
          _this5.propertyChange(value, key);
        });
      }
      this.closeAssociationsModal();
    },
    closeAssociationsModal: function closeAssociationsModal() {
      this.associationsTempValue = {};
      this.showAssociationsModal = false;
    },
    propertyChange: function propertyChange(value, property) {
      this.data[property] = value;
    },
    submitModal: function submitModal() {
      if (this.handleValidation(this.data)) {
        this.handleApiRequest();
      }
    },
    handleValidation: function handleValidation(data) {
      this.errorMessages = {};
      if (!data.title || !data.title.trim()) {
        this.errorMessages[this.errorFields.TITLE] = this.translate.titleCannotBeEmpty;
      }
      if (!this.isMotherMode && (!data.responsible || !data.responsible.length) && !this.getSettingValue('feature.controls_allow_no_owners')) {
        this.errorMessages[this.errorFields.RESPONSIBLE] = this.translate.responsibleIsRequired;
      }
      if (!this.isMotherMode && (!data.organisations || !data.organisations.length)) {
        this.errorMessages[this.errorFields.ORGANISATION] = this.translate.atLeastOneOrganisationIsRequired;
      }
      return !Object.keys(this.errorMessages).length;
    },
    handleApiRequest: function handleApiRequest() {
      var _this6 = this;
      this.errorMessages = {};
      this.backendErrorMessage = '';
      this.isCreating = true;
      return this.createControl().finally(function () {
        return _this6.isCreating = false;
      });
    },
    setDescription: function setDescription(e) {
      this.propertyChange(e, 'description');
    },
    createControl: function createControl() {
      var _this7 = this;
      return ControlsApi[this.creationMethod](this.formattedData).then(function (newControl) {
        newControl.id = +newControl.id;
        newControl.isMothercontrol = +newControl.isMothercontrol;
        _this7.$emit('update', newControl);
        _this7.$emit('close');
      }).catch(function (e) {
        _this7.backendErrorMessage = e.response.error;
      });
    }
  }
};