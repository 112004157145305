import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "glob-h2 mb-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_radio_buttons = _resolveComponent("radio-buttons");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", null, [$props.title ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($props.title), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createVNode(_component_static_table, {
    "dataset-table": $props.datasetTable,
    columns: $props.columns,
    "columns-styles": $data.columnsStyles,
    theme: $data.theme
  }, {
    signingParty: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createVNode(_component_checkboxes, {
        "model-value": slotData.value,
        "checkbox-size": 20,
        options: false,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $options.handleCheckboxUpdate('signingParty', slotData.index, $event);
        }
      }, null, 8 /* PROPS */, ["model-value", "onUpdate:modelValue"])];
    }),
    payingParty: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [_createVNode(_component_radio_buttons, {
        modelValue: slotData.value,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return slotData.value = $event;
        },
        options: [{
          value: true
        }],
        onUpdated: function onUpdated($event) {
          return $options.handleRadioBtnUpdate('payingParty', slotData.index, $event);
        }
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onUpdated"])];
    }),
    usingParty: _withCtx(function (_ref3) {
      var slotData = _ref3.slotData;
      return [_createVNode(_component_checkboxes, {
        "model-value": slotData.value,
        "checkbox-size": 20,
        options: false,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $options.handleCheckboxUpdate('usingParty', slotData.index, $event);
        }
      }, null, 8 /* PROPS */, ["model-value", "onUpdate:modelValue"])];
    }),
    checkbox: _withCtx(function (_ref4) {
      var slotData = _ref4.slotData;
      return [_createVNode(_component_checkboxes, {
        "model-value": slotData.value,
        "checkbox-size": 20,
        options: false,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $options.handleCheckboxUpdate('checkbox', slotData.index, $event);
        }
      }, null, 8 /* PROPS */, ["model-value", "onUpdate:modelValue"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-table", "columns", "columns-styles", "theme"])]);
}