import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
export default {
  name: '',
  components: {
    SingleSelect: SingleSelect
  },
  data: function data() {
    return {
      SingleSelectOptions: [{
        value: '1',
        label: 'Hello'
      }, {
        value: '2',
        label: 'To'
      }, {
        value: '3',
        label: 'You'
      }, {
        value: '4',
        label: 'Myself'
      }, {
        value: '5',
        label: 'Say'
      }, {
        value: '6',
        label: 'My'
      }, {
        value: '7',
        label: 'Little'
      }, {
        value: '9',
        label: 'Friend'
      }, {
        value: '10',
        label: 'Friend2'
      }, {
        value: '11',
        label: 'Friend3'
      }, {
        value: '12',
        label: 'Friend4'
      }, {
        value: '13',
        label: 'Friend5'
      }, {
        value: '14',
        label: 'Friend6'
      }, {
        value: '15',
        label: 'Friend7'
      }, {
        value: '16',
        label: 'Friend8'
      }, {
        value: '17',
        label: 'Friend9'
      }],
      SingleSelectValue: '3',
      SingleSelectValue2: '',
      SingleSelectValue3: '',
      SingleSelectValue4: '',
      SingleSelectValue5: '',
      SingleSelectValue6: '',
      SingleSelectOptions3: [{
        label: 'Vegetables',
        $isGroup: true
      }, {
        value: '1',
        label: 'Potato',
        groupName: 'Vegetable'
      }, {
        value: '2',
        label: 'Cucumber',
        groupName: 'Vegetable'
      }, {
        value: '3',
        label: 'Carrots',
        groupName: 'Vegetable'
      }, {
        label: 'Fruits',
        $isGroup: true
      }, {
        value: '6',
        label: 'Apple',
        groupName: 'Fruit'
      }, {
        value: '7',
        label: 'Banana ',
        groupName: 'Fruit'
      }, {
        value: '8',
        label: 'Apricot',
        groupName: 'Fruit'
      }],
      SingleSelectOptions4: [{
        label: 'Vegetables Vegetables Vegetables Vegetables Vegetables',
        $isGroup: true
      }, {
        value: '1',
        label: 'Potato',
        groupName: 'Vegetable'
      }, {
        value: '2',
        label: 'Cucumber',
        groupName: 'Vegetable'
      }, {
        value: '3',
        label: 'Carrots',
        groupName: 'Vegetable'
      }, {
        label: 'Fruits Fruits',
        $isGroup: true
      }, {
        value: '6',
        label: 'Apple',
        groupName: 'Fruit'
      }, {
        value: '7',
        label: 'Banana ',
        groupName: 'Fruit'
      }, {
        value: '8',
        label: 'Apricot',
        groupName: 'Fruit'
      }]
    };
  },
  computed: {
    longTextOptions: function longTextOptions() {
      var longText = '200 character edge case option 1, '.repeat(6);
      return [1, 2, 3].map(function (val) {
        return {
          value: val,
          label: longText
        };
      });
    }
  }
};