import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  class: "text-center"
};
var _hoisted_3 = {
  key: 4,
  class: "text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_questionnaire_selection = _resolveComponent("questionnaire-selection");
  var _component_question_selection = _resolveComponent("question-selection");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createBlock(_resolveDynamicComponent($options.isIA ? 'main-well' : 'div'), null, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.questionnaireReport
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
        title: $options.explanationText,
        type: "small"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_questionnaire_selection, {
        "initial-filters": $data.initialFilters,
        "is-i-a": $options.isIA,
        module: $props.module,
        onSelectionChanged: $options.selectionChanged,
        onAssetsSelected: $options.assetsSelected
      }, null, 8 /* PROPS */, ["initial-filters", "is-i-a", "module", "onSelectionChanged", "onAssetsSelected"]), $data.error ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h3", _hoisted_2, _toDisplayString($data.error), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $data.selectedQuestions.length > 0 || $data.selectedQuestionnaireId && !$data.loading ? (_openBlock(), _createBlock(_component_question_selection, {
        key: 1,
        ref: "questionSelection",
        questions: $data.questions,
        "is-gdpr": true,
        "is-answered-only": $data.isAnsweredOnly,
        "initial-selected-questions": $data.selectedQuestions,
        "onChecked:answered": $options.setAnsweredOnly,
        onQuestionsSelected: $options.questionsSelected
      }, null, 8 /* PROPS */, ["questions", "is-answered-only", "initial-selected-questions", "onChecked:answered", "onQuestionsSelected"])) : _createCommentVNode("v-if", true), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 2
      })) : _createCommentVNode("v-if", true), $data.selectedQuestions.length ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 3,
        text: $data.translate.createReport,
        type: "save",
        class: "ml-auto w-fit mb-4",
        onClick: $options.handleCreateReport
      }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true), $data.showReport && $data.noAvailableAssets ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
        title: $data.translate.nothingToShow,
        type: "big"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.theSelectedSearchCriteriaHaveNoResultsToDi), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $data.showReport && !$data.noAvailableAssets ? (_openBlock(), _createBlock(_resolveDynamicComponent($data.reportTable), {
        key: 5,
        mode: $data.selectedAssetType,
        "name-column-title": $options.nameColumnTitle,
        "state-local": $data.localStateName,
        rows: $data.rows,
        "report-type": $data.savedReportType,
        "report-options": $data.reportOptions,
        "table-view-on-load": $data.selectedTableView
      }, null, 8 /* PROPS */, ["mode", "name-column-title", "state-local", "rows", "report-type", "report-options", "table-view-on-load"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  });
}