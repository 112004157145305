function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
import { checkERMByProjectId } from '@/utils/risks/check';
import { RiskBaseUrls } from '@/constants/risks/RiskBaseUrls';
export default {
  name: 'RiskReportsEntry',
  description: 'Common page for risk2 reports',
  token: '<risk-reports-entry />',
  components: {
    LightTabItem: LightTabItem
  },
  props: {
    projectId: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      translate: getTranslate['RiskReportsEntry']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    hasAssessmentThreats: function hasAssessmentThreats() {
      return !!this.getSettingValue('feature.custom_risk_assessment_threats');
    },
    baseLink: function baseLink() {
      return "".concat(RiskBaseUrls.projects, "/").concat(this.projectId, "/reports/");
    },
    isERM: function isERM() {
      return checkERMByProjectId(this.projectId, this.currentUser);
    },
    tabList: function tabList() {
      var threatAssessments = this.hasAssessmentThreats ? [{
        url: "".concat(this.baseLink, "threatassessments"),
        name: this.translate.threatAssessments
      }] : [];
      return [{
        url: "".concat(this.baseLink, "risks"),
        name: this.translate.risks
      }, {
        url: "".concat(this.baseLink, "threats"),
        name: this.translate.threats
      }].concat(threatAssessments, [{
        url: "".concat(this.baseLink, "vulnerabilities"),
        name: this.translate.vulnerabilities
      }, {
        url: "".concat(this.baseLink, "questionnaire"),
        name: this.translate.questionnaire
      }]);
    }
  })
};