import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "tags-wrapper border border-solid border-gray-350 p-4 relative"
};
var _hoisted_2 = {
  class: "absolute left-6 -top-15px max-w-full-25"
};
var _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $props.title,
    type: "medium",
    class: _normalizeClass("mx-4 ".concat($props.backgroundClass, " px-4 "))
  }, null, 8 /* PROPS */, ["title", "class"])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.dataset, function (tag, index) {
    return _openBlock(), _createElementBlock("div", {
      key: "".concat($props.title, "-").concat(index),
      class: _normalizeClass([{
        'line-through': tag.withLineThrough
      }, "tag-item flex cursor-pointer items-center justify-between py-1 px-2 hover:bg-gray-125 rounded-md"])
    }, [_createElementVNode("div", {
      class: "flex items-center w-full max-w-full-30 pr-2",
      onClick: function onClick($event) {
        return $options.selectTag($event, tag);
      }
    }, [tag.trafficLight !== null && (typeof $props.hasTrafficLights === 'function' ? $props.hasTrafficLights(tag) : $props.hasTrafficLights) ? (_openBlock(), _createBlock(_component_traffic_light, {
      key: 0,
      color: tag.trafficLightColor,
      icon: tag.trafficLightIcon,
      class: "mr-2",
      size: "small"
    }, null, 8 /* PROPS */, ["color", "icon"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title_with_icon, {
      class: _normalizeClass(["hover:opacity-75 text-sm max-w-inherit", $options.checkSelectedTag(tag) ? 'font-bold' : '']),
      type: "small",
      "title-class": 'w-full' + (tag.trafficLightIcon === 'ban' ? ' line-through' : ''),
      icon: $options.icon,
      truncate: true,
      title: tag.fullTitle,
      "title-attribute": tag.fullTitle,
      "display-icon": tag.displayIcon
    }, null, 8 /* PROPS */, ["title-class", "icon", "class", "title", "title-attribute", "display-icon"])], 8 /* PROPS */, _hoisted_3), $props.hasDeleteOptions ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      icon: "x",
      class: "delete-btn ml-2 hover:opacity-50",
      onClick: function onClick($event) {
        return _ctx.$emit('delete', tag.id);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))]);
}