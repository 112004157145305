import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import ColorPicker from '@/components/Molecules/ColorPicker';
import { capitalize } from '@/utils';
import { MODULES } from '@/constants/modules';
export default {
  name: 'TrafficLightModal',
  components: {
    Modal: Modal,
    InputField: InputField,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    ColorPicker: ColorPicker
  },
  props: {
    modules: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['dismiss', 'create'],
  data: function data() {
    return {
      trafficLight: {
        legend: '',
        module: null,
        color: '#FFFFFF',
        textColor: '#FFFFFF'
      },
      errorMessages: {},
      translate: getTranslate['TrafficLightModal']()
    };
  },
  computed: {
    moduleOptions: function moduleOptions() {
      var _this = this;
      return this.modules.filter(function (item) {
        return item !== 'default';
      }).map(function (item) {
        return {
          label: _this.getTrafficLightName(item),
          value: item
        };
      });
    },
    isGap: function isGap() {
      return this.trafficLight.module === MODULES.COMPLIANCE_GAP;
    }
  },
  methods: {
    handleCreate: function handleCreate() {
      if (!this.isPossibleToCreate()) return;
      this.$emit('create', this.trafficLight);
    },
    getTrafficLightName: function getTrafficLightName(trafficLight) {
      if (trafficLight === MODULES.COMPLIANCE_GAP) return 'Gaps';
      return capitalize(trafficLight);
    },
    isPossibleToCreate: function isPossibleToCreate() {
      this.errorMessages = {};
      if (!this.trafficLight.legend.length) {
        this.errorMessages.label = this.translate.labelIsEmpty;
      }
      if (!this.trafficLight.module) {
        this.errorMessages.module = this.translate.moduleIsEmpty;
      }
      return !Object.keys(this.errorMessages).length;
    }
  }
};