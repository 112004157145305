function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  class: "action-reports-wrap"
};
var _hoisted_2 = {
  class: "w-full"
};
var _hoisted_3 = {
  class: "hidden"
};
var _hoisted_4 = {
  class: "flex"
};
var _hoisted_5 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_gantt_chart_actions = _resolveComponent("gantt-chart-actions");
  var _component_collapsible_panel = _resolveComponent("collapsible-panel");
  var _component_save_report = _resolveComponent("save-report");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.mainReport,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_collapsible_panel, {
    ref: "ganttChart",
    class: "py-4",
    onCollapseChanged: _cache[0] || (_cache[0] = function ($event) {
      return $data.showGanttChart = $event;
    })
  }, {
    header: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.showAsGanttRoadmap), 1 /* TEXT */)];
    }),
    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_gantt_chart_actions, {
        "has-preview": true,
        activities: $options.visibleActions
      }, null, 8 /* PROPS */, ["activities"])])];
    }),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */), _createVNode(_component_save_report, {
    type: $data.reportType,
    "state-local": $data.stateLocal,
    "report-options": {
      showGanttChart: $data.showGanttChart
    },
    onLoad: $options.onLoadReport
  }, null, 8 /* PROPS */, ["type", "state-local", "report-options", "onLoad"]), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_risma_datatable, {
    key: 1,
    ref: "reportsDataTable",
    "dataset-main": _ctx.datasetSlice,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    paging: true,
    "page-length": _ctx.pageLength,
    "state-local": $data.stateLocal,
    "wrapper-height-sticky": 400,
    "decouple-filter-fields": $options.decoupleFilterFields,
    "disabled-filter-by-columns": $data.disabledFilterByColumns,
    "filter-by-column": true,
    "columns-meta": $data.columnsMeta,
    "column-sorting": $data.columnSorting,
    "custom-export": $options.customExport,
    class: "action-table",
    "additional-filter-options": $data.additionalFilterOptions,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged
  }, _createSlots(_defineProperty(_defineProperty({
    title: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_createVNode(_component_preview_modal_link, {
        activity: item.activity,
        onUpdated: $options.updateFromPreviewMode,
        onDeleteItem: $options.onItemDelete,
        onDismissModal: $options.onDismissModal
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(item.field), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onUpdated", "onDeleteItem", "onDismissModal"])];
    })
  }, $data.linksGapLabel, _withCtx(function (_ref2) {
    var _item$mitigationActiv;
    var item = _ref2.item;
    return [(_item$mitigationActiv = item.mitigationActivities) !== null && _item$mitigationActiv !== void 0 && _item$mitigationActiv.length ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 0
    }, _renderList(item.mitigationActivities, function (activity) {
      return _openBlock(), _createElementBlock("div", {
        key: activity.id,
        class: "mb-2"
      }, [_createVNode(_component_preview_modal_link, {
        activity: activity,
        "selected-route": {
          tab: $data.TabTypes.GAPANALYSIS,
          id: activity.articleId
        },
        class: "hover:underline"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_4, [_createVNode(_component_traffic_light, {
            color: _ctx.getGapColor(activity.analysis_rag),
            class: "shrink-0 mt-1 mr-1"
          }, null, 8 /* PROPS */, ["color"]), _createElementVNode("div", null, _toDisplayString("".concat(activity.article.article, " ").concat(activity.article.articleTitle)), 1 /* TEXT */)])];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "selected-route"])]);
    }), 128 /* KEYED_FRAGMENT */)) : (_openBlock(), _createElementBlock("div", _hoisted_5))];
  })), "_", 2), [_renderList(_ctx.trafficLightRismaDatatableFields, function (trafficLightCol) {
    return {
      name: trafficLightCol,
      fn: _withCtx(function (_ref3) {
        var item = _ref3.item;
        return [item.field.color ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(trafficLightCol)
        }, [_createVNode(_component_traffic_light, {
          color: item.field.color
        }, null, 8 /* PROPS */, ["color"]), _createElementVNode("p", _hoisted_3, _toDisplayString(item.field.value) + "-" + _toDisplayString(item.field.label), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("span", {
          key: "".concat(trafficLightCol, "_else")
        }))];
      })
    };
  }), _renderList(_ctx.linkedColumnsForPreview, function (linkCol) {
    return {
      name: linkCol,
      fn: _withCtx(function (_ref4) {
        var item = _ref4.item;
        return [item.activities && item.activities.length ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(linkCol, "_").concat(item.id)
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.activities, function (activityObj, index) {
          return _openBlock(), _createBlock(_component_preview_modal_link, {
            key: "".concat(activityObj.fieldText, "_").concat(index),
            class: "hover:underline",
            activity: activityObj.activity
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_risma_title_with_icon, {
                icon: "confidential",
                type: "small",
                truncate: false,
                title: activityObj.fieldText,
                "display-icon": activityObj.activity.confidential
              }, null, 8 /* PROPS */, ["title", "display-icon"])];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"]);
        }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", {
          key: linkCol
        }))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "page-length", "state-local", "decouple-filter-fields", "disabled-filter-by-columns", "columns-meta", "column-sorting", "custom-export", "additional-filter-options", "onPageChange", "onOnStateChanged"]))]);
}