import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_custom_field_types = _resolveComponent("custom-field-types");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", null, [$data.showModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $options.modalHeader,
    "dismiss-on-click-outside": false,
    "show-buttons": $data.showButtons,
    onDismiss: _ctx.toggleModal
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.notificationError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        ref: "notifError",
        class: "notification-wrap",
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.notificationError), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */)) : _createCommentVNode("v-if", true), _createElementVNode("fieldset", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        modelValue: $data.field.label,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.field.label = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.enterTitle,
        type: "text",
        "focus-on-mount": "",
        invalid: !!$options.labelErrorNotification,
        "error-help-text": $options.labelErrorNotification,
        maxlength: 100
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid", "error-help-text"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_input_field, {
        modelValue: $data.field.explanatoryText,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.field.explanatoryText = $event;
        }),
        title: $data.translate.explanatoryText,
        placeholder: $data.translate.enterExplanatoryText,
        type: "text",
        maxlength: 1000
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])]), _createElementVNode("div", {
        class: _normalizeClass(["mb-3", {
          disabled: $data.isEditMode
        }])
      }, [_createVNode(_component_single_select, {
        title: $data.translate.module,
        options: _ctx.moduleTypesOptions,
        "model-value": $data.field.module,
        disabled: $data.isEditMode,
        "un-sorted": true,
        "onUpdate:modelValue": $options.onChangeModuleType
      }, null, 8 /* PROPS */, ["title", "options", "model-value", "disabled", "onUpdate:modelValue"]), _cache[2] || (_cache[2] = _createElementVNode("span", {
        class: "validation-error"
      }, null, -1 /* HOISTED */))], 2 /* CLASS */), _createVNode(_component_custom_field_types, {
        field: $data.field,
        disabled: $data.isEditMode,
        "module-type": $data.field.module,
        "custom-fields": $props.customFields,
        "save-button-click-counter": $data.saveButtonClickCounter,
        onEdit: $options.editCustomFieldType
      }, null, 8 /* PROPS */, ["field", "disabled", "module-type", "custom-fields", "save-button-click-counter", "onEdit"])]), _createVNode(_component_buttons_row, {
        class: "actions",
        data: $options.buttonsRowData
      }, null, 8 /* PROPS */, ["data"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "show-buttons", "onDismiss"])) : _createCommentVNode("v-if", true)]);
}