function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { generateFiltersQueryString } from '@/api/httpUtils';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import NoSearchResults from '@/components/Pages/Compliance/Reports/NoSearchResults';
import ControlFilters from '@/components/Organisms/Controls/ControlFilters';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { ControlLocalStorage } from '@/constants/ControlLocalStorage';
import { ControlStatusesUI } from '@/constants/controls/ControlsDashboard';
import { isSomePropsIncludedInFilterList, isIncludedInFrameworksList, getFrameworkAndNestedIds } from '@/utils/Utils';
import { checkControlsOrgAccess } from '@/utils/access';
import { UserLevels } from '@/constants/UserLevels';
import { DateFormats } from '@/constants/DateFormats';
import { monthsDefault } from '@/constants/dates/months';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { formatCurrentDateTime } from '@/utils/date';
var MONTHS_IN_YEAR = 12;
export default {
  name: 'ControlYearWheelDashboard',
  token: '<control-year-wheel-dashboard />',
  components: {
    RismaTitle: RismaTitle,
    NoSearchResults: NoSearchResults,
    ControlFilters: ControlFilters,
    FeatherIcon: FeatherIcon,
    PreviewModalLink: PreviewModalLink,
    SingleSelect: SingleSelect,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  props: {
    firstYear: {
      type: [String, Number],
      required: true,
      default: ''
    },
    lastYear: {
      type: [String, Number],
      required: true,
      default: ''
    },
    legends: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    dashboardDataset: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    motherControls: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    processes: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    showExportButton: {
      type: Boolean,
      required: false,
      default: true
    },
    downloadUrlExtension: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['dismissModal', 'updated', 'openPreview'],
  data: function data() {
    return {
      ControlStatusesUI: ControlStatusesUI,
      noSearchText: 'Adjust your search criterias to view search results',
      stateLocal: ControlLocalStorage.ANNUAL_OVERVIEW,
      filters: {
        organisations: [],
        tags: [],
        mother: null,
        responsible: [],
        search: '',
        processes: [],
        activityTypes: [],
        frameworks: []
      },
      firstMonthOnTimeline: 0,
      firstYearOnTimeline: +formatCurrentDateTime(DateFormats.DATE_YEAR_FORMAT),
      yearsToDisplay: 1,
      showOverview: true,
      translate: getTranslate['ControlYearWheelDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useTagsStore, ['tags'])), mapState(useActivityTypesStore, ['controlActivityTypes'])), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), {}, {
    yearsToDisplayOptions: function yearsToDisplayOptions() {
      return [{
        label: "1 ".concat(this.translate.year),
        value: 1
      }, {
        label: "2 ".concat(this.translate.years),
        value: 2
      }, {
        label: "3 ".concat(this.translate.years),
        value: 3
      }];
    },
    totalMonthsDisplayed: function totalMonthsDisplayed() {
      return this.yearsToDisplay * MONTHS_IN_YEAR;
    },
    displayMonths: function displayMonths() {
      var _this = this;
      return Array.from(Array(this.totalMonthsDisplayed)).map(function (value, index) {
        var monthName = monthsDefault[(index + _this.firstMonthOnTimeline) % MONTHS_IN_YEAR];
        var yearsAfterFirst = Math.floor((index + _this.firstMonthOnTimeline) / MONTHS_IN_YEAR);
        var shortYear = (_this.firstYearOnTimeline + yearsAfterFirst) % 100;
        return "".concat(monthName, "/").concat(shortYear);
      });
    },
    downloadUrl: function downloadUrl() {
      var result = '/api/2.0/controls/export/annualoverview';
      var filters = this.getSelectedFilters();
      if (this.filteredDashboard.length) {
        result += '?';
        if (this.slicedDashboard.length) {
          result += "&year=".concat(this.firstYearOnTimeline);
          result += "&month=".concat(this.firstMonthOnTimeline + 1);
          result += "&monthsDisplayed=".concat(this.totalMonthsDisplayed);
        }
        if (this.downloadUrlExtension) result += "&".concat(this.downloadUrlExtension);
        result += '&' + generateFiltersQueryString(filters);
      }
      return result;
    },
    filtersData: function filtersData() {
      return {
        organisations: this.organisations,
        tags: this.tags,
        users: this.users.filter(function (user) {
          return checkControlsOrgAccess(user, UserLevels.NORMAL);
        }),
        mother: this.motherControls.map(function (control) {
          return {
            label: "".concat(control.cpath, " ").concat(control.title),
            value: +control.cpath,
            cpath: control.cpath
          };
        }),
        activityTypes: this.controlActivityTypes,
        processes: this.processes,
        frameworks: this.frameworks
      };
    },
    filteredDashboard: function filteredDashboard() {
      var _this2 = this;
      return this.dashboardDataset.filter(function (_ref) {
        var _control$userIds, _control$tags;
        var control = _ref.control;
        if (_this2.filters.mother && control.mothercontrolId != _this2.filters.mother) return;
        if (!_this2.searchByEntity("".concat(control.cpath, " ").concat(control.title))) return false;
        return isSomePropsIncludedInFilterList(_this2.filters.organisations, control.organisationIds) && isSomePropsIncludedInFilterList(_this2.filters.activityTypes, [control.activityTypeId]) && isSomePropsIncludedInFilterList(_this2.filters.responsible, control === null || control === void 0 || (_control$userIds = control.userIds) === null || _control$userIds === void 0 ? void 0 : _control$userIds.responsible.map(function (id) {
          return +id;
        })) && isSomePropsIncludedInFilterList(_this2.filters.tags, control.tagIds || ((_control$tags = control.tags) === null || _control$tags === void 0 ? void 0 : _control$tags.map(function (tag) {
          return tag.id;
        }))) && isSomePropsIncludedInFilterList(_this2.filters.processes, control.processes ? control.processes.map(function (item) {
          return item.id;
        }) : []) && isIncludedInFrameworksList(_this2.filters.frameworks, control.solutionIds, _this2.filters.includeUnderlyingFrameworks);
      });
    },
    slicedDashboard: function slicedDashboard() {
      var _this3 = this;
      return this.filteredDashboard.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          months: item.months.slice(_this3.startMonthIndex, _this3.startMonthIndex + _this3.totalMonthsDisplayed)
        });
      });
    },
    allYears: function allYears() {
      var result = [];
      for (var i = this.firstYear; i <= this.lastYear; i++) {
        result.push(i);
      }
      return result;
    },
    startMonthIndex: function startMonthIndex() {
      var currentYearIndex = this.allYears.indexOf(this.firstYearOnTimeline);
      return MONTHS_IN_YEAR * currentYearIndex + this.firstMonthOnTimeline;
    },
    isFutureMonthLimit: function isFutureMonthLimit() {
      return this.firstMonthOnTimeline === 0 && this.firstYearOnTimeline + this.yearsToDisplay > this.lastYear;
    },
    isFutureYearLimit: function isFutureYearLimit() {
      return this.isOverYearLimit(this.yearsToDisplay);
    },
    isPastMonthLimit: function isPastMonthLimit() {
      return this.isPastYearLimit && this.firstMonthOnTimeline === 0;
    },
    isPastYearLimit: function isPastYearLimit() {
      return !this.allYears.includes(this.firstYearOnTimeline - 1);
    }
  }),
  watch: {
    filters: function filters() {
      var _this4 = this;
      this.showOverview = false;
      this.$nextTick(function () {
        return _this4.showOverview = true;
      });
    },
    firstYear: function firstYear(year) {
      if (year > this.firstYearOnTimeline) {
        this.firstYearOnTimeline = year;
      }
    },
    lastYear: function lastYear(year) {
      if (year < this.firstYearOnTimeline) {
        this.firstYearOnTimeline = year;
      }
    }
  },
  methods: {
    goMonthBack: function goMonthBack() {
      if (this.isPastMonthLimit) return;
      if (this.firstMonthOnTimeline === 0) {
        this.firstMonthOnTimeline = 11;
        this.firstYearOnTimeline--;
        return;
      }
      this.firstMonthOnTimeline--;
    },
    goMonthForward: function goMonthForward() {
      if (this.isFutureMonthLimit) return;
      if (this.firstMonthOnTimeline === 11) {
        this.firstMonthOnTimeline = 0;
        this.firstYearOnTimeline++;
        return;
      }
      this.firstMonthOnTimeline++;
    },
    goYearBack: function goYearBack() {
      if (this.isPastYearLimit) return;
      this.firstYearOnTimeline--;
    },
    goYearForward: function goYearForward() {
      if (this.isFutureYearLimit) return;
      this.firstYearOnTimeline++;
    },
    searchByEntity: function searchByEntity(item) {
      if (!this.filters.search) return true;
      return item.toLowerCase().trim().indexOf(this.filters.search.toLowerCase().trim()) !== -1;
    },
    resetFilters: function resetFilters() {
      this.filters = {
        organisations: [],
        tags: [],
        mother: null,
        responsible: [],
        search: '',
        showOverviewOptions: true,
        processes: [],
        activityTypes: []
      };
    },
    yearsToDisplaySelected: function yearsToDisplaySelected(years) {
      this.yearsToDisplay = years;
      if (this.isOverYearLimit(years - 1)) this.moveToEndOfTimeline();
    },
    isOverYearLimit: function isOverYearLimit(additionalYears) {
      var nextDisplayedYear = this.firstYearOnTimeline + additionalYears + Math.ceil(this.firstMonthOnTimeline / 12);
      return nextDisplayedYear > this.lastYear;
    },
    moveToEndOfTimeline: function moveToEndOfTimeline() {
      this.firstMonthOnTimeline = 0;
      this.firstYearOnTimeline = this.lastYear - this.yearsToDisplay + 1;
    },
    getItemHoverTitle: function getItemHoverTitle(item) {
      return DatatableRenderer.getItemHoverTitle(item);
    },
    getLegendColor: function getLegendColor(legendValue) {
      var _this$legends$find;
      return ((_this$legends$find = this.legends.find(function (legend) {
        return legend.value === legendValue;
      })) === null || _this$legends$find === void 0 ? void 0 : _this$legends$find.color) || '';
    },
    getSelectedFilters: function getSelectedFilters() {
      var _this5 = this,
        _this$filters$framewo;
      var filters = {};
      Object.entries(this.filters).forEach(function (item) {
        var _item = _slicedToArray(item, 2),
          key = _item[0],
          data = _item[1];
        var rightKey = _this5.getRightFilterKey(key);
        if (data) {
          if (Array.isArray(data) && data.length) {
            filters[rightKey] = data.map(function (item) {
              return item.id;
            });
          } else if (!Array.isArray(data) && key !== 'showOverviewOptions') {
            filters[rightKey] = data;
          }
        }
      });
      if (this.filters.includeUnderlyingFrameworks && (_this$filters$framewo = this.filters.frameworks) !== null && _this$filters$framewo !== void 0 && _this$filters$framewo.length) {
        var key = this.getRightFilterKey('frameworks');
        filters[key] = getFrameworkAndNestedIds(this.filters.frameworks);
      }
      return filters;
    },
    getRightFilterKey: function getRightFilterKey(key) {
      switch (key) {
        case 'activityTypes':
          return 'activityTypeIds';
        case 'organisations':
          return 'organisationIds';
        case 'tags':
          return 'tagIds';
        case 'mother':
          return 'motherControlId';
        case 'processes':
          return 'processIds';
        case 'frameworks':
          return 'solutionId';
        case 'search':
          return 'displayHeadline';
        default:
          return key;
      }
    }
  }
};