function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import i18n from '@/i18n';
import { useUserStore } from '@/Store/userStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { useWorkflowStore } from '@/Store/workflowStore';
import { getModuleFromObjType, ObjectTypes } from '@/constants/ObjectTypes';
import { MODULES } from '@/constants/modules';
import { TabTypes } from '@/constants/Tabs';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import * as Utils from '@/utils/Utils';
import * as RisksUtils from '@/components/Pages/Risks/RisksUtils';
import { checkERMByProjectId } from '@/utils/risks/check';
import * as formatDate from '@/utils/date';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { getProjectDescriptionLabel } from '@/utils/Compliance/complianceProject';
var getIncidentStatusName = function getIncidentStatusName(incident) {
  return useWorkflowStore().getActivityStatusTitle(incident, MODULES.INCIDENTS);
};
var currentComplianceProjectById = function currentComplianceProjectById(projects, id) {
  return projects.find(function (project) {
    return +project.id === +id && project.objType === ObjectTypes.COMPLIANCE_PROJECT;
  });
};
var getCompanyUrl = function getCompanyUrl(item) {
  var hasAccess = useUserStore().userHasCompaniesAccess;
  if (hasAccess) {
    return item.url || (item.id ? "/company/".concat(item.id, "/") : '');
  }
  return '';
};
export function prepareGapData(data, projectOptions) {
  var gapLinks = {};
  data.forEach(function (dataItem) {
    var objType = getObjectTypeByGap(dataItem);
    var projectKey = getModuleFromObjType(objType);
    if (projectKey === MODULES.COMPLIANCE) {
      projectKey += "-".concat(dataItem.activityProjectId);
    }
    if (!gapLinks[projectKey]) {
      gapLinks[projectKey] = {
        items: [],
        module: projectKey,
        moduleTitle: getProjectOptionByModule(projectKey, projectOptions).label,
        hasTrafficLight: isModuleHasTrafficLight(getPurifiedModuleName(projectKey)),
        hasDeleteOption: false
      };
    }
    var gapItem = createGapItem(dataItem, objType);
    var existedNode = gapLinks[projectKey].items.find(function (nodeItem) {
      return nodeItem.nodeId === dataItem.nodeId;
    });
    if (existedNode) {
      existedNode.gapList.push(dataItem);
    } else {
      gapLinks[projectKey].items.push(gapItem);
    }
  });
  return gapLinks;
}
function createGapItem(gap, objType) {
  return {
    dataProcessorId: gap.data_processor_id,
    nodeId: gap.nodeId,
    trafficLight: gap.activityTrafficLight,
    title: gap.activityTitle,
    projectId: gap.activityProjectId,
    gapList: [gap],
    objType: objType
  };
}
function getObjectTypeByGap(item) {
  if (item.data_processor_id) return ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR;
  if (item.nodeId) return ObjectTypes.COMPLIANCE_NODE;
  return null;
}
export function prepareMitigatingActions(gapAnalysis, projectOptions, data) {
  var gapLinks = {};
  var trafficLightsStore = useTrafficLights();
  gapAnalysis.forEach(function (analysis) {
    var projects = Object.keys(analysis.links).filter(function (key) {
      return analysis.links[key].length;
    });
    var article = analysis.article;
    projects.forEach(function (projectKey) {
      if (!gapLinks[projectKey]) {
        gapLinks[projectKey] = {
          items: [],
          module: MODULES.COMPLIANCE,
          moduleTitle: getProjectOptionByModule(projectKey, projectOptions).label,
          hasDeleteOption: false,
          hasTrafficLight: true
        };
      }
      gapLinks[projectKey].items.push({
        title: article.article + ' ' + article.articleTitle,
        trafficLightColor: trafficLightsStore.getGapColor(analysis.analysis_rag),
        projectId: data.compliance_projects_id,
        nodeId: data.id,
        mitigatingItems: analysis.links[projectKey],
        gapAnalysis: analysis
      });
    });
  });
  return gapLinks;
}
export function prepareQuestionnaireData(data, links) {
  data.forEach(function (item) {
    var linksObject = links[TabTypes.QUESTIONNAIRE];
    var itemModule = getModuleFromObjType(item.objType);
    if (itemModule === MODULES.COMPLIANCE) {
      itemModule += "-".concat(item.compliance_projects_id);
    }
    if (linksObject[itemModule]) {
      linksObject[itemModule].items.push(item);
    } else {
      linksObject[itemModule] = {
        items: [item]
      };
    }
  });
}
export function getProjectOptionByModule(linkModule, options) {
  var projectOption = options.find(function (item) {
    return item.value === linkModule;
  });
  if (!projectOption) {
    throw new Error("Could not find project option for ".concat(linkModule));
  }
  return projectOption;
}
export function isModuleHasTrafficLight(module) {
  if (module === MODULES.RISK || module === MODULES.CONTRACTS) {
    return false;
  }
  if (module === MODULES.CONTROLS) {
    return function (control) {
      return !control.isMothercontrol;
    };
  }
  return true;
}
export function getPurifiedModuleName(module) {
  return module.split('-')[0];
}
export function isIA(module) {
  return module === MODULES.COMPLIANCE_IA_SYSTEM || module === MODULES.COMPLIANCE_IA_CONTROLLER || module === MODULES.COMPLIANCE_IA_PROCESSOR;
}
export function prepareIAObject(IAList, activityList) {
  return IAList.map(function (ia) {
    var newIA = {};
    activityList.forEach(function (activity) {
      var containsCurrentIA = activity.list.some(function (item) {
        return item.id === ia.id;
      });
      if (containsCurrentIA) {
        newIA = _objectSpread(_objectSpread({}, ia), {}, {
          activity_id: activity.id
        });
      }
    });
    return newIA;
  });
}
export function preparePreviewData(item, workflows, currentUser, isAdmin, projects) {
  var result = {
    // case compliance is the same as this object
    id: item.id,
    title: item.title,
    description: item.description || '',
    organisationIds: item.organisationIds || [],
    responsibleIds: item.responsibleUserIds || [],
    hoverTitle: DatatableRenderer.getItemHoverTitle(item),
    confidential: (item === null || item === void 0 ? void 0 : item.confidential) || false,
    url: item.url || item.apiUrl,
    objType: item.objType
  };
  switch (item.objType) {
    case ObjectTypes.CONTRACT_LIMITED:
    case ObjectTypes.CONTRACT:
      {
        result = _objectSpread(_objectSpread({}, result), {}, {
          start: formatDate.toLocalDate(item.startDate),
          expirationDate: formatDate.toLocalDate(item.endDate)
        });
        if (item.workflowStatusId) {
          var status = workflows[MODULES.CONTRACTS].find(function (status) {
            return status.id === item.workflowStatusId;
          });
          result.status = status ? i18n.t(status.title) : '';
        }
        break;
      }
    case ObjectTypes.EXECUTION_INITIATIVE_LIMITED:
    case ObjectTypes.EXECUTION_INITIATIVE:
      result = _objectSpread(_objectSpread({}, result), {}, {
        trafficLight: item.trafficLight,
        closed: item.closed,
        path: item.path,
        prStatus: item.status,
        start: item.start,
        deadline: item.deadline,
        responsibleIds: item.owner || item.userIds && item.userIds.responsible || [],
        slug: item.slug
      });
      break;
    case ObjectTypes.CONTROLS_CONTROL_LIMITED:
    case ObjectTypes.CONTROLS_CONTROL:
      result = _objectSpread(_objectSpread({}, result), {}, {
        trafficLight: item.trafficLight || 0,
        cpath: item.cpath,
        path: item.cpath,
        module: MODULES.CONTROLS,
        deadline: formatDate.toLocalDate(item.deadline),
        reviewDeadline: formatDate.toLocalDate(item.reviewDeadline),
        responsibleIds: item.userIds && item.userIds.responsible || [],
        reviewIds: item.userIds && item.userIds.review || [],
        active: item.active,
        isMothercontrol: item.isMothercontrol
      });
      break;
    case ObjectTypes.RISK_RISK_LIMITED:
    case ObjectTypes.RISK_RISK:
      result = _objectSpread(_objectSpread({}, result), {}, {
        path: item.rno,
        rno: item.rno,
        responsibleIds: item.userIds && [+item.userIds.responsible] || +item.responsibleUserIds || [],
        status: item.status,
        projectId: item.projectId,
        projectPeriodId: item.projectPeriodId,
        scoringId: item.scoringId
      });
      if (checkERMByProjectId(item.projectId, currentUser)) {
        result.likelyhood = item.likelihood_score;
        result.impact = item.impact_score;
      } else {
        var _project$projectPerio;
        var project = projects.find(function (project) {
          return project.id === item.projectId && project.objType === ObjectTypes.RISK_PROJECT;
        });
        var period = (_project$projectPerio = project.projectPeriods) === null || _project$projectPerio === void 0 ? void 0 : _project$projectPerio.find(function (period) {
          return period.isCurrent;
        });
        var threats = RisksUtils.formatThreats(project.threats);
        var levelOfThreats = period ? RisksUtils.formatThreatLevels(period.threatLevels) : [];
        var vulnerabilities = period ? RisksUtils.formatVulnerabilities(project.vulnerabilities) : [];
        result.threat = Utils.findPropsByIds(item[RiskBaseModelProps.THREAT_IDS], threats, 'label');
        result.threatLevel = Utils.findValueById(levelOfThreats, item[RiskBaseModelProps.THREAT_LEVEL_VALUE], 'label');
        result.vulnerability = Utils.findPropsByIds(item[RiskBaseModelProps.VULNERABILITY_IDS], vulnerabilities, 'label');
      }
      break;
    case ObjectTypes.INCIDENTS_INCIDENT:
    case ObjectTypes.INCIDENTS_INCIDENT_LIMITED:
      result = _objectSpread(_objectSpread({}, result), {}, {
        incOccurred: formatDate.toLocalDateTime(item.incidentHappenedAt) || i18n.t('Not set'),
        severity: item.severity,
        status: getIncidentStatusName(item)
      });
      break;
    case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER:
    case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM:
    case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR:
      result = _objectSpread(_objectSpread({}, result), {}, {
        trafficLight: item.trafficLight || item.traffic_light || 0,
        path: item.uniqueId || '',
        compliance_projects_id: item.compliance_projects_id
      });
      break;
    case ObjectTypes.COMPLIANCE_NODE:
    case ObjectTypes.COMPLIANCE_NODE_LIMITED:
      result = _objectSpread(_objectSpread({}, result), {}, {
        trafficLight: item.trafficLight || item.traffic_light || 0,
        path: item.uniqueId || '',
        compliance_projects_id: item.compliance_projects_id,
        descriptionLabel: getProjectDescriptionLabel(currentComplianceProjectById(projects, item.compliance_projects_id))
      });
      break;
    case ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED:
    case ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE:
      result = _objectSpread(_objectSpread({}, result), {}, {
        trafficLight: item.trafficLight || 0,
        processTreeId: item.processTreeId
      });
      break;
    case ObjectTypes.COMPANY:
    case ObjectTypes.COMPANY_LIMITED:
      result = {
        id: item.id,
        title: item.name,
        vatNo: item.vatNo,
        countryCode: item.countryCode,
        city: item.city,
        address: item.address,
        phone: item.phone,
        zipCode: item.zipCode,
        email: item.email,
        url: getCompanyUrl(item),
        vatStatus: item.vatStatus,
        objType: item.objType
      };
      break;
  }
  return result;
}