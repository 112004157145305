function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import cloneDeep from 'lodash/cloneDeep';
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useRiskProjectsStore } from '@/Store/riskProjectsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import EditProjectModal from '@/components/Molecules/Modal/EditProjectModal';
import MainWell from '@/components/Atoms/Containers/MainWell';
import StaticTable from '@/components/Molecules/StaticTable';
import * as RiskAPI from '@/api/risk/risk';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import { isLockedRiskUser } from '@/utils/access';
import { setHeatmapColors } from '@/api/risk/risk';
export default {
  name: 'RiskAdminProjects',
  components: {
    EditProjectModal: EditProjectModal,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    MainWell: MainWell,
    StaticTable: StaticTable
  },
  emits: ['updateHeader', 'refreshMenuWithRiskProjects'],
  data: function data() {
    return {
      riskProjects: [],
      editedProject: null,
      showEditModal: false,
      RiskProjects: RiskProjects,
      translate: getTranslate['RiskAdminProjects']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser', 'isAdmin'])), mapState(useActivityTypesStore, {
    activityTypes: 'riskActivityTypes'
  })), mapState(useFrameworksStore, ['enabledFrameworks'])), {}, {
    columns: function columns() {
      return [this.translate.sorting, this.translate.title, this.translate.frameworkId, this.translate.defaultActivityType, this.translate.displayRelevantThreatsOnAssessments, this.translate.enableAccumulatedRisk, this.translate.enableInherentVsResidualRisk, this.translate.enableMatrixInverted, this.translate.enableRiskApproval, this.translate.allowConfidentialRisks];
    },
    dataset: function dataset() {
      var _this = this;
      return this.riskProjects.map(function (project) {
        var _this$enabledFramewor, _this$activityTypes$f;
        var isErm = project.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
        return {
          title: project.title,
          solution: ((_this$enabledFramewor = _this.enabledFrameworks.find(function (framework) {
            return framework.id === project.solutionId;
          })) === null || _this$enabledFramewor === void 0 ? void 0 : _this$enabledFramewor.title) || _this.translate.notSet,
          defaultActivityType: isErm ? '' : ((_this$activityTypes$f = _this.activityTypes.find(function (activityType) {
            return activityType.id === project.defaultActivityTypeId;
          })) === null || _this$activityTypes$f === void 0 ? void 0 : _this$activityTypes$f.label) || _this.translate.notSet,
          displayRelevantThreats: isErm ? '' : project.threatsEnabled ? _this.translate.yes : _this.translate.no,
          accumulatedRisks: isErm ? '' : project.accumulatedRisks ? _this.translate.yes : _this.translate.no,
          inherentRisksEnabled: isErm ? '' : project.inherentRisksEnabled ? _this.translate.yes : _this.translate.no,
          enableMatrixInverted: isErm ? '' : project.matrixInverted ? _this.translate.yes : _this.translate.no,
          enableRiskApproval: isErm ? '' : project.riskApproval ? _this.translate.yes : _this.translate.no,
          allowConfidentialRisks: isErm ? '' : project.confidentialRisks ? _this.translate.yes : _this.translate.no,
          edit: project
        };
      });
    },
    isUserLocked: function isUserLocked() {
      return isLockedRiskUser(this.currentUser) || !this.isAdmin;
    }
  }),
  mounted: function mounted() {
    this.getRiskProjects();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useRiskProjectsStore, {
    loadRiskProjects: 'load'
  })), {}, {
    onProjectChange: function onProjectChange(data) {
      var _this2 = this;
      var params = _objectSpread({
        projectId: data.id
      }, data);
      return this.updateRiskProject(data.id, params).then(function () {
        _this2.$notify({
          title: _this2.translate.riskProjectUpdated
        });
        return Promise.all([_this2.getRiskProjects(), _this2.loadRiskProjects()]);
      }).then(function () {
        _this2.$emit('updateHeader');
        _this2.$emit('refreshMenuWithRiskProjects');
      }).catch(function () {});
    },
    onEditIconClick: function onEditIconClick(project) {
      this.editedProject = cloneDeep(project);
      this.toggleEditModal();
    },
    toggleEditModal: function toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    getRiskProjects: function getRiskProjects() {
      var _this3 = this;
      return RiskAPI.getRiskProjects().then(function (_ref) {
        var list = _ref.list;
        _this3.riskProjects = list.sort(function (pr1, pr2) {
          return pr1.sorting - pr2.sorting;
        });
      });
    },
    updateRiskProject: function updateRiskProject(id, body) {
      if (!this.handleMatrixInverted(id, body)) {
        return Promise.reject(new Error('User declined change'));
      }
      this.showEditModal = false;
      return RiskAPI.updateRiskProject(id, _objectSpread({
        projectId: id
      }, body));
    },
    handleMatrixInverted: function handleMatrixInverted(id, body) {
      if (!Object.hasOwn(body, 'matrixInverted')) {
        return true;
      }
      if (!confirm(this.translate.confirmResetOfColors)) {
        return false;
      }
      setHeatmapColors(id, {
        heatmapOptions: []
      });
      return true;
    },
    onSortingChange: function onSortingChange(projects) {
      var _this4 = this;
      var promises = [];
      projects.forEach(function (project, idx) {
        if (!project.edit) return;
        promises.push(_this4.updateRiskProject(project.edit.id, {
          id: project.edit.id,
          sorting: idx + 1
        }));
      });
      return Promise.all(promises).then(this.loadRiskProjects).then(function () {
        _this4.$notify({
          title: _this4.translate.riskProjectUpdated
        });
        _this4.$emit('updateHeader');
        _this4.$emit('refreshMenuWithRiskProjects');
      });
    }
  })
};