// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.preset-width[data-v-b62f7f4c] {
  width: 120px;
  max-width: 120px;
  min-width: 120px;
}
tr td.col-name[data-v-b62f7f4c],
tr td.col-title[data-v-b62f7f4c],
tr td.col-titlePreview[data-v-b62f7f4c],
tr td.col-nodeTitle[data-v-b62f7f4c],
tr td.col-process[data-v-b62f7f4c] {
  font-weight: 600;
}
tr td.col-name[data-v-b62f7f4c]  a:hover,
tr td.col-title[data-v-b62f7f4c]  a:hover,
tr td.col-titlePreview[data-v-b62f7f4c]  a:hover,
tr td.col-nodeTitle[data-v-b62f7f4c]  a:hover,
tr td.col-process[data-v-b62f7f4c]  a:hover {
  text-decoration: underline;
}
tr td.col-name[data-v-b62f7f4c]  .cursor-pointer:hover,
tr td.col-title[data-v-b62f7f4c]  .cursor-pointer:hover,
tr td.col-titlePreview[data-v-b62f7f4c]  .cursor-pointer:hover,
tr td.col-nodeTitle[data-v-b62f7f4c]  .cursor-pointer:hover,
tr td.col-process[data-v-b62f7f4c]  .cursor-pointer:hover {
  text-decoration: underline;
}
.table-wrapper.table-wrapper-head[data-v-b62f7f4c] {
  position: relative;
  overflow: hidden;
}
.table-wrapper.table-wrapper-body thead[data-v-b62f7f4c] {
  visibility: hidden;
}
.table-wrapper.table-wrapper-body thead tr[data-v-b62f7f4c] {
  height: 0;
}
.table-wrapper.table-wrapper-body thead tr th[data-v-b62f7f4c] {
  height: 0;
  min-width: 100px;
}
.table-wrapper.table-wrapper-body thead tr th div[data-v-b62f7f4c] {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}
.table-wrapper > table[data-v-b62f7f4c] {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.table-wrapper > table th[data-v-b62f7f4c],
.table-wrapper > table td[data-v-b62f7f4c] {
  box-sizing: content-box;
  padding: 8px 10px;
  word-wrap: break-word;
}
.table-wrapper > table th[data-v-b62f7f4c] {
  position: relative;
}
.table-wrapper > table th[data-v-b62f7f4c]:after {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
}
.table-wrapper > table th.sorting[data-v-b62f7f4c]:after {
  content: none;
}
.table-wrapper > table th.sorting_asc[data-v-b62f7f4c]:after {
  content: "↑";
}
.table-wrapper > table th.is-filtered[data-v-b62f7f4c] {
  font-weight: 700;
}
.table-wrapper > table th.sorting_desc[data-v-b62f7f4c]:after {
  content: "↓";
}
.table-wrapper > table tr[data-v-b62f7f4c]:nth-child(even) {
  background: rgb(235.9, 235.9, 235.9);
}
.no-matches[data-v-b62f7f4c] {
  text-align: center;
  font-weight: 600;
}
.table-columns-move[data-v-b62f7f4c] {
  transition: transform 0.5s;
}
tr[data-v-b62f7f4c]  ul,
tr[data-v-b62f7f4c]  ol {
  padding-left: 1em;
}
tr[data-v-b62f7f4c]  ul {
  list-style-type: disc;
}
tr[data-v-b62f7f4c]  ol {
  list-style-type: decimal;
}
.search-field[data-v-b62f7f4c]::-webkit-search-cancel-button {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
