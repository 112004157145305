import StaticTable from '@/components/Molecules/StaticTable';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
export default {
  name: 'ContractPartyGenericTable',
  components: {
    Checkboxes: Checkboxes,
    StaticTable: StaticTable,
    RadioButtons: RadioButtons
  },
  props: {
    datasetTable: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  emits: ['update:checkbox', 'update:radioBtn'],
  data: function data() {
    return {
      theme: StaticTableThemes.zebra,
      columnsStyles: {
        name: 'width: 400px',
        checkbox: 'width: 50px',
        address: 'width: 20%',
        city: 'width: 20%'
      }
    };
  },
  methods: {
    handleCheckboxUpdate: function handleCheckboxUpdate(fieldName, index, value) {
      this.$emit('update:checkbox', {
        index: index,
        value: value,
        fieldName: fieldName
      });
    },
    handleRadioBtnUpdate: function handleRadioBtnUpdate(fieldName, index, value) {
      this.$emit('update:radioBtn', {
        index: index,
        value: value,
        fieldName: fieldName
      });
    }
  }
};