import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createBlock(_component_static_table, {
    "dataset-table": $options.dataset,
    columns: $options.columns
  }, {
    name: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_ctx.isAdmin ? (_openBlock(), _createBlock(_component_preview_modal_link, {
        key: 0,
        activity: $options.getCompanyWithUrl(slotData.company),
        onUpdated: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('update', $event);
        }),
        onDismissModal: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('dismiss');
        })
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_risma_title, {
            title: slotData.company.name,
            class: "hover:underline",
            type: "medium"
          }, null, 8 /* PROPS */, ["title"])];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"])) : (_openBlock(), _createElementBlock("span", {
        key: 1,
        title: slotData.company.name
      }, _toDisplayString(slotData.company.name), 9 /* TEXT, PROPS */, _hoisted_1))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-table", "columns"]);
}