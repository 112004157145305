import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 1,
  class: "mb-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_options_list = _resolveComponent("options-list");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.gapTypeOptions,
    type: "medium",
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), $data.gapTypesLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_options_list, {
    "predefined-options": $data.gapTypes,
    placeholder: $data.translate.addGapTypeOption,
    draggable: true,
    "confirm-on-delete": $options.confirmOnDelete,
    "show-empty-fields-error": !!$data.notificationError,
    "save-button-click-counter": $data.saveButtonClickCounter,
    class: "mb-3 max-w-300px",
    onOnOptionsChange: $options.onOptionsChange
  }, null, 8 /* PROPS */, ["predefined-options", "placeholder", "confirm-on-delete", "show-empty-fields-error", "save-button-click-counter", "onOnOptionsChange"])])), _createVNode(_component_risma_button, {
    text: $data.translate.saveGapTypes,
    type: "save",
    class: "mt-6",
    onClick: $options.onSaveGapTypes
  }, null, 8 /* PROPS */, ["text", "onClick"])]);
}