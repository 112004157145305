import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  class: "-ml-8px"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = {
  key: 0,
  class: "ml-1"
};
var _hoisted_4 = {
  class: "hidden"
};
var _hoisted_5 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_hierarchical_list = _resolveComponent("hierarchical-list");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$data.pageLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.pageLoaded && $data.dataset.length ? (_openBlock(), _createBlock(_component_risma_datatable, {
    key: 1,
    "dataset-main": _ctx.datasetSlice,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "wrapper-height-sticky": 500,
    "columns-meta": $options.columnsMeta,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged,
    "onUpdate:scrollState": _cache[0] || (_cache[0] = function ($event) {
      return $data.hasScroll = $event;
    })
  }, _createSlots({
    _: 2 /* DYNAMIC */
  }, [_renderList($data.slotDataFields, function (linkCol) {
    return {
      name: linkCol,
      fn: _withCtx(function (_ref) {
        var item = _ref.item;
        return [$options.isNormalField(item.field) ? (_openBlock(), _createElementBlock("div", {
          key: "link_div_".concat(linkCol),
          innerHTML: item.field
        }, null, 8 /* PROPS */, _hoisted_2)) : item.field.fieldType && item.field.fieldType === $data.fieldTypes.TRAFFIC_LIGHT ? (_openBlock(), _createElementBlock("div", {
          key: "link_traffic_".concat(linkCol),
          class: "flex items-center"
        }, [_createVNode(_component_traffic_light, {
          title: item.field.label,
          color: item.field.color,
          icon: item.field.icon || ''
        }, null, 8 /* PROPS */, ["title", "color", "icon"]), item.field.trafficLightSuffix ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(item.field.trafficLightSuffix), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("p", _hoisted_4, _toDisplayString(item.field.value) + "-" + _toDisplayString(_ctx.$trans($options.capitalize(item.field.label))), 1 /* TEXT */)])) : item.field.fieldType && item.field.fieldType === $data.fieldTypes.HIERARCHICAL_LIST ? (_openBlock(), _createElementBlock("div", {
          key: "link_hierarchical_".concat(linkCol)
        }, [_createVNode(_component_hierarchical_list, {
          node: item.field.field,
          "has-preview-mode": true,
          "show-children": true,
          "toggle-children": false,
          onUpdated: $options.handleDirty,
          onDismissModal: $options.updateFromPreview
        }, null, 8 /* PROPS */, ["node", "onUpdated", "onDismissModal"])])) : (_openBlock(), _createElementBlock("div", {
          key: linkCol
        }))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "columns-meta", "onPageChange", "onOnStateChanged"])) : _createCommentVNode("v-if", true), $data.pageLoaded && !$data.dataset.length ? (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString($data.translate.noChangeHistory), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]);
}