import i18n from '@/i18n';
export var getTranslate = {
  RismaDateRangePicker: function RismaDateRangePicker() {
    return {
      apply: i18n.t('Apply'),
      customRange: i18n.t('Custom range'),
      cancel: i18n.t('Cancel')
    };
  },
  RismaFileUpload: function RismaFileUpload() {
    return {
      selectFile: i18n.t('Select file'),
      dragAndDropOr: i18n.t('Drag and drop or '),
      browse: i18n.t('browse'),
      weSupport: i18n.t('We support'),
      chooseFile: i18n.t('Choose file'),
      chooseFiles: i18n.t('Choose files'),
      noFileChosen: i18n.t('No file chosen'),
      files: i18n.t('Files'),
      uploadErrorFileMightBeTooLargeToUpload: function uploadErrorFileMightBeTooLargeToUpload(limit) {
        return i18n.t('Upload error. The file exceeds the upload limit of %s', [limit]);
      },
      pleaseChooseTheFilesWithCorrectFiletypes: i18n.t('Please choose a supported file type'),
      oneOfTheFilesYouAreTryingToUploadIsEmpty: i18n.t('One of the files you are trying to upload is empty. Please upload something more substantial.')
    };
  }
};