function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import cloneDeep from 'lodash/cloneDeep';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import ColorPicker from '@/components/Molecules/ColorPicker';
import Notification from '@/components/Molecules/Notification';
import InputField from '@/components/Atoms/Inputs/InputField';
import { ColorSettings, LogoSettings, FaviconSettings, SiteNameSettings } from '@/constants/admin/CustomizationSettings';
import { patchUtilSettings } from '@/api/risma/settings';
import * as imageAPI from '@/api/admin/image';
import { cacheItem } from '@/routes/storeCache';
export default {
  name: 'LookFeelPage',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    RismaButton: RismaButton,
    ColorPicker: ColorPicker,
    RismaFileUpload: RismaFileUpload,
    Notification: Notification,
    InputField: InputField
  },
  data: function data() {
    return {
      colorSettings: cloneDeep([ColorSettings.settingsBgColor, ColorSettings.settingsTextColor, ColorSettings.settingsSubBgColor, ColorSettings.settingsSubTextColor, ColorSettings.settingsSubActiveBgColor, ColorSettings.settingsSubActiveTextColor]),
      colorSettingsFull: ColorSettings,
      logoSettings: LogoSettings,
      errorMessage: null,
      imageTypes: ['JPEG', 'SVG', 'PNG'],
      translate: getTranslate['LookFeelPage'](),
      FaviconSettings: FaviconSettings,
      uploadImageType: null,
      UPLOAD_TYPES: {
        LOGO: 'logo',
        IMAGE: 'image'
      },
      siteName: ''
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    getAllSettings: 'getAllSettings'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    logoBackgroundColor: function logoBackgroundColor() {
      var _this = this;
      var setting = this.colorSettings.find(function (item) {
        return item.settingName === _this.colorSettingsFull.settingsBgColor.settingName;
      });
      return setting.color || setting.default;
    },
    imgSrc: function imgSrc() {
      var hasCustomLogo = this.getSettingValue(this.logoSettings.SETTING);
      if (hasCustomLogo) {
        return this.logoSettings.CUSTOM;
      }
      return this.logoSettings.DEFAULT;
    },
    faviconImgSrc: function faviconImgSrc() {
      var id = this.getSettingValue(FaviconSettings.SETTING);
      return id ? "/api/2.0/images/".concat(id) : FaviconSettings.DEFAULT;
    }
  }),
  mounted: function mounted() {
    this.prepareColors();
    this.setSiteName();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    setSettings: 'set',
    loadSettings: 'load'
  })), {}, {
    setSiteName: function setSiteName() {
      this.siteName = this.getSettingValue(SiteNameSettings.SETTING) || SiteNameSettings.DEFAULT;
    },
    handleLoad: function handleLoad(fileList) {
      var file = this.prepareFile(fileList);
      switch (this.uploadImageType) {
        case this.UPLOAD_TYPES.LOGO:
          this.postLogo(file);
          break;
        case this.UPLOAD_TYPES.IMAGE:
          this.postFavicon(file);
          break;
      }
    },
    prepareFile: function prepareFile(fileList) {
      var file = fileList.slice(fileList.length - 1);
      file[0].name = file[0].filename;
      file[0].type = this.uploadImageType;
      file[0].creator = this.currentUser.id;
      file[0].name = file[0].filename;
      return file;
    },
    postLogo: function postLogo(file) {
      var _this2 = this;
      imageAPI.postImage(file).then(function () {
        return _this2.updateSetting(_this2.logoSettings.SETTING, true);
      }).catch(function (error) {
        return _this2.setError(error);
      });
    },
    postFavicon: function postFavicon(file) {
      var _this3 = this;
      imageAPI.postImage(file).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
          id = _ref2[0].id;
        return _this3.updateSetting(FaviconSettings.SETTING, +id);
      }).catch(function (error) {
        return _this3.setError(error);
      });
    },
    onColorChanged: function onColorChanged(item, color) {
      item.color = color;
      this.updateState(_defineProperty({}, item.settingName, color));
    },
    uploadClick: function uploadClick(type) {
      this.uploadImageType = type;
      this.$el.querySelector('.input-load').click();
    },
    prepareColors: function prepareColors() {
      var _this4 = this;
      this.colorSettings.forEach(function (item) {
        // fixes bug with saved data in cache (change tab and reenter the page)
        if (!item.default) {
          item.default = item.color;
        }
        var color = _this4.getSettingValue(item.settingName);
        if (color && color.length) {
          item.color = color;
        }
      });
    },
    resetColors: function resetColors() {
      var _this5 = this;
      this.colorSettings.forEach(function (item) {
        item.color = item.default;
        _this5.updateSetting(item.settingName, item.default);
      });
    },
    updateState: function updateState(payload) {
      //to update only vuex and wont send api request every 0.1 sec if chose color
      this.setSettings(_objectSpread(_objectSpread({}, this.getAllSettings), payload));
    },
    updateSetting: function updateSetting(settingName, value) {
      var _this6 = this;
      // send api upd colors or logo
      return patchUtilSettings(_defineProperty({}, settingName, value)).then(function () {
        return cacheItem('settings', function () {
          return _this6.loadSettings();
        }, 180);
      }).catch(function (error) {
        return _this6.setError(error);
      });
    },
    setError: function setError(error) {
      var _this7 = this;
      if (error.status === 415) {
        this.errorMessage = "".concat(this.$trans(error.response.error), ": ").concat(this.imageTypes.join(', '));
      } else {
        this.errorMessage = error.response.error;
      }
      setTimeout(function () {
        _this7.errorMessage = null;
      }, 5000);
    },
    updateSiteName: function updateSiteName() {
      var _this8 = this;
      this.updateSetting(SiteNameSettings.SETTING, this.siteName).then(function () {
        return window.document.title = _this8.getSettingValue(SiteNameSettings.SETTING);
      });
    },
    resetSiteName: function resetSiteName() {
      var _this9 = this;
      this.updateSetting(SiteNameSettings.SETTING, '').then(function () {
        _this9.siteName = SiteNameSettings.DEFAULT;
        window.document.title = SiteNameSettings.DEFAULT_TITLE;
      });
    }
  })
};