import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "glob-h3 mb-1"
};
var _hoisted_3 = {
  class: "w-full flex flex-row justify-between mb-1"
};
var _hoisted_4 = {
  class: "flex w-full max-w-250px justify-between mr-2"
};
var _hoisted_5 = {
  class: "text-blue-750 mr-2"
};
var _hoisted_6 = {
  class: "flex w-full max-w-220px justify-between mr-2"
};
var _hoisted_7 = {
  class: "text-blue-750 mr-2"
};
var _hoisted_8 = {
  class: "flex w-full max-w-160px justify-between"
};
var _hoisted_9 = {
  class: "text-blue-750 mr-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_risk_indicator = _resolveComponent("risk-indicator");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h3", _hoisted_2, _toDisplayString($options.translatedTense), 1 /* TEXT */), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString($data.translate.likelihood), 1 /* TEXT */), _createVNode(_component_single_select, {
    options: $data.riskValues,
    "model-value": $options.selectedLikelihood,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $options.emitSelect('likelihoodChange', $event);
    })
  }, null, 8 /* PROPS */, ["options", "model-value"])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("p", _hoisted_7, _toDisplayString($data.translate.impact), 1 /* TEXT */), _createVNode(_component_single_select, {
    options: $data.riskValues,
    "model-value": $options.selectedImpact,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $options.emitSelect('impactChange', $event);
    })
  }, null, 8 /* PROPS */, ["options", "model-value"])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("p", _hoisted_9, _toDisplayString($data.translate.risk), 1 /* TEXT */), _createVNode(_component_risk_indicator, {
    "risk-level": $props.riskLevel,
    class: "flex-none risk-indicator rounded-full h-10 w-10 text-white flex items-center justify-center"
  }, null, 8 /* PROPS */, ["risk-level"])])])]);
}