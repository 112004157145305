import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("h3", null, "Default InputField", -1 /* HOISTED */)), _createVNode(_component_input_field, {
    title: "Label",
    placeholder: "Placeholder text"
  }), _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[3] || (_cache[3] = _createElementVNode("h3", null, "InputField with Character Counter", -1 /* HOISTED */)), _createVNode(_component_input_field, {
    title: "Label",
    placeholder: "Placeholder text",
    "has-character-counter": true
  }), _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[5] || (_cache[5] = _createElementVNode("h3", null, "InputField with Character Limit", -1 /* HOISTED */)), _createVNode(_component_input_field, {
    title: "Label",
    placeholder: "Placeholder text",
    "pre-text": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"
  }), _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[7] || (_cache[7] = _createElementVNode("h3", null, "Read-only InputField", -1 /* HOISTED */)), _createVNode(_component_input_field, {
    title: "Label",
    enabled: false,
    type: "text",
    "pre-text": "Already filled out data - and read-only!"
  }), _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Read-only InputField with Help Text", -1 /* HOISTED */)), _createVNode(_component_input_field, {
    title: "Label",
    enabled: false,
    type: "text",
    "pre-text": "Already filled out data - and read-only!",
    "help-text": "Do X before enabling"
  }), _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[11] || (_cache[11] = _createElementVNode("h3", null, "InputField with Error and Help Text", -1 /* HOISTED */)), _cache[12] || (_cache[12] = _createElementVNode("p", null, "Delete the test value to see the error.", -1 /* HOISTED */)), _createVNode(_component_input_field, {
    modelValue: $data.testText,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.testText = $event;
    }),
    title: "Label",
    type: "text",
    invalid: !$data.testText,
    "error-help-text": "Help text"
  }, null, 8 /* PROPS */, ["modelValue", "invalid"]), _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[14] || (_cache[14] = _createElementVNode("h3", null, "InputField with Help Text and Character Counter", -1 /* HOISTED */)), _createVNode(_component_input_field, {
    title: "Label",
    type: "text",
    "pre-text": "",
    "has-character-counter": true,
    "help-text": "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"
  })]);
}