import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "mb-1 glob-h1"
};
var _hoisted_2 = {
  class: "mb-8 glob-l1 text-rm-signal-grey-dark"
};
var _hoisted_3 = {
  class: "w-350px mb-6"
};
var _hoisted_4 = {
  class: "glob-l1-sb"
};
var _hoisted_5 = {
  class: "flex items-center"
};
var _hoisted_6 = {
  class: "glob-l1"
};
var _hoisted_7 = {
  key: 0,
  class: "flex glob-l1"
};
var _hoisted_8 = {
  class: ""
};
var _hoisted_9 = {
  key: 1,
  class: "glob-l1"
};
var _hoisted_10 = {
  class: "underline"
};
var _hoisted_11 = {
  key: 1
};
var _hoisted_12 = {
  key: 0,
  class: "cursor-pointer w-fit"
};
var _hoisted_13 = ["href"];
var _hoisted_14 = {
  key: 1
};
var _hoisted_15 = {
  key: 0,
  class: "cursor-pointer w-fit"
};
var _hoisted_16 = ["href"];
var _hoisted_17 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_financial_definitions_edit_modal = _resolveComponent("financial-definitions-edit-modal");
  var _component_single_select = _resolveComponent("single-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_static_table = _resolveComponent("static-table");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, _toDisplayString($data.translate.registerOfInformation), 1 /* TEXT */), _createElementVNode("div", _hoisted_2, _toDisplayString($data.translate.chooseTheParentCompanyYourRegister), 1 /* TEXT */), $data.showDefinitionModal ? (_openBlock(), _createBlock(_component_financial_definitions_edit_modal, {
    key: 0,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showDefinitionModal = false;
    })
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
    "model-value": $setup.selectedCompanyId,
    title: $data.translate.parentCompany,
    options: $options.companyOptions,
    "with-reset": true,
    placeholder: $data.translate.selectParentCompany,
    "onUpdate:modelValue": $options.uploadDoraReport
  }, null, 8 /* PROPS */, ["model-value", "title", "options", "placeholder", "onUpdate:modelValue"])]), $setup.selectedCompanyId ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [$data.isLoaded ? (_openBlock(), _createBlock(_component_static_table, {
    key: 0,
    "dataset-table": $options.dataset,
    columns: $options.columns,
    class: "glob-l1"
  }, {
    id: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createElementVNode("span", _hoisted_4, _toDisplayString(slotData), 1 /* TEXT */)];
    }),
    name: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [_createElementVNode("div", _hoisted_5, [_createElementVNode("span", _hoisted_6, _toDisplayString(slotData.name), 1 /* TEXT */), slotData.icon ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: slotData.icon,
        width: 18,
        height: 18,
        class: "ml-2 text-rm-text stroke-2 cursor-pointer",
        onClick: slotData.action
      }, null, 8 /* PROPS */, ["icon", "onClick"])) : _createCommentVNode("v-if", true)])];
    }),
    status: _withCtx(function (_ref3) {
      var slotData = _ref3.slotData;
      return [!slotData.length ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "bg-rm-signal-green rounded-full w-4 h-4 mr-2"
      }, null, -1 /* HOISTED */)), _createElementVNode("span", _hoisted_8, _toDisplayString($data.translate.ready), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotData, function (record, index) {
        return _openBlock(), _createElementBlock("div", {
          key: "dora_".concat(index),
          class: "flex"
        }, [_cache[3] || (_cache[3] = _createElementVNode("div", {
          class: "bg-rm-signal-red rounded-full w-4 h-4 mr-2"
        }, null, -1 /* HOISTED */)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record, function (item, i) {
          return _openBlock(), _createElementBlock("div", {
            key: "str_".concat(i),
            class: "mr-1 mb-1"
          }, [item.objType ? (_openBlock(), _createBlock(_component_preview_modal_link, {
            key: 0,
            activity: item,
            onDismissModal: _cache[1] || (_cache[1] = function ($event) {
              return $options.getExtendedData(true);
            })
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("span", _hoisted_10, _toDisplayString(item.title), 1 /* TEXT */)];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"])) : (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(item), 1 /* TEXT */))]);
        }), 128 /* KEYED_FRAGMENT */))]);
      }), 128 /* KEYED_FRAGMENT */))]))];
    }),
    excel: _withCtx(function (_ref4) {
      var slotData = _ref4.slotData;
      return [slotData ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("a", {
        href: slotData,
        download: ""
      }, [_createVNode(_component_feather_icon, {
        icon: "download",
        class: "text-rm-grey hover:text-rm-active-blue active:text-rm-active-blue"
      })], 8 /* PROPS */, _hoisted_13)])) : (_openBlock(), _createElementBlock("div", _hoisted_14))];
    }),
    csv: _withCtx(function (_ref5) {
      var slotData = _ref5.slotData;
      return [slotData ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createElementVNode("a", {
        href: slotData,
        download: ""
      }, [_createVNode(_component_feather_icon, {
        icon: "download",
        class: "text-rm-grey hover:text-rm-active-blue active:text-rm-active-blue"
      })], 8 /* PROPS */, _hoisted_16)])) : (_openBlock(), _createElementBlock("div", _hoisted_17))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-table", "columns"])) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  }))], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)]);
}