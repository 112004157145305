function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { useFrameworksStore } from '@/Store/frameworksStore';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import PreviewRiskExpanded from '@/components/Atoms/Preview/PreviewRiskExpanded';
import CardIncidents from '@/components/Organisms/General/Cards/CardIncidents';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import CardContract from '@/components/Organisms/General/Cards/CardContract';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { checkERMByProjectId } from '@/utils/risks/check';
import { prepareDateTimezone } from '@/utils/date';
import { DateFormats } from '@/constants/DateFormats';
import { ControlFields } from '@/constants/ControlFields';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { Colors } from '@/Colors';
import * as Utils from '@/utils/Utils';
export default {
  name: 'ExpandedCard',
  introduction: 'Expanded view of an activity',
  description: 'Shows a shadowed box of one activity with description, e.g. an action',
  token: '<expanded-card />',
  components: {
    PreviewRiskExpanded: PreviewRiskExpanded,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    CardIncidents: CardIncidents,
    CardContract: CardContract,
    PreviewModalLink: PreviewModalLink,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  props: {
    activity: {
      type: Object,
      required: true,
      note: 'Activity object'
    },
    users: {
      type: Array,
      required: true,
      note: 'A list of all users'
    },
    organisations: {
      type: Array,
      required: true,
      note: 'A list of all organisations'
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'A list of all activity types'
    },
    workflowStatuses: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'A list of workflow statuses for contracts'
    },
    scoring: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Scoring period for ERM risks'
    },
    period: {
      type: Object,
      default: null,
      note: 'Project period data'
    },
    watermarkImage: {
      type: String,
      required: false,
      default: 'none',
      note: 'Url of background watermark image'
    },
    additionalFields: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'Additional fields to display (Reviewers, Completed, Review Deadline, etc..)'
    },
    isApprovalFlowEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    previewMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['updateFromPreviewMode', 'onDismissModal', 'deleteItem'],
  data: function data() {
    return {
      Colors: Colors,
      ControlFields: ControlFields,
      ObjectTypes: ObjectTypes,
      checkERMByProjectId: checkERMByProjectId,
      translate: getTranslate['ExpandedCard']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useTagsStore, ['tags'])), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), {}, {
    isERM: function isERM() {
      return checkERMByProjectId(this.activity.projectId, this.currentUser);
    },
    hoverTitle: function hoverTitle() {
      if (this.activity.confidential) {
        return DatatableRenderer.getItemHoverTitle(this.activity);
      }
      return this.activity.title;
    },
    usersResponsible: function usersResponsible() {
      var _this$activity, _this$activity2;
      if ((_this$activity = this.activity) !== null && _this$activity !== void 0 && (_this$activity = _this$activity.userIds) !== null && _this$activity !== void 0 && (_this$activity = _this$activity.responsible) !== null && _this$activity !== void 0 && _this$activity.length) {
        return Utils.findPropsByIds(this.activity.userIds.responsible, this.users, 'display_name');
      }
      if ((_this$activity2 = this.activity) !== null && _this$activity2 !== void 0 && (_this$activity2 = _this$activity2.responsibleUserIds) !== null && _this$activity2 !== void 0 && _this$activity2.length) {
        return Utils.findPropsByIds(this.activity.responsibleUserIds, this.users, 'display_name');
      }
      return '';
    },
    frameworkTitles: function frameworkTitles() {
      var _this$activity3;
      if ((_this$activity3 = this.activity) !== null && _this$activity3 !== void 0 && (_this$activity3 = _this$activity3.solutionIds) !== null && _this$activity3 !== void 0 && _this$activity3.length) {
        return Utils.findPropsByIdsSorted(this.activity.solutionIds, this.frameworks, 'displayName');
      }
      return this.translate.notSet;
    },
    usersReviewers: function usersReviewers() {
      if (this.activity.userIds) {
        return Utils.findPropsByIds(this.activity.userIds.review, this.users, 'display_name');
      }
      return '';
    },
    organisationsAssigned: function organisationsAssigned() {
      return Utils.findPropsByIds(this.activity.organisationIds, this.organisations, 'visible_name');
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (at) {
        return +at.id === +_this.activity.activityType;
      });
    },
    activityTypeAssigned: function activityTypeAssigned() {
      return this.currentActivityType ? this.currentActivityType.label : '';
    },
    tagsAssigned: function tagsAssigned() {
      return Utils.findPropsByIds(this.activity.tagIds, this.tags, 'tag');
    },
    daughterControls: function daughterControls() {
      var result = [];
      if (this.activity.isMothercontrol && this.activity.daughterControls) {
        result = this.activity.daughterControls.map(function (item) {
          return "".concat(item.cpath, " ").concat(item.title);
        });
      }
      return result.join(', ');
    },
    deadlineFormated: function deadlineFormated() {
      return prepareDateTimezone(this.activity.deadline, this.activity.deadlineTimezone, DateFormats.DATE_FORMAT);
    },
    completedFormated: function completedFormated() {
      return prepareDateTimezone(this.activity.completed, this.activity.deadlineTimezone, DateFormats.DATE_TIME_FORMAT);
    },
    reviewDeadlineFormated: function reviewDeadlineFormated() {
      return prepareDateTimezone(this.activity.reviewDeadline, this.activity.deadlineTimezone, DateFormats.DATE_TIME_FORMAT);
    },
    isRisk: function isRisk() {
      return this.activity.objType === ObjectTypes.RISK_RISK || this.activity.objType === ObjectTypes.RISK_RISK_LIMITED;
    },
    activityTitleLineThrough: function activityTitleLineThrough() {
      return this.isRisk && this.activity.closed;
    },
    descriptionTruncated: function descriptionTruncated() {
      return Utils.truncateHtml(this.activity.description, 300, {
        html: true,
        maxLines: 3
      });
    },
    controlTrafficLightColor: function controlTrafficLightColor() {
      return this.getControlTrafficLightColorByNumber(this.activity.trafficLight);
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getControlTrafficLightColorByNumber', 'getTrafficLightColorByObjectTypeAndNumber'])), {}, {
    showOptionalField: function showOptionalField(field) {
      var _this$currentActivity;
      if (!this.currentActivityType) return true;
      return (_this$currentActivity = this.currentActivityType.optionalFields) === null || _this$currentActivity === void 0 ? void 0 : _this$currentActivity.find(function (item) {
        return item === field;
      });
    }
  })
};