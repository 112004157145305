import DownloadLink from '@/components/Atoms/DownloadLink/DownloadLink';
export default {
  components: {
    DownloadLink: DownloadLink
  },
  data: function data() {
    return {
      fileSrc: '/risma2/examples/ActionsImportExample.xlsx'
    };
  }
};